import { DataValidationError } from "constants/DataValidationError";
import { IsArray, IsBoolean, IsNotEmpty, MaxLength } from "class-validator";
import Spec from "constants/Spec";

export default class CreateGuestCategoryReq {
  @MaxLength(Spec.maxLength.guestCategory.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.guestCategory.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @IsArray()
  shops!: number[];

  @IsBoolean()
  pointGrantFlag!: boolean;

  @IsBoolean()
  unUseUserFlag!: boolean;

  @IsBoolean()
  secondUserFlag!: boolean;

  constructor(arg: {
    name: string;
    shops: number[];
    pointGrantFlag: boolean;
    unUseUserFlag: boolean;
    secondUserFlag: boolean;
  }) {
    arg = arg || {};
    this.name = arg.name;
    this.shops = arg.shops;
    this.pointGrantFlag = arg.pointGrantFlag;
    this.unUseUserFlag = arg.unUseUserFlag;
    this.secondUserFlag = arg.secondUserFlag;
  }
}
