import { reducerWithInitialState } from "typescript-fsa-reducers";
import areaActions from "redux/actions/area";
import AreaRes from "types/res/area/AreaRes";
export type AreaState = AreaRes[];

const initialState = [] as AreaState;

const areaReducer = reducerWithInitialState(initialState)
  .case(areaActions.addAreaAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(areaActions.updateAreaAction.done, (state, payload) => {
    return state.map((item) =>
      item.areaId === payload.params.areaId ? payload.result : item
    );
  })
  .case(areaActions.deleteAreaAction.done, (state, payload) => {
    return state.filter((item) => item.areaId !== payload.params.areaId);
  })
  .case(areaActions.fetchAreaAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(areaActions.bulkInsertAreaAction.done, (state, payload) => {
    return [...state, ...payload.result];
  });
export default areaReducer;
