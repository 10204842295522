import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RecruitingMediaRes from "types/res/recruitingMedia/RecruitingMediaRes";
import {
  addRecruitingMedia,
  deleteRecruitingMedia,
  fetchRecruitingMedia,
  updateRecruitingMedia,
} from "redux/actions/recruitingMedia";
import { fetchShops } from "redux/actions/shop";
import { FormType } from "components/FormField";
import {
  Box,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ShopRes from "types/res/shop/ShopRes";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import CreateRecruitingMediaReq from "types/req/recruitingMedia/CreateRecruitingMediaReq";
import UpdateRecruitingMediaReq from "types/req/recruitingMedia/UpdateRecruitingMediaReq";
import Button from "@material-ui/core/Button";

const RecruitingMedia = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const recruitingMedia = useSelector((state) => state.recruitingMedia);
  const [selectShops, setSelectShops] = React.useState<string[]>([]);
  const headers: { key: keyof RecruitingMediaRes; label: string }[] = [
    { key: "name", label: "媒体名" },
    { key: "cost", label: "料金" },
    { key: "shops", label: "適用店舗" },
  ];

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchRecruitingMedia(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "料金",
      key: "cost",
      type: FormType.Number,
    },
    {
      label: "適用店舗",
      key: "shops",
      type: FormType.MultiOptions,
      options: shops.map((a) => ({ id: Number(a.shopId), name: a.name })),
      value: (val: any) => val.map((a: any) => a.shopId),
    },
  ];

  const onClickSearch = () => {
    dispatch(
      fetchRecruitingMedia(
        companyId,
        selectShops.filter((shopId) => shopId !== "すべて").map(Number)
      )
    );
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        marginTop={1}
        marginLeft={2}
      >
        <Box display="flex" alignItems="center">
          <Box>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={selectShops}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectShops((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length === shops.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= shops.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectShops.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Button variant="contained" color="primary" onClick={onClickSearch}>
              検索
            </Button>
          </Box>
        </Box>
      </Box>
      <RecruitingMediaTable
        title={"採用媒体"}
        formId="recruitingMediaForm"
        forms={forms}
        rows={headers}
        data={recruitingMedia}
        addType={CreateRecruitingMediaReq}
        updateType={UpdateRecruitingMediaReq}
        addFunc={(formData) => addRecruitingMedia(companyId, formData)}
        updateFunc={(formData) => updateRecruitingMedia(companyId, formData)}
        deleteFunc={(item) =>
          deleteRecruitingMedia(companyId, {
            recruitingMediaId: item.recruitingMediaId,
          })
        }
        values={[
          (s) => s.name,
          (s) => s.cost,
          (s) =>
            s.shops.map((shopRes: ShopRes) => shopRes?.name).join(",") ||
            "所属なし",
        ]}
      />
    </>
  );
};

const RecruitingMediaTable = styled<
  React.FC<
    CommonTableProps<
      RecruitingMediaRes,
      CreateRecruitingMediaReq,
      UpdateRecruitingMediaReq
    >
  >
>(CommonTable)`
  margin-top: 24px;
`;

export default RecruitingMedia;
