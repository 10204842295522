import { Expose } from "class-transformer";

export default class CastRankingRes {
  @Expose() readonly commutingDays!: number;
  @Expose() readonly commutingTimes!: number;
  @Expose() readonly absenceCount!: number;
  @Expose() readonly tardyCount!: number;
  @Expose() readonly breakCount!: number;
  @Expose() readonly breakBaseCount!: number;
  @Expose() readonly totalOrderCount!: number;
  @Expose() readonly sales!: number;
  @Expose() readonly castFee!: number;
  @Expose() readonly averageScore!: number;
  readonly [key: string]: number;
}
