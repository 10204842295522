import { reducerWithInitialState } from "typescript-fsa-reducers";
import discountActions from "redux/actions/discount";
import DiscountRes from "types/res/discount/DiscountRes";
export type DiscountState = DiscountRes[];

const initialState = [] as DiscountState;

const discountReducer = reducerWithInitialState(initialState)
  .case(discountActions.addDiscountAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(discountActions.updateDiscountAction.done, (state, payload) => {
    return state.map((item) =>
      item.discountId === payload.params.discountId ? payload.result : item
    );
  })
  .case(discountActions.deleteDiscountAction.done, (state, payload) => {
    return state.filter(
      (item) => item.discountId !== payload.params.discountId
    );
  })
  .case(discountActions.fetchDiscountAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(discountActions.bulkInsertDiscountAction.done, (state, payload) => {
    return [...state, ...payload.result];
  });
export default discountReducer;
