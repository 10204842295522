import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ShopRes from "types/res/shop/ShopRes";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import {
  addAdditionalTime,
  deleteAdditionalTime,
  fetchAdditionalTime,
  updateAdditionalTime,
} from "redux/actions/additionalTime";
import AdditionalTimeRes from "types/res/additionalTime/AdditionalTimeRes";
import { fetchShops } from "redux/actions/shop";
import { FormType } from "components/FormField";
import CreateAdditionalTimeReq from "../../types/req/additionalTime/CreateAdditionalTimeReq";
import UpdateAdditionalTimeReq from "../../types/req/additionalTime/UpdateAdditionalTimeReq";
import { fetchNotelClass } from "redux/actions/notelClass";

const headers: { key: keyof AdditionalTimeRes; label: string }[] = [
  { key: "time", label: "時間" },
  { key: "name", label: "名前" },
  { key: "totalFee", label: "料金" },
  { key: "shopFee", label: "店舗料金" },
  { key: "castFee", label: "キャスト料金" },
  { key: "isGuestShow", label: "顧客モバイル表示" },
  { key: "shops", label: "適用店舗" },
  { key: "notelClasses", label: "適用クラス" },
];

const AdditionalTime: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const notelClasses = useSelector((state) => state.notelClass);
  const additionalTime = useSelector((state) => state.additionalTime);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchNotelClass(companyId));
    dispatch(fetchAdditionalTime(companyId));
  }, [companyId, dispatch]);

  const forms = [
    {
      label: "時間",
      key: "time",
      type: FormType.Number,
    },
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "料金",
      key: "totalFee",
      type: FormType.Number,
    },
    {
      label: "店舗料金",
      key: "shopFee",
      type: FormType.Number,
    },
    {
      label: "キャスト料金",
      key: "castFee",
      type: FormType.Number,
    },
    {
      label: "顧客モバイル表示",
      key: "isGuestShow",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "非表示" },
        { id: "1", name: "表示" },
      ],
    },
    {
      label: "店舗",
      key: "shops",
      type: FormType.MultiOptions,
      options: shops.map((s) => ({ id: Number(s.shopId), name: s.name })),
      value: (val: any) => val.map((s: any) => s.shopId),
    },
    {
      label: "クラス",
      key: "notelClasses",
      type: FormType.MultiOptions,
      options: notelClasses.map((s) => ({
        id: Number(s.notelClassId),
        name: s.name,
      })),
      value: (val: any) => val.map((s: any) => s.notelClassId),
    },
  ];

  return (
    <>
      <AdditionalTimeTable
        title={"延長"}
        formId="additionalTimeForm"
        forms={forms}
        addType={CreateAdditionalTimeReq}
        updateType={UpdateAdditionalTimeReq}
        addFunc={(formData) => addAdditionalTime(companyId, formData)}
        updateFunc={(formData) => updateAdditionalTime(companyId, formData)}
        deleteFunc={(item) =>
          deleteAdditionalTime(companyId, {
            additionalTimeId: item?.additionalTimeId,
          })
        }
        rows={headers}
        data={additionalTime}
        values={[
          (s) => s.time,
          (s) => s.name || "未設定",
          (s) => s.totalFee,
          (s) => s.shopFee,
          (s) => s.castFee,
          (s) => (s.isGuestShow ? "表示" : "非表示"),
          (s) =>
            s.shops
              .map(
                (shopRes: ShopRes) =>
                  shops.find((shop) => shop.shopId === shopRes.shopId)?.name
              )
              .join(",") || "所属なし",
          (s) =>
            s.notelClasses.map((notelClass) => notelClass.name).join(",") ||
            "所属なし",
        ]}
      />
    </>
  );
};

const AdditionalTimeTable = styled<
  React.FC<
    CommonTableProps<
      AdditionalTimeRes,
      CreateAdditionalTimeReq,
      UpdateAdditionalTimeReq
    >
  >
>(CommonTable)`
  margin-top: 24px;
`;

export default AdditionalTime;
