import { reducerWithInitialState } from "typescript-fsa-reducers";
import orderActions from "redux/actions/order";
import OrderRes from "types/res/order/OrderRes";
export type OrderState = OrderRes[];

const initialState = [] as OrderState;

const orderReducer = reducerWithInitialState(initialState)
  .case(orderActions.addOrderAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(orderActions.updateOrderAction.done, (state, payload) => {
    return state.map((item) =>
      item.orderId === payload.params.orderId ? payload.result : item
    );
  })
  .case(orderActions.cancelOrderAction.done, (state, payload) => {
    return state.map((item) =>
      item.orderId === payload.params.orderId ? payload.result : item
    );
  })
  .case(orderActions.deleteOrderAction.done, (state, payload) => {
    return state.filter((item) => item.orderId !== payload.params.orderId);
  })
  .case(orderActions.fetchOrderAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(orderActions.fetchOrderByGuestIdAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(orderActions.fetchOrderWithPaginationAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(orderActions.fetchSpOrderAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(orderActions.fetchOneOrderAction.done, (state, payload) => {
    return [payload.result];
  })
  .case(orderActions.bulkInsertOrderAction.done, (state, payload) => {
    return [...state, ...payload.result];
  })
  .case(orderActions.bulkDeleteOrderAction.done, (state, payload) => {
    return [
      ...state.filter(
        (item) =>
          !payload.params.map((param) => param.orderId).includes(item.orderId)
      ),
    ];
  })
  .case(
    orderActions.fetchOrderByCastWithPaginationAction.done,
    (state, payload) => {
      return [...payload.result];
    }
  );
export default orderReducer;
