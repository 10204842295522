import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import JournalRes from "types/res/journal/JournalRes";
import UpdateJournalReq from "types/req/journal/UpdateJournalReq";
import CreateJournalReq from "types/req/journal/CreateJournalReq";
import DeleteJournalReq from "types/req/journal/DeleteJournalReq";
import JournalApi from "../../api/JournalApi";

const actionCreator = actionCreatorFactory();
const addJournalAction = actionCreator.async<
  CreateJournalReq,
  JournalRes,
  { code: number; description: string }
>("ADD_JOURNAL");

export const addJournal =
  (companyId: number, req: CreateJournalReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addJournalAction.started({ ...req }));
    try {
      const journal = new CreateJournalReq({ ...req });
      const result = await JournalApi.create(companyId, journal);
      dispatch(
        addJournalAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addJournalAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchJournalAction = actionCreator.async<
  {},
  JournalRes[],
  { code: number; description: string }
>("FETCH_JOURNAL");

export const fetchJournal =
  (companyId: number, startDate?: string, endDate?: string) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchJournalAction.started([]));

    try {
      const result = await JournalApi.findAll(companyId, startDate, endDate);
      dispatch(
        fetchJournalAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchJournalAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateJournalAction = actionCreator.async<
  UpdateJournalReq,
  JournalRes,
  { code: number; description: string }
>("UPDATE_JOURNAL");

export const updateJournal =
  (companyId: number, req: UpdateJournalReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateJournalAction.started({ ...req }));

    try {
      const updateJournal = new UpdateJournalReq({ ...req });
      const result = await JournalApi.update(companyId, updateJournal);
      dispatch(
        updateJournalAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateJournalAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteJournalAction = actionCreator.async<
  DeleteJournalReq,
  {},
  { code: number; description: string }
>("DELETE_JOURNAL");

export const deleteJournal =
  (companyId: number, req: DeleteJournalReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteJournalAction.started({ ...req }));

    try {
      const journal = new DeleteJournalReq({ ...req });
      const result = await JournalApi.delete(companyId, journal);
      dispatch(
        deleteJournalAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteJournalAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const journalActions = {
  fetchJournalAction,
  addJournalAction,
  updateJournalAction,
  deleteJournalAction,
};

export default journalActions;
