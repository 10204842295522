import { DataValidationError } from "constants/DataValidationError";
import {
  IsArray,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";

export default class UpdateCastNameReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly castNameId: number;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  castId: number;

  @MaxLength(Spec.maxLength.staff.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.staff.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  shopId!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  notelClassId!: number;

  @IsOptional()
  @IsNumber({}, { message: DataValidationError.DATA_IS_INVALID })
  age!: number | null;

  @IsOptional()
  @IsNumber({}, { message: DataValidationError.DATA_IS_INVALID })
  bodyHeight!: number | null;

  @IsOptional()
  @IsString({ message: DataValidationError.DATA_IS_INVALID })
  cup!: string;

  @IsOptional()
  @IsNumber({}, { message: DataValidationError.DATA_IS_INVALID })
  bust!: number;

  @IsOptional()
  @IsNumber({}, { message: DataValidationError.DATA_IS_INVALID })
  waist!: number;

  @IsOptional()
  @IsNumber({}, { message: DataValidationError.DATA_IS_INVALID })
  hip!: number;

  @IsOptional()
  @IsNumber()
  sort!: number | null;

  @IsOptional()
  @IsArray()
  options!: number[];

  constructor(arg: {
    castNameId: number;
    castId: number;
    name: string;
    shopId: number;
    notelClassId: number;
    age: number | null;
    bodyHeight: number | null;
    cup: string;
    bust: number;
    waist: number;
    hip: number;
    sort: number | null;
    options: number[];
  }) {
    arg = arg || {};
    this.castNameId = arg.castNameId;
    this.castId = arg.castId;
    this.name = arg.name;
    this.shopId = arg.shopId;
    this.notelClassId = arg.notelClassId;
    this.age = arg.age;
    this.bodyHeight = arg.bodyHeight;
    this.cup = arg.cup;
    this.bust = arg.bust;
    this.waist = arg.waist;
    this.hip = arg.hip;
    this.sort = arg.sort;
    this.options = arg.options;
  }
}
