import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import {
  IconButton,
  Table,
  TextField,
  Box,
  Theme,
  createStyles,
} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Delete } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { fetchShops } from "redux/actions/shop";
import CreateCourseReq from "types/req/course/CreateCourseReq";
import { addCourse } from "redux/actions/course";
import CourseRow from "./components/CourseRow";
import CreateCourseBaseData from "./components/CreateCourseBaseData";
import { makeStyles } from "@material-ui/core/styles";
import { fetchNomination } from "redux/actions/nomination";
import { fetchNotelClass } from "redux/actions/notelClass";
import CsvImport from "components/CsvImport";
import { CsvFieldType } from "utils/CsvUtils";
export type CourseProp = {
  name: string;
  price: number;
  classId: number;
  nominationId: number;
  shop: number;
  hotel: { enable: boolean; shop: number; cast: number; total: number };
  time: { time: number; shop: number; cast: number };
  welfare: number;
  course: { shop: number; cast: number };
  sort: number | null;
};

const Course: React.FC = () => {
  const dispatch = useDispatch();
  const loginStaff = useSelector((state) => state.account.staff);
  const shops = useSelector((state) => state.shop);
  const nominations = useSelector((state) => state.nomination);
  const notelClasses = useSelector((state) => state.notelClass);
  const [addCourseBase, setCourse] = React.useState<CourseProp[]>([]);
  const [openCsvImport, setOpenCsvImport] = useState(false);

  useEffect(() => {
    dispatch(fetchShops(loginStaff.companyId));
    dispatch(fetchNotelClass(loginStaff.companyId));
    dispatch(fetchNomination(loginStaff.companyId));
  }, [dispatch, loginStaff]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: { margin: theme.spacing(1) },
    })
  );

  const classes = useStyles();

  const courseRows = [
    "店舗名",
    "コース名",
    "ホテ込",
    "クラス名",
    "時間",
    "指名種",
    "厚生費",
    "コース代金",
    "並び順",
    "",
  ];

  const removeCourseTableRow = (index: number) => () => {
    setCourse(addCourseBase.filter((_, i) => index !== i));
  };

  const setCourseName =
    (index: number) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const copyTableRows = addCourseBase.slice();
      copyTableRows[index].name = event.target.value;
      setCourse(copyTableRows);
    };

  const setWelfareFee =
    (index: number) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const copyTableRows = addCourseBase.slice();
      copyTableRows[index].welfare = Number(event.target.value);
      setCourse(copyTableRows);
    };

  const setCourseSort =
    (index: number) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const copyTableRows = addCourseBase.slice();
      copyTableRows[index].sort =
        event.target.value === "" || null || undefined
          ? null
          : Number(event.target.value);
      setCourse(copyTableRows);
    };

  const registerCourseData = async () => {
    const createCourseReq = addCourseBase.map(
      (req) =>
        new CreateCourseReq({
          name: req.name,
          time: req.time.time,
          timeFee: req.time.cast + req.time.shop,
          timeShopFee: req.time.shop,
          timeCastFee: req.time.cast,
          welfareFee: req.welfare,
          hotelFee: req.hotel.total,
          hotelCastFee: req.hotel.cast,
          hotelShopFee: req.hotel.shop,
          totalFee: req.price,
          shopId: req.shop,
          notelClassId: req.classId,
          nominationId: req.nominationId,
          sort: req.sort,
        })
    );
    dispatch(addCourse(loginStaff.companyId, createCourseReq));
    setCourse([]);
  };

  const csvHeader = [
    {
      label: "コース名",
      key: "name",
      type: CsvFieldType.Text,
    },
    {
      label: "店舗",
      key: "shopId",
      type: CsvFieldType.Object,
      relation: shops,
      relationKey: "name",
      relationId: "shopId",
    },
    {
      label: "クラス名",
      key: "notelClassId",
      type: CsvFieldType.Object,
      relation: notelClasses,
      relationKey: "name",
      relationId: "notelClassId",
    },
    {
      label: "指名種",
      key: "nominationId",
      type: CsvFieldType.Object,
      relation: nominations,
      relationKey: "name",
      relationId: "nominationId",
    },
    {
      label: "時間※数字のみ",
      key: "time",
      type: CsvFieldType.Number,
    },
    {
      label: "基本料金※数字のみ",
      key: "timeFee",
      type: CsvFieldType.Number,
    },
    {
      label: "店舗基本料金※数字のみ",
      key: "timeShopFee",
      type: CsvFieldType.Number,
    },
    {
      label: "キャスト基本料金※数字のみ",
      key: "timeCastFee",
      type: CsvFieldType.Number,
    },
    {
      label: "厚生費※数字のみ",
      key: "welfareFee",
      type: CsvFieldType.Number,
    },
    {
      label: "ホテル費※数字のみ",
      key: "hotelFee",
      type: CsvFieldType.Number,
    },
    {
      label: "キャストホテル費※数字のみ",
      key: "hotelCastFee",
      type: CsvFieldType.Number,
    },
    {
      label: "店舗ホテル費※数字のみ",
      key: "hotelShopFee",
      type: CsvFieldType.Number,
    },
    {
      label: "合計料金※数字のみ",
      key: "totalFee",
      type: CsvFieldType.Number,
    },
    {
      label: "並び順",
      key: "sort",
      type: CsvFieldType.Number,
    },
  ];

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginTop={1}
        marginRight={2}
        style={{ backgroundColor: "#fff" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenCsvImport((prev) => !prev)}
          style={{ marginLeft: "30px" }}
        >
          csvインポート
        </Button>
      </Box>
      {openCsvImport && (
        <CsvImport
          templatePath={"/csv/course.csv"}
          templateName={"course_template.csv"}
          csvHeader={csvHeader}
          addType={CreateCourseReq}
          addFunc={(formData) => addCourse(loginStaff.companyId, formData)}
        />
      )}
      <CreateCourseBaseData setCourse={setCourse} />
      <Card>
        <CardContent>
          <CourseRow />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography variant="subtitle1">新規作成コース一覧</Typography>
          <Table>
            <TableHead>
              <TableRow>
                {courseRows.map((row, index) => (
                  <TableCell key={index}>{row}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {addCourseBase.map((c, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {shops.find((shop) => shop.shopId === c.shop)?.name}
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      onChange={setCourseName(index)}
                    />
                  </TableCell>
                  <TableCell>{c.hotel.enable ? "込" : "無"}</TableCell>
                  <TableCell>
                    {notelClasses.find(
                      (notelClass) => notelClass.notelClassId === c.classId
                    )?.name || "未設定"}
                  </TableCell>
                  <TableCell>{c.time.time}</TableCell>
                  <TableCell>
                    {nominations.find(
                      (nomination) => nomination.nominationId === c.nominationId
                    )?.name || "未設定"}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="column">
                      <TextField
                        label="店舗"
                        variant="outlined"
                        onChange={setWelfareFee(index)}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="column">
                      <TextField
                        label="基本料金"
                        variant="outlined"
                        defaultValue={c.time.cast + c.time.shop}
                        disabled
                      />
                      <TextField
                        label="ホテ込"
                        variant="outlined"
                        defaultValue={c.hotel.total}
                        disabled
                      />
                      <TextField
                        label="合計"
                        variant="outlined"
                        disabled
                        defaultValue={c.hotel.total + c.time.cast + c.time.shop}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="column">
                      <TextField
                        label="並び順"
                        variant="outlined"
                        defaultValue={c.sort}
                        onChange={setCourseSort(index)}
                      />
                    </Box>
                  </TableCell>
                  <TableCell padding="checkbox">
                    <IconButton onClick={removeCourseTableRow(index)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="center" style={{ margin: 10 }}>
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              disabled={addCourseBase.length === 0}
              onClick={registerCourseData}
            >
              登録
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default Course;
