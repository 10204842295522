import { reducerWithInitialState } from "typescript-fsa-reducers";
import castActions from "redux/actions/cast";
import CastRes from "types/res/cast/CastRes";
export type CastState = CastRes[];

const initialState = [] as CastState;

const castReducer = reducerWithInitialState(initialState)
  .case(castActions.addCastAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(castActions.updateCastAction.done, (state, payload) => {
    return state.map((item) =>
      item.castId === payload.params.castId ? payload.result : item
    );
  })
  .case(castActions.deleteCastAction.done, (state, payload) => {
    return state.filter((item) => item.castId !== payload.params.castId);
  })
  .case(castActions.fetchCastAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(castActions.fetchCastWithPaginationAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(castActions.fetchActualWorkCastListAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(castActions.bulkInsertCastAction.done, (state, payload) => {
    return [...state, ...payload.result];
  })
  .case(castActions.bulkDeleteCastAction.done, (state, payload) => {
    return state.filter(
      (item) =>
        !payload.params.map((param) => param.castId).includes(item.castId)
    );
  });

export default castReducer;
