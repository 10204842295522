import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, createStyles, Theme } from "@material-ui/core";
import Tooltip from "rc-tooltip";
import React from "react";
import { useSelector } from "react-redux";
import CarTransferScheduleApi from "api/CarTransferScheduleApi";

const Item = styled.div<{ color: string }>`
  background-color: ${(props) => props.color} !important;
  border: 1px solid ${(props) => props.color} !important;
  border-radius: 4px;
`;
type Props = {
  item: any;
  itemContext: any;
  getItemProps: any;
  onClick: any;
  fetchDriverShift: any;
};

const DriverScheduleCarTransferScheduleItem = ({
  item,
  itemContext,
  getItemProps,
  onClick,
  fetchDriverShift,
}: Props) => {
  const companyId = useSelector((state) => state.account.staff.companyId);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  const onClickDelete = async () => {
    const result = window.confirm("本当に削除しますか？");
    if (result) {
      const id = item.id.split("-")[1];
      await CarTransferScheduleApi.delete(companyId, {
        carTransferScheduleId: Number(id),
      });
      fetchDriverShift();
    }
  };

  return (
    <Tooltip
      trigger="hover"
      placement="top"
      overlay={
        <Box display="flex">
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={() => onClick()}
          >
            編集
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            color={"secondary"}
            onClick={() => onClickDelete()}
          >
            削除
          </Button>
        </Box>
      }
    >
      <Item {...getItemProps(item.itemProps)} color={item.color}>
        <div
          className="rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
        >
          {itemContext.title}
        </div>
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        />
      </Item>
    </Tooltip>
  );
};
export default DriverScheduleCarTransferScheduleItem;
