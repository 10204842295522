import React from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import moment from "moment";
import "moment/locale/ja";
import theme from "./theme";
import Routes from "./routes";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Settings } from "luxon";
import { Toast } from "components/Toast";
moment.locale("ja");
Settings.defaultLocale = "ja";
function App() {
  return (
    <React.Fragment>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={"ja"}>
              <Routes />
              <Toast />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
