import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty, IsNumber, IsOptional } from "class-validator";
import { CallHistoryType } from "types/enum/CallHistoryType";

export default class UpdateCallHistoryReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  callHistoryId!: number;

  @IsOptional()
  @IsNumber()
  guestId!: number | null;

  @IsOptional()
  @IsNumber()
  shopId!: number | null;

  @IsOptional()
  @IsNumber()
  staffId!: number | null;

  @IsOptional()
  type!: CallHistoryType | null;

  constructor(arg: {
    callHistoryId: number;
    shopId: number | null;
    guestId: number | null;
    staffId: number | null;
    type: CallHistoryType | null;
  }) {
    arg = arg || {};
    this.callHistoryId = arg.callHistoryId;
    this.staffId = arg.staffId;
    this.guestId = arg.guestId;
    this.shopId = arg.shopId;
    this.type = arg.type;
  }
}
