import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  createStyles,
  Grid,
  Hidden,
  IconButton as MuiIconButton,
  InputLabel,
  Popover,
  SvgIcon,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Menu as MenuIcon, Settings } from "@material-ui/icons";
import { blue, grey } from "@material-ui/core/colors";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import WarningIcon from "@material-ui/icons/Warning";
import { checkStaffStatus, logout } from "redux/actions/account";
import { makeStyles } from "@material-ui/core/styles";
import EnumUtils from "../utils/EnumUtils";
import { CastReportType } from "types/enum/CastReportType";
import { fetchCastReport, isReadCastReport } from "redux/actions/castReport";
import ReactAudioPlayer from "react-audio-player";
import StaffRole from "../types/enum/StaffRole";
import {
  fetchCompanies,
  fetchCompaniesByCompanyGroupId,
} from "redux/actions/company";
import { Autocomplete } from "@material-ui/lab";
import CompanyRes from "types/res/company/CompanyRes";
import Tooltip from "rc-tooltip";
import { updateStaff } from "redux/actions/staff";
import NotificationsIcon from "@material-ui/icons/Notifications";
import OrderRes from "types/res/order/OrderRes";
import OrderApi from "api/OrderApi";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import { fetchRequestGuests } from "redux/actions/requestGuest";
const theme = {
  color: "#333333",
  background: "#FFFFFF",
  search: {
    color: grey[800],
  },
  indicator: {
    background: blue[600],
  },
};

const AppBar = styled(MuiAppBar)`
  background: ${theme.background};
  color: ${theme.color};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
`;

const Logo = styled(Link)`
  display: flex;
  justify-self: center;
  img {
    height: 28px;
  }
`;

const IconButton: any = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type Props = {
  onDrawerToggle?: () => void;
};

const Header: React.FC<Props> = ({ onDrawerToggle }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector((state) => Boolean(state.account.accessToken));
  const companyId = useSelector((state) => state.account?.staff?.companyId);
  const staff = useSelector((state) => state.account?.staff);
  const compaines = useSelector((state) => state.company);
  const accessToken = useSelector((state) => state.account.accessToken);
  const requestGuests = useSelector((state) => state.requestGuest);
  const [now, setNow] = useState(new Date());
  const [company, setCompany] = useState<CompanyRes>();
  const [castRequestOrders, setCastRequestOrders] = useState<OrderRes[]>([]);
  const emergency = useSelector((state) =>
    state.castReport.filter(
      (report) =>
        EnumUtils.mapToEnum(CastReportType, report.type) ===
        CastReportType.emergency
    )
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickLogoutButton = () => {
    dispatch(logout());
  };
  useEffect(() => {
    if (!companyId) return;
    const intervalId = setInterval(function () {
      setNow(new Date());
      dispatch(fetchCastReport(companyId));
    }, 60000);
    return function () {
      clearInterval(intervalId);
    };
  }, [companyId, now]);

  useEffect(() => {
    if (!staff?.staffId) return;
    dispatch(checkStaffStatus(companyId, staff.staffId));
  }, [companyId, staff?.staffId]);

  useEffect(() => {
    if (
      [
        StaffRole.notelMaster,
        StaffRole.notelCallCenterEmployee,
        StaffRole.notelCallCenterPartTime,
      ].includes(
        EnumUtils.mapToEnum(StaffRole, staff?.role) ||
          StaffRole.clientShopPartTime
      )
    ) {
      dispatch(fetchCompanies());
    }
    if (
      [
        StaffRole.clientMaster,
        StaffRole.areaEmployee,
        StaffRole.areaEmployee,
      ].includes(
        EnumUtils.mapToEnum(StaffRole, staff?.role) ||
          StaffRole.clientShopPartTime
      ) &&
      staff?.company?.companyGroupId
    ) {
      dispatch(
        fetchCompaniesByCompanyGroupId(
          staff.companyId,
          staff.company.companyGroupId
        )
      );
    }
  }, []);

  useEffect(() => {
    setCompany(compaines.find((company) => company.companyId === companyId));
    if (companyId) {
      dispatch(fetchRequestGuests(companyId));
    }
    const apiCall = async () => {
      if (!companyId) return;
      const result = await OrderApi.findCastRequest(companyId);
      setCastRequestOrders(result);
    };
    apiCall();
  }, [compaines, companyId]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  const onClickShowMobilePage = () => {
    dispatch(
      updateStaff(companyId, { ...staff, isForceSite: "sp" }, accessToken)
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Logo to="/">
                <img src="/logo.png" />
              </Logo>
            </Grid>

            {isLoggedIn && (
              <>
                <Box style={{ marginRight: "auto", marginLeft: "10px" }}>
                  {emergency
                    .filter((report) => !report.isRead)
                    .map((report, index) => (
                      <>
                        <Box display="flex" alignItems="center">
                          <Box
                            style={{ color: "red" }}
                            key={index}
                            display="flex"
                            alignItems="center"
                          >
                            <WarningIcon />
                            {report.cast.displayName}
                            さんから緊急通報が来ています
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={() =>
                              dispatch(
                                isReadCastReport(
                                  company?.companyId || companyId,
                                  {
                                    castReportId: report.castReportId,
                                  }
                                )
                              )
                            }
                          >
                            <Button variant="contained" color="primary">
                              完了
                            </Button>
                          </Box>
                        </Box>
                        <ReactAudioPlayer
                          src="/audio/alert.wav"
                          autoPlay
                          style={{ display: "none" }}
                        />
                      </>
                    ))}
                </Box>
                <Box
                  marginRight={1}
                  marginTop={1}
                  style={{ position: "relative" }}
                  onClick={handleClick}
                >
                  {castRequestOrders.length + requestGuests.length > 0 ? (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      style={{
                        backgroundColor: "red",
                        position: "absolute",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        right: "-8px",
                        top: "-8px",
                        zIndex: 10000,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "white",
                        }}
                      >
                        {castRequestOrders.length + requestGuests.length}
                      </Typography>
                    </Box>
                  ) : null}
                  <SvgIcon component={NotificationsIcon} />
                </Box>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    style={{ maxHeight: "160px" }}
                  >
                    {requestGuests.map((requestGuest) => (
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        key={requestGuest.requestGuestId}
                        style={{
                          borderBottom: "1px solid black",
                          padding: "6px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          申請顧客情報
                        </Typography>
                        <Typography style={{ fontSize: "12px" }}>
                          名前： {requestGuest.name}
                        </Typography>
                        <Typography style={{ fontSize: "12px" }}>
                          電話番号： {requestGuest.tel}
                        </Typography>
                        <Typography style={{ fontSize: "12px" }}>
                          メールアドレス： {requestGuest.email}
                        </Typography>
                        <Link
                          to={"/requestGuest"}
                          style={{ fontSize: "12px", color: "blue" }}
                        >
                          管理ページへ
                        </Link>
                      </Box>
                    ))}
                    {castRequestOrders.map((order) => (
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        key={order.orderId}
                        style={{
                          borderBottom: "1px solid black",
                          padding: "6px",
                        }}
                      >
                        <Box display={"flex"}>
                          <Typography style={{ fontSize: "12px" }}>
                            ご予約日時：{" "}
                            {order.planInTime
                              ? `${DateTime.fromJSDate(
                                  order.planInTime
                                ).toFormat(FORMAT_TYPE.YEAR_DATE_TIME)}`
                              : ""}
                          </Typography>
                        </Box>

                        <Box display={"flex"}>
                          <Typography style={{ fontSize: "12px" }}>
                            キャスト: {order?.castName?.name || ""}
                          </Typography>
                          <Typography
                            style={{ fontSize: "12px", marginLeft: "6px" }}
                          >
                            顧客名: {order?.guest?.name || ""}
                          </Typography>
                          <Link
                            to={`/order/${order.orderId}`}
                            style={{
                              fontSize: "12px",
                              marginLeft: "6px",
                              color: "blue",
                            }}
                          >
                            詳細
                          </Link>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Popover>
                <Box>
                  <Button
                    variant={"contained"}
                    color={"default"}
                    onClick={() => onClickShowMobilePage()}
                  >
                    <Typography style={{ fontSize: "12px" }}>
                      SP表示切替
                    </Typography>
                  </Button>
                </Box>
                {[StaffRole.notelMaster, StaffRole.clientMaster].includes(
                  EnumUtils.mapToEnum(StaffRole, staff?.role) ||
                    StaffRole.clientShopPartTime
                ) && (
                  <Box
                    display={"flex"}
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip
                      trigger="hover"
                      placement="bottom"
                      overlayStyle={{ zIndex: "1201" }}
                      overlay={
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          padding={2}
                        >
                          <Typography
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push("/payment")}
                          >
                            プラン変更
                          </Typography>
                        </Box>
                      }
                    >
                      <Settings />
                    </Tooltip>
                  </Box>
                )}
                <Box style={{ marginRight: "10px", marginLeft: "10px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/callHistory`}
                  >
                    着信履歴
                  </Button>
                </Box>
                {[
                  StaffRole.notelMaster,
                  StaffRole.notelCallCenterEmployee,
                  StaffRole.notelCallCenterPartTime,
                  StaffRole.clientMaster,
                  StaffRole.areaEmployee,
                  StaffRole.areaPartTime,
                ].includes(
                  EnumUtils.mapToEnum(StaffRole, staff?.role) ||
                    StaffRole.clientShopPartTime
                ) &&
                  compaines.length > 1 && (
                    <Box display="flex" alignItems="center" width="500px">
                      <InputLabel>ログイン企業切り替え</InputLabel>
                      <Autocomplete
                        fullWidth
                        style={{ marginRight: "10px" }}
                        getOptionSelected={(option, value) =>
                          option.companyId === value.companyId
                        }
                        options={compaines}
                        value={company || null}
                        getOptionLabel={(option: any) => option.name || ""}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" />
                        )}
                        noOptionsText="選択肢がありません"
                        onChange={(event, value) =>
                          history.push(
                            `/?companyId=${Number(value?.companyId)}`
                          )
                        }
                      />
                    </Box>
                  )}
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to={`/order`}
                >
                  新規受注
                </Button>
              </>
            )}
            <Hidden smDown>
              {isLoggedIn && (
                <Button
                  variant="contained"
                  className={classes.button}
                  color="default"
                  onClick={onClickLogoutButton}
                >
                  ログアウト
                </Button>
              )}
            </Hidden>
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
