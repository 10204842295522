import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import DriverApi from "api/DriverApi";
import DriverRes from "types/res/driver/DriverRes";
import UpdateDriverReq from "types/req/driver/UpdateDriverReq";
import CreateDriverReq from "types/req/driver/CreateDriverReq";
import DeleteDriverReq from "types/req/driver/DeleteDriverReq";

const actionCreator = actionCreatorFactory();
const addDriverAction = actionCreator.async<
  CreateDriverReq,
  DriverRes,
  { code: number; description: string }
>("ADD_DRIVER");

export const addDriver =
  (companyId: number, req: CreateDriverReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addDriverAction.started({ ...req }));

    try {
      const driver = new CreateDriverReq({ ...req });
      const result = await DriverApi.create(companyId, driver);
      dispatch(
        addDriverAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addDriverAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertDriverAction = actionCreator.async<
  CreateDriverReq[],
  DriverRes[],
  { code: number; description: string }
>("BULK_INSERT_DRIVER");
export const bulkInsertDriver =
  (companyId: number, req: CreateDriverReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertDriverAction.started({ ...req }));

    try {
      const result = await DriverApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertDriverAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertDriverAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchDriverAction = actionCreator.async<
  {},
  DriverRes[],
  { code: number; description: string }
>("FETCH_DRIVER");

export const fetchDriver =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchDriverAction.started([]));

    try {
      const result = await DriverApi.findAll(companyId);
      dispatch(
        fetchDriverAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchDriverAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateDriverAction = actionCreator.async<
  UpdateDriverReq,
  DriverRes,
  { code: number; description: string }
>("UPDATE_DRIVER");

export const updateDriver =
  (companyId: number, req: UpdateDriverReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateDriverAction.started({ ...req }));

    try {
      const updateDriver = new UpdateDriverReq({ ...req });
      const result = await DriverApi.update(
        companyId,
        req.driverId,
        updateDriver
      );
      dispatch(
        updateDriverAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateDriverAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteDriverAction = actionCreator.async<
  DeleteDriverReq,
  {},
  { code: number; description: string }
>("DELETE_DRIVER");

export const deleteDriver =
  (companyId: number, req: DeleteDriverReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteDriverAction.started({ ...req }));

    try {
      const driver = new DeleteDriverReq({ ...req });
      const result = await DriverApi.delete(companyId, driver);
      dispatch(
        deleteDriverAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteDriverAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const driverActions = {
  fetchDriverAction,
  addDriverAction,
  updateDriverAction,
  deleteDriverAction,
  bulkInsertDriverAction,
};

export default driverActions;
