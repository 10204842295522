import RequestUtils from "utils/RequestUtils";
import FindSalesVolumeDataReq from "types/req/saleVolume/FindSaleVolumeDataReq";

export default class GuestAnalyticsSalesVolumeApi {
  private constructor() {}

  static async findData(companyId: number, data: FindSalesVolumeDataReq) {
    return await RequestUtils.postPlain(
      `/guestAnalyticsSalesVolume/findData/${companyId}`,
      data
    );
  }
}
