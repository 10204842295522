import { IsNotEmpty } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";

export default class DeleteGuestCategoryReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly guestCategoryId: number;

  constructor(arg: { guestCategoryId: number }) {
    arg = arg || {};
    this.guestCategoryId = arg.guestCategoryId;
  }
}
