import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  InputLabel,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { fetchShops } from "redux/actions/shop";
import GuestGenreType from "types/enum/GuestGenreType";
import GuestAnalyticsGroupType from "types/enum/GuestAnalyticsGroupType";
import { makeStyles } from "@material-ui/core/styles";
import GuestAnalyticsApi from "api/GuestAnalyticsApi";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GuestRes from "types/res/guest/GuestRes";
import GuestApi from "api/GuestApi";
import EnumUtils from "utils/EnumUtils";

const GuestAnalyticsDetail = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const shops = useSelector((state) => state.shop);

  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectGenre, setSelectGenre] = useState<string[]>([
    ...Object.keys(GuestGenreType),
    "すべて",
  ]);
  const [searchTel, setSearchTel] = useState("");
  const [selectGroup, setSelectGroup] = useState<string[]>([
    ...Object.keys(GuestAnalyticsGroupType),
    "すべて",
  ]);
  const [selectGuest, setSelectGuest] = useState<GuestRes | null>();
  const [guestSelectOpen, setGuestSelectOpen] = useState(false);
  const [options, setOptions] = useState<GuestRes[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isGuestLoading, setIsGuestLoading] = useState(false);
  const [guestAnalytics, setGuestAnalytics] = useState<{
    [key: string]: {
      genre: string;
      group: string;
      orderCount: number;
      amount: number;
      shopName: string;
      awayDays: number;
    };
  }>({});
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  useEffect(() => {
    dispatch(fetchShops(companyId));
  }, []);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  const onClickGuestSearch = async () => {
    setGuestSelectOpen(true);
    setIsGuestLoading(true);
    const result = await GuestApi.search(companyId, { tel: searchTel });
    setOptions(result);
    setIsGuestLoading(false);
  };

  const onClickSearch = async () => {
    if (!selectGuest?.guestId) {
      alert("顧客を選択してください");
      return;
    }
    setIsLoading(true);
    const result = await GuestAnalyticsApi.findDetail(
      companyId,
      selectGuest.guestId,
      DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      selectShops.filter((s) => s !== "すべて").join(","),
      selectGenre.filter((s) => s !== "すべて").join(","),
      selectGroup.filter((s) => s !== "すべて").join(",")
    );
    setGuestAnalytics(result);
    setIsLoading(false);
  };
  const totalOrder = Object.values(guestAnalytics);

  return (
    <Box display="flex" flexDirection={"column"}>
      <Box margin={2} display="flex" alignItems="center">
        <TextField
          type="date"
          label="終了日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) =>
            setEndDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            )
          }
        />
        <Box>
          <InputLabel id="select-multiple-">店舗</InputLabel>
          <Select
            multiple
            value={selectShops}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectShops((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length === shops.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= shops.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-shop" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return shops
                  .filter((shop) =>
                    (selected as string[]).includes(String(shop.shopId))
                  )
                  .map((shop) => shop.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectShops.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {shops.map((shop) => (
              <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                <Checkbox
                  checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                />
                <ListItemText primary={shop.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <TextField
          label={"電話番号検索"}
          style={{ margin: "10px", width: "200px" }}
          value={searchTel}
          onChange={(event) => {
            event.persist();
            setSearchTel(event.target.value);
          }}
        />
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={onClickGuestSearch}
        >
          顧客検索
        </Button>
        <Autocomplete
          id="search-guest"
          size="small"
          options={options}
          getOptionLabel={(option) =>
            option.name ? `${option.name}(${option.tel})` : ""
          }
          onChange={(event: any, newValue: GuestRes | null) => {
            setSelectGuest(newValue);
          }}
          style={{ width: 300, margin: 10 }}
          loading={isGuestLoading}
          value={selectGuest || null}
          open={guestSelectOpen}
          onOpen={() => setGuestSelectOpen(true)}
          onClose={() => setGuestSelectOpen(false)}
          renderInput={(params) => <TextField {...params} label={"顧客選択"} />}
          noOptionsText={"電話番号で検索してください。"}
          renderOption={(option) => (
            <div>{option.name ? `${option.name}(${option.tel})` : ""}</div>
          )}
        />
        <Box>
          <InputLabel id="select-multiple-guest-genre">現役・離脱</InputLabel>
          <Select
            multiple
            value={selectGenre}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGenre((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [...Object.keys(GuestGenreType), "すべて"];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(GuestGenreType).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [...Object.keys(GuestGenreType), "すべて"];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(GuestGenreType).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-genre" />}
            style={{ margin: "10px", width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.keys(GuestGenreType)
                  .filter((name) => (selected as string[]).includes(name))
                  .map((key) => (GuestGenreType as any)[key])
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGenre.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(GuestGenreType).map(([key, name]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox checked={selectGenre.indexOf(String(key)) !== -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-guest-genre">顧客グループ</InputLabel>
          <Select
            multiple
            value={selectGroup}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGroup((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [...Object.keys(GuestAnalyticsGroupType), "すべて"];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(GuestAnalyticsGroupType).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [...Object.keys(GuestAnalyticsGroupType), "すべて"];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(GuestAnalyticsGroupType).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-genre" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.keys(GuestAnalyticsGroupType)
                  .filter((name) => (selected as string[]).includes(name))
                  .map((key) => (GuestAnalyticsGroupType as any)[key])
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGroup.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(GuestAnalyticsGroupType).map(([key, name]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox checked={selectGroup.indexOf(String(key)) !== -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={onClickSearch}
        >
          分析
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>顧客名</TableCell>
            <TableCell>顧客電話番号</TableCell>
            <TableCell>年月</TableCell>
            <TableCell>現役・離脱</TableCell>
            <TableCell>顧客グループ</TableCell>
            <TableCell>利用回数</TableCell>
            <TableCell>利用金額</TableCell>
            <TableCell>平均単価</TableCell>
            <TableCell>利用店舗</TableCell>
            <TableCell>離脱期間</TableCell>
          </TableRow>
        </TableHead>
        {isLoading ? (
          <CircularProgress />
        ) : Object.keys(guestAnalytics).length ? (
          <TableBody>
            {Object.entries(guestAnalytics).map(([yearMonth, data]) => (
              <TableRow key={yearMonth}>
                <TableCell>{selectGuest?.name || ""}</TableCell>
                <TableCell>{selectGuest?.tel || ""}</TableCell>
                <TableCell>
                  {DateTime.fromFormat(
                    yearMonth,
                    FORMAT_TYPE.YEAR_MONTH
                  ).toFormat(FORMAT_TYPE.YEAR_MONTH_JP)}
                </TableCell>
                <TableCell>
                  {EnumUtils.mapToEnum(GuestGenreType, data.genre) || "-"}
                </TableCell>
                <TableCell>
                  {EnumUtils.mapToEnum(GuestAnalyticsGroupType, data.group) ||
                    "-"}
                </TableCell>
                <TableCell>{data.orderCount}回</TableCell>
                <TableCell>¥{data.amount}</TableCell>
                <TableCell>
                  ¥{Math.round(data.amount / data.orderCount) || 0}
                </TableCell>
                <TableCell>{data.shopName}</TableCell>
                <TableCell>{data.awayDays}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>{selectGuest?.name || ""}</TableCell>
              <TableCell>{selectGuest?.tel || ""}</TableCell>
              <TableCell>合計</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              <TableCell>
                {totalOrder.reduce((sum, order) => sum + order.orderCount, 0)}回
              </TableCell>
              <TableCell>
                ¥{totalOrder.reduce((sum, order) => sum + order.amount, 0)}
              </TableCell>
              <TableCell>
                ¥
                {Math.round(
                  totalOrder.reduce((sum, order) => sum + order.amount, 0) /
                    totalOrder.reduce((sum, order) => sum + order.orderCount, 0)
                ) || 0}
              </TableCell>
              <TableCell>-</TableCell>
              <TableCell>
                {Math.round(
                  totalOrder.reduce((sum, order) => sum + order.awayDays, 0) /
                    12
                ) || 0}
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <></>
        )}
      </Table>
      {!Object.keys(guestAnalytics).length ? (
        <Box display="flex" justifyContent={"center"} marginTop={2}>
          <Typography>
            データがありません。条件を変えて再度検索してください。
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
export default GuestAnalyticsDetail;
