import { DataValidationError } from "constants/DataValidationError";
import {
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";

export default class UpdateAdditionalTimeReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  additionalTimeId!: number;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  totalFee!: number;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  shopFee!: number;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  castFee!: number;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  time!: number;

  @MaxLength(Spec.maxLength.additionalTime.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.additionalTime.name,
    },
  })
  name!: string;

  @IsOptional()
  @IsBoolean()
  isGuestShow!: boolean;

  @IsArray()
  shops!: number[];

  @IsArray()
  notelClasses!: number[];

  constructor(arg: {
    additionalTimeId: number;
    totalFee: number;
    shopFee: number;
    castFee: number;
    time: number;
    name: string;
    isGuestShow: boolean;
    shops: number[];
    notelClasses: number[];
  }) {
    arg = arg || {};
    this.additionalTimeId = arg.additionalTimeId;
    this.totalFee = arg.totalFee;
    this.shopFee = arg.shopFee;
    this.castFee = arg.castFee;
    this.time = arg.time;
    this.name = arg.name;
    this.isGuestShow = arg.isGuestShow;
    this.shops = arg.shops;
    this.notelClasses = arg.notelClasses;
  }
}
