import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
type Props = {
  formData: any;
  onChange(key: string): any;
};
const OrderPoint: React.FC<Props> = ({ formData, onChange }) => {
  const shops = useSelector((state) => state.shop);
  const guestCategories = useSelector((state) => state.guestCategory);
  useEffect(() => {
    if (!formData.totalFee || !formData.shopId) {
      onChange("addAutoOrderPoint")(0);
      return;
    }
    const shop = shops.find((shop) => shop.shopId === formData.shopId);
    const guestCategory = guestCategories.find(
      (gc) => gc.guestCategoryId === formData?.guestCategoryId
    );
    if (!shop || !guestCategory?.pointGrantFlag) {
      onChange("addAutoOrderPoint")(0);
      return;
    }
    onChange("addAutoOrderPoint")(
      Math.round(formData.totalFee * (shop.pointRate / 100))
    );
  }, [
    formData.totalFee,
    formData?.shopId,
    formData?.guestCategoryId,
    shops,
    guestCategories,
  ]);
  return (
    <>
      <Box display="flex" flexDirection="column" marginTop={2}>
        <Typography variant="h5" component="h5">
          ポイント
        </Typography>
        <Box display="flex">
          <TextField
            label="自動付与ポイント"
            size="small"
            type="number"
            variant="outlined"
            value={formData?.addAutoOrderPoint || ""}
            style={{ marginBottom: 10 }}
            disabled
          />
          <TextField
            label="手動付与ポイント"
            type="number"
            size="small"
            variant="outlined"
            value={formData?.addManualOrderPoint || ""}
            style={{ marginBottom: 10 }}
            onChange={(event) =>
              onChange("addManualOrderPoint")(Number(event.target.value))
            }
          />
        </Box>
        <Box display="flex">
          <TextField
            label="ポイント備考"
            type="text"
            size="small"
            variant="outlined"
            value={formData?.pointRemark || ""}
            style={{ marginBottom: 10 }}
            onChange={(event) => onChange("pointRemark")(event.target.value)}
          />
        </Box>
      </Box>
    </>
  );
};

export default OrderPoint;
