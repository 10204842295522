import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "../../components/CommonTable";
import {
  addHotel,
  bulkInsertHotel,
  deleteHotel,
  fetchHotel,
  updateHotel,
} from "redux/actions/hotel";
import HotelRes from "../../types/res/hotel/HotelRes";
import { FormType } from "components/FormField";
import { fetchArea } from "redux/actions/area";
import CreateHotelReq from "../../types/req/hotel/CreateHotelReq";
import UpdateHotelReq from "../../types/req/hotel/UpdateHotelReq";
import { Box, Button } from "@material-ui/core";
import CsvImport from "components/CsvImport";
import { CsvFieldType } from "utils/CsvUtils";

const headers: { key: keyof HotelRes; label: string }[] = [
  { key: "name", label: "名前" },
  { key: "kana", label: "読みがな" },
  { key: "address", label: "住所" },
  { key: "tel", label: "電話番号" },
  { key: "totalFee", label: "ホテル料金" },
  { key: "shopFee", label: "店舗料金" },
  { key: "castFee", label: "キャスト料金" },
  { key: "isIncludeOrderPrice", label: "予約料金に含む" },
  { key: "memo", label: "備考" },
  { key: "sort", label: "並び順" },
  { key: "isGuestShow", label: "顧客モバイル表示" },
  { key: "areas", label: "紐付けエリア" },
];

const Hotel: React.FC = () => {
  const dispatch = useDispatch();

  const companyId = useSelector((state) => state.account.staff.companyId);
  const areas = useSelector((state) => state.area);
  const hotels = useSelector((state) => state.hotel);
  const [openCsvImport, setOpenCsvImport] = useState(false);

  useEffect(() => {
    dispatch(fetchArea(companyId));
    dispatch(fetchHotel(companyId));
  }, [companyId, dispatch]);

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "読みがな",
      key: "kana",
      type: FormType.Text,
    },
    {
      label: "住所",
      key: "address",
      type: FormType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
    {
      label: "ホテル料金",
      key: "totalFee",
      type: FormType.Number,
    },
    {
      label: "店舗料金",
      key: "shopFee",
      type: FormType.Number,
    },
    {
      label: "キャスト料金",
      key: "castFee",
      type: FormType.Number,
    },
    {
      label: "予約料金に含む",
      key: "isIncludeOrderPrice",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "含まない" },
        { id: "1", name: "含む" },
      ],
    },
    {
      label: "備考",
      key: "memo",
      type: FormType.Text,
    },
    {
      label: "並び順",
      key: "sort",
      type: FormType.Number,
    },
    {
      label: "顧客モバイル表示",
      key: "isGuestShow",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "非表示" },
        { id: "1", name: "表示" },
      ],
    },
    {
      label: "紐付けエリア",
      key: "areas",
      type: FormType.AutoCompleteMultiOptions,
      options: areas?.map((s) => ({ id: s.areaId, name: s.name })) || [],
      value: (val: any) => val.map((a: any) => a.areaId),
    },
  ];

  const csvHeader = [
    {
      label: "名前",
      key: "name",
      type: CsvFieldType.Text,
    },
    {
      label: "読みがな",
      key: "kana",
      type: CsvFieldType.Text,
    },
    {
      label: "住所",
      key: "address",
      type: CsvFieldType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: CsvFieldType.Text,
    },
    {
      label: "ホテル料金",
      key: "totalFee",
      type: CsvFieldType.Number,
    },
    {
      label: "店舗料金",
      key: "shopFee",
      type: CsvFieldType.Number,
    },
    {
      label: "キャスト料金",
      key: "castFee",
      type: CsvFieldType.Number,
    },
    {
      label: "予約料金に含む",
      key: "isIncludeOrderPrice",
      type: CsvFieldType.Boolean,
    },
    {
      label: "備考",
      key: "memo",
      type: CsvFieldType.Text,
    },
    {
      label: "並び順",
      key: "sort",
      type: CsvFieldType.Number,
    },
    {
      label: "顧客モバイル表示",
      key: "isGuestShow",
      type: CsvFieldType.Boolean,
    },

    {
      label: "紐付けエリア",
      key: "areas",
      type: CsvFieldType.Array,
      relation: areas,
      relationKey: "name",
      relationId: "areaId",
    },
  ];

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginTop={1}
        marginRight={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenCsvImport((prev) => !prev)}
          style={{ marginLeft: "30px" }}
        >
          csvインポート
        </Button>
      </Box>
      {openCsvImport && (
        <CsvImport
          templatePath={"/csv/hotel.csv"}
          templateName={"hotel_template.csv"}
          csvHeader={csvHeader}
          addType={CreateHotelReq}
          addFunc={(formData) => bulkInsertHotel(companyId, formData)}
        />
      )}
      <HotelTable
        title={"ホテルリスト"}
        formId="hotelForm"
        forms={forms}
        rows={headers}
        data={hotels}
        addType={CreateHotelReq}
        updateType={UpdateHotelReq}
        addFunc={(formData) => addHotel(companyId, formData)}
        updateFunc={(formData) => updateHotel(companyId, formData)}
        deleteFunc={(item: any) =>
          deleteHotel(companyId, {
            hotelId: item.hotelId,
          })
        }
        values={[
          (s) => s.name,
          (s) => s.kana,
          (s) => s.address || "未設定",
          (s) => s.tel || "未設定",
          (s) => s.totalFee || "未設定",
          (s) => s.shopFee || "未設定",
          (s) => s.castFee || "未設定",
          (s) => (s.isIncludeOrderPrice ? "込み" : "なし"),
          (s) => s.memo || "",
          (s) => s.sort || "未設定",
          (s) => (s.isGuestShow ? "表示" : "非表示"),
          (s) => s.areas?.map((area) => area.name).join(",") || "該当なし",
        ]}
      />
    </>
  );
};

const HotelTable = styled<
  React.FC<CommonTableProps<HotelRes, CreateHotelReq, UpdateHotelReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default Hotel;
