import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty } from "class-validator";

export default class DeleteMediumReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly mediumId: number;

  constructor(arg: { mediumId: number }) {
    arg = arg || {};
    this.mediumId = arg.mediumId;
  }
}
