import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShopRes from "types/res/shop/ShopRes";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import {
  addCosplay,
  bulkInsertCosplay,
  deleteCosplay,
  fetchCosplay,
  updateCosplay,
} from "redux/actions/cosplay";
import CosplayRes from "types/res/cosplay/CosplayRes";
import { fetchShops } from "redux/actions/shop";
import { FormType } from "components/FormField";
import CreateCosplayReq from "types/req/cosplay/CreateCosplayReq";
import UpdateCosplayReq from "types/req/cosplay/UpdateCosplayReq";
import { Box, Button } from "@material-ui/core";
import CsvImport from "components/CsvImport";
import { CsvFieldType } from "utils/CsvUtils";

const headers: { key: keyof CosplayRes; label: string }[] = [
  { key: "name", label: "名前" },
  { key: "totalFee", label: "料金" },
  { key: "shopFee", label: "店舗料金" },
  { key: "castFee", label: "キャスト料金" },
  { key: "sort", label: "並び順" },
  { key: "isGuestShow", label: "顧客モバイル表示" },
  { key: "shops", label: "適用店舗" },
];

const Cosplay: React.FC = () => {
  const dispatch = useDispatch();

  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const cosplay = useSelector((state) => state.cosplay);
  const [openCsvImport, setOpenCsvImport] = useState(false);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchCosplay(companyId));
  }, [companyId, dispatch]);

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "料金",
      key: "totalFee",
      type: FormType.Number,
    },
    {
      label: "店舗料金",
      key: "shopFee",
      type: FormType.Number,
    },
    {
      label: "キャスト料金",
      key: "castFee",
      type: FormType.Number,
    },
    {
      label: "並び順",
      key: "sort",
      type: FormType.Number,
    },
    {
      label: "顧客モバイル表示",
      key: "isGuestShow",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "非表示" },
        { id: "1", name: "表示" },
      ],
    },
    {
      label: "店舗",
      key: "shops",
      type: FormType.MultiOptions,
      options: shops.map((s) => ({ id: Number(s.shopId), name: s.name })),
      value: (val: any) => val.map((s: any) => s.shopId),
    },
  ];

  const csvHeader = [
    {
      label: "名前",
      key: "name",
      type: CsvFieldType.Text,
    },
    {
      label: "料金※数字のみ",
      key: "totalFee",
      type: CsvFieldType.Number,
    },
    {
      label: "店舗料金※数字のみ",
      key: "shopFee",
      type: CsvFieldType.Number,
    },
    {
      label: "キャスト料金※数字のみ",
      key: "castFee",
      type: CsvFieldType.Number,
    },
    {
      label: "並び順",
      key: "sort",
      type: CsvFieldType.Number,
    },
    {
      label: "顧客モバイル表示",
      key: "isGuestShow",
      type: CsvFieldType.Boolean,
    },
    {
      label: "店舗",
      key: "shops",
      type: CsvFieldType.Array,
      relation: shops,
      relationKey: "name",
      relationId: "shopId",
    },
  ];

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginTop={1}
        marginRight={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenCsvImport((prev) => !prev)}
          style={{ marginLeft: "30px" }}
        >
          csvインポート
        </Button>
      </Box>
      {openCsvImport && (
        <CsvImport
          templatePath={"/csv/cosplay.csv"}
          templateName={"cosplay_template.csv"}
          csvHeader={csvHeader}
          addType={CreateCosplayReq}
          addFunc={(formData) => bulkInsertCosplay(companyId, formData)}
        />
      )}
      <CosplayTable
        title={"コスプレ"}
        formId="cosplayForm"
        forms={forms}
        rows={headers}
        data={cosplay}
        addFunc={(formData) => addCosplay(companyId, formData)}
        updateFunc={(formData) => updateCosplay(companyId, formData)}
        deleteFunc={(item: any) =>
          deleteCosplay(companyId, {
            cosplayId: item.cosplayId,
          })
        }
        addType={CreateCosplayReq}
        updateType={UpdateCosplayReq}
        values={[
          (s) => s.name,
          (s) => s.totalFee,
          (s) => s.shopFee,
          (s) => s.castFee,
          (s) => s.sort || "未設定",
          (s) => (s.isGuestShow ? "表示" : "非表示"),
          (s) =>
            s.shops
              .map(
                (shopRes: ShopRes) =>
                  shops.find((shop) => shop.shopId === shopRes.shopId)?.name
              )
              .join(",") || "所属なし",
        ]}
      />
    </>
  );
};

const CosplayTable = styled<
  React.FC<CommonTableProps<CosplayRes, CreateCosplayReq, UpdateCosplayReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default Cosplay;
