import RequestUtils from "utils/RequestUtils";
import StaffTaskRes from "types/res/staffTask/StaffTaskRes";
import CreateStaffTaskReq from "types/req/staffTask/CreateStaffTaskReq";
import UpdateStaffTaskReq from "types/req/staffTask/UpdateStaffTaskReq";
import DeleteStaffTaskReq from "types/req/staffTask/DeleteStaffTaskReq";

export default class StaffTaskApi {
  private constructor() {}

  static async findAll(companyId: number): Promise<StaffTaskRes[]> {
    return await RequestUtils.getArray(
      StaffTaskRes,
      `/staffTask/findAll/${companyId}`
    );
  }

  static async create(companyId: number, data: CreateStaffTaskReq) {
    return await RequestUtils.post(
      StaffTaskRes,
      `/staffTask/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    data: UpdateStaffTaskReq
  ): Promise<StaffTaskRes> {
    return await RequestUtils.post(
      StaffTaskRes,
      `/staffTask/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteStaffTaskReq) {
    return await RequestUtils.postVoid(`/staffTask/delete/${companyId}`, data);
  }
}
