import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty } from "class-validator";

export default class CreateCallHistoryReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  tel!: string;

  constructor(arg: { tel: string }) {
    arg = arg || {};
    this.tel = arg.tel;
  }
}
