import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "components/Modal";
import { Button, CircularProgress, Container } from "@material-ui/core";
import useModal from "hooks/useModal";
import Typography from "@material-ui/core/Typography";

const BulkDeleteModal = ({
  bulkDeleteFunc,
  bulkDeleteHook,
}: {
  bulkDeleteFunc: (a: any) => any;
  bulkDeleteHook?: () => any;
}) => {
  const [bulkDeleteModal, setBulkDeleteShow] = useModal("bulkDeleteData");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const onClickBulkDeleteButton = async () => {
    setLoading(true);
    await dispatch(bulkDeleteFunc(bulkDeleteModal.meta?.item));
    setLoading(false);
    setBulkDeleteShow(false);
    if (bulkDeleteHook) {
      bulkDeleteHook();
    }
  };
  return (
    <Modal show={bulkDeleteModal.show} setShow={setBulkDeleteShow} title="削除">
      <Container>
        <Typography>本当に一括削除しますか？</Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          onClick={onClickBulkDeleteButton}
        >
          {loading ? <CircularProgress color="inherit" size={24} /> : "決定"}
        </Button>
      </Container>
    </Modal>
  );
};

export default BulkDeleteModal;
