import Modal from "components/Modal";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useModal from "hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import OrderRes from "../../../types/res/order/OrderRes";
import UpdateOrderReq from "../../../types/req/order/UpdateOrderReq";
import { updateOrder } from "redux/actions/order";
import ForwardIcon from "@material-ui/icons/Forward";
import OptionRes from "types/res/option/OptionRes";
import DiscountRes from "types/res/discount/DiscountRes";
import BookEmailBody from "pages/Order/components/BookEmailBody";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { DateTime } from "luxon";
type Props = {
  setStateOrders: any;
  orders: OrderRes[];
};
const DriverScheduleOnItemMove: React.FC<Props> = ({
  setStateOrders,
  orders,
}) => {
  const [driverScheduleOnItemMoveModal, setDriverScheduleOnItemMoveShow] =
    useModal("driverScheduleOnItemMove");
  const driverShifts = useSelector((state) => state.driverShift);
  const companyId = useSelector((state) => state.account.staff.companyId);
  const [loading, setLoading] = useState(false);
  const [selectDriverId, setSelectDriverId] = useState<number>();
  const [oldOrder, setOldOrder] = useState<OrderRes>();
  const [duration, setDuration] = useState<number>();
  const [sendMailFlag, setSendEmailFlag] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!driverScheduleOnItemMoveModal?.meta?.order) return;
    const driverObject: any = {};
    if (driverScheduleOnItemMoveModal?.meta?.driverType === "return") {
      setSelectDriverId(
        driverScheduleOnItemMoveModal.meta.order.returnDriverId
      );
      setDuration(driverScheduleOnItemMoveModal.meta.order.returnDuration);
      driverObject.returnDriver = driverScheduleOnItemMoveModal.meta.driver;
    }
    if (driverScheduleOnItemMoveModal?.meta?.driverType === "outward") {
      setSelectDriverId(
        driverScheduleOnItemMoveModal.meta.order.outwardDriverId
      );
      setDuration(driverScheduleOnItemMoveModal.meta.order.outwardDuration);
      driverObject.outwardDriver = driverScheduleOnItemMoveModal.meta.driver;
    }
    const setOrder = orders.find(
      (order) =>
        order.orderId === driverScheduleOnItemMoveModal.meta.order.orderId
    );
    setOldOrder({ ...setOrder, ...driverObject });
  }, [driverScheduleOnItemMoveModal?.meta]);

  useEffect(() => {
    if (driverScheduleOnItemMoveModal.show) return;
    setStateOrders(orders);
  }, [driverScheduleOnItemMoveModal?.show]);

  const onClickSubmit = async () => {
    const order = {
      ...driverScheduleOnItemMoveModal?.meta?.order,
      ...oldOrder,
    };
    if (!order) return;
    order.options =
      order.options?.map((option: OptionRes) => option.optionId) || [];
    order.discounts =
      order.discounts?.map((discount: DiscountRes) => discount.discountId) ||
      [];
    if (driverScheduleOnItemMoveModal?.meta?.driverType === "return") {
      order.returnDriverId = selectDriverId;
      order.returnDuration = duration;
      order.sendMailReturnDriver = sendMailFlag;
    }
    if (driverScheduleOnItemMoveModal?.meta?.driverType === "outward") {
      order.outwardDriverId = selectDriverId;
      order.outwardDuration = duration;
      order.sendMailOutwardDriver = sendMailFlag;
    }
    await dispatch(updateOrder(companyId, new UpdateOrderReq(order)));
    setDriverScheduleOnItemMoveShow(false);
  };

  const defaultDuration = (order?: OrderRes) => {
    if (!order) return 30;
    if (order.departureTime && order.planInTime) {
      if (
        DateTime.fromJSDate(order.planInTime).diff(
          DateTime.fromJSDate(order.departureTime),
          "minutes"
        ).minutes > 5
      ) {
        return DateTime.fromJSDate(order.planInTime).diff(
          DateTime.fromJSDate(order.departureTime),
          "minutes"
        ).minutes;
      } else {
        return 5;
      }
    } else {
      return 30;
    }
  };

  const onChangeEmail = (key: string) => (value: string) => {
    setOldOrder((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const order = driverScheduleOnItemMoveModal?.meta?.order;
  if (!order) return null;
  return (
    <>
      <Modal
        show={driverScheduleOnItemMoveModal.show}
        setShow={setDriverScheduleOnItemMoveShow}
        title={`送迎変更`}
      >
        <Container>
          <Box display="flex" justifyContent="center">
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">受注情報</Typography>
                <Typography variant="body1">
                  店舗名：{order?.shop?.name || "未設定"}{" "}
                </Typography>
                <Typography variant="body1">
                  顧客名：{order?.guest?.name || "未設定"}
                </Typography>
                <Typography variant="body1">
                  顧客電話番号：{order?.guest?.tel || "未設定"}
                </Typography>
                <Typography variant="body1">
                  源氏名：{order?.castName?.name || "未設定"}
                </Typography>
                <Typography variant="body1">
                  合計時間：
                  {order?.totalTime ? `${order.totalTime}分` : "未設定"}
                </Typography>
                <Typography variant="body1">
                  エリア：
                  {order?.area?.name || "未設定"}
                </Typography>
                <Typography variant="body1">
                  ホテル：
                  {order?.hotel?.name || "未設定"}
                </Typography>
                <Typography variant="body1">
                  住所：
                  {order?.orderAddress || "未設定"}
                </Typography>
                <Typography variant="body1">
                  住所2：
                  {order?.orderAddress2 || "未設定"}
                </Typography>
                <Typography variant="body1">
                  出発時間：
                  {order?.departureTime
                    ? DateTimeUtils.toFormatAsLocalTimezone(
                        order?.departureTime,
                        FORMAT_TYPE.TIME
                      )
                    : "未設定"}
                </Typography>
                <Typography variant="body1">
                  予定IN：
                  {order?.planInTime
                    ? DateTimeUtils.toFormatAsLocalTimezone(
                        order.planInTime,
                        FORMAT_TYPE.TIME
                      )
                    : "未設定"}
                </Typography>
                <Typography variant="body1">
                  予定OUT：
                  {order?.planOutTime
                    ? DateTimeUtils.toFormatAsLocalTimezone(
                        order.planOutTime,
                        FORMAT_TYPE.TIME
                      )
                    : "未設定"}
                </Typography>
                <Typography variant="body1">
                  実IN：
                  {order?.actualInTime
                    ? DateTimeUtils.toFormatAsLocalTimezone(
                        order.actualInTime,
                        FORMAT_TYPE.TIME
                      )
                    : "未設定"}
                </Typography>
                <Typography variant="body1">
                  実OUT：
                  {order?.actualEndTime
                    ? DateTimeUtils.toFormatAsLocalTimezone(
                        order.actualEndTime,
                        FORMAT_TYPE.TIME
                      )
                    : "未設定"}
                </Typography>
              </Box>
              <Box display="flex" marginTop={2}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1">変更前</Typography>
                  <TextField
                    label="ドライバー"
                    select
                    value={
                      driverScheduleOnItemMoveModal?.meta?.driverType ===
                      "return"
                        ? oldOrder?.returnDriverId
                        : oldOrder?.outwardDriverId
                    }
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    disabled={true}
                    onChange={(event) => {
                      setSelectDriverId(Number(event.target.value));
                    }}
                  >
                    {driverShifts.map((driverShift) => (
                      <MenuItem
                        key={driverShift.driverId}
                        value={driverShift.driverId}
                      >
                        {driverShift?.driver?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="所要時間(分)"
                    type="number"
                    value={
                      driverScheduleOnItemMoveModal?.meta?.driverType ===
                      "return"
                        ? oldOrder?.returnDuration || defaultDuration(oldOrder)
                        : oldOrder?.outwardDuration || defaultDuration(oldOrder)
                    }
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    disabled={true}
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginX={3}
                >
                  <ForwardIcon />
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1">変更後</Typography>
                  <TextField
                    label="ドライバー"
                    select
                    value={selectDriverId || ""}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    onChange={(event) => {
                      setSelectDriverId(Number(event.target.value));
                    }}
                  >
                    {driverShifts.map((driverShift) => (
                      <MenuItem
                        key={driverShift.driverId}
                        value={driverShift.driverId}
                      >
                        {driverShift?.driver?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="所要時間(分)"
                    type="number"
                    value={duration ? duration.toString() : ""}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    onChange={(event) =>
                      setDuration(Number(event.target.value))
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sendMailFlag}
                        onChange={(event) =>
                          setSendEmailFlag(event.target.checked)
                        }
                      />
                    }
                    label="メール送信"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            style={{ margin: "10px 0" }}
            justifyContent="space-evenly"
          >
            <Button
              variant="contained"
              color="default"
              disabled={loading}
              onClick={() => setDriverScheduleOnItemMoveShow(false)}
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "いいえ"
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={onClickSubmit}
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "はい"
              )}
            </Button>
          </Box>
        </Container>
      </Modal>
      {oldOrder && (
        <Box style={{ display: "none" }}>
          <BookEmailBody
            formData={{
              ...oldOrder,
              options: oldOrder?.options?.map((option) => option.optionId),
              discounts: oldOrder?.discounts?.map(
                (discount) => discount.discountId
              ),
            }}
            onChange={(key) => onChangeEmail(key)}
          />
        </Box>
      )}
    </>
  );
};
export default DriverScheduleOnItemMove;
