import { reducerWithInitialState } from "typescript-fsa-reducers";
import requestGuestActions from "redux/actions/requestGuest";
import RequestGuestRes from "types/res/requestGuest/RequestGuestRes";
export type RequestGuestState = RequestGuestRes[];

const initialState = [] as RequestGuestState;

const requestGuestReducer = reducerWithInitialState(initialState)
  .case(requestGuestActions.fetchRequestGuestsAction.done, (state, payload) => {
    return payload.result;
  })
  .case(
    requestGuestActions.approveRequestGuestAction.done,
    (state, payload) => {
      return payload.result;
    }
  )
  .case(requestGuestActions.deleteRequestGuestAction.done, (state, payload) => {
    return payload.result;
  });

export default requestGuestReducer;
