import RequestUtils from "utils/RequestUtils";
import GuestCategoryRes from "types/res/guestCategory/GuestCategoryRes";
import CreateGuestCategoryReq from "types/req/guestCategory/CreateGuestCategoryReq";
import UpdateGuestCategoryReq from "types/req/guestCategory/UpdateGuestCategoryReq";
import DeleteGuestCategoryReq from "types/req/guestCategory/DeleteGuestCategoryReq";

export default class GuestCategoryApi {
  private constructor() {}

  static async findAll(companyId: number): Promise<GuestCategoryRes[]> {
    return await RequestUtils.getArray(
      GuestCategoryRes,
      `/guestCategory/findAll/${companyId}`
    );
  }

  static async simpleFindAll(companyId: number): Promise<GuestCategoryRes[]> {
    return await RequestUtils.getArray(
      GuestCategoryRes,
      `/guestCategory/simpleFindAll/${companyId}`
    );
  }

  static async create(companyId: number, data: CreateGuestCategoryReq) {
    return await RequestUtils.post(
      GuestCategoryRes,
      `/guestCategory/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    guestCategoryId: number,
    data: UpdateGuestCategoryReq
  ): Promise<GuestCategoryRes> {
    return await RequestUtils.post(
      GuestCategoryRes,
      `/guestCategory/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteGuestCategoryReq) {
    return await RequestUtils.postVoid(
      `/guestCategory/delete/${companyId}`,
      data
    );
  }
}
