import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import CsvUtils, { CsvFieldType } from "utils/CsvUtils";
import { CSVReader } from "react-papaparse";
import React, { useEffect, useState } from "react";
import { ValidationError } from "class-validator";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CsvImportTable from "components/CsvImportTable";
import { Alert, AlertTitle } from "@material-ui/lab";
import GuestApi from "api/GuestApi";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";

type Props<T> = {
  templatePath: string;
  templateName: string;
  csvHeader: {
    key: string;
    label: string;
    type: CsvFieldType;
    relation?: any[];
    relationKey?: string;
    relationId?: string;
  }[];
  addType: T;
  addFunc: (a: any) => any;
  isLoading?: boolean;
  templateDownload?: () => void;
};
// eslint-disable-next-line @typescript-eslint/ban-types
const CsvImport = <T extends {}>({
  templatePath,
  templateName,
  csvHeader,
  addType,
  addFunc,
  isLoading,
  templateDownload,
}: Props<T>) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  const [csvReset, setCsvReset] = useState(false);
  const [csvData, setCsvData] = useState<
    { [key: string]: string | number | Date }[]
  >([]);
  const [bulkInsertData, setBulkInsertData] = useState<
    (typeof addType | ValidationError[])[]
  >([]);
  const [bulkInsertError, setBulkInsertError] = useState<{
    [x: number]: ValidationError[];
  }>({});
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOnDrop = async (data: any) => {
    setRowData(data);
  };

  const bulkInsert = async () => {
    if (!bulkInsertData.length) return;
    if (!bulkInsertData.every((data) => !Array.isArray(data))) return;
    setLoading(true);
    await dispatch(addFunc(bulkInsertData));
    setCsvReset(true);
    setCsvData([]);
    setBulkInsertData([]);
    setBulkInsertError({});
    setLoading(false);
  };

  useEffect(() => {
    if (!rowData.length) return;
    (async () => {
      let tel = undefined;
      if (templateName === "guest_template.csv") {
        const list: any = await GuestApi.checkDuplicateNumber(
          companyId,
          rowData
            .filter((d: any) => d.data.filter(Boolean).length)
            .map((d: any) => CsvUtils.transformTel(String(d.data[2])))
            .join(",")
        );
        tel = list.map((t: any) => t.tel);
      }
      const { bulkInsertReq, displayDataArray } =
        await CsvUtils.createBulkInsertData(
          csvHeader,
          rowData,
          addType,
          tel,
          DateTime.fromFormat(changeDateTime, "HH:mm:ss").hour
        );
      const errors: { [x: number]: ValidationError[] } = {};
      bulkInsertReq.forEach((data: { [key: string]: any }, index) => {
        if (Object.keys(data).includes("guestGuestCategoryShops")) {
          Object.entries(data).forEach(([key, value]) => {
            if (
              key.includes("guestGuestCategoryShop") &&
              value?.guestCategoryId &&
              value?.shopId
            ) {
              bulkInsertReq[index]["guestGuestCategoryShops"] = [
                ...(bulkInsertReq[index]["guestGuestCategoryShops"] || []),
                {
                  ...value,
                },
              ];
            }
          });
        }
        if (Array.isArray(data)) {
          errors[index] = data;
        }
      });
      setBulkInsertError(errors);
      setBulkInsertData(bulkInsertReq);
      setCsvData(displayDataArray);
    })();
  }, [rowData]);

  const csvTemplateDownload = () => {
    if (templateDownload) {
      templateDownload();
    } else {
      CsvUtils.templateDownload(templatePath, templateName);
    }
  };

  return (
    <Box>
      <Box
        padding={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={() => csvTemplateDownload()}
          style={{ height: "50px" }}
        >
          <Box display="flex" flexDirection="column">
            <Typography>CSVテンプレート</Typography>
            <Typography>ダウンロード</Typography>
          </Box>
        </Button>
        {isLoading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <CSVReader
              isReset={csvReset}
              onDrop={handleOnDrop}
              onError={CsvUtils.handleError}
              style={{}}
              config={{}}
              addRemoveButton
              onRemoveFile={() => {
                setCsvData([]);
                setCsvReset(true);
              }}
            >
              <Box display="flex" flexDirection="column">
                <Typography>CSVファイル</Typography>
                <Typography>アップロード</Typography>
              </Box>
            </CSVReader>
          </Box>
        )}
      </Box>
      {csvData.length > 0 && (
        <Box
          padding={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CsvImportTable
            csvHeader={csvHeader}
            csvData={csvData}
            bulkInsertError={bulkInsertError}
          />
          {Object.keys(bulkInsertError).length === 0 ? (
            <Box display="flex" alignItems="center">
              <Typography>{csvData.length}件を登録しますか？</Typography>
              <Button
                onClick={() => {
                  setCsvData([]);
                  setCsvReset(true);
                }}
                variant="contained"
                className={classes.button}
                color="default"
                disabled={loading}
              >
                キャンセル
              </Button>
              <Button
                onClick={bulkInsert}
                disabled={Object.keys(bulkInsertError).length > 0 || loading}
                variant="contained"
                className={classes.button}
                color="primary"
              >
                登録
              </Button>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column">
              <Typography>
                エラーがあります。csvファイルを修正して再アップしてください。
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                style={{ maxHeight: "200px", overflowY: "scroll" }}
              >
                {Object.entries(bulkInsertError).flatMap(([index, errors]) =>
                  errors.flatMap((error) => (
                    <Box display="flex" alignItems="center">
                      <Box
                        style={{
                          backgroundColor: "#ff8888",
                          width: "10px",
                          height: "10px",
                          marginRight: "5px",
                        }}
                      />
                      <Typography>
                        {Number(index) + 1}行目の
                        {csvHeader.find(
                          (header) => header.key === error.property
                        )?.label || ""}
                        {error.property === "tel" &&
                        error?.value === "重複しています。"
                          ? "が重複しています。"
                          : "を修正してください"}
                      </Typography>
                    </Box>
                  ))
                )}
              </Box>
              <Button
                onClick={() => {
                  setCsvData([]);
                  setBulkInsertError({});
                  setCsvReset(true);
                }}
                variant="contained"
                className={classes.button}
                color="default"
              >
                データクリア
              </Button>
            </Box>
          )}
        </Box>
      )}
      {loading && (
        <Alert severity="info">
          <AlertTitle>登録中です。そのままお待ち下さい。</AlertTitle>
        </Alert>
      )}
    </Box>
  );
};
export default CsvImport;
