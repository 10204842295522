import { Expose, Type } from "class-transformer";
import CastNameRes from "../castName/CastNameRes";
import CourseRes from "../course/CourseRes";
import OrderRes from "../order/OrderRes";

export default class NotelClassRes {
  @Expose() readonly notelClassId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly kana!: string;
  @Type(() => CastNameRes)
  @Expose()
  readonly castNames!: CastNameRes[];
  @Type(() => CourseRes)
  @Expose()
  readonly courses!: CourseRes[];
  @Type(() => OrderRes)
  @Expose()
  readonly orders!: OrderRes[];
}
