import { Action, Dispatch } from "redux";
import AccountingApi from "api/AccountingApi";
import actionCreatorFactory from "typescript-fsa";
import AccountingRes from "types/res/accounting/AccountingRes";
const actionCreator = actionCreatorFactory();

const fetchAccountingAction = actionCreator.async<
  {},
  AccountingRes,
  { code: number; description: string }
>("FETCH_ACCOUNTING");

export const fetchAccounting =
  (companyId: number, startDate: string, endDate: string, shopIds: string) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchAccountingAction.started({}));

    try {
      const result = await AccountingApi.findAll(
        companyId,
        startDate,
        endDate,
        shopIds
      );
      dispatch(
        fetchAccountingAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchAccountingAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const accountingActions = {
  fetchAccountingAction,
};

export default accountingActions;
