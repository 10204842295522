import { DataValidationError } from "constants/DataValidationError";
import { IsBoolean, IsNotEmpty } from "class-validator";

export default class UpdateSettingBookEmailReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  settingBookEmailId!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isOrderDateEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isOrderCreatedByEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isOrderUpdatedByEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isGuestCategoryEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isGuestNameEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isGuestTelEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isGuestMemoEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isShopNameEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isCastNameEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isCastDesignateCountEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isCastClassNameEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isCastDesignateNameEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isCourseNameEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isAdditionalTimeEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isTotalTimeEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isDiscountNameEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isOptionNameEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isCosplayNameEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isHotelEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isAreaEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isOrderAddressEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isOrderDepartureTimeEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isOrderPlanInTimeEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isOrderActualInTimeEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isOrderActualEndTimeEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isOrderMemoEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isOrderPaymentTypeEnabled!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsBoolean()
  isCarInfoEnabled!: boolean;

  constructor(arg: {
    settingBookEmailId: number;
    isOrderDateEnabled: boolean;
    isOrderCreatedByEnabled: boolean;
    isOrderUpdatedByEnabled: boolean;
    isGuestCategoryEnabled: boolean;
    isGuestNameEnabled: boolean;
    isGuestTelEnabled: boolean;
    isGuestMemoEnabled: boolean;
    isShopNameEnabled: boolean;
    isCastNameEnabled: boolean;
    isCastDesignateCountEnabled: boolean;
    isCastClassNameEnabled: boolean;
    isCastDesignateNameEnabled: boolean;
    isCourseNameEnabled: boolean;
    isAdditionalTimeEnabled: boolean;
    isTotalTimeEnabled: boolean;
    isDiscountNameEnabled: boolean;
    isOptionNameEnabled: boolean;
    isCosplayNameEnabled: boolean;
    isAreaEnabled: boolean;
    isHotelEnabled: boolean;
    isOrderAddressEnabled: boolean;
    isOrderDepartureTimeEnabled: boolean;
    isOrderPlanInTimeEnabled: boolean;
    isOrderActualInTimeEnabled: boolean;
    isOrderActualEndTimeEnabled: boolean;
    isOrderMemoEnabled: boolean;
    isOrderPaymentTypeEnabled: boolean;
    isCarInfoEnabled: boolean;
  }) {
    arg = arg || {};
    this.settingBookEmailId = arg.settingBookEmailId;
    this.isOrderDateEnabled = arg.isOrderDateEnabled;
    this.isOrderCreatedByEnabled = arg.isOrderCreatedByEnabled;
    this.isOrderUpdatedByEnabled = arg.isOrderUpdatedByEnabled;
    this.isGuestCategoryEnabled = arg.isGuestCategoryEnabled;
    this.isGuestNameEnabled = arg.isGuestNameEnabled;
    this.isGuestTelEnabled = arg.isGuestTelEnabled;
    this.isGuestMemoEnabled = arg.isGuestMemoEnabled;
    this.isShopNameEnabled = arg.isShopNameEnabled;
    this.isCastNameEnabled = arg.isCastNameEnabled;
    this.isCastDesignateCountEnabled = arg.isCastDesignateCountEnabled;
    this.isCastClassNameEnabled = arg.isCastClassNameEnabled;
    this.isCastDesignateNameEnabled = arg.isCastDesignateNameEnabled;
    this.isCourseNameEnabled = arg.isCourseNameEnabled;
    this.isAdditionalTimeEnabled = arg.isAdditionalTimeEnabled;
    this.isTotalTimeEnabled = arg.isTotalTimeEnabled;
    this.isDiscountNameEnabled = arg.isDiscountNameEnabled;
    this.isOptionNameEnabled = arg.isOptionNameEnabled;
    this.isCosplayNameEnabled = arg.isCosplayNameEnabled;
    this.isAreaEnabled = arg.isAreaEnabled;
    this.isHotelEnabled = arg.isHotelEnabled;
    this.isOrderAddressEnabled = arg.isOrderAddressEnabled;
    this.isOrderDepartureTimeEnabled = arg.isOrderDepartureTimeEnabled;
    this.isOrderPlanInTimeEnabled = arg.isOrderPlanInTimeEnabled;
    this.isOrderActualInTimeEnabled = arg.isOrderActualInTimeEnabled;
    this.isOrderActualEndTimeEnabled = arg.isOrderActualEndTimeEnabled;
    this.isOrderMemoEnabled = arg.isOrderMemoEnabled;
    this.isOrderPaymentTypeEnabled = arg.isOrderPaymentTypeEnabled;
    this.isCarInfoEnabled = arg.isCarInfoEnabled;
  }
}
