import React, { useEffect, useState } from "react";
import {
  Box,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import DateTimeUtils, { FORMAT_TYPE } from "../../utils/DateTimeUtils";
import EnumUtils from "../../utils/EnumUtils";
import ShiftStatus from "../../types/enum/ShiftStatus";
import { CastReportType } from "types/enum/CastReportType";
import { useLocation } from "react-router-dom";
import { fetchStaff, updateStaff } from "redux/actions/staff";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ChangeLogApi from "api/ChangeLogApi";
import CastReportRes from "types/res/castReport/CastReportRes";
import CastShiftRes from "types/res/castShift/CastShiftRes";
import DriverShiftRes from "types/res/driverShift/DriverShiftRes";
import OrderRes from "types/res/order/OrderRes";
import ChangeLogRes from "types/res/changeLog/ChangeLogRes";
import StaffRes from "types/res/staff/StaffRes";
import StaffRole from "types/enum/StaffRole";

const Landing = () => {
  const dispatch = useDispatch();
  const queryString = new URLSearchParams(useLocation().search);
  const queryCompanyId = queryString.get("companyId");
  const companyId = useSelector((state) => state.account.staff.companyId);
  const accessToken = useSelector((state) => state.account.accessToken);
  const staff = useSelector((state) => state.account?.staff);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = changeDateTime
    ? DateTime.fromFormat(changeDateTime, "HH:mm:ss")
    : DateTime.fromFormat("00:00:00", "HH:mm:ss");
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ days: 1, hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [allChangeLogs, setAllChangeLogs] = useState<ChangeLogRes>();
  const [filteredChangeLog, setFilteredChangeLog] = useState<
    (CastShiftRes | CastReportRes | DriverShiftRes | OrderRes)[]
  >([]);
  const categories = {
    castShift: "キャストシフト",
    driverShift: "ドライバーシフト",
    castReport: "緊急報告",
    order: "受注情報",
  };
  const [selectCategories, setSetSelectCategories] = useState<string[]>([
    ...Object.keys(categories),
    "すべて",
  ]);
  const [selectUpdatedBy, setSelectUpdatedBy] = useState<string[]>([]);
  const [staffList, setStaffList] = useState<StaffRes[]>([]);
  useEffect(() => {
    dispatch(fetchStaff(companyId));
  }, [companyId]);

  useEffect(() => {
    const apiCall = async () => {
      const result = await ChangeLogApi.findAll(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)
      );
      setAllChangeLogs(result);
    };
    apiCall();
  }, [companyId, startDate, endDate]);

  useEffect(() => {
    if (!allChangeLogs) return;
    const result = allChangeLogs;
    let items: (CastReportRes | CastShiftRes | DriverShiftRes | OrderRes)[] =
      [];

    if (selectCategories.includes("castShift")) {
      items = [...result.castShift];
    }
    if (selectCategories.includes("driverShift")) {
      items = [...items, ...result.driverShift];
    }
    if (selectCategories.includes("castReport")) {
      items = [...items, ...result.castReport];
    }
    if (selectCategories.includes("order")) {
      items = [...items, ...result.orderInfo];
    }
    items = items.filter((item) => {
      if (item instanceof CastReportRes) {
        return selectUpdatedBy.includes("その他");
      } else {
        return selectUpdatedBy.includes(
          item.updatedById ? String(item.updatedById) : "その他"
        );
      }
    });
    items.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));
    setFilteredChangeLog(items);
  }, [allChangeLogs, selectUpdatedBy, selectCategories]);

  useEffect(() => {
    if (!allChangeLogs) return;
    let list: StaffRes[] = [];
    allChangeLogs.castShift?.forEach((cs) => {
      if (
        cs.updatedBy &&
        !list.find((l) => l.staffId === cs.updatedById) &&
        EnumUtils.mapToEnum(StaffRole, cs.updatedBy.role) !==
          StaffRole.notelMaster
      ) {
        list = [...list, cs.updatedBy];
      }
    });
    allChangeLogs.driverShift?.forEach((cs) => {
      if (
        cs.updatedBy &&
        !list.find((l) => l.staffId === cs.updatedById) &&
        EnumUtils.mapToEnum(StaffRole, cs.updatedBy.role) !==
          StaffRole.notelMaster
      ) {
        list = [...list, cs.updatedBy];
      }
    });
    allChangeLogs.orderInfo?.forEach((cs) => {
      if (
        cs.updatedBy &&
        !list.find((l) => l.staffId === cs.updatedById) &&
        EnumUtils.mapToEnum(StaffRole, cs.updatedBy.role) !==
          StaffRole.notelMaster
      ) {
        list = [...list, cs.updatedBy];
      }
    });
    setStaffList(list);
    setSelectUpdatedBy([
      ...[...list.map((staff) => String(staff.staffId)), "その他"],
      "すべて",
    ]);
  }, [allChangeLogs]);

  useEffect(() => {
    if (!queryCompanyId) return;
    dispatch(
      updateStaff(
        companyId,
        { ...staff, companyId: Number(queryCompanyId) },
        accessToken
      )
    );
  }, [queryCompanyId]);

  return (
    <Box display="flex" flexDirection="column" padding={2}>
      <Box margin={2} display="flex" alignItems="center">
        <TextField
          type="date"
          label="開始日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) =>
            setStartDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            )
          }
        />
        <TextField
          type="date"
          label="終了日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) =>
            setEndDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            )
          }
        />
        <Box>
          <InputLabel id="select-multiple-category">カテゴリ</InputLabel>
          <Select
            multiple
            value={selectCategories}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSetSelectCategories((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...Object.keys(categories).map((key) => String(key)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(categories).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...Object.keys(categories).map((key) => String(key)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(categories).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-driver" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.entries(categories)
                  .filter(([key, _]) =>
                    (selected as string[]).includes(String(key))
                  )
                  .map(([_, value]) => value)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectCategories.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(categories).map(([key, value]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox
                  checked={selectCategories.indexOf(String(key)) !== -1}
                />
                <ListItemText primary={value} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box style={{ marginLeft: "10px" }}>
          <InputLabel id="select-multiple-updatedBy">更新者</InputLabel>
          <Select
            multiple
            value={selectUpdatedBy}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectUpdatedBy((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...[
                      ...staffList.map((staff) => String(staff.staffId)),
                      "すべて",
                    ],
                    "その他",
                  ];
                } else if (
                  (event.target.value as string[]).length ===
                    staffList.length + 1 &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...[
                      ...staffList.map((staff) => String(staff.staffId)),
                      "すべて",
                    ],
                    "その他",
                  ];
                } else if (
                  (event.target.value as string[]).length <=
                  staffList.length + 1
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-driver" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return staffList
                  .filter((staff) =>
                    (selected as string[]).includes(String(staff.staffId))
                  )
                  .map((staff) => staff.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectUpdatedBy.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>

            {staffList.map((staff) => (
              <MenuItem key={staff.staffId} value={String(staff.staffId)}>
                <Checkbox
                  checked={
                    selectUpdatedBy.indexOf(String(staff.staffId)) !== -1
                  }
                />
                <ListItemText primary={staff.name} />
              </MenuItem>
            ))}
            <MenuItem key={"その他"} value={"その他"}>
              <Checkbox
                name="other"
                checked={selectUpdatedBy.indexOf("その他") > -1}
              />
              <ListItemText primary={"その他"} />
            </MenuItem>
          </Select>
        </Box>
      </Box>
      <Typography>更新履歴</Typography>
      {!filteredChangeLog?.length ? (
        <Box display="flex" flexDirection="column" padding={2}>
          <Typography>更新内容はありません</Typography>
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>更新時間</TableCell>
              <TableCell>更新者</TableCell>
              <TableCell>カテゴリ</TableCell>
              <TableCell>内容</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredChangeLog.map((changeLog, index) => {
              if (changeLog instanceof CastReportRes) {
                if (!changeLog.cast) return <></>;
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {DateTimeUtils.toFormatAsLocalTimezone(
                        DateTime.fromJSDate(changeLog.updatedAt).toJSDate(),
                        FORMAT_TYPE.YEAR_DATE_TIME
                      )}
                    </TableCell>
                    <TableCell>{changeLog.cast?.displayName}</TableCell>
                    <TableCell>トラブル報告</TableCell>
                    <TableCell>
                      {changeLog.cast.displayName}さんから 「
                      {EnumUtils.mapToEnum(CastReportType, changeLog.type)}
                      」と通知が来ています。
                    </TableCell>
                  </TableRow>
                );
              }
              if (changeLog instanceof CastShiftRes) {
                if (!changeLog.cast) return <></>;
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {DateTimeUtils.toFormatAsLocalTimezone(
                        DateTime.fromJSDate(changeLog.updatedAt).toJSDate(),
                        FORMAT_TYPE.YEAR_DATE_TIME
                      )}
                    </TableCell>
                    <TableCell>
                      {changeLog.updatedById !== null
                        ? changeLog.updatedBy?.name
                        : changeLog.cast.displayName}
                    </TableCell>
                    <TableCell>キャスト</TableCell>
                    <TableCell>
                      {changeLog.cast.displayName}
                      さん
                      <br />
                      予定シフト：
                      {DateTimeUtils.toFormatAsLocalTimezone(
                        DateTime.fromJSDate(changeLog.planWorkStart).toJSDate(),
                        FORMAT_TYPE.YEAR_DATE_TIME
                      )}
                      ~
                      {changeLog.planWorkEnd
                        ? DateTimeUtils.toFormatAsLocalTimezone(
                            DateTime.fromJSDate(
                              changeLog.planWorkEnd
                            ).toJSDate(),
                            FORMAT_TYPE.YEAR_DATE_TIME
                          )
                        : "未設定"}
                      <br />
                      ステータス：
                      {EnumUtils.mapToEnum(ShiftStatus, changeLog.status)}
                      <br />
                      メモ：
                      {changeLog.castMemo}
                    </TableCell>
                  </TableRow>
                );
              }
              if (changeLog instanceof DriverShiftRes) {
                if (!changeLog.driver) return <></>;
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {DateTimeUtils.toFormatAsLocalTimezone(
                        DateTime.fromJSDate(changeLog.updatedAt).toJSDate(),
                        FORMAT_TYPE.YEAR_DATE_TIME
                      )}
                    </TableCell>
                    <TableCell>
                      {changeLog.updatedById !== null
                        ? changeLog.updatedBy?.name
                        : changeLog.driver.name}
                    </TableCell>
                    <TableCell>ドライバー</TableCell>
                    <TableCell>
                      {changeLog.driver.name}
                      さん
                      <br />
                      予定シフト：
                      {DateTimeUtils.toFormatAsLocalTimezone(
                        DateTime.fromJSDate(changeLog.planWorkStart).toJSDate(),
                        FORMAT_TYPE.YEAR_DATE_TIME
                      )}
                      ~
                      {changeLog.planWorkEnd
                        ? DateTimeUtils.toFormatAsLocalTimezone(
                            DateTime.fromJSDate(
                              changeLog.planWorkEnd
                            ).toJSDate(),
                            FORMAT_TYPE.YEAR_DATE_TIME
                          )
                        : "未設定"}
                      <br />
                      ステータス：
                      {EnumUtils.mapToEnum(ShiftStatus, changeLog.status)}
                      <br />
                      メモ：
                      {changeLog.driverMemo}
                    </TableCell>
                  </TableRow>
                );
              }
              if (changeLog instanceof OrderRes) {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {DateTimeUtils.toFormatAsLocalTimezone(
                        DateTime.fromJSDate(changeLog.updatedAt).toJSDate(),
                        FORMAT_TYPE.YEAR_DATE_TIME
                      )}
                    </TableCell>
                    <TableCell>{changeLog.updatedBy?.name}</TableCell>
                    <TableCell>受注情報</TableCell>
                    <TableCell>
                      顧客名：{changeLog.guest?.name}
                      様
                      <br />
                      電話番号：{changeLog.guest?.tel}
                      <br />
                      キャスト名：{changeLog.castName?.name}さん
                      <br />
                      予IN：
                      {changeLog.planInTime
                        ? DateTime.fromJSDate(changeLog.planInTime).toFormat(
                            FORMAT_TYPE.YEAR_DATE_TIME
                          )
                        : ""}
                      <br />
                      実IN：
                      {changeLog.actualInTime
                        ? DateTime.fromJSDate(changeLog.actualInTime).toFormat(
                            FORMAT_TYPE.YEAR_DATE_TIME
                          )
                        : ""}
                      <br />
                      コース分数：
                      {changeLog.courseTime}分
                      <br />
                      <Link href={`/order/${changeLog.orderId}`}>
                        受注詳細へ
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default Landing;
