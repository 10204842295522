import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import SettingBookEmailApi from "api/SettingBookEmailApi";
import SettingBookEmailRes from "types/res/settingBookEmail/SettingBookEmailRes";
import UpdateSettingBookEmailReq from "types/req/settingBookEmail/UpdateSettingBookEmailReq";

const actionCreator = actionCreatorFactory();

const fetchSettingBookEmailAction = actionCreator.async<
  {},
  SettingBookEmailRes[],
  { code: number; description: string }
>("FETCH_SETTING_BOOK_EMAIL");

export const fetchSettingBookEmail =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchSettingBookEmailAction.started([]));

    try {
      const result = await SettingBookEmailApi.find(companyId);
      dispatch(
        fetchSettingBookEmailAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchSettingBookEmailAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateSettingBookEmailAction = actionCreator.async<
  UpdateSettingBookEmailReq,
  SettingBookEmailRes,
  { code: number; description: string }
>("UPDATE_SETTING_BOOK_EMAIL");

export const updateSettingBookEmail =
  (companyId: number, req: UpdateSettingBookEmailReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateSettingBookEmailAction.started({ ...req }));

    try {
      const updateSettingBookEmail = new UpdateSettingBookEmailReq({ ...req });
      const result = await SettingBookEmailApi.update(
        companyId,
        updateSettingBookEmail
      );
      dispatch(
        updateSettingBookEmailAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateSettingBookEmailAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const companyActions = {
  fetchSettingBookEmailAction,
  updateSettingBookEmailAction,
};

export default companyActions;
