import { reducerWithInitialState } from "typescript-fsa-reducers";
import nominationActions from "redux/actions/nomination";
import NominationRes from "types/res/nomination/NominationRes";
export type NominationState = NominationRes[];

const initialState = [] as NominationState;

const nominationReducer = reducerWithInitialState(initialState)
  .case(nominationActions.addNominationAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(nominationActions.updateNominationAction.done, (state, payload) => {
    return state.map((item) =>
      item.nominationId === payload.params.nominationId ? payload.result : item
    );
  })
  .case(nominationActions.deleteNominationAction.done, (state, payload) => {
    return state.filter(
      (item) => item.nominationId !== payload.params.nominationId
    );
  })
  .case(nominationActions.fetchNominationAction.done, (state, payload) => {
    return [...payload.result];
  });
export default nominationReducer;
