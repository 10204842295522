import React, { useEffect, useState } from "react";
import CreateJournalReq from "../../types/req/journal/CreateJournalReq";
import { Box, MenuItem, TextField, Typography } from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import ShopRes from "../../types/res/shop/ShopRes";

type Props = {
  journal: CreateJournalReq;
  onJournalReq: any;
  shops: ShopRes[];
};

const AddJournalRow: React.FC<Props> = ({ journal, onJournalReq, shops }) => {
  const [stateJournal, setStateJournal] = useState({} as CreateJournalReq);
  const addJournalReq =
    (key: string) =>
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.persist();
      setStateJournal((prev: any) => ({
        ...prev,
        [key]:
          key === "date"
            ? DateTime.fromFormat(
                event.target.value,
                FORMAT_TYPE.YEAR_DAY
              ).toJSDate()
            : key === "amount"
            ? Number(event.target.value)
            : event.target.value,
      }));
    };

  useEffect(() => {
    setStateJournal(journal);
  }, [journal]);
  return (
    <Box display="flex" marginBottom={3}>
      <Box padding={1} border="1px solid #000" width="150px">
        <TextField
          type="date"
          value={
            stateJournal?.date
              ? DateTime.fromJSDate(stateJournal.date).toFormat(
                  FORMAT_TYPE.YEAR_DAY
                )
              : ""
          }
          onChange={onJournalReq("date")}
        />
      </Box>
      <Box padding={1} border="1px solid #000" width="150px">
        <TextField
          select
          fullWidth
          value={stateJournal.shopId || ""}
          onChange={addJournalReq("shopId")}
          onBlur={onJournalReq("shopId")}
        >
          <option value={""} />
          {shops.map((shop) => (
            <MenuItem key={shop.shopId} value={shop.shopId}>
              {shop.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box padding={1} border="1px solid #000" width="150px">
        <TextField
          type="text"
          value={stateJournal.genre}
          onChange={addJournalReq("genre")}
          onBlur={onJournalReq("genre")}
        />
      </Box>
      <Box padding={1} border="1px solid #000" width="150px">
        <TextField
          type="text"
          value={stateJournal.itemName}
          onChange={addJournalReq("itemName")}
          onBlur={onJournalReq("itemName")}
        />
      </Box>
      <Box padding={1} border="1px solid #000" width="150px">
        <Box display="flex" alignItems="flex-end">
          <TextField
            type="text"
            value={stateJournal.amount}
            onChange={addJournalReq("amount")}
            onBlur={onJournalReq("amount")}
          />
          <Typography>円</Typography>
        </Box>
      </Box>
      <Box padding={1} border="1px solid #000" width="50px" />
    </Box>
  );
};
export default AddJournalRow;
