import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import MediumPostPeriodRes from "types/res/mediumPostPeriod/MediumPostPeriodRes";

export default class MediumRes {
  @Expose() readonly mediumId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly totalFee!: number;
  @Expose() readonly shopFee!: number;
  @Expose() readonly castFee!: number;
  @Expose() readonly monthlyCost!: number;
  @Expose() readonly sort!: number | null;
  @Expose() readonly isGuestShow!: boolean;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
  @Type(() => MediumPostPeriodRes)
  @Expose()
  readonly mediumPostPeriods!: MediumPostPeriodRes[];
}
