import RequestUtils from "utils/RequestUtils";
import querystring from "querystring";
import AccountingRes from "types/res/accounting/AccountingRes";

export default class AccountingApi {
  private constructor() {}

  static async findAll(
    companyId: number,
    startDate: string,
    endDate: string,
    shopIds: string
  ) {
    const queryString = querystring.stringify({
      shopIds,
      startDate,
      endDate,
    });
    return await RequestUtils.get(
      AccountingRes,
      `/accounting/findAll/${companyId}?${queryString}`
    );
  }
}
