import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { fetchShops } from "redux/actions/shop";
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  Tooltip,
  BarChart,
  Bar,
  Line,
  YAxis,
} from "recharts";
import { fetchMedium, updateMedium } from "redux/actions/medium";
import GuestAnalyticsMediaLtvApi from "api/GuestAnalyticsMediaLtvApi";
import MathUtils from "utils/MathUtils";
import MediumPostPeriodApi from "api/MediumPostPeriodApi";
import { Delete } from "@material-ui/icons";
type LtvData = {
  totalPrice: number;
  totalCastFee: number;
  totalCount: number;
  guestCount: number;
  activeGuestCount: number;
  newGuestCount: number;
};
const GuestAnalyticsMediaLtv = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const shops = useSelector((state) => state.shop);
  const media = useSelector((state) => state.medium);
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [ltvData, setLtvData] = useState<{
    [month: string]: {
      [mediaId: number]: LtvData;
    };
  }>();
  const [adCostObject, setAdCostObject] = useState<{ [key: number]: number }>(
    {}
  );
  const [postPeriods, setPostPeriods] =
    useState<{ [mediaId: number]: { startDate: string; endDate: string } }>();
  const [isPostPeriod, setIsPostPeriod] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const colorList = [
    "#801A86",
    "#8FE388",
    "#264653",
    "#2a9d8f",
    "#e9c46a",
    "#f4a261",
    "#e63946",
    "#f1faee",
    "#9b2226",
    "#d9ed92",
  ];

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchMedium(companyId));
  }, []);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    setAdCostObject(
      media.reduce((target: { [key: number]: number }, key) => {
        target[key.mediumId] = key.monthlyCost;
        return target;
      }, {})
    );
    setPostPeriods(
      media.reduce((target: { [key: number]: any }, key) => {
        target[key.mediumId] = {};
        return target;
      }, {})
    );
  }, [media]);

  let totalSale = 0;
  let threeMonthSale = 0;
  let threeMonthGuestCount = 0;
  let totalGuestCount = 0;
  let totalGuestActiveCount = 0;
  let castTotalFee = 0;
  let newGuestCount = 0;
  if (ltvData) {
    Object.values(ltvData).forEach((data, index) =>
      Object.values(data).forEach((d) => {
        totalSale += d.totalPrice;
        totalGuestCount += d.totalCount;
        castTotalFee += d.totalCastFee;
        totalGuestActiveCount += d.activeGuestCount;
        newGuestCount += d.newGuestCount;
        if (Object.values(ltvData).length - index <= 3) {
          threeMonthSale += d.totalPrice;
          threeMonthGuestCount += d.totalCount;
        }
      })
    );
  }
  const sale90days = MathUtils.roundToTwo(
    threeMonthSale / threeMonthGuestCount
  );

  const revenue90days = MathUtils.roundToTwo(
    sale90days * (1 - castTotalFee / totalSale)
  );

  const totalWithDrawRate = MathUtils.roundToTwo(
    (totalGuestCount - totalGuestActiveCount) / totalGuestCount
  );
  const customerLifeTime = MathUtils.roundToTwo(1 / totalWithDrawRate);

  const adCostPerNewGuest = MathUtils.roundToTwo(
    Object.values(adCostObject).reduce((sum, adCost) => sum + adCost, 0) /
      newGuestCount
  );

  const withdrawalRate = (mediaData: LtvData[]) => {
    return (
      mediaData.reduce(
        (sum, data) => sum + (data.guestCount - data.activeGuestCount),
        0
      ) / mediaData.reduce((sum, data) => sum + data.guestCount, 0)
    );
  };

  const femaleSalaryRate = (mediaData: LtvData[]) => {
    const totalCastFee = mediaData.reduce(
      (sum, data) => sum + data.totalCastFee,
      0
    );
    const totalPrice = mediaData.reduce(
      (sum, data) => sum + data.totalPrice,
      0
    );
    return totalCastFee / totalPrice;
  };
  const ltv = (mediaData: LtvData[], mediaId: number) => {
    const threeMonthData = mediaData.slice(-3);
    const guestTotalSales = threeMonthData.reduce(
      (sum, data) => sum + data.totalPrice,
      0
    );
    const guestTotalCount = threeMonthData.reduce(
      (sum, data) => sum + data.totalCount,
      0
    );
    const guestNewCount = mediaData.reduce(
      (sum, data) => sum + data.newGuestCount,
      0
    );
    const sale90days = guestTotalSales / guestTotalCount;
    const customerLifeTime = 1 / withdrawalRate(mediaData);
    const revenue90days = sale90days * (1 - femaleSalaryRate(mediaData));
    const months = isPostPeriod
      ? media
          .find((medium) => medium.mediumId === mediaId)
          ?.mediumPostPeriods.reduce((sum, period) => {
            const monthDiff = DateTime.fromFormat(
              period?.endDate ||
                DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
              FORMAT_TYPE.YEAR_DAY
            ).diff(
              DateTime.fromFormat(
                period?.startDate ||
                  DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
                FORMAT_TYPE.YEAR_DAY
              ),
              "months"
            ).months;
            return sum + monthDiff;
          }, 0) || 0
      : 12;
    return (
      revenue90days * customerLifeTime -
      (adCostObject[mediaId] * months) / guestNewCount
    );
  };

  const revenue90daysByMedium = (mediaData: LtvData[]) => {
    if (!mediaData.length) return 0;
    return (
      (mediaData.reduce((sum, data) => sum + data.totalPrice, 0) /
        mediaData.reduce((sum, data) => sum + data.guestCount, 0)) *
      (1 - femaleSalaryRate(mediaData))
    );
  };

  const customerLifeTimeByMedium = (mediaData: LtvData[]) => {
    if (!mediaData.length) return 0;
    return MathUtils.roundToTwo(1 / withdrawalRate(mediaData));
  };

  const newGuestCountByMedium = (mediaData: LtvData[]) => {
    if (!mediaData.length) return 0;
    return mediaData.reduce((sum, data) => sum + data.newGuestCount, 0);
  };

  const optimalAdBudget = (mediaData: LtvData[], mediaId: number) => {
    const threeMonthData = [...mediaData.slice(-3)];
    const months = isPostPeriod
      ? media
          .find((medium) => medium.mediumId === mediaId)
          ?.mediumPostPeriods.reduce((sum, period) => {
            const monthDiff = DateTime.fromFormat(
              period?.endDate ||
                DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
              FORMAT_TYPE.YEAR_DAY
            ).diff(
              DateTime.fromFormat(
                period?.startDate ||
                  DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
                FORMAT_TYPE.YEAR_DAY
              ),
              "months"
            ).months;
            return sum + monthDiff;
          }, 0) || 0
      : 12;
    return isFinite(
      MathUtils.roundToTwo(
        (revenue90daysByMedium(threeMonthData) *
          customerLifeTimeByMedium(mediaData) *
          newGuestCountByMedium(mediaData)) /
          months
      )
    )
      ? MathUtils.roundToTwo(
          (revenue90daysByMedium(threeMonthData) *
            customerLifeTimeByMedium(mediaData) *
            newGuestCountByMedium(mediaData)) /
            months
        )
      : "∞";
  };

  const onClickPostPeriod = async (mediumId: number) => {
    if (!postPeriods?.[mediumId]?.startDate) {
      return alert("掲載期間は開始日を必ず入れてください");
    }
    await MediumPostPeriodApi.create(companyId, {
      mediumId,
      startDate: postPeriods?.[mediumId]?.startDate,
      endDate: postPeriods?.[mediumId]?.endDate,
    });
    await dispatch(fetchMedium(companyId));
  };

  const onClickDeleteMediaPostPeriod = async (mediumPostPeriodId: number) => {
    await MediumPostPeriodApi.delete(companyId, { mediumPostPeriodId });
    await dispatch(fetchMedium(companyId));
  };

  const onClickSearchData = async () => {
    setIsLoading(true);
    const result = await GuestAnalyticsMediaLtvApi.find(companyId, {
      endDate: DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      shopIds: selectShops.filter((shopId) => shopId !== "すべて").map(Number),
      isPostPeriod,
    });
    setLtvData(result);
    setIsLoading(false);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        margin={2}
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Box margin={2} display="flex" alignItems="center">
          <TextField
            type="date"
            label="終了日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
            onChange={(event) =>
              setEndDate(
                DateTime.fromISO(event.target.value as string).toJSDate()
              )
            }
          />
          <Box>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={selectShops}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectShops((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length === shops.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= shops.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectShops.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPostPeriod}
                  onChange={(event) => setIsPostPeriod(event.target.checked)}
                />
              }
              label={"掲載期間のみ"}
            />
          </Box>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => onClickSearchData()}
          >
            検索
          </Button>
        </Box>
        <Box display="flex">
          <Box marginX={2}>
            <Typography>
              LTV:
              {!isFinite(revenue90days * customerLifeTime - adCostPerNewGuest)
                ? "∞"
                : `${revenue90days * customerLifeTime - adCostPerNewGuest}円`}
            </Typography>
            <Typography>
              LTS:
              {!isFinite(sale90days * customerLifeTime - adCostPerNewGuest)
                ? "∞"
                : `${sale90days * customerLifeTime - adCostPerNewGuest}円`}
            </Typography>
            <Typography>
              顧客生涯期間:
              {!isFinite(customerLifeTime) ? "∞" : customerLifeTime}
            </Typography>
            <Typography>90日あたりの売上:{sale90days || 0}円</Typography>
            <Typography>90日あたりの収益:{revenue90days || 0}円</Typography>
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : !ltvData ? (
        <Box marginLeft={3}>
          <Typography>データがありません。検索してください。</Typography>
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent={"center"}>
            <Box display="flex" flexDirection="column">
              <Typography style={{ fontWeight: "bold", margin: "5px" }}>
                LTVと推移人数
              </Typography>
              <ComposedChart
                width={800}
                height={400}
                data={Object.entries(ltvData)
                  .sort(([a], [b]) => (a > b ? 1 : -1))
                  .flatMap(([month, monthData]) => {
                    let t = {};
                    Object.entries(monthData).forEach(([mediumId, data]) => {
                      const name = media.find(
                        (medium) => medium.mediumId === Number(mediumId)
                      )?.name;
                      if (!name) return;
                      t = {
                        ...t,
                        [`${name}LTV`]: MathUtils.roundToTwo(
                          (data.totalPrice / data.guestCount) *
                            (data.totalCount / data.guestCount) || 0
                        ),
                        [`${name}人数`]: data.guestCount,
                      };
                    });
                    return {
                      month,
                      ...t,
                    };
                  })}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" vertical={false} />
                <XAxis
                  dataKey="month"
                  interval={1}
                  tickLine={false}
                  axisLine={{ stroke: "#f5f5f5" }}
                />
                <Tooltip />
                {media.map((medium, index) => (
                  <>
                    <Bar
                      dataKey={`${medium.name}LTV`}
                      yAxisId="left"
                      stackId={"a"}
                      fill={colorList[Number(String(index).slice(-1))]}
                    />
                    <Line
                      dataKey={`${medium.name}人数`}
                      yAxisId="right"
                      type="monotone"
                      stroke={colorList[Number(String(index).slice(-1))]}
                    />
                  </>
                ))}
                <YAxis yAxisId="left" width={150} />
                <YAxis yAxisId="right" orientation="right" />
              </ComposedChart>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography style={{ fontWeight: "bold", margin: "5px" }}>
                月別総売上
              </Typography>
              <BarChart
                width={800}
                height={400}
                data={Object.entries(ltvData)
                  .sort(([a], [b]) => (a > b ? 1 : -1))
                  .flatMap(([month, monthData]) => {
                    let t = {};
                    Object.entries(monthData).forEach(([mediumId, data]) => {
                      const name = media.find(
                        (medium) => medium.mediumId === Number(mediumId)
                      )?.name;
                      if (!name) return;
                      t = {
                        ...t,
                        [`${name}`]: MathUtils.roundToTwo(data.totalPrice),
                      };
                    });
                    return {
                      month,
                      ...t,
                    };
                  })}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="month"
                    interval={1}
                    tickLine={false}
                    axisLine={{ stroke: "#f5f5f5" }}
                  />
                  <YAxis width={150} />
                  <Tooltip />
                  {media.map((medium, index) => (
                    <>
                      <Bar
                        dataKey={`${medium.name}`}
                        stackId={"a"}
                        fill={colorList[Number(String(index).slice(-1))]}
                      />
                    </>
                  ))}
                </>
              </BarChart>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" margin={2}>
            <Typography style={{ fontWeight: "bold", margin: "5px" }}>
              顧客分布表
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>媒体名</TableCell>
                  <TableCell>月額費用</TableCell>
                  <TableCell>掲載期間</TableCell>
                  <TableCell>最適広告予算</TableCell>
                  <TableCell>LTV</TableCell>
                  <TableCell>現役人数</TableCell>
                  <TableCell>離脱人数</TableCell>
                  <TableCell>離脱率</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {media.map((medium) => {
                  const mediaData = Object.values(ltvData)
                    .map((data) => data[medium.mediumId])
                    .filter(Boolean);
                  return (
                    <TableRow key={medium.mediumId}>
                      <TableCell>{medium.name}</TableCell>
                      <TableCell>
                        <TextField
                          value={adCostObject[medium.mediumId]}
                          onChange={(event) => {
                            event.persist();
                            if (event.target.value === null) return;
                            setAdCostObject((prev) => {
                              return {
                                ...prev,
                                [medium.mediumId]: Number(event.target.value),
                              };
                            });
                          }}
                          onBlur={(event) =>
                            dispatch(
                              updateMedium(companyId, {
                                ...medium,
                                shops: medium.shops.map((shop) => shop.shopId),
                                monthlyCost: Number(event.target.value),
                              })
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {medium.mediumPostPeriods?.map((period) => (
                          <Box
                            display={"flex"}
                            key={period.mediumPostPeriodId}
                            marginBottom={2}
                            alignItems={"center"}
                          >
                            <TextField type={"date"} value={period.startDate} />{" "}
                            ~
                            <TextField type={"date"} value={period.endDate} />
                            <IconButton
                              onClick={() =>
                                onClickDeleteMediaPostPeriod(
                                  period.mediumPostPeriodId
                                )
                              }
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        ))}
                        <Box display={"flex"} alignItems={"center"}>
                          <TextField
                            type={"date"}
                            value={
                              postPeriods?.[medium.mediumId]?.startDate || ""
                            }
                            onChange={(event) => {
                              event.persist();
                              setPostPeriods((prev) => {
                                return {
                                  ...prev,
                                  [medium.mediumId]: {
                                    startDate: event.target.value,
                                    endDate:
                                      prev?.[medium.mediumId]?.endDate || "",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          ~
                          <TextField
                            type={"date"}
                            value={
                              postPeriods?.[medium.mediumId]?.endDate || ""
                            }
                            onChange={(event) => {
                              event.persist();
                              setPostPeriods((prev) => {
                                return {
                                  ...prev,
                                  [medium.mediumId]: {
                                    endDate: event.target.value,
                                    startDate:
                                      prev?.[medium.mediumId]?.startDate || "",
                                  },
                                };
                              });
                            }}
                          />
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => onClickPostPeriod(medium.mediumId)}
                            style={{
                              marginLeft: "8px",
                            }}
                          >
                            保存
                          </Button>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {optimalAdBudget(mediaData, medium.mediumId)}
                      </TableCell>
                      <TableCell>
                        {isFinite(ltv(mediaData, medium.mediumId))
                          ? MathUtils.roundToTwo(
                              ltv(mediaData, medium.mediumId)
                            )
                          : "∞"}
                      </TableCell>
                      <TableCell>
                        {mediaData.reduce(
                          (sum, data) => sum + data.activeGuestCount,
                          0
                        )}
                      </TableCell>
                      <TableCell>
                        {mediaData.reduce(
                          (sum, data) =>
                            sum + (data.guestCount - data.activeGuestCount),
                          0
                        )}
                      </TableCell>
                      <TableCell>
                        {isFinite(withdrawalRate(mediaData))
                          ? MathUtils.roundToTwo(
                              withdrawalRate(mediaData) * 100
                            )
                          : "∞"}
                        %
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <Box display="flex" flexDirection="column" margin={2}>
            <Typography
              variant={"subtitle1"}
              style={{ fontWeight: "bold", margin: "5px" }}
            >
              LTV 年間データ
            </Typography>
            <Typography style={{ fontWeight: "bold", margin: "5px" }}>
              媒体別顧客月間累計購入金額
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>媒体名</TableCell>
                  <TableCell>年間累計</TableCell>
                  {Object.keys(ltvData)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((month) => (
                      <TableCell key={month}>{month}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {media.map((medium) => {
                  const mediaData = Object.values(ltvData)
                    .map((data) => data[medium.mediumId])
                    .filter(Boolean);
                  return (
                    <TableRow key={medium.mediumId}>
                      <TableCell>{medium.name}</TableCell>
                      <TableCell>
                        {mediaData.reduce(
                          (sum, data) => sum + data.totalPrice,
                          0
                        )}
                      </TableCell>
                      {Object.keys(ltvData)
                        .sort((a, b) => (a > b ? 1 : -1))
                        .map((month) => (
                          <TableCell key={`totalPrice_${month}`}>
                            {ltvData[month]?.[medium.mediumId]?.totalPrice || 0}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <Box display="flex" flexDirection="column" margin={2}>
            <Typography style={{ fontWeight: "bold", margin: "5px" }}>
              媒体別顧客月間平均購入金額
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>媒体名</TableCell>
                  <TableCell>年間累計</TableCell>
                  {Object.keys(ltvData)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((month) => (
                      <TableCell key={month}>{month}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {media.map((medium) => {
                  const mediaData = Object.values(ltvData)
                    .map((data) => data[medium.mediumId])
                    .filter(Boolean);
                  return (
                    <TableRow key={medium.mediumId}>
                      <TableCell>{medium.name}</TableCell>
                      <TableCell>
                        {MathUtils.roundToTwo(
                          mediaData.reduce(
                            (sum, data) =>
                              sum + data.totalPrice / data.guestCount,
                            0
                          )
                        ) || 0}
                      </TableCell>
                      {Object.keys(ltvData)
                        .sort((a, b) => (a > b ? 1 : -1))
                        .map((month) => (
                          <TableCell key={`averagePrice_${month}`}>
                            {MathUtils.roundToTwo(
                              (ltvData[month]?.[medium.mediumId]?.totalPrice ||
                                0) /
                                (ltvData[month]?.[medium.mediumId]
                                  ?.guestCount || 0) || 0
                            )}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <Box display="flex" flexDirection="column" margin={2}>
            <Typography style={{ fontWeight: "bold", margin: "5px" }}>
              媒体別顧客平均利用回数
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>媒体名</TableCell>
                  <TableCell>年間累計</TableCell>
                  {Object.keys(ltvData)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((month) => (
                      <TableCell key={month}>{month}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {media.map((medium) => {
                  const mediaData = Object.values(ltvData)
                    .map((data) => data[medium.mediumId])
                    .filter(Boolean);
                  return (
                    <TableRow key={medium.mediumId}>
                      <TableCell>{medium.name}</TableCell>
                      <TableCell>
                        {MathUtils.roundToTwo(
                          mediaData.reduce(
                            (sum, data) =>
                              sum + data.totalCount / data.guestCount,
                            0
                          )
                        ) || 0}
                      </TableCell>
                      {Object.keys(ltvData)
                        .sort((a, b) => (a > b ? 1 : -1))
                        .map((month) => (
                          <TableCell key={`averageCount_${month}`}>
                            {MathUtils.roundToTwo(
                              (ltvData[month]?.[medium.mediumId]?.totalCount ||
                                0) /
                                (ltvData[month]?.[medium.mediumId]
                                  ?.guestCount || 0) || 0
                            )}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <Box display="flex" flexDirection="column" margin={2}>
            <Typography style={{ fontWeight: "bold", margin: "5px" }}>
              媒体別顧客月間平均単価
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>媒体名</TableCell>
                  <TableCell>年間平均</TableCell>
                  {Object.keys(ltvData)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((month) => (
                      <TableCell key={month}>{month}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {media.map((medium) => {
                  const mediaData = Object.values(ltvData)
                    .map((data) => data[medium.mediumId])
                    .filter(Boolean);
                  return (
                    <TableRow key={medium.mediumId}>
                      <TableCell>{medium.name}</TableCell>
                      <TableCell>
                        {MathUtils.roundToTwo(
                          mediaData.reduce(
                            (sum, data) =>
                              sum + data.totalPrice / data.totalCount,
                            0
                          ) / 12
                        ) || 0}
                      </TableCell>
                      {Object.keys(ltvData)
                        .sort((a, b) => (a > b ? 1 : -1))
                        .map((month) => (
                          <TableCell key={`averagePrice_${month}`}>
                            {MathUtils.roundToTwo(
                              (ltvData[month]?.[medium.mediumId]?.totalPrice ||
                                0) /
                                (ltvData[month]?.[medium.mediumId]
                                  ?.totalCount || 0) || 0
                            )}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <Box display="flex" flexDirection="column" margin={2}>
            <Typography style={{ fontWeight: "bold", margin: "5px" }}>
              媒体別新規獲得顧客数
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>媒体名</TableCell>
                  <TableCell>年間平均</TableCell>
                  {Object.keys(ltvData)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((month) => (
                      <TableCell key={month}>{month}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {media.map((medium) => {
                  const mediaData = Object.values(ltvData)
                    .map((data) => data[medium.mediumId])
                    .filter(Boolean);
                  return (
                    <TableRow key={medium.mediumId}>
                      <TableCell>{medium.name}</TableCell>
                      <TableCell>
                        {MathUtils.roundToTwo(
                          mediaData.reduce(
                            (sum, data) => sum + data.newGuestCount,
                            0
                          ) / 12
                        ) || 0}
                      </TableCell>
                      {Object.keys(ltvData)
                        .sort((a, b) => (a > b ? 1 : -1))
                        .map((month) => (
                          <TableCell key={`newGuestCount_${month}`}>
                            {MathUtils.roundToTwo(
                              ltvData[month]?.[medium.mediumId]
                                ?.newGuestCount || 0
                            )}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </>
      )}
    </Box>
  );
};
export default GuestAnalyticsMediaLtv;
