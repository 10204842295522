import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import CallHistoryApi from "api/CallHistoryApi";
import CallHistoryRes from "types/res/callHistory/CallHistoryRes";
import UpdateCallHistoryReq from "types/req/callHistory/UpdateCallHistoryReq";
import CreateCallHistoryReq from "types/req/callHistory/CreateCallHistoryReq";
import HttpRequestError from "errors/HttpRequestError";

const actionCreator = actionCreatorFactory();
const addCallHistoryAction = actionCreator.async<
  CreateCallHistoryReq,
  CallHistoryRes,
  { code: number; description: string }
>("ADD_CALL_HISTORY");

export const addCallHistory =
  (companyId: number, req: CreateCallHistoryReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addCallHistoryAction.started({ ...req }));

    try {
      const callHistory = new CreateCallHistoryReq({ ...req });
      const result = await CallHistoryApi.create(companyId, callHistory);
      dispatch(
        addCallHistoryAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addCallHistoryAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCallHistoryAction = actionCreator.async<
  {},
  CallHistoryRes[],
  { code: number; description: string }
>("FETCH_CALL_HISTORY");

export const fetchCallHistory =
  (companyId: number, startDate?: string, endDate?: string) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCallHistoryAction.started([]));

    try {
      const result = await CallHistoryApi.findAll(
        companyId,
        startDate,
        endDate
      );
      dispatch(
        fetchCallHistoryAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      if (error instanceof HttpRequestError) {
        dispatch(
          fetchCallHistoryAction.failed({
            params: {},
            // @ts-ignore
            error: { code: error.code, description: error.description },
          })
        );
        return error;
      }
    }
  };

const updateCallHistoryAction = actionCreator.async<
  UpdateCallHistoryReq,
  CallHistoryRes,
  { code: number; description: string }
>("UPDATE_CALL_HISTORY");

export const updateCallHistory =
  (companyId: number, req: UpdateCallHistoryReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateCallHistoryAction.started({ ...req }));

    try {
      const updateCallHistory = new UpdateCallHistoryReq({ ...req });
      const result = await CallHistoryApi.update(companyId, updateCallHistory);
      dispatch(
        updateCallHistoryAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateCallHistoryAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const callHistoryActions = {
  fetchCallHistoryAction,
  addCallHistoryAction,
  updateCallHistoryAction,
};

export default callHistoryActions;
