import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import CastApi from "api/CastApi";
import CastRes from "types/res/cast/CastRes";
import UpdateCastReq from "types/req/cast/UpdateCastReq";
import CreateCastReq from "types/req/cast/CreateCastReq";
import DeleteCastReq from "types/req/cast/DeleteCastReq";
import FindCastReq from "types/req/cast/FindCastReq";

const actionCreator = actionCreatorFactory();
const addCastAction = actionCreator.async<
  CreateCastReq,
  CastRes,
  { code: number; description: string }
>("ADD_CAST");

export const addCast =
  (companyId: number, req: CreateCastReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addCastAction.started({ ...req }));

    try {
      const cast = new CreateCastReq({ ...req });
      const result = await CastApi.create(companyId, cast);
      dispatch(
        addCastAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addCastAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCastAction = actionCreator.async<
  {},
  CastRes[],
  { code: number; description: string }
>("FETCH_CAST");

export const fetchCast =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCastAction.started([]));

    try {
      const result = await CastApi.findAll(companyId);
      dispatch(
        fetchCastAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCastAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCastWithPaginationAction = actionCreator.async<
  {},
  CastRes[],
  { code: number; description: string }
>("FETCH_CAST_WITH_PAGINATION");

export const fetchCastWithPagination =
  (companyId: number, req: FindCastReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCastWithPaginationAction.started([]));

    try {
      const result = await CastApi.findAllWithPagination(companyId, req);
      dispatch(
        fetchCastWithPaginationAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCastWithPaginationAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchActualWorkCastListAction = actionCreator.async<
  {},
  CastRes[],
  { code: number; description: string }
>("FETCH_ACTUAL_WORK_CAST_LIST");

export const fetchActualWorkCastList =
  (
    companyId: number,
    startDate: string,
    endDate: string,
    shopIds: string,
    notelClassIds: string,
    castCategoryIds: string,
    enrolled: boolean,
    workingResult: boolean
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchActualWorkCastListAction.started([]));

    try {
      const result = await CastApi.findAllActualWorkCastList(
        companyId,
        startDate,
        endDate,
        shopIds,
        notelClassIds,
        castCategoryIds,
        enrolled,
        workingResult
      );
      dispatch(
        fetchActualWorkCastListAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchActualWorkCastListAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateCastAction = actionCreator.async<
  UpdateCastReq,
  CastRes,
  { code: number; description: string }
>("UPDATE_CAST");

export const updateCast =
  (companyId: number, req: UpdateCastReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateCastAction.started({ ...req }));

    try {
      const updateCast = new UpdateCastReq({ ...req });
      const result = await CastApi.update(companyId, req.castId, updateCast);
      dispatch(
        updateCastAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateCastAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteCastAction = actionCreator.async<
  DeleteCastReq,
  {
    // nothing
  },
  { code: number; description: string }
>("DELETE_CAST");

export const deleteCast =
  (companyId: number, req: DeleteCastReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteCastAction.started({ ...req }));

    try {
      const cast = new DeleteCastReq({ ...req });
      const result = await CastApi.delete(companyId, cast);
      dispatch(
        deleteCastAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteCastAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertCastAction = actionCreator.async<
  CreateCastReq[],
  CastRes[],
  { code: number; description: string }
>("BULK_INSERT_CAST");
export const bulkInsertCast =
  (companyId: number, req: CreateCastReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertCastAction.started({ ...req }));

    try {
      const result = await CastApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertCastAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertCastAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkDeleteCastAction = actionCreator.async<
  DeleteCastReq[],
  {},
  { code: number; description: string }
>("BULK_DELETE_CAST");

export const bulkDeleteCast =
  (companyId: number, req: DeleteCastReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkDeleteCastAction.started({ ...req }));

    try {
      const result = await CastApi.bulkDelete(companyId, req);
      dispatch(
        bulkDeleteCastAction.done({
          params: [...req],
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkDeleteCastAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const castActions = {
  fetchCastAction,
  fetchCastWithPaginationAction,
  fetchActualWorkCastListAction,
  addCastAction,
  updateCastAction,
  deleteCastAction,
  bulkInsertCastAction,
  bulkDeleteCastAction,
};

export default castActions;
