import { Expose, Type } from "class-transformer";
import TaskPriority from "types/enum/TaskPriority";
import TaskStatus from "types/enum/TaskStatus";
import StaffRes from "../staff/StaffRes";

export default class StaffTaskRes {
  @Expose() readonly staffTaskId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly summary!: string;
  @Type(() => StaffRes)
  @Expose()
  readonly registeredBy!: StaffRes;
  @Expose()
  readonly registeredById!: number;
  @Type(() => StaffRes)
  @Expose()
  readonly assignee!: StaffRes;
  @Expose()
  readonly assigneeId!: number;
  @Type(() => Date)
  @Expose()
  readonly deadline!: Date;
  @Expose() readonly priority!: TaskPriority;
  @Expose() readonly status!: TaskStatus;
}
