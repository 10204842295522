import React, { useEffect } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  approveRequestGuest,
  deleteRequestGuest,
  fetchRequestGuests,
} from "redux/actions/requestGuest";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";

const RequestGuest = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const requestGuests = useSelector((state) => state.requestGuest);

  useEffect(() => {
    dispatch(fetchRequestGuests(companyId));
  }, [companyId]);

  const onClickApprove = (requestGuestId: number) => {
    dispatch(approveRequestGuest(companyId, requestGuestId));
  };

  const onClickDelete = (requestGuestId: number) => {
    dispatch(deleteRequestGuest(companyId, requestGuestId));
  };

  return (
    <Box display={"flex"} flexDirection={"column"} padding={4}>
      <Typography variant={"h4"} style={{ margin: "16px 0" }}>
        登録申請顧客一覧
      </Typography>
      <Box display={"flex"}>
        {requestGuests.length === 0 && (
          <Typography>登録申請顧客はいません</Typography>
        )}
        {requestGuests.map((requestGuest) => (
          <Box
            display={"flex"}
            border={1}
            style={{ borderRadius: "10px", padding: "16px" }}
            key={requestGuest.requestGuestId}
            alignItems={"flex-end"}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                {DateTime.fromJSDate(requestGuest.createdAt).toFormat(
                  FORMAT_TYPE.DATE_TIME
                )}
              </Typography>
              <Typography style={{ marginTop: "8px" }}>
                名前：{requestGuest.name}
              </Typography>
              <Typography style={{ marginTop: "8px" }}>
                電話番号：{requestGuest.tel}
              </Typography>
              <Typography style={{ marginTop: "8px" }}>
                メールアドレス：{requestGuest.email}
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} marginLeft={2}>
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onClickDelete(requestGuest.requestGuestId)}
                >
                  削除
                </Button>
              </Box>
              <Box marginTop={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onClickApprove(requestGuest.requestGuestId)}
                >
                  承認
                </Button>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default RequestGuest;
