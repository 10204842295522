import RequestUtils from "utils/RequestUtils";
import CastRankingRes from "types/res/castRanking/CastRankingRes";
import querystring from "querystring";
import CastRankingAttendanceRes from "types/res/castRanking/CastRankingAttendanceRes";
import CastRankingEachDataRes from "types/res/castRanking/CastRankingEachDataRes";

export default class CastRankingApi {
  private constructor() {}

  static async findTotalData(
    companyId: number,
    startDate: string,
    endDate: string,
    shopIds: string,
    notelClassIds: string,
    castCategoryIds: string
  ): Promise<CastRankingRes> {
    const queryString = querystring.stringify({
      shopIds,
      notelClassIds,
      castCategoryIds,
    });
    return await RequestUtils.get(
      CastRankingRes,
      `/castRanking/findTotalData/${companyId}/${startDate}/${endDate}?${queryString}`
    );
  }

  static async findCastData(
    companyId: number,
    startDate: string,
    endDate: string,
    castIds: string
  ): Promise<CastRankingEachDataRes> {
    const queryString = querystring.stringify({
      castIds,
    });
    return await RequestUtils.get(
      CastRankingEachDataRes,
      `/castRanking/findCastData/${companyId}/${startDate}/${endDate}?${queryString}`
    );
  }

  static async findOne(
    companyId: number,
    startDate: string,
    endDate: string,
    castId?: number,
    castNameId?: number
  ) {
    const queryString = querystring.stringify({
      castId,
      castNameId,
    });
    return await RequestUtils.get(
      CastRankingRes,
      `/castRanking/findAll/${companyId}/${startDate}/${endDate}?${queryString}`
    );
  }

  static async findAttendanceData(
    companyId: number,
    startDate: string,
    endDate: string,
    shopIds: string,
    castId: string
  ): Promise<CastRankingAttendanceRes[]> {
    const queryString = querystring.stringify({
      shopIds,
      castId,
    });
    return await RequestUtils.getArray(
      CastRankingAttendanceRes,
      `/castRanking/findAttendanceData/${companyId}/${startDate}/${endDate}?${queryString}`
    );
  }

  static async findOrderData(
    companyId: number,
    startDate: string,
    endDate: string,
    shopIds: string,
    castId: string
  ) {
    const queryString = querystring.stringify({
      shopIds,
      castId,
    });
    return await RequestUtils.getPlain(
      `/castRanking/findOrderData/${companyId}/${startDate}/${endDate}?${queryString}`
    );
  }

  static async findRevenueData(
    companyId: number,
    startDate: string,
    endDate: string,
    shopIds: string,
    castId: string
  ) {
    const queryString = querystring.stringify({
      shopIds,
      castId,
    });
    return await RequestUtils.getPlain(
      `/castRanking/findRevenueData/${companyId}/${startDate}/${endDate}?${queryString}`
    );
  }
}
