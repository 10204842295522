import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import GuestApi from "api/GuestApi";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import GuestRes from "types/res/guest/GuestRes";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EnumUtils from "utils/EnumUtils";
import GuestAnalyticsGroupType from "types/enum/GuestAnalyticsGroupType";
import GuestGenreType from "types/enum/GuestGenreType";
import { Alert, AlertTitle } from "@material-ui/lab";
import UpdateGuestReq from "types/req/guest/UpdateGuestReq";
import CreateGuestReq from "types/req/guest/CreateGuestReq";
import { validate } from "class-validator";
import { addGuest, updateGuest } from "redux/actions/guest";
import GuestCategoryRes from "types/res/guestCategory/GuestCategoryRes";
import { simpleFetchGuestCategory } from "redux/actions/guestCategory";
import OrderApi from "api/OrderApi";

type Props = {
  formData: any;
  onChange: (key: string, value: any) => void;
};
const SpOrderGuestInfo = ({ formData, onChange }: Props) => {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.account.staff.company);
  const companyId = useSelector((state) => state.account.staff.companyId);
  const guestCategory = useSelector((state) => state.guestCategory);
  const shops = useSelector((state) => state.shop);
  const castShifts = useSelector((state) => state.castShift);
  const [searchTel, setSearchTel] = useState("");
  const [searchName, setSearchName] = useState("");
  const [selectGuest, setSelectGuest] = useState<any>({});
  const [guests, setGuests] = useState<GuestRes[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);
  const [filterGuestCategories, setFilterGuestCategories] = useState<
    GuestCategoryRes[]
  >([]);
  const [selectGuestCategoryId, setSelectGuestCategoryId] = useState<number>();
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  useEffect(() => {
    dispatch(simpleFetchGuestCategory(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    setFilterGuestCategories(
      formData?.shopId
        ? guestCategory.filter((guestCategory) =>
            guestCategory.shops
              .map((shop) => shop.shopId)
              .includes(formData.shopId)
          )
        : guestCategory
    );
  }, [formData.shopId, guestCategory]);

  useEffect(() => {
    if (formData?.guest) {
      setSelectGuest(formData?.guest);
    }
    if (!formData?.guestId) return;
    const apiCall = async () => {
      const result = await GuestApi.findOne(companyId, formData?.guestId);
      setSelectGuest(result);
    };
    apiCall();
  }, [formData?.guestId, formData?.guest]);

  useEffect(() => {
    if (!selectGuest.guestId) {
      return setSelectGuestCategoryId(undefined);
    }
    const selectGuestCategoryShop = company.guestCategoryShopFlag
      ? selectGuest?.guestGuestCategoryShops?.find(
          (guestGuestCategoryShop: any) =>
            guestGuestCategoryShop.shopId === formData?.shopId &&
            guestGuestCategoryShop.guestId === formData?.guestId
        )
      : selectGuest;
    if (selectGuestCategoryShop?.guestCategoryId) {
      return setSelectGuestCategoryId(selectGuestCategoryShop.guestCategoryId);
    }
    if (company.guestCategoryShopFlag) {
      const fetch = async () => {
        const result = await OrderApi.countOrder(
          companyId,
          selectGuest.guestId,
          formData.shopId,
          formData.orderId
        );
        if (result === 0) {
          setSelectGuestCategoryId(
            filterGuestCategories?.find(
              (guestCategory: any) =>
                guestCategory?.shops
                  ?.map((shop: any) => shop.shopId)
                  .includes(formData?.shopId) && guestCategory.unUseUserFlag
            )?.guestCategoryId
          );
        } else if (result === 1) {
          setSelectGuestCategoryId(
            filterGuestCategories.find(
              (guestCategory: any) =>
                guestCategory?.shops
                  ?.map((shop: any) => shop.shopId)
                  .includes(formData?.shopId) && guestCategory.secondUserFlag
            )?.guestCategoryId
          );
        }
      };
      fetch();
    } else {
      const fetch = async () => {
        const result = await OrderApi.countOrder(
          companyId,
          selectGuest.guestId,
          undefined,
          formData.orderId
        );
        if (result === 0) {
          setSelectGuestCategoryId(
            filterGuestCategories?.find(
              (guestCategory: any) => guestCategory.unUseUserFlag
            )?.guestCategoryId
          );
        } else if (result === 1) {
          setSelectGuestCategoryId(
            filterGuestCategories.find(
              (guestCategory: any) => guestCategory.secondUserFlag
            )?.guestCategoryId
          );
        }
      };
      fetch();
    }
  }, [selectGuest, formData.shopId, formData?.guestId]);

  useEffect(() => {
    onChange("guestCategoryId", selectGuestCategoryId);
  }, [selectGuestCategoryId]);

  const isNgGuest = () => {
    if (company.guestCategoryShopFlag) {
      return (
        selectGuest?.guestGuestCategoryShops?.find(
          (guestGuestCategoryShop: any) =>
            guestGuestCategoryShop.guestId === formData.guestId &&
            guestGuestCategoryShop.shopId === formData.shopId
        )?.guestCategory?.name === "NG" ||
        castShifts
          ?.find((shift) =>
            shift?.cast?.castNames
              ?.map((castName) => castName.castNameId)
              .includes(formData.castNameId)
          )
          ?.cast?.ngGuests?.some(
            (ngGuest) => ngGuest.guestId === selectGuest.guestId
          )
      );
    } else {
      return (
        selectGuest?.guestCategory?.name === "NG" ||
        castShifts
          ?.find((shift) =>
            shift?.cast?.castNames
              ?.map((castName) => castName.castNameId)
              .includes(formData.castNameId)
          )
          ?.cast?.ngGuests?.some(
            (ngGuest) => ngGuest.guestId === selectGuest.guestId
          )
      );
    }
  };

  const onChangeGuest =
    (key: string, handler: (value: any) => any = (value: any) => value) =>
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | { value: unknown }
      >
    ) => {
      event.persist();
      setSelectGuest((prev: any) => ({
        ...prev,
        [key]: handler(event.target.value),
      }));
    };

  const onBlurTel = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: unknown }
    >
  ) => {
    event.persist();
    setSelectGuest((prev: any) => ({
      ...prev,
      tel: (event.target.value as string).replace(/[-‐－―ー−]/g, ""),
    }));
  };
  const onClickSaveGuest = async () => {
    if (!formData?.shopId) {
      return alert("店舗を選択してください。");
    }
    const guestCategoryConfig = company.guestCategoryShopFlag
      ? {
          guestGuestCategoryShops: [
            {
              shopId: formData.shopId,
              guestId: formData.guestId,
              guestCategoryId:
                selectGuestCategoryId || selectGuest.guestCategoryId,
            },
          ],
        }
      : {
          guestCategoryId: selectGuestCategoryId || selectGuest.guestCategoryId,
        };
    const data = selectGuest?.guestId
      ? new UpdateGuestReq({
          ...selectGuest,
          ...guestCategoryConfig,
        })
      : new CreateGuestReq({
          ...selectGuest,
          ...guestCategoryConfig,
        });
    const errors = await validate(data);
    if (errors.length) {
      return setErrors(errors);
    }
    const result =
      data instanceof UpdateGuestReq
        ? await dispatch(updateGuest(companyId, data))
        : await dispatch(addGuest(companyId, data));
    if (result instanceof GuestRes) {
      setIsSuccess(true);
      setTimeout(function () {
        setIsSuccess(false);
      }, 3000);
      formData.guest = result;
      formData.guestId = result.guestId;
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box display={"flex"}>
        <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
          顧客検索
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <TextField
          value={searchTel}
          style={{ marginTop: "10px" }}
          onChange={(event) => setSearchTel(event.target.value)}
          placeholder={"電話番号検索"}
        />
        <TextField
          value={searchName}
          style={{ marginTop: "10px" }}
          onChange={(event) => setSearchName(event.target.value)}
          placeholder={"名前検索"}
        />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Button
            style={{ marginTop: "10px" }}
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={async () => {
              setIsLoading(true);
              setSelectGuest({});
              setGuests([]);
              setOpen(true);
              const result = await GuestApi.search(companyId, {
                tel: searchTel,
                name: searchName,
              });
              setGuests(result);
              setIsLoading(false);
            }}
          >
            検索
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" marginTop={2}>
        <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
          顧客選択
        </Typography>
        <Box display="flex" alignItems="center">
          {isLoading ? (
            <CircularProgress />
          ) : guests.length ? (
            <Autocomplete
              id="search-guest"
              size="small"
              options={guests}
              getOptionLabel={(option) =>
                option.name ? `${option.name}(${option.tel})` : ""
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus={true}
                  style={{
                    backgroundColor: isNgGuest() ? "red" : "initial",
                  }}
                />
              )}
              value={selectGuest || null}
              onChange={(event: any, newValue: GuestRes | null) => {
                onChange("guestId", newValue?.guestId);
                onChange("guest", newValue);
              }}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              noOptionsText={"電話番号か名前で検索してください。"}
              renderOption={(option) => (
                <div
                  style={{
                    backgroundColor: isNgGuest() ? "red" : "initial",
                  }}
                >
                  {option.name ? `${option.name}(${option.tel})` : ""}
                </div>
              )}
            />
          ) : (
            <Typography>
              該当する顧客が見つかりませんでした。電話番号か名前で検索してください。
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" marginTop={2}>
        <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
          顧客情報
        </Typography>
        <TextField
          label="電話番号"
          size="small"
          variant="outlined"
          value={selectGuest?.tel || ""}
          onChange={onChangeGuest("tel")}
          onBlur={onBlurTel}
          autoComplete="none"
          style={{ marginTop: "10px" }}
          error={errors?.map((error) => error.property).includes("tel")}
          helperText={Object.values(
            errors.find((error) => error.property === "tel")?.constraints || {}
          ).join(" ")}
        />
        <TextField
          label="名前"
          size="small"
          variant="outlined"
          value={selectGuest?.name || ""}
          onChange={onChangeGuest("name")}
          autoComplete="none"
          style={{ marginTop: "10px" }}
          error={errors?.map((error) => error.property).includes("name")}
          helperText={Object.values(
            errors.find((error) => error.property === "name")?.constraints || {}
          ).join(" ")}
        />
        <TextField
          label="住所"
          variant="outlined"
          size="small"
          value={selectGuest?.address || ""}
          onChange={onChangeGuest("address")}
          autoComplete="none"
          style={{ marginTop: "10px" }}
          error={errors?.map((error) => error.property).includes("address")}
          helperText={Object.values(
            errors.find((error) => error.property === "address")?.constraints ||
              {}
          ).join(" ")}
        />
        <TextField
          label="メールアドレス"
          size="small"
          variant="outlined"
          autoComplete="none"
          value={selectGuest?.email || ""}
          onChange={onChangeGuest("email")}
          style={{ marginTop: "10px" }}
          error={errors?.map((error) => error.property).includes("email")}
          helperText={Object.values(
            errors.find((error) => error.property === "email")?.constraints ||
              {}
          ).join(" ")}
        />
        <TextField
          select
          label="顧客カテゴリ"
          size="small"
          variant="outlined"
          autoComplete="none"
          value={selectGuestCategoryId || selectGuest.guestCategoryId || ""}
          onChange={(event) => {
            setSelectGuestCategoryId(Number(event.target.value));
          }}
          style={{
            marginTop: "10px",
            backgroundColor: isNgGuest() ? "red" : "initial",
          }}
          error={errors
            ?.map((error) => error.property)
            .includes("guestCategories")}
          helperText={Object.values(
            errors.find((error) => error.property === "guestCategories")
              ?.constraints || {}
          ).join(" ")}
        >
          {filterGuestCategories.map((category) => (
            <MenuItem
              value={category.guestCategoryId}
              key={category.guestCategoryId}
            >
              {category.name}
            </MenuItem>
          ))}
        </TextField>
        {selectGuest?.guestAnalyticsStatus && (
          <Box
            marginY={1}
            marginLeft={"15px"}
            display="flex"
            flexDirection="column"
          >
            <Typography style={{ fontWeight: "bold" }}>
              顧客CPMステータス
            </Typography>
            <Box display="flex" style={{ marginTop: "5px" }}>
              <Typography>
                {selectGuest?.guestAnalyticsStatus?.guestGroup
                  ? EnumUtils.mapToEnum(
                      GuestAnalyticsGroupType,
                      selectGuest?.guestAnalyticsStatus?.guestGroup
                    )
                  : ""}
              </Typography>
              <Typography style={{ marginLeft: "10px" }}>
                {selectGuest?.guestAnalyticsStatus?.genre
                  ? EnumUtils.mapToEnum(
                      GuestGenreType,
                      selectGuest?.guestAnalyticsStatus?.genre
                    )
                  : ""}
              </Typography>
              <Typography style={{ marginLeft: "10px" }}>
                {selectGuest?.guestAnalyticsStatus
                  ? `${selectGuest?.guestAnalyticsStatus?.awayDays || 0}日`
                  : ""}
              </Typography>
            </Box>
          </Box>
        )}
        <TextField
          multiline
          size="small"
          autoComplete="none"
          variant="outlined"
          value={selectGuest?.memo || ""}
          onChange={onChangeGuest("memo")}
          style={{ marginTop: "10px" }}
          rows={8}
          rowsMax={1000}
          error={errors?.map((error) => error.property).includes("memo")}
          helperText={Object.values(
            errors.find((error) => error.property === "memo")?.constraints || {}
          ).join(" ")}
        />
        <div style={{ margin: "0 auto" }}>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={onClickSaveGuest}
          >
            {selectGuest?.guestId ? "顧客情報を更新" : "顧客情報を登録"}
          </Button>
        </div>
        {isSuccess && (
          <Alert severity="success">
            <AlertTitle>
              {selectGuest?.guestId
                ? "顧客情報を更新しました"
                : "顧客情報を登録しました"}
            </AlertTitle>
          </Alert>
        )}
      </Box>
    </Box>
  );
};
export default SpOrderGuestInfo;
