import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty } from "class-validator";

export default class DeleteOptionReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly optionId: number;

  constructor(arg: { optionId: number }) {
    arg = arg || {};
    this.optionId = arg.optionId;
  }
}
