import RequestUtils from "utils/RequestUtils";
import ChangeLogRes from "types/res/changeLog/ChangeLogRes";

export default class ChangeLogApi {
  private constructor() {}

  static async findAll(
    companyId: number,
    startDate: string,
    endDate: string
  ): Promise<ChangeLogRes> {
    return await RequestUtils.get(
      ChangeLogRes,
      `/changeLog/findAll/${companyId}/${startDate}/${endDate}`
    );
  }
}
