import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import AdditionalTimeRes from "types/res/additionalTime/AdditionalTimeRes";
import UpdateAdditionalTimeReq from "types/req/additionalTime/UpdateAdditionalTimeReq";
import CreateAdditionalTimeReq from "types/req/additionalTime/CreateAdditionalTimeReq";
import DeleteAdditionalTimeReq from "types/req/additionalTime/DeleteAdditionalTimeReq";
import AdditionalTimeApi from "../../api/AdditionalTimeApi";

const actionCreator = actionCreatorFactory();
const addAdditionalTimeAction = actionCreator.async<
  CreateAdditionalTimeReq,
  AdditionalTimeRes,
  { code: number; description: string }
>("ADD_ADDITIONAL_TIME");

export const addAdditionalTime =
  (companyId: number, req: CreateAdditionalTimeReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addAdditionalTimeAction.started({ ...req }));

    try {
      const additionalTime = new CreateAdditionalTimeReq({ ...req });
      const result = await AdditionalTimeApi.create(companyId, additionalTime);
      dispatch(
        addAdditionalTimeAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addAdditionalTimeAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchAdditionalTimeAction = actionCreator.async<
  {},
  AdditionalTimeRes[],
  { code: number; description: string }
>("FETCH_ADDITIONAL_TIME");

export const fetchAdditionalTime =
  (companyId: number, shopId?: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchAdditionalTimeAction.started([]));

    try {
      const result = await AdditionalTimeApi.findAll(companyId, shopId);
      dispatch(
        fetchAdditionalTimeAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchAdditionalTimeAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateAdditionalTimeAction = actionCreator.async<
  UpdateAdditionalTimeReq,
  AdditionalTimeRes,
  { code: number; description: string }
>("UPDATE_ADDITIONAL_TIME");

export const updateAdditionalTime =
  (companyId: number, req: UpdateAdditionalTimeReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateAdditionalTimeAction.started({ ...req }));

    try {
      const updateAdditionalTime = new UpdateAdditionalTimeReq({ ...req });
      const result = await AdditionalTimeApi.update(
        companyId,
        req.additionalTimeId,
        updateAdditionalTime
      );
      dispatch(
        updateAdditionalTimeAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateAdditionalTimeAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteAdditionalTimeAction = actionCreator.async<
  DeleteAdditionalTimeReq,
  {},
  { code: number; description: string }
>("DELETE_ADDITIONAL_TIME");

export const deleteAdditionalTime =
  (companyId: number, req: DeleteAdditionalTimeReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteAdditionalTimeAction.started({ ...req }));

    try {
      const additionalTime = new DeleteAdditionalTimeReq({ ...req });
      const result = await AdditionalTimeApi.delete(companyId, additionalTime);
      dispatch(
        deleteAdditionalTimeAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteAdditionalTimeAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const additionalTimeActions = {
  fetchAdditionalTimeAction,
  addAdditionalTimeAction,
  updateAdditionalTimeAction,
  deleteAdditionalTimeAction,
};

export default additionalTimeActions;
