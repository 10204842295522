import RequestUtils from "utils/RequestUtils";
import CompanyRes from "types/res/company/CompanyRes";
import UpdateCompanyReq from "types/req/company/UpdateCompanyReq";
import DeleteCompanyReq from "types/req/company/DeleteCompanyReq";
import CreateCompanyReq from "types/req/company/CreateCompanyReq";

export default class CompanyApi {
  private constructor() {}

  static async findAll(): Promise<CompanyRes[]> {
    return await RequestUtils.getArray(CompanyRes, `/company/findAll`);
  }

  static async findByCompanyGroupId(
    companyId: number,
    companyGroupId: number
  ): Promise<CompanyRes[]> {
    return await RequestUtils.getArray(
      CompanyRes,
      `/company/findByCompanyGroupId/${companyId}/${companyGroupId}`
    );
  }

  static async create(data: CreateCompanyReq) {
    return await RequestUtils.post(CompanyRes, `/company/create`, data);
  }

  static async update(
    companyId: number,
    data: UpdateCompanyReq
  ): Promise<CompanyRes> {
    return await RequestUtils.post(
      CompanyRes,
      `/company/update/${companyId}`,
      data
    );
  }

  static async delete(data: DeleteCompanyReq) {
    return await RequestUtils.postVoid(
      `/company/delete/${data.companyId}`,
      data
    );
  }
}
