import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import { FormType } from "components/FormField";
import {
  addGuestPoint,
  bulkInsertGuestPoint,
  fetchGuestPoint,
} from "redux/actions/guestPoint";
import GuestPointRes from "types/res/guestPoint/GuestPointRes";
import CreateGuestPointReq from "types/req/guestPoint/CreateGuestPointReq";
import { fetchShops } from "redux/actions/shop";
import { simpleFetchGuest } from "redux/actions/guest";
import GuestPointType from "types/enum/GuestPointType";
import styled from "styled-components";
import EnumUtils from "utils/EnumUtils";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import {
  Box,
  Button,
  createStyles,
  InputLabel,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CsvImport from "components/CsvImport";
import { CsvFieldType } from "utils/CsvUtils";
import GuestRes from "types/res/guest/GuestRes";
import ShopRes from "types/res/shop/ShopRes";
import { makeStyles } from "@material-ui/core/styles";
import GuestApi from "api/GuestApi";
import useModal from "hooks/useModal";
import Modal from "components/Modal";
import GuestPointApi from "api/GuestPointApi";
import { validate } from "class-validator";

const headers: { key: keyof GuestPointRes; label: string }[] = [
  { key: "guestId", label: "顧客" },
  { key: "shopId", label: "店舗名" },
  { key: "point", label: "ポイント" },
  { key: "type", label: "タイプ" },
  { key: "createdAt", label: "付与日" },
  { key: "remarks", label: "備考" },
];

const GuestPointHistory: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const guestPoints = useSelector((state) => state.guestPoint);
  const shops = useSelector((state) => state.shop);
  const guests = useSelector((state) => state.guest);
  const [guest, setGuest] = useState<GuestRes | null>();
  const [shop, setShop] = useState<ShopRes | null>();
  const [searchTel, setSearchTel] = useState("");
  const [searchName, setSearchName] = useState("");
  const [openCsvImport, setOpenCsvImport] = useState(false);
  const [guestList, setGuestList] = useState<GuestRes[]>([]);
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addPointModal, setAddPointModal] = useModal("addPointForm");
  const [addPointReq, setAddPointReq] = useState<any>({
    guestId: 0,
    orderId: null,
    type: "addManualPoint" as GuestPointType,
    shopId: null,
    point: 0,
    remarks: "",
  });
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchShops(companyId));
  }, []);

  useEffect(() => {
    dispatch(
      fetchGuestPoint(companyId, limit, offset, shop?.shopId, guest?.guestId)
    );
  }, [shop, guest, limit, offset, companyId]);

  useEffect(() => {
    const apiCall = async () => {
      const result = await GuestPointApi.count(
        companyId,
        shop?.shopId,
        guest?.guestId
      );
      setTotal(result);
    };
    apiCall();
  }, [shop, guest, companyId]);

  useEffect(() => {
    if (!openCsvImport) return;
    const apiCall = async () => {
      setIsLoading(true);
      await dispatch(simpleFetchGuest(companyId));
      setIsLoading(false);
    };
    apiCall();
  }, [openCsvImport]);

  const forms = [
    {
      label: "顧客電話番号（顧客名）",
      key: "guestId",
      type: FormType.Select,
      options: guests.map((g) => ({
        id: Number(g.guestId),
        name: `${g.tel}(${g.name})`,
      })),
    },
    {
      label: "店舗名",
      key: "shopId",
      type: FormType.Select,
      options: shops.map((s) => ({ id: Number(s.shopId), name: s.name })),
    },
    {
      label: "ポイント",
      key: "point",
      type: FormType.Number,
    },
    {
      label: "備考",
      key: "remarks",
      type: FormType.TextArea,
    },
  ];

  const csvHeader = [
    {
      label: "顧客電話番号",
      key: "guestId",
      type: CsvFieldType.Object,
      relation: guests,
      relationKey: "tel",
      relationId: "guestId",
    },
    {
      label: "店舗名",
      key: "shopId",
      type: CsvFieldType.Object,
      relation: shops,
      relationKey: "name",
      relationId: "shopId",
    },
    {
      label: "ポイント※数字のみ",
      key: "point",
      type: CsvFieldType.Number,
    },
    {
      label: "備考",
      key: "remarks",
      type: CsvFieldType.Text,
    },
  ];
  const findGuest = async () => {
    const searchGuestList = await GuestApi.search(companyId, {
      tel: searchTel,
      name: searchName,
    });
    setGuestList(searchGuestList);
    setOpen(true);
  };

  const openAddModal = () => {
    if (!guest) {
      return alert(
        "電話番号もしくは名前で顧客を検索し、顧客を選択した後に再度実行してください"
      );
    }
    setAddPointReq((prev: any) => ({ ...prev, guestId: guest.guestId }));
    setAddPointModal(true, { guest, shop });
  };

  const submitAddPoint = async () => {
    const newReq = new CreateGuestPointReq(addPointReq);
    const errors = await validate(newReq);
    if (errors.length > 0) {
      return alert(
        `店舗もしくはポイントが${errors[0].constraints?.isNotEmpty}`
      );
    }
    await dispatch(addGuestPoint(companyId, newReq));
    setAddPointModal(false, {});
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginTop={1}
        marginRight={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenCsvImport((prev) => !prev)}
          style={{ marginLeft: "30px" }}
        >
          csvインポート
        </Button>
      </Box>
      {openCsvImport && (
        <CsvImport
          templatePath={"/csv/guest_point.csv"}
          templateName={"guest_point_template.csv"}
          csvHeader={csvHeader}
          addType={CreateGuestPointReq}
          addFunc={(formData) => bulkInsertGuestPoint(companyId, formData)}
          isLoading={isLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Box margin={2} display="flex" alignItems="center">
          <Box display="flex" flexDirection="column">
            <Typography variant="h6">顧客検索</Typography>
            <Box display="flex" marginTop={2}>
              <TextField
                value={searchTel}
                label="電話番号"
                onChange={(event) => setSearchTel(event.target.value)}
                style={{ width: "200px" }}
              />
              <TextField
                value={searchName}
                label="名前"
                onChange={(event) => setSearchName(event.target.value)}
                style={{ marginLeft: "5px", width: "200px" }}
              />
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={findGuest}
                style={{ marginLeft: "10px" }}
              >
                検索
              </Button>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} marginLeft={10}>
            <Typography variant="h6">絞り込み</Typography>
            <Box display="flex" marginTop={2}>
              <Box>
                <InputLabel>顧客名</InputLabel>
                <Autocomplete
                  fullWidth
                  options={guestList}
                  value={guest || null}
                  style={{ marginRight: "10px", width: "300px" }}
                  getOptionLabel={(option: any) =>
                    option?.tel ? `${option.tel}(${option.name})` : ""
                  }
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  noOptionsText={"電話番号か名前で検索してください。"}
                  renderInput={(params) => (
                    <TextField {...params} autoFocus={open} />
                  )}
                  onChange={(event, value) => {
                    setGuest(value);
                  }}
                />
              </Box>
              <Box>
                <InputLabel>店舗名</InputLabel>
                <Autocomplete
                  fullWidth
                  options={shops}
                  value={shop || null}
                  style={{ width: "300px" }}
                  getOptionLabel={(option: any) => option.name || ""}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(event, value) => {
                    setShop(value);
                    setAddPointReq((prev: any) => ({
                      ...prev,
                      shopId: Number(value?.shopId || 0),
                    }));
                  }}
                />
              </Box>
              <Button
                variant="contained"
                className={classes.button}
                color="secondary"
                onClick={openAddModal}
                style={{ marginLeft: "10px" }}
              >
                追加
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <GuestPointTable
        title={"ポイント"}
        formId="guestPointForm"
        forms={forms}
        rows={headers}
        data={guestPoints}
        setOffset={setOffset}
        setLimit={setLimit}
        limit={limit}
        offset={offset}
        totalCount={total}
        addFunc={() => {}}
        updateFunc={() => {}}
        deleteFunc={() => {}}
        invisibleDeleteIcon
        values={[
          (s) => (s.guest ? `${s.guest?.tel}(${s.guest?.name})` : "未設定"),
          (s) => s.shop?.name || "未設定",
          (s) => s.point,
          (s) => EnumUtils.mapToEnum(GuestPointType, s.type) || "未設定",
          (s) =>
            DateTimeUtils.toFormatAsLocalTimezone(
              s.createdAt,
              FORMAT_TYPE.YEAR_DAY
            ) || "",
          (s) => s.remarks,
        ]}
      />
      <Modal
        show={addPointModal.show}
        setShow={setAddPointModal}
        title={`${addPointModal.meta?.guest?.name}さんへのポイント追加`}
      >
        <Box display="flex" flexDirection="column">
          <TextField
            label={"顧客"}
            disabled={true}
            value={addPointModal.meta?.guest?.name}
          />
          <TextField
            select
            label="店舗"
            style={{ marginTop: "30px" }}
            value={addPointReq.shopId || addPointModal.meta?.shop?.shopId}
            onChange={(event) =>
              setAddPointReq((prev: any) => ({
                ...prev,
                shopId: Number(event.target.value),
              }))
            }
          >
            {shops.map((shop) => (
              <MenuItem key={shop.shopId} value={shop.shopId}>
                {shop.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="ポイント"
            style={{ marginTop: "30px" }}
            onBlur={(event) => {
              event.preventDefault();
              setAddPointReq((prev: any) => ({
                ...prev,
                point:
                  event?.target?.value === "" || null || undefined
                    ? 0
                    : Number(event.target.value),
              }));
            }}
          />
          <TextField
            type="text"
            label="備考"
            multiline
            rows={3}
            rowsMax={1000}
            style={{ marginTop: "30px" }}
            onChange={(event) => {
              event.persist();
              setAddPointReq((prev: any) => ({
                ...prev,
                remarks: event.target.value || "",
              }));
            }}
          />
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={submitAddPoint}
            style={{ marginTop: "50px" }}
          >
            決定
          </Button>
        </Box>
      </Modal>
    </>
  );
};
const GuestPointTable = styled<
  React.FC<
    CommonTableProps<GuestPointRes, CreateGuestPointReq, CreateGuestPointReq>
  >
>(CommonTable)`
  margin-top: 24px;
`;
export default GuestPointHistory;
