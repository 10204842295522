import { DataValidationError } from "constants/DataValidationError";
import {
  IsArray,
  IsAscii,
  IsEmail,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  MaxLength,
  ValidateIf,
} from "class-validator";
import Spec from "constants/Spec";

export default class CreateGuestReq {
  @MaxLength(Spec.maxLength.guest.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.guest.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @ValidateIf((o) => (o?.email?.length || 0) > 0)
  @MaxLength(Spec.maxLength.guest.email, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.guest.email,
    },
  })
  @IsEmail(
    {},
    {
      message: DataValidationError.DATA_IS_INVALID,
    }
  )
  email!: string | null;

  @MaxLength(Spec.maxLength.guest.tel, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.guest.tel,
    },
  })
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  tel!: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.guest.password, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.guest.password,
    },
  })
  @IsAscii({
    message: DataValidationError.DATA_IS_INVALID,
  })
  password: string | null = null;

  @IsOptional()
  @MaxLength(Spec.maxLength.guest.address, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.guest.address,
    },
  })
  address: string | null = null;

  @IsOptional()
  @MaxLength(Spec.maxLength.guest.memo, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.guest.memo,
    },
  })
  memo!: string | null;

  @IsOptional()
  @IsNumber()
  guestCategoryId!: number | null;

  @IsOptional()
  @IsArray()
  guestGuestCategoryShops!:
    | { guestId: number; shopId: number; guestCategoryId: number }[]
    | null;

  constructor(arg: {
    email: string | null;
    name: string;
    password: string | null;
    tel: string;
    address: string | null;
    memo: string | null;
    guestCategoryId: number | null;
    guestGuestCategoryShops:
      | { guestId: number; shopId: number; guestCategoryId: number }[]
      | null;
  }) {
    arg = arg || {};
    this.name = arg.name;
    this.email = arg.email;
    this.password = arg.password;
    this.tel = arg.tel;
    this.address = arg.address;
    this.memo = arg.memo;
    this.guestCategoryId = arg.guestCategoryId;
    this.guestGuestCategoryShops = arg.guestGuestCategoryShops;
  }
}
