import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import {
  addNotelClass,
  deleteNotelClass,
  fetchNotelClass,
  updateNotelClass,
} from "redux/actions/notelClass";
import NotelClassRes from "types/res/notelClass/NotelClassRes";
import { fetchShops } from "redux/actions/shop";
import { FormType } from "components/FormField";
import CreateNotelClassReq from "types/req/notelClass/CreateNotelClassReq";
import UpdateNotelClassReq from "types/req/notelClass/UpdateNotelClassReq";
const headers: { key: keyof NotelClassRes; label: string }[] = [
  { key: "name", label: "名前" },
  { key: "kana", label: "かな" },
];

const NotelClass: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const notelClass = useSelector((state) => state.notelClass);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchNotelClass(companyId));
  }, [companyId, dispatch]);

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "かな",
      key: "kana",
      type: FormType.Text,
    },
  ];

  return (
    <>
      <NotelClassTable
        title={"クラス"}
        formId="notelClassForm"
        forms={forms}
        rows={headers}
        data={notelClass}
        addFunc={(formData) => addNotelClass(companyId, formData)}
        updateFunc={(formData) => updateNotelClass(companyId, formData)}
        deleteFunc={(item) =>
          deleteNotelClass(companyId, {
            notelClassId: item.notelClassId,
          })
        }
        addType={CreateNotelClassReq}
        updateType={UpdateNotelClassReq}
        values={[(s) => s.name, (s) => s.kana]}
      />
    </>
  );
};

const NotelClassTable = styled<
  React.FC<
    CommonTableProps<NotelClassRes, CreateNotelClassReq, UpdateNotelClassReq>
  >
>(CommonTable)`
  margin-top: 24px;
`;

export default NotelClass;
