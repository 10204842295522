import {
  Link,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";

type RakingTableBodyProps = {
  total: boolean;
  dataArray: {
    id: number | string;
    name: string;
    shopName?: string;
    notelClassName?: string;
    commutingDays: number;
    commutingTimes: number;
    averageCommutingTimes?: number;
    totalOrderCount: number;
    nominationOrderCount: number;
    nominationOrderRate?: number;
    averageTotalOrderCount?: number;
    averageNominationOrderCount?: number;
    repeatRate?: number;
    breakRate?: number;
    sales: number;
    averageSales?: number;
    castFee: number;
    castFeePerHour?: number;
    tardyCount: number;
    absenceCount: number;
    score?: number;
    link?: string;
  }[];
};
const CastRankingTableBody = ({ total, dataArray }: RakingTableBodyProps) => {
  return (
    <TableBody>
      {dataArray.map((data) => (
        <TableRow key={data.id}>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              {data.name}
            </Typography>
          </TableCell>
          {!total && (
            <TableCell>
              <Typography style={{ fontSize: "1.2rem" }}>
                {data.shopName}
              </Typography>
            </TableCell>
          )}
          {!total && (
            <TableCell>
              <Typography style={{ fontSize: "1.2rem" }}>
                {data.notelClassName}
              </Typography>
            </TableCell>
          )}
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.commutingDays}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.commutingTimes}h
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.averageCommutingTimes !== undefined
                ? `${data.averageCommutingTimes}h`
                : "-"}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.totalOrderCount}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.nominationOrderCount}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.nominationOrderRate !== undefined
                ? `${data.nominationOrderRate}%`
                : "-"}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.averageTotalOrderCount !== undefined
                ? data.averageTotalOrderCount
                : "-"}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.averageNominationOrderCount !== undefined
                ? data.averageNominationOrderCount
                : "-"}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.repeatRate !== undefined ? `${data.repeatRate}%` : "-"}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.breakRate !== undefined ? `${data.breakRate}%` : "-"}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              ¥{data.sales}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.averageSales !== undefined ? `¥${data.averageSales}` : "-"}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              ¥{data.castFee}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.castFeePerHour !== undefined
                ? `¥${data.castFeePerHour}`
                : "-"}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.tardyCount}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.absenceCount}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ fontSize: "1.2rem" }}>
              {data.score !== undefined ? data.score : "-"}
            </Typography>
          </TableCell>
          {!total && data.link && (
            <TableCell padding={"checkbox"}>
              <Link href={data.link || ""}>詳細へ</Link>
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};
export default CastRankingTableBody;
