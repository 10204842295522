import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TextField,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCourse,
  deleteCourse,
  fetchWithPaginationCourse,
  updateCourse,
} from "redux/actions/course";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import useModal from "hooks/useModal";
import DeleteModal from "components/DeleteModal";
import { FormType } from "components/FormField";
import FormModal from "components/FormModal";
import CreateCourseReq from "types/req/course/CreateCourseReq";
import UpdateCourseReq from "types/req/course/UpdateCourseReq";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CourseApi from "api/CourseApi";

const CourseRow: React.FC = () => {
  const dispatch = useDispatch();
  const loginStaff = useSelector((state) => state.account.staff);
  const courses = useSelector((state) => state.course);
  const shops = useSelector((state) => state.shop);
  const notelClasses = useSelector((state) => state.notelClass);
  const nominations = useSelector((state) => state.nomination);
  const [, setShowForm] = useModal("course");
  const [, setDeleteShow] = useModal("deleteData");
  const [offset, setOffset] = useState(0);
  const [selectShopIds, setSelectShopIds] = useState<string[]>([]);
  const [selectName, setSelectName] = useState("");
  const [selectHotelIncludes, setSelectHotelIncludes] = useState<boolean>();
  const [selectNotelClassIds, setSelectNotelClassIds] = useState<string[]>([]);
  const [selectNominationIds, setSelectNominationIds] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const forms = [
    {
      label: "コース名",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "店舗名",
      key: "shopId",
      type: FormType.Select,
      options: shops.map((s) => ({
        id: s.shopId,
        name: s.name,
      })),
    },
    {
      label: "クラス名",
      key: "notelClassId",
      type: FormType.Select,
      options: notelClasses.map((notelClass) => ({
        id: notelClass.notelClassId,
        name: notelClass.name,
      })),
    },
    {
      label: "指名種",
      key: "nominationId",
      type: FormType.Select,
      options: nominations.map((nomination) => ({
        id: nomination.nominationId,
        name: nomination.name,
      })),
    },
    {
      label: "時間",
      key: "time",
      type: FormType.Number,
    },
    {
      label: "基本料金",
      key: "timeFee",
      type: FormType.Number,
    },
    {
      label: "店舗基本料金",
      key: "timeShopFee",
      type: FormType.Number,
    },
    {
      label: "キャスト基本料金",
      key: "timeCastFee",
      type: FormType.Number,
    },
    {
      label: "厚生費",
      key: "welfareFee",
      type: FormType.Number,
    },
    {
      label: "ホテル費",
      key: "hotelFee",
      type: FormType.Number,
    },
    {
      label: "キャストホテル費",
      key: "hotelCastFee",
      type: FormType.Number,
    },
    {
      label: "店舗ホテル費",
      key: "hotelShopFee",
      type: FormType.Number,
    },
    {
      label: "並び順",
      key: "sort",
      type: FormType.Number,
    },
  ];
  const onClickEditButton = (type: string, item: any) => () => {
    setShowForm(true, { type: "editData", item });
  };

  const onClickDeleteButton = (type: string, item: any) => () => {
    setDeleteShow(true, { item });
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(
      fetchWithPaginationCourse(loginStaff.companyId, {
        shopIds:
          selectShopIds.includes("すべて") || selectShopIds.length === 0
            ? undefined
            : selectShopIds.map(Number),
        notelClassIds:
          selectNotelClassIds.includes("すべて") ||
          selectNotelClassIds.length === 0
            ? undefined
            : selectNotelClassIds.map(Number),
        nominationIds:
          selectNominationIds.includes("すべて") ||
          selectNominationIds.length === 0
            ? undefined
            : selectNominationIds.map(Number),
        name: selectName,
        hotelInclude: selectHotelIncludes,
        offset: 0,
        limit: 100,
      })
    );
    const apiCall = async () => {
      const result = await CourseApi.count(loginStaff.companyId, {
        shopIds:
          selectShopIds.includes("すべて") || selectShopIds.length === 0
            ? undefined
            : selectShopIds.map(Number),
        notelClassIds:
          selectNotelClassIds.includes("すべて") ||
          selectNotelClassIds.length === 0
            ? undefined
            : selectNotelClassIds.map(Number),
        nominationIds:
          selectNominationIds.includes("すべて") ||
          selectNominationIds.length === 0
            ? undefined
            : selectNominationIds.map(Number),
        name: selectName,
        hotelInclude: selectHotelIncludes,
        offset: 0,
        limit: 100,
      });
      setTotalCount(result);
    };
    apiCall();
    setIsLoading(false);
  }, [dispatch, loginStaff.companyId]);

  useEffect(() => {
    setSelectShopIds([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    setSelectNotelClassIds([
      ...notelClasses.map((notelClass) => String(notelClass.notelClassId)),
      "すべて",
    ]);
  }, [notelClasses]);

  useEffect(() => {
    setSelectNominationIds([
      ...nominations.map((nomination) => String(nomination.nominationId)),
      "すべて",
    ]);
  }, [nominations]);

  const courseRows = [
    "更新時間",
    "担当者",
    "店舗名",
    "コース名",
    "ホテ込",
    "クラス名",
    "時間",
    "指名種",
    "厚生費",
    "コース代金",
    "並び順",
    "",
    "",
  ];

  const onClickSearch = async () => {
    setIsLoading(true);
    await dispatch(
      fetchWithPaginationCourse(loginStaff.companyId, {
        shopIds: selectShopIds.includes("すべて")
          ? undefined
          : selectShopIds.map(Number),
        notelClassIds: selectNotelClassIds.includes("すべて")
          ? undefined
          : selectNotelClassIds.map(Number),
        nominationIds: selectNominationIds.includes("すべて")
          ? undefined
          : selectNominationIds.map(Number),
        name: selectName,
        hotelInclude: selectHotelIncludes,
        offset: 0,
        limit: 100,
      })
    );
    const result = await CourseApi.count(loginStaff.companyId, {
      shopIds:
        selectShopIds.includes("すべて") || selectShopIds.length === 0
          ? undefined
          : selectShopIds.map(Number),
      notelClassIds:
        selectNotelClassIds.includes("すべて") ||
        selectNotelClassIds.length === 0
          ? undefined
          : selectNotelClassIds.map(Number),
      nominationIds:
        selectNominationIds.includes("すべて") ||
        selectNominationIds.length === 0
          ? undefined
          : selectNominationIds.map(Number),
      name: selectName,
      hotelInclude: selectHotelIncludes,
      offset: 0,
      limit: 100,
    });
    setTotalCount(result);
    setIsLoading(false);
  };

  const onClickPrev = async () => {
    if (offset === 0) return;
    setIsLoading(true);
    await dispatch(
      fetchWithPaginationCourse(loginStaff.companyId, {
        shopIds: selectShopIds.includes("すべて")
          ? undefined
          : selectShopIds.map(Number),
        notelClassIds: selectNotelClassIds.includes("すべて")
          ? undefined
          : selectNotelClassIds.map(Number),
        nominationIds: selectNominationIds.includes("すべて")
          ? undefined
          : selectNominationIds.map(Number),
        name: selectName,
        hotelInclude: selectHotelIncludes,
        offset: offset - 1,
        limit: 100,
      })
    );
    setOffset((prev) => prev - 1);
    setIsLoading(false);
  };

  const onClickNext = async () => {
    setIsLoading(true);
    await dispatch(
      fetchWithPaginationCourse(loginStaff.companyId, {
        shopIds: selectShopIds.includes("すべて")
          ? undefined
          : selectShopIds.map(Number),
        notelClassIds: selectNotelClassIds.includes("すべて")
          ? undefined
          : selectNotelClassIds.map(Number),
        nominationIds: selectNominationIds.includes("すべて")
          ? undefined
          : selectNominationIds.map(Number),
        name: selectName,
        hotelInclude: selectHotelIncludes,
        offset: offset + 1,
        limit: 100,
      })
    );
    setOffset((prev) => prev + 1);
    setIsLoading(false);
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1">登録済みコース一覧</Typography>
        <Box display="flex" marginY={2}>
          <Box>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={selectShopIds}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectShopIds((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length === shops.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= shops.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectShopIds.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={selectShopIds.indexOf(String(shop.shopId)) !== -1}
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <TextField
            label="コース名"
            style={{ margin: "0 10px", width: "200px" }}
            value={selectName}
            onChange={(event) => {
              event.persist();
              setSelectName(event.target.value);
            }}
          />
          <Box>
            <InputLabel id="select-multiple-notel-class">クラス</InputLabel>
            <Select
              multiple
              value={selectNotelClassIds}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectNotelClassIds((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...notelClasses.map((notelClass) =>
                        String(notelClass.notelClassId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length ===
                      notelClasses.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...notelClasses.map((notelClass) =>
                        String(notelClass.notelClassId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <=
                    notelClasses.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-notelClass" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return notelClasses
                    .filter((notelClass) =>
                      (selected as string[]).includes(
                        String(notelClass.notelClassId)
                      )
                    )
                    .map((notelClass) => notelClass.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectNotelClassIds.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {notelClasses.map((notelClass) => (
                <MenuItem
                  key={notelClass.notelClassId}
                  value={String(notelClass.notelClassId)}
                >
                  <Checkbox
                    checked={
                      selectNotelClassIds.indexOf(
                        String(notelClass.notelClassId)
                      ) !== -1
                    }
                  />
                  <ListItemText primary={notelClass.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel id="select-multiple-notel-class">指名種</InputLabel>
            <Select
              multiple
              value={selectNominationIds}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectNominationIds((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...nominations.map((nomination) =>
                        String(nomination.nominationId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length ===
                      nominations.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...nominations.map((nomination) =>
                        String(nomination.nominationId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <=
                    nominations.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-nomination" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return nominations
                    .filter((nomination) =>
                      (selected as string[]).includes(
                        String(nomination.nominationId)
                      )
                    )
                    .map((nomination) => nomination.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectNominationIds.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {nominations.map((nomination) => (
                <MenuItem
                  key={nomination.nominationId}
                  value={String(nomination.nominationId)}
                >
                  <Checkbox
                    checked={
                      selectNominationIds.indexOf(
                        String(nomination.nominationId)
                      ) !== -1
                    }
                  />
                  <ListItemText primary={nomination.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) =>
                  setSelectHotelIncludes(event.target.checked)
                }
                checked={selectHotelIncludes}
              />
            }
            label="ホテ込"
          />
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onClickSearch()}
              style={{ marginLeft: "30px" }}
            >
              検索
            </Button>
          </Box>
        </Box>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {courseRows.map((row, index) => (
                  <TableCell key={index}>{row}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {courses.map((course, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {DateTimeUtils.toFormatAsLocalTimezone(
                      course.updatedAt,
                      FORMAT_TYPE.YEAR_DAY
                    )}
                  </TableCell>
                  <TableCell>{course.updatedBy?.name || "未設定"}</TableCell>
                  <TableCell>{course.shop?.name || "未設定"}</TableCell>
                  <TableCell>{course.name}</TableCell>
                  <TableCell>{course.hotelFee ? "込" : "無"}</TableCell>
                  <TableCell>
                    {notelClasses.find(
                      (notelClass) =>
                        notelClass.notelClassId === course.notelClassId
                    )?.name || "未設定"}
                  </TableCell>
                  <TableCell>{course.time}分</TableCell>
                  <TableCell>
                    {nominations.find(
                      (nomination) =>
                        nomination.nominationId === course.nominationId
                    )?.name || "未設定"}
                  </TableCell>
                  <TableCell>
                    <p>{course.welfareFee}円</p>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="column">
                      <p>基本料金：{course.timeFee}円</p>
                      {course.hotelFee ? (
                        <p>ホテル代：{course.hotelFee}円</p>
                      ) : (
                        ""
                      )}
                      <p>
                        合計金額：
                        {course.timeFee + (course.hotelFee || 0)}円
                      </p>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <p>{course.sort || "未設定"}</p>
                  </TableCell>
                  <TableCell>
                    <TableCell padding="checkbox">
                      <IconButton
                        onClick={onClickEditButton("editData", course)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableCell>
                  <TableCell>
                    <TableCell padding="checkbox">
                      <IconButton
                        onClick={onClickDeleteButton("deleteData", course)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <Box display="flex" justifyContent="space-between" marginY={2}>
          <Button
            variant="contained"
            onClick={() => onClickPrev()}
            disabled={offset === 0}
          >
            前へ
          </Button>
          <Button
            variant="contained"
            onClick={() => onClickNext()}
            disabled={totalCount / 100 < offset + 1}
          >
            次へ
          </Button>
        </Box>
      </Box>
      <FormModal<CreateCourseReq[], UpdateCourseReq>
        formId="course"
        title="コース"
        forms={forms}
        addFunc={(formData) => addCourse(loginStaff.companyId, formData)}
        updateFunc={(formData) => updateCourse(loginStaff.companyId, formData)}
        updateType={UpdateCourseReq}
      />
      <DeleteModal
        deleteFunc={(item) =>
          deleteCourse(loginStaff.companyId, {
            courseId: item.courseId,
          })
        }
      />
    </>
  );
};
export default CourseRow;
