import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import ShopApi from "api/ShopApi";
import ShopRes from "types/res/shop/ShopRes";
import UpdateShopReq from "types/req/shop/UpdateShopReq";
import CreateShopReq from "types/req/shop/CreateShopReq";
import DeleteShopReq from "types/req/shop/DeleteShopReq";

const actionCreator = actionCreatorFactory();
const addShopAction = actionCreator.async<
  CreateShopReq,
  ShopRes,
  { code: number; description: string }
>("ADD_SHOP");

export const addShop =
  (companyId: number, req: CreateShopReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addShopAction.started({ ...req }));

    try {
      const shop = new CreateShopReq({ ...req });
      const result = await ShopApi.create(companyId, shop);
      dispatch(
        addShopAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addShopAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchShopsAction = actionCreator.async<
  {},
  ShopRes[],
  { code: number; description: string }
>("FETCH_SHOP");

export const fetchShops =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchShopsAction.started([]));

    try {
      const result = await ShopApi.findAll(companyId);
      dispatch(
        fetchShopsAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchShopsAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateShopAction = actionCreator.async<
  UpdateShopReq,
  ShopRes,
  { code: number; description: string }
>("UPDATE_SHOP");

export const updateShop =
  (companyId: number, req: UpdateShopReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateShopAction.started({ ...req }));

    try {
      const updateShop = new UpdateShopReq({ ...req });
      const result = await ShopApi.update(companyId, updateShop);
      dispatch(
        updateShopAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateShopAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteShopAction = actionCreator.async<
  { companyId: number; shopId: number },
  {},
  { code: number; description: string }
>("DELETE_SHOP");

export const deleteShop =
  (companyId: number, shopId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(deleteShopAction.started({ companyId, shopId }));

    try {
      const shop = new DeleteShopReq({ shopId });
      const result = await ShopApi.delete(companyId, shop);
      dispatch(
        deleteShopAction.done({
          params: { companyId, shopId },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteShopAction.failed({
          params: { companyId, shopId },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const companyActions = {
  fetchShopsAction,
  addShopAction,
  updateShopAction,
  deleteShopAction,
};

export default companyActions;
