import { reducerWithInitialState } from "typescript-fsa-reducers";
import staffTaskActions from "redux/actions/staffTask";
import StaffTaskRes from "types/res/staffTask/StaffTaskRes";
export type StaffTaskState = StaffTaskRes[];

const initialState = [] as StaffTaskState;

const staffTaskReducer = reducerWithInitialState(initialState)
  .case(staffTaskActions.addStaffTaskAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(staffTaskActions.updateStaffTaskAction.done, (state, payload) => {
    return state.map((item) =>
      item.staffTaskId === payload.params.staffTaskId ? payload.result : item
    );
  })
  .case(staffTaskActions.deleteStaffTaskAction.done, (state, payload) => {
    return state.filter(
      (item) => item.staffTaskId !== payload.params.staffTaskId
    );
  })
  .case(staffTaskActions.fetchStaffTaskAction.done, (state, payload) => {
    return [...payload.result];
  });

export default staffTaskReducer;
