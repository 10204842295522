import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShopRes from "types/res/shop/ShopRes";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import {
  addNotification,
  deleteNotification,
  fetchNotification,
  updateNotification,
} from "redux/actions/notification";
import NotificationRes from "types/res/notification/NotificationRes";
import { fetchShops } from "redux/actions/shop";
import { FormType } from "components/FormField";
import CreateNotificationReq from "types/req/notification/CreateNotificationReq";
import UpdateNotificationReq from "types/req/notification/UpdateNotificationReq";
import NotificationType from "../../types/enum/NotificationType";
import DateTimeUtils, { FORMAT_TYPE } from "../../utils/DateTimeUtils";
import EnumUtils from "../../utils/EnumUtils";
import Box from "@material-ui/core/Box";
import { MenuItem, TextField } from "@material-ui/core";
const headers: { key: keyof NotificationRes; label: string }[] = [
  { key: "title", label: "タイトル" },
  { key: "content", label: "内容" },
  { key: "type", label: "通知先・通知種別" },
  { key: "startDate", label: "掲載開始日" },
  { key: "endDate", label: "掲載終了日" },
  { key: "isIndefinite", label: "無期限掲載" },
  { key: "sort", label: "並び順" },
  { key: "shops", label: "適用店舗" },
];

const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const notification = useSelector((state) => state.notification);
  const [filterNotifications, setFilterNotifications] = useState<
    NotificationRes[]
  >([]);
  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchNotification(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    setFilterNotifications(notification);
  }, [notification]);

  const forms = [
    {
      label: "タイトル",
      key: "title",
      type: FormType.Text,
    },
    {
      label: "内容",
      key: "content",
      type: FormType.TextArea,
    },
    {
      label: "通知先・通知種別",
      key: "type",
      type: FormType.Select,
      options: Object.entries(NotificationType).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "掲載開始日",
      key: "startDate",
      type: FormType.Date,
    },
    {
      label: "掲載終了日",
      key: "endDate",
      type: FormType.Date,
    },
    {
      label: "無期限掲載",
      key: "isIndefinite",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "並び順",
      key: "sort",
      type: FormType.Number,
    },
    {
      label: "店舗",
      key: "shops",
      type: FormType.MultiOptions,
      options: shops.map((s) => ({ id: Number(s.shopId), name: s.name })),
      value: (val: any) => val.map((s: any) => s.shopId),
    },
  ];

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box margin={2}>
          <TextField
            label="通知先・通知種別"
            select
            style={{ margin: "10px", width: "200px" }}
            onChange={(event) =>
              setFilterNotifications(
                notification.filter(
                  (n) =>
                    EnumUtils.mapToEnum(NotificationType, n.type) ===
                    event.target.value
                )
              )
            }
          >
            {Object.entries(NotificationType).map(([, value]) => (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <NotificationTable
        title={"お知らせ"}
        formId="notificationForm"
        forms={forms}
        rows={headers}
        data={filterNotifications}
        addFunc={(formData) => addNotification(companyId, formData)}
        updateFunc={(formData) => updateNotification(companyId, formData)}
        deleteFunc={(item) =>
          deleteNotification(companyId, {
            notificationId: item.notificationId,
          })
        }
        addType={CreateNotificationReq}
        updateType={UpdateNotificationReq}
        values={[
          (s) => s.title,
          (s) => s.content,
          (s) => EnumUtils.mapToEnum(NotificationType, s.type) || "未設定",
          (s) =>
            s.startDate
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  s.startDate,
                  FORMAT_TYPE.YEAR_DAY
                )
              : "未設定",
          (s) =>
            s.endDate
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  s.endDate,
                  FORMAT_TYPE.YEAR_DAY
                )
              : "未設定",
          (s) => (s.isIndefinite ? "オン" : "オフ"),
          (s) => s.sort || "未設定",
          (s) =>
            s.shops
              .map(
                (shopRes: ShopRes) =>
                  shops.find((shop) => shop.shopId === shopRes.shopId)?.name
              )
              .join(",") || "所属なし",
        ]}
      />
    </>
  );
};

const NotificationTable = styled<
  React.FC<
    CommonTableProps<
      NotificationRes,
      CreateNotificationReq,
      UpdateNotificationReq
    >
  >
>(CommonTable)`
  margin-top: 24px;
`;

export default Notification;
