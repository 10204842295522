import { Expose, Type } from "class-transformer";
import CastRes from "../cast/CastRes";
import { CastReportType } from "../../enum/CastReportType";

export default class CastReportRes {
  @Expose() readonly castReportId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly castId!: number;
  @Expose() readonly type!: CastReportType;
  @Type(() => CastRes)
  @Expose()
  readonly cast!: CastRes;
  @Type(() => Boolean)
  @Expose()
  readonly isRead!: boolean;
  @Type(() => Date)
  @Expose()
  readonly createdAt!: Date;
  @Type(() => Date)
  @Expose()
  readonly updatedAt!: Date;
}
