import styled from "styled-components";
import React, { useEffect, useState } from "react";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import CallHistoryRes from "types/res/callHistory/CallHistoryRes";
import CreateCallHistoryReq from "types/req/callHistory/CreateCallHistoryReq";
import UpdateCallHistoryReq from "types/req/callHistory/UpdateCallHistoryReq";
import { FormType } from "components/FormField";
import {
  addCallHistory,
  fetchCallHistory,
  updateCallHistory,
} from "redux/actions/callHistory";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import EnumUtils from "utils/EnumUtils";
import { CallHistoryType } from "types/enum/CallHistoryType";
import {
  Box,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { fetchShops } from "redux/actions/shop";

const headers: { key: keyof CallHistoryRes; label: string }[] = [
  { key: "createdAt", label: "入電日時" },
  { key: "tel", label: "電話番号" },
  { key: "type", label: "カテゴリ" },
  { key: "guestId", label: "顧客名" },
  { key: "shopId", label: "店舗名" },
  { key: "staffId", label: "担当者" },
];
const CallHistory = () => {
  const dispatch = useDispatch();
  const callHistories = useSelector((state) => state.callHistory);
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectTypes, setSelectTypes] = useState<string[]>([
    ...Object.keys(CallHistoryType),
    "すべて",
  ]);
  const [filterCallHistory, setFilterCallHistory] = useState<CallHistoryRes[]>(
    []
  );
  const forms = [
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
  ];

  useEffect(() => {
    dispatch(fetchShops(companyId));
  }, []);

  useEffect(() => {
    dispatch(
      fetchCallHistory(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)
      )
    );
  }, [startDate, endDate]);

  useEffect(() => {
    setFilterCallHistory(
      callHistories.filter((callHistory) => {
        const shopCondition = selectShops.includes("すべて")
          ? true
          : selectShops.includes(String(callHistory.shopId));
        const typeCondition = selectTypes.includes("すべて")
          ? true
          : selectTypes.includes(String(callHistory.type));
        return shopCondition && typeCondition;
      })
    );
  }, [callHistories, selectShops, selectTypes]);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box margin={2} display="flex" alignItems="center">
          <TextField
            type="date"
            label="開始日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(startDate).toFormat(
              FORMAT_TYPE.YEAR_DAY
            )}
            onChange={(event) =>
              setStartDate(
                DateTime.fromISO(event.target.value as string).toJSDate()
              )
            }
          />
          <TextField
            type="date"
            label="終了日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
            onChange={(event) =>
              setEndDate(
                DateTime.fromISO(event.target.value as string).toJSDate()
              )
            }
          />
          <Box>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={selectShops}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectShops((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length === shops.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= shops.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectShops.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel id="select-multiple-cast">カテゴリ</InputLabel>
            <Select
              multiple
              value={selectTypes}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectTypes((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...Object.keys(CallHistoryType).map((type) => type),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length ===
                      Object.keys(CallHistoryType).length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...Object.keys(CallHistoryType).map((type) => type),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <=
                    Object.keys(CallHistoryType).length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-type" />}
              style={{ width: "200px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return Object.entries(CallHistoryType)
                    .filter(([key]) => (selected as string[]).includes(key))
                    .map(([, value]) => value)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectTypes.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {Object.entries(CallHistoryType).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={selectTypes.indexOf(key) !== -1} />
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
      <CallHistoryTable
        fullWidth
        title={"着信履歴"}
        description={
          <Box display="flex" flexDirection="column">
            <Typography style={{ fontSize: "14px" }}>
              着信履歴を取得する場合は、下記のURLを利用してCTI設定を行ってください。
            </Typography>
            <Typography style={{ fontSize: "14px" }}>
              ACDコードがある場合:
              https://staff.no-tel.com/orderList?tel=#電話番号#&acd=#ACDコード#
            </Typography>
            <Typography style={{ fontSize: "14px" }}>
              ACDコードがない場合:
              https://staff.no-tel.com/orderList?tel=#電話番号#
            </Typography>
          </Box>
        }
        formId="castForm"
        forms={forms}
        addFunc={(formData) => addCallHistory(companyId, formData)}
        updateFunc={(formData) => updateCallHistory(companyId, formData)}
        invisibleDeleteIcon
        rows={headers}
        data={filterCallHistory}
        values={[
          (s) =>
            DateTime.fromJSDate(s.createdAt).toFormat(
              FORMAT_TYPE.YEAR_DATE_TIME
            ),
          (s) => s.tel,
          (s) => EnumUtils.mapToEnum(CallHistoryType, s.type) || "未設定",
          (s) => s.guest?.name || "未設定",
          (s) => s.shop?.name || "未設定",
          (s) => s.staff?.name || "未設定",
        ]}
        deleteFunc={() => {}}
      />
    </>
  );
};

const CallHistoryTable = styled<
  React.FC<
    CommonTableProps<CallHistoryRes, CreateCallHistoryReq, UpdateCallHistoryReq>
  >
>(CommonTable)`
  margin-top: 24px;
`;
export default CallHistory;
