import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import { DiscountType } from "../../enum/DiscountType";

export default class DiscountRes {
  @Expose() readonly discountId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly type!: DiscountType;
  @Expose() readonly totalFee!: number;
  @Expose() readonly shopFee!: number;
  @Expose() readonly castFee!: number;
  @Expose() readonly sort!: number | null;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
}
