import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";
import CommonTable, { CommonTableProps } from "../../components/CommonTable";
import {
  addGuest,
  bulkDeleteGuest,
  bulkInsertGuest,
  deleteGuest,
  searchGuest,
  sendMailGuest,
  updateGuest,
} from "redux/actions/guest";
import GuestRes from "../../types/res/guest/GuestRes";
import { FormType } from "components/FormField";
import { simpleFetchGuestCategory } from "redux/actions/guestCategory";
import CreateGuestReq from "../../types/req/guest/CreateGuestReq";
import UpdateGuestReq from "../../types/req/guest/UpdateGuestReq";
import {
  Box,
  Button,
  createStyles,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import CsvUtils, { CsvFieldType } from "utils/CsvUtils";
import CsvImport from "components/CsvImport";
import EnvironmentUtils from "utils/EnviromentUtils";
import { makeStyles } from "@material-ui/core/styles";
import GuestApi from "api/GuestApi";
import GuestCategoryRes from "types/res/guestCategory/GuestCategoryRes";
import { fetchShops } from "redux/actions/shop";
import toastActions, { ToastType } from "redux/actions/toast";

const headers: { key: keyof GuestRes; label: string }[] = [
  { key: "name", label: "名前" },
  { key: "email", label: "メールアドレス" },
  { key: "tel", label: "電話番号" },
  { key: "address", label: "住所" },
  { key: "memo", label: "メモ" },
  { key: "guestCategory", label: "顧客区分" },
  { key: "guestPoints", label: "ポイント" },
  { key: "isSentMail", label: "メール送信" },
  { key: "orders", label: "" },
];
const Guest: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const company = useSelector((state) => state.account.staff.company);
  const guests = useSelector((state) => state.guest);
  const guestCategories = useSelector((state) => state.guestCategory);
  const shops = useSelector((state) => state.shop);
  const [searchReq, setSearchReq] = useState<any>({});
  const [selectGuestCategories, setSelectGuestCategories] = useState<string[]>(
    []
  );
  const [openCsvImport, setOpenCsvImport] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const apiCall = async () => {
      const result: any = await dispatch(simpleFetchGuestCategory(companyId));
      const guestCategoryIds = result.map(
        (r: GuestCategoryRes) => r.guestCategoryId
      );
      setSelectGuestCategories([...guestCategoryIds.map(String), "すべて"]);
      dispatch(searchGuest(companyId, { limit: 100, offset: 0 }));
      setSearchReq({ guestCategoryIds });
      setTotalCount(await GuestApi.count(companyId, {}));
    };
    apiCall();
    dispatch(fetchShops(companyId));
  }, []);

  useEffect(() => {
    const guestCategoryIds = selectGuestCategories.includes("すべて")
      ? []
      : selectGuestCategories.map(Number);
    dispatch(
      searchGuest(companyId, {
        ...searchReq,
        guestCategoryIds,
        limit,
        offset,
      })
    );
  }, [limit, offset]);

  const onChangeForm =
    (key: string, handler: (value: any) => any = (value: any) => value) =>
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | { value: unknown }
      >
    ) => {
      event.persist();
      setSearchReq((prev: any) => ({
        ...prev,
        [key]: handler(event.target.value),
      }));
    };

  const forms: any = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "メールアドレス",
      key: "email",
      type: FormType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
    {
      label: "住所",
      key: "address",
      type: FormType.Text,
    },
    {
      label: "メモ",
      key: "memo",
      type: FormType.Text,
    },
  ];

  if (company.guestCategoryShopFlag) {
    const shopGuestCategory = shops.map((s) => ({
      label: `${s.name}顧客区分`,
      key: "guestGuestCategoryShops",
      type: FormType.SelectGuestGuestCategoryShops,
      shopId: s.shopId,
      options: guestCategories
        .filter((guestCategory) =>
          guestCategory.shops.map((shop) => shop.shopId).includes(s.shopId)
        )
        .map((c) => ({
          id: c.guestCategoryId,
          shopId: s.shopId,
          name: c.name,
        })),
    }));
    forms.push(...shopGuestCategory);
  } else {
    forms.push({
      label: "顧客区分",
      key: "guestCategoryId",
      type: FormType.Select,
      options: guestCategories.map((c) => ({
        id: c.guestCategoryId,
        name: c.name,
      })),
    });
  }

  const csvHeader: any = [
    {
      label: "名前",
      key: "name",
      type: CsvFieldType.Text,
    },
    {
      label: "メールアドレス",
      key: "email",
      type: CsvFieldType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: CsvFieldType.Text,
      unique: true,
    },
    {
      label: "住所",
      key: "address",
      type: CsvFieldType.Text,
    },
    {
      label: "メモ",
      key: "memo",
      type: CsvFieldType.Text,
    },
  ];

  if (company.guestCategoryShopFlag) {
    const csvHeaderShops = shops.map((s) => ({
      label: `${s.name}顧客区分`,
      key: `guestGuestCategoryShops_${s.shopId}`,
      type: CsvFieldType.guestGuestCategoryShops,
      relation: guestCategories,
      relationId: "guestCategoryId",
      relationKey: "name",
      shopId: s.shopId,
    }));
    csvHeader.push(...csvHeaderShops);
  } else {
    csvHeader.push({
      label: "顧客区分",
      key: "guestCategoryId",
      type: CsvFieldType.Object,
      relation: guestCategories,
      relationId: "guestCategoryId",
      relationKey: "name",
    });
  }

  const onClickSearchButton = async () => {
    const guestCategoryIds = selectGuestCategories.includes("すべて")
      ? []
      : selectGuestCategories.map(Number);
    if (offset === 0) {
      dispatch(
        searchGuest(companyId, {
          ...searchReq,
          guestCategoryIds,
          limit,
          offset,
        })
      );
    }
    setOffset(0);
    setTotalCount(
      await GuestApi.count(companyId, { ...searchReq, guestCategoryIds })
    );
  };

  const useStyles = makeStyles(() =>
    createStyles({
      button: {},
    })
  );
  const classes = useStyles();

  const onClickTemplateDownload = () => {
    const header = csvHeader.map((h: any) => h.label);
    const data = [header];
    CsvUtils.download("guest", data);
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box margin={2} display="flex" alignItems="center">
          <TextField
            label="名前"
            style={{ margin: "10px", width: "200px" }}
            value={searchReq?.name || ""}
            onChange={onChangeForm("name")}
          />
          <TextField
            label="メールアドレス"
            style={{ margin: "10px", width: "200px" }}
            value={searchReq?.email || ""}
            onChange={onChangeForm("email")}
          />
          <TextField
            label="電話番号"
            style={{ margin: "10px", width: "200px" }}
            value={searchReq?.tel || ""}
            onChange={onChangeForm("tel")}
          />
          <TextField
            label="住所"
            style={{ margin: "10px", width: "200px" }}
            value={searchReq?.address || ""}
            onChange={onChangeForm("address")}
          />
          <Box>
            <InputLabel id="select-multiple-shop">顧客カテゴリ</InputLabel>
            <Select
              multiple
              value={selectGuestCategories}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectGuestCategories((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...guestCategories.map((guestCategory) =>
                        String(guestCategory.guestCategoryId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length ===
                      guestCategories.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...guestCategories.map((guestCategory) =>
                        String(guestCategory.guestCategoryId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <=
                    guestCategories.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-guest-category" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return guestCategories
                    .filter((guestCategory) =>
                      (selected as string[]).includes(
                        String(guestCategory.guestCategoryId)
                      )
                    )
                    .map((guestCategory) => guestCategory.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectGuestCategories.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {guestCategories.map((guestCategory) => (
                <MenuItem
                  key={guestCategory.guestCategoryId}
                  value={String(guestCategory.guestCategoryId)}
                >
                  <Checkbox
                    checked={
                      selectGuestCategories.indexOf(
                        String(guestCategory.guestCategoryId)
                      ) !== -1
                    }
                  />
                  <ListItemText primary={guestCategory.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickSearchButton}
          >
            検索
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(EnvironmentUtils.getGuestDomain())}
            style={{ marginLeft: "30px" }}
          >
            ゲストモバイルへ
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenCsvImport((prev) => !prev)}
            style={{ marginLeft: "30px" }}
          >
            csvインポート
          </Button>
        </Box>
        {openCsvImport && (
          <CsvImport
            templatePath={"/csv/guest.csv"}
            templateName={"guest_template.csv"}
            csvHeader={csvHeader}
            addType={CreateGuestReq}
            addFunc={(formData) => bulkInsertGuest(companyId, formData)}
            templateDownload={() => onClickTemplateDownload()}
          />
        )}
      </Box>
      <GuestTable
        title={"顧客管理"}
        formId="guestForm"
        forms={forms}
        rows={headers}
        data={guests}
        addType={CreateGuestReq}
        updateType={UpdateGuestReq}
        addFunc={(formData) => addGuest(companyId, formData)}
        updateFunc={(formData) => updateGuest(companyId, formData)}
        deleteFunc={(item: any) =>
          deleteGuest(companyId, {
            guestId: item.guestId,
          })
        }
        bulkDeleteFunc={(item) =>
          bulkDeleteGuest(
            companyId,
            item.map((value: GuestRes) => ({ guestId: value.guestId }))
          )
        }
        totalCount={totalCount}
        offset={offset}
        limit={limit}
        setLimit={setLimit}
        setOffset={setOffset}
        values={[
          (g) => g.name || "未設定",
          (g) => g.email || "未設定",
          (g) => g.tel || "未設定",
          (g) => g.address || "未設定",
          (g) => g.memo || "未設定",
          (g) =>
            company.guestCategoryShopFlag
              ? (
                  <Box display={"flex"} flexDirection={"column"}>
                    {g.guestGuestCategoryShops?.map((g) => (
                      <Typography
                        key={g.guestGuestCategoryShopId}
                        style={{ fontSize: "10px" }}
                      >
                        {g.shop?.name}:{g.guestCategory?.name}
                      </Typography>
                    ))}
                  </Box>
                ) || "未設定"
              : g.guestCategory?.name || "未設定",
          (g) =>
            g.guestPoints?.reduce(
              (g, guestPoint) => g + guestPoint?.point,
              0
            ) || 0,
          (g) => (
            <Button
              variant="contained"
              className={classes.button}
              color="default"
              onClick={async () => {
                const result = window.confirm("本当にメールを送信しますか？");
                if (!result) {
                  return;
                }
                await dispatch(sendMailGuest(companyId, g.guestId));
                await dispatch(
                  toastActions.showToast({
                    text: "メール送信しました",
                    type: ToastType.Success,
                  })
                );
              }}
            >
              {"メール送信"}
            </Button>
          ),
          (g) => (
            <Link href={`/orderList/guest/${g.guestId}`} target="_blank">
              履歴確認
            </Link>
          ),
        ]}
      />
    </>
  );
};

const GuestTable = styled<
  React.FC<CommonTableProps<GuestRes, CreateGuestReq, UpdateGuestReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default Guest;
