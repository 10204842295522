import { Expose, Type } from "class-transformer";
import MediumRes from "types/res/medium/MediumRes";

export default class MediumPostPeriodRes {
  @Expose() readonly mediumPostPeriodId!: number;
  @Expose() readonly mediumId!: number;
  @Expose()
  readonly startDate!: string;
  @Expose()
  readonly endDate!: string | null;
  @Type(() => MediumRes)
  @Expose()
  readonly medium!: MediumRes;
}
