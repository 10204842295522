import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty } from "class-validator";

export default class DeleteCosplayReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly cosplayId: number;

  constructor(arg: { cosplayId: number }) {
    arg = arg || {};
    this.cosplayId = arg.cosplayId;
  }
}
