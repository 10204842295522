import { IsNotEmpty } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";

export default class DeleteStaffTaskReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly staffTaskId: number;

  constructor(arg: { staffTaskId: number }) {
    arg = arg || {};
    this.staffTaskId = arg.staffTaskId;
  }
}
