import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import CastRes from "types/res/cast/CastRes";
import {
  AppBar,
  Button,
  createStyles,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@material-ui/core";
import CastApi from "api/CastApi";
import { fetchCastCategory } from "redux/actions/castCategory";
import { CalendarToday } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTime } from "luxon";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import { putCollection, putPayoff, spFetchPayoff } from "redux/actions/payoff";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import OrderRes from "types/res/order/OrderRes";
import EnumUtils from "utils/EnumUtils";
import OrderStatus from "types/enum/OrderStatus";
import { fetchCastShift } from "redux/actions/castShift";
import Checkbox from "@material-ui/core/Checkbox";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `payoff-tab-${index}`,
    "aria-controls": `payoff-tabpanel-${index}`,
  };
}

const SpPayoff = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const castCategories = useSelector((state) => state.castCategory);
  const castShift = useSelector((state) => state.castShift);
  const payoff = useSelector((state) => state.payoff);
  const [casts, setCasts] = useState<CastRes[]>([]);
  const [selectCasts, setSelectCasts] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(
    DateTime.local().toJSDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    DateTime.local().toJSDate()
  );
  const [activeTab, setActiveTab] = useState(0);
  const [totalNumbers, setTotalNumbers] = useState({
    sales: 0,
    castFee: 0,
    shopFee: 0,
  });
  const enrolledCastCategory = castCategories.find((c) =>
    c.name.includes("在籍")
  );
  const unSettledPayoff = payoff.filter((payoff) => !payoff.payoff);
  const unCollectReceivablePayoff = payoff.filter(
    (payoff) => !payoff.collectReceivable
  );
  const completePayoff = payoff.filter((payoff) => payoff.payoff);

  useEffect(() => {
    dispatch(fetchCastCategory(companyId));
  }, [dispatch, companyId]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    dispatch(
      fetchCastShift(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)
      )
    );
  }, [startDate, endDate]);

  useEffect(() => {
    if (!castCategories.length) return;
    const apiCall = async () => {
      const c = await CastApi.findAllWithPagination(companyId, {
        limit: 100,
        offset: 0,
        castCategoryIds: enrolledCastCategory
          ? [enrolledCastCategory?.castCategoryId]
          : undefined,
      });
      setCasts((prev) =>
        Array.from(
          new Map([...prev, ...c].map((cast) => [cast.castId, cast])).values()
        )
      );
    };
    apiCall();
  }, [castCategories]);

  useEffect(() => {
    const uniqueCast = Array.from(
      new Map(castShift.map((cs) => [cs.castId, cs])).values()
    );
    setSelectCasts([
      ...uniqueCast.map((cast) => String(cast.castId)),
      "すべて",
    ]);
  }, [castShift]);

  useEffect(() => {
    if (
      !selectCasts.filter((c) => c !== "すべて").length ||
      !startDate ||
      !endDate
    )
      return;
    dispatch(
      spFetchPayoff(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        selectCasts.includes("すべて")
          ? selectCasts.filter((c) => c !== "すべて").map((c) => Number(c))
          : selectCasts.map((c) => Number(c))
      )
    );
  }, [selectCasts, startDate, endDate]);

  useEffect(() => {
    switch (activeTab) {
      case 0: {
        setTotalNumbers({
          sales: payoff.reduce((sum, o) => sum + (o.totalFee || 0), 0),
          castFee: payoff.reduce((sum, o) => sum + (o.totalCastFee || 0), 0),
          shopFee: payoff.reduce((sum, o) => sum + (o.totalShopFee || 0), 0),
        });
        break;
      }
      case 1: {
        setTotalNumbers({
          sales: unCollectReceivablePayoff.reduce(
            (sum, o) => sum + (o.totalFee || 0),
            0
          ),
          castFee: unCollectReceivablePayoff.reduce(
            (sum, o) => sum + (o.totalCastFee || 0),
            0
          ),
          shopFee: unCollectReceivablePayoff.reduce(
            (sum, o) => sum + (o.totalShopFee || 0),
            0
          ),
        });
        break;
      }
      case 2: {
        setTotalNumbers({
          sales: unSettledPayoff.reduce((sum, o) => sum + (o.totalFee || 0), 0),
          castFee: unSettledPayoff.reduce(
            (sum, o) => sum + (o.totalCastFee || 0),
            0
          ),
          shopFee: unSettledPayoff.reduce(
            (sum, o) => sum + (o.totalShopFee || 0),
            0
          ),
        });
        break;
      }
      case 3: {
        setTotalNumbers({
          sales: completePayoff.reduce((sum, o) => sum + (o.totalFee || 0), 0),
          castFee: completePayoff.reduce(
            (sum, o) => sum + (o.totalCastFee || 0),
            0
          ),
          shopFee: completePayoff.reduce(
            (sum, o) => sum + (o.totalShopFee || 0),
            0
          ),
        });
        break;
      }
    }
  }, [activeTab, payoff]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };
  return (
    <Box display="flex" flexDirection="column" margin={1}>
      <Box
        style={{
          position: "sticky",
          top: "56px",
          left: "0",
          backgroundColor: "white",
          zIndex: "1000",
        }}
      >
        <Box display="flex" flexDirection={"column"}>
          <Select
            style={{ padding: "5px 5%" }}
            multiple
            fullWidth
            value={selectCasts}
            onChange={(event) => {
              setSelectCasts((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...casts.map((cast) => String(cast.castId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length === casts.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...casts.map((cast) => String(cast.castId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= casts.length
                ) {
                  const value = event.target.value as any[];
                  const id = (value as any[]).pop();
                  if (prev.find((p) => p === String(id))) {
                    return prev.filter((p) => p !== String(id));
                  } else {
                    return [...prev, String(id)];
                  }
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return casts
                  .filter((castShift) =>
                    (selected as string[]).includes(String(castShift.castId))
                  )
                  .map((cast) => cast.displayName)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectCasts.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {casts.map((cast) => (
              <MenuItem key={cast.castId} value={cast.castId}>
                <Checkbox
                  checked={selectCasts.indexOf(String(cast.castId)) !== -1}
                />
                <ListItemText primary={cast.displayName} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display="flex" paddingY={1}>
          <Box display="flex" flexDirection="column" flexBasis="100%">
            <Typography>総売上{totalNumbers.sales}円</Typography>
            <Typography>総支給{totalNumbers.castFee}円</Typography>
            <Typography>粗利{totalNumbers.shopFee}円</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <CalendarToday fontSize="small" />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                value={startDate}
                onChange={(event) => {
                  if (!event?.toDate() || !endDate) return;
                  const dateDiff = DateTime.fromJSDate(endDate).diff(
                    DateTime.fromJSDate(event?.toDate()),
                    "days"
                  ).days;
                  if (dateDiff > 31) {
                    return alert("期間は31日以内に設定してください。");
                  }
                  setStartDate(event?.toDate() || null);
                }}
                animateYearScrolling
                format="YYYY/MM/DD"
              />
              ~
              <DatePicker
                value={endDate}
                onChange={(event) => {
                  if (!event?.toDate() || !startDate) return;
                  const dateDiff = DateTime.fromJSDate(event?.toDate()).diff(
                    DateTime.fromJSDate(startDate),
                    "days"
                  ).days;
                  if (dateDiff > 31) {
                    return alert("期間は31日以内に設定してください。");
                  }
                  setEndDate(event?.toDate() || null);
                }}
                animateYearScrolling
                format="YYYY/MM/DD"
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <AppBar position="static">
            <Tabs value={activeTab} onChange={handleChange} variant="fullWidth">
              <Tab label="全て" {...a11yProps(0)} />
              <Tab label="未回収" {...a11yProps(1)} />
              <Tab label="未精算" {...a11yProps(2)} />
              <Tab label="完了" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
        </Box>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <PayoffItem payoffs={payoff} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <PayoffItem payoffs={unCollectReceivablePayoff} />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <PayoffItem payoffs={unSettledPayoff} />
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <PayoffItem payoffs={completePayoff} />
      </TabPanel>
    </Box>
  );
};

type PayoffProps = {
  payoffs: OrderRes[];
};
const PayoffItem = ({ payoffs }: PayoffProps) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  const onClickCollectReceivable = (order: OrderRes) => {
    if (
      EnumUtils.mapToEnum(OrderStatus, order.status) === OrderStatus.booking
    ) {
      const result = window.confirm(
        "店舗マスター未満のアカウントでは完了にすると変更が出来ませんがよろしいでしょうか？"
      );
      if (!result) {
        return false;
      }
    }
    if (order.collectReceivable) {
      const result = window.confirm(
        "ポイントがリセットされ、受注は予約状態に戻りますがよろしいでしょうか？"
      );
      if (!result) {
        return false;
      }
    }
    dispatch(putCollection(companyId, order.orderId));
  };

  const onClickPayoff = (orderId: number) => {
    dispatch(putPayoff(companyId, orderId));
  };

  if (!payoffs.length)
    return (
      <Box display="flex" justifyContent="center" marginY={2}>
        データがありません。
      </Box>
    );
  return (
    <>
      {payoffs.map((payoff) => (
        <Box
          display="flex"
          alignItems="flex-end"
          marginTop={2}
          borderBottom={1}
          paddingBottom={1}
          key={payoff.orderId}
        >
          <Box display="flex" flexDirection="column" flexBasis={"100%"}>
            <Box display="flex">
              <Typography style={{ width: "40%" }}>受付日</Typography>
              <Typography>
                {DateTime.fromJSDate(payoff.orderDate).toFormat(
                  FORMAT_TYPE.YEAR_DAY
                )}
              </Typography>
            </Box>
            <Box display="flex" marginTop={"4px"}>
              <Typography style={{ width: "40%" }}>顧客名</Typography>
              <Typography>{payoff.guest?.name || "未設定"}</Typography>
            </Box>
            <Box display="flex" marginTop={"4px"}>
              <Typography style={{ width: "40%" }}>店舗名</Typography>
              <Typography>{payoff.shop?.name || "未設定"}</Typography>
            </Box>
            <Box display="flex" marginTop={"4px"}>
              <Typography style={{ width: "40%" }}>キャスト名</Typography>
              <Typography>{payoff.castName?.name || "未設定"}</Typography>
            </Box>
            <Box display="flex" marginTop={"4px"}>
              <Typography style={{ width: "40%" }}>コース名</Typography>
              <Typography>{payoff.course?.name || "未設定"}</Typography>
            </Box>
            <Box display="flex" marginTop={"4px"}>
              <Typography style={{ width: "40%" }}>住所</Typography>
              <Typography>
                {payoff.orderAddress} {payoff.orderAddress2}
              </Typography>
            </Box>
            <Box display="flex" marginTop={"4px"}>
              <Typography style={{ width: "40%" }}>出発</Typography>
              <Typography>
                {payoff.departureTime
                  ? DateTime.fromJSDate(payoff.departureTime).toFormat(
                      FORMAT_TYPE.TIME
                    )
                  : "未設定"}
              </Typography>
            </Box>
            <Box display="flex" marginTop={"4px"}>
              <Typography style={{ width: "40%" }}>売上</Typography>
              <Typography>{payoff.totalFee}円</Typography>
            </Box>
            <Box display="flex" marginTop={"4px"}>
              <Typography style={{ width: "40%" }}>支給</Typography>
              <Typography>{payoff.totalCastFee}円</Typography>
            </Box>
            <Box display="flex" marginTop={"4px"}>
              <Typography style={{ width: "40%" }}>店舗売上</Typography>
              <Typography>{payoff.totalShopFee}円</Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Box>
              <Button
                variant="contained"
                className={classes.button}
                color={!payoff.collectReceivable ? "primary" : "default"}
                onClick={() => onClickCollectReceivable(payoff)}
              >
                回収
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                className={classes.button}
                color={!payoff.payoff ? "secondary" : "default"}
                onClick={() => onClickPayoff(payoff.orderId)}
              >
                精算
              </Button>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};
export default SpPayoff;
