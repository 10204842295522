import { reducerWithInitialState } from "typescript-fsa-reducers";
import castNameActions from "redux/actions/castName";
import CastNameRes from "types/res/castName/CastNameRes";
export type CastNameState = CastNameRes[];

const initialState = [] as CastNameState;

const castNameReducer = reducerWithInitialState(initialState)
  .case(castNameActions.addCastNameAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(castNameActions.updateCastNameAction.done, (state, payload) => {
    return state.map((item) =>
      item.castNameId === payload.params.castNameId ? payload.result : item
    );
  })
  .case(castNameActions.deleteCastNameAction.done, (state, payload) => {
    return state.filter(
      (item) => item.castNameId !== payload.params.castNameId
    );
  })
  .case(castNameActions.fetchOneCastNameAction.done, (_, payload) => {
    return [payload.result];
  })
  .case(castNameActions.fetchCastNameAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(
    castNameActions.fetchCastNameWithPaginationAction.done,
    (state, payload) => {
      return [...payload.result];
    }
  )
  .case(castNameActions.bulkInsertCastNameAction.done, (state, payload) => {
    return [...state, ...payload.result];
  });
export default castNameReducer;
