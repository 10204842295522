import { IsNotEmpty } from "class-validator";
import { DataValidationError } from "../../../constants/DataValidationError";

export default class DeleteOrderReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  readonly orderId: number;
  constructor(arg: { orderId: number }) {
    arg = arg || {};
    this.orderId = arg.orderId;
  }
}
