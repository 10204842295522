import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Table,
  TableContainer,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { fetchShops } from "redux/actions/shop";
import { fetchNotelClass } from "redux/actions/notelClass";
import {
  fetchCastRanking,
  fetchCastRankingEachData,
} from "redux/actions/castRanking";
import { fetchNomination } from "redux/actions/nomination";
import { fetchActualWorkCastList } from "redux/actions/cast";
import { fetchCastCategory } from "redux/actions/castCategory";
import CastRankingTableHeader from "pages/CastAnalyticsRanking/Table/CastRankingTableHeader";
import CastRankingTableBody from "pages/CastAnalyticsRanking/Table/CastRankingTableBody";
import { Info } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import CsvUtils from "utils/CsvUtils";
import MathUtils from "utils/MathUtils";

const CastAnalyticsRanking: React.FC = () => {
  const dispatch = useDispatch();
  const shops = useSelector((state) => state.shop);
  const notelClasses = useSelector((state) => state.notelClass);
  const nominations = useSelector((state) => state.nomination);
  const companyId = useSelector((state) => state.account.staff.companyId);
  const castRaking = useSelector((state) => state.castRanking.total);
  const castRakingEachData = useSelector((state) => state.castRanking.casts);
  const casts = useSelector((state) => state.cast);
  const castCategories = useSelector((state) => state.castCategory);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ days: 30, hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectNotelClasses, setSelectNotelClasses] = useState<string[]>([]);
  const [selectCastCategories, setSelectCastCategories] = useState<string[]>(
    []
  );
  const [selectNominationId, setSelectNominationId] = useState<number>();
  const [workingResult, setWorkingResult] = useState(true);
  const [enrolled, setEnrolled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRankingDataLoading, setIsRankingDataLoading] = useState(false);
  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchNotelClass(companyId));
    dispatch(fetchNomination(companyId));
    dispatch(fetchCastCategory(companyId));
  }, [companyId]);

  useEffect(() => {
    if (
      !(
        selectShops.filter((shopId) => shopId !== "すべて").length &&
        selectNotelClasses.filter((notelClassId) => notelClassId !== "すべて")
          .length &&
        selectCastCategories.filter(
          (castCategoryId) => castCategoryId !== "すべて"
        ).length &&
        startDate &&
        endDate
      )
    )
      return;
    const apiCall = async () => {
      setIsLoading(true);
      await dispatch(
        fetchActualWorkCastList(
          companyId,
          DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          selectShops.filter((shopId) => shopId !== "すべて").join(","),
          selectNotelClasses
            .filter((notelClassId) => notelClassId !== "すべて")
            .join(","),
          selectCastCategories
            .filter((castCategoryId) => castCategoryId !== "すべて")
            .join(","),
          enrolled,
          workingResult
        )
      );
      setIsLoading(false);
    };
    apiCall();
  }, [
    companyId,
    startDate,
    endDate,
    selectShops,
    selectNotelClasses,
    selectCastCategories,
    enrolled,
    workingResult,
  ]);

  useEffect(() => {
    if (
      !(
        selectShops.filter((shopId) => shopId !== "すべて").length &&
        selectNotelClasses.filter((notelClassId) => notelClassId !== "すべて")
          .length &&
        selectCastCategories.filter(
          (castCategoryId) => castCategoryId !== "すべて"
        ).length &&
        startDate &&
        endDate
      )
    )
      return;
    const apiCall = async () => {
      setIsRankingDataLoading(true);
      await dispatch(
        fetchCastRanking(
          companyId,
          DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          selectShops.filter((shopId) => shopId !== "すべて").join(","),
          selectNotelClasses
            .filter((notelClassId) => notelClassId !== "すべて")
            .join(","),
          selectCastCategories
            .filter((castCategoryId) => castCategoryId !== "すべて")
            .join(",")
        )
      );
      setIsRankingDataLoading(false);
    };
    apiCall();
  }, [
    companyId,
    startDate,
    endDate,
    selectShops,
    selectNotelClasses,
    selectCastCategories,
  ]);

  useEffect(() => {
    if (!casts.length) return;
    dispatch(
      fetchCastRankingEachData(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        casts.map((cast) => cast.castId).join(",")
      )
    );
  }, [companyId, startDate, endDate, casts]);

  useEffect(() => {
    setSelectNominationId(nominations[0]?.nominationId);
  }, [nominations]);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    setSelectNotelClasses([
      ...notelClasses.map((notelClass) => String(notelClass.notelClassId)),
      "すべて",
    ]);
  }, [notelClasses]);

  useEffect(() => {
    setSelectCastCategories(
      castCategories
        .filter((castCategory) => castCategory.name.includes("在籍"))
        .map((castCategory) => String(castCategory.castCategoryId))
    );
  }, [castCategories]);
  const csvDownload = async () => {
    const fileName = `キャストランキングデータ${DateTime.fromJSDate(
      startDate
    ).toFormat(FORMAT_TYPE.YEAR_DAY)}_${DateTime.fromJSDate(endDate).toFormat(
      FORMAT_TYPE.YEAR_DAY
    )}`;
    const rowData = [
      [
        "項目名",
        "所属店舗",
        "クラス",
        "出勤時間",
        "総出勤時間",
        "平均出勤時間(日)",
        "総本数",
        ...nominations.map((nomination) => nomination.name),
        ...nominations.map((nomination) => `${nomination.name}率`),
        "平均総本数/日",
        ...nominations.map((nomination) => `平均${nomination.name}本数`),
        "リピート率",
        "離脱率",
        "売上",
        "平均単価",
        "キャスト給",
        "キャスト時給",
        "遅刻",
        "当欠",
        "アンケート",
      ],
      [
        "トータル",
        "-",
        "-",
        castRaking.commutingDays,
        castRaking.commutingTimes,
        castRaking.commutingTimes / castRaking.commutingDays,
        castRaking.totalOrderCount,
        ...nominations.map(
          (nomination) =>
            castRaking[`nomination${nomination.nominationId}Count`]
        ),
        ...nominations.map(() => "-"),
        "-",
        ...nominations.map(
          (nomination) =>
            castRaking[`nomination${nomination.nominationId}Count`] /
              castRaking.commutingDays || 0
        ),
        "-",
        "-",
        castRaking.sales,
        "-",
        castRaking.castFee,
        "-",
        castRaking.tardyCount,
        castRaking.absenceCount,
        "-",
      ],
      [
        "キャスト平均",
        "-",
        "-",
        castRaking.commutingDays / casts.length || 0,
        castRaking.commutingTimes / casts.length || 0,
        castRaking.commutingTimes / castRaking.commutingDays || 0,
        castRaking.totalOrderCount / casts.length || 0,
        ...nominations.map(
          (nomination) =>
            castRaking[`nomination${nomination.nominationId}Count`] /
              casts.length || 0
        ),
        ...nominations.map(
          (nomination) =>
            (castRaking[`nomination${nomination.nominationId}Count`] /
              castRaking.totalOrderCount) *
              100 || 0
        ),
        castRaking?.totalOrderCount /
          casts.length /
          (castRaking.commutingDays / casts.length) || 0,
        ...nominations.map(
          (nomination) =>
            castRaking[`nomination${nomination.nominationId}Count`] /
              castRaking.commutingDays || 0
        ),
        (1 - castRaking.breakCount / castRaking.breakBaseCount) * 100 || 0,
        (castRaking.breakCount / castRaking.breakBaseCount) * 100 || 0,
        castRaking.sales / casts.length || 0,
        castRaking.sales / castRaking.totalOrderCount || 0,
        castRaking.castFee / casts.length || 0,
        castRaking.castFee / castRaking.commutingTimes || 0,
        castRaking.tardyCount / casts.length || 0,
        castRaking.absenceCount / casts.length || 0,
        castRaking.averageScore / castRaking.totalOrderCount || 0,
      ],
      ...casts.map((cast) => {
        const castData = castRakingEachData[cast.castId];
        if (!castData) return;
        return [
          cast.displayName,
          cast?.shops?.map((shop) => shop.name).join("/"),
          cast?.castNames.map((castName) => castName.notelClass.name).join("/"),
          castData.commutingDays,
          castData.commutingTimes,
          castData.commutingTimes / castData.commutingDays || 0,
          castData.totalOrderCount,
          ...nominations.map(
            (nomination) =>
              castData[`nomination${nomination.nominationId}Count`] || 0
          ),
          ...nominations.map(
            (nomination) =>
              (castData[`nomination${nomination.nominationId}Count`] /
                castData.totalOrderCount) *
                100 || 0
          ),
          castData.totalOrderCount / castData.commutingDays,
          ...nominations.map(
            (nomination) =>
              castData[`nomination${nomination.nominationId}Count`] /
                castData.commutingDays || 0
          ),
          (1 - castData.breakCount / castData.breakBaseCount) * 100 || 0,
          (castData.breakCount / castData.breakBaseCount) * 100 || 0,
          castData.sales,
          castData.sales / castData.totalOrderCount || 0,
          castData.castFee || 0,
          castData.castFee / castData.commutingTimes || 0,
          castData.tardyCount,
          castData.absenceCount,
          castData.averageScore / castData.totalOrderCount || 0,
        ];
      }),
    ];
    const data = rowData
      .filter(Boolean)
      .map((row) =>
        row?.map((d) => (typeof d === "number" ? MathUtils.roundToTwo(d) : d))
      );
    await CsvUtils.download(fileName, data);
  };
  return (
    <Box display="flex" flexDirection="column">
      <Header
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectShops={selectShops}
        selectNotelClasses={selectNotelClasses}
        setSelectShops={setSelectShops}
        setSelectNotelClasses={setSelectNotelClasses}
        selectCastCategories={selectCastCategories}
        setSelectCastCategories={setSelectCastCategories}
        workingResult={workingResult}
        setWorkingResult={setWorkingResult}
        enrolled={enrolled}
        setEnrolled={setEnrolled}
        csvDownload={csvDownload}
        isDataLoading={isRankingDataLoading || isLoading}
      />
      {isLoading || isRankingDataLoading ? (
        <CircularProgress />
      ) : casts.length && castRakingEachData ? (
        <Box marginTop={2}>
          <Typography style={{ margin: "10px 0 0 10px", fontWeight: "bold" }}>
            ランキング
          </Typography>
          <TableContainer style={{ maxHeight: 800 }}>
            <Table stickyHeader>
              <CastRankingTableHeader
                selectNominationId={selectNominationId}
                setSelectNominationId={setSelectNominationId}
                nominations={nominations}
                link={true}
              />
              <CastRankingTableBody
                total={false}
                dataArray={[
                  ...[
                    {
                      id: "total",
                      name: "トータル",
                      commutingDays: castRaking.commutingDays || 0,
                      commutingTimes: castRaking.commutingTimes || 0,
                      averageCommutingTimes:
                        Math.round(
                          ((castRaking.commutingTimes || 0) /
                            (castRaking.commutingDays || 0)) *
                            10
                        ) / 10 || 0,
                      totalOrderCount: castRaking.totalOrderCount || 0,
                      nominationOrderCount:
                        castRaking[`nomination${selectNominationId}Count`] || 0,
                      averageNominationOrderCount:
                        Math.round(
                          (castRaking[`nomination${selectNominationId}Count`] ||
                            0 / castRaking.commutingDays ||
                            0) * 100
                        ) / 100,
                      sales: castRaking.sales || 0,
                      castFee: castRaking.castFee || 0,
                      tardyCount: castRaking.tardyCount || 0,
                      absenceCount: castRaking.absenceCount || 0,
                    },
                    {
                      id: "castAverage",
                      name: "キャスト平均",
                      commutingDays: Math.round(
                        castRaking.commutingDays / casts.length || 0
                      ),
                      commutingTimes: Math.round(
                        castRaking.commutingTimes / casts.length || 0
                      ),
                      averageCommutingTimes:
                        Math.round(
                          ((castRaking.commutingTimes || 0) /
                            (castRaking.commutingDays || 0)) *
                            10
                        ) / 10 || 0,
                      totalOrderCount: Math.round(
                        castRaking.totalOrderCount / casts.length || 0
                      ),
                      nominationOrderCount:
                        Math.round(
                          (castRaking[`nomination${selectNominationId}Count`] /
                            casts.length) *
                            10
                        ) / 10 || 0,
                      nominationOrderRate:
                        Math.round(
                          (castRaking[`nomination${selectNominationId}Count`] /
                            castRaking.totalOrderCount) *
                            10000
                        ) / 100 || 0,
                      averageTotalOrderCount:
                        Math.round(
                          (castRaking?.totalOrderCount /
                            casts.length /
                            (castRaking.commutingDays / casts.length)) *
                            100
                        ) / 100 || 0,
                      averageNominationOrderCount:
                        Math.round(
                          (castRaking[`nomination${selectNominationId}Count`] /
                            castRaking.commutingDays || 0) * 100
                        ) / 100,
                      repeatRate: Math.round(
                        (1 -
                          (castRaking.breakCount / castRaking.breakBaseCount ||
                            0)) *
                          100
                      ),
                      breakRate: Math.round(
                        (castRaking.breakCount / castRaking.breakBaseCount ||
                          0) * 100
                      ),
                      sales: Math.round(castRaking.sales / casts.length || 0),
                      averageSales:
                        Math.round(
                          castRaking.sales / castRaking.totalOrderCount
                        ) || 0,
                      castFee: Math.round(
                        castRaking.castFee / casts.length || 0
                      ),
                      castFeePerHour: Math.round(
                        castRaking.castFee / castRaking.commutingTimes
                      ),
                      tardyCount: Math.round(
                        castRaking.tardyCount / casts.length || 0
                      ),
                      absenceCount: Math.round(
                        castRaking.absenceCount / casts.length || 0
                      ),
                      score:
                        Math.round(
                          castRaking.averageScore / castRaking.totalOrderCount
                        ) || 0,
                    },
                  ],
                  ...Object.entries(castRakingEachData).map(
                    ([castId, data]) => {
                      const cast = casts.find(
                        (cast) => cast.castId === Number(castId)
                      );
                      return {
                        id: cast?.castId || "",
                        name: cast?.displayName || "",
                        shopName: cast?.shops
                          ?.map((shop) => shop.name)
                          .join("/"),
                        notelClassName: cast?.castNames
                          .map((castName) => castName.notelClass.name)
                          .join("/"),
                        commutingDays: data.commutingDays || 0,
                        commutingTimes: data.commutingTimes || 0,
                        averageCommutingTimes:
                          Math.round(
                            ((data.commutingTimes || 0) /
                              (data.commutingDays || 0)) *
                              10
                          ) / 10 || 0,
                        totalOrderCount: data.totalOrderCount || 0,
                        nominationOrderCount:
                          data[`nomination${selectNominationId}Count`] || 0,
                        nominationOrderRate:
                          Math.round(
                            ((data[`nomination${selectNominationId}Count`] ||
                              0) /
                              (data.totalOrderCount || 0)) *
                              10000
                          ) / 100 || 0,
                        averageTotalOrderCount:
                          Math.round(
                            ((data.totalOrderCount || 0) /
                              (data.commutingDays || 0)) *
                              100
                          ) / 100 || 0,
                        averageNominationOrderCount:
                          Math.round(
                            (data[`nomination${selectNominationId}Count`] /
                              (data.commutingDays || 0)) *
                              100
                          ) / 100 || 0,
                        repeatRate: Math.round(
                          (1 - (data.breakCount / data.breakBaseCount || 0)) *
                            100
                        ),
                        breakRate: Math.round(
                          (data.breakCount / data.breakBaseCount || 0) * 100
                        ),
                        sales: data.sales || 0,
                        averageSales:
                          Math.round(
                            data.sales / (data.totalOrderCount || 0)
                          ) || 0,
                        castFee: data.castFee || 0,
                        castFeePerHour: Math.round(
                          data.castFee / data.commutingTimes
                        ),
                        tardyCount: data.tardyCount || 0,
                        absenceCount: data.absenceCount || 0,
                        score: data.totalOrderCount
                          ? Math.round(data.averageScore / data.totalOrderCount)
                          : 0,
                        link: `/castAnalyticsRanking/cast/${cast?.castId}`,
                      };
                    }
                  ),
                ]}
              />
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box>
          <Typography>データがありません。条件を変更してください。</Typography>
        </Box>
      )}
    </Box>
  );
};

type HeaderProps = {
  startDate: Date;
  setStartDate: any;
  endDate: Date;
  setEndDate: any;
  selectShops: string[];
  selectNotelClasses: string[];
  setSelectShops: any;
  setSelectNotelClasses: any;
  selectCastCategories: string[];
  setSelectCastCategories: any;
  workingResult: boolean;
  setWorkingResult: any;
  enrolled: boolean;
  setEnrolled: any;
  csvDownload: any;
  isDataLoading: boolean;
};

const Header = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectShops,
  selectNotelClasses,
  setSelectShops,
  setSelectNotelClasses,
  selectCastCategories,
  setSelectCastCategories,
  workingResult,
  setWorkingResult,
  enrolled,
  setEnrolled,
  csvDownload,
  isDataLoading,
}: HeaderProps) => {
  const castCategories = useSelector((state) => state.castCategory);
  const shops = useSelector((state) => state.shop);
  const notelClasses = useSelector((state) => state.notelClass);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  return (
    <Box margin={2} display="flex" alignItems="center">
      <TextField
        type="date"
        label="開始日"
        style={{ margin: "10px", width: "200px" }}
        value={DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
        onChange={(event) => {
          const newStartDate = DateTime.fromISO(event.target.value as string)
            .startOf("day")
            .toJSDate();
          if (endDate < newStartDate) {
            alert("開始日は終了日より前に設定してください");
          } else {
            setStartDate(newStartDate);
          }
        }}
      />
      <TextField
        type="date"
        label="終了日"
        style={{ margin: "10px", width: "200px" }}
        value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
        onChange={(event) => {
          const newEndDate = DateTime.fromISO(event.target.value as string)
            .startOf("day")
            .toJSDate();
          if (newEndDate < startDate) {
            alert("終了日は開始日より後に設定してください。");
          } else {
            setEndDate(newEndDate);
          }
        }}
      />
      <Box>
        <InputLabel id="select-multiple-shop">店舗</InputLabel>
        <Select
          multiple
          value={selectShops}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSelectShops((prev: string[]) => {
              if (
                prev.includes("すべて") &&
                (event.target.value as string[]).indexOf("すべて") === -1
              ) {
                return [];
              } else if (
                !prev.includes("すべて") &&
                (event.target.value as string[]).indexOf("すべて") !== -1
              ) {
                return [...shops.map((shop) => String(shop.shopId)), "すべて"];
              } else if (
                (event.target.value as string[]).length === shops.length &&
                (event.target.value as string[]).indexOf("すべて") === -1
              ) {
                return [...shops.map((shop) => String(shop.shopId)), "すべて"];
              } else if (
                (event.target.value as string[]).length <= shops.length
              ) {
                return (event.target.value as string[]).filter(
                  (name) => name !== "すべて"
                );
              } else {
                return event.target.value as string[];
              }
            });
          }}
          input={<Input id="select-multiple-shop" />}
          style={{ width: "200px", marginRight: "10px" }}
          renderValue={(selected) => {
            if ((selected as string[]).includes("すべて")) {
              return "すべて";
            } else {
              return shops
                .filter((shop) =>
                  (selected as string[]).includes(String(shop.shopId))
                )
                .map((shop) => shop.name)
                .join(", ");
            }
          }}
        >
          <MenuItem key={"すべて"} value={"すべて"}>
            <Checkbox name="all" checked={selectShops.indexOf("すべて") > -1} />
            <ListItemText primary={"すべて"} />
          </MenuItem>
          {shops.map((shop) => (
            <MenuItem key={shop.shopId} value={String(shop.shopId)}>
              <Checkbox
                checked={selectShops.indexOf(String(shop.shopId)) !== -1}
              />
              <ListItemText primary={shop.name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <InputLabel id="select-multiple-class">クラス</InputLabel>
        <Select
          multiple
          value={selectNotelClasses}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSelectNotelClasses((prev: string[]) => {
              if (
                prev.includes("すべて") &&
                (event.target.value as string[]).indexOf("すべて") === -1
              ) {
                return [];
              } else if (
                !prev.includes("すべて") &&
                (event.target.value as string[]).indexOf("すべて") !== -1
              ) {
                return [
                  ...notelClasses.map((notelClass) =>
                    String(notelClass.notelClassId)
                  ),
                  "すべて",
                ];
              } else if (
                (event.target.value as string[]).length ===
                  notelClasses.length &&
                (event.target.value as string[]).indexOf("すべて") === -1
              ) {
                return [
                  ...notelClasses.map((notelClass) =>
                    String(notelClass.notelClassId)
                  ),
                  "すべて",
                ];
              } else if (
                (event.target.value as string[]).length <= notelClasses.length
              ) {
                return (event.target.value as string[]).filter(
                  (name) => name !== "すべて"
                );
              } else {
                return event.target.value as string[];
              }
            });
          }}
          input={<Input id="select-multiple-notelClass" />}
          style={{ width: "200px", marginRight: "10px" }}
          renderValue={(selected) => {
            if ((selected as string[]).includes("すべて")) {
              return "すべて";
            } else {
              return notelClasses
                .filter((notelClass) =>
                  (selected as string[]).includes(
                    String(notelClass.notelClassId)
                  )
                )
                .map((notelClass) => notelClass.name)
                .join(", ");
            }
          }}
        >
          <MenuItem key={"すべて"} value={"すべて"}>
            <Checkbox
              name="all"
              checked={selectNotelClasses.indexOf("すべて") > -1}
            />
            <ListItemText primary={"すべて"} />
          </MenuItem>
          {notelClasses.map((notelClass) => (
            <MenuItem
              key={notelClass.notelClassId}
              value={String(notelClass.notelClassId)}
            >
              <Checkbox
                checked={
                  selectNotelClasses.indexOf(
                    String(notelClass.notelClassId)
                  ) !== -1
                }
              />
              <ListItemText primary={notelClass.name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <InputLabel id="select-multiple-class">キャスト区分</InputLabel>
        <Select
          multiple
          value={selectCastCategories}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSelectCastCategories((prev: string[]) => {
              if (
                prev.includes("すべて") &&
                (event.target.value as string[]).indexOf("すべて") === -1
              ) {
                return [];
              } else if (
                !prev.includes("すべて") &&
                (event.target.value as string[]).indexOf("すべて") !== -1
              ) {
                return [
                  ...castCategories.map((castCategory) =>
                    String(castCategory.castCategoryId)
                  ),
                  "すべて",
                ];
              } else if (
                (event.target.value as string[]).length ===
                  castCategories.length &&
                (event.target.value as string[]).indexOf("すべて") === -1
              ) {
                return [
                  ...castCategories.map((castCategory) =>
                    String(castCategory.castCategoryId)
                  ),
                  "すべて",
                ];
              } else if (
                (event.target.value as string[]).length <= castCategories.length
              ) {
                return (event.target.value as string[]).filter(
                  (name) => name !== "すべて"
                );
              } else {
                return event.target.value as string[];
              }
            });
          }}
          input={<Input id="select-multiple-castCategory" />}
          style={{ width: "200px", marginRight: "10px" }}
          renderValue={(selected) => {
            if ((selected as string[]).includes("すべて")) {
              return "すべて";
            } else {
              return castCategories
                .filter((castCategory) =>
                  (selected as string[]).includes(
                    String(castCategory.castCategoryId)
                  )
                )
                .map((castCategory) => castCategory.name)
                .join(", ");
            }
          }}
        >
          <MenuItem key={"すべて"} value={"すべて"}>
            <Checkbox
              name="all"
              checked={selectCastCategories.indexOf("すべて") > -1}
            />
            <ListItemText primary={"すべて"} />
          </MenuItem>
          {castCategories.map((castCategory) => (
            <MenuItem
              key={castCategory.castCategoryId}
              value={String(castCategory.castCategoryId)}
            >
              <Checkbox
                checked={
                  selectCastCategories.indexOf(
                    String(castCategory.castCategoryId)
                  ) !== -1
                }
              />
              <ListItemText primary={castCategory.name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={workingResult}
                onChange={(event) => setWorkingResult(event.target.checked)}
              />
            }
            label={"出勤実績"}
          />
          <Tooltip
            title={
              "”はい”を選択した場合、指定期間内に１度でも出勤の実績があるキャストを対象にすることが出来ます。"
            }
            arrow
          >
            <Info />
          </Tooltip>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={enrolled}
              onChange={(event) => setEnrolled(event.target.checked)}
            />
          }
          label={"対象期間の在籍"}
        />
      </Box>
      <Button
        variant="contained"
        className={classes.button}
        color="primary"
        onClick={csvDownload}
        disabled={isDataLoading}
      >
        CSVダウンロード
      </Button>
    </Box>
  );
};

export default CastAnalyticsRanking;
