import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import CompanyApi from "api/CompanyApi";
import CompanyRes from "types/res/company/CompanyRes";
import UpdateCompanyReq from "types/req/company/UpdateCompanyReq";
import CreateCompanyReq from "types/req/company/CreateCompanyReq";
import DeleteCompanyReq from "types/req/company/DeleteCompanyReq";

const actionCreator = actionCreatorFactory();
const addCompanyAction = actionCreator.async<
  CreateCompanyReq,
  CompanyRes,
  { code: number; description: string }
>("ADD_COMPANY");
export const addCompany =
  (req: CreateCompanyReq) => async (dispatch: Dispatch<Action>) => {
    dispatch(addCompanyAction.started({ ...req }));

    try {
      const company = new CreateCompanyReq({ ...req });
      const result = await CompanyApi.create(company);
      dispatch(
        addCompanyAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addCompanyAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCompaniesAction = actionCreator.async<
  {},
  CompanyRes[],
  { code: number; description: string }
>("FETCH_COMPANY");

export const fetchCompanies = () => async (dispatch: Dispatch<Action>) => {
  dispatch(fetchCompaniesAction.started([]));

  try {
    const result = await CompanyApi.findAll();

    dispatch(
      fetchCompaniesAction.done({
        params: {},
        result,
      })
    );
    return result;
  } catch (error) {
    dispatch(
      fetchCompaniesAction.failed({
        params: {},
        // @ts-ignore
        error: { code: error.code, description: error.description },
      })
    );
    return error;
  }
};

const fetchCompaniesByCompanyGroupIdAction = actionCreator.async<
  { companyId: number; companyGroupId: number },
  CompanyRes[],
  { code: number; description: string }
>("FETCH_COMPANY_BY_COMPANY_GROUP_ID");

export const fetchCompaniesByCompanyGroupId =
  (companyId: number, companyGroupId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCompaniesAction.started([]));

    try {
      const result = await CompanyApi.findByCompanyGroupId(
        companyId,
        companyGroupId
      );

      dispatch(
        fetchCompaniesByCompanyGroupIdAction.done({
          params: { companyId, companyGroupId },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCompaniesByCompanyGroupIdAction.failed({
          params: { companyId, companyGroupId },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateCompanyAction = actionCreator.async<
  UpdateCompanyReq,
  CompanyRes,
  { code: number; description: string }
>("UPDATE_COMPANY");

export const updateCompany =
  (req: UpdateCompanyReq) => async (dispatch: Dispatch<Action>) => {
    dispatch(updateCompanyAction.started({ ...req }));

    try {
      const updateCompany = new UpdateCompanyReq({ ...req });
      const result = await CompanyApi.update(req.companyId, updateCompany);

      dispatch(
        updateCompanyAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateCompanyAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteCompanyAction = actionCreator.async<
  { companyId: number },
  {},
  { code: number; description: string }
>("DELETE_COMPANY");

export const deleteCompany =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(deleteCompanyAction.started({ companyId }));

    try {
      const company = new DeleteCompanyReq({ companyId });
      const result = await CompanyApi.delete(company);
      dispatch(
        deleteCompanyAction.done({
          params: { companyId },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteCompanyAction.failed({
          params: { companyId },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const companyActions = {
  fetchCompaniesAction,
  fetchCompaniesByCompanyGroupIdAction,
  addCompanyAction,
  updateCompanyAction,
  deleteCompanyAction,
};

export default companyActions;
