import RequestUtils from "utils/RequestUtils";
import AreaRes from "types/res/area/AreaRes";
import CreateAreaReq from "types/req/area/CreateAreaReq";
import UpdateAreaReq from "types/req/area/UpdateAreaReq";
import DeleteAreaReq from "types/req/area/DeleteAreaReq";

export default class AreaApi {
  private constructor() {}

  static async findAll(companyId: number, shopId?: number): Promise<AreaRes[]> {
    return await RequestUtils.getArray(
      AreaRes,
      `/area/findAll/${companyId}${shopId ? `?shopId=${shopId}` : ""}`
    );
  }

  static async create(companyId: number, data: CreateAreaReq) {
    return await RequestUtils.post(AreaRes, `/area/create/${companyId}`, data);
  }

  static async bulkInsert(companyId: number, data: CreateAreaReq[]) {
    return await RequestUtils.postArray(
      AreaRes,
      `/area/bulkInsert/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    areaId: number,
    data: UpdateAreaReq
  ): Promise<AreaRes> {
    return await RequestUtils.post(AreaRes, `/area/update/${companyId}`, data);
  }

  static async delete(companyId: number, data: DeleteAreaReq) {
    return await RequestUtils.postVoid(`/area/delete/${companyId}`, data);
  }
}
