import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchShops } from "redux/actions/shop";
import { fetchArea } from "redux/actions/area";
import { fetchNotelClass } from "redux/actions/notelClass";
import GuestAnalyticsCpmAnalyticsApi from "api/GuestAnalyticsCpmAnalyticsApi";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import GuestAnalyticsGroupType from "types/enum/GuestAnalyticsGroupType";
import GuestGenreType from "types/enum/GuestGenreType";
import Box from "@material-ui/core/Box";
import {
  Button,
  CircularProgress,
  createStyles,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import {
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MathUtils from "utils/MathUtils";
import { Star } from "@material-ui/icons";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/core/styles";
const GuestAnalyticsCpmAnalytics = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const shops = useSelector((state) => state.shop);
  const areas = useSelector((state) => state.area);
  const notelClasses = useSelector((state) => state.notelClass);
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectNotelClasses, setSelectNotelClasses] = useState<string[]>([]);
  const [selectArea, setSelectArea] = useState<string[]>([]);
  const [selectGenre, setSelectGenre] = useState<string[]>([
    ...Object.keys(GuestGenreType),
    "すべて",
  ]);
  const [selectGroup, setSelectGroup] = useState<string[]>([
    ...Object.keys(GuestAnalyticsGroupType),
    "すべて",
  ]);
  const [lastMonthData, setLastMonthData] = useState<any>();
  const [yearData, setYearData] = useState<any>();
  const [graphDataLoading, setGraphDataLoading] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchArea(companyId));
    dispatch(fetchNotelClass(companyId));
  }, []);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    setSelectArea([...areas.map((area) => String(area.areaId)), "すべて"]);
  }, [areas]);

  useEffect(() => {
    setSelectNotelClasses([
      ...notelClasses.map((notelClass) => String(notelClass.notelClassId)),
      "すべて",
    ]);
  }, [notelClasses]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  const onClickSearch = async () => {
    setYearData(null);
    setLastMonthData(null);
    setTableDataLoading(true);
    setGraphDataLoading(true);
    let month: { [month: string]: any } = {};
    const monthObjectList = [...Array(12)].map((_, i) =>
      DateTime.fromJSDate(endDate)
        .minus({ month: 11 })
        .plus({ month: i })
        .endOf("month")
    );
    for (const monthObject of monthObjectList) {
      const monthString = monthObject.toFormat(FORMAT_TYPE.YEAR_MONTH);
      month = {
        ...month,
        [monthString]: {
          royalUser: {
            active: "-",
            breakaway: "-",
            netIncrease: {
              active: "-",
              breakaway: "-",
            },
          },
          trendyUser: {
            active: "-",
            breakaway: "-",
            netIncrease: {
              active: "-",
              breakaway: "-",
            },
          },
          kotsukotsu: {
            active: "-",
            breakaway: "-",
            netIncrease: {
              active: "-",
              breakaway: "-",
            },
          },
          yochiyochi: {
            active: "-",
            breakaway: "-",
            netIncrease: {
              active: "-",
              breakaway: "-",
            },
          },
          firstTimeUser: {
            active: "-",
            breakaway: "-",
            netIncrease: {
              active: "-",
              returnActive: "-",
              breakaway: "-",
            },
          },
        },
      };
    }
    setYearData(month);
    const result = await GuestAnalyticsCpmAnalyticsApi.findDataLastMonth(
      companyId,
      {
        endDate: DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        shopIds: selectShops.filter((shopId) => shopId !== "すべて"),
        areaIds: selectArea.filter((areaId) => areaId !== "すべて"),
        notelClassIds: selectNotelClasses.filter(
          (notelClassId) => notelClassId !== "すべて"
        ),
        guestAnalyticsType: selectGroup.filter((group) => group !== "すべて"),
        guestGenreType: selectGenre.filter((genre) => genre !== "すべて"),
      }
    );
    setLastMonthData(result);
    setGraphDataLoading(false);
    const monthList = [
      DateTime.fromJSDate(endDate)
        .minus({ month: 6 })
        .toFormat(FORMAT_TYPE.YEAR_DAY),
      DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
    ];
    for (const date of monthList) {
      const result2 = await GuestAnalyticsCpmAnalyticsApi.findData(companyId, {
        endDate: date,
        shopIds: selectShops.filter((shopId) => shopId !== "すべて"),
        areaIds: selectArea.filter((areaId) => areaId !== "すべて"),
        notelClassIds: selectNotelClasses.filter(
          (notelClassId) => notelClassId !== "すべて"
        ),
        guestAnalyticsType: selectGroup.filter((group) => group !== "すべて"),
        guestGenreType: selectGenre.filter((genre) => genre !== "すべて"),
      });
      setYearData((prev: any) => ({ ...prev, ...result2 }));
      setTableDataLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box margin={2} display="flex" alignItems="center">
        <TextField
          type="date"
          label="終了日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) =>
            setEndDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            )
          }
        />
        <Box>
          <InputLabel id="select-multiple-shop">店舗</InputLabel>
          <Select
            multiple
            value={selectShops}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectShops((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length === shops.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= shops.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-shop" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return shops
                  .filter((shop) =>
                    (selected as string[]).includes(String(shop.shopId))
                  )
                  .map((shop) => shop.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectShops.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {shops.map((shop) => (
              <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                <Checkbox
                  checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                />
                <ListItemText primary={shop.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-notel-class">クラス</InputLabel>
          <Select
            multiple
            value={selectNotelClasses}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectNotelClasses((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...notelClasses.map((notelClass) =>
                      String(notelClass.notelClassId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length ===
                    notelClasses.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...notelClasses.map((notelClass) =>
                      String(notelClass.notelClassId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= notelClasses.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-notelClass" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return notelClasses
                  .filter((notelClass) =>
                    (selected as string[]).includes(
                      String(notelClass.notelClassId)
                    )
                  )
                  .map((notelClass) => notelClass.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectNotelClasses.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {notelClasses.map((notelClass) => (
              <MenuItem
                key={notelClass.notelClassId}
                value={String(notelClass.notelClassId)}
              >
                <Checkbox
                  checked={
                    selectNotelClasses.indexOf(
                      String(notelClass.notelClassId)
                    ) !== -1
                  }
                />
                <ListItemText primary={notelClass.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-guest-genre">現役・離脱</InputLabel>
          <Select
            multiple
            value={selectGenre}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGenre((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [...Object.keys(GuestGenreType), "すべて"];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(GuestGenreType).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [...Object.keys(GuestGenreType), "すべて"];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(GuestGenreType).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-genre" />}
            style={{ margin: "10px", width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.keys(GuestGenreType)
                  .filter((name) => (selected as string[]).includes(name))
                  .map((key) => (GuestGenreType as any)[key])
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGenre.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(GuestGenreType).map(([key, name]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox checked={selectGenre.indexOf(String(key)) !== -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-guest-genre">顧客グループ</InputLabel>
          <Select
            multiple
            value={selectGroup}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGroup((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [...Object.keys(GuestAnalyticsGroupType), "すべて"];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(GuestAnalyticsGroupType).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [...Object.keys(GuestAnalyticsGroupType), "すべて"];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(GuestAnalyticsGroupType).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-genre" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.keys(GuestAnalyticsGroupType)
                  .filter((name) => (selected as string[]).includes(name))
                  .map((key) => (GuestAnalyticsGroupType as any)[key])
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGroup.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(GuestAnalyticsGroupType).map(([key, name]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox checked={selectGroup.indexOf(String(key)) !== -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-area">エリア</InputLabel>
          <Select
            multiple
            value={selectArea}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectArea((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...areas.map((area) => String(area.areaId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length === areas.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...areas.map((area) => String(area.areaId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= areas.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-area" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return areas
                  .filter((area) =>
                    (selected as string[]).includes(String(area.areaId))
                  )
                  .map((area) => area.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectArea.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {areas.map((area) => (
              <MenuItem key={area.areaId} value={String(area.areaId)}>
                <Checkbox
                  checked={selectArea.indexOf(String(area.areaId)) !== -1}
                />
                <ListItemText primary={area.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={onClickSearch}
        >
          検索
        </Button>
      </Box>
      {graphDataLoading ? (
        <Box display={"flex"} justifyContent={"center"} margin={2}>
          <CircularProgress />
        </Box>
      ) : (
        lastMonthData && <GuestCountAnalytics graphData={lastMonthData} />
      )}
      {tableDataLoading ? (
        <Box display={"flex"} justifyContent={"center"} margin={2}>
          <CircularProgress />
        </Box>
      ) : (
        yearData && (
          <GuestCountByGuestAnalyticsTable data={yearData} endDate={endDate} />
        )
      )}
    </Box>
  );
};

type GuestCountAnalyticsProps = {
  graphData: {
    royalUser: {
      active: {
        guestCount: number;
        orderCount: number;
        totalPrice: number;
        orderPeriod: number;
      };
      breakaway: {
        guestCount: number;
        orderCount: number;
        totalPrice: number;
        orderPeriod: number;
      };
    };
    trendyUser: {
      active: {
        guestCount: number;
        orderCount: number;
        totalPrice: number;
        orderPeriod: number;
      };
      breakaway: {
        guestCount: number;
        orderCount: number;
        totalPrice: number;
        orderPeriod: number;
      };
    };
    kotsukotsu: {
      active: {
        guestCount: number;
        orderCount: number;
        totalPrice: number;
        orderPeriod: number;
      };
      breakaway: {
        guestCount: number;
        orderCount: number;
        totalPrice: number;
        orderPeriod: number;
      };
    };
    yochiyochi: {
      active: {
        guestCount: number;
        orderCount: number;
        totalPrice: number;
        orderPeriod: number;
      };
      breakaway: {
        guestCount: number;
        orderCount: number;
        totalPrice: number;
        orderPeriod: number;
      };
    };
    firstTimeUser: {
      active: {
        guestCount: number;
        orderCount: number;
        totalPrice: number;
        orderPeriod: number;
      };
      breakaway: {
        guestCount: number;
        orderCount: number;
        totalPrice: number;
        orderPeriod: number;
      };
    };
  };
};
const GuestCountAnalytics = ({ graphData }: GuestCountAnalyticsProps) => {
  const activeUserData = [
    { name: "初回客", value: graphData.firstTimeUser.active.guestCount },
    { name: "よちよち客", value: graphData.yochiyochi.active.guestCount },
    { name: "こつこつ客", value: graphData.kotsukotsu.active.guestCount },
    { name: "流行客", value: graphData.trendyUser.active.guestCount },
    { name: "優良客", value: graphData.royalUser.active.guestCount },
  ];
  const breakawayUserData = [
    { name: "初回客", value: graphData.firstTimeUser.breakaway.guestCount },
    { name: "よちよち客", value: graphData.yochiyochi.breakaway.guestCount },
    { name: "こつこつ客", value: graphData.kotsukotsu.breakaway.guestCount },
    { name: "流行客", value: graphData.trendyUser.breakaway.guestCount },
    { name: "優良客", value: graphData.royalUser.breakaway.guestCount },
  ];

  const scatterData = [
    {
      genre: "現役",
      group: "初回客",
      x:
        graphData.firstTimeUser.active.orderPeriod /
        graphData.firstTimeUser.active.orderCount,
      y: graphData.firstTimeUser.active.totalPrice / 1000000,
      z: graphData.firstTimeUser.active.guestCount,
    },
    {
      genre: "現役",
      group: "よちよち客",
      x:
        graphData.yochiyochi.active.orderPeriod /
        graphData.yochiyochi.active.orderCount,
      y: graphData.yochiyochi.active.totalPrice / 1000000,
      z: graphData.yochiyochi.active.guestCount,
    },
    {
      genre: "現役",
      group: "こつこつ客",
      x:
        graphData.kotsukotsu.active.orderPeriod /
        graphData.kotsukotsu.active.orderCount,
      y: graphData.kotsukotsu.active.totalPrice / 1000000,
      z: graphData.kotsukotsu.active.guestCount,
    },
    {
      genre: "現役",
      group: "流行客",
      x:
        graphData.trendyUser.active.orderPeriod /
        graphData.trendyUser.active.orderCount,

      y: graphData.trendyUser.active.totalPrice / 1000000,
      z: graphData.trendyUser.active.guestCount,
    },
    {
      genre: "現役",
      group: "優良客",
      x:
        graphData.royalUser.active.orderPeriod /
        graphData.royalUser.active.orderCount,

      y: graphData.royalUser.active.totalPrice / 1000000,
      z: graphData.royalUser.active.guestCount,
    },
    {
      genre: "離脱",
      group: "初回客",
      x:
        graphData.firstTimeUser.breakaway.orderPeriod /
        graphData.firstTimeUser.breakaway.orderCount,

      y: graphData.firstTimeUser.breakaway.totalPrice / 1000000,
      z: graphData.firstTimeUser.breakaway.guestCount,
    },
    {
      genre: "離脱",
      group: "よちよち客",
      x:
        graphData.yochiyochi.breakaway.orderPeriod /
        graphData.yochiyochi.breakaway.orderCount,
      y: graphData.yochiyochi.breakaway.totalPrice / 1000000,
      z: graphData.yochiyochi.breakaway.guestCount,
    },
    {
      genre: "離脱",
      group: "こつこつ客",
      x:
        graphData.kotsukotsu.breakaway.orderPeriod /
        graphData.kotsukotsu.breakaway.orderCount,
      y: graphData.kotsukotsu.breakaway.totalPrice / 1000000,
      z: graphData.kotsukotsu.breakaway.guestCount,
    },
    {
      genre: "離脱",
      group: "流行客",
      x:
        graphData.trendyUser.breakaway.orderPeriod /
        graphData.trendyUser.breakaway.orderCount,
      y: graphData.trendyUser.breakaway.totalPrice / 1000000,
      z: graphData.trendyUser.breakaway.guestCount,
    },
    {
      genre: "離脱",
      group: "優良客",
      x:
        graphData.royalUser.breakaway.orderPeriod /
        graphData.royalUser.breakaway.orderCount,
      y: graphData.royalUser.breakaway.totalPrice / 1000000,
      z: graphData.royalUser.breakaway.guestCount,
    },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#4B19D2"];
  const totalGuestCount =
    graphData.firstTimeUser.active.guestCount +
    graphData.yochiyochi.active.guestCount +
    graphData.kotsukotsu.active.guestCount +
    graphData.trendyUser.active.guestCount +
    graphData.royalUser.active.guestCount +
    graphData.firstTimeUser.breakaway.guestCount +
    graphData.yochiyochi.breakaway.guestCount +
    graphData.kotsukotsu.breakaway.guestCount +
    graphData.trendyUser.breakaway.guestCount +
    graphData.royalUser.breakaway.guestCount;

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    name,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize="12"
      >
        {name} {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            border: "1px solid gray",
            borderRadius: "5px",
            backgroundColor: "white",
            padding: "10px",
          }}
        >
          <p>{`${payload[0].payload.genre}  ${payload[0].payload.group}`}</p>
          <p>{`平均在籍期間 : ${MathUtils.roundToTwo(
            payload[0].payload.x
          )}日`}</p>
          <p>{`合計売上: ${MathUtils.roundToTwo(payload[0].payload.y)}M`}</p>
          <p>{`顧客人数: ${payload[0].payload.z}人`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Box display={"flex"}>
      <Box display={"flex"} flexDirection={"column"} width={"50%"}>
        <Typography style={{ fontWeight: "bold", margin: "5px" }}>
          現役・離脱/顧客グループ別 顧客数集計
        </Typography>
        <Box display={"flex"}>
          <Box display="flex" flexDirection="column" alignItems={"center"}>
            <PieChart width={300} height={300}>
              <Pie
                data={activeUserData}
                cx="50%"
                cy="50%"
                label={renderCustomizedLabel}
                labelLine={false}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {activeUserData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <Typography>現役</Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems={"center"}>
            <PieChart width={300} height={300}>
              <Pie
                data={breakawayUserData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {breakawayUserData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <Typography>離脱</Typography>
          </Box>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>現役・離脱</TableCell>
              <TableCell>初回客</TableCell>
              <TableCell>よちよち客</TableCell>
              <TableCell>こつこつ客</TableCell>
              <TableCell>流行客</TableCell>
              <TableCell>優良客</TableCell>
              <TableCell>総計</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>現役</TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.firstTimeUser.active.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      (graphData.firstTimeUser.active.guestCount /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.yochiyochi.active.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      (graphData.yochiyochi.active.guestCount /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.kotsukotsu.active.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      (graphData.kotsukotsu.active.guestCount /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.trendyUser.active.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      (graphData.trendyUser.active.guestCount /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.royalUser.active.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      (graphData.royalUser.active.guestCount /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.firstTimeUser.active.guestCount +
                      graphData.yochiyochi.active.guestCount +
                      graphData.kotsukotsu.active.guestCount +
                      graphData.trendyUser.active.guestCount +
                      graphData.royalUser.active.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      ((graphData.firstTimeUser.active.guestCount +
                        graphData.yochiyochi.active.guestCount +
                        graphData.kotsukotsu.active.guestCount +
                        graphData.trendyUser.active.guestCount +
                        graphData.royalUser.active.guestCount) /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>離脱</TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.firstTimeUser.breakaway.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      (graphData.firstTimeUser.breakaway.guestCount /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.yochiyochi.breakaway.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      (graphData.yochiyochi.breakaway.guestCount /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.kotsukotsu.breakaway.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      (graphData.kotsukotsu.breakaway.guestCount /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.trendyUser.breakaway.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      (graphData.trendyUser.breakaway.guestCount /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.royalUser.breakaway.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      (graphData.royalUser.breakaway.guestCount /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.firstTimeUser.breakaway.guestCount +
                      graphData.yochiyochi.breakaway.guestCount +
                      graphData.kotsukotsu.breakaway.guestCount +
                      graphData.trendyUser.breakaway.guestCount +
                      graphData.royalUser.breakaway.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      ((graphData.firstTimeUser.breakaway.guestCount +
                        graphData.yochiyochi.breakaway.guestCount +
                        graphData.kotsukotsu.breakaway.guestCount +
                        graphData.trendyUser.breakaway.guestCount +
                        graphData.royalUser.breakaway.guestCount) /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>総計</TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.firstTimeUser.active.guestCount +
                      graphData.firstTimeUser.breakaway.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      ((graphData.firstTimeUser.active.guestCount +
                        graphData.firstTimeUser.breakaway.guestCount) /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.yochiyochi.active.guestCount +
                      graphData.yochiyochi.breakaway.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      ((graphData.yochiyochi.active.guestCount +
                        graphData.yochiyochi.breakaway.guestCount) /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.kotsukotsu.active.guestCount +
                      graphData.kotsukotsu.breakaway.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      ((graphData.kotsukotsu.active.guestCount +
                        graphData.kotsukotsu.breakaway.guestCount) /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.trendyUser.active.guestCount +
                      graphData.trendyUser.breakaway.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      ((graphData.trendyUser.active.guestCount +
                        graphData.trendyUser.breakaway.guestCount) /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>
                    {graphData.royalUser.active.guestCount +
                      graphData.royalUser.breakaway.guestCount}
                  </Typography>
                  <Typography>
                    {MathUtils.roundToTwo(
                      ((graphData.royalUser.active.guestCount +
                        graphData.royalUser.breakaway.guestCount) /
                        totalGuestCount) *
                        100
                    )}
                    %
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection={"column"}>
                  <Typography>{totalGuestCount}</Typography>
                  <Typography>
                    {(totalGuestCount / totalGuestCount) * 100}%
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"50%"}
        height={"500px"}
        paddingX={2}
      >
        <Typography style={{ fontWeight: "bold", margin: "5px" }}>
          現役・離脱/顧客グループポジション
        </Typography>
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart
            width={800}
            height={400}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis
              type="number"
              dataKey="x"
              name="平均在籍期間"
              unit=""
              domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.2)]}
            />
            <YAxis type="number" dataKey="y" name="合計売上" unit="M" />
            <ZAxis
              type="number"
              dataKey="z"
              range={[0, 10000]}
              name="顧客数"
              unit=""
            />
            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              content={CustomTooltip}
            />
            <Scatter
              name="scatter"
              data={scatterData}
              shape={(e) => {
                return e.genre === "離脱" ? (
                  <Star
                    x={e.x || 0}
                    y={e.y || 0}
                    style={{ fill: e.fill }}
                    width={Number(e.width || 0)}
                    height={Number(e.height || 0)}
                  />
                ) : (
                  <FiberManualRecordIcon
                    x={e.x || 0}
                    y={e.y || 0}
                    style={{ fill: e.fill }}
                    width={Number(e.width || 0)}
                    height={Number(e.height || 0)}
                  />
                );
              }}
            >
              {scatterData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Scatter>
          </ScatterChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

type GuestCountByGuestAnalyticsTableProps = {
  data: {
    [key: string]: {
      royalUser: {
        active: number;
        breakaway: number;
        netIncrease: {
          active: number;
          breakaway: number;
        };
      };
      trendyUser: {
        active: number;
        breakaway: number;
        netIncrease: {
          active: number;
          breakaway: number;
        };
      };
      kotsukotsu: {
        active: number;
        breakaway: number;
        netIncrease: {
          active: number;
          breakaway: number;
        };
      };
      yochiyochi: {
        active: number;
        breakaway: number;
        netIncrease: {
          active: number;
          breakaway: number;
        };
      };
      firstTimeUser: {
        active: number;
        breakaway: number;
        netIncrease: {
          active: number;
          returnActive: number;
          breakaway: number;
        };
      };
    };
  };
  endDate: Date;
};
const GuestCountByGuestAnalyticsTable = ({
  data,
  endDate,
}: GuestCountByGuestAnalyticsTableProps) => {
  const monthList = [...Array(12)].map((_, i) =>
    DateTime.fromJSDate(endDate)
      .minus({ month: 11 })
      .plus({ month: i })
      .endOf("month")
      .toFormat(FORMAT_TYPE.YEAR_MONTH)
  );
  return (
    <Box display="flex" flexDirection="column">
      <Typography style={{ fontWeight: "bold", margin: "5px" }}>
        グループ別顧客数推移
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>現役・離脱</TableCell>
            <TableCell>顧客グループ</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>{month}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={8}>現役</TableCell>
            <TableCell>初回客</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.firstTimeUser.active}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>よちよち客</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.yochiyochi.active}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>こつこつ客</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.kotsukotsu.active}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>流行客</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.trendyUser.active}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>優良客</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>{data[month]?.royalUser.active}</TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>割合</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {MathUtils.roundToTwo(
                  ((data[month]?.firstTimeUser.active +
                    data[month]?.yochiyochi.active +
                    data[month]?.kotsukotsu.active +
                    data[month]?.trendyUser.active +
                    data[month]?.royalUser.active) /
                    (data[month]?.firstTimeUser.active +
                      data[month]?.firstTimeUser.breakaway +
                      data[month]?.yochiyochi.active +
                      data[month]?.yochiyochi.breakaway +
                      data[month]?.kotsukotsu.active +
                      data[month]?.kotsukotsu.breakaway +
                      data[month]?.trendyUser.active +
                      data[month]?.trendyUser.breakaway +
                      data[month]?.royalUser.active +
                      data[month]?.royalUser.breakaway)) *
                    100
                )}
                %
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>小計</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.firstTimeUser.active +
                  data[month]?.yochiyochi.active +
                  data[month]?.kotsukotsu.active +
                  data[month]?.trendyUser.active +
                  data[month]?.royalUser.active}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>前月差分</TableCell>
            {monthList.map((month, index) => {
              const prevMonth = DateTime.fromFormat(
                month,
                FORMAT_TYPE.YEAR_MONTH
              )
                .minus({ month: 1 })
                .toFormat(FORMAT_TYPE.YEAR_MONTH);
              return (
                <TableCell key={month}>
                  {index === 0
                    ? "-"
                    : data[month]?.firstTimeUser.active +
                      data[month]?.yochiyochi.active +
                      data[month]?.kotsukotsu.active +
                      data[month]?.trendyUser.active +
                      data[month]?.royalUser.active -
                      (data[prevMonth]?.firstTimeUser.active +
                        data[prevMonth]?.yochiyochi.active +
                        data[prevMonth]?.kotsukotsu.active +
                        data[prevMonth]?.trendyUser.active +
                        data[prevMonth]?.royalUser.active)}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell rowSpan={6}>離脱</TableCell>
            <TableCell>初回客</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.firstTimeUser.breakaway}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>よちよち客</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.yochiyochi.breakaway}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>こつこつ客</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.kotsukotsu.breakaway}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>流行客</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.trendyUser.breakaway}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>優良客</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.royalUser.breakaway}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>割合</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {MathUtils.roundToTwo(
                  ((data[month]?.firstTimeUser.breakaway +
                    data[month]?.yochiyochi.breakaway +
                    data[month]?.kotsukotsu.breakaway +
                    data[month]?.trendyUser.breakaway +
                    data[month]?.royalUser.breakaway) /
                    (data[month]?.firstTimeUser.active +
                      data[month]?.firstTimeUser.breakaway +
                      data[month]?.yochiyochi.active +
                      data[month]?.yochiyochi.breakaway +
                      data[month]?.kotsukotsu.active +
                      data[month]?.kotsukotsu.breakaway +
                      data[month]?.trendyUser.active +
                      data[month]?.trendyUser.breakaway +
                      data[month]?.royalUser.active +
                      data[month]?.royalUser.breakaway)) *
                    100
                )}
                %
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>総計</TableCell>
            <TableCell></TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.firstTimeUser.active +
                  data[month]?.firstTimeUser.breakaway +
                  data[month]?.yochiyochi.active +
                  data[month]?.yochiyochi.breakaway +
                  data[month]?.kotsukotsu.active +
                  data[month]?.kotsukotsu.breakaway +
                  data[month]?.trendyUser.active +
                  data[month]?.trendyUser.breakaway +
                  data[month]?.royalUser.active +
                  data[month]?.royalUser.breakaway}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      <Typography style={{ fontWeight: "bold", margin: "5px" }}>
        各月における純増加人数
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>現役・離脱</TableCell>
            <TableCell>顧客グループ</TableCell>
            <TableCell>復活</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>{month}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={7}>現役</TableCell>
            <TableCell rowSpan={2}>初回客</TableCell>
            <TableCell>新規</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.firstTimeUser.netIncrease.active}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>復活</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.firstTimeUser.netIncrease.returnActive}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>よちよち客</TableCell>
            <TableCell></TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.yochiyochi.netIncrease.active}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>こつこつ客</TableCell>
            <TableCell></TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.kotsukotsu.netIncrease.active}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>流行客</TableCell>
            <TableCell></TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.trendyUser.netIncrease.active}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>優良客</TableCell>
            <TableCell></TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.royalUser.netIncrease.active}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>小計</TableCell>
            <TableCell></TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.firstTimeUser.netIncrease.active +
                  data[month]?.yochiyochi.netIncrease.active +
                  data[month]?.kotsukotsu.netIncrease.active +
                  data[month]?.trendyUser.netIncrease.active +
                  data[month]?.royalUser.netIncrease.active}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell rowSpan={6}>離脱</TableCell>
            <TableCell>初回客</TableCell>
            <TableCell>新規</TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.firstTimeUser.netIncrease.breakaway}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>よちよち客</TableCell>
            <TableCell></TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.yochiyochi.netIncrease.breakaway}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>こつこつ客</TableCell>
            <TableCell></TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.kotsukotsu.netIncrease.breakaway}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>流行客</TableCell>
            <TableCell></TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.trendyUser.netIncrease.breakaway}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>優良客</TableCell>
            <TableCell></TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.royalUser.netIncrease.breakaway}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>小計</TableCell>
            <TableCell></TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.firstTimeUser.netIncrease.breakaway +
                  data[month]?.yochiyochi.netIncrease.breakaway +
                  data[month]?.kotsukotsu.netIncrease.breakaway +
                  data[month]?.trendyUser.netIncrease.breakaway +
                  data[month]?.royalUser.netIncrease.breakaway}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>総計</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            {monthList.map((month) => (
              <TableCell key={month}>
                {data[month]?.firstTimeUser.netIncrease.active +
                  data[month]?.yochiyochi.netIncrease.active +
                  data[month]?.kotsukotsu.netIncrease.active +
                  data[month]?.trendyUser.netIncrease.active +
                  data[month]?.royalUser.netIncrease.active -
                  data[month]?.firstTimeUser.netIncrease.breakaway -
                  data[month]?.yochiyochi.netIncrease.breakaway -
                  data[month]?.kotsukotsu.netIncrease.breakaway -
                  data[month]?.trendyUser.netIncrease.breakaway -
                  data[month]?.royalUser.netIncrease.breakaway}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
export default GuestAnalyticsCpmAnalytics;
