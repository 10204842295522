import RequestUtils from "utils/RequestUtils";
import DriverShiftRes from "types/res/driverShift/DriverShiftRes";
import CreateDriverShiftReq from "types/req/driverShift/CreateDriverShiftReq";
import UpdateDriverShiftReq from "types/req/driverShift/UpdateDriverShiftReq";
import DeleteDriverShiftReq from "types/req/driverShift/DeleteDriverShiftReq";
import querystring from "querystring";
import BulkInsertDriverShiftReq from "../types/req/driverShift/BulkInsertDriverShiftReq";
export default class DriverShiftApi {
  private constructor() {}

  static async findAll(
    companyId: number,
    startDate: string,
    endDate: string,
    shopId?: number,
    driverId?: number
  ): Promise<DriverShiftRes[]> {
    const query = { shopId, driverId };
    return await RequestUtils.getArray(
      DriverShiftRes,
      `/driverShift/findAll/${companyId}/${startDate}/${endDate}?${querystring.stringify(
        query
      )}`
    );
  }

  static async create(companyId: number, data: CreateDriverShiftReq) {
    return await RequestUtils.post(
      DriverShiftRes,
      `/driverShift/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    driverShiftId: number,
    data: UpdateDriverShiftReq
  ): Promise<DriverShiftRes> {
    return await RequestUtils.post(
      DriverShiftRes,
      `/driverShift/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteDriverShiftReq) {
    return await RequestUtils.postVoid(
      `/driverShift/delete/${companyId}`,
      data
    );
  }

  static async bulkInsert(companyId: number, data: BulkInsertDriverShiftReq[]) {
    return await RequestUtils.postArray(
      DriverShiftRes,
      `/driverShift/bulkInsert/${companyId}`,
      data
    );
  }
}
