import RequestUtils from "utils/RequestUtils";
import CallHistoryRes from "types/res/callHistory/CallHistoryRes";
import CreateCallHistoryReq from "types/req/callHistory/CreateCallHistoryReq";
import UpdateCallHistoryReq from "types/req/callHistory/UpdateCallHistoryReq";
import querystring from "querystring";

export default class CallHistoryApi {
  private constructor() {
    // not supported
  }

  static async findAll(
    companyId: number,
    startDate?: string,
    endDate?: string
  ): Promise<CallHistoryRes[]> {
    const query = querystring.stringify({ startDate, endDate });
    return await RequestUtils.getArray(
      CallHistoryRes,
      `/callHistory/findAll/${companyId}?${query}`
    );
  }

  static async graphData(
    companyId: number,
    startDate: string,
    endDate: string,
    shopIds: string,
    categories: string,
    period: "day" | "month"
  ) {
    const query = querystring.stringify({
      startDate,
      endDate,
      shopIds,
      categories,
      period,
    });
    return await RequestUtils.getPlain(
      `/callHistory/graphData/${companyId}?${query}`
    );
  }

  static async create(companyId: number, data: CreateCallHistoryReq) {
    return await RequestUtils.post(
      CallHistoryRes,
      `/callHistory/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    data: UpdateCallHistoryReq
  ): Promise<CallHistoryRes> {
    return await RequestUtils.post(
      CallHistoryRes,
      `/callHistory/update/${companyId}`,
      data
    );
  }
}
