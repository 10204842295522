import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import StaffTaskApi from "api/StaffTaskApi";
import StaffTaskRes from "types/res/staffTask/StaffTaskRes";
import UpdateStaffTaskReq from "types/req/staffTask/UpdateStaffTaskReq";
import CreateStaffTaskReq from "types/req/staffTask/CreateStaffTaskReq";
import DeleteStaffTaskReq from "types/req/staffTask/DeleteStaffTaskReq";

const actionCreator = actionCreatorFactory();
const addStaffTaskAction = actionCreator.async<
  CreateStaffTaskReq,
  StaffTaskRes,
  { code: number; description: string }
>("ADD_STAFF_TASK");

export const addStaffTask =
  (companyId: number, req: CreateStaffTaskReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addStaffTaskAction.started({ ...req }));

    try {
      const staffTask = new CreateStaffTaskReq({ ...req });
      const result = await StaffTaskApi.create(companyId, staffTask);
      dispatch(
        addStaffTaskAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addStaffTaskAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchStaffTaskAction = actionCreator.async<
  {},
  StaffTaskRes[],
  { code: number; description: string }
>("FETCH_STAFF_TASK");

export const fetchStaffTask =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchStaffTaskAction.started([]));

    try {
      const result = await StaffTaskApi.findAll(companyId);

      dispatch(
        fetchStaffTaskAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchStaffTaskAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateStaffTaskAction = actionCreator.async<
  UpdateStaffTaskReq,
  StaffTaskRes,
  { code: number; description: string }
>("UPDATE_STAFF_TASK");

export const updateStaffTask =
  (companyId: number, req: UpdateStaffTaskReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateStaffTaskAction.started({ ...req }));

    try {
      const updateStaffTask = new UpdateStaffTaskReq({ ...req });
      const result = await StaffTaskApi.update(companyId, updateStaffTask);
      dispatch(
        updateStaffTaskAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateStaffTaskAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteStaffTaskAction = actionCreator.async<
  DeleteStaffTaskReq,
  {},
  { code: number; description: string }
>("DELETE_STAFF_TASK");

export const deleteStaffTask =
  (companyId: number, req: DeleteStaffTaskReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteStaffTaskAction.started({ ...req }));

    try {
      const staffTask = new DeleteStaffTaskReq({ ...req });
      const result = await StaffTaskApi.delete(companyId, staffTask);
      dispatch(
        deleteStaffTaskAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteStaffTaskAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const staffTaskActions = {
  fetchStaffTaskAction,
  addStaffTaskAction,
  updateStaffTaskAction,
  deleteStaffTaskAction,
};

export default staffTaskActions;
