import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  Icon,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { useDispatch, useSelector } from "react-redux";
import CastNameRes from "types/res/castName/CastNameRes";
import NotelClassRes from "types/res/notelClass/NotelClassRes";
import CourseRes from "types/res/course/CourseRes";
import AdditionalTimeRes from "types/res/additionalTime/AdditionalTimeRes";
import DiscountRes from "types/res/discount/DiscountRes";
import OptionRes from "types/res/option/OptionRes";
import CosplayRes from "types/res/cosplay/CosplayRes";
import HotelRes from "types/res/hotel/HotelRes";
import AreaRes from "types/res/area/AreaRes";
import MediumRes from "types/res/medium/MediumRes";
import OrderRes from "types/res/order/OrderRes";
import { makeStyles } from "@material-ui/core/styles";
import TimeUtils from "utils/TimeUtils";
import PaymentType from "types/enum/PaymentType";
import EnumUtils from "utils/EnumUtils";
import OrderApi from "api/OrderApi";
import OrderStatus from "types/enum/OrderStatus";
import { Autocomplete } from "@material-ui/lab";
import { Info } from "@material-ui/icons";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import OrderResponseStatus from "types/enum/OrderResponseStatus";
import FormControlLabel from "@material-ui/core/FormControlLabel";

type Props = {
  formData: any;
  oldOrder: any;
  onChange: (key: string, value: any) => void;
  onChangeAdditionalTimeOrder(index: number, key: string, value: any): any;
  addAdditionalTimeOrder(): any;
  deleteAdditionalTimeOrder(index: number): any;
};

const SpOrderInfo = ({
  formData,
  onChange,
  oldOrder,
  onChangeAdditionalTimeOrder,
  addAdditionalTimeOrder,
  deleteAdditionalTimeOrder,
}: Props) => {
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const castNames = useSelector((state) => state.castName);
  const castShifts = useSelector((state) => state.castShift);
  const courses = useSelector((state) => state.course);
  const additionalTimes = useSelector((state) => state.additionalTime);
  const discounts = useSelector((state) => state.discount);
  const options = useSelector((state) => state.option);
  const cosplays = useSelector((state) => state.cosplay);
  const areas = useSelector((state) => state.area);
  const hotels = useSelector((state) => state.hotel);
  const nominations = useSelector((state) => state.nomination);
  const notelClasses = useSelector((state) => state.notelClass);
  const media = useSelector((state) => state.medium);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [filterCastNames, setFilterCastNames] = useState<CastNameRes[]>([]);
  const [filterNotelClasses, setFilterNotelClasses] = useState<NotelClassRes[]>(
    []
  );
  const [filterCourses, setFilterCourses] = useState<CourseRes[]>([]);
  const [filterAdditionalTimes, setFilterAdditionalTimes] = useState<
    AdditionalTimeRes[]
  >([]);
  const [filterDiscounts, setFilterDiscounts] = useState<DiscountRes[]>([]);
  const [filterOptions, setFilterOptions] = useState<OptionRes[]>([]);
  const [filterCosplays, setFilterCosplays] = useState<CosplayRes[]>([]);
  const [filterHotels, setFilterHotels] = useState<HotelRes[]>([]);
  const [filterAreas, setFilterAreas] = useState<AreaRes[]>([]);
  const [filterMedia, setFilterMedia] = useState<MediumRes[]>([]);
  const [hours, setHours] = useState<string[]>([]);
  const [minOpeningTime, setMinOpeningTime] = useState(0);
  const [maxClosingTime, setMaxClosingTime] = useState(0);
  const [selectArea, setSelectArea] = useState<AreaRes>();
  const [selectHotel, setSelectHotel] = useState<HotelRes>();
  const [selectCastName, setSelectCastName] = useState<CastNameRes>();
  const [reOrder, setReOrder] = useState<OrderRes>();
  const [isLoading, setIsLoading] = useState(false);
  const minutes = [
    "00",
    "05",
    "10",
    "15",
    "20",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "55",
  ];
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  const highlightNgAreaStyles = makeStyles(() => ({
    inputRoot: {
      color: selectCastName?.cast?.ngAreas
        ?.map((area) => area.areaId)
        ?.includes(formData?.areaId)
        ? "red"
        : "inherit",
    },
  }));
  const highlightNgAreaClasses = highlightNgAreaStyles();
  const highlightNgGuestStyles = makeStyles(() => ({
    inputRoot: {
      color: selectCastName?.cast?.ngGuests
        ?.map((guest) => guest.guestId)
        ?.includes(formData?.guestId)
        ? "red"
        : "inherit",
    },
  }));
  const highlightNgGuestClasses = highlightNgGuestStyles();
  useEffect(() => {
    if (!maxClosingTime && !minOpeningTime) return;
    const timeDiff = TimeUtils.isInAllDays(shops)
      ? maxClosingTime - minOpeningTime
      : maxClosingTime - minOpeningTime + 24;
    const arrayHours = [...Array(timeDiff).keys()].map((a) =>
      String(a + minOpeningTime).padStart(2, "0")
    );
    setHours(arrayHours);
  }, [minOpeningTime, maxClosingTime]);

  useEffect(() => {
    if (!shops.length) return;
    setMinOpeningTime(
      Number(
        shops.reduce(
          (a, b) =>
            a < Number(b.openingTime.split(":")[0])
              ? a
              : Number(b.openingTime.split(":")[0]),
          24
        )
      )
    );
    setMaxClosingTime(TimeUtils.maxClosingTimeInShop(shops));
  }, [shops]);

  useEffect(() => {
    setFilterAdditionalTimes(
      additionalTimes.filter(
        (additionalTime) =>
          (formData?.shopId
            ? additionalTime.shops.some(
                (shop) => shop.shopId === formData.shopId
              )
            : true) &&
          (formData?.notelClassId
            ? !!additionalTime.notelClasses.find(
                (notelClass) =>
                  notelClass.notelClassId === formData.notelClassId
              )
            : true)
      )
    );
    setFilterCosplays(
      cosplays.filter((cosplay) =>
        formData?.shopId
          ? cosplay.shops.some((shop) => shop.shopId === formData.shopId)
          : true
      )
    );
    setFilterDiscounts(
      discounts.filter((discount) =>
        formData?.shopId
          ? discount.shops.some((shop) => shop.shopId === formData.shopId)
          : true
      )
    );
    setFilterMedia(
      media.filter((media) =>
        formData?.shopId
          ? media.shops.some((shop) => shop.shopId === formData.shopId)
          : true
      )
    );
  }, [
    formData?.shopId,
    formData?.notelClassId,
    additionalTimes,
    cosplays,
    discounts,
    media,
  ]);

  useEffect(() => {
    setFilterCastNames(
      castShifts?.flatMap((castShift) =>
        castShift?.cast?.castNames?.filter((castName) => {
          const shopCondition = formData?.shopId
            ? castName.shopId === formData.shopId
            : true;
          const notelClassCondition = formData?.notelClassId
            ? castName.notelClassId === formData?.notelClassId
            : true;
          return shopCondition && notelClassCondition;
        })
      )
    );
    setSelectCastName((prev) => {
      if (
        prev?.shopId === formData.shopId &&
        prev?.notelClassId === formData.notelClassId
      ) {
        return prev;
      }
    });
  }, [formData?.notelClassId, formData?.shopId, castShifts]);

  useEffect(() => {
    if (
      !formData?.castName ||
      filterCastNames
        .map((castName) => castName.castNameId)
        .includes(formData?.castNameId)
    )
      return;
    setFilterCastNames((prev) => [...prev, formData?.castName]);
  }, [formData?.castName]);

  useEffect(() => {
    let filterOptions = options.filter((option) =>
      formData?.shopId
        ? option.shops.some((shop) => shop.shopId === formData.shopId)
        : true
    );
    const castName = castNames.find(
      (castName) => castName.castNameId === formData?.castNameId
    );
    if (castName) {
      filterOptions = castName.options;
      setSelectCastName(castName);
    }
    setFilterOptions(filterOptions);
  }, [formData?.castNameId, formData.shopId, castNames]);

  useEffect(() => {
    setFilterCourses(
      courses.filter(
        (course) =>
          (formData?.notelClassId
            ? course.notelClassId === formData?.notelClassId
            : true) &&
          (formData?.shopId ? course.shopId === formData?.shopId : true) &&
          (formData?.courseTime
            ? course.time === formData?.courseTime
            : true) &&
          (formData?.nominationId
            ? course.nominationId === formData?.nominationId
            : true)
      )
    );
  }, [
    formData.courseTime,
    formData.shopId,
    formData.nominationId,
    formData.notelClassId,
    courses,
  ]);

  useEffect(() => {
    if (formData.areaId) {
      setFilterHotels(
        hotels.filter((hotel) =>
          hotel.areas?.map((area) => area.areaId).includes(formData.areaId)
        )
      );
      setSelectArea(areas.find((area) => area.areaId === formData?.areaId));
    } else {
      setFilterHotels(hotels);
    }
    const hotel = hotels.find((hotel) => hotel.hotelId === formData.hotelId);
    if (hotel) {
      setSelectHotel(hotel);
    }
    if (!hotel || !hotel?.areas) {
      setFilterAreas(
        areas.filter((area) =>
          formData?.shopId
            ? area.shops.some((shop) => shop.shopId === formData.shopId)
            : true
        )
      );
    } else if (hotel?.areas.length === 1) {
      onChange("areaId", hotel?.areas[0].areaId);
      setSelectArea(hotel?.areas[0]);
      setFilterAreas(hotel.areas);
    } else if (hotel.areas.length > 1) {
      setFilterAreas(hotel?.areas);
    }
    onChange("orderAddress2", hotel?.address || formData?.orderAddress2);
  }, [formData.hotelId, formData.areaId, formData.shopId, hotels, areas]);

  useEffect(() => {
    const nomination = nominations.find(
      (nomination) => nomination.nominationId === formData.nominationId
    );
    onChange("designateFee", nomination?.totalFee || 0);
    onChange("designateCastFee", nomination?.castFee || 0);
    onChange("designateShopFee", nomination?.shopFee || 0);
  }, [formData.nominationId, nominations]);

  useEffect(() => {
    calcTotalTime(formData);
  }, [
    formData?.courseId,
    formData?.additionalTimeCount,
    formData?.additionalTimeId,
    courses,
    additionalTimes,
  ]);

  useEffect(() => {
    calcAdditionalFee(formData?.additionalTimeOrders || []);
  }, [formData?.additionalTimeOrders]);

  useEffect(() => {
    if (
      PaymentType.creditCard ===
      EnumUtils.mapToEnum(PaymentType, formData?.paymentType)
    ) {
      const shop = shops.find((shop) => shop.shopId === formData?.shopId);
      const cardFee = Math.round(
        (formData?.subTotalFee || 0) * ((shop?.cardRate || 0) / 100)
      );
      onChange("cardFee", cardFee);
    } else {
      onChange("cardFee", null);
    }
  }, [
    formData?.paymentType,
    formData?.subTotalFee,
    formData?.shopId,
    formData?.orderId,
  ]);

  useEffect(() => {
    calcPlanOutTime(formData);
  }, [formData.planInTime, formData.totalTime]);

  useEffect(() => {
    calcActualEndTime(formData);
  }, [formData.actualInTime, formData.totalTime, oldOrder]);

  useEffect(() => {
    const course = courses.find(
      (course) => course.courseId === formData?.courseId
    );
    onChange("courseFee", course?.timeFee || 0);
    onChange("courseCastFee", course?.timeCastFee || 0);
    onChange("courseShopFee", course?.timeShopFee || 0);
    onChange("timeFee", course?.timeFee || 0);
    onChange("timeCastFee", course?.timeCastFee || 0);
    onChange("timeShopFee", course?.timeShopFee || 0);
    onChange("hotelFee", course?.hotelFee || 0);
    onChange("hotelCastFee", course?.hotelCastFee || 0);
    onChange("hotelShopFee", course?.hotelShopFee || 0);
    onChange("welfareFee", course?.welfareFee || 0);
  }, [formData.courseId, courses]);

  useEffect(() => {
    calcReturnTime(formData);
  }, [formData.areaId, formData.planOutTime]);

  useEffect(() => {
    if (!formData?.guestId || !formData?.castNameId) return;
    const fetch = async () => {
      setReOrder(
        await OrderApi.findReOrder(
          companyId,
          formData.guestId,
          formData.castNameId
        )
      );
    };
    fetch();
  }, [formData?.guestId, formData?.castNameId]);

  useEffect(() => {
    if (formData?.orderId || !reOrder?.orderId) return;
    const shop = shops.find((shop) => shop.shopId === formData?.shopId);
    if (!shop) {
      return onChange("nominationId", null);
    }
    const nominationId =
      reOrder?.shopId === formData?.shopId ? shop.nominationId : null;
    onChange("nominationId", nominationId);
  }, [formData?.shopId, reOrder]);

  useEffect(() => {
    if (!formData.castNameId || !selectCastName) {
      return setFilterNotelClasses(notelClasses);
    }
    const castName = castNames.find(
      (castName) => castName.castNameId === formData?.castNameId
    );
    if (!castName) return;
    onChange("shopId", castName.shopId);
    onChange("notelClassId", castName.notelClassId);
    setFilterNotelClasses(
      notelClasses.filter(
        (notelClass) => notelClass.notelClassId === castName.notelClassId
      )
    );
  }, [formData?.castNameId, notelClasses, selectCastName]);

  useEffect(() => {
    if (
      formData?.status &&
      formData?.actualInTime &&
      formData?.paymentType &&
      [OrderStatus.paid, OrderStatus.booking].includes(
        EnumUtils.mapToEnum(OrderStatus, formData.status) || OrderStatus.hold
      ) &&
      EnumUtils.mapToEnum(PaymentType, formData.paymentType) ===
        PaymentType.creditCard
    ) {
      onChange("collectReceivable", true);
    }
  }, [formData?.status, formData?.paymentType, formData?.actualInTime]);

  useEffect(() => {
    if (!formData?.departureHour || !formData?.departureMinute) {
      onChange("departureTime", null);
      return;
    }
    if (Number(formData.departureHour) > 24) {
      onChange(
        "departureTime",
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            days: 1,
            hours: Number(formData.departureHour) - 24,
            minutes: Number(formData.departureMinute),
          })
          .toJSDate()
      );
    } else {
      onChange(
        "departureTime",
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            hours: Number(formData.departureHour),
            minutes: Number(formData.departureMinute),
          })
          .toJSDate()
      );
    }
  }, [formData?.departureHour, formData?.departureMinute, formData?.orderDate]);

  useEffect(() => {
    if (!formData?.planInHour || !formData?.planInMinute) {
      onChange("planInTime", null);
      return;
    }
    if (Number(formData.planInHour) > 24) {
      onChange(
        "planInTime",
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            days: 1,
            hours: Number(formData.planInHour) - 24,
            minutes: Number(formData.planInMinute),
          })
          .toJSDate()
      );
    } else {
      onChange(
        "planInTime",
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            hours: Number(formData.planInHour),
            minutes: Number(formData.planInMinute),
          })
          .toJSDate()
      );
    }
  }, [formData?.planInHour, formData?.planInMinute, formData?.orderDate]);

  useEffect(() => {
    if (!formData?.actualInHour || !formData?.actualInMinute) {
      onChange("actualInTime", null);
      return;
    }
    if (Number(formData.actualInHour) > 24) {
      onChange(
        "actualInTime",
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            days: 1,
            hours: Number(formData.actualInHour) - 24,
            minutes: Number(formData.actualInMinute),
          })
          .toJSDate()
      );
    } else {
      onChange(
        "actualInTime",
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            hours: Number(formData.actualInHour),
            minutes: Number(formData.actualInMinute),
          })
          .toJSDate()
      );
    }
  }, [formData?.actualInHour, formData?.actualInMinute, formData?.orderDate]);

  useEffect(() => {
    if (!formData?.requestActualInHour || !formData?.requestActualInMinute) {
      onChange("requestActualInTime", null);
      return;
    }
    if (Number(formData.requestActualInHour) > 24) {
      onChange(
        "requestActualInTime",
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            days: 1,
            hours: Number(formData.requestActualInHour) - 24,
            minutes: Number(formData.requestActualInMinute),
          })
          .toJSDate()
      );
    } else {
      onChange(
        "requestActualInTime",
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            hours: Number(formData.requestActualInHour),
            minutes: Number(formData.requestActualInMinute),
          })
          .toJSDate()
      );
    }
  }, [
    formData?.requestActualInHour,
    formData?.requestActualInMinute,
    formData?.orderDate,
  ]);

  useEffect(() => {
    const medium = media.find(
      (medium) => medium.mediumId === formData.mediumId
    );
    onChange("mediumFee", medium?.totalFee || 0);
    onChange("mediumCastFee", medium?.castFee || 0);
    onChange("mediumShopFee", medium?.shopFee || 0);
  }, [formData.mediumId, media]);

  useEffect(() => {
    if (!selectHotel) return;
    const ho = hotels.find((hotel) => hotel.hotelId === selectHotel?.hotelId);
    if (ho?.isIncludeOrderPrice) {
      onChange("hotelFee", ho?.totalFee || 0);
      onChange("hotelCastFee", ho?.castFee || 0);
      onChange("hotelShopFee", ho?.shopFee || 0);
    }
  }, [selectHotel, hotels]);

  const calcTotalTime = (formData: any) => {
    const course = courses.find(
      (course) => course.courseId === formData?.courseId
    );
    if (!course) return;
    const additionalTime =
      formData?.additionalTimeOrders?.reduce(
        (sum: number, additionalTimeOrder: any) => {
          const at = additionalTimes.find(
            (additionalTime) =>
              additionalTime.additionalTimeId ===
              additionalTimeOrder.additionalTimeId
          );
          return sum + (at?.time || 0) * (additionalTimeOrder.count || 0);
        },
        0
      ) || 0;
    const totalTime = (formData.courseTime || course.time) + additionalTime;
    onChange("totalTime", totalTime);
  };

  const calcPlanOutTime = (formData: any) => {
    if (!formData.planInTime || !formData.totalTime) {
      onChange("planOutTime", null);
      return;
    }
    const planOutTime = DateTime.fromJSDate(formData.planInTime)
      .plus({ minutes: formData.totalTime })
      .toJSDate();
    onChange("planOutTime", planOutTime);
  };

  const calcActualEndTime = (formData: any) => {
    if (!formData.actualInTime || !formData.totalTime) {
      onChange("actualEndTime", null);
      return;
    }
    if (
      oldOrder?.actualInTime?.getTime() !== formData?.actualInTime?.getTime() ||
      formData.totalTime !== oldOrder.totalTime
    ) {
      const actualEndTime = DateTime.fromJSDate(formData.actualInTime)
        .plus({ minutes: formData.totalTime })
        .toJSDate();
      onChange("actualEndTime", actualEndTime);
    }
  };

  const calcReturnTime = (formData: any) => {
    if (!formData.planOutTime || !formData.areaId) return;
    const area = areas.find((area) => area.areaId === formData.areaId);
    if (!area) return;
    const returnTime = DateTime.fromJSDate(formData.planOutTime)
      .plus({
        minutes: area.time,
      })
      .toJSDate();
    onChange("returnTime", returnTime);
  };

  const calcAdditionalFee = (
    additionalTimeOrders: { additionalTime: AdditionalTimeRes; count: number }[]
  ) => {
    onChange(
      "additionalFee",
      additionalTimeOrders?.reduce(
        (sum, additionalTimeOrder) =>
          (additionalTimeOrder?.additionalTime?.totalFee || 0) *
            additionalTimeOrder.count +
          sum,
        0
      )
    );
    onChange(
      "additionalShopFee",
      additionalTimeOrders?.reduce(
        (sum, additionalTimeOrder) =>
          (additionalTimeOrder?.additionalTime?.shopFee || 0) *
            additionalTimeOrder.count +
          sum,
        0
      )
    );
    onChange(
      "additionalCastFee",
      additionalTimeOrders?.reduce(
        (sum, additionalTimeOrder) =>
          (additionalTimeOrder?.additionalTime?.castFee || 0) *
            additionalTimeOrder.count +
          sum,
        0
      )
    );
  };
  const castNameInfo = selectCastName ? (
    <Box display="flex" flexDirection="column">
      <Typography variant={"subtitle1"}>【キャスト備考】</Typography>
      <Typography style={{ whiteSpace: "pre-wrap" }}>
        {selectCastName?.cast?.remarks}
      </Typography>
      <Typography variant={"subtitle1"} style={{ marginTop: "12px" }}>
        【当日シフトキャストメモ】
      </Typography>
      <Typography>
        {castShifts?.find(
          (castShift) => castShift.castId === selectCastName?.castId
        )?.castMemo || ""}
      </Typography>
      <Typography variant={"subtitle1"} style={{ marginTop: "12px" }}>
        【当日シフトスタッフメモ】
      </Typography>
      <Typography>
        {castShifts?.find(
          (castShift) => castShift.castId === selectCastName?.castId
        )?.staffMemo || ""}
      </Typography>
    </Box>
  ) : (
    <Typography>キャストを選択してください</Typography>
  );

  const onClickAcceptCastRequestButton = () => {
    onChange(
      "actualEndTime",
      formData?.requestActualEndTime ||
        DateTime.fromJSDate(
          formData?.actualInTime || formData?.requestActualInTime
        )
          .plus({ minutes: formData?.totalTime || 0 })
          .toJSDate()
    );
    onChange("requestActualEndTime", null);
    if (formData?.requestActualEndTime) {
      onChange("responseStatus", "ok");
    }
    if (formData?.requestActualInTime) {
      onChange("actualInTime", formData?.requestActualInTime);
      onChange("actualInHour", formData?.requestActualInHour);
      onChange("actualInMinute", formData?.requestActualInMinute);
      onChange("requestActualInTime", null);
      onChange("requestActualInHour", null);
      onChange("requestActualInMinute", null);
    }
  };

  if (isLoading) return <CircularProgress />;
  return (
    <Box display="flex" flexDirection="column">
      <TextField
        label="日付"
        type="date"
        size="small"
        style={{ marginTop: "10px" }}
        variant="outlined"
        value={
          formData?.orderDate
            ? DateTime.fromJSDate(formData.orderDate).toFormat(
                FORMAT_TYPE.YEAR_DAY
              )
            : DateTimeUtils.toFormatAsLocalTimezone(
                DateTime.local()
                  .minus({
                    hours: changeDate.hour,
                    minutes: changeDate.minute,
                  })
                  .toJSDate(),
                FORMAT_TYPE.YEAR_DAY
              )
        }
        onChange={(event) =>
          onChange(
            "orderDate",
            DateTime.fromFormat(
              event.target.value,
              FORMAT_TYPE.YEAR_DAY
            ).toJSDate()
          )
        }
      />
      <Box display="flex" alignItems="center" style={{ marginTop: "10px" }}>
        <Autocomplete
          fullWidth
          classes={highlightNgGuestClasses}
          options={filterCastNames}
          value={selectCastName || null}
          getOptionLabel={(option: any) => option.name || ""}
          renderInput={(params) => <TextField {...params} label="キャスト" />}
          onChange={(event, value) => {
            setSelectCastName(value || undefined);
            onChange("castNameId", value?.castNameId || null);
          }}
          getOptionSelected={(option, value) =>
            option.castNameId === value.castNameId
          }
          noOptionsText="選択肢がありません"
        />
        <Tooltip title={castNameInfo} arrow>
          <Info />
        </Tooltip>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <InputLabel>クラス</InputLabel>
        <Select
          inputProps={{
            name: "class",
            id: "class",
          }}
          fullWidth
          value={formData?.notelClassId || ""}
          onChange={(event) => onChange("notelClassId", event.target.value)}
        >
          <option aria-label="None" value="" />
          {filterNotelClasses.map((notelClass) => (
            <MenuItem
              value={notelClass.notelClassId}
              key={notelClass.notelClassId}
            >
              {notelClass.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <InputLabel htmlFor={"time"}>時間</InputLabel>
        <Select
          fullWidth
          inputProps={{
            name: "time",
            id: "time",
          }}
          value={formData?.courseTime || ""}
          onChange={(event) => {
            onChange("courseTime", event.target.value || "");
            if (!event.target.value && formData?.courseId) {
              onChange("courseId", "");
            }
          }}
        >
          <option aria-label="None" value="" />
          {filterCourses
            .filter(
              (element, index, self) =>
                self.findIndex((e) => e.time === element.time) === index
            )
            .sort((a, b) => (a.time > b.time ? 1 : -1))
            .map((course) => (
              <MenuItem value={course.time} key={course.time}>
                {course.time}分
              </MenuItem>
            ))}
        </Select>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <InputLabel htmlFor={"nomination"}>指名</InputLabel>
        <Select
          fullWidth
          inputProps={{
            name: "nomination",
            id: "nomination",
          }}
          value={formData?.nominationId || ""}
          onChange={(event) => onChange("nominationId", event.target.value)}
        >
          <option aria-label="None" value="" />
          {nominations.map((nomination) => (
            <MenuItem
              value={nomination.nominationId}
              key={nomination.nominationId}
            >
              {nomination.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <InputLabel htmlFor={"course"}>コース</InputLabel>
        <Select
          fullWidth
          inputProps={{
            name: "course",
            id: "course",
          }}
          value={formData?.courseId || ""}
          onChange={(event) => {
            onChange("courseId", event.target.value);
            if (!event.target.value && formData?.courseTime) {
              onChange("courseTime", "");
            }
          }}
        >
          <option aria-label="None" value="" />
          {filterCourses.map((course) => (
            <MenuItem value={course.courseId} key={course.courseId}>
              {course.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <Box display="flex" alignItems="center">
          <Box display={"flex"} alignItems={"flex-start"}>
            <Box display={"flex"} flexDirection={"column"}>
              {formData?.additionalTimeOrders?.map(
                (selectAdditionalTimeOrder: any, i: number) => (
                  <Box
                    display={"flex"}
                    key={i}
                    alignItems={"center"}
                    marginTop={i > 0 ? 1 : 0}
                  >
                    {i === 0 && (
                      <InputLabel htmlFor={"additionalTime"}>延長</InputLabel>
                    )}
                    <Select
                      inputProps={{
                        name: "additionalTime",
                        id: "additionalTime",
                      }}
                      style={{
                        width: 100,
                        marginLeft: i > 0 ? "28px" : "initial",
                      }}
                      value={selectAdditionalTimeOrder.additionalTimeId || ""}
                      onChange={(event) =>
                        onChangeAdditionalTimeOrder(
                          i,
                          "additionalTimeId",
                          event.target.value
                        )
                      }
                    >
                      <option aria-label="None" value="" />
                      {filterAdditionalTimes.map((additionalTime) => (
                        <MenuItem
                          value={additionalTime.additionalTimeId}
                          key={additionalTime.additionalTimeId}
                        >
                          {additionalTime.time}分
                          {additionalTime.name && `(${additionalTime.name})`}
                        </MenuItem>
                      ))}
                    </Select>
                    <p style={{ margin: "0 5px" }}>×</p>
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{ minWidth: "50px" }}
                      value={selectAdditionalTimeOrder.count || 0}
                      onChange={(event) =>
                        onChangeAdditionalTimeOrder(
                          i,
                          "count",
                          event.target.value
                        )
                      }
                    />
                    <p style={{ margin: "0 0 0 5px" }}>回</p>
                    {i === 0 ? (
                      <Icon
                        onClick={() => {
                          addAdditionalTimeOrder();
                        }}
                      >
                        add
                      </Icon>
                    ) : (
                      <Icon
                        onClick={() => {
                          deleteAdditionalTimeOrder(i);
                        }}
                      >
                        delete
                      </Icon>
                    )}
                  </Box>
                )
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <InputLabel htmlFor={"discount"}>割引</InputLabel>
        <Select
          fullWidth
          inputProps={{
            name: "discount",
            id: "discount",
          }}
          multiple
          value={formData?.discounts || []}
          renderValue={(selected) =>
            filterDiscounts
              .filter((n) => (selected as number[]).includes(n.discountId))
              .map((n) => n.name)
              .join(", ")
          }
          onChange={(
            event: ChangeEvent<{
              name?: string | undefined;
              value: unknown;
            }>
          ) => {
            onChange("discounts", event.target.value);
            const discount = discounts.filter((discount) =>
              (event.target.value as number[]).includes(discount.discountId)
            );
            onChange(
              "discountFee",
              discount?.reduce((o, b) => b.totalFee + o, 0) || 0
            );
            onChange(
              "discountShopFee",
              discount?.reduce((o, b) => b.shopFee + o, 0) || 0
            );
            onChange(
              "discountCastFee",
              discount?.reduce((o, b) => b.castFee + o, 0) || 0
            );
          }}
        >
          {filterDiscounts.map((discount) => (
            <MenuItem value={discount.discountId} key={discount.discountId}>
              <Checkbox
                checked={formData?.discounts?.indexOf(discount.discountId) > -1}
              />
              <ListItemText primary={discount.name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <InputLabel htmlFor={"media"}>媒体</InputLabel>
        <Select
          inputProps={{
            name: "media",
            id: "media",
          }}
          fullWidth
          value={formData?.mediumId || ""}
          onChange={(event) => onChange("mediumId", event.target.value)}
        >
          <option aria-label="None" value="" />
          {filterMedia.map((medium) => (
            <MenuItem value={medium.mediumId} key={medium.mediumId}>
              {medium.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <InputLabel htmlFor={"option"}>オプション</InputLabel>
        <Select
          inputProps={{
            name: "option",
            id: "option",
          }}
          fullWidth
          value={formData?.options || []}
          multiple
          renderValue={(selected) =>
            filterOptions
              .filter((n) => (selected as number[]).includes(n.optionId))
              .map((n) => n.name)
              .join(", ")
          }
          onChange={(
            event: ChangeEvent<{
              name?: string | undefined;
              value: unknown;
            }>
          ) => {
            onChange("options", event.target.value);
            const option = options.filter((option) =>
              (event.target.value as number[]).includes(option.optionId)
            );
            onChange(
              "optionFee",
              option?.reduce((o, b) => b.totalFee + o, 0) || 0
            );
            onChange(
              "optionShopFee",
              option?.reduce((o, b) => b.shopFee + o, 0) || 0
            );
            onChange(
              "optionCastFee",
              option?.reduce((o, b) => b.castFee + o, 0) || 0
            );
          }}
        >
          {filterOptions.map((option) => (
            <MenuItem value={option.optionId} key={option.optionId}>
              <Checkbox
                checked={formData?.options?.indexOf(option.optionId) > -1}
              />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <InputLabel htmlFor={"cosplay"}>コスプレ</InputLabel>
        <Select
          inputProps={{
            name: "cosplay",
            id: "cosplay",
          }}
          fullWidth
          value={formData?.cosplayId || ""}
          onChange={(
            event: ChangeEvent<{
              name?: string | undefined;
              value: unknown;
            }>
          ) => {
            onChange("cosplayId", event.target.value);
            const cosplay = cosplays.find(
              (cosplay) => cosplay.cosplayId === (event.target.value as number)
            );
            onChange("cosplayFee", cosplay?.totalFee || 0);
            onChange("cosplayShopFee", cosplay?.shopFee || 0);
            onChange("cosplayCastFee", cosplay?.castFee || 0);
          }}
        >
          <option aria-label="None" value="" />
          {filterCosplays.map((cosplay) => (
            <MenuItem value={cosplay.cosplayId} key={cosplay.cosplayId}>
              {cosplay.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <InputLabel htmlFor={"area"}>エリア</InputLabel>
        <Autocomplete
          fullWidth
          classes={highlightNgAreaClasses}
          options={filterAreas}
          value={selectArea || null}
          getOptionLabel={(option: any) => option.name || ""}
          renderInput={(params) => <TextField {...params} />}
          onChange={(event, value) => {
            setSelectArea(value || undefined);
            onChange("areaId", value?.areaId);
            onChange("areaFee", value?.totalFee || 0);
            onChange("areaShopFee", value?.shopFee || 0);
            onChange("areaCastFee", value?.castFee || 0);
          }}
          noOptionsText="選択肢がありません"
        />
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <InputLabel htmlFor={"hotel"}>ホテル</InputLabel>
        <Autocomplete
          fullWidth
          options={filterHotels}
          value={selectHotel || null}
          getOptionLabel={(option: any) => option.name || ""}
          renderInput={(params) => <TextField {...params} />}
          onChange={(event, value) => {
            setSelectHotel(value || undefined);
            onChange("hotelId", value?.hotelId);
          }}
          noOptionsText="選択肢がありません"
        />
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <TextField
          label="住所"
          size="small"
          variant="outlined"
          fullWidth
          value={formData?.orderAddress || ""}
          onChange={(event) => {
            event.persist();
            onChange("orderAddress", event.target.value);
          }}
        />
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <TextField
          label="住所2"
          size="small"
          variant="outlined"
          fullWidth
          value={formData?.orderAddress2 || ""}
          onChange={(event) => {
            event.persist();
            onChange("orderAddress2", event.target.value);
          }}
        />
      </Box>
      <Box display="flex" alignItems="center" style={{ marginTop: "10px" }}>
        <InputLabel>出発</InputLabel>
        <Box display="flex">
          <Select
            native
            style={{ minWidth: "50px" }}
            value={formData?.departureHour || ""}
            onChange={(event) => onChange("departureHour", event.target.value)}
          >
            <option value="" />
            {hours.map((hour: string) => (
              <option key={hour} value={hour}>
                {hour}
              </option>
            ))}
          </Select>
          ：
          <Select
            native
            style={{ minWidth: "50px" }}
            value={formData?.departureMinute || ""}
            onChange={(event) =>
              onChange("departureMinute", event.target.value)
            }
          >
            <option value="" />
            {minutes.map((minute: string) => (
              <option key={minute} value={minute}>
                {minute}
              </option>
            ))}
          </Select>
        </Box>
        <InputLabel style={{ marginLeft: "10px" }}>予定IN</InputLabel>
        <Box display="flex" marginTop={1} flex={1}>
          <Select
            native
            style={{ minWidth: "50px" }}
            value={formData?.planInHour || ""}
            onChange={(event) => onChange("planInHour", event.target.value)}
          >
            <option value="" />
            {hours.map((hour: string) => (
              <option key={hour} value={hour}>
                {hour}
              </option>
            ))}
          </Select>
          ：
          <Select
            native
            style={{ minWidth: "50px" }}
            value={formData?.planInMinute || ""}
            onChange={(event) => onChange("planInMinute", event.target.value)}
          >
            <option value="" />
            {minutes.map((minute: string) => (
              <option key={minute} value={minute}>
                {minute}
              </option>
            ))}
          </Select>
        </Box>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <KeyboardDateTimePicker
          label="予定OUT"
          size="small"
          inputVariant="outlined"
          InputLabelProps={{ shrink: true }}
          value={formData?.planOutTime || null}
          format="YYYY/MM/DD HH:mm"
          disabled
          onChange={(date) => onChange("planOutTime", date?.toDate() || null)}
          minutesStep={5}
          variant="inline"
        />
      </Box>
      <Box display="flex" alignItems="center" style={{ marginTop: "10px" }}>
        <InputLabel>実IN</InputLabel>
        <Box display="flex" marginTop={1}>
          <Select
            native
            value={
              formData?.actualInHour || formData?.requestActualInHour || ""
            }
            onChange={(event) =>
              !formData?.actualInHour && formData?.requestActualInHour
                ? onChange("requestActualInHour", event.target.value)
                : onChange("actualInHour", event.target.value)
            }
            style={{
              minWidth: "50px",
              color:
                !formData?.actualInHour && formData?.requestActualInHour
                  ? "red"
                  : "inherit",
            }}
          >
            <option value="" />
            {hours.map((hour: string) => (
              <option key={hour} value={hour}>
                {hour}
              </option>
            ))}
          </Select>
          ：
          <Select
            native
            value={
              formData?.actualInMinute || formData?.requestActualInMinute || ""
            }
            onChange={(event) =>
              !formData?.actualInMinute && formData.requestActualInMinute
                ? onChange("requestActualInMinute", event.target.value)
                : onChange("actualInMinute", event.target.value)
            }
            style={{
              minWidth: "50px",
              color:
                !formData?.actualInMinute && formData?.requestActualInMinute
                  ? "red"
                  : "inherit",
            }}
          >
            <option value="" />
            {Array.from(Array(60).keys())
              .map((number) => String(number).padStart(2, "0"))
              .map((minute: string) => (
                <option key={minute} value={minute}>
                  {minute}
                </option>
              ))}
          </Select>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" style={{ marginTop: "10px" }}>
        <TextField
          label="実OUT"
          type="datetime-local"
          variant="outlined"
          size="small"
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            style: {
              color: formData?.requestActualEndTime ? "red" : "inherit",
            },
          }}
          value={
            formData?.requestActualEndTime || formData?.actualEndTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.requestActualEndTime || formData?.actualEndTime,
                  "yyyy-MM-dd'T'HH:mm"
                )
              : ""
          }
        />
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={onClickAcceptCastRequestButton}
          disabled={
            !formData.requestActualEndTime && !formData.requestActualInTime
          }
        >
          承認
        </Button>
      </Box>
      <Box display="flex" style={{ marginTop: "10px" }}>
        <InputLabel htmlFor={"responseStatus"}>応答</InputLabel>
        <Select
          inputProps={{
            name: "responseStatus",
            id: "responseStatus",
          }}
          style={{ width: 100 }}
          value={formData["responseStatus"] || ""}
          onChange={(event) => onChange("responseStatus", event.target.value)}
        >
          <option aria-label="None" value="" />
          {Object.entries(OrderResponseStatus).map(([key, value]) => (
            <MenuItem value={key} key={key}>
              {value}
            </MenuItem>
          ))}
        </Select>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(formData["collectReceivable"])}
              onChange={(event) =>
                onChange("collectReceivable", event.target.checked)
              }
              disabled
            />
          }
          label="回収"
        />
        <InputLabel htmlFor={"paymentType"}>支払い</InputLabel>
        <Select
          inputProps={{
            name: "paymentType",
            id: "paymentType",
          }}
          style={{ width: 100 }}
          onChange={(event) => onChange("paymentType", event.target.value)}
          value={formData["paymentType"] || "cash"}
        >
          <option aria-label="None" value="" />
          {Object.entries(PaymentType).map(([key, value]) => (
            <MenuItem value={key} key={key}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <TextField
          multiline
          label="メモ"
          variant="outlined"
          fullWidth
          rows={3}
          rowsMax={1000}
          value={formData?.memo}
          onChange={(event) => {
            event.persist();
            onChange("memo", event.target.value);
          }}
        />
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <TextField
          multiline
          label="アンケート"
          variant="outlined"
          fullWidth
          rows={3}
          rowsMax={1000}
          value={formData?.questionnaire}
          onChange={(event) => {
            event.persist();
            onChange("questionnaire", event.target.value);
          }}
        />
      </Box>
      <Box style={{ marginTop: "10px" }} display="flex" alignItems="center">
        <TextField
          label="評価点"
          type="number"
          size="small"
          variant="outlined"
          value={formData?.score}
          onChange={(event) => {
            event.persist();
            onChange("score", Number(event.target.value));
          }}
        />
        <Typography style={{ marginLeft: "10px" }}>/ 100点</Typography>
      </Box>
    </Box>
  );
};
export default SpOrderInfo;
