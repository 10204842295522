import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import NotificationRes from "types/res/notification/NotificationRes";
import UpdateNotificationReq from "types/req/notification/UpdateNotificationReq";
import CreateNotificationReq from "types/req/notification/CreateNotificationReq";
import DeleteNotificationReq from "types/req/notification/DeleteNotificationReq";
import NotificationApi from "../../api/NotificationApi";

const actionCreator = actionCreatorFactory();
const addNotificationAction = actionCreator.async<
  CreateNotificationReq,
  NotificationRes,
  { code: number; description: string }
>("ADD_NOTIFICATION");

export const addNotification =
  (companyId: number, req: CreateNotificationReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addNotificationAction.started({ ...req }));

    try {
      const notification = new CreateNotificationReq({ ...req });
      const result = await NotificationApi.create(companyId, notification);
      dispatch(
        addNotificationAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addNotificationAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchNotificationAction = actionCreator.async<
  {},
  NotificationRes[],
  { code: number; description: string }
>("FETCH_NOTIFICATION");

export const fetchNotification =
  (companyId: number, shopId?: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchNotificationAction.started([]));

    try {
      const result = await NotificationApi.findAll(companyId, shopId);
      dispatch(
        fetchNotificationAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchNotificationAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateNotificationAction = actionCreator.async<
  UpdateNotificationReq,
  NotificationRes,
  { code: number; description: string }
>("UPDATE_NOTIFICATION");

export const updateNotification =
  (companyId: number, req: UpdateNotificationReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateNotificationAction.started({ ...req }));

    try {
      const updateNotification = new UpdateNotificationReq({ ...req });
      const result = await NotificationApi.update(
        companyId,
        updateNotification
      );
      dispatch(
        updateNotificationAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateNotificationAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteNotificationAction = actionCreator.async<
  DeleteNotificationReq,
  {},
  { code: number; description: string }
>("DELETE_NOTIFICATION");

export const deleteNotification =
  (companyId: number, req: DeleteNotificationReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteNotificationAction.started({ ...req }));

    try {
      const notification = new DeleteNotificationReq({ ...req });
      const result = await NotificationApi.delete(companyId, notification);
      dispatch(
        deleteNotificationAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteNotificationAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const notificationActions = {
  fetchNotificationAction,
  addNotificationAction,
  updateNotificationAction,
  deleteNotificationAction,
};

export default notificationActions;
