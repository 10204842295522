import { Exclude, Expose, Type } from "class-transformer";
import CastRes from "types/res/cast/CastRes";
import ShopRes from "types/res/shop/ShopRes";

@Exclude()
export default class RecruitingMediaRes {
  @Expose() readonly recruitingMediaId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly companyId!: number;
  @Type(() => CastRes)
  @Expose()
  readonly casts!: CastRes[];
  @Expose() readonly cost!: number;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
}
