import React, { useState } from "react";
import styled from "styled-components";
import {
  TextField,
  Button,
  CircularProgress,
  Link,
  Box,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { signin } from "redux/actions/account";
import useModal from "hooks/useModal";

const Container = styled.div`
  > * {
    margin-bottom: ${({ theme: { spacing } }) => spacing(2)}px;
  }
`;

const SigninCard: React.FC = () => {
  const [, setShowModal] = useModal("signin");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const onClickPostButton = async () => {
    if (!email || !password) return;

    setLoading(true);
    await dispatch(signin(email, password));
    dispatch(setShowModal(false));
    setLoading(false);
  };
  const onChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(event.target.value);
  };
  const onChangePassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(event.target.value);
  };

  return (
    <Container>
      <TextField
        required
        label="メールアドレス"
        variant="outlined"
        autoComplete="email"
        fullWidth
        onChange={onChangeEmail}
      />
      <TextField
        label="パスワード"
        required
        type="password"
        autoComplete="current-password"
        variant="filled"
        fullWidth
        onChange={onChangePassword}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading}
        onClick={onClickPostButton}
      >
        {loading ? <CircularProgress color="inherit" size={24} /> : "ログイン"}
      </Button>
      <Box display="flex" justifyContent="center">
        <Link href="/resetPassword/send" style={{ color: "#888888" }}>
          パスワードをお忘れの方はこちら
        </Link>
      </Box>
    </Container>
  );
};

export default SigninCard;
