import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import RequestGuestApi from "api/RequestGuestApi";
import RequestGuestRes from "types/res/requestGuest/RequestGuestRes";

const actionCreator = actionCreatorFactory();

const fetchRequestGuestsAction = actionCreator.async<
  {},
  RequestGuestRes[],
  { code: number; description: string }
>("FETCH_REQUEST_GUESTS");

export const fetchRequestGuests =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchRequestGuestsAction.started([]));

    try {
      const result = await RequestGuestApi.findAll(companyId);
      dispatch(
        fetchRequestGuestsAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchRequestGuestsAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const approveRequestGuestAction = actionCreator.async<
  {},
  RequestGuestRes[],
  { code: number; description: string }
>("APPROVE_REQUEST_GUEST");

export const approveRequestGuest =
  (companyId: number, id: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(approveRequestGuestAction.started([]));

    try {
      const result = await RequestGuestApi.approve(companyId, id);
      dispatch(
        approveRequestGuestAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        approveRequestGuestAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };
const deleteRequestGuestAction = actionCreator.async<
  {},
  RequestGuestRes[],
  { code: number; description: string }
>("DELETE_REQUEST_GUEST");

export const deleteRequestGuest =
  (companyId: number, id: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(deleteRequestGuestAction.started([]));

    try {
      const result = await RequestGuestApi.delete(companyId, id);
      dispatch(
        deleteRequestGuestAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteRequestGuestAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const requestGuestActions = {
  fetchRequestGuestsAction,
  approveRequestGuestAction,
  deleteRequestGuestAction,
};

export default requestGuestActions;
