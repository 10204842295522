import { reducerWithInitialState } from "typescript-fsa-reducers";
import AccountingRes from "types/res/accounting/AccountingRes";
import accountingActions from "redux/actions/accouting";

export type AccountingState = AccountingRes;

const initialState = {} as AccountingState;

const accountingReducer = reducerWithInitialState(initialState).case(
  accountingActions.fetchAccountingAction.done,
  (_state, payload) => {
    return payload.result;
  }
);

export default accountingReducer;
