import RequestUtils from "utils/RequestUtils";
import MessageBoardCheckRes from "types/res/messageBoardCheck/MessageBoardCheckRes";
import CreateMessageBoardCheckReq from "types/req/messageBoardCheck/CreateMessageBoardCheckReq";
import DeleteMessageBoardCheckReq from "types/req/messageBoardCheck/DeleteMessageBoardCheckReq";

export default class MessageBoardCheckApi {
  private constructor() {}

  static async findAll(companyId: number): Promise<MessageBoardCheckRes[]> {
    return await RequestUtils.getArray(
      MessageBoardCheckRes,
      `/messageBoardCheck/findAll/${companyId}`
    );
  }

  static async create(companyId: number, data: CreateMessageBoardCheckReq) {
    return await RequestUtils.post(
      MessageBoardCheckRes,
      `/messageBoardCheck/create/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteMessageBoardCheckReq) {
    return await RequestUtils.postVoid(
      `/messageBoardCheck/delete/${companyId}`,
      data
    );
  }
}
