import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import MediumRes from "types/res/medium/MediumRes";
import UpdateMediumReq from "types/req/medium/UpdateMediumReq";
import CreateMediumReq from "types/req/medium/CreateMediumReq";
import DeleteMediumReq from "types/req/medium/DeleteMediumReq";
import MediumApi from "../../api/MediumApi";

const actionCreator = actionCreatorFactory();
const addMediumAction = actionCreator.async<
  CreateMediumReq,
  MediumRes,
  { code: number; description: string }
>("ADD_MEDIUM");

export const addMedium =
  (companyId: number, req: CreateMediumReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addMediumAction.started({ ...req }));

    try {
      const medium = new CreateMediumReq({ ...req });
      const result = await MediumApi.create(companyId, medium);
      dispatch(
        addMediumAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addMediumAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertMediumAction = actionCreator.async<
  CreateMediumReq[],
  MediumRes[],
  { code: number; description: string }
>("BULK_INSERT_MEDIUM");

export const bulkInsertMedium =
  (companyId: number, req: CreateMediumReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertMediumAction.started({ ...req }));

    try {
      const result = await MediumApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertMediumAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertMediumAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchMediumAction = actionCreator.async<
  {},
  MediumRes[],
  { code: number; description: string }
>("FETCH_MEDIUM");

export const fetchMedium =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchMediumAction.started([]));

    try {
      const result = await MediumApi.findAll(companyId);
      dispatch(
        fetchMediumAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchMediumAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateMediumAction = actionCreator.async<
  UpdateMediumReq,
  MediumRes,
  { code: number; description: string }
>("UPDATE_MEDIUM");

export const updateMedium =
  (companyId: number, req: UpdateMediumReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateMediumAction.started({ ...req }));

    try {
      const updateMedium = new UpdateMediumReq({ ...req });
      const result = await MediumApi.update(
        companyId,
        req.mediumId,
        updateMedium
      );
      dispatch(
        updateMediumAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateMediumAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteMediumAction = actionCreator.async<
  DeleteMediumReq,
  {},
  { code: number; description: string }
>("DELETE_MEDIUM");

export const deleteMedium =
  (companyId: number, req: DeleteMediumReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteMediumAction.started({ ...req }));

    try {
      const medium = new DeleteMediumReq({ ...req });
      const result = await MediumApi.delete(companyId, medium);
      dispatch(
        deleteMediumAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteMediumAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const mediumActions = {
  fetchMediumAction,
  addMediumAction,
  updateMediumAction,
  deleteMediumAction,
  bulkInsertMediumAction,
};

export default mediumActions;
