import { Exclude, Expose, Type } from "class-transformer";
import CastRes from "types/res/cast/CastRes";

@Exclude()
export default class CastExaminationRes {
  @Expose() readonly castExaminationId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly castId!: number;
  @Type(() => CastRes)
  @Expose()
  readonly cast!: CastRes;
  @Expose() readonly date!: Date;
}
