import { IsNotEmpty } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";

export default class DeleteCastCategoryReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly castCategoryId: number;

  constructor(arg: { castCategoryId: number }) {
    arg = arg || {};
    this.castCategoryId = arg.castCategoryId;
  }
}
