import RequestUtils from "utils/RequestUtils";
import GuestPointRes from "types/res/guestPoint/GuestPointRes";
import CreateGuestPointReq from "types/req/guestPoint/CreateGuestPointReq";
import CheckPointRes from "types/res/guestPoint/CheckPointRes";
import querystring from "querystring";

export default class GuestPointApi {
  private constructor() {}

  static async checkPoint(
    companyId: number,
    guestId: number,
    shopId: number
  ): Promise<CheckPointRes> {
    return await RequestUtils.get(
      CheckPointRes,
      `/guestPoint/checkPoints/${companyId}/${guestId}/${shopId}`
    );
  }
  static async findAll(
    companyId: number,
    limit: number,
    offset: number,
    shopId?: number,
    guestId?: number
  ): Promise<GuestPointRes[]> {
    const queryString = querystring.stringify({
      limit,
      offset,
      shopId,
      guestId,
    });
    return await RequestUtils.getArray(
      GuestPointRes,
      `/guestPoint/findAll/${companyId}?${queryString}`
    );
  }

  static async count(companyId: number, shopId?: number, guestId?: number) {
    const queryString = querystring.stringify({
      shopId,
      guestId,
    });
    return await RequestUtils.count(
      `/guestPoint/count/${companyId}?${queryString}`
    );
  }

  static async create(companyId: number, data: CreateGuestPointReq) {
    return await RequestUtils.post(
      GuestPointRes,
      `/guestPoint/create/${companyId}`,
      data
    );
  }

  static async bulkInsert(companyId: number, data: CreateGuestPointReq[]) {
    return await RequestUtils.postArray(
      GuestPointRes,
      `/guestPoint/bulkInsert/${companyId}`,
      data
    );
  }
}
