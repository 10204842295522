import { IsEmail, IsNotEmpty, IsOptional, MaxLength } from "class-validator";
import Spec from "constants/Spec";
import { DataValidationError } from "constants/DataValidationError";
import { BusinessCategoryType } from "types/enum/BusinessCategoryType";

export default class UpdateShopReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly shopId: number;

  @MaxLength(Spec.maxLength.shop.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.shop.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @MaxLength(Spec.maxLength.shop.email, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.shop.email,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsEmail({}, { message: DataValidationError.DATA_IS_INVALID })
  email!: string;

  @MaxLength(Spec.maxLength.shop.tel, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.shop.tel,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  tel!: string;

  @MaxLength(Spec.maxLength.shop.realName, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.shop.realName,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  realName!: string;

  @MaxLength(Spec.maxLength.shop.url, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.shop.url,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  url!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  cardRate!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  pointRate!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  orderAlertEmailTime!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  openingTime!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  closingTime!: string;

  @IsOptional()
  nominationId!: number | null;

  @IsOptional()
  acdCode!: string | null;

  @IsOptional()
  isGuestOrder!: boolean;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  businessCategoryType!: BusinessCategoryType;

  constructor(arg: {
    shopId: number;
    name: string;
    email: string;
    tel: string;
    realName: string;
    url: string;
    cardRate: number;
    pointRate: number;
    orderAlertEmailTime: number;
    openingTime: string;
    closingTime: string;
    nominationId: number | null;
    acdCode: string | null;
    isGuestOrder: boolean;
    businessCategoryType: BusinessCategoryType;
  }) {
    arg = arg || {};
    this.shopId = arg.shopId;
    this.name = arg.name;
    this.email = arg.email;
    this.tel = arg.tel;
    this.realName = arg.realName;
    this.url = arg.url;
    this.cardRate = arg.cardRate;
    this.pointRate = arg.pointRate;
    this.orderAlertEmailTime = arg.orderAlertEmailTime;
    this.openingTime = arg.openingTime;
    this.closingTime = arg.closingTime;
    this.nominationId = arg.nominationId;
    this.acdCode = arg.acdCode;
    this.isGuestOrder = arg.isGuestOrder;
    this.businessCategoryType = arg.businessCategoryType;
  }
}
