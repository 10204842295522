import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty, MaxLength } from "class-validator";
import Spec from "constants/Spec";

export default class CreateMessageBoardReq {
  @MaxLength(Spec.maxLength.messageBoard.title, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.messageBoard.title,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  title!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  content!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  createdById!: number;

  constructor(arg: { title: string; content: string; createdById: number }) {
    arg = arg || {};
    this.title = arg.title;
    this.content = arg.content;
    this.createdById = arg.createdById;
  }
}
