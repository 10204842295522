import { DataValidationError } from "constants/DataValidationError";
import { IsDate, IsNotEmpty, IsOptional, MaxLength } from "class-validator";
import Spec from "constants/Spec";
import { Type } from "class-transformer";
import ShiftStatus from "types/enum/ShiftStatus";

export default class CreateDriverShiftReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  driverId!: number;

  @Type(() => Date)
  @IsDate()
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  planWorkStart!: Date;

  @Type(() => Date)
  @IsDate()
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  planWorkEnd!: Date;

  @IsOptional()
  @Type(() => Date)
  @IsDate()
  actualWorkStart!: Date | null;

  @IsOptional()
  @Type(() => Date)
  @IsDate()
  actualWorkEnd!: Date | null;

  @IsOptional()
  @Type(() => Date)
  @IsDate()
  workOffStart!: Date | null;

  @IsOptional()
  @Type(() => Date)
  @IsDate()
  workOffEnd!: Date | null;

  @IsOptional()
  scheduleItemColor!: string | null;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  status!: ShiftStatus;

  @IsOptional()
  @MaxLength(Spec.maxLength.driverShift.staffMemo, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driverShift.staffMemo,
    },
  })
  staffMemo!: string | null;

  @IsOptional()
  @MaxLength(Spec.maxLength.driverShift.driverMemo, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driverShift.driverMemo,
    },
  })
  driverMemo!: string | null;

  constructor(arg: {
    driverId: number;
    planWorkStart: Date;
    planWorkEnd: Date;
    actualWorkStart: Date | null;
    actualWorkEnd: Date | null;
    workOffStart: Date | null;
    workOffEnd: Date | null;
    status: ShiftStatus;
    scheduleItemColor: string | null;
    staffMemo: string | null;
    driverMemo: string | null;
  }) {
    arg = arg || {};
    this.driverId = arg.driverId;
    this.planWorkStart = arg.planWorkStart;
    this.planWorkEnd = arg.planWorkEnd;
    this.actualWorkStart = arg.actualWorkStart;
    this.actualWorkEnd = arg.actualWorkEnd;
    this.workOffStart = arg.workOffStart;
    this.workOffEnd = arg.workOffEnd;
    this.status = arg.status;
    this.scheduleItemColor = arg.scheduleItemColor;
    this.staffMemo = arg.staffMemo;
    this.driverMemo = arg.driverMemo;
  }
}
