import { IsNotEmpty } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";

export default class DeleteHotelReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly hotelId: number;

  constructor(arg: { hotelId: number }) {
    arg = arg || {};
    this.hotelId = arg.hotelId;
  }
}
