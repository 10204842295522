import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty, IsNumber, IsOptional, MaxLength } from "class-validator";
import Spec from "constants/Spec";

export default class CourseBaseReq {
  @MaxLength(Spec.maxLength.course.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.course.name,
    },
  })
  name!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  time!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  timeFee!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  timeCastFee!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  timeShopFee!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  welfareFee!: number;

  @IsOptional()
  hotelFee!: number | null;

  @IsOptional()
  hotelCastFee!: number | null;

  @IsOptional()
  hotelShopFee!: number | null;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  totalFee!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  shopId!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  nominationId!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  notelClassId!: number;

  @IsOptional()
  @IsNumber()
  sort!: number | null;

  constructor(arg: {
    name: string;
    time: number;
    timeFee: number;
    timeShopFee: number;
    timeCastFee: number;
    welfareFee: number;
    hotelFee: number | null;
    hotelCastFee: number | null;
    hotelShopFee: number | null;
    totalFee: number;
    shopId: number;
    notelClassId: number;
    nominationId: number;
    sort: number | null;
  }) {
    arg = arg || {};
    this.name = arg.name;
    this.time = arg.time;
    this.timeFee = arg.timeFee;
    this.timeCastFee = arg.timeCastFee;
    this.timeShopFee = arg.timeShopFee;
    this.welfareFee = arg.welfareFee;
    this.hotelFee = arg.hotelFee;
    this.hotelCastFee = arg.hotelCastFee;
    this.hotelShopFee = arg.hotelShopFee;
    this.totalFee = arg.totalFee;
    this.shopId = arg.shopId;
    this.nominationId = arg.nominationId;
    this.notelClassId = arg.notelClassId;
    this.sort = arg.sort;
  }
}
