import FindMonthlyOrderDataReq from "types/req/summarySales/FindMonthlyOrderDataReq";
import RequestUtils from "utils/RequestUtils";
import FindMonthlyCastDataReq from "types/req/summarySales/FindMonthlyCastDataReq";

export default class GuestAnalyticsSummarySalesApi {
  private constructor() {}

  static async findMonthlyOrderData(
    companyId: number,
    data: FindMonthlyOrderDataReq
  ) {
    return await RequestUtils.postPlain(
      `/guestAnalyticsSummarySales/findMonthlyOrderData/${companyId}`,
      data
    );
  }

  static async findWeeklyOrderData(
    companyId: number,
    data: FindMonthlyOrderDataReq
  ) {
    return await RequestUtils.postPlain(
      `/guestAnalyticsSummarySales/findWeeklyOrderData/${companyId}`,
      data
    );
  }

  static async findMonthlyCastData(
    companyId: number,
    data: FindMonthlyCastDataReq
  ) {
    return await RequestUtils.postPlain(
      `/guestAnalyticsSummarySales/findMonthlyCastData/${companyId}`,
      data
    );
  }

  static async findWeeklyCastData(
    companyId: number,
    data: FindMonthlyCastDataReq
  ) {
    return await RequestUtils.postPlain(
      `/guestAnalyticsSummarySales/findWeeklyCastData/${companyId}`,
      data
    );
  }
}
