import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Box, SvgIconTypeMap, Typography } from "@material-ui/core";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SearchIcon from "@material-ui/icons/Search";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ComputerIcon from "@material-ui/icons/Computer";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import OrderApi from "api/OrderApi";
import StaffRole from "types/enum/StaffRole";
import EnumUtils from "utils/EnumUtils";
import {
  fetchCompanies,
  fetchCompaniesByCompanyGroupId,
} from "redux/actions/company";
export const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5%;
  background-color: rgb(40, 107, 204);
  border-bottom: solid 2px rgb(153, 153, 153);
`;
export const LinkContainer = styled(Link)<{ to: string }>`
  width: calc(100% / 3);
  text-align: center;
  padding: 3%;
`;

export const IconWrapper = styled.div`
  width: 80%;
  padding: 20% 0 12%;
  background-color: white;
  border-radius: 50%;
  margin: 0 auto;
  > * {
    fill: black;
  }
`;

export const Label = styled.p`
  color: white;
  font-size: 12px;
`;
const SpLanding = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const staff = useSelector((state) => state.account.staff);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const companies = useSelector((state) => state.company);
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [salesAndCount, setSalesAndCount] = useState<{
    booking: { sales: number; count: number };
    paid: { sales: number; count: number };
  }>();

  const today = DateTimeUtils.toFormatAsLocalTimezone(
    DateTime.local()
      .minus({
        hours: changeDate.hour,
        minutes: changeDate.minute,
      })
      .toJSDate(),
    FORMAT_TYPE.YEAR_DAY
  );
  useEffect(() => {
    const apiCall = async () => {
      const result = await OrderApi.saleAndCountForBookingAndPaid(
        companyId,
        DateTime.local()
          .minus({ hours: changeDate.hour, minutes: changeDate.minute })
          .toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.local()
          .minus({ hours: changeDate.hour, minutes: changeDate.minute })
          .toFormat(FORMAT_TYPE.YEAR_DAY)
      );
      setSalesAndCount(result);
    };
    apiCall();
    if (
      [
        StaffRole.notelMaster,
        StaffRole.notelCallCenterEmployee,
        StaffRole.notelCallCenterPartTime,
      ].includes(
        EnumUtils.mapToEnum(StaffRole, staff?.role) ||
          StaffRole.clientShopPartTime
      )
    ) {
      dispatch(fetchCompanies());
    }
    if (
      StaffRole.clientMaster === EnumUtils.mapToEnum(StaffRole, staff?.role) &&
      staff?.company?.companyGroupId
    ) {
      dispatch(
        fetchCompaniesByCompanyGroupId(
          staff.companyId,
          staff.company.companyGroupId
        )
      );
    }
  }, []);

  type LinkType = {
    readonly href: string;
    readonly label: string;
    readonly icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  };

  const links: LinkType[] = [
    {
      href: "/order",
      label: "受注",
      icon: NoteAddIcon,
    },
    {
      href: "/orderList",
      label: "受注検索",
      icon: SearchIcon,
    },
    {
      href: "/castSchedule",
      label: "キャストスケジュール",
      icon: ScheduleIcon,
    },
    {
      href: "/castShift",
      label: "キャストシフト",
      icon: EventIcon,
    },
    {
      href: "/driverShift",
      label: "ドライバーシフト",
      icon: EventIcon,
    },
    {
      href: "/driverSchedule",
      label: "配車",
      icon: DriveEtaIcon,
    },
    {
      href: "/payoff",
      label: "精算・会計",
      icon: ReceiptIcon,
    },
  ];
  if (
    [
      StaffRole.notelMaster,
      StaffRole.notelCallCenterEmployee,
      StaffRole.notelCallCenterPartTime,
      StaffRole.clientMaster,
    ].includes(
      EnumUtils.mapToEnum(StaffRole, staff.role) || StaffRole.clientShopPartTime
    ) &&
    companies.length > 1
  ) {
    links.push({
      href: "/companySwitch",
      label: "企業切り替え",
      icon: AutorenewIcon,
    });
  }
  links.push({
    href: "/siteSwitch",
    label: "PC表示切替",
    icon: ComputerIcon,
  });

  return (
    <>
      <LinksWrapper>
        {links.map((item) => (
          <React.Fragment key={item.href}>
            <LinkContainer to={item.href}>
              <IconWrapper>
                <item.icon fontSize="large" />
              </IconWrapper>
              <Label>{item.label}</Label>
            </LinkContainer>
          </React.Fragment>
        ))}
      </LinksWrapper>
      <Box display="flex" padding={2} borderBottom={1}>
        <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
          {today}
        </Typography>
        <Box display={"flex"} flexDirection={"column"} marginLeft={1}>
          <Typography style={{ fontSize: "16px" }}>
            予約本数: {salesAndCount?.booking?.count || 0}本
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            予約売上: {salesAndCount?.booking?.sales || 0}円
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            完了本数: {salesAndCount?.paid?.count || 0}本
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            完了売上: {salesAndCount?.paid?.sales || 0}円
          </Typography>
        </Box>
      </Box>
    </>
  );
};
export default SpLanding;
