import RequestUtils from "utils/RequestUtils";
import JournalRes from "../types/res/journal/JournalRes";
import CreateJournalReq from "../types/req/journal/CreateJournalReq";
import UpdateJournalReq from "../types/req/journal/UpdateJournalReq";
import DeleteJournalReq from "../types/req/journal/DeleteJournalReq";
import querystring from "querystring";

export default class JournalApi {
  private constructor() {}

  static async findAll(
    companyId: number,
    startDate?: string,
    endDate?: string
  ): Promise<JournalRes[]> {
    const query = querystring.stringify({ startDate, endDate });
    return await RequestUtils.getArray(
      JournalRes,
      `/journal/findAll/${companyId}?${query}`
    );
  }

  static async create(companyId: number, data: CreateJournalReq) {
    return await RequestUtils.post(
      JournalRes,
      `/journal/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    data: UpdateJournalReq
  ): Promise<JournalRes> {
    return await RequestUtils.post(
      JournalRes,
      `/journal/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteJournalReq) {
    return await RequestUtils.postVoid(`/journal/delete/${companyId}`, data);
  }
}
