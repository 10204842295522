import React from "react";
import styled from "styled-components";
import Tooltip from "rc-tooltip";
import {
  Box,
  Button,
  createStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { updateDriverShift } from "redux/actions/driverShift";
import { useDispatch, useSelector } from "react-redux";
import { CastScheduleItemColor } from "types/enum/CastScheduleItemColor";
const Item = styled.div<{ color: string }>`
  background-color: ${(props) => props.color} !important;
  border: 1px solid ${(props) => props.color} !important;
  border-radius: 4px;
`;
type Props = {
  item: any;
  itemContext: any;
  getItemProps: any;
  onClick: any;
};
const DriverScheduleShiftItem: React.FC<Props> = ({
  item,
  itemContext,
  getItemProps,
  onClick,
}) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );

  const updateScheduleColor = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    dispatch(
      updateDriverShift(companyId, {
        ...item?.driverShift,
        scheduleItemColor: event.target.value,
      })
    );
  };
  const classes = useStyles();
  return (
    <Tooltip
      trigger="hover"
      placement="top"
      overlay={
        <Box display="flex" flexDirection="column">
          <span>
            ドライバーメモ：{item?.driverShift?.driverMemo || "未設定"}
          </span>
          <span>
            スタッフメモ：{item?.driverShift?.driver?.remarks || "未設定"}{" "}
          </span>
          <Select
            style={{ backgroundColor: "white", marginBottom: "15px" }}
            variant={"outlined"}
            value={item?.driverShift?.scheduleItemColor || ""}
            onChange={(event) => updateScheduleColor(event)}
          >
            <MenuItem value={""}>
              <Typography color="textSecondary">選択を外す</Typography>
            </MenuItem>
            {Object.entries(CastScheduleItemColor).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={() => onClick()}
          >
            編集
          </Button>
        </Box>
      }
    >
      <Item {...getItemProps(item.itemProps)} color={item.color}>
        <div
          className="rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
        >
          {itemContext.title}
        </div>
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        />
      </Item>
    </Tooltip>
  );
};
export default DriverScheduleShiftItem;
