import RequestUtils from "utils/RequestUtils";
import SettingBookEmailRes from "types/res/settingBookEmail/SettingBookEmailRes";
import UpdateSettingBookEmailReq from "types/req/settingBookEmail/UpdateSettingBookEmailReq";

export default class SettingBookEmailApi {
  private constructor() {}

  static async find(companyId: number): Promise<SettingBookEmailRes[]> {
    return await RequestUtils.getArray(
      SettingBookEmailRes,
      `/settingBookEmail/find/${companyId}`
    );
  }

  static async update(
    companyId: number,
    data: UpdateSettingBookEmailReq
  ): Promise<SettingBookEmailRes> {
    return await RequestUtils.post(
      SettingBookEmailRes,
      `/settingBookEmail/update/${companyId}`,
      data
    );
  }
}
