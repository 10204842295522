import { DataValidationError } from "constants/DataValidationError";
import {
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";

export default class CreateHotelReq {
  @MaxLength(Spec.maxLength.hotel.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.hotel.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @MaxLength(Spec.maxLength.hotel.kana, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.hotel.kana,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  kana!: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.hotel.tel, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.hotel.tel,
    },
  })
  tel: string | null = null;

  @IsOptional()
  @MaxLength(Spec.maxLength.hotel.address, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.hotel.address,
    },
  })
  address: string | null = null;

  @IsNumber()
  totalFee!: number;

  @IsNumber()
  shopFee!: number;

  @IsNumber()
  castFee!: number;

  @IsBoolean()
  isIncludeOrderPrice!: boolean;

  @IsOptional()
  @MaxLength(Spec.maxLength.hotel.memo, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.hotel.memo,
    },
  })
  memo: string | null;

  @IsOptional()
  @IsNumber()
  sort!: number | null;

  @IsOptional()
  @IsBoolean()
  isGuestShow!: boolean;

  @IsOptional()
  @IsArray()
  areas!: number[];

  constructor(arg: {
    name: string;
    kana: string;
    address: string | null;
    tel: string | null;
    totalFee: number;
    shopFee: number;
    castFee: number;
    isIncludeOrderPrice: boolean;
    memo: string | null;
    isGuestShow: boolean;
    areas: number[];
  }) {
    arg = arg || {};
    this.name = arg.name;
    this.kana = arg.kana;
    this.address = arg.address;
    this.tel = arg.tel;
    this.totalFee = arg.totalFee;
    this.shopFee = arg.shopFee;
    this.castFee = arg.castFee;
    this.isIncludeOrderPrice = arg.isIncludeOrderPrice;
    this.memo = arg.memo;
    this.isGuestShow = arg.isGuestShow;
    this.areas = arg.areas;
  }
}
