import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import OptionRes from "types/res/option/OptionRes";
import UpdateOptionReq from "types/req/option/UpdateOptionReq";
import CreateOptionReq from "types/req/option/CreateOptionReq";
import DeleteOptionReq from "types/req/option/DeleteOptionReq";
import OptionApi from "../../api/OptionApi";

const actionCreator = actionCreatorFactory();
const addOptionAction = actionCreator.async<
  CreateOptionReq,
  OptionRes,
  { code: number; description: string }
>("ADD_OPTION");

export const addOption =
  (companyId: number, req: CreateOptionReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addOptionAction.started({ ...req }));

    try {
      const option = new CreateOptionReq({ ...req });
      const result = await OptionApi.create(companyId, option);
      dispatch(
        addOptionAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addOptionAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertOptionAction = actionCreator.async<
  CreateOptionReq[],
  OptionRes[],
  { code: number; description: string }
>("BULK_INSERT_OPTION");
export const bulkInsertOption =
  (companyId: number, req: CreateOptionReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertOptionAction.started({ ...req }));

    try {
      const result = await OptionApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertOptionAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertOptionAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkApproveOptionAction = actionCreator.async<
  { optionId: number },
  void,
  { code: number; description: string }
>("BULK_APPROVE_OPTION");

export const bulkApproveOption =
  (companyId: number, req: { optionId: number }) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkApproveOptionAction.started({ ...req }));

    try {
      return await OptionApi.bulkApprove(companyId, req.optionId);
    } catch (error) {
      dispatch(
        bulkApproveOptionAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchOptionAction = actionCreator.async<
  {},
  OptionRes[],
  { code: number; description: string }
>("FETCH_OPTION");

export const fetchOption =
  (companyId: number, shopId?: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchOptionAction.started([]));

    try {
      const result = await OptionApi.findAll(companyId, shopId);
      dispatch(
        fetchOptionAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchOptionAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateOptionAction = actionCreator.async<
  UpdateOptionReq,
  OptionRes,
  { code: number; description: string }
>("UPDATE_OPTION");

export const updateOption =
  (companyId: number, req: UpdateOptionReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateOptionAction.started({ ...req }));

    try {
      const updateOption = new UpdateOptionReq({ ...req });
      const result = await OptionApi.update(
        companyId,
        req.optionId,
        updateOption
      );
      dispatch(
        updateOptionAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateOptionAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteOptionAction = actionCreator.async<
  DeleteOptionReq,
  {},
  { code: number; description: string }
>("DELETE_OPTION");

export const deleteOption =
  (companyId: number, req: DeleteOptionReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteOptionAction.started({ ...req }));

    try {
      const option = new DeleteOptionReq({ ...req });
      const result = await OptionApi.delete(companyId, option);
      dispatch(
        deleteOptionAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteOptionAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const optionActions = {
  fetchOptionAction,
  addOptionAction,
  bulkApproveOptionAction,
  updateOptionAction,
  deleteOptionAction,
  bulkInsertOptionAction,
};

export default optionActions;
