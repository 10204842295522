import { Expose, Type } from "class-transformer";

class CastAttendanceRes {
  @Expose() commutingTimes!: number;
  @Expose() tardyCount!: number;
  @Expose() absenceCount!: number;
}
class ShopAttendanceRes {
  @Expose() commutingTimes!: number;
  @Expose() tardyCount!: number;
  @Expose() absenceCount!: number;
  @Expose() totalCount!: number;
}
class DataRes {
  @Type(() => CastAttendanceRes)
  @Expose()
  cast!: CastAttendanceRes;
  @Type(() => ShopAttendanceRes)
  @Expose()
  shop!: ShopAttendanceRes;
}

export default class CastRankingAttendanceRes {
  @Expose() date!: string;
  @Type(() => DataRes)
  @Expose()
  data!: DataRes;
}
