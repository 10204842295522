import { reducerWithInitialState } from "typescript-fsa-reducers";
import castRankingActions from "redux/actions/castRanking";
import CastRankingRes from "types/res/castRanking/CastRankingRes";
import CastRankingEachDataRes from "types/res/castRanking/CastRankingEachDataRes";
export type CastRankingState = {
  total: CastRankingRes;
  casts: CastRankingEachDataRes;
};

const initialState = {} as CastRankingState;

const castRankingReducer = reducerWithInitialState(initialState)
  .case(castRankingActions.fetchCastRankingAction.done, (state, payload) => {
    return { casts: state.casts, total: payload.result };
  })
  .case(
    castRankingActions.fetchCastRankingEachDataAction.done,
    (state, payload) => {
      return { casts: payload.result, total: state.total };
    }
  );

export default castRankingReducer;
