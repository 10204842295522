import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@material-ui/core";
import { fetchShops } from "redux/actions/shop";
import { DateTime } from "luxon";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import useModal from "hooks/useModal";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DriverApi from "api/DriverApi";
import DriverRes from "types/res/driver/DriverRes";
import SpDriverShiftModal from "spPages/DriverShift/SpDriverShiftModal";
const SpDriverShift = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const todayObject = DateTime.local().startOf("day");
  const [dateList, setDateList] = useState<Date[]>(
    [...Array(7)].map((_, i) => todayObject.plus({ day: i }).toJSDate())
  );
  const [selectDate, setSelectDate] = useState(todayObject.toJSDate());
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [drivers, setDrivers] = useState<DriverRes[]>([]);
  const [spDriverShiftModal, setSpDriverShiftModal] =
    useModal("spDriverShiftModal");

  useEffect(() => {
    dispatch(fetchShops(companyId));
  }, [companyId]);

  useEffect(() => {
    const apiCall = async () => {
      const result = await DriverApi.spFind(
        companyId,
        DateTime.fromJSDate(selectDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        15,
        offset
      );
      setDrivers(result);
    };
    apiCall();
  }, [selectDate, offset]);

  useEffect(() => {
    const apiCountCall = async () => {
      const result = await DriverApi.spCount(companyId);
      setTotalCount(result);
    };
    apiCountCall();
  }, []);

  const onClickBackDay = () => {
    const startDate = dateList[0];
    setDateList(
      [...Array(7)].map((_, i) =>
        DateTime.fromJSDate(startDate)
          .minus({ day: 7 })
          .plus({ day: i })
          .toJSDate()
      )
    );
  };

  const onClickNextDay = () => {
    const endDate = dateList[6];
    setDateList(
      [...Array(7)].map((_, i) =>
        DateTime.fromJSDate(endDate).plus({ day: i }).toJSDate()
      )
    );
  };

  const onClickSelectDay = (date: Date) => {
    setSelectDate(date);
  };

  const apiCall = async () => {
    const result = await DriverApi.spFind(
      companyId,
      DateTime.fromJSDate(selectDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      15,
      offset
    );
    setDrivers(result);
  };

  const dateString = (date: Date) => {
    const baseDate = DateTime.fromJSDate(selectDate);
    const targetDate = DateTime.fromJSDate(date);
    return `${String(
      targetDate.diff(baseDate, "days").days < 1
        ? targetDate.hour
        : targetDate.hour + 24
    ).padStart(2, "0")}:${String(targetDate.minute).padStart(2, "0")}`;
  };

  const prevDrivers = () => {
    if (offset === 0) return;
    setOffset((prev) => prev - 1);
  };

  const nextDrivers = () => {
    if ((offset + 1) * 15 > totalCount) return;
    setOffset((prev) => prev + 1);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        style={{
          position: "sticky",
          top: "56px",
          left: "0",
          backgroundColor: "white",
          zIndex: "1000",
        }}
      >
        <Box display="flex" flexDirection="column" borderBottom={1} padding={2}>
          <Typography
            style={{
              textAlign: "center",
              marginTop: "8px",
              fontSize: "18px",
            }}
          >
            週間スケジュール
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop={1}
        >
          <Box onClick={onClickBackDay}>
            <ChevronLeftIcon />
          </Box>
          {dateList.map((date, index) => (
            <Box
              display="flex"
              flexDirection="column"
              key={index}
              alignItems="center"
              onClick={() => onClickSelectDay(date)}
            >
              <Typography>
                {DateTime.fromJSDate(date).toFormat("ccc")}
              </Typography>
              <Typography>{DateTime.fromJSDate(date).toFormat("d")}</Typography>
            </Box>
          ))}
          <Box onClick={onClickNextDay}>
            <ChevronRightIcon />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography>
            {DateTime.fromJSDate(selectDate).toFormat(FORMAT_TYPE.YEAR_DAY_JP)}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        style={{ backgroundColor: "#dcdcdc" }}
        padding={1}
      >
        {drivers.map((driver) => (
          <Box
            key={driver.driverId}
            style={{
              backgroundColor: "white",
              padding: "8px",
              borderBottom: "1px solid #e1dede",
            }}
            display="flex"
            onClick={() =>
              setSpDriverShiftModal(true, {
                date: selectDate,
                driver: driver,
              })
            }
          >
            <Typography style={{ width: "50%" }}>{driver.name}</Typography>
            <Typography
              style={{
                color: !driver.driverShifts.length ? "#e1dede" : "black",
              }}
            >
              {!driver.driverShifts.length
                ? "未設定"
                : `${dateString(
                    driver.driverShifts[0].actualWorkStart ||
                      driver.driverShifts[0].planWorkStart
                  )} ~ ${dateString(
                    driver.driverShifts[0].actualWorkEnd ||
                      driver.driverShifts[0].planWorkEnd
                  )}`}
            </Typography>
          </Box>
        ))}
      </Box>
      {spDriverShiftModal.show && <SpDriverShiftModal apiCall={apiCall} />}
      <Box display="flex" justifyContent="space-between">
        <IconButton onClick={prevDrivers}>
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton onClick={nextDrivers}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
export default SpDriverShift;
