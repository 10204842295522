import RequestUtils from "utils/RequestUtils";
import SettingBookEmailRes from "types/res/settingBookEmail/SettingBookEmailRes";
import UpdateSettingBookEmailReq from "types/req/settingBookEmail/UpdateSettingBookEmailReq";
import DriverSettingBookEmailRes from "types/res/driverSettingBookEmail/DriverSettingBookEmailRes";
import UpdateDriverSettingBookEmailReq from "types/req/driverSettingBookEmail/UpdateDriverSettingBookEmailReq";

export default class DriverSettingBookEmailApi {
  private constructor() {}

  static async find(companyId: number): Promise<DriverSettingBookEmailRes[]> {
    return await RequestUtils.getArray(
      DriverSettingBookEmailRes,
      `/driverSettingBookEmail/find/${companyId}`
    );
  }

  static async update(
    companyId: number,
    data: UpdateDriverSettingBookEmailReq
  ): Promise<DriverSettingBookEmailRes> {
    return await RequestUtils.post(
      DriverSettingBookEmailRes,
      `/driverSettingBookEmail/update/${companyId}`,
      data
    );
  }
}
