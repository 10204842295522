import { reducerWithInitialState } from "typescript-fsa-reducers";
import messageBoardCheckActions from "redux/actions/messageBoardCheck";
import MessageBoardCheckRes from "types/res/messageBoardCheck/MessageBoardCheckRes";
export type MessageBoardCheckState = MessageBoardCheckRes[];

const initialState = [] as MessageBoardCheckState;

const messageBoardCheckReducer = reducerWithInitialState(initialState)
  .case(
    messageBoardCheckActions.addMessageBoardCheckAction.done,
    (state, payload) => {
      return [...state, payload.result];
    }
  )
  .case(
    messageBoardCheckActions.fetchMessageBoardCheckAction.done,
    (state, payload) => {
      return [...payload.result];
    }
  );
export default messageBoardCheckReducer;
