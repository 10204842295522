import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import CastCategoryRes from "types/res/castCategory/CastCategoryRes";
import {
  addCastCategory,
  deleteCastCategory,
  fetchCastCategory,
  updateCastCategory,
} from "redux/actions/castCategory";
import { FormType } from "components/FormField";
import CreateCastCategoryReq from "types/req/castCategory/CreateCastCategoryReq";
import UpdateCastCategoryReq from "types/req/castCategory/UpdateCastCategoryReq";

const CastCategory: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const castCategory = useSelector((state) => state.castCategory);
  useEffect(() => {
    dispatch(fetchCastCategory(companyId));
  }, [companyId, dispatch]);
  const headers: { key: keyof CastCategoryRes; label: string }[] = [
    { key: "name", label: "キャスト区分" },
  ];

  const forms = [
    {
      label: "キャスト区分",
      key: "name",
      type: FormType.Text,
    },
  ];

  return (
    <>
      <CastCategoryTable
        title={"キャスト区分"}
        formId="castCategoryForm"
        forms={forms}
        addFunc={(formData) => addCastCategory(companyId, formData)}
        updateFunc={(formData) => updateCastCategory(companyId, formData)}
        deleteFunc={(item) =>
          deleteCastCategory(companyId, {
            castCategoryId: item?.castCategoryId,
          })
        }
        rows={headers}
        data={castCategory}
        addType={CreateCastCategoryReq}
        updateType={UpdateCastCategoryReq}
        values={[(s) => s.name]}
      />
    </>
  );
};

const CastCategoryTable = styled<
  React.FC<
    CommonTableProps<
      CastCategoryRes,
      CreateCastCategoryReq,
      UpdateCastCategoryReq
    >
  >
>(CommonTable)`
  margin-top: 24px;
`;

export default CastCategory;
