import { Expose, Type } from "class-transformer";
import GuestRes from "../guest/GuestRes";
import GuestRank from "../../enum/GuestRank";
import CastNameRes from "../castName/CastNameRes";
import CastRes from "../cast/CastRes";
import OrderRes from "../order/OrderRes";

export default class GuestNoteRes {
  @Expose() readonly guestNoteId!: number;
  @Expose() readonly nickName!: string | null;
  @Expose() readonly work!: string | null;
  @Expose() readonly activityArea!: string | null;
  @Expose() readonly pet!: string | null;
  @Expose() readonly cigarette!: string | null;
  @Expose() readonly home!: string | null;
  @Expose() readonly favoriteType!: string | null;
  @Expose() readonly favoriteColor!: string | null;
  @Expose() readonly favoriteFood!: string | null;
  @Expose() readonly usingFrequency!: string | null;
  @Expose() readonly allergies!: string | null;
  @Expose() readonly personality!: string | null;
  @Expose() readonly bodyType!: string | null;
  @Expose() readonly address!: string | null;
  @Expose() readonly favoritePlay!: string | null;
  @Expose() readonly genitalSize!: string | null;
  @Expose() readonly genitalState!: string | null;
  @Expose() readonly playNum!: string | null;
  @Expose() readonly erogenousZone!: string | null;
  @Expose() readonly request!: string | null;
  @Expose() readonly remarks!: string | null;
  @Expose()
  readonly guestRank!: GuestRank | null;

  @Type(() => Date)
  @Expose()
  readonly birthday!: Date | null;

  @Expose()
  readonly guestId!: number;
  @Type(() => GuestRes)
  @Expose()
  readonly guest!: GuestRes;

  @Expose()
  readonly castId!: number;
  @Type(() => CastRes)
  @Expose()
  readonly cast!: CastRes;

  @Expose()
  readonly castNameId!: number;
  @Type(() => CastNameRes)
  @Expose()
  readonly castName!: CastNameRes;

  @Type(() => OrderRes)
  @Expose()
  readonly orders!: OrderRes[];
}
