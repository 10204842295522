import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import DriverSettingBookEmailApi from "api/DriverSettingBookEmailApi";
import DriverSettingBookEmailRes from "types/res/driverSettingBookEmail/DriverSettingBookEmailRes";
import UpdateDriverSettingBookEmailReq from "types/req/driverSettingBookEmail/UpdateDriverSettingBookEmailReq";

const actionCreator = actionCreatorFactory();

const fetchDriverSettingBookEmailAction = actionCreator.async<
  {},
  DriverSettingBookEmailRes[],
  { code: number; description: string }
>("FETCH_DRIVER_SETTING_BOOK_EMAIL");

export const fetchDriverSettingBookEmail =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchDriverSettingBookEmailAction.started([]));

    try {
      const result = await DriverSettingBookEmailApi.find(companyId);
      dispatch(
        fetchDriverSettingBookEmailAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchDriverSettingBookEmailAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateDriverSettingBookEmailAction = actionCreator.async<
  UpdateDriverSettingBookEmailReq,
  DriverSettingBookEmailRes,
  { code: number; description: string }
>("UPDATE_DRIVER_SETTING_BOOK_EMAIL");

export const updateDriverSettingBookEmail =
  (companyId: number, req: UpdateDriverSettingBookEmailReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateDriverSettingBookEmailAction.started({ ...req }));

    try {
      const updateDriverSettingBookEmail = new UpdateDriverSettingBookEmailReq({
        ...req,
      });
      const result = await DriverSettingBookEmailApi.update(
        companyId,
        updateDriverSettingBookEmail
      );
      dispatch(
        updateDriverSettingBookEmailAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateDriverSettingBookEmailAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const driverSettingBookEmailActions = {
  fetchDriverSettingBookEmailAction,
  updateDriverSettingBookEmailAction,
};

export default driverSettingBookEmailActions;
