import GuestNoteApi from "api/GuestNoteApi";
import { Action, Dispatch } from "redux";
import GuestNoteRes from "types/res/guestNote/GuestNoteRes";
import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

const fetchGuestNotesAction = actionCreator.async<
  {},
  GuestNoteRes[],
  { code: number; description: string }
>("FETCH_GUEST_NOTES");

export const fetchGuestNotes =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchGuestNotesAction.started([]));

    try {
      const result = await GuestNoteApi.findAll(companyId);
      dispatch(
        fetchGuestNotesAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchGuestNotesAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchGuestNotesByCastNameIdAction = actionCreator.async<
  {},
  GuestNoteRes[],
  { code: number; description: string }
>("FETCH_GUEST_NOTES_BY_CAST_NAME_ID");

export const fetchGuestNotesByCastNameId =
  (companyId: number, castNameId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchGuestNotesByCastNameIdAction.started([]));

    try {
      const result = await GuestNoteApi.findByCastNameId(companyId, castNameId);
      dispatch(
        fetchGuestNotesByCastNameIdAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchGuestNotesByCastNameIdAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchOneGuestNoteAction = actionCreator.async<
  {},
  GuestNoteRes,
  { code: number; description: string }
>("FETCH_GUEST_NOTE");

export const fetchOneGuestNotes =
  (companyId: number, guestNoteId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchOneGuestNoteAction.started([]));

    try {
      const result = await GuestNoteApi.findOne(companyId, guestNoteId);
      dispatch(
        fetchOneGuestNoteAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchOneGuestNoteAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const guestNoteActions = {
  fetchGuestNotesAction,
  fetchGuestNotesByCastNameIdAction,
  fetchOneGuestNoteAction,
};

export default guestNoteActions;
