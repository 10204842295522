import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BulkInsertCastShiftReq from "types/req/castShift/BulkInsertCastShiftReq";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import {
  bulkInsertCastShift,
  deleteCastShift,
  fetchCastShift,
} from "redux/actions/castShift";
import merge from "ts-deepmerge";
import { Link } from "react-router-dom";
import { fetchShops } from "redux/actions/shop";
import TimeUtils from "utils/TimeUtils";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import CastRes from "types/res/cast/CastRes";
import { Delete } from "@material-ui/icons";
import castShiftFilterActions from "redux/actions/castShiftFilter";
import { fetchCastCategory } from "redux/actions/castCategory";
import CastApi from "api/CastApi";
import CsvImport from "components/CsvImport";
import { CsvFieldType } from "utils/CsvUtils";
import CreateCastShiftReq from "types/req/castShift/CreateCastShiftReq";
type ShiftData = {
  [castId: number]: {
    [date: string]: {
      startDate: {
        date?: string;
        hour?: number;
        minute?: number;
        dateTime?: Date;
      };
      endDate: {
        date?: string;
        hour?: number;
        minute?: number;
        dateTime?: Date;
      };
      castShiftId?: number;
    };
  };
};
const minutes = ["00", "15", "30", "45"];
const CastShiftBulkInsert = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const castShifts = useSelector((state) => state.castShift);
  const castCategories = useSelector((state) => state.castCategory);
  const castShiftFilter = useSelector((state) => state.castShiftFilter);
  const shops = useSelector((state) => state.shop);
  const [dateDiff, setDateDiff] = useState<number>(7);
  const [dates, setDates] = useState<string[]>([]);
  const [requestData, setRequestData] = useState<ShiftData>({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const [casts, setCasts] = useState<CastRes[]>([]);
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .plus({ days: 6 })
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [requestStartDate, setRequestStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [requestEndDate, setRequestEndDate] = useState(
    DateTime.local()
      .plus({ days: 6 })
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [filterCasts, setFilterCasts] = useState<CastRes[]>([]);
  const [minOpeningTime, setMinOpeningTime] = useState<number>(0);
  const [maxClosingTime, setMaxClosingTime] = useState<number>(0);
  const [hours, setHours] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [openCsvImport, setOpenCsvImport] = useState(false);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
      cell: {
        position: "sticky",
      },
    })
  );
  const classes = useStyles();

  const csvHeader = [
    {
      label: "キャスト名",
      key: "castId",
      type: CsvFieldType.Object,
      relation: casts,
      relationId: "castId",
      relationKey: "name",
    },
    {
      label: "開始日時",
      key: "planWorkStart",
      type: CsvFieldType.DateTime,
      unique: true,
      sub: true,
      subRelationKey: "castId",
    },
    {
      label: "終了日時",
      key: "planWorkEnd",
      type: CsvFieldType.DateTime,
    },
  ];

  useEffect(() => {
    if (!shops.length) return;
    setMinOpeningTime(TimeUtils.minOpeningTimeInShop(shops));
    setMaxClosingTime(TimeUtils.maxClosingTimeInShop(shops));
  }, [shops]);

  useEffect(() => {
    setDateDiff(
      DateTime.fromJSDate(requestEndDate).diff(
        DateTime.fromJSDate(requestStartDate),
        "days"
      ).days + 1
    );
  }, [requestStartDate, requestEndDate]);

  useEffect(() => {
    setDates(
      [...Array(dateDiff)].map((_, i) =>
        DateTime.fromJSDate(requestStartDate)
          .plus({ days: i })
          .toFormat(FORMAT_TYPE.YEAR_DAY)
      )
    );
  }, [dateDiff, requestStartDate]);

  useEffect(() => {
    dispatch(
      castShiftFilterActions.setCastShiftFilter({
        selectShopIds: [...shops.map((shop) => String(shop.shopId)), "すべて"],
        selectCasts: castShifts.map((castShift) => castShift.cast),
        selectCastCategoryIds: castCategories
          .filter((castCategory) => castCategory.name.includes("在籍"))
          .map((castCategory) => String(castCategory.castCategoryId)),
      })
    );
  }, [shops, castShifts, castCategories]);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(
      fetchCastShift(
        companyId,
        DateTimeUtils.toFormatAsLocalTimezone(
          requestStartDate,
          FORMAT_TYPE.YEAR_DAY
        ),
        DateTimeUtils.toFormatAsLocalTimezone(
          DateTime.fromJSDate(requestEndDate).plus({ days: 1 }).toJSDate(),
          FORMAT_TYPE.YEAR_DAY
        )
      )
    );
    const apiCall = async () => {
      const result = await fetchCastCategory(companyId)(dispatch);
      if (result) {
        const castCategoryId = result.find((r) =>
          r.name.includes("在籍")
        )?.castCategoryId;
        const castRes = await CastApi.findAllWithPagination(companyId, {
          limit: 100,
          offset: 0,
          castCategoryIds: castCategoryId ? [castCategoryId] : undefined,
        });
        setCasts(castRes);
      }
    };
    apiCall();
  }, [companyId, requestStartDate, requestEndDate]);

  useEffect(() => {
    if (!error) return;
    setTimeout(() => {
      setError("");
    }, 5000);
  }, [error]);

  useEffect(() => {
    setIsLoading(true);
    setRequestData({});
    castShifts.forEach((castShift) => {
      const changeStartDate =
        minOpeningTime <= castShift.planWorkStart.getHours();
      const startDate = changeStartDate
        ? DateTime.fromJSDate(castShift.planWorkStart).toFormat(
            FORMAT_TYPE.YEAR_DAY
          )
        : DateTime.fromJSDate(castShift.planWorkStart)
            .minus({ days: 1 })
            .toFormat(FORMAT_TYPE.YEAR_DAY);
      const startHour = changeStartDate
        ? castShift.planWorkStart.getHours()
        : castShift.planWorkStart.getHours() + 24;
      const changeEndDate = TimeUtils.isInAllDays(shops)
        ? true
        : changeStartDate && maxClosingTime < castShift.planWorkEnd.getHours();
      const endDate = changeEndDate
        ? DateTime.fromJSDate(castShift.planWorkEnd).toFormat(
            FORMAT_TYPE.YEAR_DAY
          )
        : DateTime.fromJSDate(castShift.planWorkEnd)
            .minus({ days: 1 })
            .toFormat(FORMAT_TYPE.YEAR_DAY);
      const endHour = changeEndDate
        ? castShift.planWorkEnd.getHours()
        : castShift.planWorkEnd.getHours() + 24;
      {
        const data = {
          [castShift.castId]: {
            [startDate]: {
              startDate: {
                date: startDate,
                hour: startHour,
                minute: String(castShift.planWorkStart.getMinutes()).padStart(
                  2,
                  "0"
                ),
                dateTime: DateTime.fromFormat(startDate, FORMAT_TYPE.YEAR_DAY)
                  .plus({
                    hours: startHour,
                    minutes: castShift.planWorkStart.getMinutes(),
                  })
                  .toJSDate(),
              },
              endDate: {
                date: endDate,
                hour: endHour,
                minute: String(castShift.planWorkEnd.getMinutes()).padStart(
                  2,
                  "0"
                ),
                dateTime: DateTime.fromFormat(endDate, FORMAT_TYPE.YEAR_DAY)
                  .plus({
                    hours: endHour,
                    minutes: castShift.planWorkEnd.getMinutes(),
                  })
                  .toJSDate(),
              },
              castShiftId: castShift.castShiftId,
            },
          },
        };
        setRequestData((prev: any) => ({ ...merge(prev, data) }));
      }
    });
    setIsLoading(false);
  }, [castShifts]);

  useEffect(() => {
    if (!maxClosingTime && !minOpeningTime) return;
    const timeDiff = TimeUtils.isInAllDays(shops)
      ? maxClosingTime - minOpeningTime
      : maxClosingTime - minOpeningTime + 24;

    const arrayHours = [...Array(timeDiff).keys()].map((a) =>
      String(a + minOpeningTime).padStart(2, "0")
    );
    setHours(arrayHours);
  }, [minOpeningTime, maxClosingTime]);

  const onChangeDate = (
    date: string,
    castId: number,
    key: "startDate" | "endDate",
    hour?: string,
    minute?: string
  ) => {
    const numberHour = Number(
      hour || requestData?.[castId]?.[date]?.[key]?.hour
    );
    const dateHour =
      numberHour > 23
        ? String(numberHour - 24).padStart(2, "0")
        : String(numberHour).padStart(2, "0");
    let dateString;
    if (numberHour > 23) {
      dateString = DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY)
        .plus({
          days: 1,
        })
        .toFormat(FORMAT_TYPE.YEAR_DAY);
    } else {
      dateString = DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toFormat(
        FORMAT_TYPE.YEAR_DAY
      );
    }
    const dateTime = DateTime.fromFormat(
      `${dateString} ${
        dateHour || requestData?.[castId]?.[date]?.[key]?.hour || "00"
      }:${minute || requestData?.[castId]?.[date]?.[key]?.minute || "00"}`,
      FORMAT_TYPE.YEAR_DATE_TIME
    ).toJSDate();
    const data = {
      [castId]: {
        [date]: {
          [key]: {
            date: requestData?.[castId]?.[date]?.[key]?.date || dateString,
            hour:
              hour === undefined
                ? requestData?.[castId]?.[date]?.[key]?.hour
                : hour,
            minute:
              minute === undefined
                ? requestData?.[castId]?.[date]?.[key]?.minute
                : minute,
            dateTime,
          },
        },
      },
    };
    setRequestData((prev: any) => ({ ...merge(prev, data) }));
  };

  const createShift = async () => {
    setIsRequest(true);
    const data = Object.entries(requestData)
      .flatMap(([castId, dateObject]) => {
        return Object.entries(dateObject)
          .filter(
            ([_, value]) =>
              value?.startDate?.hour &&
              value?.startDate?.minute &&
              value?.endDate?.hour &&
              value?.endDate?.minute
          )
          .flatMap(([dateString, value]) => {
            if (!value?.startDate?.dateTime || !value?.endDate?.dateTime) {
              const cast = filterCasts.find(
                (cast) => cast.castId === Number(castId)
              );
              setError(
                `${cast?.displayName}の${dateString}に入力不備があります。`
              );
            } else {
              return new BulkInsertCastShiftReq({
                castId: Number(castId),
                planWorkStart: value.startDate.dateTime,
                planWorkEnd: value.endDate.dateTime,
                castShiftId: value.castShiftId,
              });
            }
          });
      })
      .filter((v): v is BulkInsertCastShiftReq => typeof v !== "undefined");
    if (!error && data.length) {
      const result = await bulkInsertCastShift(companyId, data)(dispatch);
      if (result instanceof Array) {
        setSuccess(true);
      }
    }
    setIsRequest(false);
  };

  const selectShopIds = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (
      castShiftFilter.selectShopIds.includes("すべて") &&
      (event.target.value as string[]).indexOf("すべて") === -1
    ) {
      return [];
    } else if (
      !castShiftFilter.selectShopIds.includes("すべて") &&
      (event.target.value as string[]).indexOf("すべて") !== -1
    ) {
      return [...shops.map((shop) => String(shop.shopId)), "すべて"];
    } else if (
      (event.target.value as string[]).length === shops.length &&
      (event.target.value as string[]).indexOf("すべて") === -1
    ) {
      return [...shops.map((shop) => String(shop.shopId)), "すべて"];
    } else if ((event.target.value as string[]).length <= shops.length) {
      return (event.target.value as string[]).filter(
        (name) => name !== "すべて"
      );
    } else {
      return event.target.value as string[];
    }
  };

  const selectCastCategoryIds = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (
      castShiftFilter.selectCastCategoryIds.includes("すべて") &&
      (event.target.value as string[]).indexOf("すべて") === -1
    ) {
      return [];
    } else if (
      !castShiftFilter.selectCastCategoryIds.includes("すべて") &&
      (event.target.value as string[]).indexOf("すべて") !== -1
    ) {
      return [
        ...castCategories.map((castCategory) =>
          String(castCategory.castCategoryId)
        ),
        "すべて",
      ];
    } else if (
      (event.target.value as string[]).length === castCategories.length &&
      (event.target.value as string[]).indexOf("すべて") === -1
    ) {
      return [
        ...castCategories.map((castCategory) =>
          String(castCategory.castCategoryId)
        ),
        "すべて",
      ];
    } else if (
      (event.target.value as string[]).length <= castCategories.length
    ) {
      return (event.target.value as string[]).filter(
        (name) => name !== "すべて"
      );
    } else {
      return event.target.value as string[];
    }
  };

  const onClickDisplayCastShift = () => {
    let result: CastRes[] = [];
    castShifts.forEach((castShift) => {
      if (!result.find((r) => r.castId === castShift.castId)) {
        result = [...result, castShift.cast];
      }
    });
    setFilterCasts((prev) =>
      Array.from(
        new Map(
          [...prev, ...result].map((cast) => [cast.castId, cast])
        ).values()
      )
    );
    setCasts((prev) =>
      Array.from(
        new Map(
          [...prev, ...result].map((cast) => [cast.castId, cast])
        ).values()
      )
    );
  };

  if (isLoading) return <CircularProgress />;
  return (
    <>
      <Box display="flex" marginTop={2}>
        <div>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            component={Link}
            to="/castShift"
          >
            一覧画面へ
          </Button>
        </div>
        <TextField
          type="date"
          label="開始日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) => {
            setStartDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .toJSDate()
            );
            setEndDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .plus({ days: 6 })
                .toJSDate()
            );
          }}
          onBlur={(event) => {
            setRequestStartDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .toJSDate()
            );
            setRequestEndDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .plus({ days: 6 })
                .toJSDate()
            );
          }}
        />
        <TextField
          type="date"
          label="終了日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) => {
            setStartDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .minus({ days: 6 })
                .toJSDate()
            );
            setEndDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .toJSDate()
            );
          }}
          onBlur={(event) => {
            setRequestStartDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .minus({ days: 6 })
                .toJSDate()
            );
            setRequestEndDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .toJSDate()
            );
          }}
        />
        <Box marginTop={"10px"}>
          <InputLabel id="select-multiple-shop">店舗</InputLabel>
          <Select
            multiple
            value={castShiftFilter.selectShopIds}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              dispatch(
                castShiftFilterActions.setCastShiftFilter({
                  selectShopIds: selectShopIds(event),
                })
              );
            }}
            input={<Input id="select-multiple-shop" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return shops
                  .filter((shop) =>
                    (selected as string[]).includes(String(shop.shopId))
                  )
                  .map((shop) => shop.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={castShiftFilter.selectShopIds.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {shops.map((shop) => (
              <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                <Checkbox
                  checked={
                    castShiftFilter.selectShopIds.indexOf(
                      String(shop.shopId)
                    ) !== -1
                  }
                />
                <ListItemText primary={shop.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <Autocomplete
            multiple
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField {...params} label="キャスト名" />
            )}
            getOptionLabel={(cast) => cast.displayName}
            options={casts}
            filterOptions={(options, state) =>
              options.filter((option) =>
                option.displayName.includes(state.inputValue)
              )
            }
            onInputChange={async (event, value) => {
              event.persist();
              const c = await CastApi.findAllWithPagination(companyId, {
                limit: 100,
                offset: 0,
                displayName: value,
                castCategoryIds:
                  castShiftFilter.selectCastCategoryIds.includes("すべて") ||
                  castShiftFilter.selectCastCategoryIds.length === 0
                    ? undefined
                    : castShiftFilter.selectCastCategoryIds.map(Number),
              });
              setCasts((prev) =>
                Array.from(
                  new Map(
                    [...prev, ...c].map((cast) => [cast.castId, cast])
                  ).values()
                )
              );
            }}
            style={{
              width: "400px",
              marginRight: "10px",
            }}
            value={filterCasts}
            limitTags={2}
            getLimitTagsText={(more) => {
              return <Typography>+{more}</Typography>;
            }}
            ListboxProps={{
              onScroll: async (event: React.SyntheticEvent) => {
                const listBoxNode = event.currentTarget;
                if (
                  listBoxNode.scrollTop + listBoxNode.clientHeight >
                  listBoxNode.scrollHeight - 1
                ) {
                  const offset = Math.trunc(casts.length / 100);
                  const c = await CastApi.findAllWithPagination(companyId, {
                    limit: 100,
                    offset,
                    castCategoryIds:
                      castShiftFilter.selectCastCategoryIds.includes(
                        "すべて"
                      ) || castShiftFilter.selectCastCategoryIds.length === 0
                        ? undefined
                        : castShiftFilter.selectCastCategoryIds.map(Number),
                  });
                  setCasts((prev) =>
                    Array.from(
                      new Map(
                        [...prev, ...c].map((cast) => [cast.castId, cast])
                      ).values()
                    )
                  );
                }
              },
            }}
            onChange={(_, value) => {
              setFilterCasts(value);
            }}
          />
        </Box>
        <Box marginTop={"10px"}>
          <InputLabel id="select-multiple-class">キャスト区分</InputLabel>
          <Select
            multiple
            value={castShiftFilter.selectCastCategoryIds}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              dispatch(
                castShiftFilterActions.setCastShiftFilter({
                  selectCastCategoryIds: selectCastCategoryIds(event),
                })
              );
            }}
            input={<Input id="select-multiple-castCategory" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return castCategories
                  .filter((castCategory) =>
                    (selected as string[]).includes(
                      String(castCategory.castCategoryId)
                    )
                  )
                  .map((castCategory) => castCategory.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={
                  castShiftFilter.selectCastCategoryIds.indexOf("すべて") > -1
                }
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {castCategories.map((castCategory) => (
              <MenuItem
                key={castCategory.castCategoryId}
                value={String(castCategory.castCategoryId)}
              >
                <Checkbox
                  checked={
                    castShiftFilter.selectCastCategoryIds.indexOf(
                      String(castCategory.castCategoryId)
                    ) !== -1
                  }
                />
                <ListItemText primary={castCategory.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box marginTop={"10px"}>
          <Button
            onClick={onClickDisplayCastShift}
            variant="contained"
            className={classes.button}
            color="secondary"
          >
            該当日の出勤キャストを表示
          </Button>
        </Box>
        <Box marginTop={"10px"}>
          <Button
            onClick={() => setOpenCsvImport((prev) => !prev)}
            variant="contained"
            className={classes.button}
            color="primary"
          >
            CSVインポート
          </Button>
        </Box>
      </Box>
      {openCsvImport && (
        <CsvImport
          templatePath={"/csv/cast_shift.csv"}
          templateName={"cast_shift.csv"}
          csvHeader={csvHeader}
          addType={BulkInsertCastShiftReq}
          addFunc={(formData) => bulkInsertCastShift(companyId, formData)}
        />
      )}
      {error && (
        <Alert severity="error">
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      <div
        style={{
          overflow: "scroll",
          width: `100vw`,
          height: `50vh`,
        }}
      >
        <Table className="sticky_table">
          <TableHead>
            <TableRow>
              <TableCell />
              {dates.map((date) => (
                <TableCell key={date} className="sticky">
                  {date}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterCasts.map((cast) => (
              <TableRow key={cast.castId}>
                <TableCell>
                  {cast.displayName}(
                  {cast.shops.map((shop) => shop.name).join("/")})
                </TableCell>
                {dates.map((date) => (
                  <TableCell key={date}>
                    <Box display="flex">
                      <Box display="flex" alignItems="center">
                        <Select
                          native
                          style={{ minWidth: "50px" }}
                          value={
                            requestData[cast.castId]?.[date]?.startDate?.hour
                              ? String(
                                  requestData[cast.castId]?.[date]?.startDate
                                    ?.hour
                                ).padStart(2, "0")
                              : ""
                          }
                          onChange={(event) => {
                            event.persist();
                            onChangeDate(
                              date,
                              cast.castId,
                              "startDate",
                              String(event.target.value)
                            );
                          }}
                        >
                          <option value={""} />
                          {hours.map((hour: string) => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Select>
                      </Box>
                      :
                      <Box display="flex" alignItems="center">
                        <Select
                          native
                          style={{ minWidth: "50px" }}
                          value={
                            requestData[cast.castId]?.[date]?.startDate?.minute
                              ? String(
                                  requestData[cast.castId]?.[date]?.startDate
                                    ?.minute
                                ).padStart(2, "0")
                              : ""
                          }
                          onChange={(event) => {
                            onChangeDate(
                              date,
                              cast.castId,
                              "startDate",
                              undefined,
                              String(event.target.value)
                            );
                          }}
                        >
                          <option value={""} />
                          {minutes.map((minute) => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </Select>
                      </Box>
                      ~
                      <Box display="flex" alignItems="center">
                        <Select
                          native
                          style={{ minWidth: "50px" }}
                          value={
                            requestData[cast.castId]?.[date]?.endDate?.hour
                              ? String(
                                  requestData[cast.castId]?.[date]?.endDate
                                    ?.hour
                                ).padStart(2, "0")
                              : ""
                          }
                          onChange={(event) =>
                            onChangeDate(
                              date,
                              cast.castId,
                              "endDate",
                              String(event.target.value)
                            )
                          }
                        >
                          <option value="" />
                          {hours.map((hour: string) => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Select>
                      </Box>
                      :
                      <Box display="flex" alignItems="center">
                        <Select
                          native
                          style={{ minWidth: "50px" }}
                          value={
                            requestData[cast.castId]?.[date]?.endDate?.minute
                              ? String(
                                  requestData[cast.castId]?.[date]?.endDate
                                    ?.minute
                                ).padStart(2, "0")
                              : ""
                          }
                          onChange={(event) =>
                            onChangeDate(
                              date,
                              cast.castId,
                              "endDate",
                              undefined,
                              String(event.target.value)
                            )
                          }
                        >
                          <option value="" />
                          {minutes.map((minute) => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </Select>
                      </Box>
                      {requestData[cast.castId]?.[date]?.castShiftId && (
                        <Delete
                          onClick={() => {
                            if (window.confirm("本当に削除しますか？")) {
                              dispatch(
                                deleteCastShift(companyId, {
                                  castShiftId:
                                    requestData[cast.castId][date]
                                      .castShiftId || 0,
                                })
                              );
                            }
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {success && (
        <Alert>
          <AlertTitle>保存しました</AlertTitle>
        </Alert>
      )}
      <Box display="flex" justifyContent="center" marginTop={2}>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={createShift}
          disabled={isRequest}
        >
          一括登録
        </Button>
      </Box>
    </>
  );
};

export default CastShiftBulkInsert;
