import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import CastNameApi from "api/CastNameApi";
import CastNameRes from "types/res/castName/CastNameRes";
import UpdateCastNameReq from "types/req/castName/UpdateCastNameReq";
import CreateCastNameReq from "types/req/castName/CreateCastNameReq";
import DeleteCastNameReq from "types/req/castName/DeleteCastNameReq";
import FindCastNameReq from "types/req/castName/FindCastNameReq";

const actionCreator = actionCreatorFactory();
const addCastNameAction = actionCreator.async<
  CreateCastNameReq,
  CastNameRes,
  { code: number; description: string }
>("ADD_CAST_NAME");

export const addCastName =
  (companyId: number, req: CreateCastNameReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addCastNameAction.started({ ...req }));

    try {
      const castName = new CreateCastNameReq({ ...req });
      const result = await CastNameApi.create(companyId, castName);
      dispatch(
        addCastNameAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addCastNameAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertCastNameAction = actionCreator.async<
  CreateCastNameReq[],
  CastNameRes[],
  { code: number; description: string }
>("BULK_INSERT_CAST_NAME");
export const bulkInsertCastName =
  (companyId: number, req: CreateCastNameReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertCastNameAction.started({ ...req }));

    try {
      const result = await CastNameApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertCastNameAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertCastNameAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchOneCastNameAction = actionCreator.async<
  {},
  CastNameRes,
  { code: number; description: string }
>("FETCH_ONE_CAST_NAME");

export const fetchOneCastName =
  (companyId: number, castNameId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchOneCastNameAction.started([]));

    try {
      const result = await CastNameApi.findOne(companyId, castNameId);
      dispatch(
        fetchOneCastNameAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchOneCastNameAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCastNameAction = actionCreator.async<
  {},
  CastNameRes[],
  { code: number; description: string }
>("FETCH_CAST_NAME");

export const fetchCastName =
  (companyId: number, shopId?: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCastNameAction.started([]));

    try {
      const result = await CastNameApi.findAll(companyId, shopId);
      dispatch(
        fetchCastNameAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCastNameAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCastNameWithPaginationAction = actionCreator.async<
  {},
  CastNameRes[],
  { code: number; description: string }
>("FETCH_CAST_NAME_WITH_PAGINATION");

export const fetchCastNameWithPagination =
  (companyId: number, req: FindCastNameReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCastNameWithPaginationAction.started([]));

    try {
      const result = await CastNameApi.findAllWithPagination(companyId, req);
      dispatch(
        fetchCastNameWithPaginationAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCastNameWithPaginationAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateCastNameAction = actionCreator.async<
  UpdateCastNameReq,
  CastNameRes,
  { code: number; description: string }
>("UPDATE_CAST_NAME");

export const updateCastName =
  (companyId: number, req: UpdateCastNameReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateCastNameAction.started({ ...req }));

    try {
      const updateCastName = new UpdateCastNameReq({ ...req });
      const result = await CastNameApi.update(
        companyId,
        req.castNameId,
        updateCastName
      );
      dispatch(
        updateCastNameAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateCastNameAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteCastNameAction = actionCreator.async<
  DeleteCastNameReq,
  {},
  { code: number; description: string }
>("DELETE_CAST_NAME");

export const deleteCastName =
  (companyId: number, req: DeleteCastNameReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteCastNameAction.started({ ...req }));

    try {
      const castName = new DeleteCastNameReq({ ...req });
      const result = await CastNameApi.delete(companyId, castName);
      dispatch(
        deleteCastNameAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteCastNameAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const castNameActions = {
  fetchOneCastNameAction,
  fetchCastNameAction,
  fetchCastNameWithPaginationAction,
  addCastNameAction,
  updateCastNameAction,
  deleteCastNameAction,
  bulkInsertCastNameAction,
};

export default castNameActions;
