import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import OrderStatus from "types/enum/OrderStatus";
import { addOrder, fetchOneOrder, updateOrder } from "redux/actions/order";
import SearchGuest from "./components/SearchGuest";
import OrderCast from "./components/OrderCast";
import BookEmailBody from "./components/BookEmailBody";
import OrderInfo from "./components/OrderInfo";
import OrderPrice from "./components/OrderPrice";
import { validate } from "class-validator";
import CreateOrderReq from "types/req/order/CreateOrderReq";
import { useHistory, useLocation, useParams } from "react-router-dom";
import UpdateOrderReq from "../../types/req/order/UpdateOrderReq";
import DateTimeUtils, { FORMAT_TYPE } from "../../utils/DateTimeUtils";
import { DateTime } from "luxon";
import { fetchShops } from "redux/actions/shop";
import { fetchHotel } from "redux/actions/hotel";
import { fetchCourse } from "redux/actions/course";
import { fetchArea } from "redux/actions/area";
import { fetchDriver } from "redux/actions/driver";
import { fetchDiscount } from "redux/actions/discount";
import { fetchAdditionalTime } from "redux/actions/additionalTime";
import { fetchOption } from "redux/actions/option";
import { fetchCosplay } from "redux/actions/cosplay";
import { fetchNomination } from "redux/actions/nomination";
import { fetchNotelClass } from "redux/actions/notelClass";
import { fetchOneCastName } from "redux/actions/castName";
import OrderRes from "../../types/res/order/OrderRes";
import EnumUtils from "../../utils/EnumUtils";
import OrderApi from "../../api/OrderApi";
import { fetchMedium } from "redux/actions/medium";
import GuestApi from "api/GuestApi";

const Order: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const order = useSelector((state) => state.order);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [formData, setFormData] = useState<any>({});
  const [oldOrder, setOldOrder] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [duplicateOrder, setDuplicateOrder] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState(false);
  const [holdOrder, setHoldOrder] = useState<OrderRes>({} as OrderRes);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    DateTimeUtils.toFormatAsLocalTimezone(
      DateTime.local()
        .minus({
          hours: changeDate.hour,
          minutes: changeDate.minute,
        })
        .toJSDate(),
      FORMAT_TYPE.YEAR_DAY
    )
  );
  const [endDate, setEndDate] = useState(
    DateTimeUtils.toFormatAsLocalTimezone(
      DateTime.local()
        .minus({
          hours: changeDate.hour,
          minutes: changeDate.minute,
        })
        .toJSDate(),
      FORMAT_TYPE.YEAR_DAY
    )
  );
  const { orderId } = useParams<{ orderId: string }>();
  const queryString = new URLSearchParams(useLocation().search);
  const tel = queryString.get("tel");
  const guestId = queryString.get("guestId");
  const shopId = queryString.get("shopId");
  useEffect(() => {
    if (guestId) {
      const apiCall = async () => {
        const result = await GuestApi.findOne(companyId, Number(guestId));
        setFormData((prev: any) => ({
          ...prev,
          guest: result,
          guestId: Number(guestId),
          shopId: shopId ? Number(shopId) : undefined,
        }));
      };
      apiCall();
    } else {
      setFormData((prev: any) => ({
        ...prev,
        guest: { tel },
        shopId: shopId ? Number(shopId) : null,
      }));
    }
  }, [tel, guestId, shopId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOneOrder(companyId, Number(orderId)));
    }
  }, [dispatch, companyId, orderId]);
  useEffect(() => {
    setFormData((prev: any) => ({
      ...prev,
      orderDate: DateTime.local()
        .minus({
          hours: changeDate.hour,
          minutes: changeDate.minute,
        })
        .toJSDate(),
      isUseOutward: true,
      isUseReturn: true,
      additionalTimeOrders: [{ additionalTimeId: null, count: 0 }],
    }));
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      setIsLoading(true);
      await dispatch(fetchShops(companyId));
      await dispatch(fetchHotel(companyId));
      await dispatch(fetchCourse(companyId));
      await dispatch(fetchArea(companyId));
      await dispatch(fetchDiscount(companyId));
      await dispatch(fetchAdditionalTime(companyId));
      await dispatch(fetchOption(companyId));
      await dispatch(fetchCosplay(companyId));
      await dispatch(fetchNomination(companyId));
      await dispatch(fetchNotelClass(companyId));
      await dispatch(fetchDriver(companyId));
      await dispatch(fetchMedium(companyId));
      setIsLoading(false);
    };
    apiCall();
  }, [companyId, dispatch]);

  useEffect(() => {
    if (!formData?.castNameId) return;
    dispatch(fetchOneCastName(companyId, formData?.castNameId));
  }, [formData?.castNameId]);

  useEffect(() => {
    if (order[0]?.orderId === Number(orderId)) {
      let departureHour = "";
      if (order[0].departureTime && order[0].shop) {
        if (
          DateTime.fromJSDate(order[0]?.departureTime).toJSDate().getHours() <
          Number(order[0].shop.openingTime.split(":")[0])
        ) {
          departureHour = String(
            DateTime.fromJSDate(order[0]?.departureTime).toJSDate().getHours() +
              24
          ).padStart(2, "0");
        } else {
          departureHour = String(
            DateTime.fromJSDate(order[0].departureTime).toJSDate().getHours()
          ).padStart(2, "0");
        }
      }

      let planInHour = "";
      if (order[0].planInTime && order[0].shop) {
        if (
          DateTime.fromJSDate(order[0]?.planInTime).toJSDate().getHours() <
          Number(order[0].shop.openingTime.split(":")[0])
        ) {
          planInHour = String(
            DateTime.fromJSDate(order[0]?.planInTime).toJSDate().getHours() + 24
          ).padStart(2, "0");
        } else {
          planInHour = String(
            DateTime.fromJSDate(order[0].planInTime).toJSDate().getHours()
          ).padStart(2, "0");
        }
      }

      let actualInHour = "";
      if (order[0].actualInTime && order[0].shop) {
        if (
          DateTime.fromJSDate(order[0]?.actualInTime).toJSDate().getHours() <
          Number(order[0].shop.openingTime.split(":")[0])
        ) {
          actualInHour = String(
            DateTime.fromJSDate(order[0]?.actualInTime).toJSDate().getHours() +
              24
          ).padStart(2, "0");
        } else {
          actualInHour = String(
            DateTime.fromJSDate(order[0].actualInTime).toJSDate().getHours()
          ).padStart(2, "0");
        }
      }

      let requestActualInHour = "";
      if (order[0].requestActualInTime && order[0].shop) {
        if (
          DateTime.fromJSDate(order[0]?.requestActualInTime)
            .toJSDate()
            .getHours() < Number(order[0].shop.openingTime.split(":")[0])
        ) {
          requestActualInHour = String(
            DateTime.fromJSDate(order[0]?.requestActualInTime)
              .toJSDate()
              .getHours() + 24
          ).padStart(2, "0");
        } else {
          requestActualInHour = String(
            DateTime.fromJSDate(order[0].requestActualInTime)
              .toJSDate()
              .getHours()
          ).padStart(2, "0");
        }
      }

      setFormData({
        ...formData,
        ...order[0],
        options: order[0].options?.map((option) => option.optionId),
        discounts: order[0].discounts?.map((discount) => discount.discountId),
        additionalTimeOrders: order[0].additionalTimeOrders?.length
          ? order[0].additionalTimeOrders?.map((ao) => ({
              additionalTimeId: ao.additionalTimeId,
              count: ao.count,
            }))
          : [{ additionalTimeId: null, count: 0 }],
        departureHour,
        departureMinute: order[0].departureTime
          ? String(
              DateTime.fromJSDate(order[0].departureTime)
                .toJSDate()
                .getMinutes()
            ).padStart(2, "0")
          : "",
        planInHour,
        planInMinute: order[0].planInTime
          ? String(
              DateTime.fromJSDate(order[0].planInTime).toJSDate().getMinutes()
            ).padStart(2, "0")
          : "",
        actualInHour,
        actualInMinute: order[0].actualInTime
          ? String(
              DateTime.fromJSDate(order[0].actualInTime).toJSDate().getMinutes()
            ).padStart(2, "0")
          : "",
        requestActualInHour,
        requestActualInMinute: order[0].requestActualInTime
          ? String(
              DateTime.fromJSDate(order[0].requestActualInTime)
                .toJSDate()
                .getMinutes()
            ).padStart(2, "0")
          : "",
      });
      setOldOrder({ ...order[0] });
    }
  }, [order]);

  useEffect(() => {
    if (!formData?.orderDate) return;
    setStartDate(
      DateTimeUtils.toFormatAsLocalTimezone(
        formData?.orderDate,
        FORMAT_TYPE.YEAR_DAY
      )
    );
    setEndDate(
      DateTimeUtils.toFormatAsLocalTimezone(
        DateTime.fromJSDate(formData?.orderDate).toJSDate(),
        FORMAT_TYPE.YEAR_DAY
      )
    );
  }, [formData?.orderDate]);

  useEffect(() => {
    if (duplicateMessage) {
      setDuplicateMessage(false);
      setDuplicateOrder(false);
    }
    if (
      !(
        formData?.status &&
        formData?.castNameId &&
        formData?.planInTime &&
        formData?.planOutTime
      )
    )
      return;
    if (
      EnumUtils.mapToEnum(OrderStatus, formData.status) !== OrderStatus.booking
    )
      return;
    setDuplicateOrder(true);
    const checkOrder = async () => {
      const result = await OrderApi.findHoldOrder(
        companyId,
        formData.castNameId,
        DateTime.fromJSDate(formData.planInTime).toFormat(
          FORMAT_TYPE.YEAR_DATE_TIME
        ),
        DateTime.fromJSDate(formData.planOutTime).toFormat(
          FORMAT_TYPE.YEAR_DATE_TIME
        )
      );
      if (result?.orderId && result?.orderId !== formData?.orderId) {
        setHoldOrder(result);
      } else {
        setDuplicateOrder(false);
      }
    };
    checkOrder();
  }, [formData?.status, formData?.castNameId, formData?.planInTime]);

  const onChangeForm =
    (key: string, handler: (value: any) => any = (value: any) => value) =>
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | { value: unknown }
      >
    ) => {
      event.persist();
      setFormData((prev: any) => ({
        ...prev,
        [key]:
          key === "orderDate"
            ? DateTime.fromJSDate(new Date(event.target.value as string))
                .startOf("day")
                .plus({ hours: 9 })
                .toJSDate()
            : event.target.value === ""
            ? null
            : handler(event.target.value),
      }));
    };
  const onChangeCheckBox =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setFormData((prev: any) => ({
        ...prev,
        [key]: event.target.checked,
      }));
    };
  const onChangeValue = (key: string) => (value: any) => {
    setFormData((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const addAdditionalTimeOrder = () => {
    setFormData((prev: any) => ({
      ...prev,
      additionalTimeOrders: [
        ...(prev.additionalTimeOrders || []),
        {
          additionalTimeId: null,
          count: 0,
        },
      ],
    }));
  };
  const onChangeAdditionalTimeOrder = (
    index: number,
    key: string,
    value: any
  ) => {
    setFormData((prev: any) => ({
      ...prev,
      additionalTimeOrders: prev.additionalTimeOrders?.map(
        (order: any, i: number) => {
          if (i === index) {
            return {
              ...order,
              [key]: Number(value),
            };
          }
          return order;
        }
      ),
    }));
  };
  const deleteAdditionalTimeOrder = (index: number) => {
    setFormData((prev: any) => ({
      ...prev,
      additionalTimeOrders: prev.additionalTimeOrders.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  const onClickCreateOrder = async () => {
    if (
      EnumUtils.mapToEnum(OrderStatus, formData.status) === OrderStatus.paid
    ) {
      const result = window.confirm(
        "店舗マスター未満のアカウントでは完了にすると変更が出来ませんがよろしいでしょうか？"
      );
      if (!result) {
        return false;
      }
    }
    setErrorMessage("");
    if (formData?.orderId) {
      const data = new UpdateOrderReq({ ...formData });
      const guestRequestChangeProperty =
        oldOrder?.status &&
        EnumUtils.mapToEnum(OrderStatus, oldOrder.status) ===
          OrderStatus.guestRequest
          ? Object.entries(oldOrder)
              .map(([key, value]) => {
                return formData?.[key] === value ? null : key;
              })
              .filter(Boolean)
              .join(",")
          : null;
      const errors = await validate(data);
      const errorMessage = errors
        .map((error) => {
          if (!error.constraints) return "";
          return Object.values(error.constraints);
        })
        .join("\n");
      if (errorMessage) {
        setErrorMessage(errorMessage);
        return;
      }
      const result = await dispatch(
        updateOrder(companyId, {
          ...data,
          orderId: formData.orderId,
          payoff: false,
          guestRequestChangeProperty,
        })
      );
      if (result instanceof OrderRes) {
        setIsSuccess(true);
        history.push("/orderList");
      }
    } else {
      const data = new CreateOrderReq({
        ...formData,
        status: formData?.status || "hold",
        paymentType: formData?.paymentType || "cash",
        orderDate:
          formData?.orderDate ||
          DateTime.local()
            .minus({
              hours: changeDate.hour,
              minutes: changeDate.minute,
            })
            .toJSDate(),
      });
      const errors = await validate(data);
      const errorMessage = errors
        .map((error) => {
          if (!error.constraints) return "";
          return Object.values(error.constraints);
        })
        .join("\n");
      if (errorMessage) {
        setErrorMessage(errorMessage);
        return;
      }
      const result = await dispatch(
        addOrder(companyId, { ...data, payoff: false })
      );
      if (result instanceof OrderRes) {
        setIsSuccess(true);
        history.push("/orderList");
      }
    }
  };

  if (isLoading) return <CircularProgress />;
  return (
    <div style={{ width: "100%", margin: 10, padding: 20 }}>
      <Box display="flex" flexDirection="row">
        <Box display="flex" justifyContent="space-between" flexGrow={1}>
          <Box display={"flex"} flexBasis={"40%"}>
            <SearchGuest
              onChangeForm={(key) => onChangeForm(key)}
              onChange={(key) => onChangeValue(key)}
              formData={formData}
            />
          </Box>
          <Box display="flex" flexBasis={"20%"}>
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <OrderCast
                onChangeCheckBox={(key) => onChangeCheckBox(key)}
                formData={formData}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flexBasis={"30%"}>
            <BookEmailBody
              formData={formData}
              onChange={(key) => onChangeValue(key)}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexGrow={1}
        style={{ marginTop: "120px" }}
      >
        <Box display="flex" flexBasis={"40%"}>
          <OrderInfo
            formData={formData}
            startDate={startDate}
            endDate={endDate}
            oldOrder={oldOrder}
            onChange={(key) => onChangeForm(key)}
            onChangePrice={(key) => onChangeValue(key)}
            onChangeCheckBox={(key) => onChangeCheckBox(key)}
            onChangeAdditionalTimeOrder={(index, key, value) =>
              onChangeAdditionalTimeOrder(index, key, value)
            }
            addAdditionalTimeOrder={addAdditionalTimeOrder}
            deleteAdditionalTimeOrder={deleteAdditionalTimeOrder}
          />
        </Box>
        <OrderPrice
          formData={formData}
          onChangeForm={(key) => onChangeForm(key)}
          onChange={(key) => onChangeValue(key)}
          onChangeCheckBox={(key) => onChangeCheckBox(key)}
          onClickCreateOrder={onClickCreateOrder}
          orderId={orderId}
          oldOrder={oldOrder}
          duplicateOrder={duplicateOrder}
          holdOrder={holdOrder}
          setHoldOrder={setHoldOrder}
          setDuplicateMessage={setDuplicateMessage}
          errorMessage={errorMessage}
          isSuccess={isSuccess}
          duplicateMessage={duplicateMessage}
          setDuplicateOrder={setDuplicateMessage}
        />
      </Box>
    </div>
  );
};

export default Order;
