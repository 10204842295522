import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import DiscountRes from "types/res/discount/DiscountRes";
import UpdateDiscountReq from "types/req/discount/UpdateDiscountReq";
import CreateDiscountReq from "types/req/discount/CreateDiscountReq";
import DeleteDiscountReq from "types/req/discount/DeleteDiscountReq";
import DiscountApi from "../../api/DiscountApi";

const actionCreator = actionCreatorFactory();
const addDiscountAction = actionCreator.async<
  CreateDiscountReq,
  DiscountRes,
  { code: number; description: string }
>("ADD_DISCOUNT");

export const addDiscount =
  (companyId: number, req: CreateDiscountReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addDiscountAction.started({ ...req }));

    try {
      const discount = new CreateDiscountReq({ ...req });
      const result = await DiscountApi.create(companyId, discount);
      dispatch(
        addDiscountAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addDiscountAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertDiscountAction = actionCreator.async<
  CreateDiscountReq[],
  DiscountRes[],
  { code: number; description: string }
>("BULK_INSERT_DISCOUNT");

export const bulkInsertDiscount =
  (companyId: number, req: CreateDiscountReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertDiscountAction.started({ ...req }));

    try {
      const result = await DiscountApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertDiscountAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertDiscountAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchDiscountAction = actionCreator.async<
  {},
  DiscountRes[],
  { code: number; description: string }
>("FETCH_DISCOUNT");

export const fetchDiscount =
  (companyId: number, shopId?: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchDiscountAction.started([]));

    try {
      const result = await DiscountApi.findAll(companyId, shopId);
      dispatch(
        fetchDiscountAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchDiscountAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateDiscountAction = actionCreator.async<
  UpdateDiscountReq,
  DiscountRes,
  { code: number; description: string }
>("UPDATE_DISCOUNT");

export const updateDiscount =
  (companyId: number, req: UpdateDiscountReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateDiscountAction.started({ ...req }));

    try {
      const updateDiscount = new UpdateDiscountReq({ ...req });
      const result = await DiscountApi.update(
        companyId,
        req.discountId,
        updateDiscount
      );
      dispatch(
        updateDiscountAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateDiscountAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteDiscountAction = actionCreator.async<
  DeleteDiscountReq,
  {},
  { code: number; description: string }
>("DELETE_DISCOUNT");

export const deleteDiscount =
  (companyId: number, req: DeleteDiscountReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteDiscountAction.started({ ...req }));

    try {
      const discount = new DeleteDiscountReq({ ...req });
      const result = await DiscountApi.delete(companyId, discount);
      dispatch(
        deleteDiscountAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteDiscountAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const discountActions = {
  fetchDiscountAction,
  addDiscountAction,
  updateDiscountAction,
  deleteDiscountAction,
  bulkInsertDiscountAction,
};

export default discountActions;
