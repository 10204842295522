import Modal from "components/Modal";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useModal from "hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardTimePicker } from "@material-ui/pickers";
import OrderRes from "../../../types/res/order/OrderRes";
import { addOrder } from "redux/actions/order";
import CreateOrderReq from "../../../types/req/order/CreateOrderReq";
import { fetchCourse } from "redux/actions/course";
import CourseRes from "../../../types/res/course/CourseRes";
import { DateTime } from "luxon";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { fetchNomination } from "redux/actions/nomination";
type Props = {
  setStateOrders: any;
  orders: OrderRes[];
};
const CastScheduleOnItemCreate: React.FC<Props> = ({
  setStateOrders,
  orders,
}) => {
  const [castScheduleOnItemCreateModal, setCastScheduleOnItemCreateShow] =
    useModal("castScheduleOnItemCreate");
  const companyId = useSelector((state) => state.account.staff.companyId);
  const staffId = useSelector((state) => state.account.staff.staffId);
  const courses = useSelector((state) => state.course);
  const nominations = useSelector((state) => state.nomination);
  const castShifts = useSelector((state) => state.castShift);
  const [loading, setLoading] = useState(false);
  const [selectCastNameId, setSelectCastNameId] = useState<number>();
  const [planInTime, setPlanInTime] = useState<Date | null>();
  const [planOutTime, setPlanOutTime] = useState<Date | null>();
  const [departureTime, setDepartureTime] = useState<Date | null>();
  const [courseId, setCourseId] = useState<number>();
  const [courseTime, setCourseTime] = useState<number>();
  const [nominationId, setNominationId] = useState<number>();
  const [filterCourses, setFilterCourses] = useState<CourseRes[]>([]);
  const dispatch = useDispatch();
  const castNames = Array.from(
    new Map(
      castShifts
        .flatMap((castShift) => castShift.cast.castNames)
        .map((castName) => [castName.castNameId, castName])
    ).values()
  );
  useEffect(() => {
    dispatch(fetchCourse(companyId));
    dispatch(fetchNomination(companyId));
  }, []);

  useEffect(() => {
    if (!castScheduleOnItemCreateModal?.meta?.castName) return;
    setSelectCastNameId(castScheduleOnItemCreateModal.meta.castName.castNameId);
    setDepartureTime(castScheduleOnItemCreateModal.meta.order.departureTime);
    setPlanInTime(castScheduleOnItemCreateModal.meta.order.planInTime);
    setPlanOutTime(castScheduleOnItemCreateModal.meta.order.planOutTime);
    setNominationId(castScheduleOnItemCreateModal.meta.order.nominationId);
    setFilterCourses(
      courses.filter((course) =>
        castScheduleOnItemCreateModal.meta.castName
          ? course.notelClassId ===
              castScheduleOnItemCreateModal.meta.castName.notelClassId &&
            course.shopId === castScheduleOnItemCreateModal.meta.castName.shopId
          : true
      )
    );
  }, [castScheduleOnItemCreateModal?.meta, courses]);

  useEffect(() => {
    setFilterCourses((prev) =>
      nominationId
        ? prev.filter((course) => course.nominationId === nominationId)
        : courses
    );
  }, [nominationId]);

  useEffect(() => {
    setFilterCourses((prev) =>
      courseTime
        ? prev.filter((course) => course.time === Number(courseTime))
        : courses
    );
  }, [courseTime]);

  useEffect(() => {
    if (castScheduleOnItemCreateModal.show) return;
    setStateOrders(orders);
  }, [castScheduleOnItemCreateModal?.show]);

  useEffect(() => {
    setPlanOutTime((prev: any) =>
      courseTime
        ? DateTime.fromJSDate(planInTime || new Date())
            .plus({ minutes: Number(courseTime) })
            .toJSDate()
        : prev
    );
  }, [planInTime, courseTime]);

  const onClickSubmit = async () => {
    const order = { ...castScheduleOnItemCreateModal.meta.order };
    const course = courses.find((course) => course.courseId === courseId);
    order.castNameId = selectCastNameId;
    order.shopId =
      castNames.find((castName) => castName.castNameId === selectCastNameId)
        ?.shopId || null;
    order.planInTime = planInTime;
    order.planOutTime = planOutTime;
    order.departureTime = departureTime;
    order.status = "hold";
    order.bookEmailBody = "";
    order.courseId = courseId;
    order.courseTime = courseTime;
    order.totalTime =
      courseTime +
        order?.additionalTimeOrders?.reduce(
          (sum: number, additionalTimeOrder: any) =>
            sum +
            (additionalTimeOrder?.additionalTime?.time || 0) *
              additionalTimeOrder.count,
          0
        ) || 0;
    order.nominationId = nominationId;
    order.courseFee = (course?.timeFee || 0) + (course?.hotelFee || 0);
    order.courseCastFee =
      (course?.timeCastFee || 0) + (course?.hotelCastFee || 0);
    order.courseShopFee =
      (course?.timeShopFee || 0) + (course?.hotelShopFee || 0);
    order.timeFee = course?.timeFee || 0;
    order.timeCastFee = course?.timeCastFee || 0;
    order.timeShopFee = course?.timeShopFee || 0;
    order.hotelFee = course?.hotelFee || 0;
    order.hotelCastFee = course?.hotelCastFee || 0;
    order.hotelShopFee = course?.hotelShopFee || 0;
    order.welfareFee = course?.welfareFee || 0;
    order.totalFee = (course?.timeFee || 0) + (course?.hotelFee || 0);
    order.totalCastFee =
      (course?.timeFee || 0) +
      (course?.hotelFee || 0) -
      (course?.welfareFee || 0);
    order.totalShopFee =
      (course?.timeFee || 0) +
      (course?.hotelFee || 0) +
      (course?.welfareFee || 0);
    order.createdById = staffId;
    order.updatedById = staffId;
    order.orderDate = DateTimeUtils.toFormatAsLocalTimezone(
      order.orderDate,
      FORMAT_TYPE.YEAR_DAY
    );
    await dispatch(addOrder(companyId, new CreateOrderReq(order)));
    setCastScheduleOnItemCreateShow(false);
  };

  return (
    <Modal
      show={castScheduleOnItemCreateModal.show}
      setShow={setCastScheduleOnItemCreateShow}
      title={`${castScheduleOnItemCreateModal.meta?.castName?.name}さんへの新規予約作成`}
    >
      <Container>
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column">
            <TextField
              label="源氏名"
              select
              value={selectCastNameId}
              fullWidth
              disabled={true}
              variant="outlined"
              margin="normal"
              onChange={(event) => {
                setSelectCastNameId(Number(event.target.value));
              }}
            >
              {castNames.map((castName) => (
                <MenuItem key={castName.castNameId} value={castName.castNameId}>
                  {castName.name}
                </MenuItem>
              ))}
            </TextField>
            <KeyboardTimePicker
              label="出発"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={departureTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setDepartureTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="予定IN"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={planInTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setPlanInTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
            <TextField
              label="利用時間"
              select
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={courseTime}
              onChange={(event) => setCourseTime(Number(event.target.value))}
              style={{ marginTop: "10px" }}
            >
              <option aria-label="None" value="" />
              {filterCourses
                .filter(
                  (element, index, self) =>
                    self.findIndex((e) => e.time === element.time) === index
                )
                .map((course) => (
                  <MenuItem key={course.time} value={course.time}>
                    {course.time}分
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              label="指名種"
              select
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={nominationId}
              onChange={(event) => setNominationId(Number(event.target.value))}
              style={{ marginTop: "10px" }}
            >
              <option aria-label="None" value="" />
              {nominations.map((nomination) => (
                <MenuItem
                  key={nomination.nominationId}
                  value={nomination.nominationId}
                >
                  {nomination.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="コース"
              select
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={courseId}
              onChange={(event) => setCourseId(Number(event.target.value))}
              style={{ marginTop: "10px" }}
            >
              {filterCourses.map((course) => (
                <MenuItem key={course.courseId} value={course.courseId}>
                  {course.name}
                </MenuItem>
              ))}
            </TextField>
            <KeyboardTimePicker
              label="予定OUT"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={planOutTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setPlanOutTime(date?.toDate() || null)}
              minutesStep={5}
              disabled
              variant="inline"
              style={{ marginTop: "10px" }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          style={{ margin: "10px 0" }}
          justifyContent="space-evenly"
        >
          <Button
            variant="contained"
            color="default"
            disabled={loading}
            onClick={() => setCastScheduleOnItemCreateShow(false)}
          >
            {loading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "いいえ"
            )}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={onClickSubmit}
          >
            {loading ? <CircularProgress color="inherit" size={24} /> : "はい"}
          </Button>
        </Box>
      </Container>
    </Modal>
  );
};
export default CastScheduleOnItemCreate;
