import { reducerWithInitialState } from "typescript-fsa-reducers";
import driverShiftActions from "redux/actions/driverShift";
import DriverShiftRes from "types/res/driverShift/DriverShiftRes";
export type DriverShiftState = DriverShiftRes[];

const initialState = [] as DriverShiftState;

const driverShiftReducer = reducerWithInitialState(initialState)
  .case(driverShiftActions.addDriverShiftAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(driverShiftActions.updateDriverShiftAction.done, (state, payload) => {
    return state.map((item) =>
      item.driverShiftId === payload.params.driverShiftId
        ? payload.result
        : item
    );
  })
  .case(driverShiftActions.deleteDriverShiftAction.done, (state, payload) => {
    return state.filter(
      (item) => item.driverShiftId !== payload.params.driverShiftId
    );
  })
  .case(driverShiftActions.fetchDriverShiftAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(
    driverShiftActions.bulkInsertDriverShiftAction.done,
    (state, payload) => {
      return [...state, ...payload.result];
    }
  );
export default driverShiftReducer;
