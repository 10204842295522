import React, { useState } from "react";
import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormHelperText,
  Popper,
  Box,
  ClickAwayListener,
  Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { DateTime } from "luxon";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { DateType } from "@date-io/type";
import useModal from "hooks/useModal";

export enum FormType {
  Text,
  TextArea,
  Number,
  Date,
  DateTime,
  Time,
  IntervalDateTime,
  Boolean,
  Select,
  SelectGuestGuestCategoryShops,
  MultiOptions,
  AutoCompleteMultiOptions,
  AutoCompleteSelect,
  None,
}

type FormProps = {
  formKey: string;
  formId: string;
  formType: FormType;
  label: string;
  options?: any[];
  disabled?: boolean;
  unSelectOptions?: any[];
  minuteStep?: number;
  formData: any;
  setFormData: (a: any) => any;
  loadMoreResults?: any;
  error?: string[];
  shopId?: number;
};

const FormField = ({
  formKey,
  formId,
  formType,
  label,
  options,
  disabled,
  unSelectOptions,
  minuteStep,
  formData,
  setFormData,
  loadMoreResults,
  error,
  shopId,
}: FormProps) => {
  const [form] = useModal(formId);
  const onChangeForm =
    (key: string, handler: (value: any) => any = (value: any) => value) =>
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | { value: unknown }
      >
    ) => {
      event.persist();
      setFormData((prev: any) => ({
        ...prev,
        [key]: handler(event.target.value),
      }));
    };

  const onChangeSelectGuestGuestCategoryShops =
    (shopId: number, guestId: number) =>
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | { value: unknown }
      >
    ) => {
      event.persist();
      setFormData((prev: any) => {
        const prevGuestCategory =
          prev.guestGuestCategoryShops?.filter(
            (item: any) => item?.guestId !== guestId || item?.shopId !== shopId
          ) || [];
        return {
          ...prev,
          guestGuestCategoryShops: [
            ...prevGuestCategory,
            { shopId, guestId, guestCategoryId: Number(event.target.value) },
          ],
        };
      });
    };

  const onChangeMultiOptions =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!options) return;
      event.persist();
      if (event.target.checked) {
        setFormData((prev: any) => {
          const current = prev[key] || [];
          return {
            ...prev,
            [key]: [...current, Number(event.target.id)],
          };
        });
      } else {
        setFormData((prev: any) => ({
          ...prev,
          [key]: prev[key].filter(
            (id: number) => id !== Number(event.target.id)
          ),
        }));
      }
    };

  const onChangeDateTime = (key: string) => (date: DateType | null) => {
    setFormData((prev: any) => ({
      ...prev,
      [key]: date ? date.toDate() : null,
    }));
  };

  const onChangeAutoCompleteMultiOptions =
    (key: string) => (event: React.ChangeEvent<{}>, value: any) => {
      event.persist();
      setFormData((prev: any) => ({
        ...prev,
        [key]: value.map((t: any) => t.id),
      }));
    };

  const onChangeAutoCompleteSelect =
    (key: string) => (event: React.ChangeEvent<{}>, value: any) => {
      event.persist();
      setFormData((prev: any) => ({
        ...prev,
        [key]: value?.id,
      }));
    };

  const onBlurTel =
    (key: string) =>
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | { value: unknown }
      >
    ) => {
      if (key !== "tel") return;
      event.persist();
      setFormData((prev: any) => ({
        ...prev,
        tel: (event.target.value as string).replace(/[-‐－―ー−]/g, ""),
      }));
    };

  const initialFocusDate = (formKey: string, formData: any) => {
    if (
      !["workOffStart", "workOffEnd"].includes(formKey) ||
      !formData?.["planWorkStart"]
    ) {
      return DateTime.local().startOf("day").toJSDate();
    } else {
      return DateTime.fromJSDate(formData?.["planWorkStart"])
        .startOf("day")
        .toJSDate();
    }
  };

  switch (formType) {
    case FormType.Text:
      return (
        <TextField
          error={Boolean(error)}
          label={label}
          variant="outlined"
          value={formData[formKey] || ""}
          fullWidth
          onChange={onChangeForm(formKey)}
          onBlur={onBlurTel(formKey)}
          helperText={error && error.join("")}
        />
      );
    case FormType.Number:
      return (
        <TextField
          error={Boolean(error)}
          label={label}
          type="number"
          variant="outlined"
          defaultValue={formData[formKey]}
          fullWidth
          onChange={onChangeForm(formKey, (value) =>
            value === null || undefined || "" ? null : Number(value)
          )}
          helperText={error && error.join("")}
        />
      );
    case FormType.Date:
      return (
        <TextField
          error={Boolean(error)}
          label={label}
          type="date"
          variant="outlined"
          defaultValue={DateTimeUtils.toFormatAsLocalTimezone(
            formData[formKey],
            FORMAT_TYPE.YEAR_DAY
          )}
          InputLabelProps={{ shrink: true }}
          fullWidth
          onChange={onChangeForm(formKey, (value) => new Date(value))}
          helperText={error && error.join("")}
        />
      );
    case FormType.DateTime:
      return (
        <TextField
          label={label}
          type="datetime-local"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          defaultValue={
            formData[formKey]
              ? DateTime.fromJSDate(formData[formKey]).toISO({
                  includeOffset: false,
                })
              : ""
          }
          fullWidth
          onChange={onChangeForm(formKey, (value) => new Date(value))}
          error={Boolean(error)}
          helperText={error && error.join("")}
        />
      );
    case FormType.Time:
      return (
        <TextField
          label={label}
          type="time"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          defaultValue={formData[formKey]}
          fullWidth
          onChange={onChangeForm(formKey)}
          error={Boolean(error)}
          helperText={error && error.join("")}
        />
      );

    case FormType.IntervalDateTime:
      return (
        <KeyboardDateTimePicker
          label={label}
          fullWidth
          ampm={false}
          inputVariant="outlined"
          inputMode={"numeric"}
          InputLabelProps={{ shrink: true }}
          value={formData[formKey] || null}
          format="YYYY/MM/DD HH:mm"
          initialFocusedDate={initialFocusDate(formKey, formData)}
          onChange={onChangeDateTime(formKey)}
          minutesStep={minuteStep}
          variant="inline"
          error={Boolean(error)}
          helperText={error && error.join("")}
        />
      );
    case FormType.TextArea:
      return (
        <TextField
          label={label}
          type="textarea"
          variant="outlined"
          defaultValue={formData[formKey] || ""}
          fullWidth
          multiline
          rows={3}
          rowsMax={1000}
          onChange={onChangeForm(formKey)}
          error={Boolean(error)}
          helperText={error && error.join("")}
        />
      );
    case FormType.Boolean:
      return (
        <>
          <FormLabel error={Boolean(error)} component="legend">
            {label}
          </FormLabel>
          <RadioGroup
            row
            aria-label="editCallCenterContract"
            name="editCallCenterContract"
            onChange={onChangeForm(formKey, (value) => value === "1")}
            value={formData[formKey]}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={options?.[1]?.name}
              checked={formData[formKey] === true}
            />
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={options?.[0]?.name}
              checked={formData[formKey] === false}
            />
          </RadioGroup>
          {error && <FormHelperText error>{error.join("")}</FormHelperText>}
        </>
      );
    case FormType.Select:
      return (
        <>
          <InputLabel error={Boolean(error)} htmlFor={formKey}>
            {label}
          </InputLabel>
          <Select
            error={Boolean(error)}
            onChange={onChangeForm(formKey)}
            inputProps={{
              name: formKey,
              id: formKey,
            }}
            fullWidth
            value={formData[formKey] || ""}
          >
            <MenuItem value={""}>
              <Typography color="textSecondary">選択を外す</Typography>
            </MenuItem>
            {options?.map((option) => (
              <MenuItem
                value={option.id}
                key={option.id}
                disabled={unSelectOptions?.includes(option.id)}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText error>{error.join("")}</FormHelperText>}
        </>
      );
    case FormType.SelectGuestGuestCategoryShops: {
      return (
        <>
          <InputLabel error={Boolean(error)} htmlFor={`${formKey}-${shopId}`}>
            {label}
          </InputLabel>
          <Select
            error={Boolean(error)}
            onChange={onChangeSelectGuestGuestCategoryShops(
              shopId || 0,
              form?.meta?.item?.guestId
            )}
            inputProps={{
              name: formKey,
              id: formKey,
            }}
            fullWidth
            value={
              formData[formKey]?.find(
                (guestGuestCategoryShop: any) =>
                  guestGuestCategoryShop.shopId === shopId &&
                  guestGuestCategoryShop.guestId === form?.meta?.item?.guestId
              )?.guestCategoryId || ""
            }
          >
            <MenuItem value={""}>
              <Typography color="textSecondary">選択を外す</Typography>
            </MenuItem>
            {options?.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText error>{error.join("")}</FormHelperText>}
        </>
      );
    }
    case FormType.MultiOptions:
      return (
        <>
          <InputLabel error={Boolean(error)} htmlFor={formKey}>
            {label}
          </InputLabel>
          {options?.map((option) => (
            <FormControlLabel
              control={
                <Checkbox
                  name={option.name}
                  onChange={onChangeMultiOptions(formKey)}
                  checked={formData[formKey]?.includes(option.id) ?? false}
                  id={`${option.id}`}
                />
              }
              disabled={disabled}
              key={option.id}
              label={option.name}
            />
          ))}
          {error && <FormHelperText error>{error.join("")}</FormHelperText>}
        </>
      );
    case FormType.AutoCompleteMultiOptions: {
      const allSelected =
        options?.length ===
        options?.filter((option) => formData[formKey]?.includes(option?.id))
          .length;
      const [open, setOpen] = useState(false);
      const checkAllChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        key: string
      ) => {
        event.persist();
        if (event.target.checked) {
          setFormData((prev: any) => ({
            ...prev,
            [key]: options?.map((t: any) => t.id) || [],
          }));
        } else {
          setFormData((prev: any) => ({
            ...prev,
            [key]: [],
          }));
        }
        setOpen(false);
      };

      return (
        <Autocomplete
          multiple
          options={options || []}
          getOptionLabel={(option) => option.name || ""}
          value={options?.filter((option) =>
            formData[formKey]?.includes(option?.id)
          )}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={onChangeAutoCompleteMultiOptions(formKey)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={label} />
          )}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={(event, reason) => {
            if (reason === "escape") {
              setOpen(false);
            }
          }}
          noOptionsText="選択肢がありません"
          PopperComponent={(params) => (
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Popper {...params}>
                <Box {...params} />
                <Box
                  style={{
                    marginTop: "-4px",
                    backgroundColor: "white",
                    height: "45px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Checkbox
                    checked={allSelected}
                    onChange={(event) => checkAllChange(event, formKey)}
                    id="check-all"
                  />
                  すべてを選択
                </Box>
              </Popper>
            </ClickAwayListener>
          )}
        />
      );
    }
    case FormType.AutoCompleteSelect: {
      const [open, setOpen] = useState(false);
      return (
        <Autocomplete
          options={options || []}
          getOptionLabel={(option) => option.name || ""}
          value={
            options?.find((option) => formData[formKey] === option?.id) || ""
          }
          filterOptions={(filterOptions, state) =>
            filterOptions.filter((o) => o.name.includes(state.inputValue))
          }
          getOptionSelected={(option, value) => option?.id === value?.id}
          onChange={onChangeAutoCompleteSelect(formKey)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={label} />
          )}
          onInputChange={async (event, value) => {
            event?.persist();
            if (loadMoreResults) {
              await loadMoreResults(value);
            }
          }}
          open={open}
          ListboxProps={{
            onScroll: (event: React.SyntheticEvent) => {
              const listBoxNode = event.currentTarget;
              if (
                listBoxNode.scrollTop + listBoxNode.clientHeight >
                  listBoxNode.scrollHeight - 1 &&
                loadMoreResults
              ) {
                loadMoreResults();
              }
            },
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          noOptionsText="選択肢がありません"
        />
      );
    }
    case FormType.None:
      return null;
    default: {
      const _: never = formType;
      throw new Error(_);
    }
  }
};

export default FormField;
