import { reducerWithInitialState } from "typescript-fsa-reducers";
import recruitingMediaActions from "redux/actions/recruitingMedia";
import RecruitingMediaRes from "types/res/recruitingMedia/RecruitingMediaRes";
export type RecruitingMediaState = RecruitingMediaRes[];

const initialState = [] as RecruitingMediaState;

const recruitingMediaReducer = reducerWithInitialState(initialState)
  .case(
    recruitingMediaActions.fetchRecruitingMediaAction.done,
    (state, payload) => {
      return payload.result;
    }
  )
  .case(
    recruitingMediaActions.addRecruitingMediaAction.done,
    (state, payload) => {
      return [...state, payload.result];
    }
  )
  .case(
    recruitingMediaActions.updateRecruitingMediaAction.done,
    (state, payload) => {
      return state.map((recruitingMedia) =>
        recruitingMedia.recruitingMediaId === payload.result.recruitingMediaId
          ? payload.result
          : recruitingMedia
      );
    }
  )
  .case(
    recruitingMediaActions.deleteRecruitingMediaAction.done,
    (state, payload) => {
      return state.filter((recruitingMedia) => {
        return (
          recruitingMedia.recruitingMediaId !== payload.params.recruitingMediaId
        );
      });
    }
  );

export default recruitingMediaReducer;
