import { reducerWithInitialState } from "typescript-fsa-reducers";
import castReportActions from "redux/actions/castReport";
import CastReportRes from "types/res/castReport/CastReportRes";
export type CastReportState = CastReportRes[];

const initialState = [] as CastReportState;

const castReportReducer = reducerWithInitialState(initialState)
  .case(castReportActions.fetchCastReportAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(castReportActions.isReadCastReportAction.done, (state, payload) => {
    return state.map((item) =>
      item.castReportId === payload.params.castReportId ? payload.result : item
    );
  });
export default castReportReducer;
