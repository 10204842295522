import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import CosplayRes from "types/res/cosplay/CosplayRes";
import UpdateCosplayReq from "types/req/cosplay/UpdateCosplayReq";
import CreateCosplayReq from "types/req/cosplay/CreateCosplayReq";
import DeleteCosplayReq from "types/req/cosplay/DeleteCosplayReq";
import CosplayApi from "../../api/CosplayApi";

const actionCreator = actionCreatorFactory();
const addCosplayAction = actionCreator.async<
  CreateCosplayReq,
  CosplayRes,
  { code: number; description: string }
>("ADD_COSPLAY");

export const addCosplay =
  (companyId: number, req: CreateCosplayReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addCosplayAction.started({ ...req }));

    try {
      const cosplay = new CreateCosplayReq({ ...req });
      const result = await CosplayApi.create(companyId, cosplay);
      dispatch(
        addCosplayAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addCosplayAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertCosplayAction = actionCreator.async<
  CreateCosplayReq[],
  CosplayRes[],
  { code: number; description: string }
>("BULK_INSERT_COSPLAY");
export const bulkInsertCosplay =
  (companyId: number, req: CreateCosplayReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertCosplayAction.started({ ...req }));

    try {
      const result = await CosplayApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertCosplayAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertCosplayAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCosplayAction = actionCreator.async<
  {},
  CosplayRes[],
  { code: number; description: string }
>("FETCH_COSPLAY");

export const fetchCosplay =
  (companyId: number, shopId?: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCosplayAction.started([]));

    try {
      const result = await CosplayApi.findAll(companyId, shopId);
      dispatch(
        fetchCosplayAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCosplayAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateCosplayAction = actionCreator.async<
  UpdateCosplayReq,
  CosplayRes,
  { code: number; description: string }
>("UPDATE_COSPLAY");

export const updateCosplay =
  (companyId: number, req: UpdateCosplayReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateCosplayAction.started({ ...req }));

    try {
      const updateCosplay = new UpdateCosplayReq({ ...req });
      const result = await CosplayApi.update(
        companyId,
        req.cosplayId,
        updateCosplay
      );
      dispatch(
        updateCosplayAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateCosplayAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteCosplayAction = actionCreator.async<
  DeleteCosplayReq,
  {},
  { code: number; description: string }
>("DELETE_COSPLAY");

export const deleteCosplay =
  (companyId: number, req: DeleteCosplayReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteCosplayAction.started({ ...req }));

    try {
      const cosplay = new DeleteCosplayReq({ ...req });
      const result = await CosplayApi.delete(companyId, cosplay);
      dispatch(
        deleteCosplayAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteCosplayAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const cosplayActions = {
  fetchCosplayAction,
  addCosplayAction,
  updateCosplayAction,
  deleteCosplayAction,
  bulkInsertCosplayAction,
};

export default cosplayActions;
