import { DataValidationError } from "constants/DataValidationError";
import {
  IsArray,
  IsAscii,
  IsDate,
  IsDecimal,
  IsEmail,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";
import { Type } from "class-transformer";

export default class CreateCastReq {
  @MaxLength(Spec.maxLength.cast.email, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.cast.email,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsEmail(
    {},
    {
      message: DataValidationError.DATA_IS_INVALID,
    }
  )
  email!: string;

  @MaxLength(Spec.maxLength.cast.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.cast.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @MaxLength(Spec.maxLength.cast.displayName, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.cast.displayName,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  displayName!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  castCategoryId!: number;

  @MaxLength(Spec.maxLength.cast.tel, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.cast.tel,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  tel!: string;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @MaxLength(Spec.maxLength.cast.password, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.cast.password,
    },
  })
  @IsAscii({
    message: DataValidationError.DATA_IS_INVALID,
  })
  password!: string;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @MaxLength(Spec.maxLength.cast.address, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.cast.address,
    },
  })
  address!: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.cast.remarks, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.cast.remarks,
    },
  })
  remarks!: string;

  @IsArray()
  shops!: number[];

  @IsOptional()
  @IsArray()
  ngAreas!: number[];

  @IsOptional()
  @IsArray()
  ngGuests!: number[];

  @IsOptional()
  @IsNumber()
  sort!: number | null;

  @IsOptional()
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  entryDate: Date | null = null;

  @IsOptional()
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  closingDate: Date | null = null;

  @IsOptional()
  invoiceRate!: number | null;

  @IsOptional()
  @IsNumber()
  recruitingMediaId!: number | null;

  constructor(arg: {
    name: string;
    castCategoryId: number;
    displayName: string;
    email: string;
    password: string;
    tel: string;
    shops: number[];
    ngAreas: number[];
    ngGuests: number[];
    address: string;
    remarks: string;
    sort: number | null;
    entryDate: Date | null;
    closingDate: Date | null;
    invoiceRate: number | null;
    recruitingMediaId: number | null;
  }) {
    arg = arg || {};
    this.name = arg.name;
    this.castCategoryId = arg.castCategoryId;
    this.displayName = arg.displayName;
    this.email = arg.email;
    this.tel = arg.tel;
    this.password = arg.password;
    this.shops = arg.shops;
    this.ngAreas = arg.ngAreas;
    this.ngGuests = arg.ngGuests;
    this.address = arg.address;
    this.remarks = arg.remarks;
    this.sort = arg.sort;
    this.entryDate = arg.entryDate;
    this.closingDate = arg.closingDate;
    this.invoiceRate = arg.invoiceRate;
    this.recruitingMediaId = arg.recruitingMediaId;
  }
}
