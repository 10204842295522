import { Expose, Type } from "class-transformer";
import StaffRole from "types/enum/StaffRole";
import CompanyRes from "../company/CompanyRes";

export default class StaffRes {
  @Expose() readonly companyId!: number;
  @Expose() readonly staffId!: number;
  @Expose() readonly email!: string;
  @Expose() readonly activeEmail!: string | null;
  @Expose() readonly name!: string;
  @Expose() readonly tel!: string | null;
  @Expose() readonly role!: StaffRole;
  @Expose() readonly sort!: number | null;
  @Expose() readonly isForceSite!: string | null;
  @Type(() => CompanyRes)
  @Expose()
  readonly company!: CompanyRes;
}
