import { IsNotEmpty } from "class-validator";

export default class DeleteCompanyGroupReq {
  @IsNotEmpty({
    message: "",
  })
  readonly companyGroupId: number;

  constructor(arg: { companyGroupId: number }) {
    arg = arg || {};
    this.companyGroupId = arg.companyGroupId;
  }
}
