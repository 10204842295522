import Modal from "components/Modal";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useModal from "hooks/useModal";
import GuestApi from "api/GuestApi";
import OrderApi from "api/OrderApi";
import { updateStaff } from "redux/actions/staff";
import { useDispatch, useSelector } from "react-redux";
import { CallHistoryType } from "types/enum/CallHistoryType";
import { updateCallHistory } from "redux/actions/callHistory";
import CallHistoryApi from "api/CallHistoryApi";
import GuestRes from "types/res/guest/GuestRes";
import OrderRes from "types/res/order/OrderRes";
import ShopApi from "api/ShopApi";
import querystring from "querystring";
import StaffApi from "api/StaffApi";
import DriverApi from "api/DriverApi";
import CastApi from "api/CastApi";
import CastRes from "types/res/cast/CastRes";
import DriverRes from "types/res/driver/DriverRes";
import StaffRes from "types/res/staff/StaffRes";

const CtiModal = () => {
  const dispatch = useDispatch();
  const [ctiModal, setCtiModalShow] = useModal("ctiModal");
  const queryString = new URLSearchParams(useLocation().search);
  const acd = queryString.get("acd");
  const tel = queryString.get("tel");
  const [ctiGuest, setCtiGuest] = useState<GuestRes>();
  const [ctiCast, setCtiCast] = useState<CastRes>();
  const [ctiDriver, setCtiDriver] = useState<DriverRes>();
  const [ctiStaff, setCtiStaff] = useState<StaffRes>();
  const [ctiOrder, setCtiOrder] = useState<OrderRes>();
  const [callType, setCallType] = useState<CallHistoryType>();
  const [callHistoryId, setCallHistoryId] = useState<number>();
  const [shopId, setShopId] = useState<number>();
  const [stateCompanyId, setStateCompanyId] = useState<number>();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const loginStaff = useSelector((state) => state.account.staff);
  const accessToken = useSelector((state) => state.account.accessToken);
  const shops = useSelector((state) => state.shop);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (!tel) return;

    const apiCall = async () => {
      let tmpCompanyId = companyId;
      setCtiModalShow(true);
      setIsLoading(true);
      if (acd) {
        const findAcdShop = await ShopApi.findByAcdCode(tmpCompanyId, acd);
        setShopId(findAcdShop?.shopId);
        setStateCompanyId(findAcdShop?.companyId);
        if (findAcdShop?.companyId) {
          await dispatch(
            updateStaff(
              companyId,
              { ...loginStaff, companyId: findAcdShop.companyId },
              accessToken
            )
          );
          setCtiGuest({} as GuestRes);
          tmpCompanyId = findAcdShop.companyId;
        }
      }
      const guest = await GuestApi.findCti(tmpCompanyId, acd || "", tel);
      const staff = await StaffApi.findCti(tmpCompanyId, tel);
      const cast = await CastApi.findCti(tmpCompanyId, tel);
      const driver = await DriverApi.findCti(tmpCompanyId, tel);
      setCtiGuest(guest);
      setCtiStaff(staff);
      setCtiCast(cast);
      setCtiDriver(driver);
      if (cast?.castId) {
        setCallType("cast" as CallHistoryType);
      } else if (driver?.driverId) {
        setCallType("driver" as CallHistoryType);
      } else if (staff?.staffId) {
        setCallType("staff" as CallHistoryType);
      } else if (guest?.guestId) {
        setCallType("guest" as CallHistoryType);
      }
      const order = await OrderApi.findCti(tmpCompanyId, acd || "", tel);
      setCtiOrder(order);
      const result = await CallHistoryApi.create(tmpCompanyId, { tel });
      setCallHistoryId(result.callHistoryId);
      setIsLoading(false);
    };
    apiCall();
  }, [acd, tel]);

  useEffect(() => {
    if (!callHistoryId) return;
    dispatch(
      updateCallHistory(stateCompanyId || companyId, {
        callHistoryId,
        shopId: shopId || null,
        type: callType || null,
        guestId: ctiGuest?.guestId || null,
        staffId: loginStaff.staffId,
      })
    );
  }, [callHistoryId, shopId, callType, ctiGuest, loginStaff]);

  const orderCreate = () => {
    if (!callHistoryId) return;
    dispatch(
      updateCallHistory(stateCompanyId || companyId, {
        callHistoryId,
        shopId: shopId || ctiOrder?.shopId || null,
        type: callType || null,
        guestId: ctiGuest?.guestId || null,
        staffId: loginStaff.staffId,
      })
    );
    const queryString = querystring.stringify({
      shopId,
      guestId: ctiGuest?.guestId,
      tel,
    });
    setCtiModalShow(false, {});
    history.push(
      ctiOrder?.orderId ? `/order/${ctiOrder.orderId}` : `/order?${queryString}`
    );
  };

  return (
    <Modal show={ctiModal.show} setShow={setCtiModalShow}>
      <Box>
        <Box display="flex" justifyContent="center" marginBottom={2}>
          <Typography variant="h4">{tel}から入電がありました。</Typography>
        </Box>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Box
              display="flex"
              justifyContent="center"
              marginTop={2}
              alignItems="center"
            >
              <Typography variant="h6" style={{ marginRight: "10px" }}>
                発信者情報
              </Typography>
              <TextField
                select
                label={"店舗"}
                value={shopId || ""}
                onChange={(event) => setShopId(Number(event.target.value))}
                style={{ width: "250px", marginRight: "10px" }}
              >
                {shops.map((shop) => (
                  <MenuItem key={shop.shopId} value={shop.shopId}>
                    {shop.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label={"種別"}
                value={callType || ""}
                onChange={(event) =>
                  setCallType(event.target.value as CallHistoryType)
                }
                style={{ width: "100px", marginRight: "10px" }}
              >
                {Object.entries(CallHistoryType).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
              <Box display={"flex"} flexDirection={"column"}>
                {ctiCast?.castId && (
                  <Box display={"flex"}>
                    <Box
                      display={"flex"}
                      flexDirection="column"
                      style={{ marginRight: "10px", paddingBottom: "7px" }}
                    >
                      <Typography style={{ fontSize: "10px" }}>
                        キャスト名
                      </Typography>
                      <Typography>{ctiCast?.displayName || ""}さん</Typography>
                    </Box>
                  </Box>
                )}
                {ctiDriver?.driverId && (
                  <Box display={"flex"}>
                    <Box
                      display={"flex"}
                      flexDirection="column"
                      style={{ marginRight: "10px", paddingBottom: "7px" }}
                    >
                      <Typography style={{ fontSize: "10px" }}>
                        ドライバー名
                      </Typography>
                      <Typography>{ctiDriver?.name || ""}さん</Typography>
                    </Box>
                  </Box>
                )}
                {ctiStaff?.staffId && (
                  <Box display={"flex"}>
                    <Box
                      display={"flex"}
                      flexDirection="column"
                      style={{ marginRight: "10px", paddingBottom: "7px" }}
                    >
                      <Typography style={{ fontSize: "10px" }}>
                        スタッフ名
                      </Typography>
                      <Typography>{ctiStaff?.name || ""}さん</Typography>
                    </Box>
                  </Box>
                )}
                {ctiGuest?.guestId && (
                  <Box display={"flex"}>
                    <Box
                      display={"flex"}
                      flexDirection="column"
                      style={{ marginRight: "10px", paddingBottom: "7px" }}
                    >
                      <Typography style={{ fontSize: "10px" }}>
                        顧客名
                      </Typography>
                      <Typography>{ctiGuest?.name || ""}様</Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection="column"
                      style={{ marginRight: "10px", paddingBottom: "7px" }}
                    >
                      <Typography style={{ fontSize: "10px" }}>
                        顧客区分
                      </Typography>
                      <Typography>
                        {loginStaff.company.guestCategoryShopFlag
                          ? ctiGuest?.guestGuestCategoryShops?.find(
                              (guestGuestCategoryShop) =>
                                guestGuestCategoryShop.shopId === shopId &&
                                guestGuestCategoryShop.guestId ===
                                  ctiGuest.guestId
                            )?.guestCategory?.name || "区分なし"
                          : ctiGuest?.guestCategory?.name || "区分なし"}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" marginTop={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={orderCreate}
              >
                {ctiOrder?.orderId ? "受注編集へ" : "新規受注へ"}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};
export default CtiModal;
