import { reducerWithInitialState } from "typescript-fsa-reducers";
import castCategoryActions from "redux/actions/castCategory";
import CastCategoryRes from "types/res/castCategory/CastCategoryRes";
export type CastCategoryState = CastCategoryRes[];

const initialState = [] as CastCategoryState;

const castCategoryReducer = reducerWithInitialState(initialState)
  .case(castCategoryActions.addCastCategoryAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(castCategoryActions.updateCastCategoryAction.done, (state, payload) => {
    return state.map((item) =>
      item.castCategoryId === payload.params.castCategoryId
        ? payload.result
        : item
    );
  })
  .case(castCategoryActions.deleteCastCategoryAction.done, (state, payload) => {
    return state.filter(
      (item) => item.castCategoryId !== payload.params.castCategoryId
    );
  })
  .case(castCategoryActions.fetchCastCategoryAction.done, (state, payload) => {
    return [...payload.result];
  });
export default castCategoryReducer;
