import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Table,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { fetchShops } from "redux/actions/shop";
import { fetchNotelClass } from "redux/actions/notelClass";
import { fetchNomination } from "redux/actions/nomination";
import { fetchActualWorkCastList, fetchCast } from "redux/actions/cast";
import { fetchCastCategory } from "redux/actions/castCategory";
import {
  fetchCastRanking,
  fetchCastRankingEachData,
} from "redux/actions/castRanking";
import { makeStyles } from "@material-ui/core/styles";
import CastRankingTableHeader from "pages/CastAnalyticsRanking/Table/CastRankingTableHeader";
import CastRankingTableBody from "pages/CastAnalyticsRanking/Table/CastRankingTableBody";
import CastRes from "types/res/cast/CastRes";
import CastRakingCalender from "pages/CastAnalyticsRanking/Calender/CastRakingCalender";
import CastRankingGraph from "pages/CastAnalyticsRanking/Graph/CastRankingGraph";
import CastRankingApi from "api/CastRankingApi";

const IndividualCastAnalytics: React.FC = () => {
  const dispatch = useDispatch();
  const { castId } = useParams<{ castId: string }>();
  const shops = useSelector((state) => state.shop);
  const notelClasses = useSelector((state) => state.notelClass);
  const nominations = useSelector((state) => state.nomination);
  const companyId = useSelector((state) => state.account.staff.companyId);
  const castRaking = useSelector((state) => state.castRanking.total);
  const castRakingEachData = useSelector((state) => state.castRanking.casts);
  const casts = useSelector((state) => state.cast);
  const castCategories = useSelector((state) => state.castCategory);
  const [selectNominationId, setSelectNominationId] = useState<number>();

  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ days: 30, hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectNotelClasses, setSelectNotelClasses] = useState<string[]>([]);
  const [selectCastCategories, setSelectCastCategories] = useState<string[]>(
    []
  );
  const [calendarDisplayKey, setCalendarDisplayKey] = useState("attendance");
  const [displayData, setDisplayData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [workingResult, setWorkingResult] = useState(true);
  const [enrolled, setEnrolled] = useState(true);
  const [isCastListLoading, setIsCastListLoading] = useState(true);
  const [isShopDataLoading, setIsShopDataLoading] = useState(true);
  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchNotelClass(companyId));
    dispatch(fetchNomination(companyId));
    dispatch(fetchCastCategory(companyId));
    dispatch(fetchCast(companyId));
  }, [companyId]);

  useEffect(() => {
    const apiCall = async () => {
      await dispatch(
        fetchActualWorkCastList(
          companyId,
          DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          selectShops.filter((shopId) => shopId !== "すべて").join(","),
          selectNotelClasses
            .filter((notelClassId) => notelClassId !== "すべて")
            .join(","),
          selectCastCategories
            .filter((castCategoryId) => castCategoryId !== "すべて")
            .join(","),
          enrolled,
          workingResult
        )
      );
      setIsCastListLoading(false);
    };
    apiCall();
  }, [
    companyId,
    startDate,
    endDate,
    selectShops,
    selectNotelClasses,
    selectCastCategories,
    enrolled,
    workingResult,
  ]);

  useEffect(() => {
    if (!selectShops.filter((shop) => shop !== "すべて").length) return;
    if (calendarDisplayKey === "attendance") {
      const apiCall = async () => {
        setIsLoading(true);
        const result = await CastRankingApi.findAttendanceData(
          companyId,
          DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          selectShops.filter((shop) => shop !== "すべて").join(","),
          castId
        );
        setDisplayData(result);
        setIsLoading(false);
      };
      apiCall();
      return;
    }
    if (["orderCount", "nominationRate"].includes(calendarDisplayKey)) {
      const apiCall = async () => {
        setIsLoading(true);
        const result = await CastRankingApi.findOrderData(
          companyId,
          DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          selectShops.filter((shop) => shop !== "すべて").join(","),
          castId
        );
        setDisplayData(result);
        setIsLoading(false);
      };
      apiCall();
      return;
    }
    if (["revenue", "cast"].includes(calendarDisplayKey)) {
      const apiCall = async () => {
        setIsLoading(true);
        const result = await CastRankingApi.findRevenueData(
          companyId,
          DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          selectShops.filter((shop) => shop !== "すべて").join(","),
          castId
        );
        setDisplayData(result);
        setIsLoading(false);
      };
      apiCall();
      return;
    }
  }, [calendarDisplayKey, selectShops, startDate, endDate]);

  useEffect(() => {
    const apiCall = async () => {
      await dispatch(
        fetchCastRanking(
          companyId,
          DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          selectShops.filter((shopId) => shopId !== "すべて").join(","),
          selectNotelClasses
            .filter((notelClassId) => notelClassId !== "すべて")
            .join(","),
          selectCastCategories
            .filter((castCategoryId) => castCategoryId !== "すべて")
            .join(",")
        )
      );
      setIsShopDataLoading(false);
    };
    apiCall();
  }, [
    companyId,
    startDate,
    endDate,
    selectShops,
    selectNotelClasses,
    selectCastCategories,
  ]);

  useEffect(() => {
    if (!castId) return;
    dispatch(
      fetchCastRankingEachData(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        castId
      )
    );
  }, [companyId, startDate, endDate, castId]);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    setSelectNotelClasses([
      ...notelClasses.map((notelClass) => String(notelClass.notelClassId)),
      "すべて",
    ]);
  }, [notelClasses]);

  useEffect(() => {
    setSelectCastCategories(
      castCategories
        .filter((castCategory) => castCategory.name.includes("在籍"))
        .map((castCategory) => String(castCategory.castCategoryId))
    );
  }, [castCategories]);

  useEffect(() => {
    setSelectNominationId(nominations[0]?.nominationId);
  }, [nominations]);
  const cast = casts.find((cast) => cast.castId === Number(castId));
  if (isCastListLoading) return <CircularProgress />;
  if (!cast)
    return (
      <Box>
        キャストが存在しません。リストからキャストを再選択してください。
      </Box>
    );
  return (
    <Box display="flex" flexDirection="column">
      <Header
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectShops={selectShops}
        setSelectShops={setSelectShops}
        selectNotelClasses={selectNotelClasses}
        setSelectNotelClasses={setSelectNotelClasses}
        selectCastCategories={selectCastCategories}
        setSelectCastCategories={setSelectCastCategories}
        workingResult={workingResult}
        setWorkingResult={setWorkingResult}
        enrolled={enrolled}
        setEnrolled={setEnrolled}
        casts={casts}
      />
      {isShopDataLoading ? (
        <CircularProgress />
      ) : (
        castRaking &&
        castRakingEachData[cast.castId] && (
          <>
            <Table>
              <CastRankingTableHeader
                selectNominationId={selectNominationId}
                setSelectNominationId={setSelectNominationId}
                nominations={nominations}
              />
              <CastRankingTableBody
                total={true}
                dataArray={[
                  {
                    id: "castAnalytics",
                    name: "店舗平均",
                    commutingDays: Math.round(
                      castRaking.commutingDays / casts.length || 0
                    ),
                    commutingTimes: Math.round(
                      castRaking.commutingTimes / casts.length || 0
                    ),
                    averageCommutingTimes:
                      Math.round(
                        ((castRaking.commutingTimes || 0) /
                          (castRaking.commutingDays || 0)) *
                          10
                      ) / 10 || 0,
                    totalOrderCount: Math.round(
                      castRaking.totalOrderCount / casts.length || 0
                    ),
                    nominationOrderCount: Math.round(
                      castRaking[`nomination${selectNominationId}Count`] /
                        casts.length || 0
                    ),
                    nominationOrderRate:
                      Math.round(
                        (castRaking[`nomination${selectNominationId}Count`] /
                          castRaking.totalOrderCount) *
                          10000
                      ) / 100 || 0,
                    averageTotalOrderCount:
                      Math.round(
                        (castRaking.totalOrderCount /
                          casts.length /
                          (castRaking.commutingDays / casts.length)) *
                          100
                      ) / 100 || 0,
                    averageNominationOrderCount:
                      Math.round(
                        (castRaking[`nomination${selectNominationId}Count`] /
                          castRaking.commutingDays || 0) * 100
                      ) / 100,
                    repeatRate: Math.round(
                      (1 -
                        (castRaking.breakCount / castRaking.breakBaseCount ||
                          0)) *
                        100
                    ),
                    breakRate: Math.round(
                      (castRaking.breakCount / castRaking.breakBaseCount || 0) *
                        100
                    ),
                    sales: Math.round(castRaking.sales / casts.length || 0),
                    averageSales: Math.round(
                      castRaking.sales / castRaking.totalOrderCount || 0
                    ),
                    castFee: Math.round(castRaking.castFee / casts.length || 0),
                    castFeePerHour: Math.round(
                      castRaking.castFee / castRaking.commutingTimes
                    ),
                    tardyCount: Math.round(
                      castRaking.tardyCount / casts.length || 0
                    ),
                    absenceCount: Math.round(
                      castRaking.absenceCount / casts.length || 0
                    ),
                    score:
                      Math.round(
                        castRaking.averageScore / castRaking.totalOrderCount
                      ) || 0,
                  },
                ]}
              />
            </Table>
            <Box marginTop={5}>
              <Typography variant={"h5"} style={{ margin: "40px 0 0 20px" }}>
                ランキング
              </Typography>
              <Table>
                <CastRankingTableHeader
                  selectNominationId={selectNominationId}
                  setSelectNominationId={setSelectNominationId}
                  nominations={nominations}
                  link={true}
                />
                <CastRankingTableBody
                  total={false}
                  dataArray={[
                    {
                      id: cast.castId,
                      name: cast.displayName,
                      shopName: cast.shops?.map((shop) => shop.name).join("/"),
                      notelClassName: cast.castNames
                        .map((castName) => castName.notelClass.name)
                        .join("/"),
                      commutingDays:
                        castRakingEachData[cast.castId].commutingDays || 0,
                      commutingTimes:
                        castRakingEachData[cast.castId].commutingTimes || 0,
                      averageCommutingTimes:
                        Math.round(
                          ((castRakingEachData[cast.castId].commutingTimes ||
                            0) /
                            (castRakingEachData[cast.castId].commutingDays ||
                              0)) *
                            10
                        ) / 10 || 0,
                      totalOrderCount:
                        castRakingEachData[cast.castId].totalOrderCount || 0,
                      nominationOrderCount:
                        castRakingEachData[cast.castId][
                          `nomination${selectNominationId}Count`
                        ] || 0,
                      nominationOrderRate:
                        Math.round(
                          ((castRakingEachData[cast.castId][
                            `nomination${selectNominationId}Count`
                          ] || 0) /
                            (castRakingEachData[cast.castId].totalOrderCount ||
                              0)) *
                            10000
                        ) / 100 || 0,
                      averageTotalOrderCount:
                        Math.round(
                          ((castRakingEachData[cast.castId].totalOrderCount ||
                            0) /
                            (castRakingEachData[cast.castId].commutingDays ||
                              0)) *
                            100
                        ) / 100 || 0,
                      averageNominationOrderCount:
                        Math.round(
                          (castRakingEachData[cast.castId][
                            `nomination${selectNominationId}Count`
                          ] /
                            (castRakingEachData[cast.castId].commutingDays ||
                              0)) *
                            100
                        ) / 100 || 0,
                      repeatRate: Math.round(
                        (1 -
                          (castRakingEachData[cast.castId].breakCount /
                            castRakingEachData[cast.castId].breakBaseCount ||
                            0)) *
                          100
                      ),
                      breakRate: Math.round(
                        (castRakingEachData[cast.castId].breakCount /
                          castRakingEachData[cast.castId].breakBaseCount || 0) *
                          100
                      ),
                      sales: castRakingEachData[cast.castId].sales || 0,
                      averageSales:
                        Math.round(
                          castRakingEachData[cast.castId].sales /
                            (castRakingEachData[cast.castId].totalOrderCount ||
                              0)
                        ) || 0,
                      castFee: castRakingEachData[cast.castId].castFee || 0,
                      castFeePerHour: Math.round(
                        castRakingEachData[cast.castId].castFee /
                          castRakingEachData[cast.castId].commutingTimes
                      ),
                      tardyCount:
                        castRakingEachData[cast.castId].tardyCount || 0,
                      absenceCount:
                        castRakingEachData[cast.castId].absenceCount || 0,
                      score: Math.round(
                        castRakingEachData[cast.castId].averageScore /
                          castRakingEachData[cast.castId].totalOrderCount
                      ),
                    },
                  ]}
                />
              </Table>
            </Box>
          </>
        )
      )}
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box display="flex">
          <Box margin={2}>
            <CastRakingCalender
              displayData={displayData}
              calendarDisplayKey={calendarDisplayKey}
              setCalendarDisplayKey={setCalendarDisplayKey}
            />
          </Box>
          <Box margin={2}>
            <CastRankingGraph
              displayData={displayData}
              calendarDisplayKey={calendarDisplayKey}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

type HeaderProps = {
  startDate: Date;
  setStartDate: any;
  endDate: Date;
  setEndDate: any;
  selectShops: string[];
  selectNotelClasses: string[];
  setSelectShops: any;
  setSelectNotelClasses: any;
  selectCastCategories: string[];
  setSelectCastCategories: any;
  workingResult: boolean;
  setWorkingResult: any;
  enrolled: boolean;
  setEnrolled: any;
  casts: CastRes[];
};
const Header = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectShops,
  selectNotelClasses,
  setSelectShops,
  setSelectNotelClasses,
  selectCastCategories,
  setSelectCastCategories,
  workingResult,
  setWorkingResult,
  enrolled,
  setEnrolled,
  casts,
}: HeaderProps) => {
  const { castId } = useParams<{ castId: string }>();
  const history = useHistory();
  const castCategories = useSelector((state) => state.castCategory);
  const shops = useSelector((state) => state.shop);
  const notelClasses = useSelector((state) => state.notelClass);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  return (
    <Box margin={2} display="flex" alignItems="center">
      <TextField
        type="date"
        label="開始日"
        style={{ margin: "10px", width: "200px" }}
        value={DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
        onChange={(event) =>
          setStartDate(
            DateTime.fromISO(event.target.value as string)
              .startOf("day")
              .toJSDate()
          )
        }
      />
      <TextField
        type="date"
        label="終了日"
        style={{ margin: "10px", width: "200px" }}
        value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
        onChange={(event) =>
          setEndDate(
            DateTime.fromISO(event.target.value as string)
              .startOf("day")
              .toJSDate()
          )
        }
      />
      <Box>
        <InputLabel id="select-multiple-shop">店舗</InputLabel>
        <Select
          multiple
          value={selectShops}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSelectShops((prev: string[]) => {
              if (
                prev.includes("すべて") &&
                (event.target.value as string[]).indexOf("すべて") === -1
              ) {
                return [];
              } else if (
                !prev.includes("すべて") &&
                (event.target.value as string[]).indexOf("すべて") !== -1
              ) {
                return [...shops.map((shop) => String(shop.shopId)), "すべて"];
              } else if (
                (event.target.value as string[]).length === shops.length &&
                (event.target.value as string[]).indexOf("すべて") === -1
              ) {
                return [...shops.map((shop) => String(shop.shopId)), "すべて"];
              } else if (
                (event.target.value as string[]).length <= shops.length
              ) {
                return (event.target.value as string[]).filter(
                  (name) => name !== "すべて"
                );
              } else {
                return event.target.value as string[];
              }
            });
          }}
          input={<Input id="select-multiple-shop" />}
          style={{ width: "200px", marginRight: "10px" }}
          renderValue={(selected) => {
            if ((selected as string[]).includes("すべて")) {
              return "すべて";
            } else {
              return shops
                .filter((shop) =>
                  (selected as string[]).includes(String(shop.shopId))
                )
                .map((shop) => shop.name)
                .join(", ");
            }
          }}
        >
          <MenuItem key={"すべて"} value={"すべて"}>
            <Checkbox name="all" checked={selectShops.indexOf("すべて") > -1} />
            <ListItemText primary={"すべて"} />
          </MenuItem>
          {shops.map((shop) => (
            <MenuItem key={shop.shopId} value={String(shop.shopId)}>
              <Checkbox
                checked={selectShops.indexOf(String(shop.shopId)) !== -1}
              />
              <ListItemText primary={shop.name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <InputLabel id="select-multiple-class">クラス</InputLabel>
        <Select
          multiple
          value={selectNotelClasses}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSelectNotelClasses((prev: string[]) => {
              if (
                prev.includes("すべて") &&
                (event.target.value as string[]).indexOf("すべて") === -1
              ) {
                return [];
              } else if (
                !prev.includes("すべて") &&
                (event.target.value as string[]).indexOf("すべて") !== -1
              ) {
                return [
                  ...notelClasses.map((notelClass) =>
                    String(notelClass.notelClassId)
                  ),
                  "すべて",
                ];
              } else if (
                (event.target.value as string[]).length ===
                  notelClasses.length &&
                (event.target.value as string[]).indexOf("すべて") === -1
              ) {
                return [
                  ...notelClasses.map((notelClass) =>
                    String(notelClass.notelClassId)
                  ),
                  "すべて",
                ];
              } else if (
                (event.target.value as string[]).length <= notelClasses.length
              ) {
                return (event.target.value as string[]).filter(
                  (name) => name !== "すべて"
                );
              } else {
                return event.target.value as string[];
              }
            });
          }}
          input={<Input id="select-multiple-notelClass" />}
          style={{ width: "200px", marginRight: "10px" }}
          renderValue={(selected) => {
            if ((selected as string[]).includes("すべて")) {
              return "すべて";
            } else {
              return notelClasses
                .filter((notelClass) =>
                  (selected as string[]).includes(
                    String(notelClass.notelClassId)
                  )
                )
                .map((notelClass) => notelClass.name)
                .join(", ");
            }
          }}
        >
          <MenuItem key={"すべて"} value={"すべて"}>
            <Checkbox
              name="all"
              checked={selectNotelClasses.indexOf("すべて") > -1}
            />
            <ListItemText primary={"すべて"} />
          </MenuItem>
          {notelClasses.map((notelClass) => (
            <MenuItem
              key={notelClass.notelClassId}
              value={String(notelClass.notelClassId)}
            >
              <Checkbox
                checked={
                  selectNotelClasses.indexOf(
                    String(notelClass.notelClassId)
                  ) !== -1
                }
              />
              <ListItemText primary={notelClass.name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <InputLabel id="select-multiple-class">キャスト区分</InputLabel>
        <Select
          multiple
          value={selectCastCategories}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSelectCastCategories((prev: string[]) => {
              if (
                prev.includes("すべて") &&
                (event.target.value as string[]).indexOf("すべて") === -1
              ) {
                return [];
              } else if (
                !prev.includes("すべて") &&
                (event.target.value as string[]).indexOf("すべて") !== -1
              ) {
                return [
                  ...castCategories.map((castCategory) =>
                    String(castCategory.castCategoryId)
                  ),
                  "すべて",
                ];
              } else if (
                (event.target.value as string[]).length ===
                  castCategories.length &&
                (event.target.value as string[]).indexOf("すべて") === -1
              ) {
                return [
                  ...castCategories.map((castCategory) =>
                    String(castCategory.castCategoryId)
                  ),
                  "すべて",
                ];
              } else if (
                (event.target.value as string[]).length <= castCategories.length
              ) {
                return (event.target.value as string[]).filter(
                  (name) => name !== "すべて"
                );
              } else {
                return event.target.value as string[];
              }
            });
          }}
          input={<Input id="select-multiple-castCategory" />}
          style={{ width: "200px", marginRight: "10px" }}
          renderValue={(selected) => {
            if ((selected as string[]).includes("すべて")) {
              return "すべて";
            } else {
              return castCategories
                .filter((castCategory) =>
                  (selected as string[]).includes(
                    String(castCategory.castCategoryId)
                  )
                )
                .map((castCategory) => castCategory.name)
                .join(", ");
            }
          }}
        >
          <MenuItem key={"すべて"} value={"すべて"}>
            <Checkbox
              name="all"
              checked={selectCastCategories.indexOf("すべて") > -1}
            />
            <ListItemText primary={"すべて"} />
          </MenuItem>
          {castCategories.map((castCategory) => (
            <MenuItem
              key={castCategory.castCategoryId}
              value={String(castCategory.castCategoryId)}
            >
              <Checkbox
                checked={
                  selectCastCategories.indexOf(
                    String(castCategory.castCategoryId)
                  ) !== -1
                }
              />
              <ListItemText primary={castCategory.name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box display="flex" flexDirection="column">
        <FormControlLabel
          control={
            <Checkbox
              checked={workingResult}
              onChange={(event) => setWorkingResult(event.target.checked)}
            />
          }
          label={"出勤実績"}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={enrolled}
              onChange={(event) => setEnrolled(event.target.checked)}
            />
          }
          label={"対象期間の在籍"}
        />
      </Box>
      <Box>
        <TextField
          label="キャスト名"
          select
          style={{ margin: "10px", width: "200px" }}
          value={Number(castId) || ""}
          onChange={(event) => {
            history.push(`/castAnalyticsRanking/cast/${event.target.value}`);
          }}
        >
          {casts.map((cast) => (
            <MenuItem value={cast.castId} key={cast.castId}>
              {cast.displayName}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Button
        variant="contained"
        className={classes.button}
        color="default"
        component={Link}
        to={"/castAnalyticsRanking"}
      >
        一覧へ
      </Button>
    </Box>
  );
};
export default IndividualCastAnalytics;
