import {
  Box,
  Button,
  Checkbox,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DateTimeUtils, { FORMAT_TYPE } from "../../utils/DateTimeUtils";
import OrderRes from "../../types/res/order/OrderRes";
import { putCollection, putPayoff } from "redux/actions/payoff";
import { useDispatch, useSelector } from "react-redux";
import CastRes from "../../types/res/cast/CastRes";
import PaymentType from "../../types/enum/PaymentType";
import EnumUtils from "../../utils/EnumUtils";
import { pdf } from "@react-pdf/renderer";
import SpecPdf from "./specPdf";
import PageUtils from "../../utils/PageUtils";
import OrderStatus from "../../types/enum/OrderStatus";

type Props = {
  startDate: Date;
  endDate: Date;
  cast: CastRes;
  orders: OrderRes[];
};
const PayoffTable: React.FC<Props> = ({ startDate, endDate, cast, orders }) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const [message, setMessage] = useState("");
  const [completePayoff, setCompletePayoff] = useState<OrderRes[]>([]);
  const [loading, setLoading] = useState(false);

  const handleCollectionChange = (order: OrderRes) => async () => {
    if (
      EnumUtils.mapToEnum(OrderStatus, order.status) === OrderStatus.booking
    ) {
      const result = window.confirm(
        "店舗マスター未満のアカウントでは完了にすると変更が出来ませんがよろしいでしょうか？"
      );
      if (!result) {
        return false;
      }
    }
    if (order.collectReceivable) {
      const result = window.confirm(
        "ポイントがリセットされ、受注は予約状態に戻りますがよろしいでしょうか？"
      );
      if (!result) {
        return false;
      }
    }
    dispatch(putCollection(companyId, order.orderId));
  };

  const handlePayoffChange = (orderId: number) => async () => {
    dispatch(putPayoff(companyId, orderId));
  };
  useEffect(() => {
    setCompletePayoff([
      ...orders.filter((order) => order.collectReceivable && order.payoff),
    ]);
  }, [orders]);

  useEffect(() => {
    if (!orders?.length) return;
  }, [orders, message]);

  const downloadSpecPdf = async () => {
    setLoading(true);
    const asPdf = pdf();
    const doc = <SpecPdf orders={orders} message={message} />;
    asPdf.updateContainer(doc);
    const pdfBlob = await asPdf.toBlob();
    const blob = new Blob([pdfBlob], { type: "application/pdf" });
    PageUtils.download(blob, "支払通知書.pdf");
    setLoading(false);
  };
  return (
    <>
      <Box display="flex" marginTop={2}>
        <Typography variant="subtitle1">{cast.displayName}</Typography>
        <Typography variant="subtitle1" style={{ marginLeft: "10px" }}>
          {DateTimeUtils.toFormatAsLocalTimezone(
            startDate,
            FORMAT_TYPE.YEAR_DAY
          )}
          〜
          {DateTimeUtils.toFormatAsLocalTimezone(endDate, FORMAT_TYPE.YEAR_DAY)}
        </Typography>
      </Box>
      {orders.length ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>日付</TableCell>
                  <TableCell>顧客名</TableCell>
                  <TableCell>顧客電話番号</TableCell>
                  <TableCell>店名</TableCell>
                  <TableCell>源氏名</TableCell>
                  <TableCell>コース</TableCell>
                  <TableCell>ステータス</TableCell>
                  <TableCell>稼働</TableCell>
                  <TableCell>支払い</TableCell>
                  <TableCell>店舗売上</TableCell>
                  <TableCell>キャスト報酬</TableCell>
                  <TableCell>全体売上</TableCell>
                  <TableCell padding="checkbox">回収</TableCell>
                  <TableCell padding="checkbox">精算</TableCell>
                  <TableCell padding="checkbox" />
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow key={order.orderId}>
                    <TableCell>
                      {DateTimeUtils.toFormatAsLocalTimezone(
                        order.orderDate,
                        FORMAT_TYPE.YEAR_DAY
                      )}
                    </TableCell>
                    <TableCell>{order.guest?.name || ""}</TableCell>
                    <TableCell>{order.guest?.tel || ""}</TableCell>
                    <TableCell>{order.shop?.name || ""}</TableCell>
                    <TableCell>{order.castName?.name || ""}</TableCell>
                    <TableCell>{order.course?.name || ""}</TableCell>
                    <TableCell>
                      {EnumUtils.mapToEnum(OrderStatus, order.status)}
                    </TableCell>
                    <TableCell>
                      {order.actualInTime && order.actualEndTime
                        ? "実IN・OUT済"
                        : "実IN・OUT未登録"}
                    </TableCell>
                    <TableCell>
                      {!order.paymentType
                        ? "未設定"
                        : EnumUtils.mapToEnum(
                            PaymentType,
                            order.paymentType
                          ) === PaymentType.cash
                        ? "現金"
                        : "クレジットカード"}
                    </TableCell>
                    <TableCell align="right">
                      <Typography>{order.totalShopFee || 0}円</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>{order.totalCastFee || 0}円</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>{order.totalFee || 0}円</Typography>
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        name="collection"
                        checked={order.collectReceivable}
                        onChange={handleCollectionChange(order)}
                      />
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        name="payoff"
                        checked={order.payoff}
                        onChange={handlePayoffChange(order.orderId)}
                        disabled={
                          EnumUtils.mapToEnum(OrderStatus, order.status) !==
                          OrderStatus.paid
                        }
                      />
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Link href={`/order/${order.orderId}`}>編集</Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            margin={2}
          >
            <Box display="flex" flexBasis="60%">
              <TextField
                multiline
                variant="outlined"
                label="キャストへのメッセージ"
                style={{ width: "50%" }}
                onBlur={(event) => setMessage(event.target.value)}
              />
              {/*<Button*/}
              {/*  style={{ marginLeft: "10px" }}*/}
              {/*  variant="outlined"*/}
              {/*  onClick={recipePdf(completePayoff, cast, totalCastFee)}*/}
              {/*  disabled={completePayoff.length === 0}*/}
              {/*>*/}
              {/*  領収書発行*/}
              {/*</Button>*/}
              <Button
                style={{ marginLeft: "10px" }}
                variant="outlined"
                disabled={completePayoff.length === 0 || loading}
                onClick={() => downloadSpecPdf()}
              >
                支払通知書発行
              </Button>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box display="flex">
                <Typography>
                  回収金額(インボイス含む）{" "}
                  {orders
                    .filter(
                      (order) =>
                        order.collectReceivable &&
                        EnumUtils.mapToEnum(PaymentType, order.paymentType) !==
                          PaymentType.creditCard
                    )
                    .reduce((sum, order) => (order.totalFee || 0) + sum, 0)}
                  円
                </Typography>
                <Typography style={{ marginLeft: "10px" }}>
                  未回収金額{" "}
                  {orders
                    .filter(
                      (order) =>
                        !order.collectReceivable &&
                        EnumUtils.mapToEnum(PaymentType, order.paymentType) !==
                          PaymentType.creditCard
                    )
                    .reduce((sum, order) => (order.totalFee || 0) + sum, 0)}
                  円
                </Typography>
                <Typography style={{ marginLeft: "10px" }}>
                  カード支払金額{" "}
                  {orders
                    .filter(
                      (order) =>
                        EnumUtils.mapToEnum(PaymentType, order.paymentType) ==
                        PaymentType.creditCard
                    )
                    .reduce((sum, order) => (order.totalFee || 0) + sum, 0)}
                  円
                </Typography>
                <Typography style={{ marginLeft: "10px" }}>
                  カード手数料{" "}
                  {orders
                    .filter(
                      (order) =>
                        EnumUtils.mapToEnum(PaymentType, order.paymentType) ==
                        PaymentType.creditCard
                    )
                    .reduce((sum, order) => (order.cardFee || 0) + sum, 0)}
                  円
                </Typography>
              </Box>
              <Box display="flex">
                <Typography>
                  精算金額{" "}
                  {orders
                    .filter((order) => order.payoff)
                    .reduce((sum, order) => (order.totalFee || 0) + sum, 0)}
                  円
                </Typography>
                <Typography style={{ marginLeft: "10px" }}>
                  未精算金額{" "}
                  {orders
                    .filter((order) => !order.payoff)
                    .reduce((sum, order) => (order.totalFee || 0) + sum, 0)}
                  円
                </Typography>
              </Box>
              <Box display="flex">
                <Typography>
                  店舗売上{" "}
                  {orders.reduce(
                    (sum, order) => (order.totalShopFee || 0) + sum,
                    0
                  )}
                  円
                </Typography>
                <Typography style={{ marginLeft: "10px" }}>
                  キャスト報酬{" "}
                  {orders.reduce(
                    (sum, order) => (order.totalCastFee || 0) + sum,
                    0
                  )}
                  円
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Typography>データがありません。</Typography>
      )}
    </>
  );
};
export default PayoffTable;
