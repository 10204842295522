import { Expose, Type } from "class-transformer";
import MessageBoardCheckRes from "../messageBoardCheck/MessageBoardCheckRes";
import MessageBoardCommentRes from "../messageBoardComment/MessageBoardCommentRes";
import StaffRes from "../staff/StaffRes";

export default class MessageBoardRes {
  @Expose() readonly messageBoardId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly title!: string;
  @Expose() readonly content!: string;
  @Expose() readonly createdById!: number;
  @Type(() => StaffRes)
  @Expose()
  readonly createdBy!: StaffRes;
  @Type(() => MessageBoardCheckRes)
  @Expose()
  readonly messageBoardChecks!: MessageBoardCheckRes[];
  @Type(() => MessageBoardCommentRes)
  @Expose()
  readonly messageBoardComments!: MessageBoardCommentRes[];
  @Type(() => Date)
  @Expose()
  readonly createdAt!: Date;
}
