import { IsNotEmpty } from "class-validator";
import { DataValidationError } from "../../../constants/DataValidationError";

export default class DeleteCourseReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  readonly courseId: number;
  constructor(arg: { courseId: number }) {
    arg = arg || {};
    this.courseId = arg.courseId;
  }
}
