import React, { useEffect, useState } from "react";
import styled from "styled-components";

import media from "utils/media";
import { Box, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import EnumUtils from "utils/EnumUtils";
import { PlanType } from "types/enum/PlanType";
import CompanyGroupApi from "api/CompanyGroupApi";
import CompanyGroupRes from "types/res/companyGroup/CompanyGroupRes";
import StaffRole from "types/enum/StaffRole";

const Container = styled.footer`
  display: flex;
  justify-content: center;
  background-color: ${({ theme: { palette } }) => palette.grey[100]};
  padding: ${({ theme: { spacing } }) => spacing(2)}px;
`;

const Inner = styled.div`
  max-width: 1200px;
  width: 100%;
`;

const Navigation = styled.nav`
  display: flex;
  ${media.sp`
    flex-wrap: wrap;
  `}
`;

const AllRightsReserved = styled.div`
  font-size: 1.2rem;
  color: ${({ theme: { palette } }) => palette.grey[500]};
  text-align: center;
`;

const StyledLink = styled.a`
  color: ${({ theme: { palette } }) => palette.grey[500]};
  font-size: 1.2rem;
`;

const Footer = () => {
  const companyGroupId = useSelector(
    (state) => state.account?.staff?.company?.companyGroupId
  );
  const companyId = useSelector(
    (state) => state.account?.staff?.company?.companyId
  );
  const plan = useSelector(
    (state) => state.account?.staff?.company?.companyGroup?.plan
  );
  const staffRole = useSelector((state) => state.account?.staff?.role);
  const now = DateTime.now().startOf("days");
  const nextPayDate =
    DateTime.now().startOf("days").day > 5
      ? DateTime.now().startOf("days").endOf("month").plus({ days: 5 })
      : DateTime.now().startOf("month").plus({ days: 5 });
  const dateDiff = nextPayDate.diff(now, "days").days;
  const isFreePlan = EnumUtils.mapToEnum(PlanType, plan) === PlanType.free;
  const [companyGroup, setCompanyGroup] = useState<CompanyGroupRes>();
  useEffect(() => {
    if (!companyGroupId) return;
    if (EnumUtils.mapToEnum(StaffRole, staffRole) !== StaffRole.clientMaster)
      return;
    const apiCall = async () => {
      const res = await CompanyGroupApi.findOne(companyGroupId, companyId);
      setCompanyGroup(res);
    };
    apiCall();
  }, [companyGroupId, staffRole]);

  return (
    <Container>
      <Inner>
        {dateDiff < 7 && !isFreePlan && (
          <Box display="flex" justifyContent={"center"}>
            <Alert severity="error">
              支払い期日が近づいております。支払期日まで残り
              {Math.floor(dateDiff)}日です。
              期日までにお支払いを完了いただけない場合フリープランへの変更となります。
            </Alert>
          </Box>
        )}
        {companyGroup?.isMonthlyPaid && companyGroup?.invoiceUrl ? (
          <Box display="flex" justifyContent={"center"} marginTop={2}>
            <Alert severity="error">
              <Box display={"flex"} flexDirection={"column"}>
                <Typography>
                  お支払いが済んでいない請求書がございます。下記よりアクセスしてお支払いをお願いいたします。
                </Typography>
                <a
                  href={companyGroup.invoiceUrl}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {companyGroup.invoiceUrl}
                </a>
                <Typography style={{ fontSize: "12px" }}>
                  ※尚、お支払いより1日以内の場合は反映されない場合がございます。
                </Typography>
                <Typography style={{ fontSize: "12px" }}>
                  URLにアクセスして請求書が支払い済みの場合、こちらはご放念ください。
                </Typography>
              </Box>
            </Alert>
          </Box>
        ) : (
          <></>
        )}
        <Navigation>
          <section></section>
        </Navigation>
        <Box display="flex" justifyContent={"center"}>
          <StyledLink href={"/special_commercail_code.pdf"}>
            特定商取引法に基づく表記
          </StyledLink>
        </Box>
        <AllRightsReserved>©TasTeck All Rights Reserved.</AllRightsReserved>
      </Inner>
    </Container>
  );
};

export default Footer;
