import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShopRes from "types/res/shop/ShopRes";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import { DiscountType } from "types/enum/DiscountType";
import {
  addDiscount,
  bulkInsertDiscount,
  deleteDiscount,
  fetchDiscount,
  updateDiscount,
} from "redux/actions/discount";
import DiscountRes from "types/res/discount/DiscountRes";
import { fetchShops } from "redux/actions/shop";
import { FormType } from "components/FormField";
import CreateDiscountReq from "types/req/discount/CreateDiscountReq";
import UpdateDiscountReq from "types/req/discount/UpdateDiscountReq";
import EnumUtils from "utils/EnumUtils";
import { CsvFieldType } from "utils/CsvUtils";
import {
  Box,
  Button,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import CsvImport from "components/CsvImport";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";

const headers: { key: keyof DiscountRes; label: string }[] = [
  { key: "name", label: "名前" },
  { key: "type", label: "タイプ" },
  { key: "totalFee", label: "割引額" },
  { key: "shopFee", label: "店舗料金" },
  { key: "castFee", label: "キャスト料金" },
  { key: "sort", label: "並び順" },
  { key: "shops", label: "適用店舗" },
];

const Discount: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const discount = useSelector((state) => state.discount);
  const [openCsvImport, setOpenCsvImport] = useState(false);
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [filterDiscount, setFilterDiscount] = useState<DiscountRes[]>([]);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchDiscount(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    const filter = discount.filter((d) => {
      const shopCondition = selectShops.some((shopId) =>
        d.shops.map((shop) => shop.shopId).includes(Number(shopId))
      );
      return shopCondition;
    });
    setFilterDiscount(filter);
  }, [discount, selectShops]);

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "タイプ",
      key: "type",
      type: FormType.Select,
      options: Object.entries(DiscountType).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "割引額",
      key: "totalFee",
      type: FormType.Number,
    },
    {
      label: "店舗料金",
      key: "shopFee",
      type: FormType.Number,
    },
    {
      label: "キャスト料金",
      key: "castFee",
      type: FormType.Number,
    },
    {
      label: "並び順",
      key: "sort",
      type: FormType.Number,
    },
    {
      label: "店舗",
      key: "shops",
      type: FormType.MultiOptions,
      options: shops.map((s) => ({ id: Number(s.shopId), name: s.name })),
      value: (val: any) => val.map((s: any) => Number(s.shopId)),
    },
  ];

  const csvHeader = [
    {
      label: "名前",
      key: "name",
      type: CsvFieldType.Text,
    },
    {
      label: "タイプ",
      key: "type",
      type: CsvFieldType.Enum,
      enumObject: DiscountType,
    },
    {
      label: "割引額",
      key: "totalFee",
      type: CsvFieldType.Number,
    },
    {
      label: "店舗料金",
      key: "shopFee",
      type: CsvFieldType.Number,
    },
    {
      label: "キャスト料金",
      key: "castFee",
      type: CsvFieldType.Number,
    },
    {
      label: "並び順",
      key: "sort",
      type: CsvFieldType.Number,
    },
    {
      label: "店舗",
      key: "shops",
      type: CsvFieldType.Array,
      relation: shops,
      relationId: "shopId",
      relationKey: "name",
    },
  ];

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        marginTop={1}
        marginRight={2}
      >
        <Box display="flex" alignItems="center">
          <Box>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={selectShops}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectShops((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length === shops.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= shops.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectShops.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCsvImport((prev) => !prev)}
              style={{ marginLeft: "30px" }}
            >
              csvインポート
            </Button>
          </Box>
        </Box>
      </Box>
      {openCsvImport && (
        <CsvImport
          templatePath={"/csv/discount.csv"}
          templateName={"discount_template.csv"}
          csvHeader={csvHeader}
          addType={CreateDiscountReq}
          addFunc={(formData) => bulkInsertDiscount(companyId, formData)}
        />
      )}
      <DiscountTable
        title={"イベント・割引"}
        formId="discountForm"
        forms={forms}
        rows={headers}
        data={filterDiscount}
        addFunc={(formData) => addDiscount(companyId, formData)}
        updateFunc={(formData) => updateDiscount(companyId, formData)}
        deleteFunc={(item) =>
          deleteDiscount(companyId, {
            discountId: item.discountId,
          })
        }
        addType={CreateDiscountReq}
        updateType={UpdateDiscountReq}
        values={[
          (s) => s.name,
          (s) => EnumUtils.mapToEnum(DiscountType, s.type) || "",
          (s) => s.totalFee,
          (s) => s.shopFee,
          (s) => s.castFee,
          (s) => s.sort || "未設定",
          (s) =>
            s.shops
              .map(
                (shopRes: ShopRes) =>
                  shops.find((shop) => shop.shopId === shopRes.shopId)?.name
              )
              .join(",") || "所属なし",
        ]}
      />
    </>
  );
};

const DiscountTable = styled<
  React.FC<CommonTableProps<DiscountRes, CreateDiscountReq, UpdateDiscountReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default Discount;
