import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import {
  addArea,
  bulkInsertArea,
  deleteArea,
  fetchArea,
  updateArea,
} from "redux/actions/area";
import AreaRes from "types/res/area/AreaRes";
import { fetchShops } from "redux/actions/shop";
import { FormType } from "components/FormField";
import CreateAreaReq from "types/req/area/CreateAreaReq";
import UpdateAreaReq from "types/req/area/UpdateAreaReq";
import { Box, Button } from "@material-ui/core";
import CsvImport from "components/CsvImport";
import { CsvFieldType } from "utils/CsvUtils";

const headers: { key: keyof AreaRes; label: string }[] = [
  { key: "name", label: "名前" },
  { key: "kana", label: "読みがな" },
  { key: "totalFee", label: "料金" },
  { key: "shopFee", label: "店舗料金" },
  { key: "castFee", label: "キャスト料金" },
  { key: "time", label: "交通時間" },
  { key: "sort", label: "並び順" },
  { key: "isGuestShow", label: "顧客モバイル表示" },
  { key: "shops", label: "適用店舗" },
];

const Area: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const area = useSelector((state) => state.area);
  const [openCsvImport, setOpenCsvImport] = useState(false);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchArea(companyId));
  }, [companyId, dispatch]);

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "読みがな",
      key: "kana",
      type: FormType.Text,
    },
    {
      label: "料金",
      key: "totalFee",
      type: FormType.Number,
    },
    {
      label: "店舗料金",
      key: "shopFee",
      type: FormType.Number,
    },
    {
      label: "キャスト料金",
      key: "castFee",
      type: FormType.Number,
    },
    {
      label: "交通時間",
      key: "time",
      type: FormType.Number,
    },
    {
      label: "並び順",
      key: "sort",
      type: FormType.Number,
    },
    {
      label: "顧客モバイル表示",
      key: "isGuestShow",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "非表示" },
        { id: "1", name: "表示" },
      ],
    },
    {
      label: "店舗",
      key: "shops",
      type: FormType.MultiOptions,
      options: shops.map((s) => ({ id: Number(s.shopId), name: s.name })),
      value: (val: any) => val.map((s: any) => s.shopId),
    },
  ];

  const csvHeader = [
    {
      label: "名前",
      key: "name",
      type: CsvFieldType.Text,
    },
    {
      label: "読みがな",
      key: "kana",
      type: CsvFieldType.Text,
    },
    {
      label: "料金※数字のみ",
      key: "totalFee",
      type: CsvFieldType.Number,
    },
    {
      label: "店舗料金※数字のみ",
      key: "shopFee",
      type: CsvFieldType.Number,
    },
    {
      label: "キャスト料金※数字のみ",
      key: "castFee",
      type: CsvFieldType.Number,
    },
    {
      label: "交通時間(分数)※数字のみ",
      key: "time",
      type: CsvFieldType.Number,
    },
    {
      label: "並び順",
      key: "sort",
      type: CsvFieldType.Number,
    },
    {
      label: "顧客モバイル表示",
      key: "isGuestShow",
      type: CsvFieldType.Boolean,
    },
    {
      label: "所属店舗",
      key: "shops",
      type: CsvFieldType.Array,
      relation: shops,
      relationKey: "name",
      relationId: "shopId",
    },
  ];

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginTop={1}
        marginRight={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenCsvImport((prev) => !prev)}
          style={{ marginLeft: "30px" }}
        >
          csvインポート
        </Button>
      </Box>
      {openCsvImport && (
        <CsvImport
          templatePath={"/csv/area.csv"}
          templateName={"area_template.csv"}
          csvHeader={csvHeader}
          addType={CreateAreaReq}
          addFunc={(formData) => bulkInsertArea(companyId, formData)}
        />
      )}
      <AreaTable
        title={"エリア"}
        formId="areaForm"
        forms={forms}
        addFunc={(formData) => addArea(companyId, formData)}
        updateFunc={(formData) => updateArea(companyId, formData)}
        deleteFunc={(item) =>
          deleteArea(companyId, {
            areaId: item?.areaId,
          })
        }
        rows={headers}
        data={area}
        addType={CreateAreaReq}
        updateType={UpdateAreaReq}
        values={[
          (s) => s.name,
          (s) => s.kana,
          (s) => s.totalFee,
          (s) => s.shopFee,
          (s) => s.castFee,
          (s) => s.time,
          (s) => s.sort || "未設定",
          (s) => (s.isGuestShow ? "表示" : "非表示"),
          (s) => s.shops?.map((shop) => shop.name).join(",") || "所属なし",
        ]}
      />
    </>
  );
};

const AreaTable = styled<
  React.FC<CommonTableProps<AreaRes, CreateAreaReq, UpdateAreaReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default Area;
