import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty, MaxLength } from "class-validator";
import Spec from "constants/Spec";

export default class CreateCastCategoryReq {
  @MaxLength(Spec.maxLength.castCategory.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.castCategory.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  constructor(arg: { name: string }) {
    arg = arg || {};
    this.name = arg.name;
  }
}
