import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import MessageBoardCheckRes from "types/res/messageBoardCheck/MessageBoardCheckRes";
import CreateMessageBoardCheckReq from "types/req/messageBoardCheck/CreateMessageBoardCheckReq";
import MessageBoardCheckApi from "api/MessageBoardCheckApi";

const actionCreator = actionCreatorFactory();
const addMessageBoardCheckAction = actionCreator.async<
  CreateMessageBoardCheckReq,
  MessageBoardCheckRes,
  { code: number; description: string }
>("ADD_MESSAGE_BOARD_CHECK");

export const addMessageBoardCheck =
  (companyId: number, req: CreateMessageBoardCheckReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addMessageBoardCheckAction.started({ ...req }));

    try {
      const messageBoardCheck = new CreateMessageBoardCheckReq({ ...req });
      const result = await MessageBoardCheckApi.create(
        companyId,
        messageBoardCheck
      );
      dispatch(
        addMessageBoardCheckAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addMessageBoardCheckAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchMessageBoardCheckAction = actionCreator.async<
  {},
  MessageBoardCheckRes[],
  { code: number; description: string }
>("FETCH_MESSAGE_BOARD_CHECK");

export const fetchMessageBoardCheck =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchMessageBoardCheckAction.started([]));

    try {
      const result = await MessageBoardCheckApi.findAll(companyId);
      dispatch(
        fetchMessageBoardCheckAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchMessageBoardCheckAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const messageBoardCheckActions = {
  fetchMessageBoardCheckAction,
  addMessageBoardCheckAction,
};

export default messageBoardCheckActions;
