import RequestUtils from "utils/RequestUtils";
import GuestNoteRes from "types/res/guestNote/GuestNoteRes";

export default class GuestCategoryApi {
  private constructor() {}

  static async findAll(companyId: number) {
    return await RequestUtils.getArray(
      GuestNoteRes,
      `/guestNote/findAll/${companyId}`
    );
  }

  static async findByCastNameId(companyId: number, castNameId: number) {
    return await RequestUtils.getArray(
      GuestNoteRes,
      `/guestNote/findByCastId/${companyId}/${castNameId}`
    );
  }

  static async findOne(companyId: number, guestNoteId: number) {
    return await RequestUtils.get(
      GuestNoteRes,
      `/guestNote/findAllByCastId/${companyId}/${guestNoteId}`
    );
  }
}
