import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import GuestPointApi from "api/GuestPointApi";
import GuestPointRes from "types/res/guestPoint/GuestPointRes";
import CreateGuestPointReq from "types/req/guestPoint/CreateGuestPointReq";

const actionCreator = actionCreatorFactory();
const addGuestPointAction = actionCreator.async<
  CreateGuestPointReq,
  GuestPointRes,
  { code: number; description: string }
>("ADD_GUEST_POINT");

export const addGuestPoint =
  (companyId: number, req: CreateGuestPointReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addGuestPointAction.started({ ...req }));

    try {
      const guestPoint = new CreateGuestPointReq({ ...req });
      const result = await GuestPointApi.create(companyId, guestPoint);
      dispatch(
        addGuestPointAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addGuestPointAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertGuestPointAction = actionCreator.async<
  CreateGuestPointReq[],
  GuestPointRes[],
  { code: number; description: string }
>("BULK_INSERT_GUEST_POINT");
export const bulkInsertGuestPoint =
  (companyId: number, req: CreateGuestPointReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertGuestPointAction.started({ ...req }));

    try {
      const result = await GuestPointApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertGuestPointAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertGuestPointAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchGuestPointAction = actionCreator.async<
  {},
  GuestPointRes[],
  { code: number; description: string }
>("FETCH_GUEST_POINT");

export const fetchGuestPoint =
  (
    companyId: number,
    limit: number,
    offset: number,
    shopId?: number,
    guestId?: number
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchGuestPointAction.started([]));

    try {
      const result = await GuestPointApi.findAll(
        companyId,
        limit,
        offset,
        shopId,
        guestId
      );
      dispatch(
        fetchGuestPointAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchGuestPointAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const guestPointActions = {
  fetchGuestPointAction,
  addGuestPointAction,
  bulkInsertGuestPointAction,
};

export default guestPointActions;
