import RequestUtils from "utils/RequestUtils";
import CastCategoryRes from "types/res/castCategory/CastCategoryRes";
import CreateCastCategoryReq from "types/req/castCategory/CreateCastCategoryReq";
import UpdateCastCategoryReq from "types/req/castCategory/UpdateCastCategoryReq";
import DeleteCastCategoryReq from "types/req/castCategory/DeleteCastCategoryReq";

export default class CastCategoryApi {
  private constructor() {}

  static async findAll(companyId: number): Promise<CastCategoryRes[]> {
    return await RequestUtils.getArray(
      CastCategoryRes,
      `/castCategory/findAll/${companyId}`
    );
  }

  static async create(companyId: number, data: CreateCastCategoryReq) {
    return await RequestUtils.post(
      CastCategoryRes,
      `/castCategory/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    castCategoryId: number,
    data: UpdateCastCategoryReq
  ): Promise<CastCategoryRes> {
    return await RequestUtils.post(
      CastCategoryRes,
      `/castCategory/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteCastCategoryReq) {
    return await RequestUtils.postVoid(
      `/castCategory/delete/${companyId}`,
      data
    );
  }
}
