import styled from "styled-components";
import React, { useEffect } from "react";
import CommonTable, { CommonTableProps } from "../../components/CommonTable";
import CompanyGroupRes from "../../types/res/companyGroup/CompanyGroupRes";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompanyGroup,
  deleteCompanyGroup,
  fetchCompanyGroups,
  recessCompanyGroup,
  updateCompanyGroup,
} from "redux/actions/companyGroup";
import CreateCompanyGroupReq from "../../types/req/companyGroup/CreateCompanyGroupReq";
import UpdateCompanyGroupReq from "../../types/req/companyGroup/UpdateCompanyGroupReq";
import { FormType } from "components/FormField";
import { PlanType } from "types/enum/PlanType";
import EnumUtils from "utils/EnumUtils";
import {
  Box,
  Button,
  InputLabel,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import { fetchCompanies } from "redux/actions/company";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";

const headers: { key: keyof CompanyGroupRes; label: string }[] = [
  { key: "name", label: "会社名" },
  { key: "kana", label: "よみがな" },
  { key: "plan", label: "プラン" },
  { key: "recessedAt", label: "休会処理" },
];
const CompanyGroup = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const companyGroup = useSelector((state) => state.companyGroup);
  const [plan, setPlan] = React.useState([...Object.keys(PlanType), "すべて"]);
  const [ongoing, setOngoing] = React.useState(false);
  const [recessed, setRecessed] = React.useState(false);
  const [stateCompanyGroup, setStateCompanyGroup] =
    React.useState(companyGroup);
  const forms = [
    {
      label: "会社名",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "よみがな",
      key: "kana",
      type: FormType.Text,
    },
    {
      label: "プラン",
      key: "plan",
      type: FormType.Select,
      options: Object.entries(PlanType).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
  ];
  useEffect(() => {
    dispatch(fetchCompanyGroups());
  }, [dispatch]);

  useEffect(() => {
    setStateCompanyGroup(companyGroup);
  }, [companyGroup]);

  const onClickRecess = async (companyGroupId: number) => {
    await dispatch(recessCompanyGroup(companyId, companyGroupId));
    await dispatch(fetchCompanies());
  };

  const onClickSearch = async () => {
    const filter = companyGroup.filter((company) => {
      const planCondition = plan.includes("すべて")
        ? true
        : plan.includes(company.plan);
      const ongoingCondition = ongoing ? company.recessedAt === null : true;
      const recessedCondition = recessed ? company.recessedAt !== null : true;
      return planCondition && ongoingCondition && recessedCondition;
    });
    setStateCompanyGroup(filter);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" padding={4}>
        <Box display={"flex"} alignItems={"center"}>
          <Box>
            <InputLabel id="select-multiple-plan">プラン</InputLabel>
            <Select
              multiple
              value={plan}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setPlan((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [...Object.keys(PlanType), "すべて"];
                  } else if (
                    (event.target.value as string[]).length === 4 &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [...Object.keys(PlanType), "すべて"];
                  } else if ((event.target.value as string[]).length < 5) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-plan" />}
              style={{ width: "100px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return Object.entries(PlanType)
                    .filter(([key]) => (selected as string[]).includes(key))
                    .map(([, value]) => value)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox name="all" checked={plan.indexOf("すべて") > -1} />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {Object.entries(PlanType).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={plan.indexOf(key) !== -1} />
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box marginLeft={1}>
            <InputLabel id="select-multiple-ongoing">継続中</InputLabel>
            <Checkbox
              checked={ongoing}
              onChange={(event) => setOngoing(event.target.checked)}
            />
          </Box>
          <Box marginLeft={1}>
            <InputLabel id="select-multiple-ongoing">休会中</InputLabel>
            <Checkbox
              checked={recessed}
              onChange={(event) => setRecessed(event.target.checked)}
            />
          </Box>
          <Box marginLeft={1}>
            <Button variant="contained" color="primary" onClick={onClickSearch}>
              検索
            </Button>
          </Box>
        </Box>
        <CompanyGroupTable
          title={"企業グループ"}
          fullWidth
          rows={headers}
          data={stateCompanyGroup}
          formId="companyGroupForm"
          forms={forms}
          addFunc={addCompanyGroup}
          updateFunc={updateCompanyGroup}
          deleteFunc={(item) => deleteCompanyGroup(item?.companyGroupId)}
          addType={CreateCompanyGroupReq}
          updateType={UpdateCompanyGroupReq}
          values={[
            (s) => s.name,
            (s) => s.kana,
            (s) => EnumUtils.mapToEnum(PlanType, s.plan) || "未設定",
            (s) => (
              <Button onClick={() => onClickRecess(s.companyGroupId)}>
                {s.recessedAt ? "復帰" : "休会"}
              </Button>
            ),
          ]}
        />
      </Box>
    </>
  );
};

const CompanyGroupTable = styled<
  React.FC<
    CommonTableProps<
      CompanyGroupRes,
      CreateCompanyGroupReq,
      UpdateCompanyGroupReq
    >
  >
>(CommonTable)`
  margin-top: 24px;
`;
export default CompanyGroup;
