import { IsNotEmpty } from "class-validator";

export default class DeleteDriverReq {
  @IsNotEmpty({
    message: "",
  })
  readonly driverId: number;

  constructor(arg: { driverId: number }) {
    arg = arg || {};
    this.driverId = arg.driverId;
  }
}
