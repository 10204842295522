import { IsNotEmpty } from "class-validator";

export default class DeleteShopReq {
  @IsNotEmpty({
    message: "",
  })
  readonly shopId: number;

  constructor(arg: { shopId: number }) {
    arg = arg || {};
    this.shopId = arg.shopId;
  }
}
