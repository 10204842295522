import { combineReducers } from "redux";
import "react-redux";
import modals, { ModalState } from "./modals";
import account, { AccountState } from "./account";
import company, { CompanyState } from "./company";
import staff, { StaffState } from "./staff";
import shop, { ShopState } from "./shop";
import cast, { CastState } from "./cast";
import castName, { CastNameState } from "./castName";
import driver, { DriverState } from "./driver";
import discount, { DiscountState } from "./discount";
import cosplay, { CosplayState } from "./cosplay";
import option, { OptionState } from "./option";
import area, { AreaState } from "./area";
import course, { CourseState } from "./course";
import staffTask, { StaffTaskState } from "./staffTask";
import castShift, { CastShiftState } from "./castShift";
import messageBoard, { MessageBoardState } from "./messageBoard";
import driverShift, { DriverShiftState } from "./driverShift";
import guestCategory, { GuestCategoryState } from "./guestCategory";
import guest, { GuestState } from "./guest";
import hotel, { HotelState } from "./hotel";
import messageBoardCheck, { MessageBoardCheckState } from "./messageBoardCheck";
import additionalTime, { AdditionalTimeState } from "./additionalTime";
import medium, { MediumState } from "./medium";
import settingBookEmail, { SettingBookEmailState } from "./settingBookEmail";
import order, { OrderState } from "./order";
import notification, { NotificationState } from "./notification";
import payoff, { PayoffState } from "./payoff";
import castReport, { CastReportState } from "./castReport";
import nomination, { NominationState } from "./nomination";
import notelClass, { NotelClassState } from "./notelClass";
import castCategory, { CastCategoryState } from "./castCategory";
import journal, { JournalState } from "./journal";
import companyGroup, { CompanyGroupState } from "./companyGroup";
import guestPoint, { GuestPointState } from "redux/reducers/guestPoint";
import callHistory, { CallHistoryState } from "redux/reducers/callHistory";
import castRanking, { CastRankingState } from "redux/reducers/castRanking";
import castShiftFilter, {
  CastShiftFilterState,
} from "redux/reducers/castShiftFilter";
import accounting, { AccountingState } from "redux/reducers/accounting";
import driverSettingBookEmail, {
  DriverSettingBookEmailState,
} from "redux/reducers/driverSettingBookEmail";
import requestGuest, { RequestGuestState } from "redux/reducers/requestGuest";
import toast, { ToastState } from "redux/reducers/toast";
import guestNote, { GuestNoteState } from "redux/reducers/guestNote";
import recruitingMedia, {
  RecruitingMediaState,
} from "redux/reducers/recruitingMedia";

export type AppState = {
  modals: ModalState;
  accounting: AccountingState;
  account: AccountState;
  additionalTime: AdditionalTimeState;
  company: CompanyState;
  companyGroup: CompanyGroupState;
  staff: StaffState;
  staffTask: StaffTaskState;
  shop: ShopState;
  cast: CastState;
  castName: CastNameState;
  castShift: CastShiftState;
  course: CourseState;
  guestCategory: GuestCategoryState;
  guestPoint: GuestPointState;
  driver: DriverState;
  driverShift: DriverShiftState;
  discount: DiscountState;
  cosplay: CosplayState;
  option: OptionState;
  area: AreaState;
  medium: MediumState;
  messageBoard: MessageBoardState;
  guest: GuestState;
  hotel: HotelState;
  messageBoardCheck: MessageBoardCheckState;
  settingBookEmail: SettingBookEmailState;
  order: OrderState;
  payoff: PayoffState;
  notification: NotificationState;
  castReport: CastReportState;
  nomination: NominationState;
  notelClass: NotelClassState;
  castCategory: CastCategoryState;
  journal: JournalState;
  callHistory: CallHistoryState;
  castRanking: CastRankingState;
  castShiftFilter: CastShiftFilterState;
  driverSettingBookEmail: DriverSettingBookEmailState;
  requestGuest: RequestGuestState;
  guestNote: GuestNoteState;
  recruitingMedia: RecruitingMediaState;
  toast: ToastState;
};
declare module "react-redux" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends AppState {}
}
const reducer = combineReducers<AppState>({
  modals,
  account,
  accounting,
  additionalTime,
  company,
  companyGroup,
  staff,
  staffTask,
  shop,
  cast,
  castName,
  castShift,
  course,
  guestCategory,
  guestPoint,
  driver,
  driverShift,
  discount,
  cosplay,
  option,
  area,
  medium,
  messageBoard,
  guest,
  hotel,
  messageBoardCheck,
  settingBookEmail,
  order,
  payoff,
  notification,
  castReport,
  nomination,
  notelClass,
  castCategory,
  journal,
  callHistory,
  castRanking,
  castShiftFilter,
  driverSettingBookEmail,
  requestGuest,
  guestNote,
  recruitingMedia,
  toast,
});

export default reducer;
