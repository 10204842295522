import RequestUtils from "utils/RequestUtils";
import OptionRes from "types/res/option/OptionRes";
import CreateOptionReq from "types/req/option/CreateOptionReq";
import UpdateOptionReq from "types/req/option/UpdateOptionReq";
import DeleteOptionReq from "types/req/option/DeleteOptionReq";

export default class OptionApi {
  private constructor() {}

  static async findAll(
    companyId: number,
    shopId?: number
  ): Promise<OptionRes[]> {
    return await RequestUtils.getArray(
      OptionRes,
      `/option/findAll/${companyId}${shopId ? `?shopId=${shopId}` : ""}`
    );
  }

  static async create(companyId: number, data: CreateOptionReq) {
    return await RequestUtils.post(
      OptionRes,
      `/option/create/${companyId}`,
      data
    );
  }

  static async bulkInsert(companyId: number, data: CreateOptionReq[]) {
    return await RequestUtils.postArray(
      OptionRes,
      `/option/bulkInsert/${companyId}`,
      data
    );
  }

  static async bulkApprove(companyId: number, optionId: number) {
    return await RequestUtils.postVoid(
      `/option/bulkApprove/${companyId}/${optionId}`,
      {}
    );
  }

  static async update(
    companyId: number,
    optionId: number,
    data: UpdateOptionReq
  ): Promise<OptionRes> {
    return await RequestUtils.post(
      OptionRes,
      `/option/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteOptionReq) {
    return await RequestUtils.postVoid(`/option/delete/${companyId}`, data);
  }
}
