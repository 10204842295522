import RequestUtils from "utils/RequestUtils";
import CosplayRes from "types/res/cosplay/CosplayRes";
import CreateCosplayReq from "types/req/cosplay/CreateCosplayReq";
import UpdateCosplayReq from "types/req/cosplay/UpdateCosplayReq";
import DeleteCosplayReq from "types/req/cosplay/DeleteCosplayReq";

export default class CosplayApi {
  private constructor() {}

  static async findAll(
    companyId: number,
    shopId?: number
  ): Promise<CosplayRes[]> {
    return await RequestUtils.getArray(
      CosplayRes,
      `/cosplay/findAll/${companyId}${shopId ? `?shopId=${shopId}` : ""}`
    );
  }

  static async create(companyId: number, data: CreateCosplayReq) {
    return await RequestUtils.post(
      CosplayRes,
      `/cosplay/create/${companyId}`,
      data
    );
  }

  static async bulkInsert(companyId: number, data: CreateCosplayReq[]) {
    return await RequestUtils.postArray(
      CosplayRes,
      `/cosplay/bulkInsert/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    cosplayId: number,
    data: UpdateCosplayReq
  ): Promise<CosplayRes> {
    return await RequestUtils.post(
      CosplayRes,
      `/cosplay/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteCosplayReq) {
    return await RequestUtils.postVoid(`/cosplay/delete/${companyId}`, data);
  }
}
