import { reducerWithInitialState } from "typescript-fsa-reducers";
import guestActions from "redux/actions/guest";
import GuestRes from "types/res/guest/GuestRes";
export type GuestState = GuestRes[];

const initialState = [] as GuestState;

const guestReducer = reducerWithInitialState(initialState)
  .case(guestActions.addGuestAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(guestActions.updateGuestAction.done, (state, payload) => {
    return state.map((item) =>
      item.guestId === payload.params.guestId ? payload.result : item
    );
  })
  .case(guestActions.deleteGuestAction.done, (state, payload) => {
    return state.filter((item) => item.guestId !== payload.params.guestId);
  })
  .case(guestActions.fetchGuestAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(guestActions.searchGuestAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(guestActions.bulkInsertGuestAction.done, (state, payload) => {
    return [...state, ...payload.result];
  })
  .case(guestActions.sendMailGuestAction.done, (state, payload) => {
    return [
      ...state.map((guest) =>
        guest.guestId === payload.params.guestId
          ? { ...guest, isSentMail: true }
          : { ...guest }
      ),
    ];
  })
  .case(guestActions.simpleFetchGuestAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(guestActions.bulkDeleteGuestAction.done, (state, payload) => {
    return [
      ...state.filter(
        (item) =>
          !payload.params.map((param) => param.guestId).includes(item.guestId)
      ),
    ];
  });
export default guestReducer;
