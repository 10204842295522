import { Expose, Type } from "class-transformer";
import CompanyGroupRes from "types/res/companyGroup/CompanyGroupRes";

export default class CreateClientRes {
  @Type(() => CompanyGroupRes)
  @Expose()
  companyGroup!: CompanyGroupRes;
  @Expose() clientSecret!: string;
  @Expose() price!: number;
}
