import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  createStyles,
  InputLabel,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { fetchShops } from "redux/actions/shop";
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  Tooltip,
  Bar,
  Line,
  YAxis,
  BarChart,
} from "recharts";
import MathUtils from "utils/MathUtils";
import GuestAnalyticsGroupType from "types/enum/GuestAnalyticsGroupType";
import GuestAnalyticsGuestGroupApi from "api/GuestAnalyticsGuestGroupApi";
import { makeStyles } from "@material-ui/core/styles";
import { fetchMedium } from "redux/actions/medium";
type LtvData = {
  totalPrice: number;
  totalCastFee: number;
  totalCount: number;
  guestCount: number;
  activeGuestCount: number;
};
const GuestAnalyticsGuestGroupLtv = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const shops = useSelector((state) => state.shop);
  const media = useSelector((state) => state.medium);
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const guestGroups = Object.entries(GuestAnalyticsGroupType).map(
    ([key, value]) => ({ key, value })
  );
  const [ltvData, setLtvData] = useState<{
    [month: string]: {
      [guestGroup: string]: LtvData;
    };
  }>();
  const [adCost, setAdCost] = useState(0);
  const colorList = [
    "#801A86",
    "#8FE388",
    "#264653",
    "#2a9d8f",
    "#e9c46a",
    "#f4a261",
    "#e63946",
    "#f1faee",
    "#9b2226",
    "#d9ed92",
  ];
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchMedium(companyId));
  }, []);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    setAdCost(media.reduce((sum, medium) => sum + medium.monthlyCost, 0));
  }, [media]);

  const onClickSearch = async () => {
    setIsLoading(true);
    setLtvData({});
    const monthList = [
      DateTime.fromJSDate(endDate)
        .minus({ month: 9 })
        .toFormat(FORMAT_TYPE.YEAR_DAY),
      DateTime.fromJSDate(endDate)
        .minus({ month: 6 })
        .toFormat(FORMAT_TYPE.YEAR_DAY),
      DateTime.fromJSDate(endDate)
        .minus({ month: 3 })
        .toFormat(FORMAT_TYPE.YEAR_DAY),
      DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
    ];
    for (const date of monthList) {
      const result = await GuestAnalyticsGuestGroupApi.find(companyId, {
        endDate: date,
        shopIds: selectShops
          .filter((shopId) => shopId !== "すべて")
          .map(Number),
      });
      setLtvData((prev) => ({ ...prev, ...result }));
      setIsLoading(false);
    }
  };

  const withdrawalRate = (mediaData: LtvData[]) => {
    return (
      mediaData.reduce(
        (sum, data) => sum + (data.guestCount - data.activeGuestCount),
        0
      ) / mediaData.reduce((sum, data) => sum + data.guestCount, 0)
    );
  };

  const femaleSalaryRate = (mediaData: LtvData[]) => {
    const totalCastFee = mediaData.reduce(
      (sum, data) => sum + data.totalCastFee,
      0
    );
    const totalPrice = mediaData.reduce(
      (sum, data) => sum + data.totalPrice,
      0
    );
    return totalCastFee / totalPrice;
  };
  const ltv = (mediaData: LtvData[]) => {
    const threeMonthData = mediaData.slice(-3);
    const guestTotalSales = threeMonthData.reduce(
      (sum, data) => sum + data.totalPrice,
      0
    );
    const guestTotalCount = threeMonthData.reduce(
      (sum, data) => sum + data.totalCount,
      0
    );
    const sale90days = guestTotalSales / guestTotalCount;
    const customerLifeTime = 1 / withdrawalRate(threeMonthData);
    const revenue90days = sale90days * (1 - femaleSalaryRate(threeMonthData));
    return revenue90days * customerLifeTime - adCost / newGuestTotalCount;
  };

  let guestTotalSale = 0;
  let guestTotalCount = 0;
  let guestActiveTotalCount = 0;
  let castTotalFee = 0;
  let newGuestTotalCount = 0;
  Object.values(ltvData || {})
    .slice(-3)
    .forEach((data) =>
      Object.entries(data).forEach(([type, d]) => {
        guestTotalSale += d.totalPrice;
        guestTotalCount += d.totalCount;
        castTotalFee += d.totalCastFee;
        guestActiveTotalCount += d.activeGuestCount;
        if (type === "firstTimeUser") {
          newGuestTotalCount += d.totalCount;
        }
      })
    );

  const sale90days = MathUtils.roundToTwo(guestTotalSale / guestTotalCount);

  const revenue90days = MathUtils.roundToTwo(
    sale90days * (1 - castTotalFee / guestTotalSale)
  );

  const totalWithDrawRate = MathUtils.roundToTwo(
    (guestTotalCount - guestActiveTotalCount) / guestTotalCount
  );

  const customerLifeTime = MathUtils.roundToTwo(1 / totalWithDrawRate);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        margin={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <TextField
            type="date"
            label="終了日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
            onChange={(event) =>
              setEndDate(
                DateTime.fromISO(event.target.value as string).toJSDate()
              )
            }
          />
          <Box>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={selectShops}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectShops((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length === shops.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= shops.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectShops.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={onClickSearch}
          >
            検索
          </Button>
        </Box>
        {!isLoading && ltvData && (
          <Box display="flex">
            <Box marginX={2}>
              <Typography>
                LTV:
                {MathUtils.roundToTwo(
                  revenue90days * customerLifeTime - adCost / newGuestTotalCount
                )}
                円
              </Typography>
              <Typography>
                LTS:
                {MathUtils.roundToTwo(
                  sale90days * customerLifeTime - adCost / newGuestTotalCount
                )}
                円
              </Typography>
              <Typography>顧客生涯期間:{customerLifeTime}</Typography>
              <Typography>90日あたりの売上:{sale90days}円</Typography>
              <Typography>90日あたりの収益:{revenue90days}円</Typography>
            </Box>
          </Box>
        )}
      </Box>
      {isLoading ? (
        <Box display={"flex"} justifyContent={"center"} margin={2}>
          <CircularProgress />
        </Box>
      ) : (
        ltvData && (
          <>
            <Box display="flex" justifyContent={"center"}>
              <Box display="flex" flexDirection={"column"}>
                <Typography style={{ fontWeight: "bold", margin: "5px" }}>
                  LTVと推移人数
                </Typography>
                <ComposedChart
                  width={800}
                  height={400}
                  data={Object.entries(ltvData)
                    .sort(([a, a1], [b, b1]) => (a > b ? 1 : -1))
                    .flatMap(([month, monthData]) => {
                      let t = {};
                      Object.entries(monthData).forEach(
                        ([guestGroup, data]) => {
                          const name = (GuestAnalyticsGroupType as any)[
                            guestGroup
                          ];
                          if (!name) return;
                          t = {
                            ...t,
                            [`${name}LTV`]: MathUtils.roundToTwo(
                              (data.totalPrice / data.guestCount) *
                                (data.totalCount / data.guestCount) || 0
                            ),
                            [`${name}人数`]: data.guestCount,
                          };
                        }
                      );
                      return {
                        month,
                        ...t,
                      };
                    })}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" vertical={false} />
                  <XAxis
                    dataKey="month"
                    interval={1}
                    tickLine={false}
                    axisLine={{ stroke: "#f5f5f5" }}
                  />
                  <Tooltip />
                  {guestGroups.map((guestGroup, index) => (
                    <>
                      <Bar
                        dataKey={`${guestGroup.value}LTV`}
                        yAxisId="left"
                        stackId={"a"}
                        fill={colorList[Number(String(index).slice(-1))]}
                      />
                      <Line
                        dataKey={`${guestGroup.value}人数`}
                        yAxisId="right"
                        type="monotone"
                        stroke={colorList[Number(String(index).slice(-1))]}
                      />
                    </>
                  ))}
                  <YAxis yAxisId="left" width={150} />
                  <YAxis yAxisId="right" orientation="right" />
                </ComposedChart>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography style={{ fontWeight: "bold", margin: "5px" }}>
                  月別総売上
                </Typography>
                <BarChart
                  width={800}
                  height={400}
                  data={Object.entries(ltvData)
                    .sort(([a, a1], [b, b1]) => (a > b ? 1 : -1))
                    .flatMap(([month, monthData]) => {
                      let t = {};
                      Object.entries(monthData).forEach(
                        ([guestGroup, data]) => {
                          const name = (GuestAnalyticsGroupType as any)[
                            guestGroup
                          ];
                          if (!name) return;
                          t = {
                            ...t,
                            [`${name}`]: MathUtils.roundToTwo(data.totalPrice),
                          };
                        }
                      );
                      return {
                        month,
                        ...t,
                      };
                    })}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="month"
                      interval={1}
                      tickLine={false}
                      axisLine={{ stroke: "#f5f5f5" }}
                    />
                    <YAxis width={150} />
                    <Tooltip />
                    {guestGroups.map((guestGroup, index) => (
                      <>
                        <Bar
                          dataKey={`${guestGroup.value}`}
                          stackId={"a"}
                          fill={colorList[Number(String(index).slice(-1))]}
                        />
                      </>
                    ))}
                  </>
                </BarChart>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" margin={2}>
              <Typography style={{ fontWeight: "bold", margin: "5px" }}>
                顧客分布表
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ランク名</TableCell>
                    <TableCell>LTV</TableCell>
                    <TableCell>現役人数</TableCell>
                    <TableCell>離脱人数</TableCell>
                    <TableCell>離脱率</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guestGroups.map((guestGroup) => {
                    const mediaData = Object.values(ltvData)
                      .map((data) => data[guestGroup.key])
                      .filter(Boolean);
                    return (
                      <TableRow key={guestGroup.key}>
                        <TableCell>{guestGroup.value}</TableCell>
                        <TableCell>
                          {MathUtils.roundToTwo(ltv(mediaData))}
                        </TableCell>
                        <TableCell>
                          {mediaData.reduce(
                            (sum, data) => sum + data.activeGuestCount,
                            0
                          )}
                        </TableCell>
                        <TableCell>
                          {mediaData.reduce(
                            (sum, data) =>
                              sum + (data.guestCount - data.activeGuestCount),
                            0
                          )}
                        </TableCell>
                        <TableCell>
                          {isFinite(
                            MathUtils.roundToTwo(
                              withdrawalRate(mediaData) * 100
                            )
                          )
                            ? MathUtils.roundToTwo(
                                withdrawalRate(mediaData) * 100
                              )
                            : "∞"}
                          %
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <Box display="flex" flexDirection="column" margin={2}>
              <Typography
                variant={"subtitle1"}
                style={{ fontWeight: "bold", margin: "5px" }}
              >
                LTV 年間データ
              </Typography>
              <Typography style={{ fontWeight: "bold", margin: "5px" }}>
                ランク別顧客月間累計購入金額
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ランク名</TableCell>
                    <TableCell>年間累計</TableCell>
                    {Object.keys(ltvData)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .map((month) => (
                        <TableCell key={month}>{month}</TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guestGroups.map((guestGroup) => {
                    const mediaData = Object.values(ltvData)
                      .map((data) => data[guestGroup.key])
                      .filter(Boolean);
                    return (
                      <TableRow key={guestGroup.key}>
                        <TableCell>{guestGroup.value}</TableCell>
                        <TableCell>
                          {mediaData.reduce(
                            (sum, data) => sum + data.totalPrice,
                            0
                          )}
                        </TableCell>
                        {Object.keys(ltvData)
                          .sort((a, b) => (a > b ? 1 : -1))
                          .map((month) => (
                            <TableCell key={`totalPrice_${month}`}>
                              {ltvData[month]?.[guestGroup.key]?.totalPrice ||
                                0}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <Box display="flex" flexDirection="column" margin={2}>
              <Typography style={{ fontWeight: "bold", margin: "5px" }}>
                ランク別顧客月間平均購入金額
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ランク名</TableCell>
                    <TableCell>年間累計</TableCell>
                    {Object.keys(ltvData)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .map((month) => (
                        <TableCell key={month}>{month}</TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guestGroups.map((guestGroup) => {
                    const mediaData = Object.values(ltvData)
                      .map((data) => data[guestGroup.key])
                      .filter(Boolean);
                    return (
                      <TableRow key={guestGroup.key}>
                        <TableCell>{guestGroup.value}</TableCell>
                        <TableCell>
                          {MathUtils.roundToTwo(
                            mediaData.reduce(
                              (sum, data) =>
                                sum +
                                (isNaN(data.totalPrice / data.guestCount)
                                  ? 0
                                  : data.totalPrice / data.guestCount),
                              0
                            )
                          )}
                        </TableCell>
                        {Object.keys(ltvData)
                          .sort((a, b) => (a > b ? 1 : -1))
                          .map((month) => (
                            <TableCell key={`averagePrice_${month}`}>
                              {MathUtils.roundToTwo(
                                (ltvData[month]?.[guestGroup.key]?.totalPrice ||
                                  0) /
                                  (ltvData[month]?.[guestGroup.key]
                                    ?.guestCount || 0) || 0
                              )}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <Box display="flex" flexDirection="column" margin={2}>
              <Typography style={{ fontWeight: "bold", margin: "5px" }}>
                ランク別顧客平均利用回数
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ランク名</TableCell>
                    <TableCell>年間累計</TableCell>
                    {Object.keys(ltvData)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .map((month) => (
                        <TableCell key={month}>{month}</TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guestGroups.map((guestGroup) => {
                    const mediaData = Object.values(ltvData)
                      .map((data) => data[guestGroup.key])
                      .filter(Boolean);
                    return (
                      <TableRow key={guestGroup.key}>
                        <TableCell>{guestGroup.value}</TableCell>
                        <TableCell>
                          {MathUtils.roundToTwo(
                            mediaData.reduce(
                              (sum, data) =>
                                sum +
                                (isNaN(data.totalCount / data.guestCount)
                                  ? 0
                                  : data.totalCount / data.guestCount),
                              0
                            )
                          )}
                        </TableCell>
                        {Object.keys(ltvData)
                          .sort((a, b) => (a > b ? 1 : -1))
                          .map((month) => (
                            <TableCell key={`averageCount_${month}`}>
                              {MathUtils.roundToTwo(
                                (ltvData[month]?.[guestGroup.key]?.totalCount ||
                                  0) /
                                  (ltvData[month]?.[guestGroup.key]
                                    ?.guestCount || 0) || 0
                              )}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <Box display="flex" flexDirection="column" margin={2}>
              <Typography style={{ fontWeight: "bold", margin: "5px" }}>
                ランク別顧客月間平均単価
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ランク名</TableCell>
                    <TableCell>年間平均</TableCell>
                    {Object.keys(ltvData)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .map((month) => (
                        <TableCell key={month}>{month}</TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guestGroups.map((guestGroup) => {
                    const mediaData = Object.values(ltvData)
                      .map((data) => data[guestGroup.key])
                      .filter(Boolean);
                    return (
                      <TableRow key={guestGroup.key}>
                        <TableCell>{guestGroup.value}</TableCell>
                        <TableCell>
                          {MathUtils.roundToTwo(
                            mediaData.reduce(
                              (sum, data) =>
                                sum +
                                (isNaN(data.totalPrice / data.totalCount)
                                  ? 0
                                  : data.totalPrice / data.totalCount),
                              0
                            ) / 12
                          )}
                        </TableCell>
                        {Object.keys(ltvData)
                          .sort((a, b) => (a > b ? 1 : -1))
                          .map((month) => (
                            <TableCell key={`averagePrice_${month}`}>
                              {MathUtils.roundToTwo(
                                (ltvData[month]?.[guestGroup.key]?.totalPrice ||
                                  0) /
                                  (ltvData[month]?.[guestGroup.key]
                                    ?.totalCount || 0) || 0
                              )}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </>
        )
      )}
    </Box>
  );
};
export default GuestAnalyticsGuestGroupLtv;
