import { reducerWithInitialState } from "typescript-fsa-reducers";
import courseActions from "redux/actions/course";
import CourseRes from "types/res/course/CourseRes";
export type CourseState = CourseRes[];

const initialState = [] as CourseState;

const courseReducer = reducerWithInitialState(initialState)
  .case(courseActions.addCourseAction.done, (state, payload) => {
    return [...state, ...payload.result];
  })
  .case(courseActions.updateCourseAction.done, (state, payload) => {
    return state.map((item) =>
      item.courseId === payload.params.courseId ? payload.result : item
    );
  })
  .case(courseActions.deleteCourseAction.done, (state, payload) => {
    return state.filter((item) => item.courseId !== payload.params.courseId);
  })
  .case(courseActions.fetchCourseAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(
    courseActions.fetchWithPaginationCourseAction.done,
    (state, payload) => {
      return [...payload.result];
    }
  );

export default courseReducer;
