import RequestUtils from "utils/RequestUtils";
import SettingCountRes from "types/res/setting/SettingCountRes";

export default class SettingApi {
  private constructor() {}

  static async countAll(companyId: number): Promise<SettingCountRes> {
    return await RequestUtils.get(
      SettingCountRes,
      `/setting/countAll/${companyId}`
    );
  }
}
