import RequestUtils from "utils/RequestUtils";

export default class GuestAnalyticsRecruitingMediaLtvApi {
  static async find(
    companyId: number,
    data: { endDate: string; shopIds?: number[] }
  ) {
    return await RequestUtils.postPlain(
      `/guestAnalyticsRecruitingMediaLtv/find/${companyId}`,
      data
    );
  }
}
