import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import RecruitingMediaApi from "api/RecruitingMediaApi";
import RecruitingMediaRes from "types/res/recruitingMedia/RecruitingMediaRes";
import UpdateRecruitingMediaReq from "types/req/recruitingMedia/UpdateRecruitingMediaReq";
import CreateRecruitingMediaReq from "types/req/recruitingMedia/CreateRecruitingMediaReq";
import DeleteRecruitingMediaReq from "types/req/recruitingMedia/DeleteRecruitingMediaReq";

const actionCreator = actionCreatorFactory();
const addRecruitingMediaAction = actionCreator.async<
  CreateRecruitingMediaReq,
  RecruitingMediaRes,
  { code: number; description: string }
>("ADD_RECRUITING_MEDIA");

export const addRecruitingMedia =
  (companyId: number, req: CreateRecruitingMediaReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addRecruitingMediaAction.started({ ...req }));

    try {
      const recruitingMedia = new CreateRecruitingMediaReq({ ...req });
      const result = await RecruitingMediaApi.create(
        companyId,
        recruitingMedia
      );
      dispatch(
        addRecruitingMediaAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addRecruitingMediaAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchRecruitingMediaAction = actionCreator.async<
  {},
  RecruitingMediaRes[],
  { code: number; description: string }
>("FETCH_RECRUITING_MEDIA");

export const fetchRecruitingMedia =
  (companyId: number, shopIds?: number[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchRecruitingMediaAction.started([]));
    try {
      const result = await RecruitingMediaApi.findAll(companyId, shopIds);
      dispatch(
        fetchRecruitingMediaAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchRecruitingMediaAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateRecruitingMediaAction = actionCreator.async<
  UpdateRecruitingMediaReq,
  RecruitingMediaRes,
  { code: number; description: string }
>("UPDATE_RECRUITING_MEDIA");

export const updateRecruitingMedia =
  (companyId: number, req: UpdateRecruitingMediaReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateRecruitingMediaAction.started({ ...req }));

    try {
      const updateRecruitingMedia = new UpdateRecruitingMediaReq({ ...req });
      const result = await RecruitingMediaApi.update(
        companyId,
        updateRecruitingMedia
      );
      dispatch(
        updateRecruitingMediaAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateRecruitingMediaAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteRecruitingMediaAction = actionCreator.async<
  DeleteRecruitingMediaReq,
  {},
  { code: number; description: string }
>("DELETE_RECRUITING_MEDIA");

export const deleteRecruitingMedia =
  (companyId: number, req: DeleteRecruitingMediaReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteRecruitingMediaAction.started({ ...req }));

    try {
      const recruitingMedia = new DeleteRecruitingMediaReq({ ...req });
      const result = await RecruitingMediaApi.delete(
        companyId,
        recruitingMedia
      );
      dispatch(
        deleteRecruitingMediaAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteRecruitingMediaAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const recruitingMediaActions = {
  fetchRecruitingMediaAction,
  addRecruitingMediaAction,
  updateRecruitingMediaAction,
  deleteRecruitingMediaAction,
};

export default recruitingMediaActions;
