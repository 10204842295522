import { IsNotEmpty } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";

export default class DeleteCastNameReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly castNameId: number;

  constructor(arg: { castNameId: number }) {
    arg = arg || {};
    this.castNameId = arg.castNameId;
  }
}
