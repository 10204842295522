import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import styled from "styled-components";
import StaffRes from "types/res/staff/StaffRes";
import {
  addStaff,
  deleteStaff,
  fetchStaff,
  updateStaff,
} from "redux/actions/staff";
import StaffRole from "types/enum/StaffRole";
import { FormType } from "components/FormField";
import CreateStaffReq from "types/req/staff/CreateStaffReq";
import UpdateStaffReq from "types/req/staff/UpdateStaffReq";
import EnumUtils from "../../utils/EnumUtils";
import { fetchShops } from "redux/actions/shop";

const headers: { key: keyof StaffRes; label: string }[] = [
  { key: "name", label: "名前" },
  { key: "email", label: "メールアドレス" },
  { key: "tel", label: "電話番号" },
  { key: "role", label: "権限" },
  { key: "sort", label: "並び順" },
];

const Staff: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const loginStaff = useSelector((state) => state.account.staff);
  const staff = useSelector((state) => state.staff);
  const [roles, setRoles] = useState<any[]>([]);
  const [filterStaff, setFilterStaff] = useState<StaffRes[]>([]);

  useEffect(() => {
    dispatch(fetchStaff(companyId));
    dispatch(fetchShops(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    const roleIndex = Object.keys(StaffRole).findIndex(
      (role) => role === loginStaff.role
    );
    setRoles(
      Object.entries(StaffRole)
        .map(([key, value], index) => {
          if (roleIndex <= index) {
            return { key, value };
          }
        })
        .filter(Boolean)
    );
  }, [loginStaff]);

  useEffect(() => {
    if (loginStaff.companyId === 1) {
      setFilterStaff(staff);
    } else {
      const roleIndex = Object.keys(StaffRole).findIndex(
        (role) => role === loginStaff.role
      );
      const roles = Object.keys(StaffRole).slice(roleIndex);
      setFilterStaff(staff.filter((s) => roles.includes(s.role)));
    }
  }, [staff, loginStaff]);

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "メールアドレス",
      key: "email",
      type: FormType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
    {
      label: "パスワード",
      key: "password",
      type: FormType.Text,
    },
    {
      label: "ロール",
      key: "role",
      type: FormType.Select,
      options: roles?.map((role) => ({
        id: role?.key,
        name: role?.value,
      })),
    },
    {
      label: "並び順",
      key: "sort",
      type: FormType.Number,
    },
  ];

  return (
    <>
      <StaffTable
        title={"スタッフ"}
        formId="staffForm"
        forms={forms}
        rows={headers}
        data={filterStaff}
        addFunc={(formData) => addStaff(companyId, formData)}
        updateFunc={(formData) => updateStaff(companyId, formData)}
        deleteFunc={(item) => deleteStaff(companyId, { staffId: item.staffId })}
        addType={CreateStaffReq}
        updateType={UpdateStaffReq}
        values={[
          (s) => s.name,
          (s) => s.email,
          (s) => s.tel || "未設定",
          (s) => EnumUtils.mapToEnum(StaffRole, s.role) || "未設定",
          (s) => s.sort || "未設定",
        ]}
      />
    </>
  );
};

const StaffTable = styled<
  React.FC<CommonTableProps<StaffRes, CreateStaffReq, UpdateStaffReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default Staff;
