import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import NotelClassRes from "types/res/notelClass/NotelClassRes";

export default class AdditionalTimeRes {
  @Expose() readonly additionalTimeId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly totalFee!: number;
  @Expose() readonly shopFee!: number;
  @Expose() readonly castFee!: number;
  @Expose() readonly time!: number;
  @Expose() readonly name!: string;
  @Expose() readonly isGuestShow!: boolean;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
  @Type(() => NotelClassRes)
  @Expose()
  readonly notelClasses!: NotelClassRes[];
}
