import { Expose } from "class-transformer";

export default class SettingCountRes {
  @Expose() readonly companyGroup!: number;
  @Expose() readonly company!: number;
  @Expose() readonly shop!: number;
  @Expose() readonly staff!: number;
  @Expose() readonly castCategory!: number;
  @Expose() readonly nomination!: number;
  @Expose() readonly notelClass!: number;
  @Expose() readonly course!: number;
  @Expose() readonly additionalTime!: number;
  @Expose() readonly option!: number;
  @Expose() readonly guestCategory!: number;
  @Expose() readonly guest!: number;
  @Expose() readonly guestPoint!: number;
  @Expose() readonly settingBookEmail!: number;
  @Expose() readonly cast!: number;
  @Expose() readonly castName!: number;
  @Expose() readonly driver!: number;
  @Expose() readonly discount!: number;
  @Expose() readonly medium!: number;
  @Expose() readonly area!: number;
  @Expose() readonly hotel!: number;
  @Expose() readonly cosplay!: number;
  @Expose() readonly requestGuest!: number;
  @Expose() readonly notification!: number;
  @Expose() readonly guestNote!: number;
  @Expose() readonly recruitingMedia!: number;
}
