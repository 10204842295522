import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import MessageBoardRes from "types/res/messageBoard/MessageBoardRes";
import UpdateMessageBoardReq from "types/req/messageBoard/UpdateMessageBoardReq";
import CreateMessageBoardReq from "types/req/messageBoard/CreateMessageBoardReq";
import DeleteMessageBoardReq from "types/req/messageBoard/DeleteMessageBoardReq";
import MessageBoardApi from "api/MessageBoardApi";

const actionCreator = actionCreatorFactory();
const addMessageBoardAction = actionCreator.async<
  CreateMessageBoardReq,
  MessageBoardRes,
  { code: number; description: string }
>("ADD_MESSAGE_BOARD");

export const addMessageBoard =
  (companyId: number, req: CreateMessageBoardReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addMessageBoardAction.started({ ...req }));

    try {
      const messageBoard = new CreateMessageBoardReq({ ...req });
      const result = await MessageBoardApi.create(companyId, messageBoard);
      dispatch(
        addMessageBoardAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addMessageBoardAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchMessageBoardAction = actionCreator.async<
  {},
  MessageBoardRes[],
  { code: number; description: string }
>("FETCH_MESSAGE_BOARD");

export const fetchMessageBoard =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchMessageBoardAction.started([]));

    try {
      const result = await MessageBoardApi.findAll(companyId);
      dispatch(
        fetchMessageBoardAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchMessageBoardAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateMessageBoardAction = actionCreator.async<
  UpdateMessageBoardReq,
  MessageBoardRes,
  { code: number; description: string }
>("UPDATE_MESSAGE_BOARD");

export const updateMessageBoard =
  (companyId: number, req: UpdateMessageBoardReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateMessageBoardAction.started({ ...req }));

    try {
      const updateMessageBoard = new UpdateMessageBoardReq({ ...req });
      const result = await MessageBoardApi.update(
        companyId,
        updateMessageBoard
      );
      dispatch(
        updateMessageBoardAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateMessageBoardAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteMessageBoardAction = actionCreator.async<
  DeleteMessageBoardReq,
  {},
  { code: number; description: string }
>("DELETE_MESSAGE_BOARD");

export const deleteMessageBoard =
  (companyId: number, req: DeleteMessageBoardReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteMessageBoardAction.started({ ...req }));

    try {
      const messageBoard = new DeleteMessageBoardReq({ ...req });
      const result = await MessageBoardApi.delete(companyId, messageBoard);
      dispatch(
        deleteMessageBoardAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteMessageBoardAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const messageBoardActions = {
  fetchMessageBoardAction,
  addMessageBoardAction,
  updateMessageBoardAction,
  deleteMessageBoardAction,
};

export default messageBoardActions;
