import {
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StaffApi from "api/StaffApi";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
const Container = styled.div`
  margin: 50px 10px;

  > * {
    margin-bottom: ${({ theme: { spacing } }) => spacing(2)}px;
  }
`;

const SpResetPassword = () => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const queryString = new URLSearchParams(useLocation().search);
  const resetToken = queryString.get("token");
  const [staffId, setStaffId] = useState<number>();
  const [isSent, setIsSent] = useState(false);

  const onChangePassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(event.target.value);
  };

  const onClickPostButton = async () => {
    if (!password || !staffId) return;

    setLoading(true);
    await StaffApi.resetPassword(staffId, password);
    setLoading(false);
    setIsSent(true);
  };
  useEffect(() => {
    const getStaff = async () => {
      try {
        const result = await StaffApi.findByResetToken(resetToken || "");
        setStaffId(result.staffId);
      } catch {
        history.push("/resetPassword/send");
      }
    };
    getStaff();
  }, []);

  return (
    <Container>
      <Typography>新しいパスワードを入力してください。</Typography>
      <TextField
        label="パスワード"
        required
        type="password"
        autoComplete="current-password"
        variant="filled"
        fullWidth
        onChange={onChangePassword}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading || isSent}
        onClick={onClickPostButton}
      >
        {loading ? <CircularProgress color="inherit" size={24} /> : "送信"}
      </Button>
      {isSent && (
        <Alert severity="success">
          <AlertTitle>パスワードを再設定しました。</AlertTitle>
          <Link href="/login">ログインページ</Link>
          へ遷移してログインしてください。
        </Alert>
      )}
    </Container>
  );
};
export default SpResetPassword;
