import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import CompanyGroupApi from "api/CompanyGroupApi";
import CompanyGroupRes from "types/res/companyGroup/CompanyGroupRes";
import UpdateCompanyGroupReq from "types/req/companyGroup/UpdateCompanyGroupReq";
import CreateCompanyGroupReq from "types/req/companyGroup/CreateCompanyGroupReq";
import DeleteCompanyGroupReq from "types/req/companyGroup/DeleteCompanyGroupReq";

const actionCreator = actionCreatorFactory();
const addCompanyGroupAction = actionCreator.async<
  CreateCompanyGroupReq,
  CompanyGroupRes,
  { code: number; description: string }
>("ADD_COMPANY_GROUP");
export const addCompanyGroup =
  (req: CreateCompanyGroupReq) => async (dispatch: Dispatch<Action>) => {
    dispatch(addCompanyGroupAction.started({ ...req }));

    try {
      const companyGroup = new CreateCompanyGroupReq({ ...req });
      const result = await CompanyGroupApi.create(companyGroup);
      dispatch(
        addCompanyGroupAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addCompanyGroupAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCompanyGroupsAction = actionCreator.async<
  {},
  CompanyGroupRes[],
  { code: number; description: string }
>("FETCH_COMPANY_GROUP");

export const fetchCompanyGroups = () => async (dispatch: Dispatch<Action>) => {
  dispatch(fetchCompanyGroupsAction.started([]));

  try {
    const result = await CompanyGroupApi.findAll();

    dispatch(
      fetchCompanyGroupsAction.done({
        params: {},
        result,
      })
    );
    return result;
  } catch (error) {
    dispatch(
      fetchCompanyGroupsAction.failed({
        params: {},
        // @ts-ignore
        error: { code: error.code, description: error.description },
      })
    );
    return error;
  }
};

const updateCompanyGroupAction = actionCreator.async<
  UpdateCompanyGroupReq,
  CompanyGroupRes,
  { code: number; description: string }
>("UPDATE_COMPANY_GROUP");

export const updateCompanyGroup =
  (req: UpdateCompanyGroupReq) => async (dispatch: Dispatch<Action>) => {
    dispatch(updateCompanyGroupAction.started({ ...req }));

    try {
      const updateCompanyGroup = new UpdateCompanyGroupReq({ ...req });
      const result = await CompanyGroupApi.update(
        req.companyGroupId,
        updateCompanyGroup
      );

      dispatch(
        updateCompanyGroupAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateCompanyGroupAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const recessCompanyGroupAction = actionCreator.async<
  { companyId: number; companyGroupId: number },
  {},
  { code: number; description: string }
>("RECESS_COMPANY_GROUP");

export const recessCompanyGroup =
  (companyId: number, companyGroupId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(recessCompanyGroupAction.started({ companyGroupId, companyId }));

    try {
      const companyGroup = new DeleteCompanyGroupReq({ companyGroupId });
      const result = await CompanyGroupApi.recess(companyId, companyGroup);
      dispatch(
        recessCompanyGroupAction.done({
          params: { companyId, companyGroupId },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        recessCompanyGroupAction.failed({
          params: { companyId, companyGroupId },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteCompanyGroupAction = actionCreator.async<
  { companyGroupId: number },
  {},
  { code: number; description: string }
>("DELETE_COMPANY_GROUP");

export const deleteCompanyGroup =
  (companyGroupId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(deleteCompanyGroupAction.started({ companyGroupId }));

    try {
      const companyGroup = new DeleteCompanyGroupReq({ companyGroupId });
      const result = await CompanyGroupApi.delete(companyGroup);
      dispatch(
        deleteCompanyGroupAction.done({
          params: { companyGroupId },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteCompanyGroupAction.failed({
          params: { companyGroupId },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const companyGroupActions = {
  fetchCompanyGroupsAction,
  addCompanyGroupAction,
  updateCompanyGroupAction,
  recessCompanyGroupAction,
  deleteCompanyGroupAction,
};

export default companyGroupActions;
