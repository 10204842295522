export enum BusinessCategoryType {
  mensAesthetic = "メンズエステ（非風俗)",
  deliveryCabaret = "デリキャバ（非風俗)",
  deliveryCompanion = "コンパニオン派遣",
  deliveryHealth = "デリヘル",
  beautyTreatmentSalon = "回春エステ・サロン",
  hotelHealth = "ホテヘル",
  soap = "ソープ",
  health = "ヘルス",
  other = "その他",
}
