import { DataValidationError } from "constants/DataValidationError";
import {
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";

export default class UpdateAreaReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly areaId: number;

  @MaxLength(Spec.maxLength.area.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.area.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @MaxLength(Spec.maxLength.area.kana, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.area.kana,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  kana!: string;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  totalFee!: number;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  shopFee!: number;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  castFee!: number;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  time!: number;

  @IsOptional()
  @IsNumber()
  sort!: number | null;

  @IsOptional()
  @IsBoolean()
  isGuestShow!: boolean;

  @IsArray()
  shops!: number[];

  constructor(arg: {
    areaId: number;
    name: string;
    kana: string;
    totalFee: number;
    shopFee: number;
    castFee: number;
    time: number;
    sort: number | null;
    isGuestShow: boolean;
    shops: number[];
  }) {
    arg = arg || {};
    this.areaId = arg.areaId;
    this.name = arg.name;
    this.kana = arg.kana;
    this.totalFee = arg.totalFee;
    this.shopFee = arg.shopFee;
    this.castFee = arg.castFee;
    this.time = arg.time;
    this.sort = arg.sort;
    this.isGuestShow = arg.isGuestShow;
    this.shops = arg.shops;
  }
}
