import { reducerWithInitialState } from "typescript-fsa-reducers";
import optionActions from "redux/actions/option";
import OptionRes from "types/res/option/OptionRes";
export type OptionState = OptionRes[];

const initialState = [] as OptionState;

const optionReducer = reducerWithInitialState(initialState)
  .case(optionActions.addOptionAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(optionActions.updateOptionAction.done, (state, payload) => {
    return state.map((item) =>
      item.optionId === payload.params.optionId ? payload.result : item
    );
  })
  .case(optionActions.deleteOptionAction.done, (state, payload) => {
    return state.filter((item) => item.optionId !== payload.params.optionId);
  })
  .case(optionActions.fetchOptionAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(optionActions.bulkInsertOptionAction.done, (state, payload) => {
    return [...state, ...payload.result];
  });
export default optionReducer;
