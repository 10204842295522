import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import OrderApi from "api/OrderApi";

type Props = {
  formData: any;
  onChange(key: string): any;
  onChangePrice(key: string): any;
  onChangeCheckBox(key: string): any;
};

const OrderDriver: React.FC<Props> = ({
  formData,
  onChange,
  onChangePrice,
  onChangeCheckBox,
}) => {
  const companyId = useSelector((state) => state.account.staff.companyId);
  const driverShifts = useSelector((state) => state.driverShift);
  useEffect(() => {
    onChangePrice("outwardDriver")(
      driverShifts.find(
        (driverShift) => driverShift.driverId === formData?.outwardDriverId
      )?.driver || null
    );
  }, [formData?.outwardDriverId]);

  useEffect(() => {
    onChangePrice("returnDriver")(
      driverShifts.find(
        (driverShift) => driverShift.driverId === formData?.returnDriverId
      )?.driver || null
    );
  }, [formData?.returnDriverId]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  const onClickReloadDriver = async () => {
    const order = await OrderApi.findOne(companyId, formData?.orderId);
    onChangePrice("outwardDriverId")(order?.outwardDriverId || null);
    onChangePrice("returnDriverId")(order?.returnDriverId || null);
  };

  return (
    <Box display="flex" flexDirection="column" marginTop={2}>
      <Box display="flex" alignItems="center">
        <Typography variant="h5" component="h5">
          配車情報
        </Typography>
        {formData?.orderId && (
          <Button
            variant="contained"
            className={classes.button}
            color="secondary"
            onClick={async (event) => {
              event.persist();
              await onClickReloadDriver();
            }}
          >
            配車情報更新
          </Button>
        )}
      </Box>
      <Box display="flex">
        <Box display="flex" alignItems="center">
          <InputLabel htmlFor={"outwardDriverId"}>送Dr</InputLabel>
          <Select
            inputProps={{
              name: "outwardDriverId",
              id: "outwardDriverId",
            }}
            style={{ width: 100 }}
            value={formData["outwardDriverId"] || ""}
            onChange={onChange("outwardDriverId")}
          >
            <option aria-label="None" value="" />
            {driverShifts?.map((driverShift) => (
              <MenuItem
                value={driverShift?.driverId}
                key={driverShift?.driverId}
              >
                {driverShift?.driver?.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display="flex">
          <InputLabel htmlFor={"returnDriverId"}>迎Dr</InputLabel>
          <Select
            inputProps={{
              name: "returnDriverId",
              id: "returnDriverId",
            }}
            style={{ width: 100 }}
            value={formData["returnDriverId"] || ""}
            onChange={onChange("returnDriverId")}
          >
            <option aria-label="None" value="" />
            {driverShifts?.map((driverShift) => (
              <MenuItem
                value={driverShift?.driverId}
                key={driverShift?.driverId}
              >
                {driverShift?.driver?.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography>送迎必要有無</Typography>
        <FormControlLabel
          control={
            <Checkbox
              onChange={onChangeCheckBox("isUseOutward")}
              checked={Boolean(formData["isUseOutward"])}
            />
          }
          label="送"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={onChangeCheckBox("isUseReturn")}
              checked={Boolean(formData["isUseReturn"])}
            />
          }
          label="迎"
        />
      </Box>
    </Box>
  );
};
export default OrderDriver;
