import React from "react";
import styled from "styled-components";
import Tooltip from "rc-tooltip";
import {
  Box,
  Button,
  createStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ShiftStatus from "../../../types/enum/ShiftStatus";
import EnumUtils from "../../../utils/EnumUtils";
import { updateCastShift } from "redux/actions/castShift";
import { useDispatch, useSelector } from "react-redux";
import { CastScheduleItemColor } from "types/enum/CastScheduleItemColor";
import ShopRes from "types/res/shop/ShopRes";

const Item = styled.div<{ color: string }>`
  background-color: ${(props) => props.color} !important;
  border: 1px solid ${(props) => props.color} !important;
  border-radius: 4px;
`;
type Props = {
  item: any;
  itemContext: any;
  getItemProps: any;
  onClick: any;
};
const CastScheduleShiftItem: React.FC<Props> = ({
  item,
  itemContext,
  getItemProps,
  onClick,
}) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  const updateScheduleColor = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    dispatch(
      updateCastShift(companyId, {
        ...item?.castShift,
        shops: item?.castShift.shops.map((shop: ShopRes) => shop.shopId),
        scheduleItemColor: event.target.value,
      })
    );
  };
  return (
    <>
      <Tooltip
        trigger="hover"
        placement="top"
        overlayStyle={{ zIndex: "1201" }}
        overlay={
          <Box display="flex" flexDirection="column">
            <span>キャストメモ：{item?.castShift?.castMemo || "未設定"} </span>
            <span>スタッフメモ：{item?.castShift?.staffMemo || "未設定"} </span>
            <span style={{ marginTop: "15px" }}>スケジュールカラー選択 </span>
            <Select
              style={{ backgroundColor: "white", marginBottom: "15px" }}
              variant={"outlined"}
              value={item?.castShift?.scheduleItemColor || ""}
              onChange={(event) => updateScheduleColor(event)}
            >
              <MenuItem value={""}>
                <Typography color="textSecondary">選択を外す</Typography>
              </MenuItem>
              {Object.entries(CastScheduleItemColor).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {EnumUtils.mapToEnum(ShiftStatus, item?.castShift?.status) !==
              ShiftStatus.absence && (
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={() => onClick()}
              >
                編集する
              </Button>
            )}
          </Box>
        }
      >
        <Item {...getItemProps(item.itemProps)} color={item.color}>
          <div
            className="rct-item-content"
            style={{ maxHeight: `${itemContext.dimensions.height}` }}
          >
            {itemContext.title}
          </div>
        </Item>
      </Tooltip>
      <div
        style={{
          height: itemContext.dimensions.height,
          overflow: "hidden",
          paddingLeft: 3,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      />
    </>
  );
};
export default CastScheduleShiftItem;
