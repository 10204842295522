import { Expose, Type } from "class-transformer";
import GuestRes from "types/res/guest/GuestRes";

export default class GuestAnalyticsStatusRes {
  @Expose() readonly guestId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly genre!: string;
  @Type(() => GuestRes)
  @Expose()
  readonly guest!: GuestRes;
  @Expose() readonly guestGroup!: string;
  @Expose() readonly awayDays!: string;
}
