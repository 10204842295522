import React, { useEffect, useState } from "react";

import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { fetchCastShift } from "redux/actions/castShift";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { fetchOrder } from "redux/actions/order";
import {
  Box,
  Button,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {
  AppointmentModel,
  GroupingState,
  IntegratedGrouping,
  Resource,
  ViewState,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  Appointments,
  Toolbar,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
  Resources,
  GroupingPanel,
} from "@devexpress/dx-react-scheduler-material-ui";
import OrderRes from "types/res/order/OrderRes";
import { Link } from "react-router-dom";
import EnumUtils from "utils/EnumUtils";
import OrderStatus from "types/enum/OrderStatus";
import CastShiftRes from "types/res/castShift/CastShiftRes";
import TimeUtils from "utils/TimeUtils";
type OrderType = OrderRes & AppointmentModel;
const SpCastSchedule = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const orders = useSelector((state) => state.order);
  const castShifts = useSelector((state) => state.castShift);
  const [currentDate, setCurrentDate] = useState(
    DateTime.local().toFormat(FORMAT_TYPE.YEAR_DAY)
  );
  const [selectCasts, setSelectCasts] = useState<string[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<AppointmentModel[]>([]);
  const [resources, setResources] = useState<Resource[]>([]);
  const [uniqueCasts, setUniqueCast] = useState<CastShiftRes[]>([]);
  const [outTimeEvent, setOutTimeEvent] = useState<AppointmentModel[]>([]);
  const [isBusinessHour, setIsBusinessHour] = useState(false);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const onCurrentDateChange = (currentDate: Date) => {
    setCurrentDate(
      DateTime.fromJSDate(currentDate).toFormat(FORMAT_TYPE.YEAR_DAY)
    );
  };

  useEffect(() => {
    const endDate = DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
      .plus({ days: 1 })
      .toFormat(FORMAT_TYPE.YEAR_DAY);
    dispatch(fetchCastShift(companyId, currentDate, endDate));
    dispatch(fetchOrder(companyId, currentDate, endDate));
  }, [currentDate]);

  useEffect(() => {
    const uniqueCast = Array.from(
      new Map(
        castShifts.map((castShift) => [castShift.castId, castShift])
      ).values()
    );
    setUniqueCast(uniqueCast);
    const initialCast = [
      ...uniqueCast.map((castShift) => String(castShift.castId)),
      "すべて",
    ];
    setSelectCasts(initialCast);
    const outOfService = uniqueCast.flatMap((castShift) => {
      if (
        12 < TimeUtils.maxClosingTimeInShop(castShift.shops) &&
        24 >= TimeUtils.maxClosingTimeInShop(castShift.shops)
      ) {
        return [
          {
            id: castShift.castId,
            castId: castShift.castId,
            startDate: DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
              .startOf("day")
              .toJSDate(),
            endDate: DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
              .startOf("day")
              .plus({ hours: TimeUtils.minOpeningTimeInShop(castShift.shops) })
              .toJSDate(),
            title: "営業時間外",
          },
          {
            id: castShift.castId,
            castId: castShift.castId,
            startDate: DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
              .startOf("day")
              .plus({ hours: TimeUtils.maxClosingTimeInShop(castShift.shops) })
              .toJSDate(),
            endDate: DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
              .endOf("day")
              .toJSDate(),
            title: "営業時間外",
          },
        ];
      } else {
        return [
          {
            id: castShift.castId,
            castId: castShift.castId,
            startDate: DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
              .startOf("day")
              .plus({ hours: TimeUtils.maxClosingTimeInShop(castShift.shops) })
              .toJSDate(),
            endDate: DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
              .startOf("day")
              .plus({ hours: TimeUtils.minOpeningTimeInShop(castShift.shops) })
              .toJSDate(),
            title: "営業時間外",
          },
        ];
      }
    });
    setOutTimeEvent(outOfService);
  }, [castShifts]);

  useEffect(() => {
    setSelectedOrders(
      orders.map((order) => ({
        ...order,
        startDate: order.actualInTime
          ? order.actualInTime
          : order.planInTime || new Date(),
        endDate: order.actualEndTime
          ? order.actualEndTime
          : order.planOutTime || new Date(),
        castId: order.castName.castId,
      }))
    );
  }, [orders]);

  useEffect(() => {
    const selectCastIds = selectCasts.filter(
      (selectCast) => selectCast !== "すべて"
    );
    if (!selectCastIds.length) return;
    const instance = selectCastIds.map((selectCastId) => ({
      id: Number(selectCastId),
      text:
        castShifts.find(
          (castShift) => castShift.castId === Number(selectCastId)
        )?.cast?.displayName || "",
    }));
    setResources([{ fieldName: "castId", instances: instance }]);
  }, [selectCasts]);
  return (
    <Box display="flex" flexDirection="column">
      <Select
        style={{ padding: "5px 5%" }}
        multiple
        value={selectCasts}
        onChange={(event) => {
          setSelectCasts((prev: string[]) => {
            if (
              prev.includes("すべて") &&
              (event.target.value as string[]).indexOf("すべて") === -1
            ) {
              return [];
            } else if (
              !prev.includes("すべて") &&
              (event.target.value as string[]).indexOf("すべて") !== -1
            ) {
              return [
                ...uniqueCasts.map((castShift) => String(castShift.castId)),
                "すべて",
              ];
            } else if (
              (event.target.value as string[]).length === uniqueCasts.length &&
              (event.target.value as string[]).indexOf("すべて") === -1
            ) {
              return [
                ...uniqueCasts.map((castShift) => String(castShift.castId)),
                "すべて",
              ];
            } else if (
              (event.target.value as string[]).length <= uniqueCasts.length
            ) {
              const value = event.target.value as any[];
              const id = (value as any[]).pop();
              if (prev.find((p) => p === String(id))) {
                return prev.filter((p) => p !== String(id));
              } else {
                return [...prev, String(id)];
              }
            } else {
              return event.target.value as string[];
            }
          });
        }}
        renderValue={(selected) => {
          if ((selected as string[]).includes("すべて")) {
            return "すべて";
          } else {
            return uniqueCasts
              .filter((castShift) =>
                (selected as string[]).includes(String(castShift.castId))
              )
              .map((castShift) => castShift.cast.displayName)
              .join(", ");
          }
        }}
      >
        <MenuItem key={"すべて"} value={"すべて"}>
          <Checkbox name="all" checked={selectCasts.indexOf("すべて") > -1} />
          <ListItemText primary={"すべて"} />
        </MenuItem>
        {uniqueCasts.map((castShift) => (
          <MenuItem key={castShift.castId} value={castShift.castId}>
            <Checkbox
              checked={selectCasts.indexOf(String(castShift.castId)) !== -1}
            />
            <ListItemText primary={castShift.cast.displayName} />
          </MenuItem>
        ))}
      </Select>
      <FormControlLabel
        control={
          <Checkbox
            checked={isBusinessHour}
            style={{ marginLeft: "5%" }}
            onChange={(event) => setIsBusinessHour(event.target.checked)}
          />
        }
        label={"営業日で絞り込む"}
      />
      {resources.length ? (
        <Scheduler
          data={[...selectedOrders, ...outTimeEvent]}
          locale="ja-JP"
          height={600}
        >
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={onCurrentDateChange}
          />
          <GroupingState
            grouping={[{ resourceName: "castId" }]}
            groupByDate={() => true}
          />
          <DayView
            startDayHour={
              currentDate === DateTime.local().toFormat(FORMAT_TYPE.YEAR_DAY) &&
              DateTime.local().hour > 3
                ? DateTime.local().minus({ hours: 3 }).hour
                : 0
            }
            endDayHour={
              currentDate === DateTime.local().toFormat(FORMAT_TYPE.YEAR_DAY) &&
              DateTime.local().hour > 3
                ? 24
                : isBusinessHour
                ? changeDate.hour
                : 24
            }
          />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <Appointments appointmentContentComponent={AppointmentContent} />
          <AppointmentTooltip contentComponent={AppointmentTooltipContent} />
          <Resources data={resources} mainResourceName={"castId"} />
          <IntegratedGrouping />
          <GroupingPanel />
        </Scheduler>
      ) : (
        <Scheduler data={selectedOrders} locale="ja-JP" height={600}>
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={onCurrentDateChange}
          />
          <DayView
            startDayHour={
              currentDate === DateTime.local().toFormat(FORMAT_TYPE.YEAR_DAY) &&
              DateTime.local().hour > 3
                ? DateTime.local().minus({ hours: 3 }).hour
                : 0
            }
            endDayHour={
              currentDate === DateTime.local().toFormat(FORMAT_TYPE.YEAR_DAY) &&
              DateTime.local().hour > 3
                ? 24
                : isBusinessHour
                ? changeDate.hour
                : 24
            }
          />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <Appointments appointmentContentComponent={AppointmentContent} />
          <AppointmentTooltip contentComponent={AppointmentTooltipContent} />
        </Scheduler>
      )}
    </Box>
  );
};

const AppointmentContent = ({ children, data, ...restProps }: any) => {
  if (data?.orderId) {
    return (
      <Appointments.AppointmentContent
        data={data}
        {...restProps}
        style={{ fontSize: 14 }}
      >
        <div>
          <VerticalItems data={data} />
        </div>
      </Appointments.AppointmentContent>
    );
  } else {
    return (
      <Appointments.Appointment
        data={data}
        {...restProps}
        style={{ fontSize: 14, backgroundColor: "gray" }}
      >
        <div>
          <Typography
            style={{ fontSize: "12px", color: "white", padding: "8px" }}
          >
            {data.title}
          </Typography>
        </div>
      </Appointments.Appointment>
    );
  }
};

const AppointmentTooltipContent = ({
  appointmentData,
  formatDate,
  children,
  ...restProps
}: any) => {
  const { options, cosplay, totalCastFee, memo, orderId } = appointmentData;
  if (orderId) {
    return (
      <AppointmentTooltip.Content
        appointmentData={appointmentData}
        {...restProps}
        formatDate={formatDate}
      >
        <MainItems data={appointmentData as OrderType} />
        <ScheduleItem title="バック" content={`${totalCastFee}円`} />
        <ScheduleItem
          title="オプション"
          content={options.map((option: any) => option.name || "").join("/")}
        />
        <ScheduleItem title="コスプレ" content={cosplay?.name || ""} />
        <ScheduleItem title="備考" content={memo} />
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={`/order/${orderId}`}
        >
          受注編集へ
        </Button>
      </AppointmentTooltip.Content>
    );
  } else {
    return <></>;
  }
};

const VerticalItems = ({ data }: { data: OrderType }) => {
  return (
    <Box style={{ writingMode: "vertical-lr" }}>
      <Typography style={{ fontSize: "12px" }}>
        顧客名:{data?.guest?.name || "未設定"}
      </Typography>
      <Typography style={{ fontSize: "12px" }}>
        ホテル:{data?.hotel?.name || "未設定"}
      </Typography>
    </Box>
  );
};

const MainItems = ({ data }: { data: OrderType }) => {
  return (
    <>
      <Box display="flex">
        <ScheduleItem title="顧客名" content={data?.guest.name || "未設定"} />
      </Box>
      <ScheduleItem
        title="ステータス"
        content={EnumUtils.mapToEnum(OrderStatus, data.status) || ""}
      />
      <ScheduleItem
        title="キャスト"
        content={data.castName?.name || "未設定"}
      />
      <ScheduleItem
        title="出発"
        content={
          data.departureTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.departureTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="予定IN"
        content={
          data.planInTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.planInTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="予定OUT"
        content={
          data.planOutTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.planOutTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="実IN"
        content={
          data.actualInTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.actualInTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="実OUT"
        content={
          data.actualEndTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.actualEndTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem title="店舗名" content={data.shop.name || ""} />
      <ScheduleItem title="コース" content={data.course.name || ""} />
      <ScheduleItem title="住所1" content={`${data.orderAddress || ""}`} />
      <ScheduleItem title="住所2" content={`${data.orderAddress2 || ""}`} />
      <ScheduleItem
        title="送り"
        content={`${data.outwardDriver?.carType || ""} ${
          data.outwardDriver?.carColor || ""
        } ${data.outwardDriver?.carNumber || ""}`}
      />
      <ScheduleItem
        title="迎え"
        content={`${data.returnDriver?.carType || ""} ${
          data.returnDriver?.carColor || ""
        } ${data.returnDriver?.carNumber || ""}`}
      />
      <ScheduleItem title="ホテル" content={`${data.hotel?.name || ""}`} />
      <ScheduleItem title="総額" content={`${data.totalFee || 0}円`} />
    </>
  );
};

const ScheduleItem = ({
  title,
  content,
}: {
  title: string;
  content: string;
}) => {
  return (
    <Box display="flex">
      <Typography style={{ fontWeight: "bold" }}>{title}:</Typography> {content}
    </Box>
  );
};

export default SpCastSchedule;
