import { reducerWithInitialState } from "typescript-fsa-reducers";
import driverSettingBookEmailActions from "redux/actions/driverSettingBookEmail";
import DriverSettingBookEmailRes from "types/res/driverSettingBookEmail/DriverSettingBookEmailRes";
export type DriverSettingBookEmailState = DriverSettingBookEmailRes[];

const initialState = [] as DriverSettingBookEmailState;

const driverDriverSettingBookEmailReducer = reducerWithInitialState(
  initialState
)
  .case(
    driverSettingBookEmailActions.updateDriverSettingBookEmailAction.done,
    (state, payload) => {
      return state.map((item) =>
        item.driverSettingBookEmailId ===
        payload.params.driverSettingBookEmailId
          ? payload.result
          : item
      );
    }
  )
  .case(
    driverSettingBookEmailActions.fetchDriverSettingBookEmailAction.done,
    (state, payload) => {
      return [...payload.result];
    }
  );
export default driverDriverSettingBookEmailReducer;
