import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import GuestGenreType from "types/enum/GuestGenreType";
import GuestAnalyticsGroupType from "types/enum/GuestAnalyticsGroupType";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { fetchShops } from "redux/actions/shop";
import { simpleFetchGuestCategory } from "redux/actions/guestCategory";
import { fetchNotelClass } from "redux/actions/notelClass";
import GuestAnalyticsProfileSalesApi from "api/GuestAnalyticsProfileSalesApi";
import { Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";

const GuestAnalyticsProfileSales = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const shops = useSelector((state) => state.shop);
  const notelClasses = useSelector((state) => state.notelClass);
  const guestCategories = useSelector((state) => state.guestCategory);

  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectNotelClasses, setSelectNotelClasses] = useState<string[]>([]);
  const [selectGuestCategories, setSelectGuestCategories] = useState<string[]>(
    []
  );
  const [selectGenre, setSelectGenre] = useState<string[]>([
    ...Object.keys(GuestGenreType),
    "すべて",
  ]);
  const [selectGroup, setSelectGroup] = useState<string[]>([
    ...Object.keys(GuestAnalyticsGroupType),
    "すべて",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [salesData, setSalesData] = useState<{
    [key: string]: { [key: string]: any };
  }>({});
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(simpleFetchGuestCategory(companyId));
    dispatch(fetchNotelClass(companyId));
  }, []);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    setSelectNotelClasses([
      ...notelClasses.map((notelClass) => String(notelClass.notelClassId)),
      "すべて",
    ]);
  }, [notelClasses]);

  useEffect(() => {
    setSelectGuestCategories([
      ...guestCategories.map((guestCategory) =>
        String(guestCategory.guestCategoryId)
      ),
      "すべて",
    ]);
  }, [guestCategories]);

  const onClickSearch = async () => {
    setIsLoading(true);
    const stringEndDate = DateTime.fromJSDate(endDate).toFormat(
      FORMAT_TYPE.YEAR_DAY
    );
    const result = await GuestAnalyticsProfileSalesApi.findData(companyId, {
      shopIds: selectShops,
      endDate: stringEndDate,
      guestCategoryIds: selectGuestCategories,
      notelClassIds: selectNotelClasses,
      guestAnalyticsType: selectGroup,
      guestGenreType: selectGenre,
    });
    setSalesData(result);
    setIsLoading(false);
  };

  return (
    <Box display="flex" flexDirection={"column"}>
      <Box margin={2} display="flex" alignItems="center">
        <TextField
          type="date"
          label="終了日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) =>
            setEndDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            )
          }
        />
        <Box>
          <InputLabel id="select-multiple-shop">店舗</InputLabel>
          <Select
            multiple
            value={selectShops}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectShops((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length === shops.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= shops.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-shop" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return shops
                  .filter((shop) =>
                    (selected as string[]).includes(String(shop.shopId))
                  )
                  .map((shop) => shop.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectShops.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {shops.map((shop) => (
              <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                <Checkbox
                  checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                />
                <ListItemText primary={shop.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-notel-class">クラス</InputLabel>
          <Select
            multiple
            value={selectNotelClasses}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectNotelClasses((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...notelClasses.map((notelClass) =>
                      String(notelClass.notelClassId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length ===
                    notelClasses.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...notelClasses.map((notelClass) =>
                      String(notelClass.notelClassId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= notelClasses.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-notelClass" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return notelClasses
                  .filter((notelClass) =>
                    (selected as string[]).includes(
                      String(notelClass.notelClassId)
                    )
                  )
                  .map((notelClass) => notelClass.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectNotelClasses.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {notelClasses.map((notelClass) => (
              <MenuItem
                key={notelClass.notelClassId}
                value={String(notelClass.notelClassId)}
              >
                <Checkbox
                  checked={
                    selectNotelClasses.indexOf(
                      String(notelClass.notelClassId)
                    ) !== -1
                  }
                />
                <ListItemText primary={notelClass.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-shop">顧客カテゴリ</InputLabel>
          <Select
            multiple
            value={selectGuestCategories}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGuestCategories((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...guestCategories.map((guestCategory) =>
                      String(guestCategory.guestCategoryId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length ===
                    guestCategories.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...guestCategories.map((guestCategory) =>
                      String(guestCategory.guestCategoryId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <=
                  guestCategories.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-category" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return guestCategories
                  .filter((guestCategory) =>
                    (selected as string[]).includes(
                      String(guestCategory.guestCategoryId)
                    )
                  )
                  .map((guestCategory) => guestCategory.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGuestCategories.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {guestCategories.map((guestCategory) => (
              <MenuItem
                key={guestCategory.guestCategoryId}
                value={String(guestCategory.guestCategoryId)}
              >
                <Checkbox
                  checked={
                    selectGuestCategories.indexOf(
                      String(guestCategory.guestCategoryId)
                    ) !== -1
                  }
                />
                <ListItemText primary={guestCategory.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-guest-genre">現役・離脱</InputLabel>
          <Select
            multiple
            value={selectGenre}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGenre((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [...Object.keys(GuestGenreType), "すべて"];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(GuestGenreType).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [...Object.keys(GuestGenreType), "すべて"];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(GuestGenreType).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-genre" />}
            style={{ margin: "10px", width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.keys(GuestGenreType)
                  .filter((name) => (selected as string[]).includes(name))
                  .map((key) => (GuestGenreType as any)[key])
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGenre.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(GuestGenreType).map(([key, name]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox checked={selectGenre.indexOf(String(key)) !== -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-guest-genre">顧客グループ</InputLabel>
          <Select
            multiple
            value={selectGroup}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGroup((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [...Object.keys(GuestAnalyticsGroupType), "すべて"];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(GuestAnalyticsGroupType).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [...Object.keys(GuestAnalyticsGroupType), "すべて"];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(GuestAnalyticsGroupType).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-genre" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.keys(GuestAnalyticsGroupType)
                  .filter((name) => (selected as string[]).includes(name))
                  .map((key) => (GuestAnalyticsGroupType as any)[key])
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGroup.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(GuestAnalyticsGroupType).map(([key, name]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox checked={selectGroup.indexOf(String(key)) !== -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={onClickSearch}
        >
          検索
        </Button>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : Object.keys(salesData).length ? (
        <Box padding={3} display={"flex"} style={{ flexWrap: "wrap" }}>
          {Object.entries(salesData).map(([key, data]) => (
            <Graph key={key} title={key} data={data} />
          ))}
        </Box>
      ) : (
        <Box display={"flex"} justifyContent={"center"}>
          <Typography>
            データがありません。条件を変えて検索してください。
          </Typography>
        </Box>
      )}
    </Box>
  );
};

type GraphProps = { title: string; data: { [key: string]: {} } };
const Graph = ({ title, data }: GraphProps) => {
  const formatData = Object.entries(data).flatMap(([category, d]) => {
    return {
      name: category,
      ...d,
    };
  });
  return (
    <Box display={"flex"} flexDirection={"column"} marginX={2} marginY={1}>
      <Typography variant={"subtitle1"}>{graphTitle[title]}</Typography>
      <BarChart width={500} height={500} layout="vertical" data={formatData}>
        <XAxis type="number" />
        <YAxis
          dataKey="name"
          type="category"
          width={100}
          tickFormatter={(value: string) => graphYAxisName[title][value]}
        />
        <Bar dataKey="thisYear" fill="#8884d8" name={"直近12ヶ月"} />
        <Bar dataKey="lastYear" fill="#FCD361" name={"前年12ヶ月"} />
        <Tooltip
          labelFormatter={(value: string) => graphYAxisName[title][value]}
          formatter={(value: any) => `¥${value}`}
        />
      </BarChart>
    </Box>
  );
};

const graphTitle: { [key: string]: string } = {
  age: "年齢別売上",
  bodyHeight: "身長別売上",
  bust: "バスト別売上",
  cup: "カップ別売上",
  waist: "ウエスト別売上",
  hip: "ヒップ別売上",
};

const graphYAxisName: { [key: string]: { [key: string]: string } } = {
  age: {
    under19: "~19",
    over20: "20~24",
    over25: "25~29",
    over30: "30~34",
    over35: "35~39",
    over40: "40~44",
    over45: "45~49",
    over50: "50~54",
    over55: "55~59",
    over60: "60",
  },
  bodyHeight: {
    under139: "~139",
    over140: "140~144",
    over145: "145~149",
    over150: "150~154",
    over155: "155~159",
    over160: "160~164",
    over165: "165~169",
    over170: "170~174",
    over175: "175~179",
    over180: "180~",
  },
  bust: {
    under69: "~69",
    over70: "70~74",
    over75: "75~79",
    over80: "80~84",
    over85: "85~89",
    over90: "90~94",
    over95: "95~99",
    over100: "100~104",
    over105: "105~109",
    over110: "110~",
  },
  cup: {
    a: "Aカップ",
    b: "Bカップ",
    c: "Cカップ",
    d: "Dカップ",
    e: "Eカップ",
    f: "Fカップ",
    g: "Gカップ",
    h: "Hカップ",
  },
  waist: {
    under49: "~49",
    over50: "50~54",
    just55: "55",
    just56: "56",
    just57: "57",
    just58: "58",
    just59: "59",
    over60: "60~64",
    over65: "65~69",
    over70: "70~",
  },
  hip: {
    under69: "~69",
    over70: "70~74",
    over75: "75~79",
    over80: "80~84",
    over85: "85~89",
    over90: "90~94",
    over95: "95~99",
    over100: "100~104",
    over105: "105~109",
    over110: "110~",
  },
};
export default GuestAnalyticsProfileSales;
