import { Box, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fetchSettingBookEmail } from "redux/actions/settingBookEmail";
import { useDispatch, useSelector } from "react-redux";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import EnumUtils from "utils/EnumUtils";
import PaymentType from "types/enum/PaymentType";
import SettingBookEmailRes from "types/res/settingBookEmail/SettingBookEmailRes";
import DriverSettingBookEmailRes from "types/res/driverSettingBookEmail/DriverSettingBookEmailRes";
import { fetchDriverSettingBookEmail } from "redux/actions/driverSettingBookEmail";

type Props = {
  formData: any;
  onChange(key: string): any;
};
const BookEmailBody: React.FC<Props> = ({ formData, onChange }) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const staff = useSelector((state) => state.account.staff);
  const guestCategory = useSelector((state) => state.guestCategory);
  const settingBookEmails = useSelector((state) => state.settingBookEmail);
  const driverSettingBookEmails = useSelector(
    (state) => state.driverSettingBookEmail
  );
  const shops = useSelector((state) => state.shop);
  const castNames = useSelector((state) => state.castName);
  const additionalTimes = useSelector((state) => state.additionalTime);
  const options = useSelector((state) => state.option);
  const discounts = useSelector((state) => state.discount);
  const cosplay = useSelector((state) => state.cosplay);
  const courses = useSelector((state) => state.course);
  const notelClasses = useSelector((state) => state.notelClass);
  const nominations = useSelector((state) => state.nomination);
  const areas = useSelector((state) => state.area);
  const hotels = useSelector((state) => state.hotel);
  const [settingBookEmail, setSettingBookEmail] =
    useState<SettingBookEmailRes>();
  const [bookEmailBody, setBookEmailBody] = useState("");
  const [driverSettingBookEmail, setDriverSettingBookEmail] =
    useState<DriverSettingBookEmailRes>();
  const [driverBookEmailBody, setDriverBookEmailBody] = useState("");

  useEffect(() => {
    dispatch(fetchSettingBookEmail(companyId));
    dispatch(fetchDriverSettingBookEmail(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (!formData.shopId) return;
    setSettingBookEmail(
      settingBookEmails.find(
        (settingBookEmail) => settingBookEmail.shopId === formData?.shopId
      )
    );
    setDriverSettingBookEmail(
      driverSettingBookEmails.find(
        (driverSettingBookEmail) =>
          driverSettingBookEmail.shopId === formData?.shopId
      )
    );
  }, [formData.shopId, settingBookEmails, driverSettingBookEmails]);

  useEffect(() => {
    setBookEmailBody(`
    【受付内容】
    ${
      settingBookEmail?.isOrderDateEnabled
        ? `受付時間: ${
            formData?.createdAt
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.createdAt,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : DateTimeUtils.toFormatAsLocalTimezone(
                  new Date(),
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
          }`
        : ""
    }
    ${
      settingBookEmail?.isOrderCreatedByEnabled
        ? `登録者: ${formData?.createdBy?.name || staff.name}`
        : ""
    }
    ${
      settingBookEmail?.isOrderUpdatedByEnabled
        ? `更新者: ${formData?.updatedBy?.name || staff.name}`
        : ""
    }
    
   【顧客情報】
    ${
      settingBookEmail?.isGuestCategoryEnabled
        ? `顧客区分: ${
            guestCategory.find(
              (gc) => gc.guestCategoryId === formData?.guestCategoryId
            )?.name || ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isGuestNameEnabled
        ? `顧客名: ${formData?.guest?.name || ""}様`
        : ""
    }
    ${
      settingBookEmail?.isGuestTelEnabled
        ? `顧客電話番号: ${formData?.guest?.tel || ""}`
        : ""
    }
    ${
      settingBookEmail?.isGuestMemoEnabled
        ? `メモ: ${formData?.guest?.memo || ""}`
        : ""
    }

    【予約情報】
    ${
      settingBookEmail?.isShopNameEnabled
        ? `店舗名：${
            shops.find((shop) => shop.shopId === formData?.shopId)?.name || ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isCastNameEnabled
        ? `キャスト名：${
            castNames?.find(
              (castName) => castName.castNameId === formData?.castNameId
            )?.name || ""
          }さん`
        : ""
    }
    ${
      settingBookEmail?.isCastDesignateCountEnabled
        ? `指名回数：${formData?.additionalTimeCount || ""}回`
        : ""
    }
    ${
      settingBookEmail?.isCastClassNameEnabled
        ? `クラス：${
            notelClasses.find(
              (notelClass) => notelClass.notelClassId === formData?.notelClassId
            )?.name || ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isCastDesignateNameEnabled
        ? `指名：${
            nominations.find(
              (nomination) => nomination.nominationId === formData?.nominationId
            )?.name || ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isCourseNameEnabled
        ? `コース：${
            courses.find((course) => course.courseId === formData?.courseId)
              ?.name || ""
          }`
        : ""
    }    
    ${
      settingBookEmail?.isAdditionalTimeEnabled
        ? `延長：${
            formData?.additionalTimeOrders
              ?.map((additionalTimeOrder: any) => {
                return `${
                  additionalTimes?.find(
                    (additionalTime) =>
                      additionalTime.additionalTimeId ===
                      additionalTimeOrder.additionalTimeId
                  )?.time || 0
                }分 × ${additionalTimeOrder.count || 0}回`;
              })
              .join(",") || ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isTotalTimeEnabled
        ? `合計時間：${formData?.totalTime || 0}分`
        : ""
    }
    ${
      settingBookEmail?.isDiscountNameEnabled
        ? `イベント・割引：${discounts
            .filter((discount) =>
              formData?.discounts?.includes(discount.discountId)
            )
            .map((discount) => discount.name)
            .join(",")} `
        : ""
    }
    ${
      settingBookEmail?.isOptionNameEnabled
        ? `オプション：${options
            .filter((option) => formData?.options?.includes(option.optionId))
            .map((option) => option.name)
            .join(",")}`
        : ""
    }
    ${
      settingBookEmail?.isCosplayNameEnabled
        ? `コスプレ：${
            cosplay.find((cos) => cos.cosplayId === formData?.cosplayId)
              ?.name || ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isAreaEnabled
        ? `エリア：${
            areas.find((area) => area.areaId === formData?.areaId)?.name || ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isHotelEnabled
        ? `ホテル：${
            hotels.find((hotel) => hotel.hotelId === formData?.hotelId)?.name ||
            ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isOrderAddressEnabled
        ? `住所：${formData?.orderAddress || ""}
    住所2：${formData?.orderAddress2 || ""}
        `
        : ""
    }
    ${
      settingBookEmail?.isCarInfoEnabled
        ? `送り：${formData?.outwardDriver?.carType || ""} ${
            formData?.outwardDriver?.carColor || ""
          } ${formData?.outwardDriver?.carNumber || ""}
    迎え：${formData?.returnDriver?.carType || ""} ${
            formData?.returnDriver?.carColor || ""
          } ${formData?.returnDriver?.carNumber || ""}
    `
        : ""
    }
    ${
      settingBookEmail?.isOrderDepartureTimeEnabled
        ? `出発：${
            formData?.departureTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.departureTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isOrderPlanInTimeEnabled
        ? `予定IN：${
            formData?.planInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.planInTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isOrderActualInTimeEnabled
        ? `実IN：${
            formData?.actualInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.actualInTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isOrderActualEndTimeEnabled
        ? `実OUT：${
            formData?.actualEndTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.actualEndTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isOrderMemoEnabled
        ? `メモ：${formData?.memo || ""}`
        : ""
    }
    ${
      settingBookEmail?.isOrderPaymentTypeEnabled
        ? `支払い方法：${
            EnumUtils.mapToEnum(PaymentType, formData?.paymentType) || ""
          }`
        : ""
    }
    ${settingBookEmail ? `総額: ${formData?.totalFee || 0}円` : ""}
    `);
  }, [
    settingBookEmail,
    formData,
    guestCategory,
    cosplay,
    discounts,
    areas,
    hotels,
    options,
    courses,
    nominations,
    notelClasses,
    additionalTimes,
  ]);
  useEffect(() => {
    setDriverBookEmailBody(`
    【受付内容】
    ${
      driverSettingBookEmail?.isOrderDateEnabled
        ? `受付時間: ${
            formData?.createdAt
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.createdAt,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : DateTimeUtils.toFormatAsLocalTimezone(
                  new Date(),
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderCreatedByEnabled
        ? `登録者: ${formData?.createdBy?.name || staff.name}`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderUpdatedByEnabled
        ? `更新者: ${formData?.updatedBy?.name || staff.name}`
        : ""
    }
    
   【顧客情報】
    ${
      driverSettingBookEmail?.isGuestCategoryEnabled
        ? `顧客区分: ${
            guestCategory.find(
              (gc) => gc.guestCategoryId === formData?.guestCategoryId
            )?.name || ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isGuestNameEnabled
        ? `顧客名: ${formData?.guest?.name || ""}様`
        : ""
    }
    ${
      driverSettingBookEmail?.isGuestTelEnabled
        ? `顧客電話番号: ${formData?.guest?.tel || ""}`
        : ""
    }
    ${
      driverSettingBookEmail?.isGuestMemoEnabled
        ? `メモ: ${formData?.guest?.memo || ""}`
        : ""
    }

    【予約情報】
    ${
      driverSettingBookEmail?.isShopNameEnabled
        ? `店舗名：${
            shops.find((shop) => shop.shopId === formData?.shopId)?.name || ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isCastNameEnabled
        ? `キャスト名：${
            castNames?.find(
              (castName) => castName.castNameId === formData?.castNameId
            )?.name || ""
          }さん`
        : ""
    }
    ${
      driverSettingBookEmail?.isCastDesignateCountEnabled
        ? `指名回数：${formData?.additionalTimeCount || ""}回`
        : ""
    }
    ${
      driverSettingBookEmail?.isCastClassNameEnabled
        ? `クラス：${
            notelClasses.find(
              (notelClass) => notelClass.notelClassId === formData?.notelClassId
            )?.name || ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isCastDesignateNameEnabled
        ? `指名：${
            nominations.find(
              (nomination) => nomination.nominationId === formData?.nominationId
            )?.name || ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isCourseNameEnabled
        ? `コース：${
            courses.find((course) => course.courseId === formData?.courseId)
              ?.name || ""
          }`
        : ""
    }    
    ${
      driverSettingBookEmail?.isAdditionalTimeEnabled
        ? `延長：${
            formData?.additionalTimeOrders
              ?.map((additionalTimeOrder: any) => {
                return `${
                  additionalTimes?.find(
                    (additionalTime) =>
                      additionalTime.additionalTimeId ===
                      additionalTimeOrder.additionalTimeId
                  )?.time || 0
                }分 × ${additionalTimeOrder.count || 0}回`;
              })
              .join(",") || ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isTotalTimeEnabled
        ? `合計時間：${formData?.totalTime || 0}分`
        : ""
    }
    ${
      driverSettingBookEmail?.isDiscountNameEnabled
        ? `イベント・割引：${discounts
            .filter((discount) =>
              formData?.discounts?.includes(discount.discountId)
            )
            .map((discount) => discount.name)
            .join(",")} `
        : ""
    }
    ${
      driverSettingBookEmail?.isOptionNameEnabled
        ? `オプション：${options
            .filter((option) => formData?.options?.includes(option.optionId))
            .map((option) => option.name)
            .join(",")}`
        : ""
    }
    ${
      driverSettingBookEmail?.isCosplayNameEnabled
        ? `コスプレ：${
            cosplay.find((cos) => cos.cosplayId === formData?.cosplayId)
              ?.name || ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isAreaEnabled
        ? `エリア：${
            areas.find((area) => area.areaId === formData?.areaId)?.name || ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isHotelEnabled
        ? `ホテル：${
            hotels.find((hotel) => hotel.hotelId === formData?.hotelId)?.name ||
            ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderAddressEnabled
        ? `住所：${formData?.orderAddress || ""}
    住所2：${formData?.orderAddress2 || ""}
        `
        : ""
    }
    ${
      driverSettingBookEmail?.isCarInfoEnabled
        ? `送り：${formData?.outwardDriver?.carType || ""} ${
            formData?.outwardDriver?.carColor || ""
          } ${formData?.outwardDriver?.carNumber || ""}
    迎え：${formData?.returnDriver?.carType || ""} ${
            formData?.returnDriver?.carColor || ""
          } ${formData?.returnDriver?.carNumber || ""}
    `
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderDepartureTimeEnabled
        ? `出発：${
            formData?.departureTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.departureTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderPlanInTimeEnabled
        ? `予定IN：${
            formData?.planInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.planInTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderActualInTimeEnabled
        ? `実IN：${
            formData?.actualInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.actualInTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderActualEndTimeEnabled
        ? `実OUT：${
            formData?.actualEndTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.actualEndTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderMemoEnabled
        ? `メモ：${formData?.memo || ""}`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderPaymentTypeEnabled
        ? `支払い方法：${
            EnumUtils.mapToEnum(PaymentType, formData?.paymentType) || ""
          }`
        : ""
    }
    ${driverSettingBookEmail ? `総額: ${formData?.totalFee || 0}円` : ""}
    `);
  }, [
    driverSettingBookEmail,
    formData,
    guestCategory,
    cosplay,
    discounts,
    areas,
    hotels,
    options,
    courses,
    nominations,
    notelClasses,
    additionalTimes,
  ]);

  useEffect(() => {
    onChange("bookEmailBody")(bookEmailBody);
  }, [bookEmailBody]);

  useEffect(() => {
    onChange("driverBookEmailBody")(driverBookEmailBody);
  }, [driverBookEmailBody]);

  return (
    <>
      <Box width={"80%"} display={"flex"} flexDirection={"column"}>
        <Typography variant="h5" component="h5">
          予約詳細テキストコピー
        </Typography>
        <TextField
          multiline
          variant="outlined"
          style={{ margin: 5 }}
          rows={20}
          rowsMax={1000}
          value={bookEmailBody}
        />
      </Box>
    </>
  );
};

export default BookEmailBody;
