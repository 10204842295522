export enum CastScheduleItemColor {
  "#ff00ff" = "ピンク",
  "#ff0000" = "赤",
  "#000000" = "黒",
  "#808000" = "オリーブ",
  "#008000" = "緑",
  "#0000ff" = "青",
  "#000080" = "ネイビー",
  "#800080" = "紫",
}
