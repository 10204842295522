import { DataValidationError } from "constants/DataValidationError";
import { IsDate, IsNotEmpty, IsOptional } from "class-validator";
import { Type } from "class-transformer";

export default class BulkInsertDriverShiftReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  driverId!: number;

  @Type(() => Date)
  @IsDate()
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  planWorkStart!: Date;

  @Type(() => Date)
  @IsDate()
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  planWorkEnd!: Date;

  @IsOptional()
  driverShiftId?: number;

  constructor(arg: {
    driverId: number;
    planWorkStart: Date;
    planWorkEnd: Date;
    driverShiftId?: number;
  }) {
    arg = arg || {};
    this.driverId = arg.driverId;
    this.planWorkStart = arg.planWorkStart;
    this.planWorkEnd = arg.planWorkEnd;
    this.driverShiftId = arg.driverShiftId;
  }
}
