import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty, IsOptional } from "class-validator";
import GuestPointType from "types/enum/GuestPointType";

export default class CreateGuestPointReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  guestId!: number;

  @IsOptional()
  orderId!: number | null;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  shopId!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  point!: number;

  @IsOptional()
  type!: GuestPointType;

  @IsOptional()
  remarks!: string;

  constructor(arg: {
    guestId: number;
    orderId: number | null;
    shopId: number;
    point: number;
    type: GuestPointType;
    remarks: string;
  }) {
    arg = arg || {};
    this.guestId = arg.guestId;
    this.orderId = arg.orderId;
    this.shopId = arg.shopId;
    this.point = arg.point;
    this.type = arg.type;
    this.remarks = arg.remarks;
  }
}
