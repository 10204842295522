import { reducerWithInitialState } from "typescript-fsa-reducers";
import guestPointActions from "redux/actions/guestPoint";
import GuestPointRes from "types/res/guestPoint/GuestPointRes";
export type GuestPointState = GuestPointRes[];

const initialState = [] as GuestPointState;

const guestPointReducer = reducerWithInitialState(initialState)
  .case(guestPointActions.addGuestPointAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(guestPointActions.fetchGuestPointAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(guestPointActions.bulkInsertGuestPointAction.done, (state, payload) => {
    return [...state, ...payload.result];
  });
export default guestPointReducer;
