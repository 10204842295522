import { reducerWithInitialState } from "typescript-fsa-reducers";
import messageBoardActions from "redux/actions/messageBoard";
import MessageBoardRes from "types/res/messageBoard/MessageBoardRes";
export type MessageBoardState = MessageBoardRes[];

const initialState = [] as MessageBoardState;

const messageBoardReducer = reducerWithInitialState(initialState)
  .case(messageBoardActions.addMessageBoardAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(messageBoardActions.updateMessageBoardAction.done, (state, payload) => {
    return state.map((item) =>
      item.messageBoardId === payload.params.messageBoardId
        ? payload.result
        : item
    );
  })
  .case(messageBoardActions.deleteMessageBoardAction.done, (state, payload) => {
    return state.filter(
      (item) => item.messageBoardId !== payload.params.messageBoardId
    );
  })
  .case(messageBoardActions.fetchMessageBoardAction.done, (state, payload) => {
    return [...payload.result];
  });
export default messageBoardReducer;
