import { reducerWithInitialState } from "typescript-fsa-reducers";
import additionalTimeActions from "redux/actions/additionalTime";
import AdditionalTimeRes from "types/res/additionalTime/AdditionalTimeRes";
export type AdditionalTimeState = AdditionalTimeRes[];

const initialState = [] as AdditionalTimeState;

const additionalTimeReducer = reducerWithInitialState(initialState)
  .case(
    additionalTimeActions.addAdditionalTimeAction.done,
    (state, payload) => {
      return [...state, payload.result];
    }
  )
  .case(
    additionalTimeActions.updateAdditionalTimeAction.done,
    (state, payload) => {
      return state.map((item) =>
        item.additionalTimeId === payload.params.additionalTimeId
          ? payload.result
          : item
      );
    }
  )
  .case(
    additionalTimeActions.deleteAdditionalTimeAction.done,
    (state, payload) => {
      return state.filter(
        (item) => item.additionalTimeId !== payload.params.additionalTimeId
      );
    }
  )
  .case(
    additionalTimeActions.fetchAdditionalTimeAction.done,
    (state, payload) => {
      return [...payload.result];
    }
  );
export default additionalTimeReducer;
