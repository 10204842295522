import { reducerWithInitialState } from "typescript-fsa-reducers";
import shopActions from "redux/actions/shop";
import ShopRes from "types/res/shop/ShopRes";
export type ShopState = ShopRes[];

const initialState = [] as ShopState;

const shopReducer = reducerWithInitialState(initialState)
  .case(shopActions.addShopAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(shopActions.updateShopAction.done, (state, payload) => {
    return state.map((item) =>
      item.shopId === payload.params.shopId ? payload.result : item
    );
  })
  .case(shopActions.deleteShopAction.done, (state, payload) => {
    return state.filter((item) => item.shopId !== payload.params.shopId);
  })
  .case(shopActions.fetchShopsAction.done, (state, payload) => {
    return payload.result;
  });
export default shopReducer;
