const Spec = {
  maxLength: {
    company: {
      name: 128,
      kana: 128,
      representativeName: 128,
      email: 256,
      tel: 128,
    },
    shop: {
      name: 128,
      realName: 128,
      url: 2048,
      email: 256,
      tel: 128,
    },
    staff: {
      email: 256,
      name: 128,
      tel: 128,
      password: 32,
    },
    staffTask: {
      name: 128,
      summary: 2048,
    },
    cast: {
      email: 256,
      name: 128,
      displayName: 128,
      tel: 128,
      password: 32,
      address: 1024,
      remarks: 2048,
    },
    castCategory: {
      name: 128,
    },
    castShift: {
      staffMemo: 1024,
      castMemo: 1024,
    },
    driver: {
      email: 256,
      name: 128,
      password: 32,
      address: 1024,
      tel: 32,
      carType: 256,
      carColor: 256,
      carNumber: 256,
      remarks: 2048,
    },
    driverShift: {
      staffMemo: 1024,
      driverMemo: 1024,
    },
    course: {
      name: 256,
    },
    cosplay: {
      name: 128,
    },
    discount: {
      name: 128,
    },
    option: {
      name: 128,
    },
    area: {
      name: 128,
      kana: 128,
    },
    guest: {
      name: 128,
      email: 256,
      password: 32,
      address: 1024,
      tel: 15,
      memo: 63359,
    },
    guestCategory: {
      name: 128,
    },
    hotel: {
      name: 256,
      kana: 256,
      tel: 15,
      price: 1024,
      address: 1024,
      memo: 2048,
    },
    medium: {
      name: 256,
    },
    messageBoard: {
      title: 128,
    },
    order: {
      orderAddress: 2048,
      memo: 63356,
      questionnaire: 63356,
      bookEmailBody: 63356,
    },
    notification: {
      title: 1024,
      content: 63356,
    },
    nomination: {
      name: 128,
    },
    notelClass: {
      name: 128,
    },
    additionalTime: {
      name: 128,
    },
  },
};
export default Spec;
