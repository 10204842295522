import { Box, Button, createStyles, Theme } from "@material-ui/core";
import DateTimeUtils, { FORMAT_TYPE } from "../../../utils/DateTimeUtils";
import Tooltip from "rc-tooltip";
import React from "react";
import OrderRes from "../../../types/res/order/OrderRes";
import styled from "styled-components";
import EnumUtils from "../../../utils/EnumUtils";
import OrderStatus from "../../../types/enum/OrderStatus";
import { useDispatch, useSelector } from "react-redux";
import { cancelOrder } from "redux/actions/order";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import OrderApi from "api/OrderApi";

type Props = {
  order: OrderRes;
  item: any;
  itemContext: any;
  getItemProps: any;
  rightResizeProps: any;
  leftResizeProps: any;
};

const Item = styled.div<{ color: string }>`
  background-color: ${(props) => props.color} !important;
  border: 1px solid ${(props) => props.color} !important;
  border-radius: 4px;
`;
const CastScheduleOrderItem: React.FC<Props> = ({
  order,
  item,
  itemContext,
  getItemProps,
  leftResizeProps,
  rightResizeProps,
}) => {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.account.staff.company);
  const companyId = useSelector((state) => state.account.staff.companyId);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  const sendEmail = async () => {
    const result = confirm("メールを送信しますか？");
    if (result) {
      await OrderApi.sendMailCast(companyId, order.orderId);
      alert("メールを送信しました。");
    }
  };
  return (
    <Tooltip
      trigger={["hover"]}
      overlayStyle={{ zIndex: "1201" }}
      overlay={
        <Box display="flex" flexDirection="column">
          <span>店舗名：{order?.shop?.name || "未設定"} </span>
          <span>
            顧客名：{order?.guest?.name || "未設定"} (ホテル：
            {order?.hotel?.name || "未設定"})
          </span>
          <span>顧客電話番号：{order?.guest?.tel || "未設定"}</span>
          <span>
            顧客区分：
            {company.guestCategoryShopFlag
              ? order?.guest?.guestGuestCategoryShops?.find(
                  (guestGuestCategoryShop) =>
                    guestGuestCategoryShop.shopId === order.shopId &&
                    guestGuestCategoryShop.guestId === order.guestId
                )?.guestCategory?.name || "未設定"
              : order?.guest?.guestCategory?.name || "未設定"}
          </span>
          <span>源氏名：{order?.castName?.name || "未設定"}</span>
          <span>
            指名種：
            {order?.nomination?.name || "未設定"}
          </span>
          <span>
            合計時間：{order?.totalTime ? `${order.totalTime}分` : "未設定"}
          </span>
          <span>
            エリア：
            {order?.area?.name || "未設定"}
          </span>
          <span>
            ホテル：
            {order?.hotel?.name || "未設定"}
          </span>
          <span>
            住所：
            {order?.orderAddress || "未設定"}
          </span>
          <span>
            住所2：
            {order?.orderAddress2 || "未設定"}
          </span>
          <span>
            オプション：
            {order?.options?.map((option) => option.name).join("/") || "未設定"}
          </span>
          <span>
            出発時間：
            {order?.departureTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  order?.departureTime,
                  FORMAT_TYPE.TIME
                )
              : "未設定"}
          </span>
          <span>
            予定IN：
            {order?.planInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  order.planInTime,
                  FORMAT_TYPE.TIME
                )
              : "未設定"}
          </span>
          <span>
            予定OUT：
            {order?.planOutTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  order.planOutTime,
                  FORMAT_TYPE.TIME
                )
              : "未設定"}
          </span>
          <span>
            実IN：
            {order?.actualInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  order.actualInTime,
                  FORMAT_TYPE.TIME
                )
              : "未設定"}
          </span>
          <span>
            実OUT：
            {order?.actualEndTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  order.actualEndTime,
                  FORMAT_TYPE.TIME
                )
              : "未設定"}
          </span>
          <span style={{ whiteSpace: "pre-wrap" }}>
            メモ：{order?.memo || "未設定"}
          </span>
          {EnumUtils.mapToEnum(OrderStatus, order.status) ===
            OrderStatus.hold && (
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              onClick={() =>
                dispatch(cancelOrder(companyId, { orderId: order.orderId }))
              }
            >
              この予約をキャンセルする
            </Button>
          )}
          <Button
            className={classes.button}
            color="default"
            variant="contained"
            onClick={sendEmail}
          >
            キャストへメール送信する
          </Button>
          <Button
            component={Link}
            to={`/order/${order.orderId}`}
            className={classes.button}
            color="primary"
            variant="contained"
          >
            予約詳細へ
          </Button>
        </Box>
      }
    >
      <Item {...getItemProps(item.itemProps)} color={item.color}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
        <div
          className="rct-item-content"
          style={{
            maxHeight: `${itemContext.dimensions.height}`,
            color: item.textColor,
          }}
        >
          {itemContext.title}
        </div>
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        />
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </Item>
    </Tooltip>
  );
};

export default CastScheduleOrderItem;
