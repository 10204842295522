import RequestUtils from "utils/RequestUtils";
import MessageBoardRes from "types/res/messageBoard/MessageBoardRes";
import CreateMessageBoardReq from "types/req/messageBoard/CreateMessageBoardReq";
import UpdateMessageBoardReq from "types/req/messageBoard/UpdateMessageBoardReq";
import DeleteMessageBoardReq from "types/req/messageBoard/DeleteMessageBoardReq";

export default class MessageBoardApi {
  private constructor() {}

  static async findAll(companyId: number): Promise<MessageBoardRes[]> {
    return await RequestUtils.getArray(
      MessageBoardRes,
      `/messageBoard/findAll/${companyId}`
    );
  }

  static async create(companyId: number, data: CreateMessageBoardReq) {
    return await RequestUtils.post(
      MessageBoardRes,
      `/messageBoard/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    data: UpdateMessageBoardReq
  ): Promise<MessageBoardRes> {
    return await RequestUtils.post(
      MessageBoardRes,
      `/messageBoard/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteMessageBoardReq) {
    return await RequestUtils.postVoid(
      `/messageBoard/delete/${companyId}`,
      data
    );
  }
}
