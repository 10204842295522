import {
  addOrder,
  deleteOrder,
  fetchOrderByGuestId,
  updateOrder,
} from "redux/actions/order";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import EnumUtils from "utils/EnumUtils";
import OrderStatus from "types/enum/OrderStatus";
import { Button, Typography } from "@material-ui/core";
import { DateTime } from "luxon";
import OrderResponseStatus from "types/enum/OrderResponseStatus";
import PaymentType from "types/enum/PaymentType";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { FormType } from "components/FormField";
import OrderRes from "types/res/order/OrderRes";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import CreateOrderReq from "types/req/order/CreateOrderReq";
import UpdateOrderReq from "types/req/order/UpdateOrderReq";
import { useDispatch, useSelector } from "react-redux";

const OrderListByGuest = () => {
  const dispatch = useDispatch();
  const { guestId } = useParams<{ guestId: string }>();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const orders = useSelector((state) => state.order);
  const loginStaff = useSelector((state) => state.account.staff);

  useEffect(() => {
    if (!guestId) return;
    const guestIdToNumber = Number(guestId);
    if (Number.isNaN(guestIdToNumber)) return;
    dispatch(fetchOrderByGuestId(companyId, Number(guestIdToNumber)));
  }, [guestId]);

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "料金",
      key: "totalFee",
      type: FormType.Number,
    },
  ];
  const headers: {
    key: keyof OrderRes;
    label: string;
  }[] = [
    { key: "orderDate", label: "日付" },
    { key: "status", label: "ステータス" },
    { key: "shopId", label: "店名" },
    { key: "guestId", label: "顧客名" },
    { key: "guestId", label: "顧客区分" },
    { key: "guestId", label: "顧客電話番号" },
    { key: "castName", label: "キャスト" },
    { key: "orderAddress", label: "場所" },
    { key: "courseId", label: "コース" },
    { key: "options", label: "オプション" },
    { key: "courseTime", label: "コース分数" },
    { key: "areaId", label: "エリア" },
    { key: "orderAddress", label: "住所1" },
    { key: "departureTime", label: "出発" },
    { key: "planInTime", label: "予IN" },
    { key: "actualInTime", label: "実IN-OUT" },
    { key: "actualEndTime", label: "call" },
    { key: "responseStatus", label: "応答" },
    { key: "collectReceivable", label: "回収" },
    { key: "totalFee", label: "金額" },
    { key: "paymentType", label: "カード" },
    { key: "payoff", label: "精算" },
    { key: "createdBy", label: "登録者" },
    { key: "updatedBy", label: "最終更新者" },
    { key: "updatedAt", label: "更新日時" },
    { key: "orderId", label: "" },
  ];

  return (
    <OrderTable
      title={"受注"}
      formId="orderForm"
      fullWidth
      forms={forms}
      rows={headers}
      data={orders}
      addFunc={(formData) => addOrder(companyId, formData)}
      updateFunc={(formData) => updateOrder(companyId, formData)}
      deleteFunc={(item) =>
        deleteOrder(companyId, {
          orderId: item.orderId,
        })
      }
      invisibleDeleteIcon={true}
      values={[
        (o) =>
          DateTimeUtils.toFormatAsLocalTimezone(
            o.orderDate,
            FORMAT_TYPE.DAY_LIST
          ),
        (o) => EnumUtils.mapToEnum(OrderStatus, o.status) || "",
        (o) => o.shop?.name || "未設定",
        (o) => o.guest?.name || "未設定",
        (o) =>
          loginStaff.company.guestCategoryShopFlag
            ? o.guest?.guestGuestCategoryShops?.find(
                (guestGuestCategoryShop) =>
                  guestGuestCategoryShop.shopId === o.shopId &&
                  guestGuestCategoryShop.guestId === o.guestId
              )?.guestCategory?.name || "未設定"
            : o?.guest?.guestCategory?.name || "未設定",
        (o) => o.guest?.tel || "未設定",
        (o) => o.castName?.name || "未設定",
        (o) => o.hotel?.name || "未設定",
        (o) => o.course?.name || "未設定",
        (o) => o.options?.map((option) => option.name).join(",") || "未設定",
        (o) => (o.courseTime ? `${o.courseTime}分` : "未設定"),
        (o) => o.area?.name || "未設定",
        (o) => o.orderAddress || "未設定",

        (o) =>
          o.departureTime
            ? DateTimeUtils.toFormatAsLocalTimezone(
                o.departureTime,
                FORMAT_TYPE.TIME
              )
            : "未設定",
        (o) =>
          o.planInTime
            ? DateTimeUtils.toFormatAsLocalTimezone(
                o.planInTime,
                FORMAT_TYPE.TIME
              )
            : "未設定",
        (o) =>
          o.actualInTime && o.actualEndTime ? (
            `${DateTimeUtils.toFormatAsLocalTimezone(
              o.actualInTime,
              FORMAT_TYPE.TIME
            )} ~ ${DateTimeUtils.toFormatAsLocalTimezone(
              o.actualEndTime,
              FORMAT_TYPE.TIME
            )}`
          ) : o.requestActualInTime ? (
            <Typography style={{ color: "red" }} variant="body1">
              リクエスト有り
            </Typography>
          ) : (
            "未設定"
          ),
        (o) =>
          o?.planOutTime && o?.shop?.orderAlertEmailTime
            ? DateTime.fromJSDate(o.planOutTime)
                .minus({
                  minutes: o?.shop?.orderAlertEmailTime,
                })
                .toFormat(FORMAT_TYPE.TIME)
            : "未設定",
        (o) =>
          EnumUtils.mapToEnum(OrderResponseStatus, o.responseStatus) ||
          "未設定",
        (o) => (o.collectReceivable ? "済" : "未"),
        (o) => `${o.totalFee || 0}円`,
        (o) => EnumUtils.mapToEnum(PaymentType, o.paymentType) || "未設定",
        (o) => (o.payoff ? "精算済み" : "未精算"),
        (o) => o.createdBy?.name,
        (o) => o.updatedBy?.name,
        (o) =>
          DateTimeUtils.toFormatAsLocalTimezone(
            o.updatedAt,
            FORMAT_TYPE.DATE_TIME
          ),
        (o) => (
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to={o.orderId ? `/order/${o.orderId}` : `/order`}
          >
            編集
          </Button>
        ),
      ]}
    />
  );
};
const OrderTable = styled<
  React.FC<CommonTableProps<OrderRes, CreateOrderReq, UpdateOrderReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default OrderListByGuest;
