import RequestUtils from "utils/RequestUtils";
import AdditionalTimeRes from "types/res/additionalTime/AdditionalTimeRes";
import CreateAdditionalTimeReq from "types/req/additionalTime/CreateAdditionalTimeReq";
import UpdateAdditionalTimeReq from "types/req/additionalTime/UpdateAdditionalTimeReq";
import DeleteAdditionalTimeReq from "types/req/additionalTime/DeleteAdditionalTimeReq";

export default class AdditionalTimeApi {
  private constructor() {}

  static async findAll(
    companyId: number,
    shopId?: number
  ): Promise<AdditionalTimeRes[]> {
    return await RequestUtils.getArray(
      AdditionalTimeRes,
      `/additionalTime/findAll/${companyId}${shopId ? `?shopId=${shopId}` : ""}`
    );
  }

  static async create(companyId: number, data: CreateAdditionalTimeReq) {
    return await RequestUtils.post(
      AdditionalTimeRes,
      `/additionalTime/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    additionalTimeId: number,
    data: UpdateAdditionalTimeReq
  ): Promise<AdditionalTimeRes> {
    return await RequestUtils.post(
      AdditionalTimeRes,
      `/additionalTime/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteAdditionalTimeReq) {
    return await RequestUtils.postVoid(
      `/additionalTime/delete/${companyId}`,
      data
    );
  }
}
