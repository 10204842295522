import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import GuestApi from "api/GuestApi";
import GuestRes from "types/res/guest/GuestRes";
import UpdateGuestReq from "types/req/guest/UpdateGuestReq";
import CreateGuestReq from "types/req/guest/CreateGuestReq";
import DeleteGuestReq from "types/req/guest/DeleteGuestReq";

const actionCreator = actionCreatorFactory();
const addGuestAction = actionCreator.async<
  CreateGuestReq,
  GuestRes,
  { code: number; description: string }
>("ADD_GUEST");

export const addGuest =
  (companyId: number, req: CreateGuestReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addGuestAction.started({ ...req }));

    try {
      const guest = new CreateGuestReq({ ...req });
      const result = await GuestApi.create(companyId, guest);
      dispatch(
        addGuestAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addGuestAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertGuestAction = actionCreator.async<
  CreateGuestReq[],
  GuestRes[],
  { code: number; description: string }
>("BULK_INSERT_GUEST");

export const bulkInsertGuest =
  (companyId: number, req: CreateGuestReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertGuestAction.started({ ...req }));

    try {
      const result = await GuestApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertGuestAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertGuestAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchGuestAction = actionCreator.async<
  {},
  GuestRes[],
  { code: number; description: string }
>("FETCH_GUEST");

export const fetchGuest =
  (companyId: number, limit?: number, offset?: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchGuestAction.started([]));

    try {
      const result = await GuestApi.findAll(companyId, limit, offset);
      dispatch(
        fetchGuestAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchGuestAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const simpleFetchGuestAction = actionCreator.async<
  {},
  GuestRes[],
  { code: number; description: string }
>("SIMPLE_FETCH_GUEST");

export const simpleFetchGuest =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(simpleFetchGuestAction.started([]));

    try {
      const result = await GuestApi.simpleFindAll(companyId);
      dispatch(
        simpleFetchGuestAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        simpleFetchGuestAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const searchGuestAction = actionCreator.async<
  {},
  GuestRes[],
  { code: number; description: string }
>("SEARCH_GUEST");

export const searchGuest =
  (companyId: number, req: any) => async (dispatch: Dispatch<Action>) => {
    dispatch(searchGuestAction.started({ ...req }));

    try {
      const result = await GuestApi.search(companyId, req);
      dispatch(
        searchGuestAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        searchGuestAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateGuestAction = actionCreator.async<
  UpdateGuestReq,
  GuestRes,
  { code: number; description: string }
>("UPDATE_GUEST");

export const updateGuest =
  (companyId: number, req: UpdateGuestReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateGuestAction.started({ ...req }));

    try {
      const updateGuest = new UpdateGuestReq({ ...req });
      const result = await GuestApi.update(companyId, req.guestId, updateGuest);
      dispatch(
        updateGuestAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateGuestAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteGuestAction = actionCreator.async<
  DeleteGuestReq,
  {},
  { code: number; description: string }
>("DELETE_GUEST");

export const deleteGuest =
  (companyId: number, req: DeleteGuestReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteGuestAction.started({ ...req }));

    try {
      const guest = new DeleteGuestReq({ ...req });
      const result = await GuestApi.delete(companyId, guest);
      dispatch(
        deleteGuestAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteGuestAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const sendMailGuestAction = actionCreator.async<
  { guestId: number },
  {},
  { code: number; description: string }
>("SEND_MAIL_GUEST");

export const sendMailGuest =
  (companyId: number, guestId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(sendMailGuestAction.started({ guestId }));

    try {
      const result = await GuestApi.sendMail(companyId, guestId);
      dispatch(
        sendMailGuestAction.done({
          params: { guestId },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        sendMailGuestAction.failed({
          params: { guestId },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkDeleteGuestAction = actionCreator.async<
  DeleteGuestReq[],
  {},
  { code: number; description: string }
>("BULK_DELETE_GUEST");

export const bulkDeleteGuest =
  (companyId: number, req: DeleteGuestReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkDeleteGuestAction.started({ ...req }));

    try {
      const result = await GuestApi.bulkDelete(companyId, req);
      dispatch(
        bulkDeleteGuestAction.done({
          params: [...req],
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkDeleteGuestAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const guestActions = {
  fetchGuestAction,
  addGuestAction,
  updateGuestAction,
  deleteGuestAction,
  searchGuestAction,
  bulkInsertGuestAction,
  sendMailGuestAction,
  simpleFetchGuestAction,
  bulkDeleteGuestAction,
};

export default guestActions;
