import { DataValidationError } from "constants/DataValidationError";
import { IsDate, IsNotEmpty, IsNumber, IsString } from "class-validator";
import JournalType from "../../enum/JournalType";
import { Type } from "class-transformer";

export default class CreateJournalReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  shopId!: number;

  @Type(() => Date)
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsDate()
  date!: Date;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  type!: JournalType;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsString()
  genre!: string;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsString()
  itemName!: string;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  amount!: number;

  constructor(arg: {
    shopId: number;
    date: Date;
    type: JournalType;
    genre: string;
    itemName: string;
    amount: number;
  }) {
    arg = arg || {};
    this.shopId = arg.shopId;
    this.date = arg.date;
    this.type = arg.type;
    this.genre = arg.genre;
    this.itemName = arg.itemName;
    this.amount = arg.amount;
  }
}
