import { reducerWithInitialState } from "typescript-fsa-reducers";
import castShiftFilterActions from "redux/actions/castShiftFilter";
import CastRes from "types/res/cast/CastRes";

export type CastShiftFilterState = {
  selectShopIds: string[];
  selectCasts: CastRes[];
  selectCastCategoryIds: string[];
};

const initialState = {
  selectShopIds: [],
  selectCasts: [],
  selectCastCategoryIds: [],
} as CastShiftFilterState;

const castShiftFilterReducer = reducerWithInitialState(initialState).case(
  castShiftFilterActions.setCastShiftFilter,
  (state, { selectShopIds, selectCasts, selectCastCategoryIds }) => {
    return {
      selectShopIds: selectShopIds ? selectShopIds : state.selectShopIds,
      selectCasts: selectCasts ? selectCasts : state.selectCasts,
      selectCastCategoryIds: selectCastCategoryIds
        ? selectCastCategoryIds
        : state.selectCastCategoryIds,
    };
  }
);

export default castShiftFilterReducer;
