import { IsDate, IsNotEmpty, MaxLength } from "class-validator";
import Spec from "constants/Spec";
import { DataValidationError } from "constants/DataValidationError";
import TaskPriority from "types/enum/TaskPriority";
import TaskStatus from "types/enum/TaskStatus";
import { Type } from "class-transformer";

export default class CreateStaffTaskReq {
  @MaxLength(Spec.maxLength.staffTask.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.staffTask.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  assigneeId!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  registeredById!: number;

  @Type(() => Date)
  @IsDate()
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  deadline!: Date;

  @MaxLength(Spec.maxLength.staffTask.summary, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.staffTask.summary,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  summary!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  priority!: TaskPriority;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  status: TaskStatus;

  constructor(arg: {
    name: string;
    assigneeId: number;
    registeredById: number;
    deadline: Date;
    summary: string;
    priority: TaskPriority;
    status: TaskStatus;
  }) {
    arg = arg || {};
    this.name = arg.name;
    this.assigneeId = arg.assigneeId;
    this.registeredById = arg.registeredById;
    this.deadline = arg.deadline;
    this.summary = arg.summary;
    this.priority = arg.priority;
    this.status = arg.status;
  }
}
