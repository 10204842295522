import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty } from "class-validator";

export default class CreateMessageBoardCheckReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  staffId!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  messageBoardId!: number;

  constructor(arg: { staffId: number; messageBoardId: number }) {
    arg = arg || {};
    this.staffId = arg.staffId;
    this.messageBoardId = arg.messageBoardId;
  }
}
