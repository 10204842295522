import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import AreaRes from "types/res/area/AreaRes";
import UpdateAreaReq from "types/req/area/UpdateAreaReq";
import CreateAreaReq from "types/req/area/CreateAreaReq";
import DeleteAreaReq from "types/req/area/DeleteAreaReq";
import AreaApi from "../../api/AreaApi";

const actionCreator = actionCreatorFactory();
const addAreaAction = actionCreator.async<
  CreateAreaReq,
  AreaRes,
  { code: number; description: string }
>("ADD_AREA");

export const addArea =
  (companyId: number, req: CreateAreaReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addAreaAction.started({ ...req }));

    try {
      const area = new CreateAreaReq({ ...req });
      const result = await AreaApi.create(companyId, area);
      dispatch(
        addAreaAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addAreaAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertAreaAction = actionCreator.async<
  CreateAreaReq[],
  AreaRes[],
  { code: number; description: string }
>("BULK_INSERT_AREA");
export const bulkInsertArea =
  (companyId: number, req: CreateAreaReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertAreaAction.started({ ...req }));

    try {
      const result = await AreaApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertAreaAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertAreaAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchAreaAction = actionCreator.async<
  {},
  AreaRes[],
  { code: number; description: string }
>("FETCH_AREA");

export const fetchArea =
  (companyId: number, shopId?: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchAreaAction.started([]));

    try {
      const result = await AreaApi.findAll(companyId, shopId);
      dispatch(
        fetchAreaAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchAreaAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateAreaAction = actionCreator.async<
  UpdateAreaReq,
  AreaRes,
  { code: number; description: string }
>("UPDATE_AREA");

export const updateArea =
  (companyId: number, req: UpdateAreaReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateAreaAction.started({ ...req }));

    try {
      const updateArea = new UpdateAreaReq({ ...req });
      const result = await AreaApi.update(companyId, req.areaId, updateArea);
      dispatch(
        updateAreaAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateAreaAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteAreaAction = actionCreator.async<
  DeleteAreaReq,
  {},
  { code: number; description: string }
>("DELETE_AREA");

export const deleteArea =
  (companyId: number, req: DeleteAreaReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteAreaAction.started({ ...req }));

    try {
      const area = new DeleteAreaReq({ ...req });
      const result = await AreaApi.delete(companyId, area);
      dispatch(
        deleteAreaAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteAreaAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const areaActions = {
  fetchAreaAction,
  addAreaAction,
  updateAreaAction,
  deleteAreaAction,
  bulkInsertAreaAction,
};

export default areaActions;
