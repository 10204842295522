import { reducerWithInitialState } from "typescript-fsa-reducers";
import callHistoryActions from "redux/actions/callHistory";
import CallHistoryRes from "types/res/callHistory/CallHistoryRes";
export type CallHistoryState = CallHistoryRes[];

const initialState = [] as CallHistoryState;

const callHistoryReducer = reducerWithInitialState(initialState)
  .case(callHistoryActions.addCallHistoryAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(callHistoryActions.updateCallHistoryAction.done, (state, payload) => {
    return state.map((item) =>
      item.callHistoryId === payload.params.callHistoryId
        ? payload.result
        : item
    );
  })
  .case(callHistoryActions.fetchCallHistoryAction.done, (state, payload) => {
    return [...payload.result];
  });

export default callHistoryReducer;
