import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import GuestCategoryApi from "api/GuestCategoryApi";
import GuestCategoryRes from "types/res/guestCategory/GuestCategoryRes";
import UpdateGuestCategoryReq from "types/req/guestCategory/UpdateGuestCategoryReq";
import CreateGuestCategoryReq from "types/req/guestCategory/CreateGuestCategoryReq";
import DeleteGuestCategoryReq from "types/req/guestCategory/DeleteGuestCategoryReq";

const actionCreator = actionCreatorFactory();
const addGuestCategoryAction = actionCreator.async<
  CreateGuestCategoryReq,
  GuestCategoryRes,
  { code: number; description: string }
>("ADD_GUEST_CATEGORY");

export const addGuestCategory =
  (companyId: number, req: CreateGuestCategoryReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addGuestCategoryAction.started({ ...req }));

    try {
      const guestCategory = new CreateGuestCategoryReq({ ...req });
      const result = await GuestCategoryApi.create(companyId, guestCategory);
      dispatch(
        addGuestCategoryAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addGuestCategoryAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchGuestCategoryAction = actionCreator.async<
  {},
  GuestCategoryRes[],
  { code: number; description: string }
>("FETCH_GUEST_CATEGORY");

export const fetchGuestCategory =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchGuestCategoryAction.started([]));

    try {
      const result = await GuestCategoryApi.findAll(companyId);
      dispatch(
        fetchGuestCategoryAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchGuestCategoryAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const simpleFetchGuestCategoryAction = actionCreator.async<
  {},
  GuestCategoryRes[],
  { code: number; description: string }
>("SIMPLE_FETCH_GUEST_CATEGORY");

export const simpleFetchGuestCategory =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(simpleFetchGuestCategoryAction.started([]));

    try {
      const result = await GuestCategoryApi.simpleFindAll(companyId);
      dispatch(
        simpleFetchGuestCategoryAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        simpleFetchGuestCategoryAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateGuestCategoryAction = actionCreator.async<
  UpdateGuestCategoryReq,
  GuestCategoryRes,
  { code: number; description: string }
>("UPDATE_GUEST_CATEGORY");

export const updateGuestCategory =
  (companyId: number, req: UpdateGuestCategoryReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateGuestCategoryAction.started({ ...req }));

    try {
      const updateGuestCategory = new UpdateGuestCategoryReq({ ...req });
      const result = await GuestCategoryApi.update(
        companyId,
        req.guestCategoryId,
        updateGuestCategory
      );
      dispatch(
        updateGuestCategoryAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateGuestCategoryAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteGuestCategoryAction = actionCreator.async<
  DeleteGuestCategoryReq,
  {},
  { code: number; description: string }
>("DELETE_GUEST_CATEGORY");

export const deleteGuestCategory =
  (companyId: number, req: DeleteGuestCategoryReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteGuestCategoryAction.started({ ...req }));

    try {
      const guestCategory = new DeleteGuestCategoryReq({ ...req });
      const result = await GuestCategoryApi.delete(companyId, guestCategory);
      dispatch(
        deleteGuestCategoryAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteGuestCategoryAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const guestCategoryActions = {
  fetchGuestCategoryAction,
  simpleFetchGuestCategoryAction,
  addGuestCategoryAction,
  updateGuestCategoryAction,
  deleteGuestCategoryAction,
};

export default guestCategoryActions;
