import Modal from "components/Modal";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useModal from "hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardTimePicker } from "@material-ui/pickers";
import ForwardIcon from "@material-ui/icons/Forward";
import DriverShiftRes from "../../../types/res/driverShift/DriverShiftRes";
import { updateDriverShift } from "../../../redux/actions/driverShift";
import UpdateDriverShiftReq from "../../../types/req/driverShift/UpdateDriverShiftReq";
import ShiftStatus from "../../../types/enum/ShiftStatus";
type Props = {
  setStateShifts: any;
  shifts: DriverShiftRes[];
};
const DriverScheduleOnShiftMove: React.FC<Props> = ({
  setStateShifts,
  shifts,
}) => {
  const [driverScheduleOnShiftMoveModal, setDriverScheduleOnShiftMoveShow] =
    useModal("driverScheduleOnShiftMove");
  const companyId = useSelector((state) => state.account.staff.companyId);
  const [loading, setLoading] = useState(false);
  const [changeShift, setChangeShift] = useState<UpdateDriverShiftReq>();
  const [oldShift, setOldShift] = useState<DriverShiftRes>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!driverScheduleOnShiftMoveModal?.meta?.driverShift) return;
    setChangeShift({
      ...driverScheduleOnShiftMoveModal?.meta?.driverShift,
      staffMemo:
        driverScheduleOnShiftMoveModal?.meta?.driverShift?.driver?.remarks,
    });
    const shift = shifts.find(
      (shift) =>
        shift.driverShiftId ===
        driverScheduleOnShiftMoveModal?.meta?.driverShift?.driverShiftId
    );
    setOldShift(shift);
  }, [driverScheduleOnShiftMoveModal?.meta]);

  useEffect(() => {
    if (driverScheduleOnShiftMoveModal.show) return;
    setStateShifts(shifts);
  }, [driverScheduleOnShiftMoveModal?.show]);
  const onClickSubmit = async () => {
    if (!changeShift) return;
    await dispatch(
      updateDriverShift(
        companyId,
        new UpdateDriverShiftReq({
          ...changeShift,
        })
      )
    );
    setDriverScheduleOnShiftMoveShow(false);
  };
  return (
    <Modal
      show={driverScheduleOnShiftMoveModal.show}
      setShow={setDriverScheduleOnShiftMoveShow}
      title={`${driverScheduleOnShiftMoveModal.meta?.driverShift?.driver?.name}さんのシフト変更`}
    >
      <Container>
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">変更前</Typography>
            <KeyboardTimePicker
              label="予定勤務開始"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={oldShift?.planWorkStart || null}
              format="YYYY/MM/DD HH:mm"
              onChange={() => ""}
              minutesStep={5}
              variant="inline"
              disabled={true}
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="予定勤務終了"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={oldShift?.planWorkEnd || null}
              format="YYYY/MM/DD HH:mm"
              onChange={() => ""}
              minutesStep={5}
              variant="inline"
              disabled={true}
              style={{ marginTop: "10px" }}
            />

            <KeyboardTimePicker
              label="実勤務開始"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={oldShift?.actualWorkStart || null}
              format="YYYY/MM/DD HH:mm"
              onChange={() => ""}
              minutesStep={5}
              variant="inline"
              disabled={true}
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="実勤務終了"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={oldShift?.actualWorkEnd || null}
              format="YYYY/MM/DD HH:mm"
              onChange={() => ""}
              minutesStep={5}
              variant="inline"
              disabled={true}
              style={{ marginTop: "10px" }}
            />

            <KeyboardTimePicker
              label="休憩開始"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={oldShift?.workOffStart || null}
              format="YYYY/MM/DD HH:mm"
              onChange={() => ""}
              minutesStep={5}
              variant="inline"
              disabled={true}
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="休憩終了"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={oldShift?.workOffEnd || null}
              format="YYYY/MM/DD HH:mm"
              onChange={() => ""}
              minutesStep={5}
              variant="inline"
              disabled={true}
              style={{ marginTop: "10px" }}
            />
            <TextField
              label="ステータス"
              value={oldShift?.status || ""}
              fullWidth
              select
              variant="outlined"
              margin="normal"
              onChange={() => ""}
              disabled
            >
              {Object.entries(ShiftStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="ドライバーメモ"
              value={oldShift?.driverMemo || ""}
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={() => ""}
              disabled
            />
            <TextField
              label="スタッフメモ"
              value={oldShift?.driverMemo || ""}
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={() => ""}
              disabled
            />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginX={3}
          >
            <ForwardIcon />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">変更後</Typography>
            <KeyboardTimePicker
              label="予定勤務開始"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={changeShift?.planWorkStart || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) =>
                setChangeShift((prev: any) => ({
                  ...prev,
                  planWorkStart: date?.toDate(),
                }))
              }
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="予定勤務終了"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={changeShift?.planWorkEnd || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) =>
                setChangeShift((prev: any) => ({
                  ...prev,
                  planWorkEnd: date?.toDate(),
                }))
              }
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />

            <KeyboardTimePicker
              label="実勤務開始"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={changeShift?.actualWorkStart || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) =>
                setChangeShift((prev: any) => ({
                  ...prev,
                  actualWorkStart: date?.toDate(),
                }))
              }
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="実勤務終了"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={changeShift?.actualWorkEnd || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) =>
                setChangeShift((prev: any) => ({
                  ...prev,
                  actualWorkEnd: date?.toDate(),
                }))
              }
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />

            <KeyboardTimePicker
              label="休憩開始"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={changeShift?.workOffStart || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) =>
                setChangeShift((prev: any) => ({
                  ...prev,
                  workOffStart: date?.toDate(),
                }))
              }
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="休憩終了"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={changeShift?.workOffEnd || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) =>
                setChangeShift((prev: any) => ({
                  ...prev,
                  workOffEnd: date?.toDate(),
                }))
              }
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
            <TextField
              label="ステータス"
              select
              value={changeShift?.status || ""}
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={(event) => {
                event.persist();
                setChangeShift((prev: any) => ({
                  ...prev,
                  status: event.target.value,
                }));
              }}
            >
              {Object.entries(ShiftStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="ドライバーメモ"
              value={changeShift?.driverMemo || ""}
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={(event) => {
                event.persist();
                setChangeShift((prev: any) => ({
                  ...prev,
                  driverMemo: event.target.value,
                }));
              }}
            />
            <TextField
              label="スタッフメモ"
              value={changeShift?.staffMemo || ""}
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={(event) => {
                event.persist();
                setChangeShift((prev: any) => ({
                  ...prev,
                  staffMemo: event.target.value,
                }));
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          style={{ margin: "10px 0" }}
          justifyContent="space-evenly"
        >
          <Button
            variant="contained"
            color="default"
            disabled={loading}
            onClick={() => setDriverScheduleOnShiftMoveShow(false)}
          >
            {loading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "いいえ"
            )}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={onClickSubmit}
          >
            {loading ? <CircularProgress color="inherit" size={24} /> : "はい"}
          </Button>
        </Box>
      </Container>
    </Modal>
  );
};
export default DriverScheduleOnShiftMove;
