import { reducerWithInitialState } from "typescript-fsa-reducers";
import castShiftActions from "redux/actions/castShift";
import CastShiftRes from "types/res/castShift/CastShiftRes";
export type CastShiftState = CastShiftRes[];

const initialState = [] as CastShiftState;

const castShiftReducer = reducerWithInitialState(initialState)
  .case(castShiftActions.addCastShiftAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(castShiftActions.updateCastShiftAction.done, (state, payload) => {
    return state.map((item) =>
      item.castShiftId === payload.params.castShiftId ? payload.result : item
    );
  })
  .case(castShiftActions.deleteCastShiftAction.done, (state, payload) => {
    return state.filter(
      (item) => item.castShiftId !== payload.params.castShiftId
    );
  })
  .case(castShiftActions.fetchCastShiftAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(
    castShiftActions.fetchCastShiftWithPaginationAction.done,
    (state, payload) => {
      return [...payload.result];
    }
  )
  .case(castShiftActions.bulkInsertCastShiftAction.done, (state, payload) => {
    return [...state, ...payload.result];
  });
export default castShiftReducer;
