import { reducerWithInitialState } from "typescript-fsa-reducers";
import notificationActions from "redux/actions/notification";
import NotificationRes from "types/res/notification/NotificationRes";
export type NotificationState = NotificationRes[];

const initialState = [] as NotificationState;

const notificationReducer = reducerWithInitialState(initialState)
  .case(notificationActions.addNotificationAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(notificationActions.updateNotificationAction.done, (state, payload) => {
    return state.map((item) =>
      item.notificationId === payload.params.notificationId
        ? payload.result
        : item
    );
  })
  .case(notificationActions.deleteNotificationAction.done, (state, payload) => {
    return state.filter(
      (item) => item.notificationId !== payload.params.notificationId
    );
  })
  .case(notificationActions.fetchNotificationAction.done, (state, payload) => {
    return [...payload.result];
  });
export default notificationReducer;
