import { Expose, Type } from "class-transformer";
import CastReportRes from "types/res/castReport/CastReportRes";
import CastShiftRes from "types/res/castShift/CastShiftRes";
import DriverShiftRes from "types/res/driverShift/DriverShiftRes";
import OrderRes from "types/res/order/OrderRes";

export default class ChangeLogRes {
  @Type(() => CastReportRes)
  @Expose()
  readonly castReport!: CastReportRes[];

  @Type(() => CastShiftRes)
  @Expose()
  readonly castShift!: CastShiftRes[];

  @Type(() => DriverShiftRes)
  @Expose()
  readonly driverShift!: DriverShiftRes[];

  @Type(() => OrderRes)
  @Expose()
  readonly orderInfo!: OrderRes[];
}
