import { reducerWithInitialState } from "typescript-fsa-reducers";
import notelClassActions from "redux/actions/notelClass";
import NotelClassRes from "types/res/notelClass/NotelClassRes";
export type NotelClassState = NotelClassRes[];

const initialState = [] as NotelClassState;

const notelClassReducer = reducerWithInitialState(initialState)
  .case(notelClassActions.addNotelClassAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(notelClassActions.updateNotelClassAction.done, (state, payload) => {
    return state.map((item) =>
      item.notelClassId === payload.params.notelClassId ? payload.result : item
    );
  })
  .case(notelClassActions.deleteNotelClassAction.done, (state, payload) => {
    return state.filter(
      (item) => item.notelClassId !== payload.params.notelClassId
    );
  })
  .case(notelClassActions.fetchNotelClassAction.done, (state, payload) => {
    return [...payload.result];
  });
export default notelClassReducer;
