import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import CastMobileMenuRes from "types/res/castMobileMenu/CastMobileMenuRes";
import { useDispatch, useSelector } from "react-redux";
import CastMobileMenuApi from "api/CastMobileMenuApi";
import FormLabel from "@material-ui/core/FormLabel";
import toastActions, { ToastType } from "redux/actions/toast";

const CastMobileMenu = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const [castMobileMenu, setCastMobileMenu] = useState<any>(
    {} as CastMobileMenuRes
  );
  useEffect(() => {
    const fetchData = async () => {
      const data = await CastMobileMenuApi.findOne(companyId);
      setCastMobileMenu(data);
    };
    fetchData();
  }, [companyId]);

  const onChange = (key: string, value: boolean) => {
    setCastMobileMenu({ ...castMobileMenu, [key]: value });
  };
  const menus = [
    {
      key: "isShiftDisplay",
      label: "シフト表示",
    },
    {
      key: "isScheduleDisplay",
      label: "スケジュール表示",
    },
    {
      key: "isOrderDetailDisplay",
      label: "予約詳細表示",
    },
    {
      key: "isAttendanceDisplay",
      label: "出勤確認表示",
    },
    {
      key: "isEmergencyDisplay",
      label: "緊急通報表示",
    },
    {
      key: "isPayoffDisplay",
      label: "精算表示",
    },
    {
      key: "isGuestNoteDisplay",
      label: "お客様ノート表示",
    },
  ];

  return (
    <Box display={"flex"} flexDirection={"column"} padding={4}>
      <Typography variant={"h5"}>キャストモバイルメニュー管理</Typography>
      {menus.map((menu) => (
        <Box
          display={"flex"}
          flexDirection={"column"}
          marginY={2}
          key={menu.key}
        >
          <FormLabel component="legend">{menu.label}</FormLabel>
          <RadioGroup
            row
            aria-label={menu.key}
            name={menu.key}
            onChange={(event) => onChange(menu.key, event.target.value === "1")}
            value={castMobileMenu?.[menu.key]}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={"オン"}
              checked={castMobileMenu?.[menu.key]}
            />
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={"オフ"}
              checked={!castMobileMenu?.[menu.key]}
            />
          </RadioGroup>
        </Box>
      ))}
      <Box>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={async () => {
            await CastMobileMenuApi.update(companyId, castMobileMenu);
            setCastMobileMenu(await CastMobileMenuApi.findOne(companyId));
            await dispatch(
              toastActions.showToast({
                text: "保存しました",
                type: ToastType.Success,
              })
            );
          }}
        >
          保存
        </Button>
      </Box>
    </Box>
  );
};
export default CastMobileMenu;
