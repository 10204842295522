import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import StaffApi from "api/StaffApi";
import LoginStaffReq from "types/req/staff/LoginStaffReq";
import LoginRes from "types/res/staff/LoginRes";
import HttpRequestError from "../../errors/HttpRequestError";
import { ErrorCode } from "constants/ErrorCode";
import UpdateCompanyGroupReq from "types/req/companyGroup/UpdateCompanyGroupReq";
import CompanyGroupRes from "types/res/companyGroup/CompanyGroupRes";
import CompanyGroupApi from "api/CompanyGroupApi";
import StaffRes from "types/res/staff/StaffRes";

const actionCreator = actionCreatorFactory();

const signinWithEmailAction = actionCreator.async<
  { email: string; password: string },
  LoginRes,
  { code: number; description: string }
>("SIGNIN");

export const signin =
  (email: string, password: string) => async (dispatch: Dispatch<Action>) => {
    dispatch(signinWithEmailAction.started({ email, password }));

    try {
      const req = new LoginStaffReq({ email, password });
      const result = await StaffApi.login(req);
      if (!result) {
        throw new HttpRequestError(ErrorCode.BAD_REQUEST, 400);
      }
      dispatch(
        signinWithEmailAction.done({
          params: { email, password },
          result,
        })
      );

      return result;
    } catch (error) {
      dispatch(
        signinWithEmailAction.failed({
          params: { email, password },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );

      return error;
    }
  };

const updatePlanAction = actionCreator.async<
  UpdateCompanyGroupReq,
  CompanyGroupRes,
  { code: number; description: string }
>("UPDATE_PLAN");

export const updatePlan =
  (req: UpdateCompanyGroupReq, companyId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updatePlanAction.started({ ...req }));

    try {
      const updateCompanyGroup = new UpdateCompanyGroupReq({ ...req });
      const result = await CompanyGroupApi.update(
        companyId,
        updateCompanyGroup
      );

      dispatch(
        updatePlanAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updatePlanAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const logoutAction = actionCreator.async<
  {},
  {},
  { code: number; description: string }
>("LOGOUT");

export const logout = () => async (dispatch: Dispatch<Action>) => {
  dispatch(logoutAction.started({}));

  try {
    await StaffApi.logout();
    dispatch(
      logoutAction.done({
        params: {},
        result: {},
      })
    );
    return null;
  } catch (error) {
    dispatch(
      logoutAction.failed({
        params: {},
        // @ts-ignore
        error: { code: error.code, description: error.description },
      })
    );

    return error;
  }
};

const checkStaffStatusAction = actionCreator.async<
  {},
  StaffRes,
  { code: number; description: string }
>("CHECK_STAFF_STATUS");
export const checkStaffStatus =
  (companyId: number, staffId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(checkStaffStatusAction.started({}));
    try {
      const result = await StaffApi.checkStaffStatus(companyId, staffId);
      dispatch(
        checkStaffStatusAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        checkStaffStatusAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const accountActions = {
  signinWithEmailAction,
  updatePlanAction,
  logoutAction,
  checkStaffStatusAction,
};

export default accountActions;
