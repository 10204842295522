import { Expose, Type } from "class-transformer";
import DriverShiftRes from "types/res/driverShift/DriverShiftRes";
import CarTransferScheduleRes from "types/res/carTransferSchedule/CarTransferScheduleRes";

export default class DriverRes {
  @Expose() readonly driverId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly email!: string;
  @Expose() readonly activeEmail!: string | null;
  @Expose() readonly name!: string;
  @Expose() readonly address!: string;
  @Expose() readonly tel!: string;
  @Expose() readonly carType!: string;
  @Expose() readonly carNumber!: string;
  @Expose() readonly carColor!: string;
  @Expose() readonly remarks!: string;
  @Expose() readonly sort!: number | null;
  @Type(() => DriverShiftRes)
  @Expose()
  readonly driverShifts!: DriverShiftRes[];
  @Type(() => CarTransferScheduleRes)
  @Expose()
  readonly carTransferSchedules!: CarTransferScheduleRes[];
}
