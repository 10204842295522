import { reducerWithInitialState } from "typescript-fsa-reducers";
import guestNoteActions from "redux/actions/guestNote";
import GuestNoteRes from "types/res/guestNote/GuestNoteRes";
export type GuestNoteState = GuestNoteRes[];

const initialState = [] as GuestNoteState;

const guestNoteReducer = reducerWithInitialState(initialState)
  .case(guestNoteActions.fetchGuestNotesAction.done, (_, payload) => {
    return payload.result;
  })
  .case(
    guestNoteActions.fetchGuestNotesByCastNameIdAction.done,
    (state, payload) => {
      return payload.result;
    }
  )
  .case(guestNoteActions.fetchOneGuestNoteAction.done, (state, payload) => {
    return [payload.result];
  });
export default guestNoteReducer;
