import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty } from "class-validator";

export default class DeleteJournalReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly journalId: number;

  constructor(arg: { journalId: number }) {
    arg = arg || {};
    this.journalId = arg.journalId;
  }
}
