import React from "react";
import { Box, FormControlLabel, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

type Props = {
  formData: any;
  oldOrder: any;
  onChange(key: string): any;
};
const OrderSendMail = ({ formData, onChange, oldOrder }: Props) => {
  return (
    <Box display="flex" flexDirection="column" marginTop={2}>
      <Typography variant="h5">メール送信対象</Typography>
      <FormControlLabel
        style={{ marginTop: "10px" }}
        control={
          <Checkbox
            checked={formData?.sendMailCast}
            onChange={(event) => onChange("sendMailCast")(event.target.checked)}
          />
        }
        label={"キャスト"}
      />
      <FormControlLabel
        style={{ marginTop: "5px" }}
        control={
          <Checkbox
            checked={formData?.sendMailOutwardDriver}
            onChange={(event) =>
              onChange("sendMailOutwardDriver")(event.target.checked)
            }
          />
        }
        label={"送りドライバー"}
      />
      <FormControlLabel
        style={{ marginTop: "5px" }}
        control={
          <Checkbox
            checked={formData?.sendMailReturnDriver}
            onChange={(event) =>
              onChange("sendMailReturnDriver")(event.target.checked)
            }
          />
        }
        label={"迎えドライバー"}
      />
      {oldOrder?.castNameId &&
        formData?.castNameId !== oldOrder?.castNameId && (
          <FormControlLabel
            style={{ marginTop: "10px" }}
            control={
              <Checkbox
                checked={formData?.sendCancelMailOldCast}
                onChange={(event) =>
                  onChange("sendCancelMailOldCast")(event.target.checked)
                }
              />
            }
            label={"旧キャストにキャンセルメール"}
          />
        )}
      {oldOrder?.outwardDriverId &&
        formData?.outwardDriverId !== oldOrder?.outwardDriverId && (
          <FormControlLabel
            style={{ marginTop: "5px" }}
            control={
              <Checkbox
                checked={formData?.sendCancelMailOldOutwardDriver}
                onChange={(event) =>
                  onChange("sendCancelMailOldOutwardDriver")(
                    event.target.checked
                  )
                }
              />
            }
            label={"旧送りドライバーにキャンセルメール"}
          />
        )}
      {oldOrder?.returnDriverId &&
        formData?.returnDriverId !== oldOrder?.returnDriverId && (
          <FormControlLabel
            style={{ marginTop: "5px" }}
            control={
              <Checkbox
                checked={formData?.sendCancelMailOldReturnDriver}
                onChange={(event) =>
                  onChange("sendCancelMailOldReturnDriver")(
                    event.target.checked
                  )
                }
              />
            }
            label={"旧迎えドライバーにキャンセルメール"}
          />
        )}
    </Box>
  );
};
export default OrderSendMail;
