import RequestUtils from "utils/RequestUtils";
import NotificationRes from "types/res/notification/NotificationRes";
import CreateNotificationReq from "types/req/notification/CreateNotificationReq";
import UpdateNotificationReq from "types/req/notification/UpdateNotificationReq";
import DeleteNotificationReq from "types/req/notification/DeleteNotificationReq";

export default class NotificationApi {
  private constructor() {}

  static async findAll(
    companyId: number,
    shopId?: number
  ): Promise<NotificationRes[]> {
    return await RequestUtils.getArray(
      NotificationRes,
      `/notification/findAll/${companyId}${shopId ? `?shopId=${shopId}` : ""}`
    );
  }

  static async create(companyId: number, data: CreateNotificationReq) {
    return await RequestUtils.post(
      NotificationRes,
      `/notification/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    data: UpdateNotificationReq
  ): Promise<NotificationRes> {
    return await RequestUtils.post(
      NotificationRes,
      `/notification/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteNotificationReq) {
    return await RequestUtils.postVoid(
      `/notification/delete/${companyId}`,
      data
    );
  }
}
