import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import EnumUtils from "utils/EnumUtils";
import { PlanType } from "types/enum/PlanType";
import { makeStyles } from "@material-ui/core/styles";
import useModal from "hooks/useModal";
import Modal from "components/Modal";
import PaymentApi from "api/PaymentApi";
import { Alert, AlertTitle } from "@material-ui/lab";
import { updatePlan } from "redux/actions/account";
import StaffRole from "types/enum/StaffRole";
import { useHistory } from "react-router-dom";

const Payment = () => {
  const [clientSecret, setClientSecret] = useState("");
  const history = useHistory();
  const staff = useSelector((state) => state.account?.staff);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
  );
  const [cardPaymentModal, setCardPaymentModal] = useModal("cardPaymentModal");

  useEffect(() => {
    if (!clientSecret) return;
    setCardPaymentModal(true);
  }, [clientSecret]);

  if (
    ![StaffRole.notelMaster, StaffRole.clientMaster].includes(
      EnumUtils.mapToEnum(StaffRole, staff?.role) ||
        StaffRole.clientShopPartTime
    )
  ) {
    history.push("/");
    return null;
  }
  return (
    <>
      <PaymentForm setClientSecret={setClientSecret} />
      {clientSecret && (
        <Modal
          show={cardPaymentModal.show}
          setShow={setCardPaymentModal}
          title={"カード支払い情報登録"}
        >
          <Typography style={{ margin: "20px 0" }}>
            今月の日割り料金：{cardPaymentModal?.meta?.price}円
          </Typography>
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm clientSecret={clientSecret} />
          </Elements>
        </Modal>
      )}
    </>
  );
};

type PaymentFormProps = {
  setClientSecret: any;
};
const PaymentForm = ({ setClientSecret }: PaymentFormProps) => {
  const currentPlan = useSelector(
    (state) => state.account.staff.company.companyGroup.plan
  );
  const [, setSelectFreeModal] = useModal("selectFreeModal");

  const bodyList = [
    {
      title: "月額料金",
      free: "0円",
      standard: "15,000円",
      premium: "30,000円",
      enterprise: "50,000円〜",
    },
    {
      title: "年額料金",
      free: "0円",
      standard: "150,000円",
      premium: "300,000円",
      enterprise: "600,000円〜",
    },
    {
      title: "グループ機能",
      free: "×",
      standard: "○",
      premium: "○",
      enterprise: "○",
    },
    {
      title: "受注履歴保存期間",
      free: "1年",
      standard: "3年",
      premium: "5年",
      enterprise: "無制限",
    },
    {
      title: "店舗管理",
      free: "2件",
      standard: "10件",
      premium: "25件",
      enterprise: "無制限",
    },
    {
      title: "CTI",
      free: "○",
      standard: "○",
      premium: "○",
      enterprise: "○",
    },
    {
      title: "会社管理",
      free: "1社",
      standard: "3社",
      premium: "5社",
      enterprise: "無制限",
    },
    {
      title: "スタッフ管理",
      free: "2名",
      standard: "3名",
      premium: "10名",
      enterprise: "無制限",
    },
    {
      title: "タスク管理・掲示板",
      free: "×",
      standard: "○",
      premium: "○",
      enterprise: "○",
    },
    {
      title: "お知らせ管理",
      free: "5件",
      standard: "10件",
      premium: "25件",
      enterprise: "無制限",
    },
    {
      title: "顧客管理",
      free: "5000件",
      standard: "15000件",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "ポイント管理",
      free: "5000件",
      standard: "15000件",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "キャスト管理",
      free: "20名",
      standard: "40名",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "源氏名管理",
      free: "30件",
      standard: "80件",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "キャスト区分管理",
      free: "8件",
      standard: "無制限",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "指名種管理",
      free: "6件",
      standard: "無制限",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "クラス管理",
      free: "6件",
      standard: "15件",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "ドライバー管理",
      free: "5名",
      standard: "15名",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "オプション管理",
      free: "25件",
      standard: "無制限",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "イベント・割引管理",
      free: "5件",
      standard: "10件",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "媒体管理",
      free: "10件",
      standard: "20件",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "エリア・ホテル管理",
      free: "無制限",
      standard: "無制限",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "コスプレ管理",
      free: "10件",
      standard: "20件",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "コース管理",
      free: "無制限",
      standard: "無制限",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "延長管理",
      free: "無制限",
      standard: "無制限",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "顧客区分管理",
      free: "3件",
      standard: "5件",
      premium: "無制限",
      enterprise: "無制限",
    },
    {
      title: "予約メール管理",
      free: "○",
      standard: "○",
      premium: "○",
      enterprise: "○",
    },
    {
      title: "チャットサポート",
      free: "無料",
      standard: "無料",
      premium: "無料",
      enterprise: "無料",
    },
    {
      title: "オンラインサポート",
      free: "無料",
      standard: "無料",
      premium: "無料",
      enterprise: "無料",
    },
    {
      title: "電話サポート",
      free: "無料",
      standard: "無料",
      premium: "無料",
      enterprise: "無料",
    },
    {
      title: "訪問サポート",
      free: "×",
      standard: "○",
      premium: "○",
      enterprise: "○",
    },
    {
      title: "データインポート代行",
      free: "1件あたり5円",
      standard: "1件あたり3円",
      premium: "無制限",
      enterprise: "無制限",
    },
  ];
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
      cell: {
        position: "sticky",
      },
    })
  );
  const classes = useStyles();
  const [, setPlanSelectModal] = useModal("planSelectModal");

  return (
    <Paper style={{ margin: "40px" }}>
      {EnumUtils.mapToEnum(PlanType, currentPlan) === PlanType.enterprise && (
        <Alert>
          <AlertTitle>
            現在エンタープライズをご契約のためプラン変更を行いたい場合は、お問い合わせください。
          </AlertTitle>
        </Alert>
      )}
      <Alert color={"info"}>
        <AlertTitle>
          以下のドメインよりメールを送付するため、ドメイン解除設定をお願いいたします。
        </AlertTitle>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography>
            またメールが届かない方は、翌日以降画面下部に請求書URLが表示されますので、そちらからご決済をお願いいたします。
          </Typography>
          <Typography>notel.com</Typography>
          <Typography>stripe.com</Typography>
        </Box>
      </Alert>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>フリー</TableCell>
            <TableCell>スタンダード</TableCell>
            <TableCell>プレミアム</TableCell>
            <TableCell>エンタープライズ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => setSelectFreeModal(true)}
                disabled={[PlanType.free, PlanType.enterprise].includes(
                  EnumUtils.mapToEnum(PlanType, currentPlan) || PlanType.free
                )}
              >
                {EnumUtils.mapToEnum(PlanType, currentPlan) === PlanType.free
                  ? "現在のプラン"
                  : "このプランにする"}
              </Button>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => setPlanSelectModal(true, { plan: "starter" })}
                disabled={[PlanType.starter, PlanType.enterprise].includes(
                  EnumUtils.mapToEnum(PlanType, currentPlan) || PlanType.free
                )}
              >
                {EnumUtils.mapToEnum(PlanType, currentPlan) === PlanType.starter
                  ? "現在のプラン"
                  : "このプランにする"}
              </Button>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => setPlanSelectModal(true, { plan: "premium" })}
                disabled={[PlanType.premium, PlanType.enterprise].includes(
                  EnumUtils.mapToEnum(PlanType, currentPlan) || PlanType.free
                )}
              >
                {EnumUtils.mapToEnum(PlanType, currentPlan) === PlanType.premium
                  ? "現在のプラン"
                  : "このプランにする"}
              </Button>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => setPlanSelectModal(true, { plan: "enterprise" })}
                disabled={
                  EnumUtils.mapToEnum(PlanType, currentPlan) ===
                  PlanType.enterprise
                }
              >
                {EnumUtils.mapToEnum(PlanType, currentPlan) ===
                PlanType.enterprise
                  ? "現在のプラン"
                  : "問い合わせする"}
              </Button>
            </TableCell>
          </TableRow>
          {bodyList.map((body, index) => (
            <TableRow key={index}>
              <TableCell>{body.title}</TableCell>
              <TableCell>{body.free}</TableCell>
              <TableCell>{body.standard}</TableCell>
              <TableCell>{body.premium}</TableCell>
              <TableCell>{body.enterprise}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <PlanSelectModal setClientSecret={setClientSecret} />
      <PaymentSendMailModal />
      <SelectFreeModal />
    </Paper>
  );
};

type CheckoutFormProps = {
  clientSecret: string;
};
const CheckoutForm = ({ clientSecret }: CheckoutFormProps) => {
  const [cardPaymentModal, setCardPaymentModal] = useModal("cardPaymentModal");
  const companyGroup = useSelector(
    (state) => state.account.staff.company.companyGroup
  );
  const companyId = useSelector((state) => state.account.staff.companyId);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
      cell: {
        position: "sticky",
      },
    })
  );
  const classes = useStyles();
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement) || { token: "" },
      },
    });
    if (result.error) {
      throw new Error(result.error.message);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        await PaymentApi.updatePaymentMethod(
          companyId,
          result.paymentIntent.payment_method || ""
        );
        dispatch(
          updatePlan(
            {
              ...companyGroup,
              plan: cardPaymentModal.meta?.plan,
            },
            companyId
          )
        );
        setCardPaymentModal(false, {});
      }
    }
  };

  return (
    <>
      <CardElement options={{ hidePostalCode: true }} />
      <Box display="flex" justifyContent="center" margin={2}>
        <Button
          disabled={!stripe}
          onClick={handleSubmit}
          variant="contained"
          className={classes.button}
          color="primary"
        >
          送信
        </Button>
      </Box>
    </>
  );
};

const PlanSelectModal = ({ setClientSecret }: PaymentFormProps) => {
  const [planSelectModal, setPlanSelectModal] = useModal("planSelectModal");
  const companyId = useSelector((state) => state.account.staff.companyId);
  const [, setCardPaymentModal] = useModal("cardPaymentModal");
  const [, setPaymentSendMailModal] = useModal("paymentSendMailModal");
  const companyGroup = useSelector(
    (state) => state.account.staff.company.companyGroup
  );
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
      cell: {
        position: "sticky",
      },
    })
  );
  const classes = useStyles();
  const planPrice: any = {
    starter: {
      month: {
        value: "15,000円",
        id: process.env.REACT_APP_STANDARD_MONTH_PRICE_ID,
      },
      year: {
        value: "150,000円",
        id: process.env.REACT_APP_STANDARD_YEAR_PRICE_ID,
      },
    },
    premium: {
      month: {
        value: "30,000円",
        id: process.env.REACT_APP_PREMIUM_MONTH_PRICE_ID,
      },
      year: {
        value: "300,000円",
        id: process.env.REACT_APP_PREMIUM_YEAR_PRICE_ID,
      },
    },
  };

  const clickAtCardPayment = async (planId: string, isAnnual: boolean) => {
    const customer = await PaymentApi.findOrCreateSubscription(
      companyId,
      planId,
      isAnnual
    );
    setClientSecret(customer.clientSecret);
    if (!customer.clientSecret) {
      dispatch(
        updatePlan(
          {
            ...companyGroup,
            plan: planSelectModal.meta?.plan,
          },
          companyId
        )
      );
    }
    setPlanSelectModal(false, {});
    setCardPaymentModal(true, {
      price: customer.price,
      plan: planSelectModal.meta?.plan,
    });
  };

  const clickAtPayment = async (
    planId: string,
    isAnnual: boolean,
    planType: PlanType
  ) => {
    await PaymentApi.findOrCreateSubscriptionOnMail(
      companyId,
      planId,
      isAnnual,
      planType
    );
    setPlanSelectModal(false, {});
    setPaymentSendMailModal(true);
  };

  return (
    <Modal
      show={planSelectModal.show}
      setShow={setPlanSelectModal}
      title={`${EnumUtils.mapToEnum(
        PlanType,
        planSelectModal.meta?.plan || ""
      )}への切り替え`}
    >
      {EnumUtils.mapToEnum(PlanType, planSelectModal.meta?.plan || "") ===
      PlanType.enterprise ? (
        <Box margin={1}>
          <Typography>右下のチャット欄よりお問い合わせください。</Typography>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              月額
              {planPrice[planSelectModal.meta?.plan || ""]?.month.value}
            </Typography>
            <Box display={"flex"}>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() =>
                  clickAtCardPayment(
                    String(
                      planPrice[planSelectModal.meta?.plan || ""]?.month.id
                    ),
                    false
                  )
                }
              >
                カード支払いへ
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() =>
                  clickAtPayment(
                    String(
                      planPrice[planSelectModal.meta?.plan || ""]?.month.id
                    ),
                    false,
                    planSelectModal.meta?.plan
                  )
                }
              >
                コンビニ支払いへ
              </Button>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              年額{planPrice[planSelectModal.meta?.plan || ""]?.year.value}
            </Typography>
            <Box display={"flex"}>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() =>
                  clickAtCardPayment(
                    String(
                      planPrice[planSelectModal.meta?.plan || ""]?.year.id
                    ),
                    true
                  )
                }
              >
                カード支払いへ
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() =>
                  clickAtPayment(
                    String(
                      planPrice[planSelectModal.meta?.plan || ""]?.year.id
                    ),
                    true,
                    planSelectModal.meta?.plan
                  )
                }
              >
                コンビニ支払いへ
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

const PaymentSendMailModal = () => {
  const [paymentSendMailModal, setPaymentSendMailModal] = useModal(
    "paymentSendMailModal"
  );
  const email = useSelector((state) => state.account.staff.company.email);
  return (
    <Modal show={paymentSendMailModal.show} setShow={setPaymentSendMailModal}>
      <Box>
        <Typography>{email}宛にメールを送信しました。</Typography>
      </Box>
    </Modal>
  );
};

const SelectFreeModal = () => {
  const [selectFreeModal, setSelectFreeModal] = useModal("selectFreeModal");
  const companyId = useSelector((state) => state.account.staff.companyId);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
      cell: {
        position: "sticky",
      },
    })
  );
  const classes = useStyles();
  const changeFreePlan = async () => {
    await PaymentApi.changeFreePlan(companyId);
    setSelectFreeModal(false);
  };
  return (
    <Modal
      show={selectFreeModal.show}
      setShow={setSelectFreeModal}
      title={"フリープランへの変更"}
    >
      <Box display="flex" alignItems="center">
        <Typography>今契約期間でフリープランに変更しますか？</Typography>
        <Button
          style={{ marginLeft: "10px" }}
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={changeFreePlan}
        >
          決定
        </Button>
      </Box>
    </Modal>
  );
};

export default Payment;
