import RequestUtils from "utils/RequestUtils";
import ShopRes from "types/res/shop/ShopRes";
import UpdateShopReq from "types/req/shop/UpdateShopReq";
import DeleteShopReq from "types/req/shop/DeleteShopReq";
import CreateShopReq from "types/req/shop/CreateShopReq";

export default class ShopApi {
  private constructor() {}

  static async findAll(companyId: number): Promise<ShopRes[]> {
    return await RequestUtils.getArray(ShopRes, `/shop/findAll/${companyId}`);
  }

  static async acdCodeList(companyId: number): Promise<string[]> {
    return await RequestUtils.stringList(`/shop/acdCodeList/${companyId}`);
  }

  static async findByAcdCode(
    companyId: number,
    acdCode: string
  ): Promise<ShopRes> {
    return await RequestUtils.get(
      ShopRes,
      `/shop/findByAcdCode/${companyId}/${acdCode}`
    );
  }

  static async create(companyId: number, data: CreateShopReq) {
    return await RequestUtils.post(ShopRes, `/shop/create/${companyId}`, data);
  }

  static async update(
    companyId: number,
    data: UpdateShopReq
  ): Promise<ShopRes> {
    return await RequestUtils.post(ShopRes, `/shop/update/${companyId}`, data);
  }

  static async delete(companyId: number, data: DeleteShopReq) {
    return await RequestUtils.postVoid(`/shop/delete/${companyId}`, data);
  }
}
