import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import CourseApi from "api/CourseApi";
import CourseRes from "types/res/course/CourseRes";
import UpdateCourseReq from "types/req/course/UpdateCourseReq";
import CreateCourseReq from "types/req/course/CreateCourseReq";
import DeleteCourseReq from "types/req/course/DeleteCourseReq";
import FindCourseReq from "types/req/course/FindCourseReq";

const actionCreator = actionCreatorFactory();
const addCourseAction = actionCreator.async<
  CreateCourseReq[],
  CourseRes[],
  { code: number; description: string }
>("ADD_COURSE");

export const addCourse =
  (companyId: number, req: CreateCourseReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addCourseAction.started({ ...req }));

    try {
      const courses = req.map((course) => new CreateCourseReq({ ...course }));
      const result = await CourseApi.create(companyId, courses);
      dispatch(
        addCourseAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addCourseAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCourseAction = actionCreator.async<
  {
    // nothing
  },
  CourseRes[],
  { code: number; description: string }
>("FETCH_COURSE");

export const fetchCourse =
  (companyId: number, shopId?: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCourseAction.started([]));

    try {
      const result = await CourseApi.findAll(companyId, shopId);
      dispatch(
        fetchCourseAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCourseAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchWithPaginationCourseAction = actionCreator.async<
  {
    // nothing
  },
  CourseRes[],
  { code: number; description: string }
>("FETCH_WITH_PAGINATION_COURSE");

export const fetchWithPaginationCourse =
  (companyId: number, req: FindCourseReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchWithPaginationCourseAction.started([]));

    try {
      const result = await CourseApi.findAllWithPagination(companyId, req);
      dispatch(
        fetchWithPaginationCourseAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchWithPaginationCourseAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateCourseAction = actionCreator.async<
  UpdateCourseReq,
  CourseRes,
  { code: number; description: string }
>("UPDATE_COURSE");

export const updateCourse =
  (companyId: number, req: UpdateCourseReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateCourseAction.started({ ...req }));

    try {
      const updateCourse = new UpdateCourseReq({ ...req });
      const result = await CourseApi.update(
        companyId,
        req.courseId,
        updateCourse
      );
      dispatch(
        updateCourseAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateCourseAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteCourseAction = actionCreator.async<
  DeleteCourseReq,
  {},
  { code: number; description: string }
>("DELETE_COURSE");

export const deleteCourse =
  (companyId: number, req: DeleteCourseReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteCourseAction.started({ ...req }));

    try {
      const course = new DeleteCourseReq({ ...req });
      const result = await CourseApi.delete(companyId, course);
      dispatch(
        deleteCourseAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteCourseAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const courseActions = {
  fetchCourseAction,
  fetchWithPaginationCourseAction,
  addCourseAction,
  updateCourseAction,
  deleteCourseAction,
};

export default courseActions;
