import RequestUtils from "utils/RequestUtils";
import MediumPostPeriodRes from "types/res/mediumPostPeriod/MediumPostPeriodRes";
import CreateMediumPostPeriodReq from "types/req/mediumPostPeriod/CreateMediumPostPeriodReq";
import UpdateMediumPostPeriodReq from "types/req/mediumPostPeriod/UpdateMediumPostPeriodReq";
import DeleteMediumPostPeriodReq from "types/req/mediumPostPeriod/DeleteMediumPostPeriodReq";

export default class MediumPostPeriodApi {
  private constructor() {}

  static async create(companyId: number, data: CreateMediumPostPeriodReq) {
    return await RequestUtils.post(
      MediumPostPeriodRes,
      `/mediumPostPeriod/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    mediumPostPeriodId: number,
    data: UpdateMediumPostPeriodReq
  ): Promise<MediumPostPeriodRes> {
    return await RequestUtils.post(
      MediumPostPeriodRes,
      `/mediumPostPeriod/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteMediumPostPeriodReq) {
    return await RequestUtils.postVoid(
      `/mediumPostPeriod/delete/${companyId}`,
      data
    );
  }
}
