import OrderBaseReq from "./OrderBaseReq";
import { IsNotEmpty } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";

export default class UpdateOrderReq extends OrderBaseReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  readonly orderId: number;
  constructor(
    arg: ConstructorParameters<typeof OrderBaseReq>[0] & {
      orderId: number;
    }
  ) {
    arg = arg || {};
    super(arg);
    this.orderId = arg.orderId;
  }
}
