import { DataValidationError } from "constants/DataValidationError";
import {
  IsArray,
  IsBoolean,
  IsDate,
  IsNotEmpty,
  IsOptional,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";
import { Type } from "class-transformer";
import ShiftStatus from "types/enum/ShiftStatus";

export default class CreateCastShiftReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  castId!: number;

  @IsArray()
  shops!: number[];

  @Type(() => Date)
  @IsDate()
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  planWorkStart!: Date;

  @Type(() => Date)
  @IsDate()
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  planWorkEnd!: Date;

  @IsOptional()
  @Type(() => Date)
  @IsDate()
  actualWorkStart!: Date | null;

  @IsOptional()
  @Type(() => Date)
  @IsDate()
  actualWorkEnd!: Date | null;

  @IsOptional()
  @Type(() => Date)
  @IsDate()
  workOffStart!: Date | null;

  @IsOptional()
  @Type(() => Date)
  @IsDate()
  workOffEnd!: Date | null;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  status!: ShiftStatus;

  @IsOptional()
  @MaxLength(Spec.maxLength.castShift.staffMemo, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.castShift.staffMemo,
    },
  })
  staffMemo!: string | null;

  @IsOptional()
  @MaxLength(Spec.maxLength.castShift.castMemo, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.castShift.castMemo,
    },
  })
  castMemo!: string | null;

  @IsOptional()
  scheduleItemColor!: string | null;

  @IsOptional()
  @Type(() => Boolean)
  @IsBoolean()
  isForceShift!: boolean;

  constructor(arg: {
    castId: number;
    shops: number[];
    planWorkStart: Date;
    planWorkEnd: Date;
    actualWorkStart: Date | null;
    actualWorkEnd: Date | null;
    workOffStart: Date | null;
    workOffEnd: Date | null;
    status: ShiftStatus;
    staffMemo: string | null;
    castMemo: string | null;
    scheduleItemColor: string | null;
    isForceShift: boolean;
  }) {
    arg = arg || {};
    this.castId = arg.castId;
    this.shops = arg.shops;
    this.planWorkStart = arg.planWorkStart;
    this.planWorkEnd = arg.planWorkEnd;
    this.actualWorkStart = arg.actualWorkStart;
    this.actualWorkEnd = arg.actualWorkEnd;
    this.workOffStart = arg.workOffStart;
    this.workOffEnd = arg.workOffEnd;
    this.status = arg.status;
    this.staffMemo = arg.staffMemo;
    this.castMemo = arg.castMemo;
    this.scheduleItemColor = arg.scheduleItemColor;
    this.isForceShift = arg.isForceShift;
  }
}
