import { Expose, Type } from "class-transformer";
import GuestRes from "../guest/GuestRes";
import ShopRes from "types/res/shop/ShopRes";
import OrderRes from "types/res/order/OrderRes";
import GuestPointType from "types/enum/GuestPointType";

export default class GuestPointRes {
  @Expose() readonly guestPointId!: number;
  @Expose() readonly point!: number;
  @Expose() readonly type!: GuestPointType;

  @Expose() readonly orderId!: number | null;
  @Type(() => OrderRes)
  @Expose()
  readonly order!: OrderRes | null;

  @Expose() readonly shopId!: number;
  @Type(() => ShopRes)
  @Expose()
  readonly shop!: ShopRes;

  @Expose() readonly guestId!: number;
  @Type(() => GuestRes)
  @Expose()
  readonly guest!: GuestRes;

  @Expose() readonly remarks!: string;
  @Type(() => Date)
  @Expose()
  readonly createdAt!: Date;
}
