import RequestUtils from "utils/RequestUtils";
import StaffRes from "types/res/staff/StaffRes";
import CreateStaffReq from "types/req/staff/CreateStaffReq";
import UpdateStaffReq from "types/req/staff/UpdateStaffReq";
import DeleteStaffReq from "types/req/staff/DeleteStaffReq";
import LoginStaffReq from "types/req/staff/LoginStaffReq";
import LoginRes from "types/res/staff/LoginRes";

export default class StaffApi {
  private constructor() {}
  static async login(data: LoginStaffReq): Promise<LoginRes> {
    return await RequestUtils.post(LoginRes, `/auth/login`, data);
  }

  static async logout() {
    return await RequestUtils.postVoid(`/auth/logout`, undefined);
  }

  static async findCti(companyId: number, tel: string): Promise<StaffRes> {
    return await RequestUtils.get(
      StaffRes,
      `/staff/findCti/${companyId}/${tel}`
    );
  }

  static async checkStaffStatus(
    companyId: number,
    staffId: number
  ): Promise<StaffRes> {
    return await RequestUtils.get(
      StaffRes,
      `/staff/checkStaffStatus/${companyId}/${staffId}`
    );
  }

  static async findAll(companyId: number): Promise<StaffRes[]> {
    return await RequestUtils.getArray(StaffRes, `/staff/findAll/${companyId}`);
  }

  static async create(companyId: number, data: CreateStaffReq) {
    return await RequestUtils.post(
      StaffRes,
      `/staff/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    staffId: number,
    data: UpdateStaffReq
  ): Promise<StaffRes> {
    return await RequestUtils.post(
      StaffRes,
      `/staff/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteStaffReq) {
    return await RequestUtils.postVoid(`/staff/delete/${companyId}`, data);
  }

  static async findByResetToken(resetToken: string) {
    return await RequestUtils.get(
      StaffRes,
      `/staff/findByResetToken?token=${resetToken}`
    );
  }

  static async resetPassword(staffId: number, password: string) {
    return await RequestUtils.post(StaffRes, `/staff/passwordReset`, {
      staffId,
      password,
    });
  }

  static async resetPasswordSendEmail(email: string) {
    return await RequestUtils.postVoid(`/staff/passwordReset/sendEmail`, {
      email,
    });
  }
}
