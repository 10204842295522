import RequestUtils from "utils/RequestUtils";
import FindAreaSalesDataReq from "types/req/areaSales/FindAreaSalesDataReq";

export default class GuestAnalyticsAreaSalesApi {
  private constructor() {}

  static async findData(companyId: number, data: FindAreaSalesDataReq) {
    return await RequestUtils.postPlain(
      `/guestAnalyticsAreaSales/findData/${companyId}`,
      data
    );
  }
}
