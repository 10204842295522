import {
  Box,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";

type Props = {
  id: string;
  title: string;
  options: { id: number; name: string }[];
  selectValues: string[];
  setSelectValues: React.Dispatch<React.SetStateAction<string[]>>;
};
const MultipleSelect = ({
  id,
  title,
  options,
  selectValues,
  setSelectValues,
}: Props) => {
  return (
    <Box>
      <InputLabel id={id}>{title}</InputLabel>
      <Select
        multiple
        value={selectValues}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          setSelectValues((prev: string[]) => {
            if (
              prev.includes("すべて") &&
              (event.target.value as string[]).indexOf("すべて") === -1
            ) {
              return [];
            } else if (
              !prev.includes("すべて") &&
              (event.target.value as string[]).indexOf("すべて") !== -1
            ) {
              return [
                ...options.map((option) => String(option.id)),
                "未設定",
                "すべて",
              ];
            } else if (
              (event.target.value as string[]).length === options.length + 1 &&
              (event.target.value as string[]).indexOf("すべて") === -1
            ) {
              return [
                ...options.map((option) => String(option.id)),
                "未設定",
                "すべて",
              ];
            } else if (
              (event.target.value as string[]).length - 1 <=
              options.length
            ) {
              return (event.target.value as string[]).filter(
                (name) => name !== "すべて"
              );
            } else {
              return event.target.value as string[];
            }
          });
        }}
        input={<Input id="select-multiple-option" />}
        style={{ width: "200px", marginRight: "10px" }}
        renderValue={(selected) => {
          if ((selected as string[]).includes("すべて")) {
            return "すべて";
          } else if ((selected as string[]).includes("未設定")) {
            return [
              ...options
                .filter((option) =>
                  (selected as string[]).includes(String(option.id))
                )
                .map((option) => option.name),
              "未設定",
            ].join(", ");
          } else {
            return options
              .filter((option) =>
                (selected as string[]).includes(String(option.id))
              )
              .map((option) => option.name)
              .join(", ");
          }
        }}
      >
        <MenuItem key={"すべて"} value={"すべて"}>
          <Checkbox name="all" checked={selectValues.indexOf("すべて") > -1} />
          <ListItemText primary={"すべて"} />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.id} value={String(option.id)}>
            <Checkbox
              checked={selectValues.indexOf(String(option.id)) !== -1}
            />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
        <MenuItem key={"未設定"} value={"未設定"}>
          <Checkbox name="null" checked={selectValues.indexOf("未設定") > -1} />
          <ListItemText primary={"未設定"} />
        </MenuItem>
      </Select>
    </Box>
  );
};
export default MultipleSelect;
