import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import CastShiftApi from "api/CastShiftApi";
import CastShiftRes from "types/res/castShift/CastShiftRes";
import UpdateCastShiftReq from "types/req/castShift/UpdateCastShiftReq";
import CreateCastShiftReq from "types/req/castShift/CreateCastShiftReq";
import DeleteCastShiftReq from "types/req/castShift/DeleteCastShiftReq";
import BulkInsertCastShiftReq from "../../types/req/castShift/BulkInsertCastShiftReq";

const actionCreator = actionCreatorFactory();
const addCastShiftAction = actionCreator.async<
  CreateCastShiftReq,
  CastShiftRes,
  { code: number; description: string }
>("ADD_CAST_SHIFT");

export const addCastShift =
  (companyId: number, req: CreateCastShiftReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addCastShiftAction.started({ ...req }));

    try {
      const castShift = new CreateCastShiftReq({ ...req });
      const result = await CastShiftApi.create(companyId, castShift);
      dispatch(
        addCastShiftAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addCastShiftAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCastShiftAction = actionCreator.async<
  {},
  CastShiftRes[],
  { code: number; description: string }
>("FETCH_CAST_SHIFT");

export const fetchCastShift =
  (
    companyId: number,
    startDate: string,
    endDate: string,
    shopId?: number,
    castNameId?: number
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCastShiftAction.started([]));

    try {
      const result = await CastShiftApi.findAll(
        companyId,
        startDate,
        endDate,
        shopId,
        castNameId
      );
      dispatch(
        fetchCastShiftAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCastShiftAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCastShiftWithPaginationAction = actionCreator.async<
  {},
  CastShiftRes[],
  { code: number; description: string }
>("FETCH_CAST_SHIFT_WITH_PAGINATION");

export const fetchCastShiftWithPagination =
  (
    companyId: number,
    startDate: string,
    endDate: string,
    selectCastIds?: number[],
    selectShopIds?: number[],
    selectCastCategoryIds?: number[],
    limit?: number,
    offset?: number
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCastShiftWithPaginationAction.started([]));

    try {
      const result = await CastShiftApi.findAllWithPagination(
        companyId,
        startDate,
        endDate,
        selectCastIds,
        selectShopIds,
        selectCastCategoryIds,
        limit,
        offset
      );
      dispatch(
        fetchCastShiftWithPaginationAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCastShiftWithPaginationAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateCastShiftAction = actionCreator.async<
  UpdateCastShiftReq,
  CastShiftRes,
  { code: number; description: string }
>("UPDATE_CAST_SHIFT");

export const updateCastShift =
  (companyId: number, req: UpdateCastShiftReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateCastShiftAction.started({ ...req }));

    try {
      const updateCastShift = new UpdateCastShiftReq({ ...req });
      const result = await CastShiftApi.update(companyId, updateCastShift);
      dispatch(
        updateCastShiftAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateCastShiftAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteCastShiftAction = actionCreator.async<
  DeleteCastShiftReq,
  {},
  { code: number; description: string }
>("DELETE_CAST_SHIFT");

export const deleteCastShift =
  (companyId: number, req: DeleteCastShiftReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteCastShiftAction.started({ ...req }));

    try {
      const castShift = new DeleteCastShiftReq({ ...req });
      const result = await CastShiftApi.delete(companyId, castShift);
      dispatch(
        deleteCastShiftAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteCastShiftAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertCastShiftAction = actionCreator.async<
  BulkInsertCastShiftReq[],
  CastShiftRes[],
  { code: number; description: string }
>("BULK_INSERT_CAST_SHIFT");

export const bulkInsertCastShift =
  (companyId: number, req: BulkInsertCastShiftReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertCastShiftAction.started({ ...req }));

    try {
      const result = await CastShiftApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertCastShiftAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertCastShiftAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const castShiftActions = {
  fetchCastShiftAction,
  fetchCastShiftWithPaginationAction,
  addCastShiftAction,
  updateCastShiftAction,
  deleteCastShiftAction,
  bulkInsertCastShiftAction,
};

export default castShiftActions;
