import React, { useEffect, useRef, useState } from "react";
import { Box, MenuItem, TextField, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import FullCalendar, { EventContentArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useSelector } from "react-redux";
import OrderRes from "types/res/order/OrderRes";
type CastRakingCalenderProps = {
  displayData: any;
  calendarDisplayKey: string;
  setCalendarDisplayKey: any;
};

const CastRakingCalender = ({
  displayData,
  calendarDisplayKey,
  setCalendarDisplayKey,
}: CastRakingCalenderProps) => {
  const casts = useSelector((state) => state.cast);
  const [date, setDate] = useState<Date>(new Date());
  const [events, setEvents] = useState<any[]>([]);
  const nominations = useSelector((state) => state.nomination);
  const selectList = {
    attendance: "勤怠データ",
    orderCount: "本数データ",
    nominationRate: "指名種率データ",
    revenue: "売上データ",
    cast: "キャスト詳細データ",
  };
  const ref = useRef<FullCalendar>(null);
  const dayClassName = (cast: number, shop: number) => {
    return cast > shop
      ? "fc-custom-day-cast"
      : cast === shop
      ? "fc-custom-day-equal"
      : "fc-custom-day-shop";
  };
  useEffect(() => {
    if (calendarDisplayKey === "attendance") {
      if (!displayData) return;
      const d = displayData.flatMap((data: any) => {
        return [
          {
            id: `cast_${data.date}_commutingTimes`,
            title: `出勤時間 ${
              Math.round(data.data.cast.commutingTimes * 10) / 10 || 0
            }h`,
            start: DateTime.fromFormat(
              data.date,
              FORMAT_TYPE.YEAR_DAY
            ).toJSDate(),
            end: DateTime.fromFormat(
              data.date,
              FORMAT_TYPE.YEAR_DAY
            ).toJSDate(),
            allDay: true,
            className: "fullcalendar_event",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "black",
            dayClassName: dayClassName(
              data.data.cast.commutingTimes,
              data.data.shop.commutingTimes / casts.length
            ),
          },
          {
            id: `cast_${data.date}_tardyCount`,
            title: `遅刻 ${data.data.cast.tardyCount}`,
            start: DateTime.fromFormat(
              data.date,
              FORMAT_TYPE.YEAR_DAY
            ).toJSDate(),
            end: DateTime.fromFormat(
              data.date,
              FORMAT_TYPE.YEAR_DAY
            ).toJSDate(),
            allDay: true,
            className: "fullcalendar_event",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "black",
            dayClassName: dayClassName(
              data.data.cast.tardyCount,
              data.data.shop.tardyCount / casts.length
            ),
          },
          {
            id: `cast_${data.date}_absenceCount`,
            title: `当欠 ${data.data.cast.absenceCount}`,
            start: DateTime.fromFormat(
              data.date,
              FORMAT_TYPE.YEAR_DAY
            ).toJSDate(),
            end: DateTime.fromFormat(
              data.date,
              FORMAT_TYPE.YEAR_DAY
            ).toJSDate(),
            allDay: true,
            className: "fullcalendar_event",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "black",
            dayClassName: dayClassName(
              data.data.cast.absenceCount,
              data.data.shop.absenceCount / casts.length
            ),
          },
        ];
      });
      setEvents(d);
    }
    if (calendarDisplayKey === "orderCount") {
      if (typeof displayData !== "object") return;
      const d: any = Object.entries(displayData).flatMap(([date, value]) => {
        const data: any = value;
        return [
          {
            id: `cast_${date}_orderCount`,
            title: `総本数 ${data?.cast?.orderCount}`,
            start: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            end: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            allDay: true,
            className: "fullcalendar_event",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "black",
            dayClassName: dayClassName(
              data?.cast?.orderCount || 0,
              data?.shop?.orderCount / casts.length || 0
            ),
          },
          ...nominations.flatMap((nomination) => ({
            id: `cast_${date}_${nomination.nominationId}Count`,
            title: `${nomination.name}本数 ${
              data?.cast?.[`${nomination.nominationId}Count`]
            }`,
            start: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            end: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            allDay: true,
            className: "fullcalendar_event",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "black",
            dayClassName: dayClassName(
              data?.cast?.[`${nomination.nominationId}Count`] || 0,
              data?.shop?.[`${nomination.nominationId}Count`] / casts.length ||
                0
            ),
          })),
          {
            id: `cast_${date}_averageScore`,
            title: `平均スコア ${data?.cast?.averageScore}`,
            start: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            end: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            allDay: true,
            className: "fullcalendar_event",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "black",
            dayClassName: dayClassName(
              data?.cast?.averageScore || 0,
              data?.shop?.averageScore / casts.length || 0
            ),
          },
        ];
      });
      setEvents(d);
    }
    if (calendarDisplayKey === "nominationRate") {
      if (typeof displayData !== "object") return;
      const d: any = Object.entries(displayData).flatMap(([date, value]) => {
        const data: any = value;
        if (!data?.cast || !data.shop) return;
        return [
          ...nominations.flatMap((nomination) => {
            return {
              id: `cast_${date}_${nomination.nominationId}Rate`,
              title: `${nomination.name}率 ${
                Math.round(
                  (data?.cast?.[`${nomination.nominationId}Count`] /
                    data?.cast?.orderCount) *
                    10000
                ) / 100 || 0
              }%`,
              start: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
              end: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
              allDay: true,
              className: "fullcalendar_event",
              backgroundColor: "transparent",
              borderColor: "transparent",
              textColor: "black",
              dayClassName: dayClassName(
                Math.round(
                  (data?.cast?.[`${nomination.nominationId}Count`] /
                    data?.cast?.orderCount) *
                    10000
                ) / 100 || 0,
                Math.round(
                  ((data?.shop?.[`${nomination.nominationId}Count`] /
                    data?.shop?.orderCount /
                    casts.length) *
                    10000) /
                    100 || 0
                )
              ),
            };
          }),
        ];
      });
      setEvents(d);
    }
    if (calendarDisplayKey === "revenue") {
      if (typeof displayData !== "object") return;
      const d: any = Object.entries(displayData).flatMap(([date, value]) => {
        const data: any = value;
        if (!Array.isArray(data?.cast) || !Array.isArray(data?.shop)) return;
        return [
          {
            id: `cast_${date}_totalRevenue`,
            title: `総売上 ¥${data?.cast?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.totalFee || 0),
              0
            )}`,
            start: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            end: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            allDay: true,
            className: "fullcalendar_event",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "black",
            dayClassName: dayClassName(
              data?.cast?.reduce(
                (sum: number, c: OrderRes) => sum + (c?.totalFee || 0),
                0
              ),
              data?.shop?.reduce(
                (sum: number, c: OrderRes) => sum + (c?.totalFee || 0),
                0
              ) / casts.length
            ),
          },
          {
            id: `cast_${date}_shopFee`,
            title: `店舗売上 ¥${data?.cast?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.totalShopFee || 0),
              0
            )}`,
            start: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            end: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            allDay: true,
            className: "fullcalendar_event",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "black",
            dayClassName: dayClassName(
              data?.cast?.reduce(
                (sum: number, c: OrderRes) => sum + (c?.totalShopFee || 0),
                0
              ),
              data?.shop?.reduce(
                (sum: number, c: OrderRes) => sum + (c?.totalShopFee || 0),
                0
              ) / casts.length
            ),
          },
          {
            id: `cast_${date}_castFee`,
            title: `キャスト売上 ¥${data?.cast?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
              0
            )}`,
            start: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            end: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            allDay: true,
            className: "fullcalendar_event",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "black",
            dayClassName: dayClassName(
              data?.cast?.reduce(
                (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
                0
              ),
              data?.shop?.reduce(
                (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
                0
              ) / casts.length
            ),
          },
        ];
      });
      setEvents(d);
    }
    if (calendarDisplayKey === "cast") {
      if (typeof displayData !== "object") return;
      const d: any = Object.entries(displayData).flatMap(([date, value]) => {
        const data: any = value;
        return [
          {
            id: `cast_${date}_castFee`,
            title: `キャスト給 ¥${data?.cast?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
              0
            )}`,
            start: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            end: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            allDay: true,
            className: "fullcalendar_event",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "black",
            dayClassName: dayClassName(
              data?.cast?.reduce(
                (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
                0
              ),
              data?.shop?.reduce(
                (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
                0
              ) / casts.length
            ),
          },
          {
            id: `cast_${date}_castFeeByHour`,
            title: `キャスト時給 ¥${
              Math.round(
                (data?.cast?.reduce(
                  (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
                  0
                ) /
                  (data?.cast?.reduce(
                    (sum: number, c: OrderRes) => sum + (c?.courseTime || 0),
                    0
                  ) /
                    60)) *
                  10
              ) / 10 || 0
            }`,
            start: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            end: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            allDay: true,
            className: "fullcalendar_event",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "black",
            dayClassName: dayClassName(
              data?.cast?.reduce(
                (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
                0
              ) /
                (data?.cast?.reduce(
                  (sum: number, c: OrderRes) => sum + (c?.courseTime || 0),
                  0
                ) /
                  60),
              data?.shop?.reduce(
                (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
                0
              ) /
                (data?.shop?.reduce(
                  (sum: number, c: OrderRes) => sum + (c?.courseTime || 0),
                  0
                ) /
                  60) /
                casts.length
            ),
          },
          {
            id: `cast_${date}_averageCourseTime`,
            title: `平均コース時間(分） ${
              Math.round(
                (data?.cast?.reduce(
                  (sum: number, c: OrderRes) => sum + (c?.courseTime || 0),
                  0
                ) /
                  data?.cast?.length) *
                  10
              ) / 10 || 0
            }`,
            start: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            end: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toJSDate(),
            allDay: true,
            className: "fullcalendar_event",
            backgroundColor: "transparent",
            borderColor: "transparent",
            textColor: "black",
            dayClassName: dayClassName(
              data?.cast?.reduce(
                (sum: number, c: OrderRes) => sum + (c?.courseTime || 0),
                0
              ) / data?.cast?.length,
              data?.shop?.reduce(
                (sum: number, c: OrderRes) => sum + (c?.courseTime || 0),
                0
              ) / data?.shop?.length
            ),
          },
        ];
      });
      setEvents(d);
    }
  }, [displayData, calendarDisplayKey]);

  const handlePrev = () => {
    setDate((prev) =>
      DateTime.fromJSDate(prev).minus({ months: 1 }).toJSDate()
    );
    ref?.current?.getApi()?.prev();
  };

  const handleNext = () => {
    setDate((prev) => DateTime.fromJSDate(prev).plus({ months: 1 }).toJSDate());
    ref?.current?.getApi()?.next();
  };

  return (
    <Box display="flex" flexDirection="column">
      <div>
        <TextField
          select
          label={"表示項目"}
          value={calendarDisplayKey}
          style={{ width: "150px" }}
          onChange={(event) => setCalendarDisplayKey(event.target.value)}
        >
          {Object.entries(selectList).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <Box style={{ width: "1000px", height: "800px" }}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent={"flex-end"}>
            <ArrowBackIosIcon onClick={handlePrev} />
            <Typography>
              {DateTime.fromJSDate(date).toFormat(FORMAT_TYPE.YEAR_MONTH)}
            </Typography>
            <ArrowForwardIosIcon onClick={handleNext} />
          </Box>
          <FullCalendar
            ref={ref}
            editable={false}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            headerToolbar={false}
            locale={"ja"}
            dayMaxEventRows={20}
            events={events}
            eventContent={renderEventContent}
            dayCellClassNames={(dayCellContent) => {
              const event = events.find(
                (e) =>
                  DateTime.fromJSDate(e.start).toFormat(
                    FORMAT_TYPE.YEAR_DAY
                  ) ===
                  DateTime.fromJSDate(dayCellContent.date).toFormat(
                    FORMAT_TYPE.YEAR_DAY
                  )
              );
              return event?.dayClassName || "";
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
function renderEventContent(eventContent: EventContentArg) {
  return (
    <Box style={{ backgroundColor: eventContent.event.backgroundColor }}>
      <Typography style={{ fontSize: "12px" }}>
        {eventContent.event.title}
      </Typography>
    </Box>
  );
}
export default CastRakingCalender;
