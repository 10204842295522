import { DataValidationError } from "constants/DataValidationError";
import {
  IsArray,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";
import { DiscountType } from "../../enum/DiscountType";

export default class UpdateDiscountReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly discountId: number;

  @MaxLength(Spec.maxLength.cast.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.cast.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  type!: DiscountType;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  totalFee!: number;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  shopFee!: number;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  castFee!: number;

  @IsOptional()
  @IsNumber()
  sort!: number | null;

  @IsArray()
  shops!: number[];

  constructor(arg: {
    discountId: number;
    name: string;
    type: DiscountType;
    totalFee: number;
    shopFee: number;
    castFee: number;
    sort: number | null;
    shops: number[];
  }) {
    arg = arg || {};
    this.discountId = arg.discountId;
    this.name = arg.name;
    this.type = arg.type;
    this.totalFee = arg.totalFee;
    this.shopFee = arg.shopFee;
    this.castFee = arg.castFee;
    this.sort = arg.sort;
    this.shops = arg.shops;
  }
}
