import RequestUtils from "utils/RequestUtils";
import CastExaminationRes from "types/res/castExamination/CastExaminationRes";
import CreateCastExaminationReq from "types/req/castExamination/CreateCastExaminationReq";
import NeedCastExaminationRes from "types/res/castExamination/NeedCastExaminationRes";

export default class CastExaminationApi {
  private constructor() {}

  static async findByCastIds(
    companyId: number,
    castIds: number[],
    date: string
  ): Promise<NeedCastExaminationRes[]> {
    return await RequestUtils.getArray(
      NeedCastExaminationRes,
      `/castExamination/findByCastIds/${companyId}?castIds=${castIds.join(
        ","
      )}&date=${date}`
    );
  }

  static async create(companyId: number, data: CreateCastExaminationReq) {
    return await RequestUtils.post(
      CastExaminationRes,
      `/castExamination/create/${companyId}`,
      data
    );
  }
}
