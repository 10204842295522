import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTime } from "luxon";
import AreaRes from "types/res/area/AreaRes";
import { fetchArea } from "redux/actions/area";
import { Autocomplete } from "@material-ui/lab";
import CastApi from "api/CastApi";
import CastRes from "types/res/cast/CastRes";
import { fetchCastCategory } from "redux/actions/castCategory";
import { fetchSpOrder } from "redux/actions/order";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useHistory } from "react-router-dom";

const SpOrderList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const orders = useSelector((state) => state.order);
  const areas = useSelector((state) => state.area);
  const castCategories = useSelector((state) => state.castCategory);
  const [startDate, setStartDate] = useState<Date>(DateTime.local().toJSDate());
  const [endDate, setEndDate] = useState<Date>(DateTime.local().toJSDate());
  const [guestName, setGuestName] = useState("");
  const [guestTel, setGuestTel] = useState("");
  const [casts, setCasts] = useState<CastRes[]>([]);
  const [cast, setCast] = useState<CastRes>();
  const [area, setArea] = useState<AreaRes>();
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchArea(companyId));
    const apiCall = async () => {
      const result = await fetchCastCategory(companyId)(dispatch);
      if (result) {
        const castCategoryId = result.find((r) =>
          r.name.includes("在籍")
        )?.castCategoryId;
        const castRes = await CastApi.findAllWithPagination(companyId, {
          limit: 100,
          offset: 0,
          castCategoryIds: castCategoryId ? [castCategoryId] : undefined,
        });
        setCasts(castRes);
      }
    };
    apiCall();
  }, [companyId]);

  const onClickSearch = async () => {
    setIsLoading(true);
    await dispatch(
      fetchSpOrder(companyId, {
        limit: 20,
        offset: 0,
        guestName,
        guestTel,
        castId: cast?.castId,
        areaId: area?.areaId,
        startDate: DateTime.fromJSDate(startDate).toFormat(
          FORMAT_TYPE.YEAR_DAY
        ),
        endDate: DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      })
    );
    setIsLoading(false);
  };

  const prevOrders = async () => {
    if (offset === 0) return;
    setIsLoading(true);
    await dispatch(
      fetchSpOrder(companyId, {
        limit: 20,
        offset: offset - 1,
        guestName,
        guestTel,
        castId: cast?.castId,
        areaId: area?.areaId,
        startDate: DateTime.fromJSDate(startDate).toFormat(
          FORMAT_TYPE.YEAR_DAY
        ),
        endDate: DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      })
    );
    setIsLoading(false);
    setOffset(offset - 1);
  };

  const nextOrders = async () => {
    setIsLoading(true);
    await dispatch(
      fetchSpOrder(companyId, {
        limit: 20,
        offset: offset + 1,
        guestName,
        guestTel,
        castId: cast?.castId,
        areaId: area?.areaId,
        startDate: DateTime.fromJSDate(startDate).toFormat(
          FORMAT_TYPE.YEAR_DAY
        ),
        endDate: DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      })
    );
    setIsLoading(false);
    setOffset(offset + 1);
  };

  const onClickOrder = (orderId: number) => {
    history.push(`/order/${orderId}`);
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={2}
        style={{
          position: "sticky",
          top: "56px",
          left: "0",
          backgroundColor: "white",
          zIndex: "1000",
        }}
      >
        <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
          検索条件
        </Typography>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Box display={"flex"} style={{ marginTop: "8px" }}>
            <DatePicker
              label={"開始日"}
              value={startDate}
              onChange={(event) => setStartDate(event?.toDate() || new Date())}
              animateYearScrolling
              format="YYYY/MM/DD"
            />
            ~
            <DatePicker
              label={"終了日"}
              value={endDate}
              onChange={(event) => setEndDate(event?.toDate() || new Date())}
              animateYearScrolling
              format="YYYY/MM/DD"
            />
          </Box>
        </MuiPickersUtilsProvider>
        <TextField
          style={{ marginTop: "8px" }}
          label={"顧客名"}
          variant="outlined"
          value={guestName}
          size={"small"}
          onChange={(event) => setGuestName(event.target.value)}
        />
        <TextField
          style={{ marginTop: "8px" }}
          label={"顧客電話番号"}
          variant="outlined"
          value={guestTel}
          size={"small"}
          onChange={(event) => setGuestTel(event.target.value)}
        />
        <Autocomplete
          renderInput={(params) => (
            <TextField {...params} label={"キャスト選択"} />
          )}
          options={casts}
          value={cast}
          getOptionLabel={(option) => option.displayName}
          filterOptions={(options, state) =>
            options.filter((option) =>
              option.displayName.includes(state.inputValue)
            )
          }
          onInputChange={async (event, value) => {
            event.persist();
            if (value) {
              const castCategoryId = castCategories.find((r) =>
                r.name.includes("在籍")
              )?.castCategoryId;
              const c = await CastApi.findAllWithPagination(companyId, {
                limit: 100,
                offset: 0,
                displayName: value,
                castCategoryIds: castCategoryId ? [castCategoryId] : undefined,
              });
              setCasts((prev) =>
                Array.from(
                  new Map(
                    [...prev, ...c].map((cast) => [cast.castId, cast])
                  ).values()
                )
              );
            }
          }}
          ListboxProps={{
            onScroll: async (event: React.SyntheticEvent) => {
              const listBoxNode = event.currentTarget;
              if (
                listBoxNode.scrollTop + listBoxNode.clientHeight >
                listBoxNode.scrollHeight - 1
              ) {
                const offset = Math.trunc(casts.length / 100);
                const castCategoryId = castCategories.find((r) =>
                  r.name.includes("在籍")
                )?.castCategoryId;
                const c = await CastApi.findAllWithPagination(companyId, {
                  limit: 100,
                  offset,
                  castCategoryIds: castCategoryId
                    ? [castCategoryId]
                    : undefined,
                });
                setCasts((prev) =>
                  Array.from(
                    new Map(
                      [...prev, ...c].map((cast) => [cast.castId, cast])
                    ).values()
                  )
                );
              }
            },
          }}
          onChange={(_, value) => {
            setCast(value || undefined);
          }}
          noOptionsText={"選択肢がありません"}
        />
        <Autocomplete
          renderInput={(params) => (
            <TextField {...params} label={"エリア選択"} />
          )}
          size={"small"}
          options={areas}
          value={area}
          getOptionLabel={(option) => option.name}
          filterOptions={(options, state) =>
            options.filter((option) => option.name.includes(state.inputValue))
          }
          onChange={(_, value) => {
            setArea(value || undefined);
          }}
          noOptionsText={"選択肢がありません"}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => onClickSearch()}
          style={{ marginTop: "16px" }}
        >
          検索
        </Button>
      </Box>
      <Divider />
      <Box display="flex" flexDirection={"column"} padding={2}>
        <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
          検索結果
        </Typography>
        {isLoading ? (
          <Box display={"flex"} justifyContent="center">
            <CircularProgress />
          </Box>
        ) : orders.length === 0 ? (
          <Box display={"flex"} justifyContent="center">
            <Typography>検索結果がありません。</Typography>
          </Box>
        ) : (
          <Box display={"flex"} flexDirection={"column"}>
            {orders.map((order) => (
              <Box
                display="flex"
                alignItems="flex-end"
                marginTop={2}
                borderBottom={1}
                paddingBottom={1}
                key={order.orderId}
              >
                <Box display="flex" flexDirection="column" flexBasis={"100%"}>
                  <Box display="flex">
                    <Typography style={{ width: "40%" }}>受付日</Typography>
                    <Typography>
                      {DateTime.fromJSDate(order.orderDate).toFormat(
                        FORMAT_TYPE.YEAR_DAY
                      )}
                    </Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>顧客名</Typography>
                    <Typography>{order.guest?.name || "未設定"}</Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>顧客TEL</Typography>
                    <Typography>{order.guest?.tel || "未設定"}</Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>店舗名</Typography>
                    <Typography>{order.shop?.name || "未設定"}</Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>キャスト名</Typography>
                    <Typography>{order.castName?.name || "未設定"}</Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>コース名</Typography>
                    <Typography>{order.course?.name || "未設定"}</Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>エリア</Typography>
                    <Typography>{order.area?.name || "未設定"}</Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>ホテル</Typography>
                    <Typography>{order.hotel?.name || "未設定"}</Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>住所</Typography>
                    <Typography>
                      {order.orderAddress} {order.orderAddress2}
                    </Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>出発</Typography>
                    <Typography>
                      {order.departureTime
                        ? DateTime.fromJSDate(order.departureTime).toFormat(
                            FORMAT_TYPE.TIME
                          )
                        : "未設定"}
                    </Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>
                      予定IN・OUT
                    </Typography>
                    <Typography>
                      {order.planInTime
                        ? DateTime.fromJSDate(order.planInTime).toFormat(
                            FORMAT_TYPE.TIME
                          )
                        : "未設定"}{" "}
                      ~{" "}
                      {order.planOutTime
                        ? DateTime.fromJSDate(order.planOutTime).toFormat(
                            FORMAT_TYPE.TIME
                          )
                        : "未設定"}
                    </Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>実IN・OUT</Typography>
                    <Typography>
                      {order.actualInTime
                        ? DateTime.fromJSDate(order.actualInTime).toFormat(
                            FORMAT_TYPE.TIME
                          )
                        : "未設定"}{" "}
                      ~{" "}
                      {order.actualEndTime
                        ? DateTime.fromJSDate(order.actualEndTime).toFormat(
                            FORMAT_TYPE.TIME
                          )
                        : "未設定"}
                    </Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>売上</Typography>
                    <Typography>{order.totalFee}円</Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>支給</Typography>
                    <Typography>{order.totalCastFee}円</Typography>
                  </Box>
                  <Box display="flex" marginTop={"4px"}>
                    <Typography style={{ width: "40%" }}>店舗売上</Typography>
                    <Typography>{order.totalShopFee}円</Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => onClickOrder(order.orderId)}
                    >
                      編集
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}
            <Box display="flex" justifyContent="space-between">
              <IconButton onClick={prevOrders}>
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton onClick={nextOrders}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default SpOrderList;
