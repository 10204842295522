import { IsNotEmpty } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";

export default class DeleteStaffReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly staffId: number;

  constructor(arg: { staffId: number }) {
    arg = arg || {};
    this.staffId = arg.staffId;
  }
}
