import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty, IsNumber, MaxLength } from "class-validator";
import Spec from "constants/Spec";

export default class UpdateNominationReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly nominationId: number;

  @MaxLength(Spec.maxLength.nomination.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.nomination.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  totalFee!: number;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  shopFee!: number;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @IsNumber()
  castFee!: number;

  constructor(arg: {
    nominationId: number;
    name: string;
    totalFee: number;
    shopFee: number;
    castFee: number;
  }) {
    arg = arg || {};
    this.nominationId = arg.nominationId;
    this.name = arg.name;
    this.totalFee = arg.totalFee;
    this.shopFee = arg.shopFee;
    this.castFee = arg.castFee;
  }
}
