import React from "react";
import styled from "styled-components";

import LoginCard from "components/LoginCard";

const Container = styled.div`
  margin: 50px 10px;
`;
const SpSignin = () => {
  return (
    <Container>
      <LoginCard />
    </Container>
  );
};
export default SpSignin;
