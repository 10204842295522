import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import StaffTaskRes from "types/res/staffTask/StaffTaskRes";
import {
  addStaffTask,
  deleteStaffTask,
  fetchStaffTask,
  updateStaffTask,
} from "redux/actions/staffTask";
import { fetchStaff } from "redux/actions/staff";
import TaskPriority from "types/enum/TaskPriority";
import TaskStatus from "types/enum/TaskStatus";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { FormType } from "components/FormField";
import UpdateStaffTaskReq from "types/req/staffTask/UpdateStaffTaskReq";
import CreateStaffTaskReq from "types/req/staffTask/CreateStaffTaskReq";
import EnumUtils from "../../utils/EnumUtils";

const StaffTask: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const staffList = useSelector((state) => state.staff);
  const staffTasks = useSelector((state) => state.staffTask);
  useEffect(() => {
    dispatch(fetchStaff(companyId));
    dispatch(fetchStaffTask(companyId));
  }, [dispatch, companyId]);
  const headers: { key: keyof StaffTaskRes; label: string }[] = [
    { key: "name", label: "タスク名" },
    { key: "summary", label: "概要" },
    { key: "priority", label: "優先度" },
    { key: "status", label: "ステータス" },
    { key: "deadline", label: "期限" },
    { key: "registeredBy", label: "登録者" },
    { key: "assignee", label: "担当者" },
  ];
  const forms = [
    {
      label: "タスク名",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "概要",
      key: "summary",
      type: FormType.TextArea,
    },
    {
      label: "優先度",
      key: "priority",
      type: FormType.Select,
      options: Object.entries(TaskPriority).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "ステータス",
      key: "status",
      type: FormType.Select,
      options: Object.entries(TaskStatus).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "期限",
      key: "deadline",
      type: FormType.Date,
    },
    {
      label: "登録者",
      key: "registeredById",
      type: FormType.Select,
      options: staffList.map((s) => ({
        id: s.staffId,
        name: s.name,
      })),
    },
    {
      label: "担当者",
      key: "assigneeId",
      type: FormType.Select,
      options: staffList.map((s) => ({ id: s.staffId, name: s.name })),
    },
  ];
  return (
    <StaffTaskTable
      title={"タスク"}
      formId="staffTaskForm"
      forms={forms}
      addFunc={(formData) => addStaffTask(companyId, formData)}
      updateFunc={(formData) => updateStaffTask(companyId, formData)}
      deleteFunc={(item) =>
        deleteStaffTask(companyId, {
          staffTaskId: item?.staffTaskId,
        })
      }
      rows={headers}
      data={staffTasks}
      addType={CreateStaffTaskReq}
      updateType={UpdateStaffTaskReq}
      values={[
        (s) => s.name,
        (s) => s.summary,
        (s) => EnumUtils.mapToEnum(TaskPriority, s.priority) || "未設定",
        (s) => EnumUtils.mapToEnum(TaskStatus, s.status) || "未設定",
        (s) =>
          DateTimeUtils.toFormatAsLocalTimezone(
            s.deadline,
            FORMAT_TYPE.YEAR_DAY
          ),
        (s) => s.registeredBy.name || "",
        (s) => s.assignee.name || "",
      ]}
    />
  );
};

const StaffTaskTable = styled<
  React.FC<
    CommonTableProps<StaffTaskRes, CreateStaffTaskReq, UpdateStaffTaskReq>
  >
>(CommonTable)`
  margin-top: 24px;
`;

export default StaffTask;
