import React from "react";
import { Box, Link } from "@material-ui/core";
import styled from "styled-components";

const StyledLink = styled(Link)`
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px 50px;
  width: 300px;
  text-decoration: none;
  &:hover {
    background-color: #eee;
    text-decoration: none;
  }
`;

const Analytics: React.FC = () => {
  const list = [
    { href: "/accounting", label: "会計" },
    { href: "/callHistory", label: "着信履歴" },
    { href: "/callHistory/graph", label: "着信履歴推移表" },
    { href: "/castAnalyticsRanking", label: "キャスト分析" },
    { href: "/guestAnalytics/detail", label: "顧客分析" },
    { href: "/guestAnalytics", label: "CPM顧客リスト" },
    { href: "/guestAnalytics/summarySales", label: "売上サマリー" },
    { href: "/guestAnalytics/profileSales", label: "プロフィール別売上" },
    { href: "/guestAnalytics/areaSales", label: "エリア別売上" },
    { href: "/guestAnalytics/salesVolume", label: "売上昨対比較" },
    { href: "/guestAnalytics/cpmAnalytics", label: "CPM分析" },
    { href: "/guestAnalytics/mediaLtv", label: "媒体別LTV分析" },
    { href: "/guestAnalytics/guestGroupLtv", label: "ランク別LTV分析" },
    { href: "/guestAnalytics/recruitingMediaLtv", label: "採用媒体別LTV分析" },
  ];

  return (
    <>
      <Box display="flex" flexWrap="wrap" style={{ margin: "20px" }}>
        {list.map((item) => (
          <StyledLink href={item.href} key={item.href}>
            {item.label}
          </StyledLink>
        ))}
      </Box>
    </>
  );
};
export default Analytics;
