import { Expose, Type } from "class-transformer";
import AdditionalTimeRes from "types/res/additionalTime/AdditionalTimeRes";
import OrderRes from "types/res/order/OrderRes";

export default class AdditionalTimeOrderRes {
  @Expose() readonly orderId!: number;
  @Type(() => OrderRes)
  @Expose()
  readonly order!: OrderRes;
  @Expose() readonly additionalTimeId!: number;
  @Type(() => AdditionalTimeRes)
  @Expose()
  readonly additionalTime!: AdditionalTimeRes;
  @Expose() readonly count!: number;
}
