import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import { useDispatch, useSelector } from "react-redux";
import { fetchShops } from "redux/actions/shop";
import AccountingTable from "./AccountingTable";
import { fetchCompaniesByCompanyGroupId } from "redux/actions/company";
import CompanyRes from "../../types/res/company/CompanyRes";
import { fetchJournal } from "redux/actions/journal";
import JournalRes from "types/res/journal/JournalRes";
import { fetchAccounting } from "redux/actions/accouting";

const Accounting = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const companyGroupId = useSelector(
    (state) => state.account.staff.company.companyGroupId
  );
  const companies = useSelector((state) => state.company);
  const shops = useSelector((state) => state.shop);
  const journals = useSelector((state) => state.journal);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [requestStartDate, setRequestStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [requestEndDate, setRequestEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );

  const [filterJournal, setFilterJournal] = useState<JournalRes[]>([]);
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [company, setCompany] = useState({} as CompanyRes);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchCompaniesByCompanyGroupId(companyId, companyGroupId));
  }, [companyId, companyGroupId]);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    setFilterJournal(
      journals.filter((journal) => selectShops.includes(String(journal.shopId)))
    );
  }, [journals, selectShops]);

  useEffect(() => {
    setCompany(
      companies.find((c) => c.companyId === companyId) || ({} as CompanyRes)
    );
  }, [companies]);

  useEffect(() => {
    dispatch(
      fetchJournal(
        companyId,
        DateTime.fromJSDate(requestStartDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(requestEndDate).toFormat(FORMAT_TYPE.YEAR_DAY)
      )
    );
    dispatch(
      fetchAccounting(
        companyId,
        DateTime.fromJSDate(requestStartDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(requestEndDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        selectShops.filter((selectShop) => selectShop !== "すべて").join(",")
      )
    );
  }, [requestStartDate, requestEndDate, companyId, selectShops]);

  return (
    <Box display="flex" flexDirection="column">
      <Box margin={2} display="flex" alignItems="center">
        <TextField
          type="date"
          label="開始日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) =>
            setStartDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            )
          }
          onBlur={(event) =>
            setRequestStartDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            )
          }
        />
        <TextField
          type="date"
          label="終了日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) =>
            setEndDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            )
          }
          onBlur={(event) =>
            setRequestEndDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            )
          }
        />
        <Box>
          <InputLabel id="select-multiple-shop">店舗</InputLabel>
          <Select
            multiple
            value={selectShops}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectShops((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length === shops.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= shops.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-shop" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return shops
                  .filter((shop) =>
                    (selected as string[]).includes(String(shop.shopId))
                  )
                  .map((shop) => shop.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectShops.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {shops.map((shop) => (
              <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                <Checkbox
                  checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                />
                <ListItemText primary={shop.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <AccountingTable
        company={company}
        journals={filterJournal}
        shops={shops}
      />
    </Box>
  );
};
export default Accounting;
