import { reducerWithInitialState } from "typescript-fsa-reducers";
import toastActions, { ShowToastPayloadType } from "redux/actions/toast";

export type ToastState = ShowToastPayloadType | null;

const initialState = null as ToastState;

const toastReducer = reducerWithInitialState(initialState)
  .case(toastActions.showToast, (_, { text, type }) => ({ text, type }))
  .case(toastActions.hideToast, () => null);

export default toastReducer;
