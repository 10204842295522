import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import ShiftStatus from "types/enum/ShiftStatus";
import StaffRes from "../staff/StaffRes";
import CastRes from "../cast/CastRes";

export default class CastShiftRes {
  @Expose() readonly castShiftId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly castId!: number;
  @Type(() => Date)
  @Expose()
  readonly planWorkStart!: Date;
  @Type(() => Date)
  @Expose()
  readonly planWorkEnd!: Date;
  @Type(() => Date)
  @Expose()
  readonly actualWorkStart!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly actualWorkEnd!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly workOffStart!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly workOffEnd!: Date | null;
  @Expose() readonly status!: ShiftStatus;
  @Expose() readonly staffMemo!: string | null;
  @Expose() readonly castMemo!: string | null;
  @Type(() => Boolean)
  @Expose()
  readonly isForceShift!: boolean;
  @Expose() readonly scheduleItemColor!: string | null;

  @Type(() => CastRes)
  @Expose()
  readonly cast!: CastRes;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];

  @Type(() => Date)
  @Expose()
  readonly updatedAt!: Date;

  @Type(() => StaffRes)
  @Expose()
  readonly updatedBy!: StaffRes;
  @Expose() readonly updatedById!: number | null;
}
