import React, { useEffect, useState } from "react";
import { Box, Icon, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import StaffRole from "../../types/enum/StaffRole";
import EnumUtils from "../../utils/EnumUtils";
import styled from "styled-components";
import SettingApi from "api/SettingApi";
import SettingCountRes from "types/res/setting/SettingCountRes";
import CompanyGroup from "pages/CompanyGroup";
import Company from "pages/Company";
import Shop from "pages/Shop";
import Staff from "pages/Staff";
import Option from "pages/Option";
import AdditionalTime from "pages/AdditionalTime";
import Medium from "pages/Medium";
import Area from "pages/Area";
import Hotel from "pages/Hotel";
import Cosplay from "pages/Cosplay";
import Discount from "pages/Discount";
import Driver from "pages/Driver";
import Nomination from "pages/Nomination";
import NotelClass from "pages/NotelClass";
import Course from "pages/Course";
import GuestCategory from "pages/GuestCategory";
import Guest from "pages/Guest";
import GuestPointHistory from "pages/GuestPointHistory";
import CastCategory from "pages/CastCategory";
import Cast from "pages/Cast";
import CastName from "pages/CastName";
import Notification from "pages/Notification";
import SettingBookEmail from "pages/SettingBookEmail";
import DriverSettingBookEmail from "pages/DriverSettingBookEmail";
import RequestGuest from "pages/RequestGuest";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CastMobileMenu from "pages/CastMobileMenu";
import GuestNote from "pages/GuestNote";
import RecruitingMedia from "pages/RecruitingMedia";
const StyledBox = styled(Box)`
  padding: 5px 20px;
  text-decoration: none;
  cursor: pointer;
  color: #2196f3;
  width: 400px;
`;
const Setting: React.FC = () => {
  const role = useSelector((state) => state.account.staff.role);
  const companyId = useSelector((state) => state.account.staff.companyId);
  const [settingCount, setSettingCount] = useState<SettingCountRes>(
    {} as SettingCountRes
  );
  const [selectedMaster, setSelectedMaster] = useState<string>("");
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  useEffect(() => {
    const apiCall = async () => {
      setSettingCount(await SettingApi.countAll(companyId));
    };
    apiCall();
  }, [companyId]);
  let infomationMenu = [
    {
      href: "/notification",
      label: "お知らせ管理",
      table: "notification",
      count: settingCount.notification,
    },
  ];
  if (
    ![
      StaffRole.areaEmployee,
      StaffRole.clientShopEmployee,
      StaffRole.clientShopPartTime,
      StaffRole.areaPartTime,
    ].includes(EnumUtils.mapToEnum(StaffRole, role) || StaffRole.notelMaster)
  ) {
    infomationMenu = [
      ...infomationMenu,
      {
        href: "/settingBookEmail",
        label: "予約メール管理",
        table: "settingBookEmail",
        count: settingCount.settingBookEmail,
      },
      {
        href: "/driverSettingBookEmail",
        label: "ドライバー予約メール管理",
        table: "driverSettingBookEmail",
        count: settingCount.settingBookEmail,
      },
    ];
  }
  let bookingMenu: {
    href: string;
    label: string;
    table: string;
    count: number;
  }[] = [];
  if (
    ![
      StaffRole.areaEmployee,
      StaffRole.clientShopEmployee,
      StaffRole.clientShopPartTime,
      StaffRole.areaPartTime,
    ].includes(EnumUtils.mapToEnum(StaffRole, role) || StaffRole.notelMaster)
  ) {
    bookingMenu = [
      {
        href: "/course",
        label: "コース管理",
        table: "course",
        count: settingCount.course,
      },
      {
        href: "/additionalTime",
        label: "延長管理",
        table: "additionalTime",
        count: settingCount.additionalTime,
      },
      {
        href: "/option",
        label: "オプション管理",
        table: "option",
        count: settingCount.option,
      },
      {
        href: "/nomination",
        label: "指名種管理",
        table: "nomination",
        count: settingCount.nomination,
      },
      {
        href: "/class",
        label: "クラス管理",
        table: "class",
        count: settingCount.notelClass,
      },
    ];
  }
  bookingMenu = [
    ...bookingMenu,
    {
      href: "/discount",
      label: "イベント・割引管理",
      table: "discount",
      count: settingCount.discount,
    },
    {
      href: "/medium",
      label: "媒体管理",
      table: "medium",
      count: settingCount.medium,
    },
    {
      href: "/area",
      label: "エリア管理",
      table: "area",
      count: settingCount.area,
    },
    {
      href: "/hotel",
      label: "ホテル管理",
      table: "hotel",
      count: settingCount.hotel,
    },
    {
      href: "/cosplay",
      label: "コスプレ管理",
      table: "cosplay",
      count: settingCount.cosplay,
    },
    {
      href: "/driver",
      label: "ドライバー管理",
      table: "driver",
      count: settingCount.driver,
    },
  ];

  let castMenu = [
    {
      href: "/cast",
      label: "在籍管理",
      table: "cast",
      count: settingCount.cast,
    },
    {
      href: "/castName",
      label: "源氏名管理",
      table: "castName",
      count: settingCount.castName,
    },
  ];
  if (
    ![
      StaffRole.areaEmployee,
      StaffRole.clientShopEmployee,
      StaffRole.clientShopPartTime,
      StaffRole.areaPartTime,
    ].includes(EnumUtils.mapToEnum(StaffRole, role) || StaffRole.notelMaster)
  ) {
    castMenu = [
      ...castMenu,
      {
        href: "/castCategory",
        label: "キャスト区分管理",
        table: "castCategory",
        count: settingCount.castCategory,
      },
      {
        href: "/recruitingMedia",
        label: "採用媒体管理",
        table: "recruitingMedia",
        count: settingCount.recruitingMedia,
      },
    ];
  }

  const guestMenu = [
    {
      href: "/guestCategory",
      label: "顧客区分管理",
      table: "guestCategory",
      count: settingCount.guestCategory,
    },
    {
      href: "/guest",
      label: "顧客管理",
      table: "guest",
      count: settingCount.guest,
    },
    {
      href: "/guestPoint",
      label: "ポイント管理",
      table: "guestPoint",
      count: settingCount.guestPoint,
    },

    {
      href: "/requestGuest",
      label: "登録申請顧客管理",
      table: "requestGuest",
      count: settingCount.requestGuest,
    },
  ];
  let managementMenu = [
    {
      href: "/shop",
      label: "店舗管理",
      table: "shop",
      count: settingCount.shop,
    },
    {
      href: "/staff",
      label: "スタッフ管理",
      table: "staff",
      count: settingCount.staff,
    },
    {
      href: "/castMobileMenu",
      label: "キャストモバイルメニュー管理",
      table: "castMobileMenu",
      count: 1,
    },
    {
      href: "/guestNote",
      label: "顧客ノート管理",
      table: "guestNote",
      count: settingCount.guestNote,
    },
  ];
  if (EnumUtils.mapToEnum(StaffRole, role) === StaffRole.notelMaster) {
    managementMenu = [
      ...managementMenu,
      {
        href: "/company",
        label: "会社管理",
        table: "company",
        count: settingCount.company,
      },
      {
        href: "/companyGroup",
        label: "グループ管理",
        table: "companyGroup",
        count: settingCount.companyGroup,
      },
    ];
  }

  const TableComponent = () => {
    const component: { [key: string]: JSX.Element } = {
      companyGroup: <CompanyGroup />,
      company: <Company />,
      shop: <Shop />,
      staff: <Staff />,
      option: <Option />,
      additionalTime: <AdditionalTime />,
      medium: <Medium />,
      area: <Area />,
      hotel: <Hotel />,
      cosplay: <Cosplay />,
      discount: <Discount />,
      driver: <Driver />,
      nomination: <Nomination />,
      class: <NotelClass />,
      course: <Course />,
      guestCategory: <GuestCategory />,
      guest: <Guest />,
      guestPoint: <GuestPointHistory />,
      castCategory: <CastCategory />,
      cast: <Cast />,
      castName: <CastName />,
      notification: <Notification />,
      settingBookEmail: <SettingBookEmail />,
      driverSettingBookEmail: <DriverSettingBookEmail />,
      requestGuest: <RequestGuest />,
      castMobileMenu: <CastMobileMenu />,
      guestNote: <GuestNote />,
      recruitingMedia: <RecruitingMedia />,
    };
    if (!selectedMaster) {
      return (
        <Box display={"flex"} padding={4}>
          <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
            左から項目を選択してください
          </Typography>
        </Box>
      );
    }
    return (
      <Box
        style={{
          width: sideBarOpen ? "calc(100% - 400px)" : "calc(100% - 71px)",
          maxHeight: "calc(100vh - 188px)",
          overflowY: "scroll",
        }}
      >
        {component[selectedMaster]}
      </Box>
    );
  };
  return (
    <>
      <Box display={"flex"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          paddingTop={2}
          style={{
            backgroundColor: "whitesmoke",
            height: "calc(100vh - 188px)",
            overflowY: "scroll",
          }}
        >
          <Box
            paddingX={2}
            style={{ cursor: "pointer" }}
            onClick={() => setSideBarOpen(!sideBarOpen)}
          >
            <Icon>menu</Icon>
          </Box>
          {sideBarOpen ? (
            <Box display={"flex"} flexDirection={"column"} paddingTop={3}>
              <SettingSideBarMenu
                menu={infomationMenu}
                title={"インフォメーション"}
                icon={"info"}
                selectedMaster={selectedMaster}
                setSelectedMaster={setSelectedMaster}
              />
              <SettingSideBarMenu
                menu={bookingMenu}
                title={"予約メニュー"}
                icon={"eventNote"}
                selectedMaster={selectedMaster}
                setSelectedMaster={setSelectedMaster}
              />
              <SettingSideBarMenu
                menu={castMenu}
                title={"キャストメニュー"}
                icon={"person"}
                selectedMaster={selectedMaster}
                setSelectedMaster={setSelectedMaster}
              />
              <SettingSideBarMenu
                menu={guestMenu}
                title={"顧客メニュー"}
                icon={"assignmentInd"}
                selectedMaster={selectedMaster}
                setSelectedMaster={setSelectedMaster}
              />
              <SettingSideBarMenu
                menu={managementMenu}
                title={"管理者メニュー"}
                icon={"business"}
                selectedMaster={selectedMaster}
                setSelectedMaster={setSelectedMaster}
              />
            </Box>
          ) : (
            <Box
              display={"flex"}
              flexDirection={"column"}
              paddingX={2}
              onClick={() => setSideBarOpen(true)}
            >
              <Box paddingTop={1}>
                <Icon>info</Icon>
              </Box>
              <Box paddingTop={1}>
                <Icon>eventNote</Icon>
              </Box>
              <Box paddingTop={1}>
                <Icon>person</Icon>
              </Box>
              <Box paddingTop={1}>
                <Icon>assignmentInd</Icon>
              </Box>
              <Box paddingTop={1}>
                <Icon>business</Icon>
              </Box>
            </Box>
          )}
        </Box>
        <Box>{TableComponent}</Box>
      </Box>
    </>
  );
};

type SettingSideBarMenuProps = {
  menu: {
    label: string;
    href: string;
    table: string;
    count: number;
  }[];
  title: string;
  icon: string;
  selectedMaster: string;
  setSelectedMaster: (table: string) => void;
};
const SettingSideBarMenu = ({
  menu,
  title,
  icon,
  selectedMaster,
  setSelectedMaster,
}: SettingSideBarMenuProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Box display={"flex"} flexDirection={"column"} padding={2}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ cursor: "pointer", width: "400px" }}
        onClick={() => setOpen(!open)}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Icon>{icon}</Icon>
          <Typography
            style={{ marginLeft: "8px", fontSize: "20px", fontWeight: "bold" }}
          >
            {title}
          </Typography>
        </Box>
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>
      {open
        ? menu.map((item, index) => (
            <StyledBox
              display="flex"
              key={index}
              style={{
                backgroundColor: selectedMaster === item.table ? "#eee" : "",
              }}
              onClick={() => setSelectedMaster(item.table)}
              flexDirection={"column"}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems={"center"}
                style={{ color: "#212121" }}
                paddingX={1}
              >
                <Typography
                  style={{
                    margin: "0 5px",
                    fontSize: "14px",
                  }}
                >
                  {item.label}
                </Typography>
                <Typography
                  color={item.count === 0 ? "error" : "textSecondary"}
                >
                  {item.count}件
                </Typography>
              </Box>
            </StyledBox>
          ))
        : null}
    </Box>
  );
};
export default Setting;
