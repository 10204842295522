import { reducerWithInitialState } from "typescript-fsa-reducers";
import cosplayActions from "redux/actions/cosplay";
import CosplayRes from "types/res/cosplay/CosplayRes";
export type CosplayState = CosplayRes[];

const initialState = [] as CosplayState;

const cosplayReducer = reducerWithInitialState(initialState)
  .case(cosplayActions.addCosplayAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(cosplayActions.updateCosplayAction.done, (state, payload) => {
    return state.map((item) =>
      item.cosplayId === payload.params.cosplayId ? payload.result : item
    );
  })
  .case(cosplayActions.deleteCosplayAction.done, (state, payload) => {
    return state.filter((item) => item.cosplayId !== payload.params.cosplayId);
  })
  .case(cosplayActions.fetchCosplayAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(cosplayActions.bulkInsertCosplayAction.done, (state, payload) => {
    return [...state, ...payload.result];
  });
export default cosplayReducer;
