import RequestUtils from "utils/RequestUtils";
import CastRes from "types/res/cast/CastRes";
import CreateCastReq from "types/req/cast/CreateCastReq";
import UpdateCastReq from "types/req/cast/UpdateCastReq";
import DeleteCastReq from "types/req/cast/DeleteCastReq";
import querystring from "querystring";
import FindCastReq from "types/req/cast/FindCastReq";

export default class CastApi {
  private constructor() {
    // not supported
  }
  static async findCti(companyId: number, tel: string): Promise<CastRes> {
    return await RequestUtils.get(CastRes, `/cast/findCti/${companyId}/${tel}`);
  }

  static async findAll(companyId: number): Promise<CastRes[]> {
    return await RequestUtils.getArray(CastRes, `/cast/findAll/${companyId}`);
  }

  static async findOne(companyId: number, castId: number): Promise<CastRes> {
    return await RequestUtils.get(
      CastRes,
      `/cast/findOne/${companyId}/${castId}`
    );
  }

  static async findAllWithPagination(
    companyId: number,
    req: FindCastReq
  ): Promise<CastRes[]> {
    return await RequestUtils.postArray(
      CastRes,
      `/cast/findAllWithPagination/${companyId}`,
      req
    );
  }

  static async count(companyId: number, req: FindCastReq) {
    return await RequestUtils.countPost(`/cast/count/${companyId}`, req);
  }

  static async findAllActualWorkCastList(
    companyId: number,
    startDate: string,
    endDate: string,
    shopIds: string,
    notelClassIds: string,
    castCategoryIds: string,
    enrolled: boolean,
    workingResult: boolean
  ): Promise<CastRes[]> {
    const query = {
      shopIds,
      notelClassIds,
      castCategoryIds,
      enrolled,
      workingResult,
    };
    return await RequestUtils.getArray(
      CastRes,
      `/cast/findAllActualWorkCastList/${companyId}/${startDate}/${endDate}?${querystring.stringify(
        query
      )}`
    );
  }

  static async create(companyId: number, data: CreateCastReq) {
    return await RequestUtils.post(CastRes, `/cast/create/${companyId}`, data);
  }

  static async bulkInsert(companyId: number, data: CreateCastReq[]) {
    return await RequestUtils.postArray(
      CastRes,
      `/cast/bulkInsert/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    castId: number,
    data: UpdateCastReq
  ): Promise<CastRes> {
    return await RequestUtils.post(CastRes, `/cast/update/${companyId}`, data);
  }

  static async delete(companyId: number, data: DeleteCastReq) {
    return await RequestUtils.postVoid(`/cast/delete/${companyId}`, data);
  }

  static async bulkDelete(companyId: number, data: DeleteCastReq[]) {
    return await RequestUtils.postVoid(`/cast/bulkDelete/${companyId}`, data);
  }

  static async downloadTemplate(companyId: number) {
    return await RequestUtils.getBlob(`/cast/template/${companyId}`, true);
  }

  static async sendMailCast(companyId: number, castId: number) {
    return await RequestUtils.postVoid(
      `/cast/sendMail/${companyId}/${castId}`,
      {}
    );
  }
}
