import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import DriverShiftRes from "types/res/driverShift/DriverShiftRes";
import UpdateDriverShiftReq from "types/req/driverShift/UpdateDriverShiftReq";
import CreateDriverShiftReq from "types/req/driverShift/CreateDriverShiftReq";
import DeleteDriverShiftReq from "types/req/driverShift/DeleteDriverShiftReq";
import BulkInsertDriverShiftReq from "types/req/driverShift/BulkInsertDriverShiftReq";
import DriverShiftApi from "api/DriverShiftApi";

const actionCreator = actionCreatorFactory();
const addDriverShiftAction = actionCreator.async<
  CreateDriverShiftReq,
  DriverShiftRes,
  { code: number; description: string }
>("ADD_DRIVER_SHIFT");

export const addDriverShift =
  (companyId: number, req: CreateDriverShiftReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addDriverShiftAction.started({ ...req }));

    try {
      const driverShift = new CreateDriverShiftReq({ ...req });
      const result = await DriverShiftApi.create(companyId, driverShift);
      dispatch(
        addDriverShiftAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addDriverShiftAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchDriverShiftAction = actionCreator.async<
  {},
  DriverShiftRes[],
  { code: number; description: string }
>("FETCH_DRIVER_SHIFT");

export const fetchDriverShift =
  (companyId: number, startDate: string, endDate: string, driverId?: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchDriverShiftAction.started([]));

    try {
      const result = await DriverShiftApi.findAll(
        companyId,
        startDate,
        endDate,
        driverId
      );
      dispatch(
        fetchDriverShiftAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchDriverShiftAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateDriverShiftAction = actionCreator.async<
  UpdateDriverShiftReq,
  DriverShiftRes,
  { code: number; description: string }
>("UPDATE_DRIVER_SHIFT");

export const updateDriverShift =
  (companyId: number, req: UpdateDriverShiftReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateDriverShiftAction.started({ ...req }));

    try {
      const updateDriverShift = new UpdateDriverShiftReq({ ...req });
      const result = await DriverShiftApi.update(
        companyId,
        req.driverShiftId,
        updateDriverShift
      );
      dispatch(
        updateDriverShiftAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateDriverShiftAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteDriverShiftAction = actionCreator.async<
  DeleteDriverShiftReq,
  {},
  { code: number; description: string }
>("DELETE_DRIVER_SHIFT");

export const deleteDriverShift =
  (companyId: number, req: DeleteDriverShiftReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteDriverShiftAction.started({ ...req }));

    try {
      const driverShift = new DeleteDriverShiftReq({ ...req });
      const result = await DriverShiftApi.delete(companyId, driverShift);
      dispatch(
        deleteDriverShiftAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteDriverShiftAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };
const bulkInsertDriverShiftAction = actionCreator.async<
  BulkInsertDriverShiftReq[],
  DriverShiftRes[],
  { code: number; description: string }
>("BULK_INSERT_CAST_SHIFT");

export const bulkInsertDriverShift =
  (companyId: number, req: BulkInsertDriverShiftReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertDriverShiftAction.started({ ...req }));

    try {
      const result = await DriverShiftApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertDriverShiftAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertDriverShiftAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const driverShiftActions = {
  fetchDriverShiftAction,
  addDriverShiftAction,
  updateDriverShiftAction,
  deleteDriverShiftAction,
  bulkInsertDriverShiftAction,
};

export default driverShiftActions;
