import Modal from "components/Modal";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useModal from "hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardTimePicker } from "@material-ui/pickers";
import OrderRes from "../../../types/res/order/OrderRes";
import UpdateOrderReq from "../../../types/req/order/UpdateOrderReq";
import { updateOrder } from "redux/actions/order";
import ForwardIcon from "@material-ui/icons/Forward";
import OptionRes from "types/res/option/OptionRes";
import DiscountRes from "types/res/discount/DiscountRes";
type Props = {
  setStateOrders: any;
  orders: OrderRes[];
};
const CastScheduleOnItemMove: React.FC<Props> = ({
  setStateOrders,
  orders,
}) => {
  const [castScheduleOnItemMoveModal, setCastScheduleOnItemMoveShow] = useModal(
    "castScheduleOnItemMove"
  );
  const castShifts = useSelector((state) => state.castShift);
  const companyId = useSelector((state) => state.account.staff.companyId);
  const [loading, setLoading] = useState(false);
  const [selectCastNameId, setSelectCastNameId] = useState<number>();
  const [planInTime, setPlanInTime] = useState<Date | null>();
  const [planOutTime, setPlanOutTime] = useState<Date | null>();
  const [departureTime, setDepartureTime] = useState<Date | null>();
  const [oldOrder, setOldOrder] = useState<OrderRes>();
  const [sendMail, setSendMail] = useState(false);
  const dispatch = useDispatch();
  const castNames = Array.from(
    new Map(
      castShifts
        .flatMap((castShift) => castShift.cast.castNames)
        .map((castName) => [castName.castNameId, castName])
    ).values()
  );
  useEffect(() => {
    if (!castScheduleOnItemMoveModal?.meta?.castName) return;
    setSelectCastNameId(castScheduleOnItemMoveModal.meta.castName.castNameId);
    setDepartureTime(castScheduleOnItemMoveModal.meta.order.departureTime);
    setPlanInTime(castScheduleOnItemMoveModal.meta.order.planInTime);
    setPlanOutTime(castScheduleOnItemMoveModal.meta.order.planOutTime);
    const setOrder = orders.find(
      (order) =>
        order.orderId === castScheduleOnItemMoveModal.meta.order.orderId
    );
    setOldOrder(setOrder);
  }, [castScheduleOnItemMoveModal?.meta]);

  useEffect(() => {
    if (castScheduleOnItemMoveModal.show) return;
    setStateOrders(orders);
  }, [castScheduleOnItemMoveModal?.show]);

  const onClickSubmit = async () => {
    const order = { ...castScheduleOnItemMoveModal.meta.order };
    order.options =
      order.options?.map((option: OptionRes) => option.optionId) || [];
    order.discounts =
      order.discounts?.map((discount: DiscountRes) => discount.discountId) ||
      [];
    order.castNameId = selectCastNameId;
    order.planInTime = planInTime;
    order.planOutTime = planOutTime;
    order.departureTime = departureTime;
    order.sendMailCast = sendMail;
    await dispatch(updateOrder(companyId, new UpdateOrderReq(order)));
    setCastScheduleOnItemMoveShow(false);
  };

  return (
    <Modal
      show={castScheduleOnItemMoveModal.show}
      setShow={setCastScheduleOnItemMoveShow}
      title={`${castScheduleOnItemMoveModal.meta?.castName?.name}さんへの予約変更`}
    >
      <Container>
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">変更前</Typography>
            <TextField
              label="源氏名"
              select
              value={oldOrder?.castName?.castNameId || "未設定"}
              fullWidth
              variant="outlined"
              margin="normal"
              disabled={true}
              onChange={(event) => {
                setSelectCastNameId(Number(event.target.value));
              }}
            >
              {castNames.map((castName) => (
                <MenuItem key={castName.castNameId} value={castName.castNameId}>
                  {castName.name}
                </MenuItem>
              ))}
            </TextField>
            <KeyboardTimePicker
              label="出発"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={oldOrder?.departureTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setDepartureTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              disabled={true}
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="予定IN"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={oldOrder?.planInTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setPlanInTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              disabled={true}
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="予定OUT"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={oldOrder?.planOutTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setPlanOutTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              disabled={true}
              style={{ marginTop: "10px" }}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginX={3}
          >
            <ForwardIcon />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">変更後</Typography>
            <TextField
              label="源氏名"
              select
              value={selectCastNameId}
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={(event) => {
                setSelectCastNameId(Number(event.target.value));
              }}
            >
              {castNames.map((castName) => (
                <MenuItem key={castName.castNameId} value={castName.castNameId}>
                  {castName.name}
                </MenuItem>
              ))}
            </TextField>
            <KeyboardTimePicker
              label="出発"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={departureTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setDepartureTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="予定IN"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={planInTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setPlanInTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="予定OUT"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={planOutTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setPlanOutTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendMail}
                  onChange={(event) => setSendMail(event.target.checked)}
                />
              }
              label="メール送信"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          style={{ margin: "10px 0" }}
          justifyContent="space-evenly"
        >
          <Button
            variant="contained"
            color="default"
            disabled={loading}
            onClick={() => setCastScheduleOnItemMoveShow(false)}
          >
            {loading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "いいえ"
            )}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={onClickSubmit}
          >
            {loading ? <CircularProgress color="inherit" size={24} /> : "はい"}
          </Button>
        </Box>
      </Container>
    </Modal>
  );
};
export default CastScheduleOnItemMove;
