import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import GuestRes from "types/res/guest/GuestRes";
import OrderRes from "types/res/order/OrderRes";
import { CallHistoryType } from "types/enum/CallHistoryType";
import StaffRes from "types/res/staff/StaffRes";

export default class CallHistoryRes {
  @Expose() readonly callHistoryId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly tel!: string;
  @Type(() => ShopRes)
  @Expose()
  readonly shop!: ShopRes;
  @Expose() readonly shopId!: number | null;
  @Type(() => GuestRes)
  @Expose()
  readonly guest!: GuestRes;
  @Expose() readonly guestId!: number | null;
  @Type(() => StaffRes)
  @Expose()
  readonly staff!: StaffRes;
  @Expose() readonly staffId!: number | null;
  @Expose()
  readonly type!: CallHistoryType;

  @Type(() => Date)
  @Expose()
  readonly createdAt!: Date;
}
