import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { CallHistoryType } from "types/enum/CallHistoryType";
import CallHistoryRes from "types/res/callHistory/CallHistoryRes";
import { fetchShops } from "redux/actions/shop";
import { fetchCallHistory } from "redux/actions/callHistory";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import {
  Box,
  InputLabel,
  ListItemText,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import CallHistoryApi from "api/CallHistoryApi";
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CallHistoryGraph = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectTypes, setSelectTypes] = useState<string[]>([
    ...Object.keys(CallHistoryType),
    "すべて",
  ]);
  const [callHistoryGraphData, setCallHistoryGraphData] = useState<any>();
  const [apiData, setApiData] =
    useState<{ [date: string]: { [shopId: string]: number } }>();
  const [allShop, setAllShop] = useState(0);
  const [period, setPeriod] = useState(0);

  useEffect(() => {
    dispatch(fetchShops(companyId));
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      const result: { [date: string]: { [shopId: string]: number } } =
        await CallHistoryApi.graphData(
          companyId,
          DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          selectShops.join(","),
          selectTypes.join(","),
          period === 0 ? "day" : "month"
        );
      setApiData(result);
    };
    apiCall();
  }, [startDate, endDate, selectShops, selectTypes, period]);

  useEffect(() => {
    if (!apiData) return;
    const graphData = Object.entries(apiData).map(([date, shopObject]) => {
      const allCount = Object.values(shopObject).reduce(
        (sum, object) => sum + object,
        0
      );
      let shopData: { [key: string]: number } = {};
      Object.entries(shopObject).forEach(([shopId, c]) => {
        const shopName =
          shops.find((shop) => `${shop.shopId}` === shopId)?.name || "未設定";
        shopData = { ...shopData, [shopName]: c };
      });
      return {
        date,
        count: allCount,
        ...shopData,
      };
    });
    setCallHistoryGraphData(graphData);
  }, [apiData]);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  const handleShopChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setAllShop(newValue);
  };

  const handlePeriodChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setPeriod(newValue);
  };

  const colorList = [
    "#801A86",
    "#8FE388",
    "#264653",
    "#2a9d8f",
    "#e9c46a",
    "#f4a261",
    "#e63946",
    "#f1faee",
    "#9b2226",
    "#d9ed92",
  ];

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box margin={2} display="flex" alignItems="center">
          <TextField
            type="date"
            label="開始日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(startDate).toFormat(
              FORMAT_TYPE.YEAR_DAY
            )}
            onChange={(event) =>
              setStartDate(
                DateTime.fromISO(event.target.value as string)
                  .minus({ hours: changeDate.hour, minutes: changeDate.minute })
                  .toJSDate()
              )
            }
          />
          <TextField
            type="date"
            label="終了日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
            onChange={(event) =>
              setEndDate(
                DateTime.fromISO(event.target.value as string)
                  .minus({ hours: changeDate.hour, minutes: changeDate.minute })
                  .toJSDate()
              )
            }
          />
          <Box>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={selectShops}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectShops((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length === shops.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= shops.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectShops.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel id="select-multiple-cast">カテゴリ</InputLabel>
            <Select
              multiple
              value={selectTypes}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectTypes((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...Object.keys(CallHistoryType).map((type) => type),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length ===
                      Object.keys(CallHistoryType).length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...Object.keys(CallHistoryType).map((type) => type),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <=
                    Object.keys(CallHistoryType).length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-type" />}
              style={{ width: "200px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return Object.entries(CallHistoryType)
                    .filter(([key]) => (selected as string[]).includes(key))
                    .map(([, value]) => value)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectTypes.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {Object.entries(CallHistoryType).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={selectTypes.indexOf(key) !== -1} />
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" marginX={5}>
            <Tabs
              value={allShop}
              onChange={handleShopChange}
              style={{ backgroundColor: "#d9d9d9", borderRadius: "8px" }}
            >
              <Tab label="全体" {...a11yProps(0)} />
              <Tab label="店舗別" {...a11yProps(1)} />
            </Tabs>
            <Tabs
              value={period}
              onChange={handlePeriodChange}
              style={{ backgroundColor: "#d9d9d9", borderRadius: "8px" }}
            >
              <Tab label="日別" {...a11yProps(0)} />
              <Tab label="月別" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <Box display="flex" justifyContent="center">
            {callHistoryGraphData ? (
              <LineChart
                width={900}
                height={400}
                data={callHistoryGraphData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {allShop === 0 ? (
                  <>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis dataKey="count" />
                    <Tooltip />
                    <Legend verticalAlign="top" />
                    <Line
                      dataKey="count"
                      legendType={"plainline"}
                      name={"着信回数"}
                    />
                  </>
                ) : (
                  <>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" />
                    <Line
                      dataKey={"未設定"}
                      legendType={"plainline"}
                      name={"未設定"}
                      key={0}
                    />
                    {shops.map((shop, i) => (
                      <Line
                        dataKey={shop.name}
                        legendType={"plainline"}
                        name={shop.name}
                        key={shop.shopId}
                        stroke={colorList[Number(String(i).slice(-1))]}
                      />
                    ))}
                  </>
                )}
              </LineChart>
            ) : (
              <Box>
                <Typography>データがありません。</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CallHistoryGraph;
