import { Box, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CastShiftRes from "../../../types/res/castShift/CastShiftRes";
import CastNameRes from "types/res/castName/CastNameRes";
type Props = {
  onChangeCheckBox(key: string): any;
  formData: any;
};
const OrderCast: React.FC<Props> = ({ onChangeCheckBox, formData }) => {
  const castShift = useSelector((state) => state.castShift);
  const castNames = useSelector((state) => state.castName);
  const [selectCastShift, setSelectCastShift] = useState<CastShiftRes>();
  const [selectCastName, setSelectCastName] = useState<CastNameRes>();
  useEffect(() => {
    setSelectCastShift(
      castShift?.find((shift) =>
        shift?.cast?.castNames
          ?.map((castName) => castName.castNameId)
          .includes(formData.castNameId)
      )
    );
    setSelectCastName(
      castNames?.find(
        (castName) => castName.castNameId === formData?.castNameId
      )
    );
  }, [formData?.castNameId, castShift]);
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h5" component="h5">
        キャスト情報
      </Typography>
      <TextField
        label="キャスト名"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastShift?.cast.displayName || ""}
      />
      <TextField
        label="電話番号"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastShift?.cast.tel || ""}
      />
      <TextField
        label="年齢"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastName?.age || ""}
      />
      <TextField
        label="身長"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastName?.bodyHeight || ""}
      />
      <TextField
        label="カップ"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastName?.cup || ""}
      />
      <TextField
        label="バスト"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastName?.bust || ""}
      />
      <TextField
        label="ウエスト"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastName?.waist || ""}
      />
      <TextField
        label="ヒップ"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastName?.hip || ""}
      />
      <TextField
        label="備考"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastShift?.cast.remarks || ""}
      />
      <TextField
        label="当日シフトキャストメモ"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastShift?.castMemo || ""}
      />
      <TextField
        label="当日シフトスタッフメモ"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastShift?.staffMemo || ""}
      />
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1" style={{ marginRight: "10px" }}>
          NGエリア
        </Typography>
        <Typography variant="body2">
          {selectCastShift?.cast.ngAreas?.map((area) => area.name).join(",") ||
            "なし"}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1" style={{ marginRight: "10px" }}>
          NG顧客
        </Typography>
        <Typography variant="body2">
          {selectCastShift?.cast.ngGuests
            ?.map((guest) => `${guest.name}(${guest.tel})`)
            .join(",") || "なし"}
        </Typography>
      </Box>
    </Box>
  );
};
export default OrderCast;
