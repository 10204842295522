import { useDispatch, useSelector } from "react-redux";
import useModal from "hooks/useModal";
import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import { Box, Button, Container, TextField } from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";
import CreateCarTransferScheduleReq from "types/req/carTransferSchedule/CreateCarTransferScheduleReq";
import CarTransferScheduleApi from "api/CarTransferScheduleApi";
import { fetchDriverShift } from "redux/actions/driverShift";
import UpdateCarTransferScheduleReq from "types/req/carTransferSchedule/UpdateCarTransferScheduleReq";

type Props = {
  startDate: string;
  endDate: string;
};
const DriverScheduleOnItemCreate = ({ startDate, endDate }: Props) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const [driverScheduleOnItemCreateModal, setDriverScheduleOnItemCreateShow] =
    useModal("driverScheduleOnItemCreate");
  const [title, setTitle] = useState("");
  const [startTime, setStartTime] = useState<Date | null>();
  const [endTime, setEndTime] = useState<Date | null>();

  useEffect(() => {
    if (!driverScheduleOnItemCreateModal?.meta?.carTransferSchedule) return;
    setStartTime(
      driverScheduleOnItemCreateModal.meta.carTransferSchedule.startTime
    );
    setEndTime(
      driverScheduleOnItemCreateModal.meta.carTransferSchedule.endTime
    );
    setTitle(driverScheduleOnItemCreateModal.meta.carTransferSchedule.title);
  }, [driverScheduleOnItemCreateModal]);

  const onClickSubmit = async () => {
    const carTransferSchedule = {
      ...driverScheduleOnItemCreateModal.meta.carTransferSchedule,
    };
    carTransferSchedule.startTime = startTime;
    carTransferSchedule.endTime = endTime;
    carTransferSchedule.title = title;
    if (carTransferSchedule.carTransferScheduleId) {
      await CarTransferScheduleApi.update(
        companyId,
        new UpdateCarTransferScheduleReq(carTransferSchedule)
      );
    } else {
      await CarTransferScheduleApi.create(
        companyId,
        new CreateCarTransferScheduleReq(carTransferSchedule)
      );
    }
    dispatch(fetchDriverShift(companyId, startDate, endDate));
    setDriverScheduleOnItemCreateShow(false);
  };

  return (
    <Modal
      show={driverScheduleOnItemCreateModal.show}
      setShow={setDriverScheduleOnItemCreateShow}
      title={
        driverScheduleOnItemCreateModal?.meta?.carTransferSchedule
          ?.carTransferScheduleId
          ? "編集"
          : `新規作成`
      }
    >
      <Container>
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column">
            <TextField
              label="タイトル"
              variant="outlined"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="開始"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={startTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setStartTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="終了"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={endTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setEndTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          style={{ margin: "10px 0" }}
          justifyContent="space-evenly"
        >
          <Button
            variant="contained"
            color="default"
            onClick={() => setDriverScheduleOnItemCreateShow(false)}
          >
            いいえ
          </Button>
          <Button variant="contained" color="primary" onClick={onClickSubmit}>
            はい
          </Button>
        </Box>
      </Container>
    </Modal>
  );
};
export default DriverScheduleOnItemCreate;
