import { IsNotEmpty, MaxLength } from "class-validator";
import Spec from "constants/Spec";
import { PlanType } from "types/enum/PlanType";

export default class UpdateCompanyGroupReq {
  @IsNotEmpty({
    message: "",
  })
  readonly companyGroupId: number;

  @MaxLength(Spec.maxLength.company.name, {
    message: "",
    context: {
      constraint1: Spec.maxLength.company.name,
    },
  })
  @IsNotEmpty({ message: "" })
  name!: string;

  @MaxLength(Spec.maxLength.company.kana, {
    message: "",
    context: {
      constraint1: Spec.maxLength.company.kana,
    },
  })
  @IsNotEmpty({ message: "" })
  kana!: string;

  @IsNotEmpty({ message: "" })
  plan!: PlanType;

  constructor(arg: {
    companyGroupId: number;
    name: string;
    kana: string;
    plan: PlanType;
  }) {
    arg = arg || {};
    this.companyGroupId = arg.companyGroupId;
    this.name = arg.name;
    this.kana = arg.kana;
    this.plan = arg.plan;
  }
}
