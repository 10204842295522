import { Box, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import OrderPoint from "pages/Order/components/OrderPoint";
import GuestPointApi from "api/GuestPointApi";
import { useSelector } from "react-redux";
import OrderSubmit from "pages/Order/components/OrderSubmit";
import OrderRes from "types/res/order/OrderRes";
import OrderSendMail from "pages/Order/components/OrderSendMail";
import OrderDriver from "pages/Order/components/OrderDriver";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
type Props = {
  formData: any;
  onChange(key: string): any;
  onChangeForm(key: string): any;
  onChangeCheckBox(key: string): any;
  onClickCreateOrder: any;
  orderId: any;
  oldOrder: any;
  duplicateOrder: any;
  holdOrder: OrderRes;
  setHoldOrder: any;
  setDuplicateMessage: any;
  errorMessage: any;
  isSuccess: boolean;
  duplicateMessage: any;
  setDuplicateOrder: any;
};
const OrderPrice: React.FC<Props> = ({
  formData,
  onChange,
  onChangeForm,
  onChangeCheckBox,
  onClickCreateOrder,
  orderId,
  oldOrder,
  duplicateOrder,
  holdOrder,
  setHoldOrder,
  setDuplicateMessage,
  errorMessage,
  isSuccess,
  duplicateMessage,
  setDuplicateOrder,
}) => {
  const companyId = useSelector((state) => state.account.staff.companyId);
  const castNames = useSelector((state) => state.castName);
  const [availablePoint, setAvailablePoint] = useState(0);
  const [usePoint, setUsePoint] = useState(0);
  useEffect(() => {
    if (!formData.shopId || !formData.guestId) {
      setAvailablePoint(0);
      return;
    }
    const fetch = async () => {
      const result = await GuestPointApi.checkPoint(
        companyId,
        formData.guestId,
        formData.shopId
      );
      setAvailablePoint(result.point || 0);
    };
    fetch();
  }, [formData.shopId, formData.guestId]);

  useEffect(() => {
    if (!formData) return;
    calcTotalFee(formData);
  }, [
    formData?.courseFee,
    formData?.courseShopFee,
    formData?.courseCastFee,
    formData?.additionalFee,
    formData?.additionalCastFee,
    formData?.additionalShopFee,
    formData?.optionFee,
    formData?.optionCastFee,
    formData?.optionShopFee,
    formData?.cosplayFee,
    formData?.cosplayShopFee,
    formData?.cosplayCastFee,
    formData?.areaFee,
    formData?.areaCastFee,
    formData?.areaShopFee,
    formData?.otherFee,
    formData?.otherCastFee,
    formData?.otherShopFee,
    formData?.pointFee,
    formData?.cardFee,
    formData?.discountFee,
    formData?.discountShopFee,
    formData?.discountCastFee,
    formData?.designateFee,
    formData?.designateCastFee,
    formData?.designateShopFee,
    formData?.mediumFee,
    formData?.mediumCastFee,
    formData?.mediumShopFee,
    formData?.invoiceFee,
    formData?.hotelFee,
    formData?.hotelShopFee,
    formData?.hotelCastFee,
  ]);
  const calcTotalFee = (formData: any) => {
    const subTotalFee =
      (formData?.courseFee || 0) +
      (formData?.designateFee || 0) +
      (formData?.additionalFee || 0) +
      (formData?.optionFee || 0) +
      (formData?.cosplayFee || 0) +
      (formData?.areaFee || 0) +
      (formData?.hotelFee || 0) +
      (formData?.otherFee || 0) -
      (formData?.mediumFee || 0) -
      (formData?.discountFee || 0);
    const totalFee =
      subTotalFee + (formData?.cardFee || 0) - (formData?.pointFee || 0);
    const totalCastFee =
      (formData?.courseCastFee || 0) +
      (formData?.designateCastFee || 0) +
      (formData?.additionalCastFee || 0) +
      (formData?.optionCastFee || 0) +
      (formData?.cosplayCastFee || 0) -
      (formData?.discountCastFee || 0) +
      (formData?.areaCastFee || 0) +
      (formData?.hotelCastFee || 0) +
      (formData?.otherCastFee || 0) -
      (formData?.mediumCastFee || 0) -
      (formData?.welfareFee || 0);
    const totalShopFee =
      (formData?.courseShopFee || 0) +
      (formData?.designateShopFee || 0) +
      (formData?.additionalShopFee || 0) +
      (formData?.optionShopFee || 0) +
      (formData?.cosplayShopFee || 0) -
      (formData?.discountShopFee || 0) +
      (formData?.areaShopFee || 0) +
      (formData?.hotelShopFee || 0) +
      (formData?.otherShopFee || 0) -
      (formData?.mediumShopFee || 0) -
      (formData?.pointFee || 0) +
      (formData?.invoiceFee || 0) +
      (formData?.welfareFee || 0);
    const castName = castNames.find(
      (castName) => castName.castNameId === formData?.castNameId
    );
    const invoiceFee =
      totalCastFee * (castName?.cast?.invoiceRate || 0) !== 0 &&
      formData?.orderDate &&
      DateTime.fromJSDate(formData.orderDate).toFormat(FORMAT_TYPE.YEAR_DAY) >=
        "2023-10-01"
        ? Math.floor((totalCastFee * (castName?.cast?.invoiceRate || 0)) / 100)
        : null;
    onChange("invoiceFee")(invoiceFee);
    onChange("subTotalFee")(subTotalFee);
    onChange("totalFee")(totalFee);
    onChange("totalCastFee")(totalCastFee - (invoiceFee || 0));
    onChange("totalShopFee")(totalShopFee);
  };

  useEffect(() => {
    if (!availablePoint || oldOrder.pointFee === formData.pointFee) return;
    if (availablePoint < formData.pointFee) {
      alert("ポイントが利用上限を超えています。");
    }
  }, [availablePoint, formData.pointFee, oldOrder]);

  useEffect(() => {
    setUsePoint(formData.pointFee);
  }, [formData.pointFee]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        marginLeft={3}
        flexBasis={"20%"}
      >
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Typography variant="h5" component="h5">
            料金詳細
          </Typography>
          <Box display="flex">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <TextField
                  size="small"
                  label="コース代金"
                  type="number"
                  variant="outlined"
                  value={formData?.courseFee || ""}
                  disabled
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: 10 }}
                >
                  <TextField
                    size="small"
                    label="基本料金"
                    type="number"
                    variant="outlined"
                    value={formData?.timeFee || ""}
                    disabled
                  />
                  <TextField
                    label="指名料"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.designateFee || ""}
                    disabled
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <TextField
                  label="延長"
                  size="small"
                  type="number"
                  variant="outlined"
                  value={formData?.additionalFee || ""}
                  disabled
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: 10 }}
                >
                  <TextField
                    label="店舗"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.additionalShopFee || ""}
                    disabled
                  />
                  <TextField
                    label="キャスト"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.additionalCastFee || ""}
                    disabled
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <TextField
                  label="オプション"
                  size="small"
                  type="number"
                  variant="outlined"
                  value={formData?.optionFee || ""}
                  disabled
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: 10 }}
                >
                  <TextField
                    label="店舗"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.optionShopFee || ""}
                    disabled
                  />
                  <TextField
                    label="キャスト"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.optionCastFee || ""}
                    disabled
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <TextField
                  label="コスプレ"
                  size="small"
                  type="number"
                  variant="outlined"
                  value={formData?.cosplayFee || ""}
                  disabled
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: 10 }}
                >
                  <TextField
                    label="店舗"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.cosplayShopFee || ""}
                    disabled
                  />
                  <TextField
                    label="キャスト"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.cosplayCastFee || ""}
                    disabled
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <TextField
                  size="small"
                  label="割引"
                  type="number"
                  variant="outlined"
                  value={formData?.discountFee || ""}
                  disabled
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: 10 }}
                >
                  <TextField
                    label="店舗"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.discountShopFee || ""}
                    disabled
                  />
                  <TextField
                    label="キャスト"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.discountCastFee || ""}
                    disabled
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <TextField
                  label="媒体"
                  size="small"
                  type="number"
                  variant="outlined"
                  value={formData?.mediumFee || ""}
                  disabled
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: 10 }}
                >
                  <TextField
                    label="店舗"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.mediumShopFee || ""}
                    disabled
                  />
                  <TextField
                    label="キャスト"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.mediumCastFee || ""}
                    disabled
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <TextField
                  label="交通費"
                  size="small"
                  type="number"
                  variant="outlined"
                  value={formData?.areaFee || ""}
                  disabled
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: 10 }}
                >
                  <TextField
                    label="店舗"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.areaShopFee || ""}
                    disabled
                  />
                  <TextField
                    label="キャスト"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.areaCastFee || ""}
                    disabled
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <TextField
                  label="ホテル"
                  size="small"
                  type="number"
                  variant="outlined"
                  value={formData?.hotelFee || ""}
                  disabled
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: 10 }}
                >
                  <TextField
                    label="店舗"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.hotelShopFee || ""}
                    disabled
                  />
                  <TextField
                    label="キャスト"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.hotelCastFee || ""}
                    disabled
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <TextField
                  label="インボイス"
                  size="small"
                  type="number"
                  variant="outlined"
                  value={formData?.invoiceFee || ""}
                  disabled
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: 10 }}
                >
                  <TextField
                    label="キャスト"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.invoiceFee || ""}
                    disabled
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{ marginBottom: 10 }}
              >
                <TextField
                  label="その他"
                  size="small"
                  type="number"
                  variant="outlined"
                  value={formData?.otherFee || ""}
                  disabled
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: 10 }}
                >
                  <TextField
                    label="店舗"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.otherShopFee || ""}
                    onChange={(event) => {
                      onChange("otherShopFee")(Number(event.target.value));
                    }}
                    onBlur={(event) =>
                      onChange("otherFee")(
                        Number(event.target.value) +
                          (formData?.otherCastFee || 0)
                      )
                    }
                  />
                  <TextField
                    label="キャスト"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={formData?.otherCastFee || ""}
                    onChange={(event) => {
                      onChange("otherCastFee")(Number(event.target.value));
                      onChange("otherFee")(
                        Number(event.target.value) +
                          (formData?.otherShopFee || 0)
                      );
                    }}
                    onBlur={(event) =>
                      onChange("otherFee")(
                        Number(event.target.value) +
                          (formData?.otherShopFee || 0)
                      )
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" flexBasis={"30%"}>
        <Box>
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box
              display="flex"
              alignItems="center"
              style={{ margin: "32px 0 10px 0" }}
            >
              <TextField
                label="カード手数料"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.cardFee || ""}
                disabled
              />
              <Box display="flex" flexDirection="column">
                <TextField
                  label="ポイント利用"
                  size="small"
                  type="number"
                  variant="outlined"
                  value={usePoint || ""}
                  style={{ marginLeft: 10 }}
                  onChange={(event) => setUsePoint(Number(event.target.value))}
                  onBlur={(event) =>
                    onChange("pointFee")(Number(event.target.value))
                  }
                />
              </Box>
            </Box>
            <Box
              display="flex"
              alignSelf="flex-end"
              alignItems="flex-end"
              flexDirection="column"
            >
              <Typography variant="body2">
                利用できるポイントは{availablePoint}ポイントです。
              </Typography>
            </Box>
            <Box
              marginTop={1}
              display="flex"
              alignSelf="flex-end"
              alignItems="flex-end"
              flexDirection="column"
              width="100%"
              style={{
                backgroundColor: "#d3d3d3",
                borderRadius: "5px",
                padding: "5px 10px 0",
              }}
            >
              <p>店舗 {formData?.totalShopFee || 0}円</p>
              <p>キャスト {formData?.totalCastFee || 0}円</p>
              <p style={{ fontWeight: "bold", fontSize: "24px" }}>
                合計金額 {formData?.totalFee || 0}円
              </p>
            </Box>
            <OrderPoint formData={formData} onChange={onChange} />
            <OrderDriver
              formData={formData}
              onChange={(key) => onChangeForm(key)}
              onChangePrice={(key) => onChange(key)}
              onChangeCheckBox={(key) => onChangeCheckBox(key)}
            />
            <OrderSendMail
              formData={formData}
              onChange={onChange}
              oldOrder={oldOrder}
            />
            <OrderSubmit
              formData={formData}
              onChangeForm={(key) => onChangeForm(key)}
              onClickCreateOrder={onClickCreateOrder}
              orderId={orderId}
              oldOrder={oldOrder}
              duplicateOrder={duplicateOrder}
              holdOrder={holdOrder}
              setHoldOrder={setHoldOrder}
              setDuplicateMessage={setDuplicateMessage}
              errorMessage={errorMessage}
              isSuccess={isSuccess}
              duplicateMessage={duplicateMessage}
              setDuplicateOrder={setDuplicateOrder}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default OrderPrice;
