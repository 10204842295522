import RequestUtils from "utils/RequestUtils";
import CastMobileMenuRes from "types/res/castMobileMenu/CastMobileMenuRes";
import UpdateCastMobileMenuReq from "types/req/castMobileMenu/UpdateCastMobileMenuReq";

export default class CastMobileMenuApi {
  private constructor() {}

  static async findOne(companyId: number): Promise<CastMobileMenuRes> {
    return await RequestUtils.get(
      CastMobileMenuRes,
      `/castMobileMenu/findOne/${companyId}`
    );
  }

  static async update(
    companyId: number,
    data: UpdateCastMobileMenuReq
  ): Promise<CastMobileMenuRes> {
    return await RequestUtils.post(
      CastMobileMenuRes,
      `/castMobileMenu/update/${companyId}`,
      data
    );
  }
}
