import { ValidationError } from "class-validator";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";

type Props = {
  csvHeader: any[];
  csvData: any[];
  bulkInsertError: {
    [x: number]: ValidationError[];
  };
};
const CsvImportTable: React.FC<Props> = ({
  csvData,
  csvHeader,
  bulkInsertError,
}) => {
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ overflow: "scroll", maxHeight: "350px" }}
      >
        <Table style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              {csvHeader.map((header, index) => (
                <TableCell
                  key={index}
                  style={{
                    width: `${100 / csvHeader.length}%`,
                    padding: "0 2px",
                  }}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {csvData.map((data, index) => {
              return (
                <TableRow key={index}>
                  {Object.entries(data).map(([key, value]) => {
                    if (csvHeader.map((header) => header.key).includes(key)) {
                      return (
                        <TableCell
                          style={{
                            width: `${100 / csvHeader.length}%`,
                            padding: "0 2px",
                            backgroundColor: bulkInsertError[index]?.some(
                              (error) => error.property === key
                            )
                              ? "#ff8888"
                              : "init",
                          }}
                        >
                          {Array.isArray(value)
                            ? value.join(" ")
                            : value instanceof Date
                            ? DateTime.fromJSDate(value as Date).toFormat(
                                FORMAT_TYPE.YEAR_DATE_TIME
                              )
                            : (value as string)}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default CsvImportTable;
