import { IsDate, IsNotEmpty } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";
import { Type } from "class-transformer";

export default class CreateCarTransferScheduleReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  driverId!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  startTime!: Date;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  endTime!: Date;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  title!: string;

  constructor(arg: {
    driverId: number;
    startTime: Date;
    endTime: Date;
    title: string;
  }) {
    arg = arg || {};
    this.driverId = arg.driverId;
    this.startTime = arg.startTime;
    this.endTime = arg.endTime;
    this.title = arg.title;
  }
}
