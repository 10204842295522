import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompany,
  deleteCompany,
  fetchCompanies,
  updateCompany,
} from "redux/actions/company";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import CompanyRes from "types/res/company/CompanyRes";
import styled from "styled-components";

import { FormType } from "components/FormField";
import UpdateCompanyReq from "types/req/company/UpdateCompanyReq";
import EnumUtils from "../../utils/EnumUtils";
import StaffRole from "../../types/enum/StaffRole";
import { Redirect } from "react-router-dom";
import { fetchCompanyGroups } from "redux/actions/companyGroup";
import CreateCompanyReq from "types/req/company/CreateCompanyReq";
import { CastExaminationType } from "types/enum/CastExaminationType";

const headers: { key: keyof CompanyRes; label: string }[] = [
  { key: "name", label: "会社名" },
  { key: "companyGroupId", label: "グループ名" },
  { key: "kana", label: "よみがな" },
  { key: "email", label: "代表メールアドレス" },
  { key: "tel", label: "代表電話番号" },
  { key: "representativeName", label: "代表者名" },
  { key: "isCallCenterContract", label: "コールセンター契約" },
  { key: "pointShopFlag", label: "ポイント利用" },
  { key: "guestCategoryShopFlag", label: "顧客区分" },
  { key: "castExaminationType", label: "検査種別" },
  { key: "castExaminationCount", label: "検査までの件数・日数" },
  { key: "sort", label: "並び順" },
  { key: "changeDateTime", label: "日付変更時間" },
];

const Company: React.FC = () => {
  const dispatch = useDispatch();
  const companies: CompanyRes[] = useSelector((state) => state.company);
  const companyGroup = useSelector((state) => state.companyGroup);
  const role = useSelector((state) => state.account.staff.role);
  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(fetchCompanyGroups());
  }, [dispatch]);
  if (
    ![
      StaffRole.notelMaster,
      StaffRole.notelCallCenterEmployee,
      StaffRole.notelCallCenterPartTime,
    ].includes(
      EnumUtils.mapToEnum(StaffRole, role) || StaffRole.clientShopPartTime
    )
  ) {
    return <Redirect to="/" />;
  }
  const forms = [
    {
      label: "会社名",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "グループ",
      key: "companyGroupId",
      type: FormType.Select,
      options:
        companyGroup.map((c) => ({
          id: c.companyGroupId,
          name: c.name,
        })) || [],
    },
    {
      label: "よみがな",
      key: "kana",
      type: FormType.Text,
    },
    {
      label: "代表メールアドレス",
      key: "email",
      type: FormType.Text,
    },
    {
      label: "代表電話番号",
      key: "tel",
      type: FormType.Text,
    },
    {
      label: "代表者名",
      key: "representativeName",
      type: FormType.Text,
    },
    {
      label: "コールセンター契約",
      key: "isCallCenterContract",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "未契約" },
        { id: "1", name: "契約中" },
      ],
    },
    {
      label: "ポイント利用",
      key: "pointShopFlag",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "一括利用" },
        { id: "1", name: "店舗別利用" },
      ],
    },
    {
      label: "顧客区分",
      key: "guestCategoryShopFlag",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "一括" },
        { id: "1", name: "店舗別" },
      ],
    },
    {
      label: "検査種別",
      key: "castExaminationType",
      type: FormType.Select,
      options: Object.entries(CastExaminationType).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "検査までの件数・日数",
      key: "castExaminationCount",
      type: FormType.Number,
    },
    { label: "並び順", key: "sort", type: FormType.Number },
    { label: "日付変更時間", key: "changeDateTime", type: FormType.Time },
  ];

  return (
    <>
      <CompanyTable
        title={"契約企業"}
        rows={headers}
        data={companies}
        formId="companyForm"
        forms={forms}
        addFunc={addCompany}
        updateFunc={updateCompany}
        deleteFunc={(item) => deleteCompany(item?.companyId)}
        addType={CreateCompanyReq}
        updateType={UpdateCompanyReq}
        values={[
          (s) => s.name,
          (s) => s.companyGroup.name,
          (s) => s.kana,
          (s) => s.email,
          (s) => s.tel,
          (s) => s.representativeName,
          (s) => (s.isCallCenterContract ? "契約中" : "未契約"),
          (s) => (s.pointShopFlag ? "店舗別利用" : "一括利用"),
          (s) => (s.guestCategoryShopFlag ? "店舗別" : "一括"),
          (s) =>
            s.castExaminationType
              ? EnumUtils.mapToEnum(
                  CastExaminationType,
                  s.castExaminationType
                ) || ""
              : "",
          (s) => s.castExaminationCount || "",
          (s) => s.sort || "",
          (s) => s.changeDateTime,
        ]}
      />
    </>
  );
};

const CompanyTable = styled<React.FC<CommonTableProps<CompanyRes>>>(
  CommonTable
)`
  margin-top: 24px;
`;

export default Company;
