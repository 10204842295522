import { reducerWithInitialState } from "typescript-fsa-reducers";
import companyActions from "redux/actions/company";
import CompanyRes from "types/res/company/CompanyRes";
export type CompanyState = CompanyRes[];

const initialState = [] as CompanyState;

const companyReducer = reducerWithInitialState(initialState)
  .case(companyActions.addCompanyAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(companyActions.updateCompanyAction.done, (state, payload) => {
    return state.map((item) =>
      item.companyId === payload.params.companyId ? payload.result : item
    );
  })
  .case(companyActions.deleteCompanyAction.done, (state, payload) => {
    return state.filter((item) => item.companyId !== payload.params.companyId);
  })
  .case(companyActions.fetchCompaniesAction.done, (state, payload) => {
    return payload.result;
  })
  .case(
    companyActions.fetchCompaniesByCompanyGroupIdAction.done,
    (state, payload) => {
      return payload.result;
    }
  );
export default companyReducer;
