import { reducerWithInitialState } from "typescript-fsa-reducers";
import mediumActions from "redux/actions/medium";
import MediumRes from "types/res/medium/MediumRes";
export type MediumState = MediumRes[];

const initialState = [] as MediumState;

const mediumReducer = reducerWithInitialState(initialState)
  .case(mediumActions.addMediumAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(mediumActions.updateMediumAction.done, (state, payload) => {
    return state.map((item) =>
      item.mediumId === payload.params.mediumId ? payload.result : item
    );
  })
  .case(mediumActions.deleteMediumAction.done, (state, payload) => {
    return state.filter((item) => item.mediumId !== payload.params.mediumId);
  })
  .case(mediumActions.fetchMediumAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(mediumActions.bulkInsertMediumAction.done, (state, payload) => {
    return [...state, ...payload.result];
  });
export default mediumReducer;
