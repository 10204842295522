import { Box, Link } from "@material-ui/core";
import DateTimeUtils, { FORMAT_TYPE } from "../../../utils/DateTimeUtils";
import Tooltip from "rc-tooltip";
import React from "react";
import OrderRes from "../../../types/res/order/OrderRes";
import styled from "styled-components";

type Props = {
  order: OrderRes;
  item: any;
  itemContext: any;
  getItemProps: any;
  rightResizeProps: any;
  leftResizeProps: any;
};

const Item = styled.div<{ color: string }>`
  background-color: ${(props) => props.color} !important;
  border: 1px solid ${(props) => props.color} !important;
  border-radius: 4px;
`;
const DriverScheduleOrderItem: React.FC<Props> = ({
  order,
  item,
  itemContext,
  getItemProps,
  leftResizeProps,
  rightResizeProps,
}) => {
  return (
    <Tooltip
      placement="right"
      trigger={["hover"]}
      overlay={
        <Box display="flex" flexDirection="column">
          <span>店舗名：{order?.shop?.name || "未設定"} </span>
          <span>顧客名：{order?.guest?.name || "未設定"}</span>
          <span>顧客電話番号：{order?.guest?.tel || "未設定"}</span>
          <span>源氏名：{order?.castName?.name || "未設定"}</span>
          <span>
            合計時間：{order?.totalTime ? `${order.totalTime}分` : "未設定"}
          </span>
          <span>
            エリア：
            {order?.area?.name || "未設定"}
          </span>
          <span>
            ホテル：
            {order?.hotel?.name || "未設定"}
          </span>
          <span>
            住所：
            {order?.orderAddress || "未設定"}
          </span>
          <span>
            住所2：
            {order?.orderAddress2 || "未設定"}
          </span>
          <span>
            オプション：
            {order?.options?.map((option) => option.name).join("/") || "未設定"}
          </span>
          <span>
            出発時間：
            {order?.departureTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  order?.departureTime,
                  FORMAT_TYPE.TIME
                )
              : "未設定"}
          </span>
          <span>
            予定IN：
            {order?.planInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  order.planInTime,
                  FORMAT_TYPE.TIME
                )
              : "未設定"}
          </span>
          <span>
            予定OUT：
            {order?.planOutTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  order.planOutTime,
                  FORMAT_TYPE.TIME
                )
              : "未設定"}
          </span>
          <span>
            実IN：
            {order?.actualInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  order.actualInTime,
                  FORMAT_TYPE.TIME
                )
              : "未設定"}
          </span>
          <span>
            実OUT：
            {order?.actualEndTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  order.actualEndTime,
                  FORMAT_TYPE.TIME
                )
              : "未設定"}
          </span>
          <Link href={`/order/${order.orderId}`}>予約詳細へ</Link>
        </Box>
      }
    >
      <Item {...getItemProps(item.itemProps)} color={item.color}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
        <div
          className="rct-item-content"
          style={{
            maxHeight: `${itemContext.dimensions.height}`,
            overflow: "hidden",
            padding: "0 2px",
            fontSize: "8px",
          }}
        >
          {itemContext.title}
        </div>
        <div
          style={{
            height: itemContext.dimensions.height,
          }}
        />
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </Item>
    </Tooltip>
  );
};

export default DriverScheduleOrderItem;
