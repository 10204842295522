import RequestUtils from "utils/RequestUtils";
import FindCpmAnalyticsDataReq from "types/req/cpmAnalytics/FindCpmAnalyticsDataReq";

export default class GuestAnalyticsCpmAnalyticsApi {
  private constructor() {}

  static async findDataLastMonth(
    companyId: number,
    data: FindCpmAnalyticsDataReq
  ) {
    return await RequestUtils.postPlain(
      `/guestAnalyticsCpmAnalytics/findDataLastMonth/${companyId}`,
      data
    );
  }
  static async findData(companyId: number, data: FindCpmAnalyticsDataReq) {
    return await RequestUtils.postPlain(
      `/guestAnalyticsCpmAnalytics/findData/${companyId}`,
      data
    );
  }
}
