import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "components/Modal";
import { Button, CircularProgress, Container } from "@material-ui/core";
import useModal from "hooks/useModal";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const DeleteModal = ({
  deleteFunc,
  descriptionComponent,
}: {
  deleteFunc: (a: any) => any;
  descriptionComponent?: React.ReactElement;
}) => {
  const [deleteModal, setDeleteShow] = useModal("deleteData");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const onClickDeleteButton = async () => {
    setLoading(true);
    await dispatch(deleteFunc(deleteModal.meta?.item));
    setLoading(false);
    setDeleteShow(false);
  };
  return (
    <Modal show={deleteModal.show} setShow={setDeleteShow} title="削除">
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column">
            {descriptionComponent}
            <Typography>本当に削除しますか？</Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          onClick={onClickDeleteButton}
          style={{ marginTop: "8px" }}
        >
          {loading ? <CircularProgress color="inherit" size={24} /> : "決定"}
        </Button>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
