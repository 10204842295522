import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Delete, Edit } from "@material-ui/icons";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import useModal from "hooks/useModal";
import styled from "styled-components";
import {
  Checkbox,
  CircularProgress,
  createStyles,
  Icon,
  IconButton,
  TablePagination,
  TableSortLabel,
  Theme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import FormModal from "./FormModal";
import DeleteModal from "./DeleteModal";
import CreateCompanyReq from "types/req/company/CreateCompanyReq";
import UpdateCompanyReq from "types/req/company/UpdateCompanyReq";
import BulkDeleteModal from "./BulkDeleteModal";
import React from "react";
import { useSelector } from "react-redux";

export type Constructor<T> = new (...args: any[]) => T;
type Order = "asc" | "desc";
export type CommonTableProps<T, S = CreateCompanyReq, U = UpdateCompanyReq> = {
  title: string;
  description?: JSX.Element;
  rows: { key: keyof T; label: string }[];
  formId: string;
  forms: any[];
  data: T[];
  values: ((item: T) => string | number | JSX.Element)[];
  addFunc: (a: any) => any;
  updateFunc: (a: any) => any;
  deleteFunc: (a: any) => any;
  bulkDeleteFunc?: (a: any) => any;
  bulkDeleteHook?: () => any;
  addType?: Constructor<S>;
  updateType?: Constructor<U>;
  invisibleDeleteIcon?: boolean;
  fullWidth?: boolean;
  disablePagenation?: boolean;
  defaultValue?: any;
  isLoading?: boolean;
  totalCount?: number;
  limit?: number;
  offset?: number;
  setLimit?: React.Dispatch<React.SetStateAction<number>>;
  setOffset?: React.Dispatch<React.SetStateAction<number>>;
  deleteModalText?: React.ReactElement;
  updateHook?: (a: any) => any;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CommonTable = <
  T extends {
    //  no
  },
  S extends {
    //no
  },
  U extends {
    //no
  }
>({
  title,
  description,
  formId,
  forms = [],
  rows,
  data,
  values,
  addFunc,
  updateFunc,
  deleteFunc,
  bulkDeleteFunc,
  bulkDeleteHook,
  addType,
  updateType,
  invisibleDeleteIcon,
  fullWidth,
  disablePagenation,
  defaultValue,
  isLoading,
  totalCount,
  limit,
  offset,
  setLimit,
  setOffset,
  deleteModalText,
  updateHook,
}: CommonTableProps<T, S, U>) => {
  const [, setShowForm] = useModal(formId);
  const [, setDeleteShow] = useModal("deleteData");
  const [, setBulkDeleteShow] = useModal("bulkDeleteData");
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof T>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [selected, setSelected] = React.useState<T[]>([]);
  const currentPlan = useSelector(
    (state) => state.account.staff.company.companyGroup.plan
  );
  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  const onClickAddButton = () => () => {
    setShowForm(true, { type: "addData", item: defaultValue });
  };

  const itemCount: { [key: string]: { [key: string]: number } } = {
    companyForm: {
      free: 1,
      starter: 3,
      premium: 5,
    },
    shopForm: {
      free: 2,
      starter: 10,
      premium: 25,
    },
    staffForm: {
      free: 2,
      starter: 3,
      premium: 10,
    },
    notificationForm: {
      free: 5,
      starter: 10,
      premium: 25,
    },
    guestForm: {
      free: 5000,
      starter: 15000,
    },
    guestPointForm: {
      free: 5000,
      starter: 15000,
    },
    castForm: {
      free: 20,
      starter: 40,
    },
    castNameForm: {
      free: 30,
      starter: 80,
    },
    castCategoryForm: {
      free: 8,
    },
    nominationForm: {
      free: 6,
    },
    notelClassForm: {
      free: 6,
      starter: 15,
    },
    driverForm: {
      free: 5,
      starter: 15,
    },
    optionForm: {
      free: 25,
    },
    discountForm: {
      free: 5,
      starter: 10,
    },
    mediumForm: {
      free: 10,
      starter: 20,
    },
    cosplayForm: {
      free: 10,
      starter: 20,
    },
    guestCategoryForm: {
      free: 3,
      starter: 5,
    },
  };

  const onClickEditButton = (type: string, item: any) => () => {
    if (updateHook) {
      updateHook(item);
    }
    if (
      itemCount?.[formId]?.[currentPlan] &&
      itemCount[formId][currentPlan] < data.length
    ) {
      alert("制限数を超えているためデータを削除してください。");
    } else {
      setShowForm(true, { type: "editData", item });
    }
  };

  const onClickDeleteButton = (type: string, item: any) => () => {
    setDeleteShow(true, { item });
  };

  const onClickBulkDeleteButton = () => () => {
    setBulkDeleteShow(true, { item: selected });
  };

  function stableSort<V>(array: V[], comparator: (a: V, b: V) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [V, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    if (setOffset) {
      setOffset(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (setLimit && setOffset) {
      setLimit(parseInt(event.target.value, 10));
      setOffset(0);
    } else {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }
  };
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((item) => item);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const isSelected = (item: T) => selected.indexOf(item) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, item: T) => {
    const selectedIndex = selected.indexOf(item);
    let newSelected: T[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  return (
    <>
      <Container
        maxWidth={fullWidth ? false : "xl"}
        style={{ padding: "0 5px" }}
      >
        <Box display="flex" alignItems="center">
          <Box p={1} flexGrow={1}>
            <TheTypography variant="h5">{title}一覧</TheTypography>
            {description}
          </Box>
          {bulkDeleteFunc && (
            <Box p={1}>
              <Button
                variant="contained"
                className={classes.button}
                endIcon={<Icon>delete</Icon>}
                color="default"
                onClick={onClickBulkDeleteButton()}
                disabled={!selected.length}
              >
                一括削除
              </Button>
            </Box>
          )}
          {addType && (
            <Box p={1}>
              <Button
                variant="contained"
                className={classes.button}
                endIcon={<Icon>add</Icon>}
                color="secondary"
                onClick={onClickAddButton()}
              >
                追加
              </Button>
            </Box>
          )}
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {bulkDeleteFunc && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < data.length
                      }
                      checked={
                        data.length > 0 && selected.length === data.length
                      }
                      onChange={onSelectAllClick}
                      inputProps={{ "aria-label": "すべてを選択" }}
                    />
                  </TableCell>
                )}
                {rows.map((row, i) => (
                  <TableCell
                    key={i}
                    sortDirection={orderBy === row.key ? order : false}
                    padding={"checkbox"}
                  >
                    <TableSortLabel
                      active={orderBy === row.key}
                      direction={orderBy === row.key ? order : "asc"}
                      onClick={createSortHandler(row.key)}
                    >
                      <Typography style={{ fontSize: "1.2rem" }} noWrap>
                        {row.label}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                ))}
                {updateType && <TableCell padding="checkbox" />}
                {!invisibleDeleteIcon && <TableCell padding="checkbox" />}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <TableBody>
                {stableSort(data, getComparator(order, orderBy as string))
                  .slice(
                    disablePagenation || (limit && offset !== undefined)
                      ? undefined
                      : page * rowsPerPage,
                    disablePagenation || (limit && offset !== undefined)
                      ? undefined
                      : page * rowsPerPage + rowsPerPage
                  )
                  .map((item, i) => {
                    const isItemSelected = isSelected(item);
                    return (
                      <TableRow key={i}>
                        {bulkDeleteFunc && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onClick={(event) => handleClick(event, item)}
                              inputProps={{ "aria-labelledby": `${i}` }}
                            />
                          </TableCell>
                        )}
                        {values.map((value, i) => (
                          <TableCell
                            key={i}
                            component="th"
                            scope="row"
                            padding={"checkbox"}
                          >
                            <Typography
                              style={{
                                fontSize: "1.2rem",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {value(item)}
                            </Typography>
                          </TableCell>
                        ))}
                        {updateType && (
                          <TableCell padding="checkbox">
                            <IconButton
                              onClick={onClickEditButton("editData", item)}
                            >
                              <Edit />
                            </IconButton>
                          </TableCell>
                        )}
                        {!invisibleDeleteIcon && (
                          <TableCell padding="checkbox">
                            <IconButton
                              onClick={onClickDeleteButton("deleteData", item)}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {!disablePagenation && (
          <TablePagination
            rowsPerPageOptions={[100, 300, 500]}
            component="div"
            count={totalCount || data.length}
            rowsPerPage={limit || rowsPerPage}
            page={offset || page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="ページ毎件数"
          />
        )}
      </Container>
      <FormModal<S, U>
        title={title + "追加"}
        formId={formId}
        forms={forms}
        addFunc={addFunc}
        updateFunc={updateFunc}
        addType={addType}
        updateType={updateType}
      />
      <DeleteModal
        deleteFunc={deleteFunc}
        descriptionComponent={deleteModalText}
      />
      {bulkDeleteFunc && (
        <BulkDeleteModal
          bulkDeleteFunc={bulkDeleteFunc}
          bulkDeleteHook={bulkDeleteHook}
        />
      )}
    </>
  );
};

const TheTypography = styled(Typography)`
  margin: 24px 0;
`;

export default CommonTable;
