import NominationRes from "types/res/nomination/NominationRes";
import {
  Box,
  MenuItem,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Info } from "@material-ui/icons";

type RakingTableHeaderProps = {
  selectNominationId?: number;
  setSelectNominationId: any;
  nominations: NominationRes[];
  link?: boolean;
};
const CastRankingTableHeader = ({
  selectNominationId,
  setSelectNominationId,
  nominations,
  link,
}: RakingTableHeaderProps) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding={"none"} width={"100px"} />
        {link && (
          <>
            <TableCell>
              <Typography style={{ fontSize: "1.2rem" }} noWrap>
                所属店舗
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ fontSize: "1.2rem" }} noWrap>
                クラス
              </Typography>
            </TableCell>
          </>
        )}
        <TableCell padding={"checkbox"}>
          <Typography style={{ fontSize: "1.2rem" }} noWrap>
            出勤日数
          </Typography>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Typography style={{ fontSize: "1.2rem" }} noWrap>
            総出勤時間
          </Typography>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Typography style={{ fontSize: "1.2rem" }} noWrap>
            平均出勤時間(日)
          </Typography>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Typography style={{ fontSize: "1.2rem" }} noWrap>
            総本数
          </Typography>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <TextField
            select
            value={selectNominationId || ""}
            style={{ width: "100px", fontSize: "1.2rem" }}
            inputProps={{ style: { fontSize: "1.2rem" } }}
            onChange={(event) =>
              setSelectNominationId(Number(event.target.value))
            }
          >
            {nominations.map((nomination) => (
              <MenuItem
                key={nomination.nominationId}
                value={nomination.nominationId}
              >
                <Typography style={{ fontSize: "1.2rem" }} noWrap>
                  {nomination.name}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <TextField
            select
            value={selectNominationId || ""}
            style={{ width: "100px", fontSize: "1.2rem" }}
            inputProps={{ style: { fontSize: "1.2rem" } }}
            onChange={(event) =>
              setSelectNominationId(Number(event.target.value))
            }
          >
            {nominations.map((nomination) => (
              <MenuItem
                key={nomination.nominationId}
                value={nomination.nominationId}
              >
                <Typography style={{ fontSize: "1.2rem" }} noWrap>
                  {nomination.name}率
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Box display="flex" alignItems="center">
            <Typography style={{ fontSize: "1.2rem" }} noWrap>
              平均総本数/日
            </Typography>
            <Tooltip
              title={
                "キャスト1人あたりが1日何本平均で接客しているか？がわかります"
              }
              arrow
            >
              <Info />
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <TextField
            select
            value={selectNominationId || ""}
            style={{ width: "100px", fontSize: "1.2rem" }}
            inputProps={{ style: { fontSize: "1.2rem" } }}
            onChange={(event) =>
              setSelectNominationId(Number(event.target.value))
            }
          >
            {nominations.map((nomination) => (
              <MenuItem
                key={nomination.nominationId}
                value={nomination.nominationId}
              >
                <Typography style={{ fontSize: "1.2rem" }} noWrap>
                  平均{nomination.name}本数
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Box display="flex" alignItems="center">
            <Typography style={{ fontSize: "1.2rem" }} noWrap>
              リピート率
            </Typography>
            <Tooltip
              title={
                "キャストが接客したユーザーが三ヶ月以内にリピートする確率がわかります"
              }
              arrow
            >
              <Info />
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Box display={"flex"} alignItems={"center"}>
            <Typography style={{ fontSize: "1.2rem" }} noWrap>
              離脱率
            </Typography>
            <Tooltip
              title={
                "キャストが接客したユーザーが三ヶ月以内に離脱する確率がわかります"
              }
              arrow
            >
              <Info />
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Typography style={{ fontSize: "1.2rem" }} noWrap>
            売上
          </Typography>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Typography style={{ fontSize: "1.2rem" }} noWrap>
            平均単価
          </Typography>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Typography style={{ fontSize: "1.2rem" }} noWrap>
            キャスト給
          </Typography>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Box display={"flex"} alignItems={"center"}>
            <Typography style={{ fontSize: "1.2rem" }} noWrap>
              キャスト時給
            </Typography>
            <Tooltip
              title={"キャストが1時間あたりの給与を獲得してるかがわかります"}
              arrow
            >
              <Info />
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Typography style={{ fontSize: "1.2rem" }} noWrap>
            遅刻
          </Typography>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Typography style={{ fontSize: "1.2rem" }} noWrap>
            当欠
          </Typography>
        </TableCell>
        <TableCell padding={"checkbox"}>
          <Box display={"flex"} alignItems={"center"}>
            <Typography style={{ fontSize: "1.2rem" }} noWrap>
              アンケート
            </Typography>
            <Tooltip
              title={
                "キャストの集計したアンケートの点数の平均点数がわかります。"
              }
              arrow
            >
              <Info />
            </Tooltip>
          </Box>
        </TableCell>
        {link && <TableCell padding={"checkbox"} />}
      </TableRow>
    </TableHead>
  );
};
export default CastRankingTableHeader;
