import { DateTime } from "luxon";
import RequestUtils from "utils/RequestUtils";
import PageUtils from "utils/PageUtils";
import { validate, ValidationError } from "class-validator";
import { FORMAT_TYPE } from "utils/DateTimeUtils";

export enum CsvFieldType {
  Text,
  Number,
  Date,
  DateTime,
  Time,
  Boolean,
  Array,
  Object,
  Enum,
  guestGuestCategoryShops,
}
export default class CsvUtils {
  private constructor() {}

  public static async templateDownload(templateUrl: string, fileName: string) {
    const blob = await RequestUtils.getBlob(templateUrl);
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    PageUtils.download(new Blob([bom, blob], { type: "text/csv" }), fileName);
  }

  public static async download(fileName: string, records: any[]) {
    const data = records.map((record) => record.join(",")).join("\r\n");
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    PageUtils.download(new Blob([bom, data], { type: "text/csv" }), fileName);
  }

  public static handleError() {}

  public static async createBulkInsertData(
    csvHeader: {
      key: string;
      label: string;
      type: CsvFieldType;
      relation?: any[];
      relationKey?: string;
      relationId?: string;
      enumObject?: any;
      unique?: boolean;
      sub?: boolean;
      subRelationKey?: string;
      subRelationValue?: string;
      shopId?: number;
    }[],
    data: any[],
    addType: any,
    existingTelData?: string[],
    changeDateHour?: number
  ) {
    data = data.filter((d) => d.data.filter(Boolean).length);
    this.formatValidate(csvHeader, data);
    const reqs: (typeof addType | ValidationError[])[] = [];
    const displayDataArray: { [key: string]: string | number | Date }[] = [];
    await Promise.all(
      data.map(async (b, dataIndex) => {
        let req: { [key: string]: any } = {};
        let datum: { [key: string]: any } = {};
        b.data.forEach((c: string, index: number) => {
          if (!csvHeader[index]) return;
          let dataList = [];
          let subRelationValue = undefined;
          if (csvHeader[index]?.unique) {
            dataList = data.map((b) => b.data[index]);
            dataList.splice(dataIndex, 1);
          }
          if (csvHeader[index]?.sub) {
            subRelationValue = req[csvHeader[index].subRelationKey as string];
          }
          if (csvHeader[index]?.key === "planWorkStart") {
            dataList = data.map((b) => ({
              castId: csvHeader[0]?.relation?.find(
                (cast) => cast.displayName === b.data[0]
              )?.castId,
              planWorkDate: DateTime.fromFormat(b.data[1], "yyyy/M/d h:m")
                .isValid
                ? DateTime.fromFormat(b.data[1], "yyyy/M/d h:m")
                    .minus({ hour: changeDateHour })
                    .toFormat(FORMAT_TYPE.YEAR_DAY)
                : undefined,
            }));
            dataList.splice(dataIndex, 1);
          }
          req[csvHeader[index].key as string] = this.typeCastReq(
            c,
            csvHeader[index].type,
            csvHeader[index].relation,
            csvHeader[index].relationKey,
            csvHeader[index].relationId,
            csvHeader[index].enumObject,
            dataList,
            csvHeader[index].unique,
            csvHeader[index].subRelationKey,
            String(subRelationValue),
            csvHeader[index].shopId,
            changeDateHour
          );
          datum[csvHeader[index].key as string] = this.typeCastDisplayData(
            c,
            csvHeader[index].type,
            csvHeader[index].relation,
            csvHeader[index].relationKey,
            csvHeader[index].relationKey,
            csvHeader[index].subRelationKey,
            String(subRelationValue),
            csvHeader[index].shopId
          );
        });
        if (req?.tel && req?.tel !== "重複しています") {
          req = {
            ...req,
            tel: this.transformTel(req.tel),
          };
        }
        if (
          req?.tel &&
          existingTelData &&
          existingTelData.includes(this.transformTel(req.tel))
        ) {
          req = {
            ...req,
            tel: "重複しています",
          };
        }
        if (datum?.tel) {
          datum = {
            ...datum,
            tel: this.transformTel(datum.tel),
          };
        }
        const castData = Object.assign(new addType(), { ...req });
        const errors: (
          | ValidationError
          | { property: string; value: string }
        )[] = await validate(castData);
        if (Object.values(req).find((r) => r === "重複しています")) {
          errors.push({ property: "tel", value: "重複しています。" });
        }
        if (errors.length > 0) {
          req = errors;
        } else {
          req = castData;
        }
        reqs.push(req);
        displayDataArray.push(datum);
      })
    );
    return { bulkInsertReq: reqs, displayDataArray: displayDataArray };
  }

  private static formatValidate(
    csvHeader: { key: string; label: string }[],
    data: { data: any; error: any; meta: any }[]
  ) {
    if (
      data[0].data.toString() !==
      csvHeader.map((header) => header.label).toString()
    ) {
      alert(
        "フォーマットが誤っています。再度テンプレートをダウンロードしてください。"
      );
      throw new Error(
        "フォーマットが誤っています。再度テンプレートをダウンロードしてください。"
      );
    }
    data.splice(0, 1);
    if (!data.length) {
      alert(
        "データが入力されていません。2行目以降にデータを入力してください。"
      );
      throw new Error(
        "データが入力されていません。2行目以降にデータを入力してください。"
      );
    }
    // if (data.length >= 1000) {
    //   alert(
    //     "1000件以上の一括登録は対応しておりません。分割して登録をお願いいたします。"
    //   );
    //   throw new Error(
    //     "1000件以上の一括登録は対応しておりません。分割して登録をお願いいたします。"
    //   );
    // }
  }

  private static typeCastDisplayData(
    datum: string,
    type: CsvFieldType,
    relation?: any[],
    relationKey?: string,
    relationId?: string,
    subRelationKey?: string,
    subRelationValue?: string,
    shopId?: number
  ) {
    switch (type) {
      case CsvFieldType.Number:
        return Number(datum);
      case CsvFieldType.Date:
        return datum;
      case CsvFieldType.DateTime:
        return datum
          ? DateTime.fromFormat(datum, "yyyy/M/d h:m").toJSDate()
          : "";
      case CsvFieldType.Time:
        return datum;
      case CsvFieldType.Enum:
        return datum;
      case CsvFieldType.Boolean:
        return ["済", "表示"].includes(datum) ? "◯" : "☓";
      case CsvFieldType.Text:
        return relationKey && relationId
          ? relation?.find((r) => r[relationKey] === datum)?.[relationId] ||
              "紐付いてません"
          : datum;
      case CsvFieldType.Array: {
        if (relationKey && relationId) {
          return datum.includes(",")
            ? datum
                .split(",")
                .map(
                  (d) =>
                    relation?.find((r) => r[relationKey] === d)?.[relationId] ||
                    "紐付いてません"
                )
            : relation?.find((r) => r[relationKey] === datum)?.[relationId]
            ? [relation?.find((r) => r[relationKey] === datum)?.[relationId]]
            : "紐付いてません";
        } else {
          return datum.includes(",") ? datum.split(",") : [datum];
        }
      }
      case CsvFieldType.Object: {
        if (relationKey && relationId) {
          if (relationKey === "tel") {
            const tel = this.transformTel(datum);
            return (
              relation?.find((r) => String(r[relationKey]) === String(tel))?.[
                relationId
              ] || "紐付いてません"
            );
          } else {
            if (subRelationKey && subRelationValue) {
              return (
                relation?.find(
                  (r) =>
                    String(r[relationKey]) === datum &&
                    String(r[subRelationKey]) === subRelationValue
                )?.[relationId] || "紐付いてません"
              );
            } else {
              return (
                relation?.find((r) => String(r[relationKey]) === datum)?.[
                  relationId
                ] || "紐付いてません"
              );
            }
          }
        }
        break;
      }
      case CsvFieldType.guestGuestCategoryShops: {
        if (!relation) return "紐づいてません";
        return (
          relation.find(
            (r) =>
              r.name === datum &&
              r.shops.map((shop: any) => shop.shopId).includes(shopId)
          )?.name || "紐づいてません"
        );
      }
      default: {
        const _: never = type;
        throw new Error(_);
      }
    }
  }
  private static typeCastReq(
    datum: string,
    type: CsvFieldType,
    relation?: any[],
    relationKey?: string,
    relationId?: string,
    enumObject?: { [key: string]: string },
    data?: any[],
    unique?: boolean,
    subRelationKey?: string,
    subRelationValue?: string,
    shopId?: number,
    changeDateHour?: number
  ) {
    switch (type) {
      case CsvFieldType.Number:
        return Number(datum);
      case CsvFieldType.Date:
        return datum
          ? DateTime.fromFormat(datum, "yyyy/M/d").toJSDate()
          : undefined;
      case CsvFieldType.DateTime: {
        if (subRelationValue && data) {
          const date = DateTime.fromFormat(datum, "yyyy/M/d H:m")
            .minus({ hours: changeDateHour })
            .toFormat(FORMAT_TYPE.YEAR_DAY);
          return !data.find(
            (d) =>
              d.planWorkDate === date && Number(subRelationValue) === d.castId
          )
            ? DateTime.fromFormat(datum, "yyyy/M/d H:m").toJSDate()
            : undefined;
        }
        return datum
          ? DateTime.fromFormat(datum, "yyyy/M/d H:m").toJSDate()
          : undefined;
      }
      case CsvFieldType.Boolean:
        return ["済", "表示"].includes(datum);
      case CsvFieldType.Time:
        return datum;
      case CsvFieldType.Text: {
        if (unique && data && data.find((d) => d === datum)) {
          return "重複しています";
        }
        return relationKey && relationId
          ? relation?.find((r) => r[relationKey] === datum)?.[relationId]
          : datum;
      }
      case CsvFieldType.Array: {
        if (relationKey && relationId) {
          return datum.includes(",")
            ? datum
                .split(",")
                .map(
                  (d) =>
                    relation?.find((r) => r[relationKey] === d)?.[relationId]
                )
            : relation?.find((r) => r[relationKey] === datum)?.[relationId]
            ? [relation?.find((r) => r[relationKey] === datum)?.[relationId]]
            : undefined;
        } else {
          return datum.includes(",") ? datum.split(",") : [datum];
        }
      }
      case CsvFieldType.Object: {
        if (relationKey && relationId) {
          if (relationKey === "tel") {
            const tel = this.transformTel(datum);
            return relation?.find((r) => String(r[relationKey]) === tel)?.[
              relationId
            ];
          } else {
            if (subRelationKey && subRelationValue) {
              return relation?.find(
                (r) =>
                  String(r[relationKey]) === datum &&
                  String(r[subRelationKey]) === subRelationValue
              )?.[relationId];
            } else {
              return relation?.find((r) => String(r[relationKey]) === datum)?.[
                relationId
              ];
            }
          }
        }
        break;
      }
      case CsvFieldType.Enum:
        return enumObject
          ? Object.keys(enumObject).find((key) => enumObject[key] === datum) ||
              undefined
          : undefined;
      case CsvFieldType.guestGuestCategoryShops: {
        if (!relation) return undefined;
        const guestCategoryId = relation.find(
          (r) =>
            r.name === datum &&
            r.shops.map((shop: any) => shop.shopId).includes(shopId)
        )?.guestCategoryId;
        return {
          guestCategoryId,
          shopId,
        };
      }
      default: {
        const _: never = type;
        throw new Error(_);
      }
    }
  }

  static transformTel(tel: string) {
    let transformTel = tel;
    transformTel = tel.replace(/[-‐－―ー−]/g, "");
    if (!transformTel.match(/^0/g)) {
      transformTel = "0" + transformTel;
    }
    return transformTel;
  }
}
