import { Box, CssBaseline, Typography } from "@material-ui/core";
import Footer from "components/Footer";
import React from "react";
import styled from "styled-components";
import SpHeader from "components/Sp/SpHeader";
import Tooltip from "rc-tooltip";
const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  margin: 0 auto;
`;

const LinePopup = styled.div`
  position: fixed;
  cursor: pointer;
  width: 100%;
  max-width: 768px;
  height: 40px;
  z-index: 100000;
  bottom: 10px;
`;
const MainContent = styled.div`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
`;

const SpMain: React.FC = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <AppContent>
        <SpHeader />
        <MainContent>{children}</MainContent>
        <Footer />
        <LinePopup
          onClick={() => {
            window.open("https://lin.ee/fctnyNX", "_blank");
          }}
        >
          <Tooltip
            trigger="hover"
            placement="bottomRight"
            overlayStyle={{ zIndex: "1201" }}
            overlay={
              <Box display={"flex"} flexDirection={"column"} padding={1}>
                <Typography>お問い合わせはLINEから</Typography>
              </Box>
            }
          >
            <img
              src="/line.png"
              width="40"
              height="40"
              alt={"line popup"}
              style={{ float: "right" }}
            />
          </Tooltip>
        </LinePopup>
      </AppContent>
    </Root>
  );
};
export default SpMain;
