import Modal from "components/Modal";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useModal from "hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardTimePicker } from "@material-ui/pickers";
import OrderRes from "../../../types/res/order/OrderRes";
import UpdateOrderReq from "../../../types/req/order/UpdateOrderReq";
import { updateOrder } from "redux/actions/order";
import ForwardIcon from "@material-ui/icons/Forward";
import CourseRes from "../../../types/res/course/CourseRes";
import { DateTime } from "luxon";
import { fetchCourse } from "redux/actions/course";
type Props = {
  setStateOrders: any;
  orders: OrderRes[];
};
const CastScheduleOnItemResize: React.FC<Props> = ({
  setStateOrders,
  orders,
}) => {
  const [castScheduleOnItemResizeModal, setCastScheduleOnItemResizeShow] =
    useModal("castScheduleOnItemResize");
  const castShifts = useSelector((state) => state.castShift);
  const companyId = useSelector((state) => state.account.staff.companyId);
  const courses = useSelector((state) => state.course);
  const additionalTimes = useSelector((state) => state.additionalTime);
  const [loading, setLoading] = useState(false);
  const [selectCastNameId, setSelectCastNameId] = useState<number>();
  const [planInTime, setPlanInTime] = useState<Date | null>();
  const [planOutTime, setPlanOutTime] = useState<Date | null>();
  const [departureTime, setDepartureTime] = useState<Date | null>();
  const [filterCourses, setFilterCourses] = useState<CourseRes[]>([]);
  const [courseTime, setCourseTime] = useState<number>();
  const [courseId, setCourseId] = useState<number>();
  const [oldOrder, setOldOrder] = useState<OrderRes>();
  const [additionalTimeId, setAdditionalTimeId] = useState<number>();
  const [additionalTimeCount, setAdditionalTimeCount] = useState<number>();
  const dispatch = useDispatch();
  const castNames = Array.from(
    new Map(
      castShifts
        .flatMap((castShift) => castShift.cast.castNames)
        .map((castName) => [castName.castNameId, castName])
    ).values()
  );
  useEffect(() => {
    if (!castScheduleOnItemResizeModal?.meta?.order) return;
    setSelectCastNameId(castScheduleOnItemResizeModal.meta.order.castNameId);
    setDepartureTime(castScheduleOnItemResizeModal.meta.order.departureTime);
    setPlanInTime(castScheduleOnItemResizeModal.meta.order.planInTime);
    setAdditionalTimeId(
      castScheduleOnItemResizeModal.meta.order.additionalTimeId
    );
    setAdditionalTimeCount(
      castScheduleOnItemResizeModal.meta.order.additionalTimeCount
    );
    const setOrder = orders.find(
      (order) =>
        order.orderId === castScheduleOnItemResizeModal.meta.order.orderId
    );
    setOldOrder(setOrder);
    setPlanOutTime(setOrder?.planOutTime);
    if (castScheduleOnItemResizeModal.meta.order.course) {
      setCourseTime(castScheduleOnItemResizeModal.meta.order.course.time);
      setCourseId(castScheduleOnItemResizeModal.meta.order.course.courseId);
    }
  }, [castScheduleOnItemResizeModal?.meta]);

  useEffect(() => {
    dispatch(fetchCourse(companyId));
  }, []);

  useEffect(() => {
    setFilterCourses(courses);
  }, [courses]);

  useEffect(() => {
    if (!castScheduleOnItemResizeModal.meta?.order?.castName || !courses.length)
      return;
    const result = courses.filter(
      (course) =>
        course.notelClassId ===
          castScheduleOnItemResizeModal.meta.order?.castName.notelClassId &&
        course.shopId ===
          castScheduleOnItemResizeModal.meta.order?.castName.shopId
    );
    setFilterCourses(result);
  }, [courses, castScheduleOnItemResizeModal.meta?.order?.castName]);

  useEffect(() => {
    if (castScheduleOnItemResizeModal.show) return;
    setStateOrders(orders);
  }, [castScheduleOnItemResizeModal?.show]);

  useEffect(() => {
    const planInTimeToDateTime = DateTime.fromJSDate(planInTime || new Date());
    setPlanOutTime(
      planInTimeToDateTime.plus({ minutes: courseTime }).toJSDate()
    );
    const setAdditionalTime = additionalTimes.find(
      (a) => a.additionalTimeId === additionalTimeId
    );
    if (!setAdditionalTime || !additionalTimeCount) return;
    setPlanOutTime(
      planInTimeToDateTime
        .plus({
          minutes:
            (courseTime || 0) + setAdditionalTime.time * additionalTimeCount,
        })
        .toJSDate()
    );
  }, [additionalTimeCount, additionalTimeId, courseTime]);

  useEffect(() => {
    setFilterCourses(
      courseTime
        ? filterCourses.filter((course) => course.time === courseTime)
        : courses.filter(
            (course) =>
              course.notelClassId ===
                castScheduleOnItemResizeModal.meta.order?.castName
                  .notelClassId &&
              course.shopId ===
                castScheduleOnItemResizeModal.meta.order?.castName.shopId
          )
    );
  }, [courseTime]);
  const onClickSubmit = async () => {
    const order = { ...castScheduleOnItemResizeModal.meta.order };
    order.planOutTime = planOutTime;
    order.additionalTimeId = additionalTimeId;
    order.additionalTimeCount = additionalTimeCount;
    order.courseId = courseId;
    order.courseTime = courseTime;
    order.totalTime =
      courseTime +
        order?.additionalTimeOrders?.reduce(
          (sum: number, additionalTimeOrder: any) =>
            sum +
            (additionalTimeOrder?.additionalTime?.time || 0) *
              additionalTimeOrder.count,
          0
        ) || 0;
    await dispatch(updateOrder(companyId, new UpdateOrderReq(order)));
    setCastScheduleOnItemResizeShow(false);
  };

  return (
    <Modal
      show={castScheduleOnItemResizeModal.show}
      setShow={setCastScheduleOnItemResizeShow}
      title={`${
        castScheduleOnItemResizeModal.meta?.order?.castName?.name ||
        "未アサイン"
      }さんへの予約変更`}
    >
      <Container>
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">変更前</Typography>
            <TextField
              label="源氏名"
              select
              value={oldOrder?.castName?.castNameId || "未設定"}
              fullWidth
              variant="outlined"
              margin="normal"
              disabled={true}
              onChange={(event) => {
                setSelectCastNameId(Number(event.target.value));
              }}
            >
              {castNames.map((castName) => (
                <MenuItem key={castName.castNameId} value={castName.castNameId}>
                  {castName.name}
                </MenuItem>
              ))}
            </TextField>
            <KeyboardTimePicker
              label="出発"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={oldOrder?.departureTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setDepartureTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              disabled={true}
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="予定IN"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={oldOrder?.planInTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setPlanInTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              disabled={true}
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="予定OUT"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={oldOrder?.planOutTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setPlanOutTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              disabled={true}
              style={{ marginTop: "10px" }}
            />
            <TextField
              label="時間"
              value={
                oldOrder?.courseTime ? `${oldOrder?.courseTime}分` : "未設定"
              }
              fullWidth
              variant="outlined"
              margin="normal"
              disabled
            />
            <TextField
              label="コース"
              value={oldOrder?.course?.name || "未設定"}
              fullWidth
              variant="outlined"
              margin="normal"
              disabled
            />
            <Box display="flex" justifyContent="center" alignItems="center">
              <TextField
                label="延長"
                select
                value={oldOrder?.additionalTimeId || "未設定"}
                fullWidth
                variant="outlined"
                margin="normal"
                disabled
              >
                {additionalTimes.map((additionalTime) => (
                  <MenuItem
                    key={additionalTime.additionalTimeId}
                    value={additionalTime.additionalTimeId}
                  >
                    {additionalTime.time}
                  </MenuItem>
                ))}
              </TextField>
              <Typography style={{ marginLeft: "3px" }}>分</Typography>
              <Typography style={{ margin: "0 10px" }}>×</Typography>
              <TextField
                value={oldOrder?.additionalTimeCount || ""}
                fullWidth
                variant="outlined"
                margin="normal"
                disabled
              />
              <Typography style={{ marginLeft: "3px" }}>回</Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginX={3}
          >
            <ForwardIcon />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">変更後</Typography>
            <TextField
              label="源氏名"
              select
              value={selectCastNameId}
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={(event) => {
                setSelectCastNameId(Number(event.target.value));
              }}
              disabled
            >
              {castNames.map((castName) => (
                <MenuItem key={castName.castNameId} value={castName.castNameId}>
                  {castName.name}
                </MenuItem>
              ))}
            </TextField>
            <KeyboardTimePicker
              label="出発"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={departureTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setDepartureTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
              disabled
            />
            <KeyboardTimePicker
              label="予定IN"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={planInTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setPlanInTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
              disabled
            />
            <KeyboardTimePicker
              label="予定OUT"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={planOutTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setPlanOutTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
              disabled
            />
            <TextField
              label="時間"
              select
              value={courseTime || "未設定"}
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={(event) => setCourseTime(Number(event.target.value))}
            >
              <option aria-label="None" value="" />
              {filterCourses.map((course) => (
                <MenuItem key={course.time} value={course.time}>
                  {course.time}分
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="コース"
              select
              value={courseId || "未設定"}
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={(event) => setCourseId(Number(event.target.value))}
            >
              {filterCourses.map((course) => (
                <MenuItem key={course.courseId} value={course.courseId}>
                  {course.name}
                </MenuItem>
              ))}
            </TextField>
            <Box display="flex" justifyContent="center" alignItems="center">
              <TextField
                label="延長"
                select
                value={additionalTimeId || "未設定"}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={(event) =>
                  setAdditionalTimeId(Number(event.target.value))
                }
              >
                {additionalTimes.map((additionalTime) => (
                  <MenuItem
                    key={additionalTime.additionalTimeId}
                    value={additionalTime.additionalTimeId}
                  >
                    {additionalTime.time}
                  </MenuItem>
                ))}
              </TextField>
              <Typography style={{ marginLeft: "3px" }}>分</Typography>
              <Typography style={{ margin: "0 10px" }}>×</Typography>
              <TextField
                value={additionalTimeCount ? additionalTimeCount.toString() : 0}
                fullWidth
                variant="outlined"
                margin="normal"
                type="number"
                onChange={(event) =>
                  setAdditionalTimeCount(Number(event.target.value))
                }
              />
              <Typography style={{ marginLeft: "3px" }}>回</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          style={{ margin: "10px 0" }}
          justifyContent="space-evenly"
        >
          <Button
            variant="contained"
            color="default"
            disabled={loading}
            onClick={() => setCastScheduleOnItemResizeShow(false)}
          >
            {loading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "いいえ"
            )}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={onClickSubmit}
          >
            {loading ? <CircularProgress color="inherit" size={24} /> : "はい"}
          </Button>
        </Box>
      </Container>
    </Modal>
  );
};
export default CastScheduleOnItemResize;
