import RequestUtils from "utils/RequestUtils";
import CompanyGroupRes from "types/res/companyGroup/CompanyGroupRes";
import UpdateCompanyGroupReq from "types/req/companyGroup/UpdateCompanyGroupReq";
import DeleteCompanyGroupReq from "types/req/companyGroup/DeleteCompanyGroupReq";
import CreateCompanyGroupReq from "types/req/companyGroup/CreateCompanyGroupReq";

export default class CompanyGroupApi {
  private constructor() {}

  static async findOne(
    companyGroupId: number,
    companyId: number
  ): Promise<CompanyGroupRes> {
    return await RequestUtils.get(
      CompanyGroupRes,
      `/companyGroup/findOne/${companyGroupId}/${companyId}`
    );
  }

  static async findAll(): Promise<CompanyGroupRes[]> {
    return await RequestUtils.getArray(
      CompanyGroupRes,
      `/companyGroup/findAll`
    );
  }

  static async create(data: CreateCompanyGroupReq) {
    return await RequestUtils.post(
      CompanyGroupRes,
      `/companyGroup/create`,
      data
    );
  }

  static async update(
    companyId: number,
    data: UpdateCompanyGroupReq
  ): Promise<CompanyGroupRes> {
    return await RequestUtils.post(
      CompanyGroupRes,
      `/companyGroup/update/${companyId}`,
      data
    );
  }

  static async recess(companyId: number, data: DeleteCompanyGroupReq) {
    return await RequestUtils.postVoid(
      `/companyGroup/recess/${companyId}`,
      data
    );
  }
  static async delete(data: DeleteCompanyGroupReq) {
    return await RequestUtils.postVoid(
      `/companyGroup/delete/${data.companyGroupId}`,
      data
    );
  }
}
