import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty } from "class-validator";

export default class DeleteNominationReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly nominationId: number;

  constructor(arg: { nominationId: number }) {
    arg = arg || {};
    this.nominationId = arg.nominationId;
  }
}
