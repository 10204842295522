import querystring from "querystring";
import RequestUtils from "utils/RequestUtils";

export default class GuestAnalyticsApi {
  private constructor() {}

  static async findAll(
    companyId: number,
    endDate: string,
    shopIds: string,
    guestGenreType: string,
    guestAnalyticsType: string,
    limit: number,
    offset: number
  ) {
    const queryString = querystring.stringify({
      shopIds,
      guestGenreType,
      guestAnalyticsType,
      limit,
      offset,
    });
    return await RequestUtils.getPlain(
      `/guestAnalytics/findAll/${companyId}/${endDate}?${queryString}`
    );
  }

  static async count(
    companyId: number,
    endDate: string,
    shopIds: string,
    guestGenreType: string,
    guestAnalyticsType: string
  ) {
    const queryString = querystring.stringify({
      shopIds,
      guestGenreType,
      guestAnalyticsType,
    });
    return await RequestUtils.count(
      `/guestAnalytics/count/${companyId}/${endDate}?${queryString}`
    );
  }

  static async findDetail(
    companyId: number,
    guestId: number,
    endDate: string,
    shopIds: string,
    guestGenreType: string,
    guestAnalyticsType: string
  ) {
    const queryString = querystring.stringify({
      shopIds,
      guestGenreType,
      guestAnalyticsType,
    });
    return await RequestUtils.getPlain(
      `/guestAnalytics/findDetail/${companyId}/${guestId}/${endDate}?${queryString}`
    );
  }
}
