import RequestUtils from "utils/RequestUtils";
import DeleteCarTransferScheduleReq from "types/req/carTransferSchedule/DeleteCarTransferScheduleReq";
import UpdateCarTransferScheduleReq from "types/req/carTransferSchedule/UpdateCarTransferScheduleReq";
import CreateCarTransferScheduleReq from "types/req/carTransferSchedule/CreateCarTransferScheduleReq";

export default class CarTransferScheduleApi {
  private constructor() {
    // not supported
  }

  static async create(companyId: number, data: CreateCarTransferScheduleReq) {
    return await RequestUtils.postVoid(
      `/carTransferSchedule/create/${companyId}`,
      data
    );
  }

  static async update(companyId: number, data: UpdateCarTransferScheduleReq) {
    return await RequestUtils.postVoid(
      `/carTransferSchedule/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteCarTransferScheduleReq) {
    return await RequestUtils.postVoid(
      `/carTransferSchedule/delete/${companyId}`,
      data
    );
  }
}
