import { IsNotEmpty } from "class-validator";

export default class DeleteCastReq {
  @IsNotEmpty({
    message: "",
  })
  readonly castId: number;

  constructor(arg: { castId: number }) {
    arg = arg || {};
    this.castId = arg.castId;
  }
}
