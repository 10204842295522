import { reducerWithInitialState } from "typescript-fsa-reducers";
import guestCategoryActions from "redux/actions/guestCategory";
import GuestCategoryRes from "types/res/guestCategory/GuestCategoryRes";
export type GuestCategoryState = GuestCategoryRes[];

const initialState = [] as GuestCategoryState;

const guestCategoryReducer = reducerWithInitialState(initialState)
  .case(guestCategoryActions.addGuestCategoryAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(
    guestCategoryActions.updateGuestCategoryAction.done,
    (state, payload) => {
      return state.map((item) =>
        item.guestCategoryId === payload.params.guestCategoryId
          ? payload.result
          : item
      );
    }
  )
  .case(
    guestCategoryActions.deleteGuestCategoryAction.done,
    (state, payload) => {
      return state.filter(
        (item) => item.guestCategoryId !== payload.params.guestCategoryId
      );
    }
  )
  .case(
    guestCategoryActions.fetchGuestCategoryAction.done,
    (state, payload) => {
      return [...payload.result];
    }
  )
  .case(
    guestCategoryActions.simpleFetchGuestCategoryAction.done,
    (state, payload) => {
      return [...payload.result];
    }
  );
export default guestCategoryReducer;
