import RequestUtils from "utils/RequestUtils";
import GuestRes from "types/res/guest/GuestRes";
import CreateGuestReq from "types/req/guest/CreateGuestReq";
import UpdateGuestReq from "types/req/guest/UpdateGuestReq";
import DeleteGuestReq from "types/req/guest/DeleteGuestReq";
import querystring from "querystring";

export default class GuestApi {
  private constructor() {}

  static async findCti(
    companyId: number,
    acd: string,
    tel: string
  ): Promise<GuestRes> {
    return await RequestUtils.get(
      GuestRes,
      `/guest/findCti/${companyId}/${tel}?acd=${acd}`
    );
  }

  static async findAll(
    companyId: number,
    limit?: number,
    offset?: number
  ): Promise<GuestRes[]> {
    const query = limit ? querystring.stringify({ limit, offset }) : undefined;
    return await RequestUtils.getArray(
      GuestRes,
      `/guest/findAll/${companyId}${query ? `?${query}` : ""}`
    );
  }

  static async findOne(companyId: number, guestId: number): Promise<GuestRes> {
    return await RequestUtils.get(
      GuestRes,
      `/guest/findOne/${companyId}/${guestId}`
    );
  }

  static async simpleFindAll(companyId: number): Promise<GuestRes[]> {
    return await RequestUtils.getArray(
      GuestRes,
      `/guest/simpleFindAll/${companyId}`
    );
  }

  static async count(companyId: number, data: any) {
    return await RequestUtils.countPost(`/guest/count/${companyId}`, data);
  }

  static async checkDuplicateNumber(companyId: number, tel: string) {
    return await RequestUtils.stringListPost(
      `/guest/checkDuplicateNumber/${companyId}`,
      { tel }
    );
  }

  static async search(companyId: number, data: any) {
    return await RequestUtils.postArray(
      GuestRes,
      `/guest/search/${companyId}`,
      data
    );
  }

  static async create(companyId: number, data: CreateGuestReq) {
    return await RequestUtils.post(
      GuestRes,
      `/guest/create/${companyId}`,
      data
    );
  }

  static async bulkInsert(companyId: number, data: CreateGuestReq[]) {
    return await RequestUtils.postArray(
      GuestRes,
      `/guest/bulkInsert/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    guestId: number,
    data: UpdateGuestReq
  ): Promise<GuestRes> {
    return await RequestUtils.post(
      GuestRes,
      `/guest/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteGuestReq) {
    return await RequestUtils.postVoid(`/guest/delete/${companyId}`, data);
  }

  static async bulkDelete(companyId: number, data: DeleteGuestReq[]) {
    return await RequestUtils.postVoid(`/guest/bulkDelete/${companyId}`, data);
  }

  static async sendMail(companyId: number, guestId: number) {
    return await RequestUtils.postVoid(
      `/guest/sendMail/${companyId}/${guestId}`,
      {}
    );
  }
}
