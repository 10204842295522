import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty } from "class-validator";

export default class DeleteRecruitingMediaReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  recruitingMediaId!: number;

  constructor(arg: { recruitingMediaId: number }) {
    arg = arg || {};
    this.recruitingMediaId = arg.recruitingMediaId;
  }
}
