import { DataValidationError } from "constants/DataValidationError";
import {
  IsArray,
  IsBoolean,
  IsDate,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";
import NotificationType from "../../enum/NotificationType";
import { Type } from "class-transformer";

export default class UpdateNotificationReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  notificationId!: number;

  @MaxLength(Spec.maxLength.notification.title, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.cosplay.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  title!: string;

  @MaxLength(Spec.maxLength.notification.content, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.cosplay.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  content!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  type!: NotificationType;

  @IsOptional()
  @Type(() => Date)
  @IsDate()
  startDate!: Date | null;

  @IsOptional()
  @Type(() => Date)
  @IsDate()
  endDate!: Date | null;

  @IsOptional()
  @Type(() => Boolean)
  @IsBoolean()
  isIndefinite!: boolean | null;

  @IsOptional()
  @IsNumber()
  sort!: number | null;

  @IsArray()
  shops!: number[];

  constructor(arg: {
    notificationId: number;
    shops: number[];
    title: string;
    content: string;
    type: NotificationType;
    startDate: Date | null;
    endDate: Date | null;
    isIndefinite: boolean | null;
    sort: number | null;
  }) {
    arg = arg || {};
    this.notificationId = arg.notificationId;
    this.shops = arg.shops;
    this.title = arg.title;
    this.content = arg.content;
    this.type = arg.type;
    this.startDate = arg.startDate;
    this.endDate = arg.endDate;
    this.isIndefinite = arg.isIndefinite;
    this.sort = arg.sort;
  }
}
