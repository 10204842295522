import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StaffRole from "types/enum/StaffRole";
import EnumUtils from "utils/EnumUtils";
import {
  fetchCompanies,
  fetchCompaniesByCompanyGroupId,
} from "redux/actions/company";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import { updateStaff } from "redux/actions/staff";

const SpCompanySwitch = () => {
  const dispatch = useDispatch();
  const staff = useSelector((state) => state.account.staff);
  const accessToken = useSelector((state) => state.account.accessToken);
  const companies = useSelector((state) => state.company);

  useEffect(() => {
    if (
      [
        StaffRole.notelMaster,
        StaffRole.notelCallCenterEmployee,
        StaffRole.notelCallCenterPartTime,
      ].includes(
        EnumUtils.mapToEnum(StaffRole, staff?.role) ||
          StaffRole.clientShopPartTime
      )
    ) {
      dispatch(fetchCompanies());
    }
    if (
      StaffRole.clientMaster === EnumUtils.mapToEnum(StaffRole, staff?.role) &&
      staff?.company?.companyGroupId
    ) {
      dispatch(
        fetchCompaniesByCompanyGroupId(
          staff.companyId,
          staff.company.companyGroupId
        )
      );
    }
  }, []);
  const onClickSwitchCompany = (companyId: number) => {
    dispatch(
      updateStaff(companyId, { ...staff, companyId: companyId }, accessToken)
    );
  };
  return (
    <Box display="flex" flexDirection="column" paddingY={2}>
      <Box paddingX={2} marginBottom={1}>
        <Typography>現在ログイン中の企業：{staff.company.name}</Typography>
      </Box>
      <Divider />
      <Box paddingX={2} marginTop={1}>
        <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
          企業一覧
        </Typography>
        {companies.map((company) => (
          <Box
            key={company.companyId}
            marginTop={1}
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>{company.name}</Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onClickSwitchCompany(company.companyId)}
            >
              切り替え
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default SpCompanySwitch;
