import { reducerWithInitialState } from "typescript-fsa-reducers";
import journalActions from "redux/actions/journal";
import JournalRes from "types/res/journal/JournalRes";
export type JournalState = JournalRes[];

const initialState = [] as JournalState;

const journalReducer = reducerWithInitialState(initialState)
  .case(journalActions.addJournalAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(journalActions.updateJournalAction.done, (state, payload) => {
    return state.map((item) =>
      item.journalId === payload.params.journalId ? payload.result : item
    );
  })
  .case(journalActions.deleteJournalAction.done, (state, payload) => {
    return state.filter((item) => item.journalId !== payload.params.journalId);
  })
  .case(journalActions.fetchJournalAction.done, (state, payload) => {
    return [...payload.result];
  });
export default journalReducer;
