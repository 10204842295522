import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

type Props = {
  formData: any;
  onChange: (key: string, value: any) => void;
};
const SpOrderPrice = ({ formData, onChange }: Props) => {
  const shops = useSelector((state) => state.shop);
  const guestCategories = useSelector((state) => state.guestCategory);
  useEffect(() => {
    if (!formData.totalFee || !formData.shopId) {
      onChange("addAutoOrderPoint", 0);
      return;
    }
    const shop = shops.find((shop) => shop.shopId === formData.shopId);
    const guestCategory = guestCategories.find(
      (gc) => gc.guestCategoryId === formData?.guestCategoryId
    );
    if (!shop || !guestCategory?.pointGrantFlag) {
      onChange("addAutoOrderPoint", 0);
      return;
    }
    onChange(
      "addAutoOrderPoint",
      Math.round(formData.totalFee * (shop.pointRate / 100))
    );
  }, [
    formData.totalFee,
    formData?.shopId,
    formData?.guestCategoryId,
    shops,
    guestCategories,
  ]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
        料金詳細
      </Typography>
      <Box display="flex">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
            <TextField
              size="small"
              label="コース代金"
              type="number"
              variant="outlined"
              value={formData?.courseFee || ""}
              disabled
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: 10 }}
            >
              <TextField
                size="small"
                label="基本料金"
                type="number"
                variant="outlined"
                value={formData?.timeFee || ""}
                disabled
              />
              <TextField
                label="指名料"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.designateFee || ""}
                disabled
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
            <TextField
              label="延長"
              size="small"
              type="number"
              variant="outlined"
              value={formData?.additionalFee || ""}
              disabled
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: 10 }}
            >
              <TextField
                label="店舗"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.additionalShopFee || ""}
                disabled
              />
              <TextField
                label="キャスト"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.additionalCastFee || ""}
                disabled
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
            <TextField
              label="オプション"
              size="small"
              type="number"
              variant="outlined"
              value={formData?.optionFee || ""}
              disabled
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: 10 }}
            >
              <TextField
                label="店舗"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.optionShopFee || ""}
                disabled
              />
              <TextField
                label="キャスト"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.optionCastFee || ""}
                disabled
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
            <TextField
              label="コスプレ"
              size="small"
              type="number"
              variant="outlined"
              value={formData?.cosplayFee || ""}
              disabled
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: 10 }}
            >
              <TextField
                label="店舗"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.cosplayShopFee || ""}
                disabled
              />
              <TextField
                label="キャスト"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.cosplayCastFee || ""}
                disabled
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
            <TextField
              size="small"
              label="割引"
              type="number"
              variant="outlined"
              value={formData?.discountFee || ""}
              disabled
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: 10 }}
            >
              <TextField
                label="店舗"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.discountShopFee || ""}
                disabled
              />
              <TextField
                label="キャスト"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.discountCastFee || ""}
                disabled
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
            <TextField
              label="媒体"
              size="small"
              type="number"
              variant="outlined"
              value={formData?.mediumFee || ""}
              disabled
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: 10 }}
            >
              <TextField
                label="店舗"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.mediumShopFee || ""}
                disabled
              />
              <TextField
                label="キャスト"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.mediumCastFee || ""}
                disabled
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
            <TextField
              label="交通費"
              size="small"
              type="number"
              variant="outlined"
              value={formData?.areaFee || ""}
              disabled
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: 10 }}
            >
              <TextField
                label="店舗"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.areaShopFee || ""}
                disabled
              />
              <TextField
                label="キャスト"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.areaCastFee || ""}
                disabled
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
            <TextField
              label="ホテル"
              size="small"
              type="number"
              variant="outlined"
              value={formData?.hotelFee || ""}
              disabled
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: 10 }}
            >
              <TextField
                label="店舗"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.hotelShopFee || ""}
                disabled
              />
              <TextField
                label="キャスト"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.hotelCastFee || ""}
                disabled
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
            <TextField
              label="インボイス"
              size="small"
              type="number"
              variant="outlined"
              value={formData?.invoiceFee || ""}
              disabled
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: 10 }}
            >
              <TextField
                label="キャスト"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.invoiceFee || ""}
                disabled
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
            <TextField
              label="その他"
              size="small"
              type="number"
              variant="outlined"
              value={formData?.otherFee || ""}
              disabled
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: 10 }}
            >
              <TextField
                label="店舗"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.otherShopFee || ""}
                onChange={(event) => {
                  onChange("otherShopFee", Number(event.target.value));
                }}
                onBlur={(event) =>
                  onChange(
                    "otherFee",
                    Number(event.target.value) + (formData?.otherCastFee || 0)
                  )
                }
              />
              <TextField
                label="キャスト"
                size="small"
                type="number"
                variant="outlined"
                value={formData?.otherCastFee || ""}
                onChange={(event) => {
                  onChange("otherCastFee", Number(event.target.value));
                  onChange(
                    "otherFee",
                    Number(event.target.value) + (formData?.otherShopFee || 0)
                  );
                }}
                onBlur={(event) =>
                  onChange(
                    "otherFee",
                    Number(event.target.value) + (formData?.otherShopFee || 0)
                  )
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
        ポイント
      </Typography>
      <Box display="flex">
        <TextField
          label="自動付与ポイント"
          size="small"
          type="number"
          variant="outlined"
          value={formData?.addAutoOrderPoint || ""}
          style={{ marginBottom: 10 }}
          disabled
        />
        <TextField
          label="手動付与ポイント"
          type="number"
          size="small"
          variant="outlined"
          value={formData?.addManualOrderPoint || ""}
          style={{ marginBottom: 10, marginLeft: 10 }}
          onChange={(event) =>
            onChange("addManualOrderPoint", Number(event.target.value))
          }
        />
      </Box>
      <Box display="flex">
        <TextField
          label="ポイント備考"
          type="text"
          size="small"
          variant="outlined"
          value={formData?.pointRemark || ""}
          style={{ marginBottom: 10 }}
          onChange={(event) => onChange("pointRemark", event.target.value)}
        />
      </Box>
    </Box>
  );
};
export default SpOrderPrice;
