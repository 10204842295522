import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#e64a19",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
    },
  },

  typography: {
    htmlFontSize: 10,
    fontSize: 14,
    body1: {
      fontSize: "1.4rem",
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 479,
      md: 768,
      lg: 959,
      xl: 1112,
    },
  },
});

export default theme;
