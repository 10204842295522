import { DataValidationError } from "constants/DataValidationError";
import {
  IsAscii,
  IsEmail,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";

export default class CreateDriverReq {
  @MaxLength(Spec.maxLength.driver.email, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driver.email,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsEmail(
    {},
    {
      message: DataValidationError.DATA_IS_INVALID,
    }
  )
  email!: string;

  @MaxLength(Spec.maxLength.driver.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driver.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @MaxLength(Spec.maxLength.driver.password, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driver.password,
    },
  })
  @IsAscii({
    message: DataValidationError.DATA_IS_INVALID,
  })
  password!: string;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @MaxLength(Spec.maxLength.driver.address, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driver.address,
    },
  })
  address!: string;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @MaxLength(Spec.maxLength.driver.tel, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driver.tel,
    },
  })
  tel!: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.carNumber, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driver.carNumber,
    },
  })
  carNumber!: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.carType, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driver.carType,
    },
  })
  carType!: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.carColor, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driver.carColor,
    },
  })
  carColor!: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.driver.remarks, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driver.remarks,
    },
  })
  remarks!: string;

  @IsOptional()
  @IsNumber()
  sort!: number | null;

  constructor(arg: {
    email: string;
    name: string;
    password: string;
    address: string;
    tel: string;
    carType: string;
    carColor: string;
    carNumber: string;
    remarks: string;
    sort: number | null;
  }) {
    arg = arg || {};
    this.email = arg.email;
    this.name = arg.name;
    this.password = arg.password;
    this.address = arg.address;
    this.tel = arg.tel;
    this.carNumber = arg.carNumber;
    this.carType = arg.carType;
    this.carColor = arg.carColor;
    this.remarks = arg.remarks;
    this.sort = arg.sort;
  }
}
