import { DataValidationError } from "constants/DataValidationError";
import { IsArray, IsNotEmpty } from "class-validator";

export default class UpdateRecruitingMediaReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  recruitingMediaId!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  cost!: number;

  @IsArray()
  shops!: number[];

  constructor(arg: {
    recruitingMediaId: number;
    name: string;
    cost: number;
    shops: number[];
  }) {
    arg = arg || {};
    this.recruitingMediaId = arg.recruitingMediaId;
    this.name = arg.name;
    this.cost = arg.cost;
    this.shops = arg.shops;
  }
}
