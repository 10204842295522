import RequestUtils from "utils/RequestUtils";
import RequestGuestRes from "types/res/requestGuest/RequestGuestRes";

export default class SettingApi {
  static async findAll(companyId: number) {
    return await RequestUtils.getArray(
      RequestGuestRes,
      `/requestGuest/findAll/${companyId}`
    );
  }
  static async approve(companyId: number, id: number) {
    return await RequestUtils.postArray(
      RequestGuestRes,
      `/requestGuest/approve/${companyId}/${id}`,
      {}
    );
  }
  static async delete(companyId: number, id: number) {
    return await RequestUtils.postArray(
      RequestGuestRes,
      `/requestGuest/delete/${companyId}/${id}`,
      {}
    );
  }
}
