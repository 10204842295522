import RequestUtils from "utils/RequestUtils";
import OrderRes from "types/res/order/OrderRes";
import querystring from "querystring";

export default class PayoffApi {
  private constructor() {
    // nothing
  }

  static async findAll(
    companyId: number,
    startDate: string,
    endDate: string,
    shopId?: number,
    castNameId?: number
  ): Promise<OrderRes[]> {
    const query = { shopId, castNameId };
    return await RequestUtils.getArray(
      OrderRes,
      `/payoff/findAll/${companyId}/${startDate}/${endDate}?${querystring.stringify(
        query
      )}`
    );
  }

  static async spFindAllByCast(
    companyId: number,
    startDate: string,
    endDate: string,
    castIds: number[]
  ): Promise<OrderRes[]> {
    return await RequestUtils.postArray(
      OrderRes,
      `/payoff/spFindAllByCast/${companyId}`,
      {
        startDate,
        endDate,
        castIds,
      }
    );
  }

  static async collection(companyId: number, orderId: number) {
    return await RequestUtils.post(
      OrderRes,
      `/payoff/collection/${companyId}/${orderId}`,
      {}
    );
  }

  static async payoff(companyId: number, orderId: number) {
    return await RequestUtils.post(
      OrderRes,
      `/payoff/payoff/${companyId}/${orderId}`,
      {}
    );
  }

  static async recipePdf(
    companyId: number,
    castNameId: number,
    startDate: string,
    endDate: string
  ) {
    return await RequestUtils.getBlob(
      `/payoff/recipe/pdf/${companyId}/${castNameId}/${startDate}/${endDate}`,
      true
    );
  }
}
