import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import NominationRes from "types/res/nomination/NominationRes";
import UpdateNominationReq from "types/req/nomination/UpdateNominationReq";
import CreateNominationReq from "types/req/nomination/CreateNominationReq";
import DeleteNominationReq from "types/req/nomination/DeleteNominationReq";
import NominationApi from "../../api/NominationApi";

const actionCreator = actionCreatorFactory();
const addNominationAction = actionCreator.async<
  CreateNominationReq,
  NominationRes,
  { code: number; description: string }
>("ADD_NOMINATION");

export const addNomination =
  (companyId: number, req: CreateNominationReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addNominationAction.started({ ...req }));

    try {
      const nomination = new CreateNominationReq({ ...req });
      const result = await NominationApi.create(companyId, nomination);
      dispatch(
        addNominationAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addNominationAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchNominationAction = actionCreator.async<
  {},
  NominationRes[],
  { code: number; description: string }
>("FETCH_NOMINATION");

export const fetchNomination =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchNominationAction.started([]));

    try {
      const result = await NominationApi.findAll(companyId);
      dispatch(
        fetchNominationAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchNominationAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateNominationAction = actionCreator.async<
  UpdateNominationReq,
  NominationRes,
  { code: number; description: string }
>("UPDATE_NOMINATION");

export const updateNomination =
  (companyId: number, req: UpdateNominationReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateNominationAction.started({ ...req }));

    try {
      const updateNomination = new UpdateNominationReq({ ...req });
      const result = await NominationApi.update(companyId, updateNomination);
      dispatch(
        updateNominationAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateNominationAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteNominationAction = actionCreator.async<
  DeleteNominationReq,
  {},
  { code: number; description: string }
>("DELETE_NOMINATION");

export const deleteNomination =
  (companyId: number, req: DeleteNominationReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteNominationAction.started({ ...req }));

    try {
      const nomination = new DeleteNominationReq({ ...req });
      const result = await NominationApi.delete(companyId, nomination);
      dispatch(
        deleteNominationAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteNominationAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const nominationActions = {
  fetchNominationAction,
  addNominationAction,
  updateNominationAction,
  deleteNominationAction,
};

export default nominationActions;
