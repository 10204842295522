import Modal from "components/Modal";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useModal from "hooks/useModal";
import OrderRes from "../../../types/res/order/OrderRes";
import { Link } from "react-router-dom";
type Props = {
  setStateOrders: any;
  orders: OrderRes[];
};
const CastScheduleOnItemMoveChangeClass: React.FC<Props> = ({
  setStateOrders,
  orders,
}) => {
  const [
    castScheduleOnItemMoveChangeClassModal,
    setCastScheduleOnItemMoveChangeClassShow,
  ] = useModal("castScheduleOnItemMoveChangeClass");
  const [orderId, setOrderId] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!castScheduleOnItemMoveChangeClassModal.meta?.order) return;
    setOrderId(castScheduleOnItemMoveChangeClassModal.meta.order.orderId);
  }, [castScheduleOnItemMoveChangeClassModal.meta?.order]);
  useEffect(() => {
    if (castScheduleOnItemMoveChangeClassModal.show) return;
    setStateOrders(orders);
  }, [castScheduleOnItemMoveChangeClassModal?.show]);
  return (
    <Modal
      show={castScheduleOnItemMoveChangeClassModal.show}
      setShow={setCastScheduleOnItemMoveChangeClassShow}
      title={`${castScheduleOnItemMoveChangeClassModal.meta?.castName?.name}さんへの予約変更`}
    >
      <Container>
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column">
            <Typography style={{ margin: "10px 0" }}>
              同コースが存在しないため編集画面に移動しますか？
            </Typography>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="default"
                disabled={loading}
                onClick={() => setCastScheduleOnItemMoveChangeClassShow(false)}
                style={{ marginRight: "10px" }}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "いいえ"
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                component={Link}
                to={`/order/${orderId}`}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "はい"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Modal>
  );
};
export default CastScheduleOnItemMoveChangeClass;
