import React from "react";
import { Box, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { updateStaff } from "redux/actions/staff";

const SiteSwitch = () => {
  const dispatch = useDispatch();
  const staff = useSelector((state) => state.account.staff);
  const accessToken = useSelector((state) => state.account.accessToken);
  const onClickDisplayPcSite = () => {
    dispatch(
      updateStaff(staff.companyId, { ...staff, isForceSite: "pc" }, accessToken)
    );
  };
  return (
    <Box display={"flex"} justifyContent="center" margin={3}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => onClickDisplayPcSite()}
      >
        PC表示切り替え
      </Button>
    </Box>
  );
};

export default SiteSwitch;
