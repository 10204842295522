import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import GuestGenreType from "types/enum/GuestGenreType";
import GuestAnalyticsGroupType from "types/enum/GuestAnalyticsGroupType";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { fetchShops } from "redux/actions/shop";
import { fetchArea } from "redux/actions/area";
import { simpleFetchGuestCategory } from "redux/actions/guestCategory";
import { fetchNotelClass } from "redux/actions/notelClass";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import GuestAnalyticsSummarySalesApi from "api/GuestAnalyticsSummarySalesApi";
import MathUtils from "utils/MathUtils";

const GuestAnalyticsSummarySales = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const shops = useSelector((state) => state.shop);
  const notelClasses = useSelector((state) => state.notelClass);
  const guestCategories = useSelector((state) => state.guestCategory);
  const areas = useSelector((state) => state.area);

  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectNotelClasses, setSelectNotelClasses] = useState<string[]>([]);
  const [selectGuestCategories, setSelectGuestCategories] = useState<string[]>(
    []
  );
  const [selectArea, setSelectArea] = useState<string[]>([]);
  const [selectGenre, setSelectGenre] = useState<string[]>([
    ...Object.keys(GuestGenreType),
    "すべて",
  ]);
  const [selectGroup, setSelectGroup] = useState<string[]>([
    ...Object.keys(GuestAnalyticsGroupType),
    "すべて",
  ]);
  const [compareSelectShops, setCompareSelectShops] = useState<string[]>([]);
  const [compareSelectNotelClasses, setCompareSelectNotelClasses] = useState<
    string[]
  >([]);
  const [compareSelectGuestCategories, setCompareSelectGuestCategories] =
    useState<string[]>([]);
  const [compareSelectArea, setCompareSelectArea] = useState<string[]>([]);
  const [compareSelectGenre, setCompareSelectGenre] = useState<string[]>([
    ...Object.keys(GuestGenreType),
    "すべて",
  ]);
  const [compareSelectGroup, setCompareSelectGroup] = useState<string[]>([
    ...Object.keys(GuestAnalyticsGroupType),
    "すべて",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [monthlyOrderData, setMonthlyOrderData] = useState<
    {
      yearMonth: string;
      sales: number;
      orderCount: number;
      averagePrice: number;
      averageTime: number;
      compareSales?: number;
      compareOrderCount?: number;
      compareAveragePrice?: number;
      compareAverageTime?: number;
    }[]
  >([]);
  const [weeklyOrderData, setWeeklyOrderData] = useState<
    {
      yearMonth: string;
      sales: number;
      orderCount: number;
      averagePrice: number;
      averageTime: number;
      compareSales?: number;
      compareOrderCount?: number;
      compareAveragePrice?: number;
      compareAverageTime?: number;
    }[]
  >([]);
  const [monthlyCastData, setMonthlyCastData] = useState<
    {
      yearMonth: string;
      castCount: number;
      castShiftCount: number;
      orderCountPerCast: number;
      compareCastCount?: number;
      compareCastShiftCount?: number;
      compareOrderCountPerCast?: number;
    }[]
  >([]);
  const [weeklyCastData, setWeeklyCastData] = useState<
    {
      yearMonth: string;
      castCount: number;
      castShiftCount: number;
      orderCountPerCast: number;
      compareCastCount?: number;
      compareCastShiftCount?: number;
      compareOrderCountPerCast?: number;
    }[]
  >([]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchArea(companyId));
    dispatch(simpleFetchGuestCategory(companyId));
    dispatch(fetchNotelClass(companyId));
  }, []);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
    setCompareSelectShops([
      ...shops.map((shop) => String(shop.shopId)),
      "すべて",
    ]);
  }, [shops]);

  useEffect(() => {
    setSelectNotelClasses([
      ...notelClasses.map((notelClass) => String(notelClass.notelClassId)),
      "すべて",
    ]);
    setCompareSelectNotelClasses([
      ...notelClasses.map((notelClass) => String(notelClass.notelClassId)),
      "すべて",
    ]);
  }, [notelClasses]);

  useEffect(() => {
    setSelectGuestCategories([
      ...guestCategories.map((guestCategory) =>
        String(guestCategory.guestCategoryId)
      ),
      "すべて",
    ]);
    setCompareSelectGuestCategories([
      ...guestCategories.map((guestCategory) =>
        String(guestCategory.guestCategoryId)
      ),
      "すべて",
    ]);
  }, [guestCategories]);

  useEffect(() => {
    setSelectArea([...areas.map((area) => String(area.areaId)), "すべて"]);
    setCompareSelectArea([
      ...areas.map((area) => String(area.areaId)),
      "すべて",
    ]);
  }, [areas]);

  const onClickSearch = async () => {
    setIsLoading(true);
    setMonthlyOrderData([]);
    setMonthlyCastData([]);
    setWeeklyOrderData([]);
    setWeeklyCastData([]);
    const shopIds = selectShops
      .filter((id) => id !== "すべて")
      .map((id) => Number(id));
    const notelClassIds = selectNotelClasses
      .filter((id) => id !== "すべて")
      .map((id) => Number(id));
    const stringEndDate = DateTime.fromJSDate(endDate).toFormat(
      FORMAT_TYPE.YEAR_DAY
    );
    const stringEndDateMonth = DateTime.fromJSDate(endDate).toFormat(
      FORMAT_TYPE.YEAR_MONTH
    );
    // APIリクエスト
    const monthlyOrderData: {
      [key: string]: any;
    } = await GuestAnalyticsSummarySalesApi.findMonthlyOrderData(companyId, {
      shopIds: selectShops,
      endDate: stringEndDateMonth,
      guestCategoryIds: selectGuestCategories,
      notelClassIds: selectNotelClasses,
      areaIds: selectArea,
      guestAnalyticsType: selectGroup,
      guestGenreType: selectGenre,
    });

    const weeklyOrderData: {
      [key: string]: any;
    } = await GuestAnalyticsSummarySalesApi.findWeeklyOrderData(companyId, {
      shopIds: selectShops,
      endDate: stringEndDate,
      guestCategoryIds: selectGuestCategories,
      notelClassIds: selectNotelClasses,
      areaIds: selectArea,
      guestAnalyticsType: selectGroup,
      guestGenreType: selectGenre,
    });

    const monthlyCastData: {
      [key: string]: any;
    } = await GuestAnalyticsSummarySalesApi.findMonthlyCastData(companyId, {
      shopIds,
      endDate: stringEndDateMonth,
      notelClassIds,
    });

    const weeklyCastData: {
      [key: string]: any;
    } = await GuestAnalyticsSummarySalesApi.findWeeklyCastData(companyId, {
      shopIds,
      endDate: stringEndDate,
      notelClassIds,
    });

    Object.entries(monthlyOrderData).forEach(([yearMonth, order]) => {
      setMonthlyOrderData((prev) => [
        ...prev,
        {
          yearMonth,
          sales: order.sales,
          orderCount: order.orderCount,
          averagePrice: order.averagePrice,
          averageTime: order.averageTime,
        },
      ]);
    });
    Object.entries(weeklyOrderData).forEach(([yearMonth, order]) => {
      setWeeklyOrderData((prev) => [
        ...prev,
        {
          yearMonth,
          sales: order.sales,
          orderCount: order.orderCount,
          averagePrice: order.averagePrice,
          averageTime: order.averageTime,
        },
      ]);
    });

    Object.entries(monthlyCastData).forEach(([yearMonth, cast]) => {
      setMonthlyCastData((prev) => [
        ...prev,
        {
          yearMonth,
          castCount: cast.castCount,
          castShiftCount: cast.castShiftCount,
          orderCountPerCast: cast.castShiftCount
            ? MathUtils.roundToTwo(
                monthlyOrderData[yearMonth].orderCount / cast.castShiftCount
              )
            : 0,
        },
      ]);
    });
    Object.entries(weeklyCastData).forEach(([yearMonth, cast]) => {
      setWeeklyCastData((prev) => [
        ...prev,
        {
          yearMonth,
          castCount: cast.castCount,
          castShiftCount: cast.castShiftCount,
          orderCountPerCast: cast.castShiftCount
            ? MathUtils.roundToTwo(
                weeklyOrderData[yearMonth].orderCount / cast.castShiftCount
              )
            : 0,
        },
      ]);
    });
    if (isCompare) {
      const compareMonthlyOrderData: {
        [key: string]: any;
      } = await GuestAnalyticsSummarySalesApi.findMonthlyOrderData(companyId, {
        shopIds: compareSelectShops,
        endDate: stringEndDateMonth,
        guestCategoryIds: compareSelectGuestCategories,
        notelClassIds: compareSelectNotelClasses,
        areaIds: compareSelectArea,
        guestGenreType: compareSelectGenre,
        guestAnalyticsType: compareSelectGroup,
      });

      const compareWeeklyOrderData: {
        [key: string]: any;
      } = await GuestAnalyticsSummarySalesApi.findWeeklyOrderData(companyId, {
        shopIds: selectShops,
        endDate: stringEndDate,
        guestCategoryIds: compareSelectGuestCategories,
        notelClassIds: compareSelectNotelClasses,
        areaIds: compareSelectArea,
        guestGenreType: compareSelectGenre,
        guestAnalyticsType: compareSelectGroup,
      });
      const compareShopIds = compareSelectShops
        .filter((id) => id !== "すべて")
        .map((id) => Number(id));
      const compareNotelClassIds = compareSelectNotelClasses
        .filter((id) => id !== "すべて")
        .map((id) => Number(id));
      const compareMonthlyCastData: {
        [key: string]: any;
      } = await GuestAnalyticsSummarySalesApi.findMonthlyCastData(companyId, {
        shopIds: compareShopIds,
        endDate: stringEndDateMonth,
        notelClassIds: compareNotelClassIds,
      });

      const compareWeeklyCastData: {
        [key: string]: any;
      } = await GuestAnalyticsSummarySalesApi.findWeeklyCastData(companyId, {
        shopIds: compareShopIds,
        endDate: stringEndDate,
        notelClassIds: compareNotelClassIds,
      });
      setMonthlyOrderData([]);
      setMonthlyCastData([]);
      setWeeklyOrderData([]);
      setWeeklyCastData([]);
      Object.entries(compareMonthlyOrderData).forEach(([yearMonth, order]) => {
        setMonthlyOrderData((prev) => [
          ...prev,
          {
            yearMonth,
            sales: monthlyOrderData[yearMonth].sales,
            orderCount: monthlyOrderData[yearMonth].orderCount,
            averagePrice: monthlyOrderData[yearMonth].averagePrice,
            averageTime: monthlyOrderData[yearMonth].averageTime,
            compareSales: order.sales,
            compareOrderCount: order.orderCount,
            compareAveragePrice: order.averagePrice,
            compareAverageTime: order.averageTime,
          },
        ]);
      });
      Object.entries(compareWeeklyOrderData).forEach(([yearMonth, order]) => {
        setWeeklyOrderData((prev) => [
          ...prev,
          {
            yearMonth,
            sales: weeklyOrderData[yearMonth].sales,
            orderCount: weeklyOrderData[yearMonth].orderCount,
            averagePrice: weeklyOrderData[yearMonth].averagePrice,
            averageTime: weeklyOrderData[yearMonth].averageTime,
            compareSales: order.sales,
            compareOrderCount: order.orderCount,
            compareAveragePrice: order.averagePrice,
            compareAverageTime: order.averageTime,
          },
        ]);
      });
      Object.entries(compareMonthlyCastData).forEach(([yearMonth, cast]) => {
        setMonthlyCastData((prev) => [
          ...prev,
          {
            yearMonth,
            castCount: monthlyCastData[yearMonth].castCount,
            castShiftCount: monthlyCastData[yearMonth].castShiftCount,
            orderCountPerCast: monthlyCastData[yearMonth].castShiftCount
              ? MathUtils.roundToTwo(
                  monthlyOrderData[yearMonth].orderCount /
                    monthlyCastData[yearMonth].castShiftCount
                )
              : 0,
            compareCastCount: cast.castCount,
            compareCastShiftCount: cast.castShiftCount,
            compareOrderCountPerCast: cast.castShiftCount
              ? MathUtils.roundToTwo(
                  compareMonthlyOrderData[yearMonth].orderCount /
                    cast.castShiftCount
                )
              : 0,
          },
        ]);
      });
      Object.entries(compareWeeklyCastData).forEach(([yearMonth, cast]) => {
        setWeeklyCastData((prev) => [
          ...prev,
          {
            yearMonth,
            castCount: weeklyCastData[yearMonth].castCount,
            castShiftCount: weeklyCastData[yearMonth].castShiftCount,
            orderCountPerCast: weeklyCastData[yearMonth].castShiftCount
              ? MathUtils.roundToTwo(
                  weeklyOrderData[yearMonth].orderCount /
                    weeklyCastData[yearMonth].castShiftCount
                )
              : 0,
            compareCastCount: cast.castCount,
            compareCastShiftCount: cast.castShiftCount,
            compareOrderCountPerCast: cast.castShiftCount
              ? MathUtils.roundToTwo(
                  compareWeeklyOrderData[yearMonth].orderCount /
                    cast.castShiftCount
                )
              : 0,
          },
        ]);
      });
    }
    setIsLoading(false);
  };
  return (
    <Box display="flex" flexDirection={"column"}>
      <Box margin={2} display="flex" alignItems="center">
        <TextField
          type="date"
          label="終了日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) =>
            setEndDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            )
          }
        />
        <Box>
          <InputLabel id="select-multiple-shop">店舗</InputLabel>
          <Select
            multiple
            value={selectShops}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectShops((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length === shops.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= shops.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-shop" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return shops
                  .filter((shop) =>
                    (selected as string[]).includes(String(shop.shopId))
                  )
                  .map((shop) => shop.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectShops.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {shops.map((shop) => (
              <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                <Checkbox
                  checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                />
                <ListItemText primary={shop.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-notel-class">クラス</InputLabel>
          <Select
            multiple
            value={selectNotelClasses}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectNotelClasses((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...notelClasses.map((notelClass) =>
                      String(notelClass.notelClassId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length ===
                    notelClasses.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...notelClasses.map((notelClass) =>
                      String(notelClass.notelClassId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= notelClasses.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-notelClass" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return notelClasses
                  .filter((notelClass) =>
                    (selected as string[]).includes(
                      String(notelClass.notelClassId)
                    )
                  )
                  .map((notelClass) => notelClass.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectNotelClasses.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {notelClasses.map((notelClass) => (
              <MenuItem
                key={notelClass.notelClassId}
                value={String(notelClass.notelClassId)}
              >
                <Checkbox
                  checked={
                    selectNotelClasses.indexOf(
                      String(notelClass.notelClassId)
                    ) !== -1
                  }
                />
                <ListItemText primary={notelClass.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-shop">顧客カテゴリ</InputLabel>
          <Select
            multiple
            value={selectGuestCategories}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGuestCategories((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...guestCategories.map((guestCategory) =>
                      String(guestCategory.guestCategoryId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length ===
                    guestCategories.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...guestCategories.map((guestCategory) =>
                      String(guestCategory.guestCategoryId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <=
                  guestCategories.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-category" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return guestCategories
                  .filter((guestCategory) =>
                    (selected as string[]).includes(
                      String(guestCategory.guestCategoryId)
                    )
                  )
                  .map((guestCategory) => guestCategory.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGuestCategories.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {guestCategories.map((guestCategory) => (
              <MenuItem
                key={guestCategory.guestCategoryId}
                value={String(guestCategory.guestCategoryId)}
              >
                <Checkbox
                  checked={
                    selectGuestCategories.indexOf(
                      String(guestCategory.guestCategoryId)
                    ) !== -1
                  }
                />
                <ListItemText primary={guestCategory.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-guest-genre">現役・離脱</InputLabel>
          <Select
            multiple
            value={selectGenre}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGenre((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [...Object.keys(GuestGenreType), "すべて"];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(GuestGenreType).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [...Object.keys(GuestGenreType), "すべて"];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(GuestGenreType).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-genre" />}
            style={{ margin: "10px", width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.keys(GuestGenreType)
                  .filter((name) => (selected as string[]).includes(name))
                  .map((key) => (GuestGenreType as any)[key])
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGenre.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(GuestGenreType).map(([key, name]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox checked={selectGenre.indexOf(String(key)) !== -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-guest-genre">顧客グループ</InputLabel>
          <Select
            multiple
            value={selectGroup}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGroup((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [...Object.keys(GuestAnalyticsGroupType), "すべて"];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(GuestAnalyticsGroupType).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [...Object.keys(GuestAnalyticsGroupType), "すべて"];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(GuestAnalyticsGroupType).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-genre" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.keys(GuestAnalyticsGroupType)
                  .filter((name) => (selected as string[]).includes(name))
                  .map((key) => (GuestAnalyticsGroupType as any)[key])
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGroup.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(GuestAnalyticsGroupType).map(([key, name]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox checked={selectGroup.indexOf(String(key)) !== -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-area">エリア</InputLabel>
          <Select
            multiple
            value={selectArea}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectArea((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...areas.map((area) => String(area.areaId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length === areas.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...areas.map((area) => String(area.areaId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= areas.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-area" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return areas
                  .filter((area) =>
                    (selected as string[]).includes(String(area.areaId))
                  )
                  .map((area) => area.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectArea.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {areas.map((area) => (
              <MenuItem key={area.areaId} value={String(area.areaId)}>
                <Checkbox
                  checked={selectArea.indexOf(String(area.areaId)) !== -1}
                />
                <ListItemText primary={area.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={onClickSearch}
        >
          検索
        </Button>
      </Box>
      <Box marginX={2} display="flex" alignItems="center">
        <FormControlLabel
          style={{ width: "200px", margin: "0 10px" }}
          control={
            <Checkbox
              checked={isCompare}
              onChange={(event) => setIsCompare(event.target.checked)}
            />
          }
          label={"比較"}
        />
        {isCompare && (
          <>
            <Box>
              <InputLabel id="select-multiple-compare-shop">店舗</InputLabel>
              <Select
                multiple
                value={compareSelectShops}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setCompareSelectShops((prev: string[]) => {
                    if (
                      prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [];
                    } else if (
                      !prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") !== -1
                    ) {
                      return [
                        ...shops.map((shop) => String(shop.shopId)),
                        "すべて",
                      ];
                    } else if (
                      (event.target.value as string[]).length ===
                        shops.length &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [
                        ...shops.map((shop) => String(shop.shopId)),
                        "すべて",
                      ];
                    } else if (
                      (event.target.value as string[]).length <= shops.length
                    ) {
                      return (event.target.value as string[]).filter(
                        (name) => name !== "すべて"
                      );
                    } else {
                      return event.target.value as string[];
                    }
                  });
                }}
                input={<Input id="select-multiple-shop" />}
                style={{ width: "200px" }}
                renderValue={(selected) => {
                  if ((selected as string[]).includes("すべて")) {
                    return "すべて";
                  } else {
                    return shops
                      .filter((shop) =>
                        (selected as string[]).includes(String(shop.shopId))
                      )
                      .map((shop) => shop.name)
                      .join(", ");
                  }
                }}
              >
                <MenuItem key={"すべて"} value={"すべて"}>
                  <Checkbox
                    name="all"
                    checked={compareSelectShops.indexOf("すべて") > -1}
                  />
                  <ListItemText primary={"すべて"} />
                </MenuItem>
                {shops.map((shop) => (
                  <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                    <Checkbox
                      checked={
                        compareSelectShops.indexOf(String(shop.shopId)) !== -1
                      }
                    />
                    <ListItemText primary={shop.name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <InputLabel id="select-multiple-compare-notel-class">
                クラス
              </InputLabel>
              <Select
                multiple
                value={compareSelectNotelClasses}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setCompareSelectNotelClasses((prev: string[]) => {
                    if (
                      prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [];
                    } else if (
                      !prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") !== -1
                    ) {
                      return [
                        ...notelClasses.map((notelClass) =>
                          String(notelClass.notelClassId)
                        ),
                        "すべて",
                      ];
                    } else if (
                      (event.target.value as string[]).length ===
                        notelClasses.length &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [
                        ...notelClasses.map((notelClass) =>
                          String(notelClass.notelClassId)
                        ),
                        "すべて",
                      ];
                    } else if (
                      (event.target.value as string[]).length <=
                      notelClasses.length
                    ) {
                      return (event.target.value as string[]).filter(
                        (name) => name !== "すべて"
                      );
                    } else {
                      return event.target.value as string[];
                    }
                  });
                }}
                input={<Input id="select-multiple-notelClass" />}
                style={{ width: "200px", marginRight: "10px" }}
                renderValue={(selected) => {
                  if ((selected as string[]).includes("すべて")) {
                    return "すべて";
                  } else {
                    return notelClasses
                      .filter((notelClass) =>
                        (selected as string[]).includes(
                          String(notelClass.notelClassId)
                        )
                      )
                      .map((notelClass) => notelClass.name)
                      .join(", ");
                  }
                }}
              >
                <MenuItem key={"すべて"} value={"すべて"}>
                  <Checkbox
                    name="all"
                    checked={compareSelectNotelClasses.indexOf("すべて") > -1}
                  />
                  <ListItemText primary={"すべて"} />
                </MenuItem>
                {notelClasses.map((notelClass) => (
                  <MenuItem
                    key={notelClass.notelClassId}
                    value={String(notelClass.notelClassId)}
                  >
                    <Checkbox
                      checked={
                        compareSelectNotelClasses.indexOf(
                          String(notelClass.notelClassId)
                        ) !== -1
                      }
                    />
                    <ListItemText primary={notelClass.name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <InputLabel id="select-multiple-compare-guest-category">
                顧客カテゴリ
              </InputLabel>
              <Select
                multiple
                value={compareSelectGuestCategories}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setCompareSelectGuestCategories((prev: string[]) => {
                    if (
                      prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [];
                    } else if (
                      !prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") !== -1
                    ) {
                      return [
                        ...guestCategories.map((guestCategory) =>
                          String(guestCategory.guestCategoryId)
                        ),
                        "すべて",
                      ];
                    } else if (
                      (event.target.value as string[]).length ===
                        guestCategories.length &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [
                        ...guestCategories.map((guestCategory) =>
                          String(guestCategory.guestCategoryId)
                        ),
                        "すべて",
                      ];
                    } else if (
                      (event.target.value as string[]).length <=
                      guestCategories.length
                    ) {
                      return (event.target.value as string[]).filter(
                        (name) => name !== "すべて"
                      );
                    } else {
                      return event.target.value as string[];
                    }
                  });
                }}
                input={<Input id="select-multiple-guest-category" />}
                style={{ width: "200px", marginRight: "10px" }}
                renderValue={(selected) => {
                  if ((selected as string[]).includes("すべて")) {
                    return "すべて";
                  } else {
                    return guestCategories
                      .filter((guestCategory) =>
                        (selected as string[]).includes(
                          String(guestCategory.guestCategoryId)
                        )
                      )
                      .map((guestCategory) => guestCategory.name)
                      .join(", ");
                  }
                }}
              >
                <MenuItem key={"すべて"} value={"すべて"}>
                  <Checkbox
                    name="all"
                    checked={
                      compareSelectGuestCategories.indexOf("すべて") > -1
                    }
                  />
                  <ListItemText primary={"すべて"} />
                </MenuItem>
                {guestCategories.map((guestCategory) => (
                  <MenuItem
                    key={guestCategory.guestCategoryId}
                    value={String(guestCategory.guestCategoryId)}
                  >
                    <Checkbox
                      checked={
                        compareSelectGuestCategories.indexOf(
                          String(guestCategory.guestCategoryId)
                        ) !== -1
                      }
                    />
                    <ListItemText primary={guestCategory.name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <InputLabel id="select-multiple-compare-guest-genre">
                現役・離脱
              </InputLabel>
              <Select
                multiple
                value={compareSelectGenre}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setCompareSelectGenre((prev: string[]) => {
                    if (
                      prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [];
                    } else if (
                      !prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") !== -1
                    ) {
                      return [...Object.keys(GuestGenreType), "すべて"];
                    } else if (
                      (event.target.value as string[]).length ===
                        Object.keys(GuestGenreType).length &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [...Object.keys(GuestGenreType), "すべて"];
                    } else if (
                      (event.target.value as string[]).length <=
                      Object.keys(GuestGenreType).length
                    ) {
                      return (event.target.value as string[]).filter(
                        (name) => name !== "すべて"
                      );
                    } else {
                      return event.target.value as string[];
                    }
                  });
                }}
                input={<Input id="select-multiple-guest-genre" />}
                style={{ margin: "10px", width: "200px" }}
                renderValue={(selected) => {
                  if ((selected as string[]).includes("すべて")) {
                    return "すべて";
                  } else {
                    return Object.keys(GuestGenreType)
                      .filter((name) => (selected as string[]).includes(name))
                      .map((key) => (GuestGenreType as any)[key])
                      .join(", ");
                  }
                }}
              >
                <MenuItem key={"すべて"} value={"すべて"}>
                  <Checkbox
                    name="all"
                    checked={compareSelectGenre.indexOf("すべて") > -1}
                  />
                  <ListItemText primary={"すべて"} />
                </MenuItem>
                {Object.entries(GuestGenreType).map(([key, name]) => (
                  <MenuItem key={key} value={String(key)}>
                    <Checkbox
                      checked={compareSelectGenre.indexOf(String(key)) !== -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <InputLabel id="select-multiple-compare-guest-genre">
                顧客グループ
              </InputLabel>
              <Select
                multiple
                value={compareSelectGroup}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setCompareSelectGroup((prev: string[]) => {
                    if (
                      prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [];
                    } else if (
                      !prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") !== -1
                    ) {
                      return [
                        ...Object.keys(GuestAnalyticsGroupType),
                        "すべて",
                      ];
                    } else if (
                      (event.target.value as string[]).length ===
                        Object.keys(GuestAnalyticsGroupType).length &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [
                        ...Object.keys(GuestAnalyticsGroupType),
                        "すべて",
                      ];
                    } else if (
                      (event.target.value as string[]).length <=
                      Object.keys(GuestAnalyticsGroupType).length
                    ) {
                      return (event.target.value as string[]).filter(
                        (name) => name !== "すべて"
                      );
                    } else {
                      return event.target.value as string[];
                    }
                  });
                }}
                input={<Input id="select-multiple-guest-genre" />}
                style={{ width: "200px" }}
                renderValue={(selected) => {
                  if ((selected as string[]).includes("すべて")) {
                    return "すべて";
                  } else {
                    return Object.keys(GuestAnalyticsGroupType)
                      .filter((name) => (selected as string[]).includes(name))
                      .map((key) => (GuestAnalyticsGroupType as any)[key])
                      .join(", ");
                  }
                }}
              >
                <MenuItem key={"すべて"} value={"すべて"}>
                  <Checkbox
                    name="all"
                    checked={compareSelectGroup.indexOf("すべて") > -1}
                  />
                  <ListItemText primary={"すべて"} />
                </MenuItem>
                {Object.entries(GuestAnalyticsGroupType).map(([key, name]) => (
                  <MenuItem key={key} value={String(key)}>
                    <Checkbox
                      checked={compareSelectGroup.indexOf(String(key)) !== -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <InputLabel id="select-multiple-compare-area">エリア</InputLabel>
              <Select
                multiple
                value={compareSelectArea}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setCompareSelectArea((prev: string[]) => {
                    if (
                      prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [];
                    } else if (
                      !prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") !== -1
                    ) {
                      return [
                        ...areas.map((area) => String(area.areaId)),
                        "すべて",
                      ];
                    } else if (
                      (event.target.value as string[]).length ===
                        areas.length &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [
                        ...areas.map((area) => String(area.areaId)),
                        "すべて",
                      ];
                    } else if (
                      (event.target.value as string[]).length <= areas.length
                    ) {
                      return (event.target.value as string[]).filter(
                        (name) => name !== "すべて"
                      );
                    } else {
                      return event.target.value as string[];
                    }
                  });
                }}
                input={<Input id="select-multiple-area" />}
                style={{ width: "200px", marginRight: "10px" }}
                renderValue={(selected) => {
                  if ((selected as string[]).includes("すべて")) {
                    return "すべて";
                  } else {
                    return areas
                      .filter((area) =>
                        (selected as string[]).includes(String(area.areaId))
                      )
                      .map((area) => area.name)
                      .join(", ");
                  }
                }}
              >
                <MenuItem key={"すべて"} value={"すべて"}>
                  <Checkbox
                    name="all"
                    checked={compareSelectArea.indexOf("すべて") > -1}
                  />
                  <ListItemText primary={"すべて"} />
                </MenuItem>
                {areas.map((area) => (
                  <MenuItem key={area.areaId} value={String(area.areaId)}>
                    <Checkbox
                      checked={
                        compareSelectArea.indexOf(String(area.areaId)) !== -1
                      }
                    />
                    <ListItemText primary={area.name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex">
          <Typography variant={"subtitle1"} style={{ marginLeft: "25%" }}>
            月別
          </Typography>
          <Typography variant={"subtitle1"} style={{ marginLeft: "50%" }}>
            週別
          </Typography>
        </Box>
        {isLoading ? (
          <CircularProgress />
        ) : monthlyOrderData.length ? (
          <>
            <GraphGroup
              name={"売上"}
              monthlyData={monthlyOrderData}
              weeklyData={weeklyOrderData}
              monthlyXAxis={"yearMonth"}
              weeklyXAxis={"yearMonth"}
              dataKey={"sales"}
              formatter={(value: any) => `¥${value}`}
              compareDataKey={isCompare ? "compareSales" : undefined}
            />
            <GraphGroup
              name={"利用本数"}
              monthlyData={monthlyOrderData}
              weeklyData={weeklyOrderData}
              monthlyXAxis={"yearMonth"}
              weeklyXAxis={"yearMonth"}
              dataKey={"orderCount"}
              formatter={(value: any) => `${value}本`}
              compareDataKey={isCompare ? "compareOrderCount" : undefined}
            />
            <GraphGroup
              name={"平均利用単価"}
              monthlyData={monthlyOrderData}
              weeklyData={weeklyOrderData}
              monthlyXAxis={"yearMonth"}
              weeklyXAxis={"yearMonth"}
              dataKey={"averagePrice"}
              formatter={(value: any) => `¥${Math.round(value)}`}
              compareDataKey={isCompare ? "compareAveragePrice" : undefined}
            />
            <GraphGroup
              name={"平均利用時間"}
              monthlyData={monthlyOrderData}
              weeklyData={weeklyOrderData}
              monthlyXAxis={"yearMonth"}
              weeklyXAxis={"yearMonth"}
              dataKey={"averageTime"}
              formatter={(value: any) =>
                `${DateTime.fromSeconds(value * 60)
                  .minus({ hours: 9 })
                  .toFormat("HH'h'mm'm'")}`
              }
              compareDataKey={isCompare ? "compareAverageTime" : undefined}
            />
            <GraphGroup
              name={"出勤在籍人数"}
              monthlyData={monthlyCastData}
              weeklyData={weeklyCastData}
              monthlyXAxis={"yearMonth"}
              weeklyXAxis={"yearMonth"}
              dataKey={"castCount"}
              formatter={(value: any) => `${value}`}
              compareDataKey={isCompare ? "compareCastCount" : undefined}
            />
            <GraphGroup
              name={"出勤人数"}
              monthlyData={monthlyCastData}
              weeklyData={weeklyCastData}
              monthlyXAxis={"yearMonth"}
              weeklyXAxis={"yearMonth"}
              dataKey={"castShiftCount"}
              formatter={(value: any) => `${value}`}
              compareDataKey={isCompare ? "compareCastShiftCount" : undefined}
            />
            <GraphGroup
              name={"キャストあたりの平均本数"}
              monthlyData={monthlyCastData}
              weeklyData={weeklyCastData}
              monthlyXAxis={"yearMonth"}
              weeklyXAxis={"yearMonth"}
              dataKey={"orderCountPerCast"}
              formatter={(value: any) => `${value}本`}
              compareDataKey={
                isCompare ? "compareOrderCountPerCast" : undefined
              }
            />
          </>
        ) : (
          <Box display="flex" justifyContent="center">
            <Typography>条件を選択して検索してください。</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

type GraphGroupProps = {
  name: string;
  monthlyData: any[];
  weeklyData: any;
  monthlyXAxis: string;
  weeklyXAxis: string;
  dataKey: string;
  formatter: any;
  compareDataKey?: string;
};
const GraphGroup = ({
  name,
  monthlyData,
  weeklyData,
  monthlyXAxis,
  weeklyXAxis,
  dataKey,
  formatter,
  compareDataKey,
}: GraphGroupProps) => {
  return (
    <Box display="flex" margin={2}>
      <Box height={"300px"} width={"30px"} display="flex" alignItems={"center"}>
        <Typography variant={"subtitle1"}>{name}</Typography>
      </Box>
      <Box width={"50%"} height={"300px"} marginLeft={1} border={1} padding={1}>
        <Graph
          data={monthlyData}
          xAxis={monthlyXAxis}
          dataKey={dataKey}
          name={name}
          formatter={formatter}
          compareDataKey={compareDataKey}
        />
      </Box>
      <Box
        width={"50%"}
        height={"300px"}
        borderTop={1}
        borderBottom={1}
        borderRight={1}
        padding={1}
      >
        <Graph
          data={weeklyData}
          xAxis={weeklyXAxis}
          dataKey={dataKey}
          name={name}
          formatter={formatter}
          compareDataKey={compareDataKey}
        />
      </Box>
    </Box>
  );
};

type GraphProps = {
  data: any[];
  xAxis: string;
  dataKey: string;
  name: string;
  formatter: any;
  compareDataKey?: string;
};
const Graph = ({
  data,
  xAxis,
  dataKey,
  compareDataKey,
  name,
  formatter,
}: GraphProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxis} />
        <YAxis type="number" width={150} tickFormatter={formatter} />
        <Tooltip formatter={formatter} />
        <Bar dataKey={dataKey} fill="#8884d8" format={"¥"} name={name} />
        {compareDataKey && (
          <Bar dataKey={compareDataKey} fill="#FCD361" name={`比較${name}`} />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};
export default GuestAnalyticsSummarySales;
