import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDriver,
  bulkInsertDriver,
  deleteDriver,
  fetchDriver,
  updateDriver,
} from "redux/actions/driver";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import DriverRes from "types/res/driver/DriverRes";
import { FormType } from "components/FormField";
import CreateDriverReq from "types/req/driver/CreateDriverReq";
import UpdateDriverReq from "types/req/driver/UpdateDriverReq";
import CsvImport from "components/CsvImport";
import { Box, Button } from "@material-ui/core";
import { CsvFieldType } from "utils/CsvUtils";
import EnvironmentUtils from "utils/EnviromentUtils";

const headers: { key: keyof DriverRes; label: string }[] = [
  { key: "name", label: "名前" },
  { key: "email", label: "メールアドレス" },
  { key: "address", label: "住所" },
  { key: "tel", label: "電話番号" },
  { key: "carType", label: "車種" },
  { key: "carColor", label: "車色" },
  { key: "carNumber", label: "車ナンバー" },
  { key: "remarks", label: "備考" },
  { key: "sort", label: "並び順" },
];

const Driver: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const driver = useSelector((state) => state.driver);
  const [openCsvImport, setOpenCsvImport] = useState(false);

  useEffect(() => {
    dispatch(fetchDriver(companyId));
  }, [companyId, dispatch]);

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "メールアドレス",
      key: "email",
      type: FormType.Text,
    },
    {
      label: "初期パスワード",
      key: "password",
      type: FormType.Text,
    },
    {
      label: "住所",
      key: "address",
      type: FormType.TextArea,
    },
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
    {
      label: "車種",
      key: "carType",
      type: FormType.Text,
    },
    {
      label: "車色",
      key: "carColor",
      type: FormType.Text,
    },
    {
      label: "車ナンバー",
      key: "carNumber",
      type: FormType.Text,
    },
    {
      label: "備考",
      key: "remarks",
      type: FormType.TextArea,
    },
    {
      label: "並び順",
      key: "sort",
      type: FormType.Number,
    },
  ];

  const csvHeader = [
    {
      label: "名前",
      key: "name",
      type: CsvFieldType.Text,
    },
    {
      label: "メールアドレス",
      key: "email",
      type: CsvFieldType.Text,
    },
    {
      label: "初期パスワード",
      key: "password",
      type: CsvFieldType.Text,
    },
    {
      label: "住所",
      key: "address",
      type: CsvFieldType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: CsvFieldType.Text,
    },
    {
      label: "車種",
      key: "carType",
      type: CsvFieldType.Text,
    },
    {
      label: "車色",
      key: "carColor",
      type: CsvFieldType.Text,
    },
    {
      label: "車ナンバー",
      key: "carNumber",
      type: CsvFieldType.Text,
    },
    {
      label: "備考",
      key: "remarks",
      type: CsvFieldType.Text,
    },
    {
      label: "並び順",
      key: "sort",
      type: CsvFieldType.Number,
    },
  ];

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginTop={1}
        marginRight={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.open(EnvironmentUtils.getDriverDomain())}
          style={{ marginLeft: "30px" }}
        >
          ドライバーモバイルへ
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenCsvImport((prev) => !prev)}
          style={{ marginLeft: "30px" }}
        >
          csvインポート
        </Button>
      </Box>
      {openCsvImport && (
        <CsvImport
          templatePath={"/csv/driver.csv"}
          templateName={"driver_template.csv"}
          csvHeader={csvHeader}
          addType={CreateDriverReq}
          addFunc={(formData) => bulkInsertDriver(companyId, formData)}
        />
      )}
      <DriverTable
        title={"ドライバー"}
        formId="driverForm"
        forms={forms}
        rows={headers}
        data={driver}
        addFunc={(formData) => addDriver(companyId, formData)}
        updateFunc={(formData) => updateDriver(companyId, formData)}
        deleteFunc={(item) =>
          deleteDriver(companyId, {
            driverId: item.driverId,
          })
        }
        addType={CreateDriverReq}
        updateType={UpdateDriverReq}
        values={[
          (s) => s.name,
          (s) => s.email,
          (s) => s.address,
          (s) => s.tel,
          (s) => s.carType,
          (s) => s.carColor,
          (s) => s.carNumber,
          (s) => s.remarks,
          (s) => s.sort || "未設定",
        ]}
      />
    </>
  );
};

const DriverTable = styled<
  React.FC<CommonTableProps<DriverRes, CreateDriverReq, UpdateDriverReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default Driver;
