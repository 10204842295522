import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { fetchShops } from "redux/actions/shop";
import { fetchHotel } from "redux/actions/hotel";
import { fetchCourse } from "redux/actions/course";
import { fetchArea } from "redux/actions/area";
import { fetchDiscount } from "redux/actions/discount";
import { fetchAdditionalTime } from "redux/actions/additionalTime";
import { fetchOption } from "redux/actions/option";
import { fetchCosplay } from "redux/actions/cosplay";
import { fetchNomination } from "redux/actions/nomination";
import { fetchNotelClass } from "redux/actions/notelClass";
import { fetchDriver } from "redux/actions/driver";
import { fetchOneCastName } from "redux/actions/castName";
import { fetchMedium } from "redux/actions/medium";
import { fetchOneOrder } from "redux/actions/order";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { Box, CircularProgress } from "@material-ui/core";
import SpOrderHeader from "spPages/Order/components/SpOrderHeader";
import SpOrderGuestInfo from "spPages/Order/components/SpOrderGuestInfo";
import SpOrderInfo from "spPages/Order/components/SpOrderInfo";
import SpOrderDriverAndCast from "spPages/Order/components/SpOrderDriverAndCast";
import SpOrderPrice from "spPages/Order/components/SpOrderPrice";
import SpOrderConfirm from "spPages/Order/components/SpOrderConfirm";
import SpOrderFooter from "spPages/Order/components/SpOrderFooter";
import { fetchDriverShift } from "redux/actions/driverShift";
import { fetchCastShift } from "redux/actions/castShift";

const SpOrder = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const order = useSelector((state) => state.order);
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const { orderId } = useParams<{ orderId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [oldOrder, setOldOrder] = useState<any>({});
  const [pageNum, setPageNum] = useState(0);
  const [startDate, setStartDate] = useState(
    DateTimeUtils.toFormatAsLocalTimezone(
      DateTime.local()
        .minus({
          hours: changeDate.hour,
          minutes: changeDate.minute,
        })
        .toJSDate(),
      FORMAT_TYPE.YEAR_DAY
    )
  );
  const [endDate, setEndDate] = useState(
    DateTimeUtils.toFormatAsLocalTimezone(
      DateTime.local()
        .minus({
          hours: changeDate.hour,
          minutes: changeDate.minute,
        })
        .toJSDate(),
      FORMAT_TYPE.YEAR_DAY
    )
  );
  // 初期値セット
  useEffect(() => {
    const apiCall = async () => {
      setIsLoading(true);
      await dispatch(fetchShops(companyId));
      await dispatch(fetchHotel(companyId));
      await dispatch(fetchCourse(companyId));
      await dispatch(fetchArea(companyId));
      await dispatch(fetchDiscount(companyId));
      await dispatch(fetchAdditionalTime(companyId));
      await dispatch(fetchOption(companyId));
      await dispatch(fetchCosplay(companyId));
      await dispatch(fetchNomination(companyId));
      await dispatch(fetchNotelClass(companyId));
      await dispatch(fetchDriver(companyId));

      await dispatch(fetchMedium(companyId));
      setIsLoading(false);
    };
    setFormData((prev: any) => ({
      ...prev,
      orderDate: DateTime.local()
        .minus({
          hours: changeDate.hour,
          minutes: changeDate.minute,
        })
        .toJSDate(),
      isUseOutward: true,
      isUseReturn: true,
      additionalTimeOrders: [{ additionalTimeId: null, count: 0 }],
    }));
    apiCall();
  }, []);

  useEffect(() => {
    if (!formData?.castNameId) return;
    dispatch(fetchOneCastName(companyId, formData?.castNameId));
  }, [formData?.castNameId]);

  useEffect(() => {
    const apiCall = async () => {
      setIsLoading(true);
      await dispatch(fetchDriverShift(companyId, startDate, endDate));
      await dispatch(fetchCastShift(companyId, startDate, endDate));
      setIsLoading(false);
    };
    apiCall();
  }, [startDate, endDate]);

  // 編集時のデータ取得
  useEffect(() => {
    if (!orderId) return;
    dispatch(fetchOneOrder(companyId, Number(orderId)));
  }, [orderId]);

  // 編集時の時間セット
  useEffect(() => {
    if (order[0]?.orderId === Number(orderId)) {
      let departureHour = "";
      if (order[0].departureTime && order[0].shop) {
        if (
          DateTime.fromJSDate(order[0]?.departureTime).toJSDate().getHours() <
          Number(order[0].shop.openingTime.split(":")[0])
        ) {
          departureHour = String(
            DateTime.fromJSDate(order[0]?.departureTime).toJSDate().getHours() +
              24
          ).padStart(2, "0");
        } else {
          departureHour = String(
            DateTime.fromJSDate(order[0].departureTime).toJSDate().getHours()
          ).padStart(2, "0");
        }
      }

      let planInHour = "";
      if (order[0].planInTime && order[0].shop) {
        if (
          DateTime.fromJSDate(order[0]?.planInTime).toJSDate().getHours() <
          Number(order[0].shop.openingTime.split(":")[0])
        ) {
          planInHour = String(
            DateTime.fromJSDate(order[0]?.planInTime).toJSDate().getHours() + 24
          ).padStart(2, "0");
        } else {
          planInHour = String(
            DateTime.fromJSDate(order[0].planInTime).toJSDate().getHours()
          ).padStart(2, "0");
        }
      }

      let actualInHour = "";
      if (order[0].actualInTime && order[0].shop) {
        if (
          DateTime.fromJSDate(order[0]?.actualInTime).toJSDate().getHours() <
          Number(order[0].shop.openingTime.split(":")[0])
        ) {
          actualInHour = String(
            DateTime.fromJSDate(order[0]?.actualInTime).toJSDate().getHours() +
              24
          ).padStart(2, "0");
        } else {
          actualInHour = String(
            DateTime.fromJSDate(order[0].actualInTime).toJSDate().getHours()
          ).padStart(2, "0");
        }
      }

      let requestActualInHour = "";
      if (order[0].requestActualInTime && order[0].shop) {
        if (
          DateTime.fromJSDate(order[0]?.requestActualInTime)
            .toJSDate()
            .getHours() < Number(order[0].shop.openingTime.split(":")[0])
        ) {
          requestActualInHour = String(
            DateTime.fromJSDate(order[0]?.requestActualInTime)
              .toJSDate()
              .getHours() + 24
          ).padStart(2, "0");
        } else {
          requestActualInHour = String(
            DateTime.fromJSDate(order[0].requestActualInTime)
              .toJSDate()
              .getHours()
          ).padStart(2, "0");
        }
      }

      setFormData({
        ...formData,
        ...order[0],
        options: order[0].options?.map((option) => option.optionId),
        discounts: order[0].discounts?.map((discount) => discount.discountId),
        additionalTimeOrders: order[0].additionalTimeOrders?.map((ao) => ({
          additionalTimeId: ao.additionalTimeId,
          count: ao.count,
        })),
        departureHour,
        departureMinute: order[0].departureTime
          ? String(
              DateTime.fromJSDate(order[0].departureTime)
                .toJSDate()
                .getMinutes()
            ).padStart(2, "0")
          : "",
        planInHour,
        planInMinute: order[0].planInTime
          ? String(
              DateTime.fromJSDate(order[0].planInTime).toJSDate().getMinutes()
            ).padStart(2, "0")
          : "",
        actualInHour,
        actualInMinute: order[0].actualInTime
          ? String(
              DateTime.fromJSDate(order[0].actualInTime).toJSDate().getMinutes()
            ).padStart(2, "0")
          : "",
        requestActualInHour,
        requestActualInMinute: order[0].requestActualInTime
          ? String(
              DateTime.fromJSDate(order[0].requestActualInTime)
                .toJSDate()
                .getMinutes()
            ).padStart(2, "0")
          : "",
      });
      setOldOrder({ ...order[0] });
    }
  }, [order]);

  useEffect(() => {
    if (!formData?.orderDate) return;
    setStartDate(
      DateTimeUtils.toFormatAsLocalTimezone(
        formData?.orderDate,
        FORMAT_TYPE.YEAR_DAY
      )
    );
    setEndDate(
      DateTimeUtils.toFormatAsLocalTimezone(
        DateTime.fromJSDate(formData?.orderDate).toJSDate(),
        FORMAT_TYPE.YEAR_DAY
      )
    );
  }, [formData?.orderDate]);

  const onChangeAdditionalTimeOrder = (
    index: number,
    key: string,
    value: any
  ) => {
    setFormData((prev: any) => ({
      ...prev,
      additionalTimeOrders: prev.additionalTimeOrders?.map(
        (order: any, i: number) => {
          if (i === index) {
            return {
              ...order,
              [key]: Number(value),
            };
          }
          return order;
        }
      ),
    }));
  };
  const addAdditionalTimeOrder = () => {
    setFormData((prev: any) => ({
      ...prev,
      additionalTimeOrders: [
        ...(prev.additionalTimeOrders || []),
        {
          additionalTimeId: null,
          count: 0,
        },
      ],
    }));
  };
  const deleteAdditionalTimeOrder = (index: number) => {
    setFormData((prev: any) => ({
      ...prev,
      additionalTimeOrders: prev.additionalTimeOrders.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };
  const onChangeFormData = (key: string, value: any) => {
    setFormData((prev: any) => ({ ...prev, [key]: value }));
  };

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <SpOrderHeader
        pageNum={pageNum}
        setPageNum={setPageNum}
        shopId={formData?.shopId}
        onChangeFormData={onChangeFormData}
      />
      <Box padding={1}>
        {pageNum === 0 && (
          <SpOrderGuestInfo
            formData={formData}
            onChange={(key, value) => onChangeFormData(key, value)}
          />
        )}
        {pageNum === 1 && (
          <SpOrderInfo
            formData={formData}
            oldOrder={oldOrder}
            onChange={(key, value) => onChangeFormData(key, value)}
            onChangeAdditionalTimeOrder={(index, key, value) =>
              onChangeAdditionalTimeOrder(index, key, value)
            }
            addAdditionalTimeOrder={addAdditionalTimeOrder}
            deleteAdditionalTimeOrder={deleteAdditionalTimeOrder}
          />
        )}
        {pageNum === 2 && (
          <SpOrderDriverAndCast
            formData={formData}
            oldOrder={oldOrder}
            onChange={(key, value) => onChangeFormData(key, value)}
          />
        )}
        {pageNum === 3 && (
          <SpOrderPrice
            formData={formData}
            onChange={(key, value) => onChangeFormData(key, value)}
          />
        )}
        {pageNum === 4 && (
          <SpOrderConfirm
            formData={formData}
            onChange={(key, value) => onChangeFormData(key, value)}
          />
        )}
      </Box>
      <SpOrderFooter
        pageNum={pageNum}
        setPageNum={setPageNum}
        formData={formData}
        onChange={(key, value) => onChangeFormData(key, value)}
        oldOrder={oldOrder}
      />
    </>
  );
};

export default SpOrder;
