import RequestUtils from "utils/RequestUtils";
import FindMediaLtvReq from "types/req/mediaLtv/FindMediaLtvReq";

export default class GuestAnalyticsMediaLtvApi {
  private constructor() {}
  static async find(companyId: number, data: FindMediaLtvReq) {
    return await RequestUtils.postPlain(
      `/guestAnalyticsMediaLtv/find/${companyId}`,
      data
    );
  }
}
