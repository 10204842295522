import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import HotelApi from "api/HotelApi";
import HotelRes from "types/res/hotel/HotelRes";
import UpdateHotelReq from "types/req/hotel/UpdateHotelReq";
import CreateHotelReq from "types/req/hotel/CreateHotelReq";
import DeleteHotelReq from "types/req/hotel/DeleteHotelReq";

const actionCreator = actionCreatorFactory();
const addHotelAction = actionCreator.async<
  CreateHotelReq,
  HotelRes,
  { code: number; description: string }
>("ADD_HOTEL");

export const addHotel =
  (companyId: number, req: CreateHotelReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addHotelAction.started({ ...req }));
    try {
      const hotel = new CreateHotelReq({ ...req });
      const result = await HotelApi.create(companyId, hotel);
      dispatch(
        addHotelAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addHotelAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertHotelAction = actionCreator.async<
  CreateHotelReq[],
  HotelRes[],
  { code: number; description: string }
>("BULK_INSERT_HOTEL");

export const bulkInsertHotel =
  (companyId: number, req: CreateHotelReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertHotelAction.started({ ...req }));

    try {
      const result = await HotelApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertHotelAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertHotelAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchHotelAction = actionCreator.async<
  {},
  HotelRes[],
  { code: number; description: string }
>("FETCH_HOTEL");

export const fetchHotel =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchHotelAction.started([]));

    try {
      const result = await HotelApi.findAll(companyId);
      dispatch(
        fetchHotelAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchHotelAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateHotelAction = actionCreator.async<
  UpdateHotelReq,
  HotelRes,
  { code: number; description: string }
>("UPDATE_HOTEL");

export const updateHotel =
  (companyId: number, req: UpdateHotelReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateHotelAction.started({ ...req }));

    try {
      const updateHotel = new UpdateHotelReq({ ...req });
      const result = await HotelApi.update(companyId, updateHotel);
      dispatch(
        updateHotelAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateHotelAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteHotelAction = actionCreator.async<
  DeleteHotelReq,
  {},
  { code: number; description: string }
>("DELETE_HOTEL");

export const deleteHotel =
  (companyId: number, req: DeleteHotelReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteHotelAction.started({ ...req }));

    try {
      const hotel = new DeleteHotelReq({ ...req });
      const result = await HotelApi.delete(companyId, hotel);
      dispatch(
        deleteHotelAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteHotelAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const hotelActions = {
  fetchHotelAction,
  addHotelAction,
  updateHotelAction,
  deleteHotelAction,
  bulkInsertHotelAction,
};

export default hotelActions;
