import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import JournalType from "../../enum/JournalType";

export default class JournalRes {
  @Expose() readonly journalId!: number;
  @Expose() readonly shopId!: number;
  @Type(() => Date)
  @Expose()
  readonly date!: Date;
  @Expose() readonly type!: JournalType;
  @Expose() readonly genre!: string;
  @Expose() readonly itemName!: string;
  @Expose() readonly amount!: number;
  @Type(() => ShopRes)
  @Expose()
  readonly shop!: ShopRes;
}
