import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import {
  addNomination,
  deleteNomination,
  fetchNomination,
  updateNomination,
} from "redux/actions/nomination";
import NominationRes from "types/res/nomination/NominationRes";
import { fetchShops } from "redux/actions/shop";
import { FormType } from "components/FormField";
import CreateNominationReq from "types/req/nomination/CreateNominationReq";
import UpdateNominationReq from "types/req/nomination/UpdateNominationReq";
const headers: { key: keyof NominationRes; label: string }[] = [
  { key: "name", label: "名前" },
  { key: "totalFee", label: "料金" },
  { key: "shopFee", label: "店舗料金" },
  { key: "castFee", label: "キャスト料金" },
];

const Nomination: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const nomination = useSelector((state) => state.nomination);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchNomination(companyId));
  }, [companyId, dispatch]);

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "料金",
      key: "totalFee",
      type: FormType.Number,
    },
    {
      label: "店舗料金",
      key: "shopFee",
      type: FormType.Number,
    },
    {
      label: "キャスト料金",
      key: "castFee",
      type: FormType.Number,
    },
  ];

  return (
    <>
      <NominationTable
        title={"指名種"}
        formId="nominationForm"
        forms={forms}
        rows={headers}
        data={nomination}
        addFunc={(formData) => addNomination(companyId, formData)}
        updateFunc={(formData) => updateNomination(companyId, formData)}
        deleteFunc={(item) =>
          deleteNomination(companyId, {
            nominationId: item.nominationId,
          })
        }
        addType={CreateNominationReq}
        updateType={UpdateNominationReq}
        values={[
          (s) => s.name,
          (s) => s.totalFee,
          (s) => s.shopFee,
          (s) => s.castFee,
        ]}
      />
    </>
  );
};

const NominationTable = styled<
  React.FC<
    CommonTableProps<NominationRes, CreateNominationReq, UpdateNominationReq>
  >
>(CommonTable)`
  margin-top: 24px;
`;

export default Nomination;
