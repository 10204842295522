import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@material-ui/core";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import MessageBoardRes from "types/res/messageBoard/MessageBoardRes";
import {
  addMessageBoard,
  deleteMessageBoard,
  fetchMessageBoard,
  updateMessageBoard,
} from "redux/actions/messageBoard";
import { fetchStaff } from "redux/actions/staff";
import { FormType } from "components/FormField";
import {
  addMessageBoardCheck,
  fetchMessageBoardCheck,
} from "redux/actions/messageBoardCheck";
import CreateMessageBoardReq from "types/req/messageBoard/CreateMessageBoardReq";
import UpdateMessageBoardReq from "types/req/messageBoard/UpdateMessageBoardReq";

const MessageBoard: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const staffId = useSelector((state) => state.account.staff.staffId);
  const staffList = useSelector((state) => state.staff);
  const messageBoards = useSelector((state) => state.messageBoard);
  const messageBoardChecks = useSelector((state) => state.messageBoardCheck);
  useEffect(() => {
    dispatch(fetchStaff(companyId));
    dispatch(fetchMessageBoard(companyId));
    dispatch(fetchMessageBoardCheck(companyId));
  }, [dispatch, companyId]);

  const onClickMessageBoardCheck =
    (messageBoard: MessageBoardRes) => async () => {
      dispatch(
        addMessageBoardCheck(companyId, {
          messageBoardId: messageBoard.messageBoardId,
          staffId: staffId,
        })
      );
    };

  const isClickCheckButton = (messageBoard: MessageBoardRes) => {
    return messageBoardChecks.some(
      (messageBoardCheck) =>
        messageBoardCheck.messageBoardId === messageBoard.messageBoardId &&
        messageBoardCheck.staffId === staffId
    );
  };
  const headers: { key: keyof MessageBoardRes; label: string }[] = [
    { key: "title", label: "タイトル" },
    { key: "content", label: "内容" },
    { key: "createdBy", label: "作成者" },
    { key: "messageBoardChecks", label: "既読数" },
    { key: "title", label: "" },
  ];
  const forms = [
    {
      label: "タイトル",
      key: "title",
      type: FormType.Text,
    },
    {
      label: "内容",
      key: "content",
      type: FormType.TextArea,
    },
    {
      label: "作成者",
      key: "createdById",
      type: FormType.Select,
      options: staffList.map((s) => ({
        id: s.staffId,
        name: s.name,
      })),
    },
    {
      label: "既読数",
      key: "readCount",
      type: FormType.None,
    },
  ];
  return (
    <MessageBoardTable
      title={"掲示板"}
      formId="messageBoardForm"
      forms={forms}
      addFunc={(formData) => addMessageBoard(companyId, formData)}
      updateFunc={(formData) => updateMessageBoard(companyId, formData)}
      deleteFunc={(item) =>
        deleteMessageBoard(companyId, {
          messageBoardId: item?.messageBoardId,
        })
      }
      rows={headers}
      data={messageBoards}
      addType={CreateMessageBoardReq}
      updateType={UpdateMessageBoardReq}
      values={[
        (s) => s.title,
        (s) => (
          <Typography style={{ whiteSpace: "pre-wrap", width: "500px" }}>
            {s.content}
          </Typography>
        ),
        (s) => s.createdBy?.name || "",
        (s) =>
          `${
            messageBoardChecks.filter(
              (messageBoardCheck) =>
                messageBoardCheck.messageBoardId === s.messageBoardId
            )?.length || 0
          } / ${staffList.length}`,
        (s) => {
          return (
            <Button
              onClick={onClickMessageBoardCheck(s)}
              disabled={isClickCheckButton(s)}
            >
              <Typography style={{ fontSize: "12px" }}>既読にする</Typography>
            </Button>
          );
        },
      ]}
    />
  );
};

const MessageBoardTable = styled<
  React.FC<
    CommonTableProps<
      MessageBoardRes,
      CreateMessageBoardReq,
      UpdateMessageBoardReq
    >
  >
>(CommonTable)`
  margin-top: 24px;
`;

export default MessageBoard;
