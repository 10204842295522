import RequestUtils from "utils/RequestUtils";
import CourseRes from "types/res/course/CourseRes";
import CreateCourseReq from "types/req/course/CreateCourseReq";
import UpdateCourseReq from "types/req/course/UpdateCourseReq";
import DeleteCourseReq from "types/req/course/DeleteCourseReq";
import FindCourseReq from "types/req/course/FindCourseReq";

export default class CourseApi {
  private constructor() {}

  static async findAll(
    companyId: number,
    shopId?: number
  ): Promise<CourseRes[]> {
    return await RequestUtils.getArray(
      CourseRes,
      `/course/findAll/${companyId}${shopId ? `?shopId=${shopId}` : ""}`
    );
  }

  static async findAllWithPagination(
    companyId: number,
    req: FindCourseReq
  ): Promise<CourseRes[]> {
    return await RequestUtils.postArray(
      CourseRes,
      `/course/findAllWithPagination/${companyId}`,
      req
    );
  }

  static async count(companyId: number, req: FindCourseReq) {
    return await RequestUtils.countPost(`/course/count/${companyId}`, req);
  }

  static async create(companyId: number, data: CreateCourseReq[]) {
    return await RequestUtils.postArray(
      CourseRes,
      `/course/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    courseId: number,
    data: UpdateCourseReq
  ): Promise<CourseRes> {
    return await RequestUtils.post(
      CourseRes,
      `/course/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteCourseReq) {
    return await RequestUtils.postVoid(`/course/delete/${companyId}`, data);
  }
}
