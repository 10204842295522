import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import {
  AppointmentModel,
  ChangeSet,
  EditingState,
  GroupingState,
  IntegratedGrouping,
  Resource,
  ViewState,
} from "@devexpress/dx-react-scheduler";
import { fetchOrder, updateOrder } from "redux/actions/order";
import { fetchDriverShift } from "redux/actions/driverShift";
import {
  Appointments,
  AppointmentTooltip,
  DateNavigator,
  DragDropProvider,
  DayView,
  GroupingPanel,
  EditRecurrenceMenu,
  Resources,
  Scheduler,
  TodayButton,
  Toolbar,
} from "@devexpress/dx-react-scheduler-material-ui";
import { Box, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import EnumUtils from "utils/EnumUtils";
import OrderStatus from "types/enum/OrderStatus";
import OrderRes from "types/res/order/OrderRes";
import OrderApi from "api/OrderApi";
type OrderType = OrderRes & AppointmentModel;

const SpDriverSchedule = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const driverShift = useSelector((state) => state.driverShift);
  const orders = useSelector((state) => state.order);
  const [currentDate, setCurrentDate] = useState(
    DateTime.local().toFormat(FORMAT_TYPE.YEAR_DAY)
  );
  const [selectedOrders, setSelectedOrders] = useState<any[]>([]);
  const [resources, setResources] = useState<Resource[]>([]);

  const onCurrentDateChange = (currentDate: Date) => {
    setCurrentDate(
      DateTime.fromJSDate(currentDate).toFormat(FORMAT_TYPE.YEAR_DAY)
    );
  };

  useEffect(() => {
    const endDate = DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
      .plus({ days: 1 })
      .toFormat(FORMAT_TYPE.YEAR_DAY);
    dispatch(fetchDriverShift(companyId, currentDate, endDate));
    dispatch(fetchOrder(companyId, currentDate, endDate));
  }, [currentDate]);

  useEffect(() => {
    const carTransferSchedules = driverShift.flatMap((shift) => {
      return shift.driver.carTransferSchedules?.flatMap(
        (carTransferSchedule) => {
          return {
            id: `carTransferSchedule-${carTransferSchedule.carTransferScheduleId}`,
            driverId: shift.driverId,
            title: carTransferSchedule.title,
            carTransferScheduleId: carTransferSchedule.carTransferScheduleId,
            startDate: DateTime.fromJSDate(
              carTransferSchedule.startTime
            ).toJSDate(),
            endDate: DateTime.fromJSDate(
              carTransferSchedule.endTime
            ).toJSDate(),
          };
        }
      );
    });
    const stateOrders = orders.flatMap((order) => [
      {
        ...order,
        startDate: order.departureTime || new Date(),
        endDate: order?.outwardDuration
          ? DateTime.fromJSDate(order.departureTime || new Date())
              .plus({ minutes: order.outwardDuration })
              .toJSDate()
          : DateTime.fromJSDate(order.departureTime || new Date())
              .plus({ minutes: 30 })
              .toJSDate(),
        driverId: order.outwardDriverId || 0,
        driverType: "outward",
        id: `${order.orderId}-outward`,
      },
      {
        ...order,
        startDate: order?.returnDuration
          ? DateTime.fromJSDate(
              order.actualEndTime || order.planOutTime || new Date()
            )
              .minus({ minutes: order.returnDuration })
              .toJSDate()
          : DateTime.fromJSDate(
              order.actualEndTime || order.planOutTime || new Date()
            )
              .minus({ minutes: 30 })
              .toJSDate(),
        endDate: order.actualEndTime || order.planOutTime || new Date(),
        driverId: order.returnDriverId || 0,
        driverType: "return",
        id: `${order.orderId}-return`,
      },
    ]);
    const carTransferSchedulesAndOrders = [
      ...carTransferSchedules,
      ...stateOrders,
    ];
    setSelectedOrders(carTransferSchedulesAndOrders);
  }, [orders, driverShift]);

  useEffect(() => {
    if (!driverShift.length) return;
    const instance = Array.from(
      new Map(
        driverShift
          .map((driverShift) => ({
            id: Number(driverShift.driverId),
            text: driverShift.driver.name,
          }))
          .map((driverShift) => [driverShift.id, driverShift])
      ).values()
    );
    instance.unshift({ id: 0, text: "未設定" });
    setResources([{ fieldName: "driverId", instances: instance }]);
  }, [driverShift]);

  const onChangeCommit = async (changes: ChangeSet) => {
    if (!changes.changed) return;
    const [orderId, driverType] = Object.keys(changes.changed)[0].split("-");
    const order = orders.find((o) => o.orderId === Number(orderId));
    if (!driverType || !order) return;
    await OrderApi.assignOrderDriver(companyId, {
      orderId: order.orderId,
      driverType,
      driverId: changes.changed[`${orderId}-${driverType}`].driverId,
    });
    const endDate = DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
      .plus({ days: 1 })
      .toFormat(FORMAT_TYPE.YEAR_DAY);
    dispatch(fetchOrder(companyId, currentDate, endDate));
  };

  return (
    <Box>
      {resources.length ? (
        <Scheduler data={selectedOrders} locale="ja-JP" height={600}>
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={onCurrentDateChange}
          />
          <GroupingState
            grouping={[{ resourceName: "driverId" }]}
            groupByDate={() => true}
          />
          <EditingState onCommitChanges={onChangeCommit} />
          <EditRecurrenceMenu />
          <DayView
            startDayHour={
              currentDate === DateTime.local().toFormat(FORMAT_TYPE.YEAR_DAY) &&
              DateTime.local().hour > 3
                ? DateTime.local().minus({ hours: 3 }).hour
                : 0
            }
            endDayHour={24}
          />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <Appointments appointmentContentComponent={AppointmentContent} />
          <AppointmentTooltip contentComponent={AppointmentTooltipContent} />
          <Resources data={resources} mainResourceName={"driverId"} />
          <IntegratedGrouping />
          <GroupingPanel />
          <DragDropProvider />
        </Scheduler>
      ) : (
        <Scheduler data={selectedOrders} locale="ja-JP" height={600}>
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={onCurrentDateChange}
          />
          <DayView
            startDayHour={
              currentDate === DateTime.local().toFormat(FORMAT_TYPE.YEAR_DAY) &&
              DateTime.local().hour > 3
                ? DateTime.local().minus({ hours: 3 }).hour
                : 0
            }
            endDayHour={24}
          />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <Appointments appointmentContentComponent={AppointmentContent} />
          <AppointmentTooltip contentComponent={AppointmentTooltipContent} />
        </Scheduler>
      )}
    </Box>
  );
};

const AppointmentContent = ({ data, ...restProps }: any) => {
  return (
    <Appointments.AppointmentContent
      data={data}
      {...restProps}
      style={{ fontSize: 14 }}
    >
      <div>
        {data.orderId ? (
          <OrderTitleItems data={data} />
        ) : (
          <CarItem data={data} />
        )}
      </div>
    </Appointments.AppointmentContent>
  );
};

const AppointmentTooltipContent = ({
  appointmentData,
  formatDate,
  ...restProps
}: any) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  if (!appointmentData?.orderId)
    return (
      <AppointmentTooltip.Content
        appointmentData={appointmentData}
        {...restProps}
        formatDate={formatDate}
      />
    );
  const {
    options,
    cosplay,
    totalCastFee,
    memo,
    orderId,
    driverType,
    returnDriverId,
    outwardDriverId,
  } = appointmentData;

  const onClickSendMail = () => {
    const order = appointmentData;
    if (driverType === "outward" && outwardDriverId) {
      order.sendMailOutwardDriver = true;
    }
    if (driverType === "return" && returnDriverId) {
      order.sendMailReturnDriver = true;
    }
    dispatch(updateOrder(companyId, order));
  };
  return (
    <AppointmentTooltip.Content
      appointmentData={appointmentData}
      {...restProps}
      formatDate={formatDate}
    >
      <OrderItems data={appointmentData as OrderType} />
      <ScheduleItem title="バック" content={`${totalCastFee}円`} />
      <ScheduleItem
        title="オプション"
        content={options.map((option: any) => option.name || "").join("/")}
      />
      <ScheduleItem title="コスプレ" content={cosplay?.name || ""} />
      <ScheduleItem title="備考" content={memo} />
      <Box display="flex" flexDirection="column">
        {(driverType === "outward" && outwardDriverId) ||
        (driverType === "return" && returnDriverId) ? (
          <Button
            variant="contained"
            color="default"
            onClick={() => onClickSendMail()}
          >
            メール送信
          </Button>
        ) : null}
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          style={{ marginTop: 10 }}
          to={`/order/${orderId}`}
        >
          受注編集へ
        </Button>
      </Box>
    </AppointmentTooltip.Content>
  );
};
const OrderTitleItems = ({ data }: { data: OrderType }) => {
  return (
    <>
      <Box display="flex">
        <Typography style={{ fontSize: "10px", whiteSpace: "pre-wrap" }}>
          {data?.castName?.name || ""}
        </Typography>
      </Box>
    </>
  );
};
const OrderItems = ({ data }: { data: OrderType }) => {
  return (
    <>
      <Box display="flex">
        <ScheduleItem title="顧客名" content={data?.guest.name || "未設定"} />
      </Box>
      <ScheduleItem
        title="ステータス"
        content={EnumUtils.mapToEnum(OrderStatus, data.status) || ""}
      />
      <ScheduleItem
        title="キャスト"
        content={data.castName?.name || "未設定"}
      />
      <ScheduleItem
        title="出発"
        content={
          data.departureTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.departureTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="予定IN"
        content={
          data.planInTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.planInTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="予定OUT"
        content={
          data.planOutTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.planOutTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="実IN"
        content={
          data.actualInTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.actualInTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="実OUT"
        content={
          data.actualEndTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.actualEndTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem title="店舗名" content={data.shop.name || ""} />
      <ScheduleItem title="コース" content={data.course.name || ""} />
      <ScheduleItem title="住所1" content={`${data.orderAddress || ""}`} />
      <ScheduleItem title="住所2" content={`${data.orderAddress2 || ""}`} />
      <ScheduleItem
        title="送り"
        content={`${data.outwardDriver?.carType || ""} ${
          data.outwardDriver?.carColor || ""
        } ${data.outwardDriver?.carNumber || ""}`}
      />
      <ScheduleItem
        title="迎え"
        content={`${data.returnDriver?.carType || ""} ${
          data.returnDriver?.carColor || ""
        } ${data.returnDriver?.carNumber || ""}`}
      />
      <ScheduleItem title="ホテル" content={`${data.hotel?.name || ""}`} />
      <ScheduleItem title="総額" content={`${data.totalFee || 0}円`} />
    </>
  );
};

const ScheduleItem = ({
  title,
  content,
}: {
  title: string;
  content: string;
}) => {
  return (
    <Box display="flex">
      <Typography style={{ fontWeight: "bold" }}>{title}:</Typography> {content}
    </Box>
  );
};

const CarItem = (data: any) => {
  return <Box>{data.data.title}</Box>;
};
export default SpDriverSchedule;
