import useModal from "hooks/useModal";
import React, { useEffect, useState } from "react";
import { fetchCourse } from "redux/actions/course";
import { useDispatch, useSelector } from "react-redux";
import CourseRes from "types/res/course/CourseRes";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { addOrder } from "redux/actions/order";
import CreateOrderReq from "types/req/order/CreateOrderReq";
import Modal from "components/Modal";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";
import OrderRes from "types/res/order/OrderRes";

type Props = {
  setStateOrders: any;
  orders: OrderRes[];
};

const CastScheduleOnItemCreateNoCast: React.FC<Props> = ({
  setStateOrders,
  orders,
}) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const staffId = useSelector((state) => state.account.staff.staffId);
  const courses = useSelector((state) => state.course);
  const [
    castScheduleOnItemCreateNoCastModal,
    setCastScheduleOnItemCreateNoCastShow,
  ] = useModal("castScheduleOnItemCreateNoCast");
  const [planInTime, setPlanInTime] = useState<Date | null>();
  const [planOutTime, setPlanOutTime] = useState<Date | null>();
  const [departureTime, setDepartureTime] = useState<Date | null>();
  const [courseId, setCourseId] = useState<number>();
  const [courseTime, setCourseTime] = useState<number>();
  const [filterCourses, setFilterCourses] = useState<CourseRes[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchCourse(companyId));
  }, []);

  useEffect(() => {
    if (!castScheduleOnItemCreateNoCastModal?.meta?.order) return;
    setDepartureTime(
      castScheduleOnItemCreateNoCastModal.meta.order.departureTime
    );
    setPlanInTime(castScheduleOnItemCreateNoCastModal.meta.order.planInTime);
    setPlanOutTime(castScheduleOnItemCreateNoCastModal.meta.order.planOutTime);
  }, [castScheduleOnItemCreateNoCastModal]);

  useEffect(() => {
    if (castScheduleOnItemCreateNoCastModal.show) return;
    setStateOrders(orders);
  }, [castScheduleOnItemCreateNoCastModal?.show]);

  useEffect(() => {
    setFilterCourses((prev) =>
      courseTime ? prev.filter((course) => course.time === courseTime) : courses
    );
  }, [courseTime]);

  const onClickSubmit = async () => {
    const order = { ...castScheduleOnItemCreateNoCastModal.meta.order };
    const course = courses.find((course) => course.courseId === courseId);
    order.planInTime = planInTime;
    order.planOutTime = planOutTime;
    order.departureTime = departureTime;
    order.status = "hold";
    order.bookEmailBody = "";
    order.courseId = courseId;
    order.courseTime = courseTime;
    order.totalTime =
      courseTime +
        order?.additionalTimeOrders?.reduce(
          (sum: number, additionalTimeOrder: any) =>
            sum +
            (additionalTimeOrder?.additionalTime?.time || 0) *
              additionalTimeOrder.count,
          0
        ) || 0;
    order.courseFee = (course?.timeFee || 0) + (course?.hotelFee || 0);
    order.courseCastFee =
      (course?.timeCastFee || 0) + (course?.hotelCastFee || 0);
    order.courseShopFee =
      (course?.timeShopFee || 0) + (course?.hotelShopFee || 0);
    order.timeFee = course?.timeFee || 0;
    order.timeCastFee = course?.timeCastFee || 0;
    order.timeShopFee = course?.timeShopFee || 0;
    order.hotelFee = course?.hotelFee || 0;
    order.hotelCastFee = course?.hotelCastFee || 0;
    order.hotelShopFee = course?.hotelShopFee || 0;
    order.welfareFee = course?.welfareFee || 0;
    order.totalFee = (course?.timeFee || 0) + (course?.hotelFee || 0);
    order.totalCastFee =
      (course?.timeFee || 0) +
      (course?.hotelFee || 0) -
      (course?.welfareFee || 0);
    order.totalShopFee =
      (course?.timeFee || 0) +
      (course?.hotelFee || 0) +
      (course?.welfareFee || 0);
    order.createdById = staffId;
    order.updatedById = staffId;
    order.orderDate = DateTimeUtils.toFormatAsLocalTimezone(
      order.orderDate,
      FORMAT_TYPE.YEAR_DAY
    );
    await dispatch(addOrder(companyId, new CreateOrderReq(order)));
    setCastScheduleOnItemCreateNoCastShow(false);
  };

  return (
    <Modal
      show={castScheduleOnItemCreateNoCastModal.show}
      setShow={setCastScheduleOnItemCreateNoCastShow}
      title={`新規予約作成`}
    >
      <Container>
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column">
            <KeyboardTimePicker
              label="出発"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={departureTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setDepartureTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
            <KeyboardTimePicker
              label="予定IN"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={planInTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setPlanInTime(date?.toDate() || null)}
              minutesStep={5}
              variant="inline"
              style={{ marginTop: "10px" }}
            />
            <TextField
              label="利用時間"
              select
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={courseTime}
              onChange={(event) => setCourseTime(Number(event.target.value))}
              style={{ marginTop: "10px" }}
            >
              <option aria-label="None" value="" />
              {filterCourses
                .filter(
                  (element, index, self) =>
                    self.findIndex((e) => e.time === element.time) === index
                )
                .map((course) => (
                  <MenuItem key={course.time} value={course.time}>
                    {course.time}分
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              label="コース"
              select
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={courseId}
              onChange={(event) => setCourseId(Number(event.target.value))}
              style={{ marginTop: "10px" }}
            >
              {filterCourses.map((course) => (
                <MenuItem key={course.courseId} value={course.courseId}>
                  {course.name}
                </MenuItem>
              ))}
            </TextField>
            <KeyboardTimePicker
              label="予定OUT"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={planOutTime || null}
              format="YYYY/MM/DD HH:mm"
              onChange={(date) => setPlanOutTime(date?.toDate() || null)}
              minutesStep={5}
              disabled
              variant="inline"
              style={{ marginTop: "10px" }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          style={{ margin: "10px 0" }}
          justifyContent="space-evenly"
        >
          <Button
            variant="contained"
            color="default"
            disabled={loading}
            onClick={() => setCastScheduleOnItemCreateNoCastShow(false)}
          >
            {loading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "いいえ"
            )}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={onClickSubmit}
          >
            {loading ? <CircularProgress color="inherit" size={24} /> : "はい"}
          </Button>
        </Box>
      </Container>
    </Modal>
  );
};
export default CastScheduleOnItemCreateNoCast;
