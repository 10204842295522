import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";

export default class OptionRes {
  @Expose() readonly optionId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly totalFee!: number;
  @Expose() readonly shopFee!: number;
  @Expose() readonly castFee!: number;
  @Expose() readonly sort!: number | null;
  @Expose() readonly isGuestShow!: boolean;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
}
