import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  createStyles,
  IconButton,
  InputLabel,
  ListItemText,
  Table,
  TextField,
  Theme,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { Delete } from "@material-ui/icons";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchShops } from "redux/actions/shop";
import Button from "@material-ui/core/Button";
import { CourseProp } from "../index";
import { makeStyles } from "@material-ui/core/styles";
import { fetchNotelClass } from "redux/actions/notelClass";
import { fetchNomination } from "redux/actions/nomination";

type Props = {
  setCourse: React.Dispatch<React.SetStateAction<CourseProp[]>>;
};
const CreateCourseBaseData: React.FC<Props> = ({ setCourse }) => {
  const dispatch = useDispatch();
  const shops = useSelector((state) => state.shop);
  const loginStaff = useSelector((state) => state.account.staff);
  const notelClass = useSelector((state) => state.notelClass);
  const nominations = useSelector((state) => state.nomination);
  const [addCourseBase] = useState<CourseProp[]>([]);
  useEffect(() => {
    dispatch(fetchShops(loginStaff.companyId));
    dispatch(fetchNotelClass(loginStaff.companyId));
    dispatch(fetchNomination(loginStaff.companyId));
  }, [dispatch, loginStaff]);
  const [timeTableRows, setTimeTableRows] = useState([
    { time: 0, shop: 0, cast: 0 },
  ]);
  const [hotel, setHotel] = React.useState({
    enable: false,
    shop: 0,
    cast: 0,
    total: 0,
  });
  const [selectClass, setSelectClass] = useState<number[]>([]);
  const [selectShop, setSelectShop] = useState<number[]>([]);
  const [selectNomination, setSelectNomination] = useState<number>();

  const addTimeTableRow = () => {
    setTimeTableRows([...timeTableRows, { time: 0, shop: 0, cast: 0 }]);
  };

  const removeTimeTableRow = (index: number) => () => {
    setTimeTableRows(timeTableRows.filter((_, i) => i !== index));
  };

  const setTime =
    (index: number) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const copyTableRows = timeTableRows.slice();
      copyTableRows[index].time = Number(event.target.value);
      setTimeTableRows(copyTableRows);
    };

  const setTimeCast =
    (index: number) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const copyTableRows = timeTableRows.slice();
      copyTableRows[index].cast = Number(event.target.value);
      setTimeTableRows(copyTableRows);
    };

  const setTimeShop =
    (index: number) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const copyTableRows = timeTableRows.slice();
      copyTableRows[index].shop = Number(event.target.value);
      setTimeTableRows(copyTableRows);
    };

  const onChangeSelectShop = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectShop(event.target.value as number[]);
  };

  const onChangeSelectClass = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectClass(event.target.value as number[]);
  };
  const onChangeHotel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHotel({ ...hotel, enable: event.target.checked });
  };

  const setHotelTotal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHotel({ ...hotel, total: Number(event.target.value) });
  };

  const setHotelShop = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHotel({
      ...hotel,
      shop: Number(event.target.value),
      total: (hotel.cast || 0) + Number(event.target.value),
    });
  };

  const setHotelCast = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHotel({
      ...hotel,
      cast: Number(event.target.value),
      total: (hotel.shop || 0) + Number(event.target.value),
    });
  };
  const rows = ["時間", "店舗料金", "キャスト料金", "店舗総売上", ""];

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const styleClass = useStyles();

  const createCourseData = () => {
    if (!selectNomination) return;
    const addCourses: CourseProp[] = [];
    selectShop.forEach((shop) =>
      selectClass.forEach((notelClass) =>
        timeTableRows.forEach((timeTable) =>
          addCourses.push({
            name: "",
            price: 0,
            shop: shop,
            hotel: hotel,
            nominationId: selectNomination,
            classId: notelClass,
            time: timeTable,
            welfare: 0,
            course: { shop: 0, cast: 0 },
            sort: null,
          })
        )
      )
    );
    setCourse(addCourseBase.concat(addCourses));
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="subtitle1">基本設定</Typography>
          <Box display="flex" marginY={2} alignItems="baseline">
            <Box marginX={2}>
              <InputLabel id="select-multiple-shop">店舗選択</InputLabel>
              <Select
                multiple
                value={selectShop}
                onChange={onChangeSelectShop}
                input={<Input id="select-multiple-shop" />}
                style={{ width: "100px" }}
                renderValue={(selected) =>
                  shops
                    .filter((n) => (selected as number[]).includes(n.shopId))
                    .map((n) => n.name)
                    .join(", ")
                }
              >
                {shops.map((shop) => (
                  <MenuItem key={shop.shopId} value={shop.shopId}>
                    <Checkbox checked={selectShop.indexOf(shop.shopId) > -1} />
                    <ListItemText primary={shop.name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box marginX={2}>
              <InputLabel id="select-multiple-class">クラス選択</InputLabel>
              <Select
                multiple
                value={selectClass}
                onChange={onChangeSelectClass}
                input={<Input id="select-multiple-class" />}
                style={{ width: "100px" }}
                renderValue={(selected) =>
                  notelClass
                    .filter((n) =>
                      (selected as number[]).includes(n.notelClassId)
                    )
                    .map((n) => n.name)
                    .join(", ")
                }
              >
                {notelClass.map((notelClass) => (
                  <MenuItem
                    key={notelClass.notelClassId}
                    value={notelClass.notelClassId}
                  >
                    <Checkbox
                      checked={
                        selectClass.indexOf(notelClass.notelClassId) > -1
                      }
                    />
                    <ListItemText primary={notelClass.name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box marginX={2}>
              <InputLabel id="select-multiple-nomination">
                指名種選択
              </InputLabel>
              <Select
                value={selectNomination}
                onChange={(event) =>
                  setSelectNomination(Number(event.target.value))
                }
                input={<Input id="select-multiple-nomination" />}
                style={{ width: "100px" }}
              >
                {nominations.map((nomination) => (
                  <MenuItem
                    key={nomination.nominationId}
                    value={nomination.nominationId}
                  >
                    {nomination.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box marginX={2}>
              <InputLabel id="hotel">ホテ込み</InputLabel>
              <Checkbox
                name="hotel"
                onChange={onChangeHotel}
                defaultChecked={false}
                id={`hotel`}
              />
            </Box>
            {hotel.enable && (
              <Box marginX={2} display="flex" alignItems="center">
                <Box marginX={1}>
                  <TextField
                    type="number"
                    variant="outlined"
                    onChange={setHotelShop}
                    label="店舗料金"
                  />
                </Box>
                <Box marginX={1}>
                  <TextField
                    type="number"
                    variant="outlined"
                    onChange={setHotelCast}
                    label="キャスト料金"
                  />
                </Box>
                <Box marginX={1}>
                  <TextField
                    type="number"
                    variant="outlined"
                    value={hotel.total || ""}
                    onChange={setHotelTotal}
                    label="全体売上"
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Typography variant="subtitle1">時間設定</Typography>
          <Table>
            <TableHead>
              <TableRow>
                {rows.map((row, index) => (
                  <TableCell key={index}>{row}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {timeTableRows.map((tableRow, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      onChange={setTime(index)}
                      placeholder="100"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      onChange={setTimeShop(index)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      onChange={setTimeCast(index)}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography>{tableRow.cast + tableRow.shop}円</Typography>
                  </TableCell>
                  <TableCell>
                    <TableCell padding="checkbox">
                      <IconButton onClick={removeTimeTableRow(index)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <Button
              variant="contained"
              className={styleClass.button}
              color="primary"
              onClick={addTimeTableRow}
            >
              時間の追加
            </Button>
          </Table>
          <Box display="flex" justifyContent="center" style={{ margin: 10 }}>
            <Button
              variant="contained"
              className={styleClass.button}
              color="primary"
              onClick={createCourseData}
            >
              データ生成
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CreateCourseBaseData;
