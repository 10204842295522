import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import StaffApi from "api/StaffApi";
import { Alert, AlertTitle } from "@material-ui/lab";
const Container = styled.div`
  margin: 50px 10px;

  > * {
    margin-bottom: ${({ theme: { spacing } }) => spacing(2)}px;
  }
`;

const SpResetPasswordSend = () => {
  const [email, setEmail] = useState("");
  const onChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(event.target.value);
  };
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const onClickPostButton = async () => {
    if (!email) return;

    setLoading(true);
    await StaffApi.resetPasswordSendEmail(email);
    setLoading(false);
    setIsSent(true);
  };
  return (
    <Container>
      <Typography>
        パスワードを忘れた方は、パスワードの再発行をして下さい。アカウント登録時にご登録して頂いたメールアドレスにパスワード再発行手続きのメールをお送りします。
      </Typography>
      <TextField
        required
        label="メールアドレス"
        variant="outlined"
        autoComplete="email"
        fullWidth
        onChange={onChangeEmail}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading}
        onClick={onClickPostButton}
      >
        {loading ? <CircularProgress color="inherit" size={24} /> : "送信"}
      </Button>
      {isSent && (
        <Alert severity="success">
          <AlertTitle>メールを送信しました</AlertTitle>
        </Alert>
      )}
    </Container>
  );
};
export default SpResetPasswordSend;
