import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppBar as MuiAppBar, Box, Button, Toolbar } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { blue, grey } from "@material-ui/core/colors";
import { logout } from "redux/actions/account";
const theme = {
  color: "#333333",
  background: "#FFFFFF",
  search: {
    color: grey[800],
  },
  indicator: {
    background: blue[600],
  },
};

const AppBar = styled(MuiAppBar)`
  background: ${theme.background};
  color: ${theme.color};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
`;

const Logo = styled(Link)`
  display: flex;
  justify-self: center;
  img {
    height: 28px;
  }
`;

const SpHeader = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => Boolean(state.account.accessToken));
  const onClickLogoutButton = () => {
    dispatch(logout());
  };

  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <Logo to="/">
              <img src="/logo.png" />
            </Logo>
            {isLoggedIn && (
              <Button onClick={() => onClickLogoutButton()}>ログアウト</Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default SpHeader;
