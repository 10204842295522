import { Expose, Type } from "class-transformer";

export default class MessageBoardCommentRes {
  @Expose() readonly messageBoardCommentId!: number;
  @Expose() readonly messageBoardId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly staffId!: number;
  @Expose() readonly content!: string;
  @Type(() => Date)
  @Expose()
  readonly createdAt!: Date;
}
