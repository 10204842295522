import RequestUtils from "utils/RequestUtils";
import FindProfileSalesDataReq from "types/req/profileSales/FindProfileSalesDataReq";

export default class GuestAnalyticsProfileSalesApi {
  private constructor() {}

  static async findData(companyId: number, data: FindProfileSalesDataReq) {
    return await RequestUtils.postPlain(
      `/guestAnalyticsProfileSales/findData/${companyId}`,
      data
    );
  }
}
