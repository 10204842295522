import { Expose, Type } from "class-transformer";
import CastRes from "../cast/CastRes";
import OptionRes from "../option/OptionRes";
import NotelClassRes from "../notelClass/NotelClassRes";
import ShopRes from "../shop/ShopRes";

export default class CastNameRes {
  @Expose() readonly castNameId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly castId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly shopId!: number;
  @Expose() readonly age!: number | null;
  @Expose() readonly bodyHeight!: number | null;
  @Expose() readonly cup!: string | null;
  @Expose() readonly bust!: number | null;
  @Expose() readonly waist!: number | null;
  @Expose() readonly hip!: number | null;
  @Expose() readonly sort!: number | null;
  @Expose() readonly notelClassId!: number;
  @Type(() => ShopRes)
  @Expose()
  readonly shop!: ShopRes;
  @Type(() => NotelClassRes)
  @Expose()
  readonly notelClass!: NotelClassRes;
  @Type(() => CastRes)
  @Expose()
  readonly cast!: CastRes;
  @Type(() => OptionRes)
  @Expose()
  readonly options!: OptionRes[];
}
