import { Expose, Type } from "class-transformer";
import DriverRes from "../driver/DriverRes";
import ShiftStatus from "../../enum/ShiftStatus";
import StaffRes from "../staff/StaffRes";
import DriverStatus from "types/enum/DriverStatus";

export default class DriverShiftRes {
  @Expose() readonly driverShiftId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly driverId!: number;
  @Type(() => Date)
  @Expose()
  readonly planWorkStart!: Date;
  @Type(() => Date)
  @Expose()
  readonly planWorkEnd!: Date;
  @Type(() => Date)
  @Expose()
  readonly actualWorkStart!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly actualWorkEnd!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly workOffStart!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly workOffEnd!: Date | null;
  @Expose() readonly status!: ShiftStatus;
  @Expose() readonly scheduleItemColor!: string | null;
  @Expose() readonly driverMemo!: string | null;
  @Expose() readonly driverStatus!: DriverStatus | null;
  @Expose() readonly roadConditionMemo!: string | null;
  @Type(() => Date)
  @Expose()
  readonly planReturnTime!: Date | null;
  @Type(() => DriverRes)
  @Expose()
  readonly driver!: DriverRes;
  @Type(() => Date)
  @Expose()
  readonly updatedAt!: Date;
  @Type(() => StaffRes)
  @Expose()
  readonly updatedBy!: StaffRes;
  @Expose() readonly updatedById!: number | null;
}
