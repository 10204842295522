import CreateOrderReq from "types/req/order/CreateOrderReq";
import UpdateOrderReq from "types/req/order/UpdateOrderReq";
import {
  addOrder,
  bulkDeleteOrder,
  bulkInsertOrder,
  deleteOrder,
  fetchOrderWithPagination,
  updateOrder,
} from "redux/actions/order";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import OrderRes from "types/res/order/OrderRes";
import { FormType } from "components/FormField";
import { useDispatch, useSelector } from "react-redux";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import EnumUtils from "utils/EnumUtils";
import OrderStatus from "types/enum/OrderStatus";
import PaymentType from "types/enum/PaymentType";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import OrderResponseStatus from "../../types/enum/OrderResponseStatus";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { fetchShops } from "redux/actions/shop";
import StaffRole from "types/enum/StaffRole";
import {
  fetchCompanies,
  fetchCompaniesByCompanyGroupId,
} from "redux/actions/company";
import CsvImport from "components/CsvImport";
import { CsvFieldType } from "utils/CsvUtils";
import { simpleFetchGuest } from "redux/actions/guest";
import { fetchCastName } from "redux/actions/castName";
import { fetchCosplay } from "redux/actions/cosplay";
import { fetchCourse } from "redux/actions/course";
import { fetchNomination } from "redux/actions/nomination";
import { fetchNotelClass } from "redux/actions/notelClass";
import { fetchAdditionalTime } from "redux/actions/additionalTime";
import { fetchArea } from "redux/actions/area";
import { fetchHotel } from "redux/actions/hotel";
import { fetchDriver } from "redux/actions/driver";
import { fetchMedium } from "redux/actions/medium";
import { fetchOption } from "redux/actions/option";
import { fetchDiscount } from "redux/actions/discount";
import { PlanType } from "types/enum/PlanType";
import CtiModal from "pages/OrderList/ctiModal";
import { Help } from "@material-ui/icons";
import OrderApi from "api/OrderApi";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ShopApi from "api/ShopApi";

const OrderList: React.FC = () => {
  const dispatch = useDispatch();

  const companyId = useSelector((state) => state.account.staff.companyId);
  const plan = useSelector(
    (state) => state.account.staff.company.companyGroup.plan
  );
  const loginStaff = useSelector((state) => state.account.staff);
  const orders = useSelector((state) => state.order);
  const shops = useSelector((state) => state.shop);
  const guests = useSelector((state) => state.guest);
  const castNames = useSelector((state) => state.castName);
  const cosplays = useSelector((state) => state.cosplay);
  const courses = useSelector((state) => state.course);
  const notelClasses = useSelector((state) => state.notelClass);
  const nominations = useSelector((state) => state.nomination);
  const areas = useSelector((state) => state.area);
  const hotels = useSelector((state) => state.hotel);
  const drivers = useSelector((state) => state.driver);
  const media = useSelector((state) => state.medium);
  const discounts = useSelector((state) => state.discount);
  const options = useSelector((state) => state.option);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState<string[]>([
    ...Object.keys(OrderStatus).filter((os) => os !== "cancel"),
  ]);
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [openCsvImport, setOpenCsvImport] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [includeUnconfirmedOrder, setIncludeUnconfirmedOrder] = useState(false);
  const [salesAndCount, setSalesAndCount] = useState<{
    booking: { sales: number; count: number };
    paid: { sales: number; count: number };
  }>();

  useEffect(() => {
    const apiCall = async () => {
      const initialShops = await ShopApi.findAll(companyId);
      const requestStatus = Object.keys(OrderStatus)
        .filter((os) => os !== "cancel")
        .join(",");

      const requestShopIds = initialShops
        .map((shop) => String(shop.shopId))
        .join(",");
      dispatch(
        fetchOrderWithPagination(
          companyId,
          limit,
          offset * limit,
          DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          requestStatus,
          requestShopIds,
          includeUnconfirmedOrder
        )
      );
      setTotalCount(
        await OrderApi.count(
          companyId,
          undefined,
          DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          requestStatus,
          requestShopIds
        )
      );
      setIsLoading(false);
      const result = await OrderApi.saleAndCountForBookingAndPaid(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)
      );
      setSalesAndCount(result);
    };
    apiCall();
  }, []);

  const onClickSearch = async () => {
    if (EnumUtils.mapToEnum(PlanType, plan) === PlanType.free) {
      const lastYear = DateTime.local()
        .minus({ year: 1 })
        .startOf("days")
        .toJSDate();
      if (lastYear > startDate) {
        alert("フリープランでは1年以上前のデータは閲覧できません。");
        return;
      }
    }
    if (EnumUtils.mapToEnum(PlanType, plan) === PlanType.starter) {
      const lastYear = DateTime.local()
        .minus({ year: 3 })
        .startOf("days")
        .toJSDate();
      if (lastYear > startDate) {
        alert("スタータープランでは3年以上前のデータは閲覧できません。");
        return;
      }
    }
    if (EnumUtils.mapToEnum(PlanType, plan) === PlanType.premium) {
      const lastYear = DateTime.local()
        .minus({ year: 5 })
        .startOf("days")
        .toJSDate();
      if (lastYear > startDate) {
        alert("プレミアムプランでは5年以上前のデータは閲覧できません。");
        return;
      }
    }
    if (!shops.length || !status) return;
    setIsLoading(true);
    const requestStatus = status.join(",");
    const requestShopIds = selectShops
      .filter((shop) => shop !== "すべて")
      .join(",");
    dispatch(
      fetchOrderWithPagination(
        companyId,
        limit,
        offset * limit,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        requestStatus,
        requestShopIds,
        includeUnconfirmedOrder
      )
    );
    setTotalCount(
      await OrderApi.count(
        companyId,
        undefined,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        requestStatus,
        requestShopIds
      )
    );
    setIsLoading(false);
    const result = await OrderApi.saleAndCountForBookingAndPaid(
      companyId,
      DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)
    );
    setSalesAndCount(result);
  };

  useEffect(() => {
    dispatch(fetchShops(companyId));
    if (
      [
        StaffRole.notelMaster,
        StaffRole.notelCallCenterEmployee,
        StaffRole.notelCallCenterPartTime,
      ].includes(
        EnumUtils.mapToEnum(StaffRole, loginStaff?.role) ||
          StaffRole.clientShopPartTime
      )
    ) {
      dispatch(fetchCompanies());
    }
    if (
      StaffRole.clientMaster ===
        EnumUtils.mapToEnum(StaffRole, loginStaff?.role) &&
      loginStaff?.company?.companyGroupId
    ) {
      dispatch(
        fetchCompaniesByCompanyGroupId(
          loginStaff.companyId,
          loginStaff.company.companyGroupId
        )
      );
    }
  }, [companyId, loginStaff]);

  useEffect(() => {
    if (!openCsvImport) return;
    setIsCsvLoading(true);
    const apiCall = async () => {
      await dispatch(simpleFetchGuest(companyId));
      await dispatch(fetchCastName(companyId));
      await dispatch(fetchCosplay(companyId));
      await dispatch(fetchCourse(companyId));
      await dispatch(fetchNomination(companyId));
      await dispatch(fetchNotelClass(companyId));
      await dispatch(fetchArea(companyId));
      await dispatch(fetchHotel(companyId));
      await dispatch(fetchDriver(companyId));
      await dispatch(fetchMedium(companyId));
      await dispatch(fetchOption(companyId));
      await dispatch(fetchDiscount(companyId));
      setIsCsvLoading(false);
    };
    apiCall();
  }, [openCsvImport, companyId]);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "料金",
      key: "totalFee",
      type: FormType.Number,
    },
  ];
  const headers: {
    key: keyof OrderRes;
    label: string;
  }[] = [
    { key: "orderDate", label: "日付" },
    { key: "status", label: "ステータス" },
    { key: "shopId", label: "店名" },
    { key: "guestId", label: "顧客名" },
    { key: "guestId", label: "顧客区分" },
    { key: "guestId", label: "顧客電話番号" },
    { key: "castName", label: "キャスト" },
    { key: "orderAddress", label: "場所" },
    { key: "courseId", label: "コース" },
    { key: "options", label: "オプション" },
    { key: "courseTime", label: "コース分数" },
    { key: "areaId", label: "エリア" },
    { key: "orderAddress", label: "住所1" },
    { key: "departureTime", label: "出発" },
    { key: "planInTime", label: "予IN" },
    { key: "actualInTime", label: "実IN-OUT" },
    { key: "actualEndTime", label: "call" },
    { key: "responseStatus", label: "応答" },
    { key: "collectReceivable", label: "回収" },
    { key: "totalFee", label: "金額" },
    { key: "paymentType", label: "カード" },
    { key: "payoff", label: "精算" },
    { key: "createdBy", label: "登録者" },
    { key: "updatedBy", label: "最終更新者" },
    { key: "updatedAt", label: "更新日時" },
    { key: "orderId", label: "" },
  ];

  const csvHeader = [
    {
      label: "※日付",
      key: "orderDate",
      type: CsvFieldType.Date,
    },
    {
      label: "※顧客(電話番号)",
      key: "guestId",
      type: CsvFieldType.Object,
      relation: guests,
      relationKey: "tel",
      relationId: "guestId",
    },
    {
      label: "※店舗",
      key: "shopId",
      type: CsvFieldType.Object,
      relation: shops,
      relationId: "shopId",
      relationKey: "name",
    },
    {
      label: "※源氏名",
      key: "castNameId",
      type: CsvFieldType.Object,
      relation: castNames,
      relationId: "castNameId",
      relationKey: "name",
      sub: true,
      subRelationKey: "shopId",
    },
    {
      label: "コスプレ",
      key: "cosplayId",
      type: CsvFieldType.Object,
      relation: cosplays,
      relationId: "cosplayId",
      relationKey: "name",
    },
    {
      label: "※コース",
      key: "courseId",
      type: CsvFieldType.Object,
      relation: courses,
      relationId: "courseId",
      relationKey: "name",
    },
    {
      label: "※指名種",
      key: "nominationId",
      type: CsvFieldType.Object,
      relation: nominations,
      relationId: "nominationId",
      relationKey: "name",
    },
    {
      label: "※クラス",
      key: "notelClassId",
      type: CsvFieldType.Object,
      relation: notelClasses,
      relationId: "notelClassId",
      relationKey: "name",
    },
    {
      label: "※エリア",
      key: "areaId",
      type: CsvFieldType.Object,
      relation: areas,
      relationId: "areaId",
      relationKey: "name",
    },
    {
      label: "ホテル",
      key: "hotelId",
      type: CsvFieldType.Object,
      relation: hotels,
      relationId: "hotelId",
      relationKey: "name",
    },
    {
      label: "迎えドライバー",
      key: "outwardDriverId",
      type: CsvFieldType.Object,
      relation: drivers,
      relationId: "outwardDriverId",
      relationKey: "name",
    },
    {
      label: "送りドライバー",
      key: "returnDriverId",
      type: CsvFieldType.Object,
      relation: drivers,
      relationId: "returnDriverId",
      relationKey: "name",
    },
    {
      label: "媒体",
      key: "mediumId",
      type: CsvFieldType.Object,
      relation: media,
      relationId: "mediumId",
      relationKey: "name",
    },
    {
      label: "※ステータス",
      key: "status",
      type: CsvFieldType.Enum,
      enumObject: OrderStatus,
    },
    {
      label: "※出発時間",
      key: "departureTime",
      type: CsvFieldType.DateTime,
    },
    {
      label: "※予定IN時間",
      key: "planInTime",
      type: CsvFieldType.DateTime,
    },
    {
      label: "※予定OUT時間",
      key: "planOutTime",
      type: CsvFieldType.DateTime,
    },
    {
      label: "※実IN時間",
      key: "actualInTime",
      type: CsvFieldType.DateTime,
    },
    {
      label: "※実OUT時間",
      key: "actualEndTime",
      type: CsvFieldType.DateTime,
    },
    {
      label: "※応答",
      key: "responseStatus",
      type: CsvFieldType.Enum,
      enumObject: OrderResponseStatus,
    },
    {
      label: "※回収",
      key: "collectReceivable",
      type: CsvFieldType.Boolean,
    },
    {
      label: "※精算",
      key: "payoff",
      type: CsvFieldType.Boolean,
    },
    {
      label: "※支払い",
      key: "paymentType",
      type: CsvFieldType.Enum,
      enumObject: PaymentType,
    },
    {
      label: "メモ",
      key: "memo",
      type: CsvFieldType.Text,
    },
    {
      label: "アンケート",
      key: "questionnaire",
      type: CsvFieldType.Text,
    },
    {
      label: "住所1",
      key: "orderAddress",
      type: CsvFieldType.Text,
    },
    {
      label: "※住所2",
      key: "orderAddress2",
      type: CsvFieldType.Text,
    },
    {
      label: "スコア※数字のみ",
      key: "score",
      type: CsvFieldType.Number,
    },
    {
      label: "オプション",
      key: "options",
      type: CsvFieldType.Array,
      relation: options,
      relationId: "optionId",
      relationKey: "name",
    },
    {
      label: "割引",
      key: "discounts",
      type: CsvFieldType.Array,
      relation: discounts,
      relationId: "discountId",
      relationKey: "name",
    },
    {
      label: "ポイント利用※数字のみ",
      key: "pointFee",
      type: CsvFieldType.Number,
    },
    {
      label: "カード利用料※数字のみ",
      key: "cardFee",
      type: CsvFieldType.Number,
    },
    {
      label: "その他料金※数字のみ",
      key: "otherFee",
      type: CsvFieldType.Number,
    },
    {
      label: "その他キャスト料金※数字のみ",
      key: "otherCastFee",
      type: CsvFieldType.Number,
    },
    {
      label: "その他店舗料金※数字のみ",
      key: "otherShopFee",
      type: CsvFieldType.Number,
    },
  ];
  const bulkInsertOrderData = (formData: CreateOrderReq[]) => {
    return bulkInsertOrder(
      companyId,
      formData.map((formDatum) => {
        const area = areas.find((area) => area.areaId === formDatum.areaId);
        const medium = media.find(
          (medium) => medium.mediumId === formDatum.mediumId
        );
        const course = courses.find(
          (course) => course.courseId === formDatum.courseId
        );
        const cosplay = cosplays.find(
          (c) => c.cosplayId === formDatum.cosplayId
        );
        const nomination = nominations.find(
          (n) => n.nominationId === formDatum.nominationId
        );
        const optionList = options.filter((o) =>
          formDatum.options?.includes(o.optionId)
        );
        const discountList = discounts?.filter((d) =>
          formDatum.discounts?.includes(d.discountId)
        );
        const optionFee = optionList.reduce(
          (sum, o) => sum + o.totalFee || 0,
          0
        );
        const optionCastFee = optionList.reduce(
          (sum, o) => sum + o.castFee || 0,
          0
        );
        const optionShopFee = optionList.reduce(
          (sum, o) => sum + o.shopFee || 0,
          0
        );
        const discountFee = discountList.reduce(
          (sum, o) => sum + o.totalFee || 0,
          0
        );
        const discountCastFee = discountList.reduce(
          (sum, o) => sum + o.castFee || 0,
          0
        );
        const discountShopFee = discountList.reduce(
          (sum, o) => sum + o.shopFee || 0,
          0
        );
        const subTotalFee =
          (course?.totalFee || 0) +
          (nomination?.totalFee || 0) +
          optionFee +
          (cosplay?.totalFee || 0) +
          (area?.totalFee || 0) +
          (formDatum?.otherFee || 0) -
          (medium?.totalFee || 0) -
          discountFee;
        const totalFee =
          subTotalFee + (formDatum?.cardFee || 0) - (formDatum?.pointFee || 0);
        const totalCastFee =
          (course?.timeCastFee || 0) +
          (nomination?.castFee || 0) +
          optionCastFee +
          (cosplay?.castFee || 0) -
          discountCastFee +
          (area?.castFee || 0) +
          (formDatum?.otherCastFee || 0) -
          (medium?.castFee || 0) -
          (course?.welfareFee || 0);
        const totalShopFee =
          (course?.timeShopFee || 0) +
          (nomination?.shopFee || 0) +
          optionShopFee +
          (cosplay?.shopFee || 0) -
          discountShopFee +
          (area?.shopFee || 0) +
          (formDatum?.otherShopFee || 0) -
          (medium?.shopFee || 0) +
          (course?.welfareFee || 0);
        return {
          ...formDatum,
          additionalFee: 0,
          additionalCastFee: 0,
          additionalShopFee: 0,
          additionalTimeOrders: [],
          areaFee: area?.totalFee || 0,
          areaCastFee: area?.castFee || 0,
          areaShopFee: area?.shopFee || 0,
          mediumFee: medium?.totalFee || 0,
          mediumShopFee: medium?.shopFee || 0,
          mediumCastFee: medium?.castFee || 0,
          courseFee: course?.totalFee || 0,
          courseShopFee: course?.timeShopFee || 0,
          courseCastFee: course?.timeCastFee || 0,
          cosplayFee: cosplay?.totalFee || 0,
          cosplayShopFee: cosplay?.shopFee || 0,
          cosplayCastFee: cosplay?.castFee || 0,
          timeFee: course?.timeFee || 0,
          timeCastFee: course?.timeCastFee || 0,
          timeShopFee: course?.timeShopFee || 0,
          hotelFee: course?.hotelFee || 0,
          hotelShopFee: course?.hotelShopFee || 0,
          hotelCastFee: course?.hotelCastFee || 0,
          welfareFee: course?.welfareFee || 0,
          designateFee: nomination?.totalFee || 0,
          designateCastFee: nomination?.castFee || 0,
          designateShopFee: nomination?.shopFee || 0,
          options: optionList.map((option) => option.optionId),
          optionFee,
          optionCastFee,
          optionShopFee,
          discounts: discountList.map((discount) => discount.discountId),
          discountFee,
          discountCastFee,
          discountShopFee,
          subTotalFee,
          totalFee,
          totalCastFee,
          totalShopFee,
          cardFee: formDatum?.cardFee || 0,
          pointFee: formDatum?.pointFee || 0,
          otherFee: formDatum?.otherFee || 0,
          otherCastFee: formDatum?.otherCastFee || 0,
          otherShopFee: formDatum?.otherShopFee || 0,
          courseTime: course?.time || 0,
          totalTime: course?.time || 0,
        };
      })
    );
  };

  const onClickReload = async () => {
    setIsLoading(true);
    const requestStatus = status.join(",");
    const requestShopIds = selectShops
      .filter((shop) => shop !== "すべて")
      .join(",");
    await dispatch(
      fetchOrderWithPagination(
        companyId,
        limit,
        offset * limit,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        requestStatus,
        requestShopIds,
        includeUnconfirmedOrder
      )
    );
    setTotalCount(
      await OrderApi.count(
        companyId,
        undefined,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        requestStatus,
        requestShopIds
      )
    );
    setIsLoading(false);
  };

  const orderImportDataList = [
    "指名種管理",
    "店舗管理",
    "エリア管理",
    "クラス管理",
    "コース管理",
    "顧客区分管理",
    "顧客管理",
    "キャスト区分管理",
    "キャスト管理",
    "源氏名管理",
  ];
  if (
    EnumUtils.mapToEnum(StaffRole, loginStaff?.role) === StaffRole.notelMaster
  ) {
    orderImportDataList.unshift("会社管理");
    orderImportDataList.unshift("グループ管理");
  }
  const helpText = (
    <Box
      display="flex"
      flexDirection={"column"}
      marginTop={2}
      justifyContent="center"
      alignItems="center"
    >
      <Typography>
        受注インポートには下記のデータを登録してある必要があります。
      </Typography>
      {orderImportDataList.map((data, index) => (
        <Typography key={index}>
          {index + 1}.{data}
        </Typography>
      ))}
    </Box>
  );

  const createdByName = (order: OrderRes) => {
    if (order.isCastOrder) {
      return "姫予約";
    }
    if (order.isGuestOrder) {
      return "顧客予約";
    }
    return order?.createdBy?.name || "未設定";
  };

  const updatedByName = (order: OrderRes) => {
    if (order.createdAt === order.updatedAt) {
      if (order.isCastOrder) {
        return "姫予約";
      }
      if (order.isGuestOrder) {
        return "顧客予約";
      }
    }
    return order?.updatedBy?.name || "未設定";
  };
  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box margin={2} display="flex" alignItems="center">
          <TextField
            type="date"
            label="開始日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(startDate).toFormat(
              FORMAT_TYPE.YEAR_DAY
            )}
            onChange={(event) =>
              setStartDate(
                DateTime.fromISO(event.target.value as string).toJSDate()
              )
            }
          />
          <TextField
            type="date"
            label="終了日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
            onChange={(event) =>
              setEndDate(
                DateTime.fromISO(event.target.value as string).toJSDate()
              )
            }
          />
          <Box>
            <InputLabel id="select-multiple-status">ステータス</InputLabel>
            <Select
              multiple
              value={status}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setStatus((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [...Object.keys(OrderStatus), "すべて"];
                  } else if (
                    (event.target.value as string[]).length === 4 &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [...Object.keys(OrderStatus), "すべて"];
                  } else if ((event.target.value as string[]).length < 5) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-status" />}
              style={{ width: "100px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return Object.entries(OrderStatus)
                    .filter(([key]) => (selected as string[]).includes(key))
                    .map(([, value]) => value)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox name="all" checked={status.indexOf("すべて") > -1} />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {Object.entries(OrderStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={status.indexOf(key) !== -1} />
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={selectShops}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectShops((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length === shops.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= shops.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "100px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectShops.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                name={"includeUnconfirmedOrder"}
                onChange={(event) =>
                  setIncludeUnconfirmedOrder(event.target.checked)
                }
                checked={includeUnconfirmedOrder}
                id={`includeUnconfirmedOrder`}
              />
            }
            label={"未確定予約を含む"}
          />
          <Box>
            <Button onClick={onClickSearch} variant="contained" color="primary">
              検索
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection="column"
            marginTop={1}
            marginRight={2}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCsvImport((prev) => !prev)}
              style={{ marginLeft: "30px" }}
            >
              csvインポート
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onClickReload()}
              style={{ marginLeft: "30px", marginTop: "10px" }}
            >
              更新
            </Button>
          </Box>
          <Box display="flex">
            <Box display="flex" flexDirection="column">
              <Typography>
                予約本数：{salesAndCount?.booking.count || 0}本
              </Typography>
              <Typography>
                予約売上：{salesAndCount?.booking.sales || 0}円
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" marginLeft={2}>
              <Typography>
                完了本数：{salesAndCount?.paid.count || 0}本
              </Typography>
              <Typography>
                完了売上：{salesAndCount?.paid.sales || 0}円
              </Typography>
            </Box>
          </Box>
        </Box>
        {openCsvImport && (
          <Box display="flex" justifyContent="center">
            <CsvImport
              templatePath={"/csv/order.csv"}
              templateName={"order_template.csv"}
              csvHeader={csvHeader}
              addType={CreateOrderReq}
              addFunc={(formData) => bulkInsertOrderData(formData)}
              isLoading={isCsvLoading}
            />
            <Tooltip title={helpText}>
              <Help />
            </Tooltip>
          </Box>
        )}
      </Box>
      <OrderTable
        title={"受注"}
        formId="orderForm"
        fullWidth
        isLoading={isLoading}
        forms={forms}
        rows={headers}
        data={orders}
        totalCount={totalCount}
        offset={offset}
        limit={limit}
        setLimit={setLimit}
        setOffset={setOffset}
        addFunc={(formData) => addOrder(companyId, formData)}
        updateFunc={(formData) => updateOrder(companyId, formData)}
        deleteFunc={(item) =>
          deleteOrder(companyId, {
            orderId: item.orderId,
          })
        }
        bulkDeleteFunc={(item) =>
          bulkDeleteOrder(
            companyId,
            item.map((value: OrderRes) => ({ orderId: value.orderId }))
          )
        }
        bulkDeleteHook={async () => {
          const requestStatus = status.join(",");
          const requestShopIds = selectShops
            .filter((shop) => shop !== "すべて")
            .join(",");
          await dispatch(
            fetchOrderWithPagination(
              companyId,
              limit,
              offset * limit,
              DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
              DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
              requestStatus,
              requestShopIds,
              includeUnconfirmedOrder
            )
          );
          setTotalCount(
            await OrderApi.count(
              companyId,
              undefined,
              DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
              DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
              requestStatus,
              requestShopIds,
              includeUnconfirmedOrder
            )
          );
        }}
        invisibleDeleteIcon={
          ![StaffRole.notelMaster, StaffRole.clientMaster].includes(
            EnumUtils.mapToEnum(StaffRole, loginStaff.role) ||
              StaffRole.clientShopEmployee
          )
        }
        values={[
          (o) =>
            DateTimeUtils.toFormatAsLocalTimezone(
              o.orderDate,
              FORMAT_TYPE.DAY_LIST
            ),
          (o) => EnumUtils.mapToEnum(OrderStatus, o.status) || "",
          (o) => o.shop?.name || "未設定",
          (o) => o.guest?.name || "未設定",
          (o) =>
            loginStaff.company.guestCategoryShopFlag
              ? o.guest?.guestGuestCategoryShops?.find(
                  (guestGuestCategoryShop) =>
                    guestGuestCategoryShop.shopId === o.shopId &&
                    guestGuestCategoryShop.guestId === o.guestId
                )?.guestCategory?.name || "未設定"
              : o?.guest?.guestCategory?.name || "未設定",
          (o) => o.guest?.tel || "未設定",
          (o) =>
            o.castName?.name ? (
              <p
                style={{
                  color: `${o.isSentEmailToCast ? "red" : ""}`,
                }}
              >
                {o.castName?.name}
              </p>
            ) : (
              "未設定"
            ),
          (o) => o.hotel?.name || "未設定",
          (o) => o.course?.name || "未設定",
          (o) => o.options?.map((option) => option.name).join(",") || "未設定",
          (o) => (o.courseTime ? `${o.courseTime}分` : "未設定"),
          (o) => o.area?.name || "未設定",
          (o) => o.orderAddress || "未設定",
          (o) =>
            o.departureTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  o.departureTime,
                  FORMAT_TYPE.TIME
                )
              : "未設定",
          (o) =>
            o.planInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  o.planInTime,
                  FORMAT_TYPE.TIME
                )
              : "未設定",
          (o) =>
            o.actualInTime && o.actualEndTime ? (
              `${DateTimeUtils.toFormatAsLocalTimezone(
                o.actualInTime,
                FORMAT_TYPE.TIME
              )} ~ ${DateTimeUtils.toFormatAsLocalTimezone(
                o.actualEndTime,
                FORMAT_TYPE.TIME
              )}`
            ) : o.requestActualInTime || o.requestActualEndTime ? (
              <Typography style={{ color: "red" }} variant="body1">
                リクエスト有り
              </Typography>
            ) : (
              "未設定"
            ),
          (o) =>
            !o?.shop?.orderAlertEmailTime
              ? "未設定"
              : o?.actualEndTime
              ? DateTime.fromJSDate(o.actualEndTime)
                  .minus({
                    minutes: o?.shop?.orderAlertEmailTime,
                  })
                  .toFormat(FORMAT_TYPE.TIME)
              : o?.planOutTime
              ? DateTime.fromJSDate(o.planOutTime)
                  .minus({
                    minutes: o?.shop?.orderAlertEmailTime,
                  })
                  .toFormat(FORMAT_TYPE.TIME)
              : "未設定",
          (o) =>
            EnumUtils.mapToEnum(OrderResponseStatus, o.responseStatus) ||
            "未設定",
          (o) => (o.collectReceivable ? "済" : "未"),
          (o) => `${o.totalFee || 0}円`,
          (o) => EnumUtils.mapToEnum(PaymentType, o.paymentType) || "未設定",
          (o) => (o.payoff ? "精算済み" : "未精算"),
          (o) => createdByName(o),
          (o) => updatedByName(o),
          (o) =>
            DateTimeUtils.toFormatAsLocalTimezone(
              o.updatedAt,
              FORMAT_TYPE.DATE_TIME
            ),
          (o) => (
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to={o.orderId ? `/order/${o.orderId}` : `/order`}
            >
              編集
            </Button>
          ),
        ]}
      />
      <CtiModal />
    </>
  );
};
const OrderTable = styled<
  React.FC<CommonTableProps<OrderRes, CreateOrderReq, UpdateOrderReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default OrderList;
