import RequestUtils from "utils/RequestUtils";
import NominationRes from "types/res/nomination/NominationRes";
import CreateNominationReq from "types/req/nomination/CreateNominationReq";
import UpdateNominationReq from "types/req/nomination/UpdateNominationReq";
import DeleteNominationReq from "types/req/nomination/DeleteNominationReq";

export default class NominationApi {
  private constructor() {}

  static async findAll(companyId: number) {
    return await RequestUtils.getArray(
      NominationRes,
      `/nomination/findAll/${companyId}`
    );
  }

  static async create(companyId: number, data: CreateNominationReq) {
    return await RequestUtils.post(
      NominationRes,
      `/nomination/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    data: UpdateNominationReq
  ): Promise<NominationRes> {
    return await RequestUtils.post(
      NominationRes,
      `/nomination/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteNominationReq) {
    return await RequestUtils.postVoid(`/nomination/delete/${companyId}`, data);
  }
}
