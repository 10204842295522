import actionCreatorFactory from "typescript-fsa";
import CastRes from "types/res/cast/CastRes";
const actionCreator = actionCreatorFactory();

type CastShiftFilterActionPayloadProps = {
  selectShopIds?: string[];
  selectCasts?: CastRes[];
  selectCastCategoryIds?: string[];
};

const castShiftFilterActions = {
  setCastShiftFilter: actionCreator<CastShiftFilterActionPayloadProps>(
    "SET_CAST_SHIFT_FILTER"
  ),
};
export default castShiftFilterActions;
