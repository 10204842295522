import { Expose, Type } from "class-transformer";
import GuestRes from "../guest/GuestRes";
import ShopRes from "types/res/shop/ShopRes";

export default class GuestCategoryRes {
  @Expose() readonly guestCategoryId!: number;
  @Expose() readonly name!: string;
  @Type(() => GuestRes)
  @Expose()
  readonly guests!: GuestRes[];
  @Type(() => Boolean)
  @Expose()
  readonly pointGrantFlag!: boolean;
  @Type(() => Boolean)
  @Expose()
  readonly unUseUserFlag!: boolean;
  @Type(() => Boolean)
  @Expose()
  readonly secondUserFlag!: boolean;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
}
