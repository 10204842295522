import RequestUtils from "utils/RequestUtils";

import CreateClientRes from "types/res/payment/CreateClientRes";
import { PlanType } from "types/enum/PlanType";

export default class PaymentApi {
  private constructor() {}

  static async findOrCreateSubscription(
    companyId: number,
    priceId: string,
    isAnnual: boolean
  ): Promise<CreateClientRes> {
    return await RequestUtils.post(
      CreateClientRes,
      `/payment/findOrCreateSubscription/${companyId}/${priceId}`,
      { isAnnual }
    );
  }

  static async findOrCreateSubscriptionOnMail(
    companyId: number,
    priceId: string,
    isAnnual: boolean,
    planType: PlanType
  ) {
    return await RequestUtils.postVoid(
      `/payment/findOrCreateSubscriptionOnMail/${companyId}/${priceId}`,
      { isAnnual, planType }
    );
  }

  static async updatePaymentMethod(companyId: number, paymentMethodId: string) {
    return await RequestUtils.postVoid(
      `/payment/updatePaymentMethod/${companyId}/${paymentMethodId}`,
      {}
    );
  }

  static async changeFreePlan(companyId: number) {
    return await RequestUtils.postVoid(
      `/payment/changeFreePlan/${companyId}`,
      {}
    );
  }
}
