import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import OrderApi from "api/OrderApi";
import OrderRes from "types/res/order/OrderRes";
import UpdateOrderReq from "types/req/order/UpdateOrderReq";
import CreateOrderReq from "types/req/order/CreateOrderReq";
import DeleteOrderReq from "types/req/order/DeleteOrderReq";
import CancelOrderReq from "../../types/req/order/CancelOrderReq";
import FindSpOrderReq from "types/req/order/FindSpOrderReq";

const actionCreator = actionCreatorFactory();
const addOrderAction = actionCreator.async<
  CreateOrderReq,
  OrderRes,
  { code: number; description: string }
>("ADD_ORDER");

export const addOrder =
  (companyId: number, req: CreateOrderReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addOrderAction.started({ ...req }));
    try {
      const order = new CreateOrderReq({ ...req });
      const result = await OrderApi.create(companyId, order);
      dispatch(
        addOrderAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addOrderAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkInsertOrderAction = actionCreator.async<
  CreateOrderReq[],
  OrderRes[],
  { code: number; description: string }
>("BULK_INSERT_ORDER");

export const bulkInsertOrder =
  (companyId: number, req: CreateOrderReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkInsertOrderAction.started({ ...req }));

    try {
      const result = await OrderApi.bulkInsert(companyId, req);
      dispatch(
        bulkInsertOrderAction.done({
          params: [...req],
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkInsertOrderAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchOneOrderAction = actionCreator.async<
  {},
  OrderRes,
  { code: number; description: string }
>("FETCH_ONE_ORDER");
export const fetchOneOrder =
  (companyId: number, orderId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchOneOrderAction.started({}));
    try {
      const result = await OrderApi.findOne(companyId, orderId);
      dispatch(
        fetchOneOrderAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchOneOrderAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchOrderAction = actionCreator.async<
  {},
  OrderRes[],
  { code: number; description: string }
>("FETCH_ORDER");

export const fetchOrder =
  (companyId: number, startDate: string, endDate: string) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchOrderAction.started([]));

    try {
      const result = await OrderApi.findAll(companyId, startDate, endDate);
      dispatch(
        fetchOrderAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchOrderAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchOrderByGuestIdAction = actionCreator.async<
  {},
  OrderRes[],
  { code: number; description: string }
>("FETCH_ORDER_BY_GUEST_ID");

export const fetchOrderByGuestId =
  (companyId: number, guestId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchOrderByGuestIdAction.started([]));

    try {
      const result = await OrderApi.findAllByGuestId(companyId, guestId);
      dispatch(
        fetchOrderByGuestIdAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchOrderByGuestIdAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchOrderWithPaginationAction = actionCreator.async<
  {},
  OrderRes[],
  { code: number; description: string }
>("FETCH_ORDER_WITH_PAGINATION");

export const fetchOrderWithPagination =
  (
    companyId: number,
    limit: number,
    offset: number,
    startDate: string,
    endDate: string,
    status: string,
    shopIds: string,
    includeUnconfirmedOrder: boolean
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchOrderWithPaginationAction.started([]));

    try {
      const result = await OrderApi.findAllWithPagination(
        companyId,
        limit,
        offset,
        startDate,
        endDate,
        status,
        shopIds,
        includeUnconfirmedOrder
      );
      dispatch(
        fetchOrderWithPaginationAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchOrderWithPaginationAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchOrderByCastWithPaginationAction = actionCreator.async<
  {},
  OrderRes[],
  { code: number; description: string }
>("FETCH_ORDER_BY_CAST_WITH_PAGINATION");

export const fetchOrderByCastWithPagination =
  (companyId: number, limit: number, offset: number, castId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchOrderByCastWithPaginationAction.started([]));

    try {
      const result = await OrderApi.findByCastWithPagination(
        companyId,
        limit,
        offset,
        castId
      );
      dispatch(
        fetchOrderByCastWithPaginationAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchOrderByCastWithPaginationAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchSpOrderAction = actionCreator.async<
  {},
  OrderRes[],
  { code: number; description: string }
>("FETCH_SP_ORDER");

export const fetchSpOrder =
  (companyId: number, req: FindSpOrderReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchSpOrderAction.started([]));

    try {
      const result = await OrderApi.findSpAll(companyId, req);
      dispatch(
        fetchSpOrderAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchSpOrderAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateOrderAction = actionCreator.async<
  UpdateOrderReq,
  OrderRes,
  { code: number; description: string }
>("UPDATE_ORDER");

export const updateOrder =
  (companyId: number, req: UpdateOrderReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateOrderAction.started({ ...req }));

    try {
      const updateOrder = new UpdateOrderReq({ ...req });
      const result = await OrderApi.update(companyId, updateOrder);
      dispatch(
        updateOrderAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateOrderAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const cancelOrderAction = actionCreator.async<
  CancelOrderReq,
  OrderRes,
  { code: number; description: string }
>("CANCEL_ORDER");
export const cancelOrder =
  (companyId: number, req: CancelOrderReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(cancelOrderAction.started({ ...req }));

    try {
      const cancelOrder = new CancelOrderReq({ ...req });
      const result = await OrderApi.cancel(companyId, cancelOrder);
      dispatch(
        cancelOrderAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        cancelOrderAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteOrderAction = actionCreator.async<
  DeleteOrderReq,
  {},
  { code: number; description: string }
>("DELETE_ORDER");

export const deleteOrder =
  (companyId: number, req: DeleteOrderReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteOrderAction.started({ ...req }));

    try {
      const order = new DeleteOrderReq({ ...req });
      const result = await OrderApi.delete(companyId, order);
      dispatch(
        deleteOrderAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteOrderAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const bulkDeleteOrderAction = actionCreator.async<
  DeleteOrderReq[],
  {},
  { code: number; description: string }
>("BULK_DELETE_ORDER");

export const bulkDeleteOrder =
  (companyId: number, req: DeleteOrderReq[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(bulkDeleteOrderAction.started({ ...req }));

    try {
      const result = await OrderApi.bulkDelete(companyId, req);
      dispatch(
        bulkDeleteOrderAction.done({
          params: [...req],
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        bulkDeleteOrderAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const orderActions = {
  fetchOrderAction,
  fetchOneOrderAction,
  fetchOrderByGuestIdAction,
  fetchOrderWithPaginationAction,
  fetchOrderByCastWithPaginationAction,
  fetchSpOrderAction,
  addOrderAction,
  updateOrderAction,
  cancelOrderAction,
  deleteOrderAction,
  bulkInsertOrderAction,
  bulkDeleteOrderAction,
};

export default orderActions;
