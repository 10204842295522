import { reducerWithInitialState } from "typescript-fsa-reducers";
import accountActions from "redux/actions/account";
import LoginRes from "types/res/staff/LoginRes";

export type AccountState = LoginRes;

const initialState = {} as AccountState;

const accountReducer = reducerWithInitialState(initialState)
  .case(accountActions.signinWithEmailAction.done, (_state, payload) => {
    return payload.result;
  })
  .case(accountActions.logoutAction.done, (_state, _payload) => {
    return {} as AccountState;
  })
  .case(accountActions.updatePlanAction.done, (state, payload) => {
    return {
      ...state,
      staff: {
        ...state.staff,
        company: {
          ...state.staff.company,
          companyGroup: {
            ...payload.result,
          },
        },
      },
    };
  })
  .case(accountActions.checkStaffStatusAction.done, (state, payload) => {
    return {
      ...state,
      staff: { ...payload.result },
    };
  });

export default accountReducer;
