import React from "react";
import { AppBar, Tab, Tabs as MuiTabs } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import EnumUtils from "../utils/EnumUtils";
import StaffRole from "../types/enum/StaffRole";
import { PlanType } from "types/enum/PlanType";

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

interface LinkTabProps {
  label?: string;
  value: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const Tabs = () => {
  const history = useHistory();
  const role = useSelector((state) => state.account.staff.role);
  const plan = useSelector(
    (state) => state.account.staff.company.companyGroup.plan
  );
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    history.push(newValue);
  };
  let links = [
    { label: "TOP", value: "/", sort: 1 },
    { label: "受注", value: "/orderList", sort: 4 },
    { label: "キャストシフト", value: "/castShift", sort: 5 },
    { label: "キャストスケジュール", value: "/castSchedule", sort: 7 },
  ];
  if (EnumUtils.mapToEnum(PlanType, plan) !== PlanType.free) {
    links = [
      ...links,
      [
        { label: "タスク", value: "/staffTask", sort: 2 },
        { label: "掲示板", value: "/messageBoard", sort: 3 },
      ],
    ].flat();
  }
  if (
    [
      StaffRole.notelMaster,
      StaffRole.notelCallCenterEmployee,
      StaffRole.notelCallCenterPartTime,
      StaffRole.clientMaster,
      StaffRole.clientShopMaster,
    ].includes(
      EnumUtils.mapToEnum(StaffRole, role) || StaffRole.clientShopPartTime
    )
  ) {
    links = [
      ...links,
      [
        { label: "ドライバーシフト", value: "/driverShift", sort: 6 },
        { label: "配車", value: "/driverSchedule", sort: 8 },
        { label: "分析", value: "/analytics", sort: 12 },
      ],
    ].flat();
  }
  if (EnumUtils.mapToEnum(StaffRole, role) !== StaffRole.clientShopPartTime) {
    links = [
      ...links,
      [
        { label: "精算", value: "/payoff", sort: 11 },
        { label: "マスター", value: "/setting", sort: 13 },
      ],
    ].flat();
  }

  return (
    <div style={{ flexGrow: 1, width: "100vw" }}>
      <AppBar position="static">
        <MuiTabs
          value={history.location.pathname}
          onChange={handleChange}
          aria-label="nav tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {links
            .sort((a, b) => {
              if (a.sort < b.sort) return -1;
              if (a.sort > b.sort) return 1;
              return 0;
            })
            .map((link, index) => (
              <LinkTab
                label={link.label}
                value={link.value}
                key={index}
                {...a11yProps(index)}
              />
            ))}
        </MuiTabs>
      </AppBar>
    </div>
  );
};

export default Tabs;
