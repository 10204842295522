enum StaffRole {
  notelMaster = "タステックマスター",
  notelCallCenterEmployee = "タステックコールセンター社員",
  notelCallCenterPartTime = "タステックコールセンターアルバイト",
  clientMaster = "マスター",
  clientShopMaster = "店舗マスター",
  clientShopEmployee = "店舗社員",
  clientShopPartTime = "店舗アルバイト",
  areaEmployee = "エリア社員",
  areaPartTime = "エリアアルバイト",
}
export default StaffRole;
