enum OrderStatus {
  // inquiry = "お問い合わせ",
  hold = "保留",
  booking = "予約",
  paid = "完了",
  cancel = "キャンセル",
  castRequest = "キャスト申請",
  castConfirm = "キャスト承認待ち",
  guestRequest = "顧客申請",
  guestConfirm = "顧客承認待ち",
}

export default OrderStatus;
