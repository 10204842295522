import JournalRes from "../../types/res/journal/JournalRes";
import React, { useState } from "react";
import {
  Box,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import ShopRes from "../../types/res/shop/ShopRes";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import { useDispatch, useSelector } from "react-redux";
import { deleteJournal, updateJournal } from "redux/actions/journal";
import { Delete } from "@material-ui/icons";

type Props = {
  journal: JournalRes;
  shops: ShopRes[];
};

const EditJournalRow: React.FC<Props> = ({ journal, shops }) => {
  const companyId = useSelector((state) => state.account.staff.companyId);
  const dispatch = useDispatch();
  const [date, setDate] = useState(
    DateTime.fromJSDate(journal.date).toFormat(FORMAT_TYPE.YEAR_DAY)
  );
  const updateJournalReq = (key: string) => (event: any) => {
    dispatch(
      updateJournal(companyId, {
        ...journal,
        [key]:
          key === "date"
            ? DateTime.fromFormat(
                event.target.value,
                FORMAT_TYPE.YEAR_DAY
              ).toJSDate()
            : key === "amount"
            ? Number(event.target.value)
            : event.target.value,
      })
    );
  };
  return (
    <Box display="flex">
      <Box padding={1} border="1px solid #000" width="150px">
        <TextField
          type="date"
          value={date || ""}
          onChange={(event) =>
            setDate(
              DateTime.fromFormat(
                event.target.value,
                FORMAT_TYPE.YEAR_DAY
              ).toFormat(FORMAT_TYPE.YEAR_DAY)
            )
          }
          onBlur={updateJournalReq("date")}
        />
      </Box>
      <Box padding={1} border="1px solid #000" width="150px">
        <TextField
          select
          fullWidth
          value={journal.shopId}
          onChange={updateJournalReq("shopId")}
        >
          {shops.map((shop) => (
            <MenuItem key={shop.shopId} value={shop.shopId}>
              {shop.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box padding={1} border="1px solid #000" width="150px">
        <TextField
          type="text"
          defaultValue={journal.genre}
          onBlur={updateJournalReq("genre")}
        />
      </Box>
      <Box padding={1} border="1px solid #000" width="150px">
        <TextField
          type="text"
          defaultValue={journal.itemName}
          onBlur={updateJournalReq("itemName")}
        />
      </Box>
      <Box padding={1} border="1px solid #000" width="150px">
        <Box display="flex" alignItems="flex-end">
          <TextField
            type="text"
            defaultValue={journal.amount}
            onBlur={updateJournalReq("amount")}
          />
          <Typography>円</Typography>
        </Box>
      </Box>
      <Box
        padding={1}
        border="1px solid #000"
        width="50px"
        display="flex"
        justifyContent="center"
      >
        <IconButton
          style={{ padding: "3px" }}
          onClick={() => {
            dispatch(
              deleteJournal(companyId, { journalId: journal.journalId })
            );
          }}
        >
          <Delete width="10px" />
        </IconButton>
      </Box>
    </Box>
  );
};
export default EditJournalRow;
