import RequestUtils from "utils/RequestUtils";
import DiscountRes from "types/res/discount/DiscountRes";
import CreateDiscountReq from "types/req/discount/CreateDiscountReq";
import UpdateDiscountReq from "types/req/discount/UpdateDiscountReq";
import DeleteDiscountReq from "types/req/discount/DeleteDiscountReq";

export default class DiscountApi {
  private constructor() {}

  static async findAll(
    companyId: number,
    shopId?: number
  ): Promise<DiscountRes[]> {
    return await RequestUtils.getArray(
      DiscountRes,
      `/discount/findAll/${companyId}${shopId ? `?shopId=${shopId}` : ""}`
    );
  }

  static async create(companyId: number, data: CreateDiscountReq) {
    return await RequestUtils.post(
      DiscountRes,
      `/discount/create/${companyId}`,
      data
    );
  }

  static async bulkInsert(companyId: number, data: CreateDiscountReq[]) {
    return await RequestUtils.postArray(
      DiscountRes,
      `/discount/bulkInsert/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    discountId: number,
    data: UpdateDiscountReq
  ): Promise<DiscountRes> {
    return await RequestUtils.post(
      DiscountRes,
      `/discount/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteDiscountReq) {
    return await RequestUtils.postVoid(`/discount/delete/${companyId}`, data);
  }
}
