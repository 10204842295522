import RequestUtils from "utils/RequestUtils";
import RecruitingMediaRes from "types/res/recruitingMedia/RecruitingMediaRes";
import CreateRecruitingMediaReq from "types/req/recruitingMedia/CreateRecruitingMediaReq";
import UpdateRecruitingMediaReq from "types/req/recruitingMedia/UpdateRecruitingMediaReq";
import DeleteRecruitingMediaReq from "types/req/recruitingMedia/DeleteRecruitingMediaReq";

export default class RecruitingMediaApi {
  static async findAll(companyId: number, shopIds?: number[]) {
    return await RequestUtils.getArray(
      RecruitingMediaRes,
      `/recruitingMedia/findAll/${companyId}${
        shopIds?.length ? `?shopIds=${shopIds.join(",")}` : ""
      }`
    );
  }
  static async create(companyId: number, req: CreateRecruitingMediaReq) {
    return await RequestUtils.post(
      RecruitingMediaRes,
      `/recruitingMedia/create/${companyId}`,
      {
        ...req,
      }
    );
  }
  static async update(companyId: number, req: UpdateRecruitingMediaReq) {
    return await RequestUtils.post(
      RecruitingMediaRes,
      `/recruitingMedia/update/${companyId}`,
      {
        ...req,
      }
    );
  }
  static async delete(companyId: number, req: DeleteRecruitingMediaReq) {
    return await RequestUtils.postArray(
      RecruitingMediaRes,
      `/recruitingMedia/delete/${companyId}`,
      { ...req }
    );
  }
}
