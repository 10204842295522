import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  createStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import OrderStatus from "types/enum/OrderStatus";
import EnumUtils from "utils/EnumUtils";
import StaffRole from "types/enum/StaffRole";
import { useDispatch, useSelector } from "react-redux";
import UpdateOrderReq from "types/req/order/UpdateOrderReq";
import { validate } from "class-validator";
import { addOrder, updateOrder } from "redux/actions/order";
import OrderRes from "types/res/order/OrderRes";
import CreateOrderReq from "types/req/order/CreateOrderReq";
import { DateTime } from "luxon";
import { useHistory, useParams } from "react-router-dom";
import OrderApi from "api/OrderApi";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { Alert, AlertTitle } from "@material-ui/lab";

type Props = {
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  formData: any;
  onChange: (key: string, value: any) => any;
  oldOrder: any;
};
const SpOrderFooter = ({
  pageNum,
  setPageNum,
  formData,
  onChange,
  oldOrder,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const staff = useSelector((state) => state.account.staff);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [duplicateOrder, setDuplicateOrder] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState(false);
  const [holdOrder, setHoldOrder] = useState<OrderRes>({} as OrderRes);

  const justifyContent = pageNum === 0 ? "flex-end" : "flex-start";

  useEffect(() => {
    if (duplicateMessage) {
      setDuplicateMessage(false);
      setDuplicateOrder(false);
    }
    if (
      !(
        formData?.status &&
        formData?.castNameId &&
        formData?.planInTime &&
        formData?.planOutTime
      )
    )
      return;
    if (
      EnumUtils.mapToEnum(OrderStatus, formData.status) !== OrderStatus.booking
    )
      return;
    setDuplicateOrder(true);
    const checkOrder = async () => {
      const result = await OrderApi.findHoldOrder(
        companyId,
        formData.castNameId,
        DateTime.fromJSDate(formData.planInTime).toFormat(
          FORMAT_TYPE.YEAR_DATE_TIME
        ),
        DateTime.fromJSDate(formData.planOutTime).toFormat(
          FORMAT_TYPE.YEAR_DATE_TIME
        )
      );
      if (result?.orderId && result?.orderId !== formData?.orderId) {
        setHoldOrder(result);
      } else {
        setDuplicateOrder(false);
      }
    };
    checkOrder();
  }, [formData?.status, formData?.castNameId, formData?.planInTime]);

  const onClickCreateOrder = async () => {
    if (
      EnumUtils.mapToEnum(OrderStatus, formData.status) === OrderStatus.paid
    ) {
      const result = window.confirm(
        "店舗マスター未満のアカウントでは完了にすると変更が出来ませんがよろしいでしょうか？"
      );
      if (!result) {
        return false;
      }
    }
    setErrorMessage("");
    if (formData?.orderId) {
      const data = new UpdateOrderReq({ ...formData });
      const errors = await validate(data);
      const errorMessage = errors
        .map((error) => {
          if (!error.constraints) return "";
          return Object.values(error.constraints);
        })
        .join("\n");
      if (errorMessage) {
        setErrorMessage(errorMessage);
        return;
      }
      const result = await dispatch(
        updateOrder(companyId, {
          ...data,
          orderId: formData.orderId,
          payoff: false,
        })
      );
      if (result instanceof OrderRes) {
        setIsSuccess(true);
        history.push("/orderList");
      }
    } else {
      const data = new CreateOrderReq({
        ...formData,
        status: formData?.status || "hold",
        paymentType: formData?.paymentType || "cash",
        orderDate:
          formData?.orderDate ||
          DateTime.local()
            .minus({
              hours: changeDate.hour,
              minutes: changeDate.minute,
            })
            .toJSDate(),
      });
      const errors = await validate(data);
      const errorMessage = errors
        .map((error) => {
          if (!error.constraints) return "";
          return Object.values(error.constraints);
        })
        .join("\n");
      if (errorMessage) {
        setErrorMessage(errorMessage);
        return;
      }
      const result = await dispatch(
        addOrder(companyId, { ...data, payoff: false })
      );
      if (result instanceof OrderRes) {
        setIsSuccess(true);
        history.push("/orderList");
      }
    }
  };
  return (
    <Box display="flex" justifyContent={justifyContent}>
      {pageNum === 0 && (
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          endIcon={<ArrowRightIcon />}
          onClick={() => setPageNum(1)}
        >
          受注情報へ
        </Button>
      )}
      {pageNum === 1 && (
        <Box display="flex" justifyContent="space-between" width={"100%"}>
          <Button
            variant="contained"
            className={classes.button}
            color="default"
            startIcon={<ArrowLeftIcon />}
            onClick={() => setPageNum(0)}
          >
            顧客情報へ
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            endIcon={<ArrowRightIcon />}
            onClick={() => setPageNum(2)}
          >
            配車・キャスト情報へ
          </Button>
        </Box>
      )}
      {pageNum === 2 && (
        <Box display="flex" justifyContent="space-between" width={"100%"}>
          <Button
            variant="contained"
            className={classes.button}
            color="default"
            startIcon={<ArrowLeftIcon />}
            onClick={() => setPageNum(1)}
          >
            受注情報へ
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            endIcon={<ArrowRightIcon />}
            onClick={() => setPageNum(3)}
          >
            料金詳細へ
          </Button>
        </Box>
      )}
      {pageNum === 3 && (
        <Box display="flex" justifyContent="space-between" width={"100%"}>
          <Button
            variant="contained"
            className={classes.button}
            color="default"
            startIcon={<ArrowLeftIcon />}
            onClick={() => setPageNum(2)}
          >
            配車・キャスト情報へ
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            endIcon={<ArrowRightIcon />}
            onClick={() => setPageNum(4)}
          >
            内容確認へ
          </Button>
        </Box>
      )}
      {pageNum === 4 && (
        <Box display="flex" flexDirection="column" width={"100%"} padding={1}>
          <Box display="flex" justifyContent="space-between" width={"100%"}>
            <Button
              variant="contained"
              className={classes.button}
              color="default"
              startIcon={<ArrowLeftIcon />}
              onClick={() => setPageNum(3)}
            >
              内容確認へ
            </Button>
            <Select
              onChange={(event) => onChange("status", event.target.value)}
              value={formData["status"] || "hold"}
              style={{ width: "120px" }}
            >
              {Object.entries(OrderStatus).map(([key, value]) => {
                return (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          {holdOrder?.orderId && (
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              style={{
                marginTop: "10px",
                padding: "10px",
                border: "1px solid black",
                borderRadius: "4px",
              }}
            >
              <Typography variant="body1">
                店舗名：{holdOrder?.shop?.name || "未設定"}
              </Typography>
              <Typography variant="body1">
                顧客名：{holdOrder?.guest?.name || "未設定"}
              </Typography>
              <Typography variant="body1">
                顧客電話番号：{holdOrder?.guest?.tel || "未設定"}
              </Typography>
              <Typography variant="body1">
                源氏名：{holdOrder?.castName?.name || "未設定"}
              </Typography>
              <Typography variant="body1">
                合計時間：{holdOrder?.totalTime || 0}分
              </Typography>
              <Typography variant="body1">
                住所・ホテル：
                {holdOrder?.orderAddress || holdOrder?.hotel?.name || "未設定"}
              </Typography>
              <Typography variant="body1">
                オプション：
                {holdOrder?.options?.map((option) => option.name).join("/") ||
                  "未設定"}
              </Typography>
              <Typography variant="body1">
                出発時間：
                {holdOrder?.departureTime
                  ? DateTimeUtils.toFormatAsLocalTimezone(
                      holdOrder.departureTime,
                      FORMAT_TYPE.YEAR_DATE_TIME
                    )
                  : "未設定"}
              </Typography>
              <Typography variant="body1">
                予定IN：
                {holdOrder?.planInTime
                  ? DateTimeUtils.toFormatAsLocalTimezone(
                      holdOrder.planInTime,
                      FORMAT_TYPE.YEAR_DATE_TIME
                    )
                  : "未設定"}
              </Typography>
              <Typography variant="body1">
                出発時間：
                {holdOrder?.planOutTime
                  ? DateTimeUtils.toFormatAsLocalTimezone(
                      holdOrder.planOutTime,
                      FORMAT_TYPE.YEAR_DATE_TIME
                    )
                  : "未設定"}
              </Typography>
              <Typography variant="body1" style={{ marginTop: "20px" }}>
                上記の保留中の予約をキャンセルしますか？
              </Typography>
              <Box display="flex">
                <Button
                  className={classes.button}
                  color="default"
                  variant="contained"
                  onClick={() => {
                    setHoldOrder({} as OrderRes);
                    setDuplicateMessage(true);
                  }}
                >
                  いいえ
                </Button>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={async () => {
                    await OrderApi.cancel(companyId, {
                      orderId: holdOrder?.orderId,
                    });
                    setHoldOrder({} as OrderRes);
                    setDuplicateOrder(false);
                  }}
                >
                  はい
                </Button>
              </Box>
            </Box>
          )}
          {duplicateMessage && (
            <Alert severity="error">
              <AlertTitle>
                予約が重複しているので、キャストもしくは予定IN時間の変更をお願いします。
              </AlertTitle>
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error">
              <AlertTitle>エラーがあります</AlertTitle>
            </Alert>
          )}
          {isSuccess && (
            <Alert severity="success">
              <AlertTitle>保存しました</AlertTitle>
            </Alert>
          )}
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={onClickCreateOrder}
            disabled={
              (EnumUtils.mapToEnum(OrderStatus, oldOrder?.status) ===
                OrderStatus.paid &&
                !!orderId &&
                ![
                  StaffRole.notelMaster,
                  StaffRole.notelCallCenterPartTime,
                  StaffRole.notelCallCenterEmployee,
                  StaffRole.clientMaster,
                  StaffRole.clientShopMaster,
                ].includes(
                  EnumUtils.mapToEnum(StaffRole, staff.role) ||
                    StaffRole.clientShopPartTime
                )) ||
              duplicateOrder
            }
          >
            保存
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SpOrderFooter;
