import { DataValidationError } from "constants/DataValidationError";
import {
  IsEmail,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";
import StaffRole from "types/enum/StaffRole";

export default class UpdateStaffReq {
  @IsNotEmpty({
    message: "",
  })
  readonly staffId: number;

  @MaxLength(Spec.maxLength.staff.email, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.staff.email,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsEmail(
    {},
    {
      message: DataValidationError.DATA_IS_INVALID,
    }
  )
  email!: string;

  @MaxLength(Spec.maxLength.staff.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.staff.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.staff.tel, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.staff.tel,
    },
  })
  tel!: string | null;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  role!: StaffRole;

  @IsOptional()
  @IsNumber()
  sort!: number | null;

  @IsOptional()
  companyId?: number;

  @IsOptional()
  @IsString()
  isForceSite!: string | null;

  constructor(arg: {
    staffId: number;
    name: string;
    email: string;
    role: StaffRole;
    tel: string | null;
    companyId?: number;
    sort: number | null;
    isForceSite: string | null;
  }) {
    arg = arg || {};
    this.staffId = arg.staffId;
    this.name = arg.name;
    this.email = arg.email;
    this.role = arg.role;
    this.tel = arg.tel;
    this.companyId = arg.companyId;
    this.sort = arg.sort;
    this.isForceSite = arg.isForceSite;
  }
}
