import React from "react";
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import OrderStatus from "types/enum/OrderStatus";
import EnumUtils from "utils/EnumUtils";
import StaffRole from "types/enum/StaffRole";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import OrderRes from "types/res/order/OrderRes";
import OrderApi from "api/OrderApi";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSelector } from "react-redux";

type Props = {
  formData: any;
  onChangeForm: (key: string) => any;
  onClickCreateOrder: any;
  orderId: any;
  oldOrder: any;
  duplicateOrder: any;
  holdOrder: OrderRes;
  setHoldOrder: any;
  setDuplicateMessage: any;
  errorMessage: any;
  isSuccess: boolean;
  duplicateMessage: any;
  setDuplicateOrder: any;
};
const OrderSubmit: React.FC<Props> = ({
  formData,
  onChangeForm,
  onClickCreateOrder,
  orderId,
  oldOrder,
  duplicateOrder,
  holdOrder,
  setHoldOrder,
  setDuplicateMessage,
  errorMessage,
  isSuccess,
  duplicateMessage,
  setDuplicateOrder,
}) => {
  const companyId = useSelector((state) => state.account.staff.companyId);
  const staff = useSelector((state) => state.account.staff);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box height={350}>
          {holdOrder?.orderId && (
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              style={{
                padding: "10px",
                border: "1px solid black",
                borderRadius: "4px",
                width: "400px",
              }}
            >
              <Typography variant="body1">
                店舗名：{holdOrder?.shop?.name || "未設定"}
              </Typography>
              <Typography variant="body1">
                顧客名：{holdOrder?.guest?.name || "未設定"}
              </Typography>
              <Typography variant="body1">
                顧客電話番号：{holdOrder?.guest?.tel || "未設定"}
              </Typography>
              <Typography variant="body1">
                源氏名：{holdOrder?.castName?.name || "未設定"}
              </Typography>
              <Typography variant="body1">
                合計時間：{holdOrder?.totalTime || 0}分
              </Typography>
              <Typography variant="body1">
                住所・ホテル：
                {holdOrder?.orderAddress || holdOrder?.hotel?.name || "未設定"}
              </Typography>
              <Typography variant="body1">
                オプション：
                {holdOrder?.options?.map((option) => option.name).join("/")}
              </Typography>
              <Typography variant="body1">
                出発時間：
                {holdOrder?.departureTime
                  ? DateTimeUtils.toFormatAsLocalTimezone(
                      holdOrder.departureTime,
                      FORMAT_TYPE.YEAR_DATE_TIME
                    )
                  : "未設定"}
              </Typography>
              <Typography variant="body1">
                予定IN：
                {holdOrder?.planInTime
                  ? DateTimeUtils.toFormatAsLocalTimezone(
                      holdOrder.planInTime,
                      FORMAT_TYPE.YEAR_DATE_TIME
                    )
                  : "未設定"}
              </Typography>
              <Typography variant="body1">
                出発時間：
                {holdOrder?.planOutTime
                  ? DateTimeUtils.toFormatAsLocalTimezone(
                      holdOrder.planOutTime,
                      FORMAT_TYPE.YEAR_DATE_TIME
                    )
                  : "未設定"}
              </Typography>
              <Typography variant="body1" style={{ marginTop: "20px" }}>
                上記の保留中の予約をキャンセルしますか？
              </Typography>
              <Box display="flex">
                <Button
                  className={classes.button}
                  color="default"
                  variant="contained"
                  onClick={() => {
                    setHoldOrder({} as OrderRes);
                    setDuplicateMessage(true);
                  }}
                >
                  いいえ
                </Button>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={async () => {
                    await OrderApi.cancel(companyId, {
                      orderId: holdOrder?.orderId,
                    });
                    setHoldOrder({} as OrderRes);
                    setDuplicateOrder(false);
                  }}
                >
                  はい
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="center">
          <Select
            onChange={onChangeForm("status")}
            value={formData["status"] || "hold"}
          >
            {Object.entries(OrderStatus).map(([key, value]) => {
              return (
                <MenuItem value={key} key={key}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={onClickCreateOrder}
            disabled={
              (EnumUtils.mapToEnum(OrderStatus, oldOrder?.status) ===
                OrderStatus.paid &&
                !!orderId &&
                ![
                  StaffRole.notelMaster,
                  StaffRole.notelCallCenterPartTime,
                  StaffRole.notelCallCenterEmployee,
                  StaffRole.clientMaster,
                  StaffRole.clientShopMaster,
                ].includes(
                  EnumUtils.mapToEnum(StaffRole, staff.role) ||
                    StaffRole.clientShopPartTime
                )) ||
              duplicateOrder
            }
          >
            保存
          </Button>
        </Box>
      </Box>
      {duplicateMessage && (
        <Alert severity="error">
          <AlertTitle>
            予約が重複しているので、キャストもしくは予定IN時間の変更をお願いします。
          </AlertTitle>
        </Alert>
      )}
      {errorMessage && (
        <Alert severity="error">
          <AlertTitle>エラーがあります</AlertTitle>
        </Alert>
      )}
      {isSuccess && (
        <Alert severity="success">
          <AlertTitle>保存しました</AlertTitle>
        </Alert>
      )}
    </>
  );
};
export default OrderSubmit;
