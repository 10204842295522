import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty, IsOptional, MaxLength } from "class-validator";
import Spec from "constants/Spec";

export default class UpdateNotelClassReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly notelClassId: number;

  @MaxLength(Spec.maxLength.notelClass.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.notelClass.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @IsOptional()
  @MaxLength(Spec.maxLength.notelClass.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.notelClass.name,
    },
  })
  kana!: string;
  constructor(arg: { notelClassId: number; name: string; kana: string }) {
    arg = arg || {};
    this.notelClassId = arg.notelClassId;
    this.name = arg.name;
    this.kana = arg.kana;
  }
}
