import {
  Box,
  Button,
  createStyles,
  Link,
  TextField,
  Theme,
  Typography,
  MenuItem,
  Select,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GuestRes from "types/res/guest/GuestRes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { addGuest, updateGuest } from "redux/actions/guest";
import UpdateGuestReq from "../../../types/req/guest/UpdateGuestReq";
import { validate } from "class-validator";
import { Alert, AlertTitle } from "@material-ui/lab";
import CreateGuestReq from "types/req/guest/CreateGuestReq";
import { simpleFetchGuestCategory } from "redux/actions/guestCategory";
import GuestCategoryRes from "types/res/guestCategory/GuestCategoryRes";
import OrderApi from "api/OrderApi";
import GuestApi from "api/GuestApi";
import EnumUtils from "utils/EnumUtils";
import GuestGenreType from "types/enum/GuestGenreType";
import GuestAnalyticsGroupType from "types/enum/GuestAnalyticsGroupType";

type Props = {
  onChange(key: string): any;
  onChangeForm(key: string): any;
  formData: any;
};
const SearchGuest: React.FC<Props> = ({ onChange, onChangeForm, formData }) => {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.account.staff.company);
  const companyId = useSelector((state) => state.account.staff.companyId);
  const guestCategory = useSelector((state) => state.guestCategory);
  const shops = useSelector((state) => state.shop);
  const castShifts = useSelector((state) => state.castShift);
  const [searchTel, setSearchTel] = useState("");
  const [searchName, setSearchName] = useState("");
  const [selectGuest, setSelectGuest] = useState<any>({});
  const [updateRemarks, setUpdateRemarks] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);
  const [guests, setGuests] = useState<GuestRes[]>([]);
  const [filterGuestCategories, setFilterGuestCategories] = useState<
    GuestCategoryRes[]
  >([]);
  const [selectGuestCategoryId, setSelectGuestCategoryId] = useState<number>();
  const [open, setOpen] = useState(true);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  useEffect(() => {
    dispatch(simpleFetchGuestCategory(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (formData?.guest) {
      setSelectGuest(formData?.guest);
      setSelectGuestCategoryId(formData?.guest?.guestCategoryId);
    }
    if (!formData?.guestId) return;
    const apiCall = async () => {
      const result = await GuestApi.findOne(companyId, formData?.guestId);
      setSelectGuest(result);
      setUpdateRemarks(result?.memo || "");
    };
    apiCall();
  }, [formData?.guestId, formData?.guest]);

  useEffect(() => {
    setFilterGuestCategories(
      formData?.shopId
        ? guestCategory.filter((guestCategory) =>
            guestCategory.shops
              .map((shop) => shop.shopId)
              .includes(formData.shopId)
          )
        : guestCategory
    );
  }, [formData.shopId, guestCategory]);

  useEffect(() => {
    if (!selectGuest.guestId) {
      return setSelectGuestCategoryId(undefined);
    }

    if (company.guestCategoryShopFlag) {
      if (!formData?.shopId) return;
      const fetch = async () => {
        const result = await OrderApi.countOrder(
          companyId,
          selectGuest.guestId,
          formData.shopId,
          formData.orderId
        );
        if (result === 0) {
          setSelectGuestCategoryId(
            (prev) =>
              filterGuestCategories?.find(
                (guestCategory: any) =>
                  guestCategory?.shops
                    ?.map((shop: any) => shop.shopId)
                    .includes(formData?.shopId) && guestCategory.unUseUserFlag
              )?.guestCategoryId || prev
          );
        } else {
          setSelectGuestCategoryId(
            filterGuestCategories.find(
              (guestCategory: any) =>
                guestCategory?.shops
                  ?.map((shop: any) => shop.shopId)
                  .includes(formData?.shopId) && guestCategory.unUseUserFlag
            )?.guestCategoryId ===
              selectGuest?.guestGuestCategoryShops?.find(
                (guestGuestCategoryShop: any) =>
                  guestGuestCategoryShop?.shopId === formData?.shopId
              )?.guestCategoryId
              ? filterGuestCategories.find(
                  (guestCategory: any) =>
                    guestCategory?.shops
                      ?.map((shop: any) => shop.shopId)
                      .includes(formData?.shopId) &&
                    guestCategory.secondUserFlag
                )?.guestCategoryId
              : selectGuest?.guestGuestCategoryShops?.find(
                  (guestGuestCategoryShop: any) =>
                    guestGuestCategoryShop.shopId === formData?.shopId
                )?.guestCategoryId
          );
        }
      };
      fetch();
    } else {
      const fetch = async () => {
        const result = await OrderApi.countOrder(
          companyId,
          selectGuest.guestId,
          undefined,
          formData.orderId
        );
        if (result === 0) {
          setSelectGuestCategoryId(
            (prev) =>
              filterGuestCategories?.find(
                (guestCategory: any) => guestCategory.unUseUserFlag
              )?.guestCategoryId || prev
          );
        } else {
          setSelectGuestCategoryId((prev) =>
            filterGuestCategories.find(
              (guestCategory: any) => guestCategory.unUseUserFlag
            )?.guestCategoryId === selectGuest?.guestCategoryId
              ? filterGuestCategories.find(
                  (guestCategory: any) => guestCategory.secondUserFlag
                )?.guestCategoryId
              : selectGuest?.guestCategoryId
          );
        }
      };
      fetch();
    }
  }, [selectGuest, formData.shopId, formData?.guestId]);

  useEffect(() => {
    onChange("guestCategoryId")(
      selectGuestCategoryId || selectGuest?.guestCategoryId
    );
  }, [selectGuestCategoryId, selectGuest]);

  const onChangeGuest =
    (key: string, handler: (value: any) => any = (value: any) => value) =>
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | { value: unknown }
      >
    ) => {
      event.persist();
      setSelectGuest((prev: any) => ({
        ...prev,
        [key]: handler(event.target.value),
      }));
    };

  const onBlurTel = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: unknown }
    >
  ) => {
    event.persist();
    setSelectGuest((prev: any) => ({
      ...prev,
      tel: (event.target.value as string).replace(/[-‐－―ー−]/g, ""),
    }));
  };
  const onClickSaveGuest = async () => {
    if (!formData?.shopId) {
      return alert("店舗を選択してください。");
    }
    const guestCategoryConfig = company.guestCategoryShopFlag
      ? {
          guestGuestCategoryShops: [
            {
              shopId: formData.shopId,
              guestId: formData.guestId,
              guestCategoryId:
                selectGuestCategoryId || selectGuest.guestCategoryId,
            },
          ],
        }
      : {
          guestCategoryId: selectGuestCategoryId || selectGuest.guestCategoryId,
        };
    const data = selectGuest?.guestId
      ? new UpdateGuestReq({
          ...selectGuest,
          ...guestCategoryConfig,
        })
      : new CreateGuestReq({
          ...selectGuest,
          ...guestCategoryConfig,
        });
    const errors = await validate(data);
    if (errors.length) {
      return setErrors(errors);
    }
    const result =
      data instanceof UpdateGuestReq
        ? await dispatch(updateGuest(companyId, data))
        : await dispatch(addGuest(companyId, data));
    if (result instanceof GuestRes) {
      setIsSuccess(true);
      setTimeout(function () {
        setIsSuccess(false);
      }, 3000);
      formData.guest = result;
      formData.guestId = result.guestId;
      formData = { ...formData, ...guestCategoryConfig };
    }
  };

  const isNgGuest = () => {
    if (company.guestCategoryShopFlag) {
      return (
        selectGuest?.guestGuestCategoryShops?.find(
          (guestGuestCategoryShop: any) =>
            guestGuestCategoryShop.guestId === formData.guestId &&
            guestGuestCategoryShop.shopId === formData.shopId
        )?.guestCategory?.name === "NG" ||
        castShifts
          ?.find((shift) =>
            shift?.cast?.castNames
              ?.map((castName) => castName.castNameId)
              .includes(formData.castNameId)
          )
          ?.cast?.ngGuests?.some(
            (ngGuest) => ngGuest.guestId === selectGuest.guestId
          )
      );
    } else {
      return (
        selectGuest?.guestCategory?.name === "NG" ||
        castShifts
          ?.find((shift) =>
            shift?.cast?.castNames
              ?.map((castName) => castName.castNameId)
              .includes(formData.castNameId)
          )
          ?.cast?.ngGuests?.some(
            (ngGuest) => ngGuest.guestId === selectGuest.guestId
          )
      );
    }
  };
  return (
    <Box display="flex" flexDirection="column">
      <Box display={"flex"} flexDirection={"column"}>
        <Typography variant="h5" component="h5">
          店舗選択
        </Typography>
        <Box display="flex" marginBottom={2}>
          <Select
            inputProps={{
              name: "shop",
              id: "shop",
            }}
            style={{ width: 100, margin: 5 }}
            value={formData?.shopId || ""}
            onChange={onChangeForm("shopId")}
          >
            <option aria-label="None" value="" />
            {shops?.map((shop) => (
              <MenuItem value={shop.shopId} key={shop.shopId}>
                {shop.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Typography variant="h5" component="h5">
          顧客検索
        </Typography>
        <Box display="flex" alignItems="center">
          <TextField
            style={{ width: "250px" }}
            value={searchTel}
            onChange={(event) => setSearchTel(event.target.value)}
            placeholder={"電話番号検索"}
          />
          <TextField
            style={{ margin: "0 10px", width: "250px" }}
            value={searchName}
            onChange={(event) => setSearchName(event.target.value)}
            placeholder={"名前検索"}
          />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={async () => {
                setSelectGuest({});
                setGuests([]);
                setOpen(true);
                const result = await GuestApi.search(companyId, {
                  tel: searchTel,
                  name: searchName,
                });
                setGuests(result);
              }}
            >
              検索
            </Button>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" marginTop={2}>
          <Typography variant="h5" component="h5">
            顧客選択
          </Typography>
          <Box display="flex" alignItems="center">
            {guests.length ? (
              <Autocomplete
                id="search-guest"
                size="small"
                options={guests}
                getOptionLabel={(option) =>
                  option.name ? `${option.name}(${option.tel})` : ""
                }
                style={{ width: 500, margin: 5 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus={true}
                    style={{
                      backgroundColor: isNgGuest() ? "red" : "initial",
                    }}
                  />
                )}
                value={selectGuest || null}
                onChange={(event: any, newValue: GuestRes | null) => {
                  onChange("guestId")(newValue?.guestId);
                  onChange("guest")(newValue);
                }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                noOptionsText={"電話番号か名前で検索してください。"}
                renderOption={(option) => (
                  <div
                    style={{
                      backgroundColor: isNgGuest() ? "red" : "initial",
                    }}
                  >
                    {option.name ? `${option.name}(${option.tel})` : ""}
                  </div>
                )}
              />
            ) : (
              <Typography>
                該当する顧客が見つかりませんでした。電話番号か名前で検索してください。
              </Typography>
            )}
            {Object.keys(selectGuest).length > 0 && (
              <Link
                href={`/orderList/guest/${selectGuest.guestId}`}
                target="_blank"
              >
                履歴確認
              </Link>
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" marginTop={2}>
          <Typography variant="h5" component="h5">
            顧客情報
          </Typography>
          <TextField
            label="電話番号"
            size="small"
            variant="outlined"
            value={selectGuest?.tel || ""}
            onChange={onChangeGuest("tel")}
            onBlur={onBlurTel}
            autoComplete="none"
            style={{ margin: 5 }}
            error={errors?.map((error) => error.property).includes("tel")}
            helperText={Object.values(
              errors.find((error) => error.property === "tel")?.constraints ||
                {}
            ).join(" ")}
          />
          <TextField
            label="名前"
            size="small"
            variant="outlined"
            value={selectGuest?.name || ""}
            onChange={onChangeGuest("name")}
            autoComplete="none"
            style={{ margin: 5 }}
            error={errors?.map((error) => error.property).includes("name")}
            helperText={Object.values(
              errors.find((error) => error.property === "name")?.constraints ||
                {}
            ).join(" ")}
          />
          <TextField
            label="住所"
            variant="outlined"
            size="small"
            value={selectGuest?.address || ""}
            onChange={onChangeGuest("address")}
            autoComplete="none"
            style={{ margin: 5 }}
            error={errors?.map((error) => error.property).includes("address")}
            helperText={Object.values(
              errors.find((error) => error.property === "address")
                ?.constraints || {}
            ).join(" ")}
          />
          <TextField
            label="メールアドレス"
            size="small"
            variant="outlined"
            autoComplete="none"
            value={selectGuest?.email || ""}
            onChange={onChangeGuest("email")}
            style={{ margin: 5 }}
            error={errors?.map((error) => error.property).includes("email")}
            helperText={Object.values(
              errors.find((error) => error.property === "email")?.constraints ||
                {}
            ).join(" ")}
          />
          <TextField
            select
            label="顧客カテゴリ"
            size="small"
            variant="outlined"
            autoComplete="none"
            value={selectGuestCategoryId || selectGuest.guestCategoryId || ""}
            onChange={(event) => {
              setSelectGuestCategoryId(Number(event.target.value));
            }}
            style={{
              margin: 5,
              backgroundColor: isNgGuest() ? "red" : "initial",
            }}
            error={errors
              ?.map((error) => error.property)
              .includes("guestCategories")}
            helperText={Object.values(
              errors.find((error) => error.property === "guestCategories")
                ?.constraints || {}
            ).join(" ")}
          >
            {filterGuestCategories.map((category) => (
              <MenuItem
                value={category.guestCategoryId}
                key={category.guestCategoryId}
              >
                {category.name}
              </MenuItem>
            ))}
          </TextField>
          {selectGuest?.guestAnalyticsStatus && (
            <Box
              marginY={1}
              marginLeft={"15px"}
              display="flex"
              flexDirection="column"
            >
              <Typography style={{ fontWeight: "bold" }}>
                顧客CPMステータス
              </Typography>
              <Box display="flex" style={{ marginTop: "5px" }}>
                <Typography>
                  {selectGuest?.guestAnalyticsStatus?.guestGroup
                    ? EnumUtils.mapToEnum(
                        GuestAnalyticsGroupType,
                        selectGuest?.guestAnalyticsStatus?.guestGroup
                      )
                    : ""}
                </Typography>
                <Typography style={{ marginLeft: "10px" }}>
                  {selectGuest?.guestAnalyticsStatus?.genre
                    ? EnumUtils.mapToEnum(
                        GuestGenreType,
                        selectGuest?.guestAnalyticsStatus?.genre
                      )
                    : ""}
                </Typography>
                <Typography style={{ marginLeft: "10px" }}>
                  {selectGuest?.guestAnalyticsStatus
                    ? `${selectGuest?.guestAnalyticsStatus?.awayDays || 0}日`
                    : ""}
                </Typography>
              </Box>
            </Box>
          )}
          <TextField
            multiline
            size="small"
            autoComplete="none"
            variant="outlined"
            value={updateRemarks || ""}
            onChange={(event) => setUpdateRemarks(event.target.value)}
            onBlur={onChangeGuest("memo")}
            style={{ margin: 5 }}
            rows={8}
            rowsMax={1000}
            error={errors?.map((error) => error.property).includes("memo")}
            helperText={Object.values(
              errors.find((error) => error.property === "memo")?.constraints ||
                {}
            ).join(" ")}
          />
          <div style={{ margin: "0 auto" }}>
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={onClickSaveGuest}
            >
              {selectGuest?.guestId ? "顧客情報を更新" : "顧客情報を登録"}
            </Button>
          </div>
          {isSuccess && (
            <Alert severity="success">
              <AlertTitle>
                {selectGuest?.guestId
                  ? "顧客情報を更新しました"
                  : "顧客情報を登録しました"}
              </AlertTitle>
            </Alert>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchGuest;
