import RequestUtils from "utils/RequestUtils";
import OrderRes from "types/res/order/OrderRes";
import CreateOrderReq from "types/req/order/CreateOrderReq";
import UpdateOrderReq from "types/req/order/UpdateOrderReq";
import DeleteOrderReq from "types/req/order/DeleteOrderReq";
import querystring from "querystring";
import CancelOrderReq from "../types/req/order/CancelOrderReq";
import AssignOrderDriverReq from "types/req/order/AssignOrderDriverReq";
import FindSpOrderReq from "types/req/order/FindSpOrderReq";

export default class OrderApi {
  private constructor() {}

  static async findCti(
    companyId: number,
    acd: string,
    tel: string
  ): Promise<OrderRes> {
    return await RequestUtils.get(
      OrderRes,
      `/order/findCti/${companyId}/${tel}?acd=${acd}`
    );
  }

  static async findOne(companyId: number, orderId?: number): Promise<OrderRes> {
    return await RequestUtils.get(
      OrderRes,
      `/order/findOne/${companyId}/${orderId}`
    );
  }

  static async findAll(
    companyId: number,
    startDate: string,
    endDate: string
  ): Promise<OrderRes[]> {
    return await RequestUtils.getArray(
      OrderRes,
      `/order/findAll/${companyId}/${startDate}/${endDate}`
    );
  }

  static async findAllByGuestId(
    companyId: number,
    guestId: number
  ): Promise<OrderRes[]> {
    return await RequestUtils.getArray(
      OrderRes,
      `/order/findAllByGuestId/${companyId}/${guestId}`
    );
  }

  static async findAllWithPagination(
    companyId: number,
    limit: number,
    offset: number,
    startDate: string,
    endDate: string,
    status: string,
    shopIds: string,
    includeUnconfirmedOrder: boolean
  ): Promise<OrderRes[]> {
    const query = querystring.stringify({
      startDate,
      endDate,
      limit,
      offset,
      status,
      shopIds,
      includeUnconfirmedOrder,
    });
    return await RequestUtils.getArray(
      OrderRes,
      `/order/findAllWithPagination/${companyId}${query ? `?${query}` : ""}`
    );
  }

  static async count(
    companyId: number,
    guestId?: number,
    startDate?: string,
    endDate?: string,
    status?: string,
    shopIds?: string,
    includeUnconfirmedOrder?: boolean
  ) {
    const query = querystring.stringify({
      guestId,
      startDate,
      endDate,
      status,
      shopIds,
      includeUnconfirmedOrder,
    });

    return await RequestUtils.count(`/order/count/${companyId}?${query}`);
  }

  static async findByCastWithPagination(
    companyId: number,
    limit: number,
    offset: number,
    castId: number
  ): Promise<OrderRes[]> {
    const query = querystring.stringify({
      limit,
      offset,
    });
    return await RequestUtils.getArray(
      OrderRes,
      `/order/findByCastWithPagination/${companyId}/${castId}/${
        query ? `?${query}` : ""
      }`
    );
  }

  static async countByCast(companyId: number, castId: number) {
    return await RequestUtils.count(
      `/order/countByCast/${companyId}/${castId}`
    );
  }

  static async findCastRequest(companyId: number) {
    return await RequestUtils.getArray(
      OrderRes,
      `/order/findCastRequest/${companyId}`
    );
  }

  static async findReOrder(
    companyId: number,
    guestId: number,
    castNameId: number
  ) {
    return await RequestUtils.get(
      OrderRes,
      `/order/findReOrder/${companyId}/${guestId}/${castNameId}`
    );
  }

  static async findHoldOrder(
    companyId: number,
    castNameId: number,
    planInTime: string,
    planOutTime: string
  ) {
    return await RequestUtils.get(
      OrderRes,
      `/order/findHoldOrder/${companyId}/${castNameId}/${planInTime}/${planOutTime}`
    );
  }

  static async countOrder(
    companyId: number,
    guestId: number,
    shopId?: number,
    orderId?: number
  ) {
    const query = querystring.stringify({ shopId, orderId });
    return await RequestUtils.count(
      `/order/countOrder/${companyId}/${guestId}${query ? `?${query}` : ""}`
    );
  }

  static async saleAndCountForBookingAndPaid(
    companyId: number,
    startDate: string,
    endDate: string
  ) {
    return await RequestUtils.getPlain(
      `/order/saleAndCountForBookingAndPaid/${companyId}/${startDate}/${endDate}`
    );
  }

  static async findSpAll(companyId: number, req: FindSpOrderReq) {
    return await RequestUtils.postArray(
      OrderRes,
      `/order/findSpAll/${companyId}`,
      req
    );
  }

  static async create(companyId: number, data: CreateOrderReq) {
    return await RequestUtils.post(
      OrderRes,
      `/order/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    data: UpdateOrderReq
  ): Promise<OrderRes> {
    return await RequestUtils.post(
      OrderRes,
      `/order/update/${companyId}`,
      data
    );
  }

  static async assignOrderDriver(
    companyId: number,
    data: AssignOrderDriverReq
  ): Promise<OrderRes> {
    return await RequestUtils.post(
      OrderRes,
      `/order/assignOrderDriver/${companyId}`,
      data
    );
  }

  static async cancel(companyId: number, data: CancelOrderReq) {
    return await RequestUtils.post(
      OrderRes,
      `/order/cancel/${companyId}`,
      data
    );
  }

  static async bulkInsert(companyId: number, data: CreateOrderReq[]) {
    return await RequestUtils.postArray(
      OrderRes,
      `/order/bulkInsert/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteOrderReq) {
    return await RequestUtils.postVoid(`/order/delete/${companyId}`, data);
  }

  static async bulkDelete(companyId: number, data: DeleteOrderReq[]) {
    return await RequestUtils.postVoid(`/order/bulkDelete/${companyId}`, data);
  }

  static async sendMailCast(companyId: number, orderId: number) {
    return await RequestUtils.postVoid(
      `/order/sendMailCast/${companyId}/${orderId}`,
      {}
    );
  }
}
