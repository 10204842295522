import { Exclude, Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import AreaRes from "../area/AreaRes";
import GuestRes from "../guest/GuestRes";
import CastNameRes from "../castName/CastNameRes";
import CastCategoryRes from "../castCategory/CastCategoryRes";
import CompanyRes from "types/res/company/CompanyRes";
import CastShiftRes from "types/res/castShift/CastShiftRes";
import CastExaminationRes from "types/res/castExamination/CastExaminationRes";
import RecruitingMediaRes from "types/res/recruitingMedia/RecruitingMediaRes";

@Exclude()
export default class CastRes {
  @Expose() readonly castId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly email!: string;
  @Expose() readonly activeEmail!: string | null;
  @Expose() readonly tel!: string;
  @Expose() readonly name!: string;
  @Expose() readonly displayName!: string;
  @Expose() readonly address!: string;
  @Expose() readonly remarks!: string;
  @Expose() readonly sort!: number | null;
  @Type(() => Date)
  @Expose()
  readonly entryDate!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly closingDate!: Date | null;
  @Type(() => Number)
  @Expose()
  readonly invoiceRate!: number | null;
  @Expose() readonly recruitingMediaId!: number | null;
  @Type(() => RecruitingMediaRes)
  @Expose()
  readonly recruitingMedia!: RecruitingMediaRes | null;
  @Expose() readonly castCategoryId!: number;
  @Type(() => CastCategoryRes)
  @Expose()
  readonly castCategory!: CastCategoryRes;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
  @Type(() => AreaRes)
  @Expose()
  readonly ngAreas!: AreaRes[];
  @Type(() => GuestRes)
  @Expose()
  readonly ngGuests!: GuestRes[];
  @Type(() => CastNameRes)
  @Expose()
  readonly castNames!: CastNameRes[];
  @Type(() => CompanyRes)
  @Expose()
  readonly company!: CompanyRes;
  @Type(() => CastShiftRes)
  @Expose()
  readonly castShifts!: CastShiftRes[];
  @Type(() => CastExaminationRes)
  readonly castExaminations!: CastExaminationRes[];
}
