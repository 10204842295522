import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty } from "class-validator";

export default class DeleteAdditionalTimeReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly additionalTimeId: number;

  constructor(arg: { additionalTimeId: number }) {
    arg = arg || {};
    this.additionalTimeId = arg.additionalTimeId;
  }
}
