import { IsNotEmpty } from "class-validator";

export default class DeleteCompanyReq {
  @IsNotEmpty({
    message: "",
  })
  readonly companyId: number;

  constructor(arg: { companyId: number }) {
    arg = arg || {};
    this.companyId = arg.companyId;
  }
}
