import { reducerWithInitialState } from "typescript-fsa-reducers";
import hotelActions from "redux/actions/hotel";
import HotelRes from "types/res/hotel/HotelRes";
export type HotelState = HotelRes[];

const initialState = [] as HotelState;

const hotelReducer = reducerWithInitialState(initialState)
  .case(hotelActions.addHotelAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(hotelActions.updateHotelAction.done, (state, payload) => {
    return state.map((item) =>
      item.hotelId === payload.params.hotelId ? payload.result : item
    );
  })
  .case(hotelActions.deleteHotelAction.done, (state, payload) => {
    return state.filter((item) => item.hotelId !== payload.params.hotelId);
  })
  .case(hotelActions.fetchHotelAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(hotelActions.bulkInsertHotelAction.done, (state, payload) => {
    return [...state, ...payload.result];
  });
export default hotelReducer;
