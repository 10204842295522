import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import useModal from "hooks/useModal";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ShiftStatus from "types/enum/ShiftStatus";
import CreateDriverShiftReq from "types/req/driverShift/CreateDriverShiftReq";
import DriverShiftApi from "api/DriverShiftApi";
import UpdateDriverShiftReq from "types/req/driverShift/UpdateDriverShiftReq";

const Label = styled(Typography)`
  width: 40%;
`;

type Props = {
  apiCall: any;
};

const SpDriverShiftModal = ({ apiCall }: Props) => {
  const [spDriverShiftModal, setSpDriverShiftModal] =
    useModal("spDriverShiftModal");
  const [driverShiftReq, setDriverShiftReq] = useState<any>({});
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const minutes = ["00", "15", "30", "45"];
  const hours = [...Array(24 + changeDate.hour)].map((_, i) =>
    String(i).padStart(2, "0")
  );

  useEffect(() => {
    if (!spDriverShiftModal.meta?.driver?.driverShifts?.length) {
      return setDriverShiftReq({ status: "unconfirmed" });
    }
    const baseDate = DateTime.fromJSDate(spDriverShiftModal.meta.date);
    const driverShift = spDriverShiftModal.meta.driver.driverShifts[0];
    const planWorkStart = DateTime.fromJSDate(driverShift.planWorkStart);
    const planWorkEnd = DateTime.fromJSDate(driverShift.planWorkEnd);
    const actualWorkStart = driverShift.actualWorkStart
      ? DateTime.fromJSDate(driverShift.actualWorkStart)
      : undefined;
    const actualWorkEnd = driverShift.actualWorkEnd
      ? DateTime.fromJSDate(driverShift.actualWorkEnd)
      : undefined;

    const workOffStart = driverShift.workOffStart
      ? DateTime.fromJSDate(driverShift.workOffStart)
      : undefined;
    const workOffEnd = driverShift.workOffEnd
      ? DateTime.fromJSDate(driverShift.workOffEnd)
      : undefined;
    setDriverShiftReq({
      planWorkStartHour: String(
        planWorkStart.diff(baseDate, "days").days < 1
          ? planWorkStart.hour
          : planWorkStart.hour + 24
      ).padStart(2, "0"),
      planWorkStartMinute: String(planWorkStart.minute).padStart(2, "0"),
      planWorkEndHour: String(
        planWorkEnd.diff(baseDate, "days").days < 1
          ? planWorkEnd.hour
          : planWorkEnd.hour + 24
      ).padStart(2, "0"),
      planWorkEndMinute: String(planWorkEnd.minute).padStart(2, "0"),
      actualWorkStartHour: actualWorkStart
        ? String(
            actualWorkStart.diff(baseDate, "days").days < 1
              ? actualWorkStart.hour
              : actualWorkStart.hour + 24
          ).padStart(2, "0")
        : "",
      actualWorkStartMinute: actualWorkStart
        ? String(actualWorkStart.minute).padStart(2, "0")
        : "",
      actualWorkEndHour: actualWorkEnd
        ? String(
            actualWorkEnd.diff(baseDate, "days").days < 1
              ? actualWorkEnd.hour
              : actualWorkEnd.hour + 24
          ).padStart(2, "0")
        : "",
      actualWorkEndMinute: actualWorkEnd
        ? String(actualWorkEnd.minute).padStart(2, "0")
        : "",
      workOffStartHour: workOffStart
        ? String(
            workOffStart.diff(baseDate, "days").days < 1
              ? workOffStart.hour
              : workOffStart.hour + 24
          ).padStart(2, "0")
        : "",
      workOffStartMinute: workOffStart
        ? String(workOffStart.minute).padStart(2, "0")
        : "",
      workOffEndHour: workOffEnd
        ? String(
            workOffEnd.diff(baseDate, "days").days < 1
              ? workOffEnd.hour
              : workOffEnd.hour + 24
          ).padStart(2, "0")
        : "",
      workOffEndMinute: workOffEnd
        ? String(workOffEnd.minute).padStart(2, "0")
        : "",
      status: driverShift.status,
      staffMemo: driverShift.staffMemo,
      driverMemo: driverShift.driverMemo,
      scheduleItemColor: driverShift.scheduleItemColor,
      isForceShift: driverShift.isForceShift,
    });
  }, [spDriverShiftModal.meta?.driver]);

  const onChange =
    (key: string, handler: (value: any) => any = (value: any) => value) =>
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | { value: unknown }
      >
    ) => {
      event.persist();
      setDriverShiftReq((prev: any) => ({
        ...prev,
        [key]: handler(event.target.value),
      }));
    };

  const onClickSubmit = async () => {
    if (
      !(
        driverShiftReq.planWorkStartHour &&
        driverShiftReq.planWorkStartMinute &&
        driverShiftReq.planWorkEndHour &&
        driverShiftReq.planWorkEndMinute
      )
    ) {
      return alert("予定勤務開始と予定勤務終了は必須項目です。");
    }
    const planWorkStart = DateTime.fromJSDate(
      spDriverShiftModal.meta?.date || new Date()
    )
      .startOf("days")
      .plus({
        hours: Number(driverShiftReq.planWorkStartHour),
        minutes: Number(driverShiftReq.planWorkStartMinute),
      })
      .toJSDate();
    const planWorkEnd = DateTime.fromJSDate(
      spDriverShiftModal.meta?.date || new Date()
    )
      .startOf("days")
      .plus({
        hours: Number(driverShiftReq.planWorkEndHour),
        minutes: Number(driverShiftReq.planWorkEndMinute),
      })
      .toJSDate();

    const actualWorkStart =
      driverShiftReq.actualWorkStartHour && driverShiftReq.actualWorkStartMinute
        ? DateTime.fromJSDate(spDriverShiftModal.meta?.date || new Date())
            .startOf("days")
            .plus({
              hours: Number(driverShiftReq.actualWorkStartHour),
              minutes: Number(driverShiftReq.actualWorkStartMinute),
            })
            .toJSDate()
        : null;
    const actualWorkEnd =
      driverShiftReq.actualWorkEndHour && driverShiftReq.actualWorkEndMinute
        ? DateTime.fromJSDate(spDriverShiftModal.meta?.date || new Date())
            .startOf("days")
            .plus({
              hours: Number(driverShiftReq.actualWorkEndHour),
              minutes: Number(driverShiftReq.actualWorkEndMinute),
            })
            .toJSDate()
        : null;

    const workOffStart =
      driverShiftReq.workOffStartHour && driverShiftReq.workOffStartMinute
        ? DateTime.fromJSDate(spDriverShiftModal.meta?.date || new Date())
            .startOf("days")
            .plus({
              hours: Number(driverShiftReq.workOffStartHour),
              minutes: Number(driverShiftReq.workOffStartMinute),
            })
            .toJSDate()
        : null;
    const workOffEnd =
      driverShiftReq.workOffEndHour && driverShiftReq.workOffEndMinute
        ? DateTime.fromJSDate(spDriverShiftModal.meta?.date || new Date())
            .startOf("days")
            .plus({
              hours: Number(driverShiftReq.workOffEndHour),
              minutes: Number(driverShiftReq.workOffEndMinute),
            })
            .toJSDate()
        : null;
    if (!spDriverShiftModal.meta?.driver?.driverShifts?.length) {
      const req = new CreateDriverShiftReq({
        driverId: spDriverShiftModal.meta.driver.driverId,
        planWorkStart,
        planWorkEnd,
        actualWorkStart,
        actualWorkEnd,
        workOffStart,
        workOffEnd,
        status: driverShiftReq.status,
        staffMemo: driverShiftReq.staffMemo,
        driverMemo: driverShiftReq.driverMemo,
        scheduleItemColor: driverShiftReq.scheduleItemColor,
      });
      await DriverShiftApi.create(companyId, req);
    } else {
      const req = new UpdateDriverShiftReq({
        driverShiftId:
          spDriverShiftModal.meta.driver.driverShifts[0].driverShiftId,
        driverId: spDriverShiftModal.meta.driver.driverId,
        planWorkStart,
        planWorkEnd,
        actualWorkStart,
        actualWorkEnd,
        workOffStart,
        workOffEnd,
        status: driverShiftReq.status,
        staffMemo: driverShiftReq.staffMemo,
        driverMemo: driverShiftReq.driverMemo,
        scheduleItemColor: driverShiftReq.scheduleItemColor,
      });
      await DriverShiftApi.update(
        companyId,
        spDriverShiftModal.meta.driver.driverShifts[0].driverShiftId,
        req
      );
    }
    apiCall();
    setSpDriverShiftModal(false, {});
  };

  return (
    <Modal show={spDriverShiftModal.show} setShow={setSpDriverShiftModal}>
      <Box display="flex">
        <Typography>
          {DateTime.fromJSDate(
            spDriverShiftModal.meta?.date || new Date()
          ).toFormat(FORMAT_TYPE.YEAR_DAY_JP)}
        </Typography>
        <Typography style={{ marginLeft: "8px" }}>
          {spDriverShiftModal.meta?.driverName?.name || ""}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>予定勤務開始</Label>
        <Box display="flex">
          <SelectTime
            driverShiftReq={driverShiftReq}
            onChange={onChange}
            hours={hours}
            minutes={minutes}
            hourKey={"planWorkStartHour"}
            minuteKey={"planWorkStartMinute"}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>予定勤務終了</Label>
        <Box display="flex">
          <SelectTime
            driverShiftReq={driverShiftReq}
            onChange={onChange}
            hours={hours}
            minutes={minutes}
            hourKey={"planWorkEndHour"}
            minuteKey={"planWorkEndMinute"}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>実勤務開始</Label>
        <Box display="flex">
          <SelectTime
            driverShiftReq={driverShiftReq}
            onChange={onChange}
            hours={hours}
            minutes={minutes}
            hourKey={"actualWorkStartHour"}
            minuteKey={"actualWorkStartMinute"}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>実勤務終了</Label>
        <Box display="flex">
          <SelectTime
            driverShiftReq={driverShiftReq}
            onChange={onChange}
            hours={hours}
            minutes={minutes}
            hourKey={"actualWorkEndHour"}
            minuteKey={"actualWorkEndMinute"}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>休憩開始</Label>
        <Box display="flex">
          <SelectTime
            driverShiftReq={driverShiftReq}
            onChange={onChange}
            hours={hours}
            minutes={minutes}
            hourKey={"workOffStartHour"}
            minuteKey={"workOffStartMinute"}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>休憩終了</Label>
        <Box display="flex">
          <SelectTime
            driverShiftReq={driverShiftReq}
            onChange={onChange}
            hours={hours}
            minutes={minutes}
            hourKey={"workOffEndHour"}
            minuteKey={"workOffEndMinute"}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>ステータス</Label>
        <Box display="flex">
          <Select
            autoWidth
            style={{ minWidth: "100px" }}
            value={driverShiftReq?.status || ""}
            onChange={onChange("status")}
          >
            {Object.entries(ShiftStatus).map(([key, name]) => (
              <MenuItem key={key} value={key}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>スタッフメモ</Label>
        <Box display="flex">
          <TextareaAutosize
            value={driverShiftReq?.staffMemo || ""}
            onChange={onChange("staffMemo")}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>ドライバーメモ</Label>
        <Box display="flex">
          <TextareaAutosize
            value={driverShiftReq?.driverMemo || ""}
            onChange={onChange("driverMemo")}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => onClickSubmit()}
        >
          送信
        </Button>
      </Box>
    </Modal>
  );
};

type SelectTimeProps = {
  hours: string[];
  minutes: string[];
  driverShiftReq: any;
  onChange: any;
  hourKey: string;
  minuteKey: string;
};

const SelectTime = ({
  hours,
  minutes,
  hourKey,
  minuteKey,
  driverShiftReq,
  onChange,
}: SelectTimeProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Select
        native
        style={{ minWidth: "50px" }}
        value={driverShiftReq?.[hourKey] || ""}
        onChange={onChange(hourKey)}
      >
        <option value={""} />
        {hours.map((hour: string) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </Select>
      :
      <Select
        native
        style={{ minWidth: "50px" }}
        value={driverShiftReq?.[minuteKey] || ""}
        onChange={onChange(minuteKey)}
      >
        <option value={""} />
        {minutes.map((minute: string) => (
          <option key={minute} value={minute}>
            {minute}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default SpDriverShiftModal;
