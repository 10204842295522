import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  InputLabel,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import GuestGenreType from "types/enum/GuestGenreType";
import GuestAnalyticsGroupType from "types/enum/GuestAnalyticsGroupType";
import EnumUtils from "utils/EnumUtils";
import { fetchShops } from "redux/actions/shop";
import { makeStyles } from "@material-ui/core/styles";
import GuestAnalyticsApi from "api/GuestAnalyticsApi";
import MathUtils from "utils/MathUtils";
import CsvUtils from "utils/CsvUtils";
const GuestAnalytics = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const shops = useSelector((state) => state.shop);

  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectGenre, setSelectGenre] = useState<string[]>(["active"]);
  const [selectGroup, setSelectGroup] = useState<string[]>([
    ...Object.keys(GuestAnalyticsGroupType),
    "すべて",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [guestData, setGuestData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchShops(companyId));
  }, []);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  const onClickSearch = async () => {
    setIsLoading(true);
    setPage(0);
    setTotalCount(0);
    const result = await GuestAnalyticsApi.findAll(
      companyId,
      DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      selectShops.filter((shop) => shop !== "すべて").join(","),
      selectGenre.filter((shop) => shop !== "すべて").join(","),
      selectGroup.filter((shop) => shop !== "すべて").join(","),
      rowsPerPage,
      0
    );
    const count = await GuestAnalyticsApi.count(
      companyId,
      DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      selectShops.filter((shop) => shop !== "すべて").join(","),
      selectGenre.filter((shop) => shop !== "すべて").join(","),
      selectGroup.filter((shop) => shop !== "すべて").join(",")
    );
    setTotalCount(count);
    setGuestData(result);

    setIsLoading(false);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage);
    setIsLoading(true);
    const result = await GuestAnalyticsApi.findAll(
      companyId,
      DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      selectShops.filter((shop) => shop !== "すべて").join(","),
      selectGenre.filter((shop) => shop !== "すべて").join(","),
      selectGroup.filter((shop) => shop !== "すべて").join(","),
      rowsPerPage,
      newPage
    );
    setGuestData(result);
    setIsLoading(false);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setIsLoading(true);
    const result = await GuestAnalyticsApi.findAll(
      companyId,
      DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      selectShops.filter((shop) => shop !== "すべて").join(","),
      selectGenre.filter((shop) => shop !== "すべて").join(","),
      selectGroup.filter((shop) => shop !== "すべて").join(","),
      parseInt(event.target.value, 10),
      0
    );
    setGuestData(result);
    setIsLoading(false);
  };
  const dataRequest = async (limit: number, offset: number) => {
    return await GuestAnalyticsApi.findAll(
      companyId,
      DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      selectShops.filter((shop) => shop !== "すべて").join(","),
      selectGenre.filter((shop) => shop !== "すべて").join(","),
      selectGroup.filter((shop) => shop !== "すべて").join(","),
      limit,
      offset
    );
  };

  const csvDownload = async () => {
    setIsDataLoading(true);

    const fileName = `CPM顧客リスト_${DateTime.fromJSDate(endDate).toFormat(
      FORMAT_TYPE.YEAR_DAY
    )}`;
    let rowData: any[][] = [
      [
        "顧客名",
        "顧客電話番号",
        "現役・離脱",
        "顧客グループ",
        "累計利用回数",
        "累計利用金額",
        "平均単価",
        "利用店舗",
        "離脱期間",
      ],
    ];
    async function requestData() {
      const sleep = (m: number) => new Promise((r) => setTimeout(r, m));
      for (const [i] of [...Array(Math.ceil(totalCount / 400))].entries()) {
        const result = await dataRequest(400, i);
        rowData = [
          ...rowData,
          ...result.map((r: any) => [
            r.name || "",
            ` ${r.tel}` || "",
            EnumUtils.mapToEnum(GuestGenreType, r.genre) || "-",
            EnumUtils.mapToEnum(GuestAnalyticsGroupType, r.guestGroup) || "-",
            r.orderCount,
            r.totalPrice,
            Math.round(r.totalPrice / r.orderCount) || 0,
            r.shopName.replaceAll(",", "/"),
            r.awayDays,
          ]),
        ];
        await sleep(3000);
      }
    }
    await requestData();
    const data = rowData
      .filter(Boolean)
      .map((row) =>
        row?.map((d) => (typeof d === "number" ? MathUtils.roundToTwo(d) : d))
      );
    await CsvUtils.download(fileName, data);
    setIsDataLoading(false);
  };
  if (isDataLoading) {
    return (
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        margin={2}
      >
        <Typography>CSVダウンロード中</Typography>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection={"column"}>
      <Box margin={2} display="flex" alignItems="center">
        <TextField
          type="date"
          label="終了日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) =>
            setEndDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            )
          }
        />
        <Box>
          <InputLabel id="select-multiple-">店舗</InputLabel>
          <Select
            multiple
            value={selectShops}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectShops((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length === shops.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= shops.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-shop" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return shops
                  .filter((shop) =>
                    (selected as string[]).includes(String(shop.shopId))
                  )
                  .map((shop) => shop.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectShops.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {shops.map((shop) => (
              <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                <Checkbox
                  checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                />
                <ListItemText primary={shop.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-guest-genre">現役・離脱</InputLabel>
          <Select
            multiple
            value={selectGenre}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGenre((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [...Object.keys(GuestGenreType), "すべて"];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(GuestGenreType).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [...Object.keys(GuestGenreType), "すべて"];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(GuestGenreType).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-genre" />}
            style={{ margin: "10px", width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.keys(GuestGenreType)
                  .filter((name) => (selected as string[]).includes(name))
                  .map((key) => (GuestGenreType as any)[key])
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGenre.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(GuestGenreType).map(([key, name]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox checked={selectGenre.indexOf(String(key)) !== -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-guest-genre">顧客グループ</InputLabel>
          <Select
            multiple
            value={selectGroup}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGroup((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [...Object.keys(GuestAnalyticsGroupType), "すべて"];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(GuestAnalyticsGroupType).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [...Object.keys(GuestAnalyticsGroupType), "すべて"];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(GuestAnalyticsGroupType).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-genre" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.keys(GuestAnalyticsGroupType)
                  .filter((name) => (selected as string[]).includes(name))
                  .map((key) => (GuestAnalyticsGroupType as any)[key])
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGroup.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(GuestAnalyticsGroupType).map(([key, name]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox checked={selectGroup.indexOf(String(key)) !== -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={onClickSearch}
        >
          分析
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={csvDownload}
          disabled={isDataLoading || !totalCount}
        >
          CSVダウンロード
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>顧客名</TableCell>
            <TableCell>顧客電話番号</TableCell>
            <TableCell>現役・離脱</TableCell>
            <TableCell>顧客グループ</TableCell>
            <TableCell>累計利用回数</TableCell>
            <TableCell>累計利用金額</TableCell>
            <TableCell>平均単価</TableCell>
            <TableCell>利用店舗</TableCell>
            <TableCell>離脱期間</TableCell>
          </TableRow>
        </TableHead>
        {isLoading ? (
          <CircularProgress />
        ) : guestData.length ? (
          <TableBody>
            {guestData.map((data, index) => (
              <TableRow key={index}>
                <TableCell>{data?.name || ""}</TableCell>
                <TableCell>{data?.tel || ""}</TableCell>
                <TableCell>
                  {EnumUtils.mapToEnum(GuestGenreType, data.genre) || "-"}
                </TableCell>
                <TableCell>
                  {EnumUtils.mapToEnum(
                    GuestAnalyticsGroupType,
                    data.guestGroup
                  ) || "-"}
                </TableCell>
                <TableCell>{data.orderCount}回</TableCell>
                <TableCell>¥{data.totalPrice}</TableCell>
                <TableCell>
                  ¥{Math.round(data.totalPrice / data.orderCount) || 0}
                </TableCell>
                <TableCell>{data.shopName}</TableCell>
                <TableCell>{data.awayDays}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <></>
        )}
      </Table>
      <TablePagination
        rowsPerPageOptions={[30, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="ページ毎件数"
      />
      {!guestData.length ? (
        <Box display="flex" justifyContent={"center"} marginTop={2}>
          <Typography>
            データがありません。条件を変えて再度検索してください。
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
export default GuestAnalytics;
