import { Expose, Type } from "class-transformer";

export default class RequestGuestRes {
  @Expose() readonly requestGuestId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly tel!: string;
  @Expose() readonly email!: string;
  @Type(() => Date)
  @Expose()
  readonly createdAt!: Date;
  @Type(() => Date)
  @Expose()
  readonly updatedAt!: Date;
}
