import { Expose, Type } from "class-transformer";
import DriverRes from "types/res/driver/DriverRes";

export default class CarTransferScheduleRes {
  @Expose() readonly carTransferScheduleId!: number;
  @Expose() readonly companyId!: number;
  @Type(() => Date)
  @Expose()
  readonly startTime!: Date;
  @Type(() => Date)
  @Expose()
  readonly endTime!: Date;
  @Expose() readonly title!: string;
  @Type(() => DriverRes)
  @Expose()
  readonly driver!: DriverRes;
}
