import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import CastRankingApi from "api/CastRankingApi";
import CastRankingRes from "types/res/castRanking/CastRankingRes";
import CastRankingEachDataRes from "types/res/castRanking/CastRankingEachDataRes";
const actionCreator = actionCreatorFactory();

const fetchCastRankingAction = actionCreator.async<
  {},
  CastRankingRes,
  { code: number; description: string }
>("FETCH_CAST_RANKING");

export const fetchCastRanking =
  (
    companyId: number,
    startDate: string,
    endDate: string,
    shopIds: string,
    notelClassIds: string,
    castCategoryIds: string
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCastRankingAction.started({}));

    try {
      const result = await CastRankingApi.findTotalData(
        companyId,
        startDate,
        endDate,
        shopIds,
        notelClassIds,
        castCategoryIds
      );
      dispatch(
        fetchCastRankingAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCastRankingAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCastRankingEachDataAction = actionCreator.async<
  {},
  CastRankingEachDataRes,
  { code: number; description: string }
>("FETCH_CAST_RANKING_EACH_DATA");

export const fetchCastRankingEachData =
  (companyId: number, startDate: string, endDate: string, castIds: string) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCastRankingEachDataAction.started({}));

    try {
      const result = await CastRankingApi.findCastData(
        companyId,
        startDate,
        endDate,
        castIds
      );
      dispatch(
        fetchCastRankingEachDataAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCastRankingEachDataAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const castRankingActions = {
  fetchCastRankingAction,
  fetchCastRankingEachDataAction,
};
export default castRankingActions;
