import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { fetchShops } from "redux/actions/shop";
import { FormType } from "components/FormField";
import { Box } from "@material-ui/core";
import DriverSettingBookEmailRes from "types/res/driverSettingBookEmail/DriverSettingBookEmailRes";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import UpdateDriverSettingBookEmailReq from "types/req/driverSettingBookEmail/UpdateDriverSettingBookEmailReq";
import {
  fetchDriverSettingBookEmail,
  updateDriverSettingBookEmail,
} from "redux/actions/driverSettingBookEmail";

const headers: { key: keyof DriverSettingBookEmailRes; label: string }[] = [
  { key: "isOrderDateEnabled", label: "受付日時" },
  { key: "isOrderCreatedByEnabled", label: "受付作成者" },
  { key: "isOrderUpdatedByEnabled", label: "受付最終更新者" },
  { key: "isGuestCategoryEnabled", label: "顧客区分" },
  { key: "isGuestNameEnabled", label: "顧客名" },
  { key: "isGuestTelEnabled", label: "顧客電話番号" },
  { key: "isGuestMemoEnabled", label: "顧客メモ" },
  { key: "isShopNameEnabled", label: "店舗名" },
  { key: "isCastNameEnabled", label: "源氏名" },
  { key: "isCastDesignateCountEnabled", label: "指名回数" },
  { key: "isCastClassNameEnabled", label: "クラス名" },
  { key: "isCastDesignateNameEnabled", label: "指名" },
  { key: "isCourseNameEnabled", label: "コース名" },
  { key: "isAdditionalTimeEnabled", label: "延長" },
  { key: "isTotalTimeEnabled", label: "合計時間数" },
  { key: "isDiscountNameEnabled", label: "イベント・割引" },
  { key: "isOptionNameEnabled", label: "オプション" },
  { key: "isCosplayNameEnabled", label: "コスプレ" },
  { key: "isAreaEnabled", label: "エリア" },
  { key: "isHotelEnabled", label: "ホテル" },
  { key: "isOrderAddressEnabled", label: "住所" },
  { key: "isOrderDepartureTimeEnabled", label: "出発時間" },
  { key: "isOrderPlanInTimeEnabled", label: "予定IN時間" },
  { key: "isOrderActualInTimeEnabled", label: "実IN時間" },
  { key: "isOrderActualEndTimeEnabled", label: "実OUT時間" },
  { key: "isOrderMemoEnabled", label: "受注メモ" },
  { key: "isOrderPaymentTypeEnabled", label: "支払い方法" },
  { key: "isCarInfoEnabled", label: "車両情報" },
  { key: "shopId", label: "店舗" },
];

const DriverSettingBookEmail = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const driverSettingBookEmails = useSelector(
    (state) => state.driverSettingBookEmail
  );
  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchDriverSettingBookEmail(companyId));
  }, [dispatch, companyId]);

  const forms = [
    {
      label: "受付日時",
      key: "isOrderDateEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "受付作成者",
      key: "isOrderCreatedByEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "受付最終更新者",
      key: "isOrderUpdatedByEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "顧客区分",
      key: "isGuestCategoryEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "顧客名",
      key: "isGuestNameEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "顧客電話番号",
      key: "isGuestTelEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "顧客メモ",
      key: "isGuestMemoEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "店舗名",
      key: "isShopNameEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "源氏名",
      key: "isCastNameEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "指名回数",
      key: "isCastDesignateCountEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "クラス名",
      key: "isCastClassNameEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "指名",
      key: "isCastDesignateNameEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "コース名",
      key: "isCourseNameEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "延長",
      key: "isAdditionalTimeEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "合計時間数",
      key: "isTotalTimeEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "イベント・割引",
      key: "isDiscountNameEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "オプション",
      key: "isOptionNameEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "コスプレ",
      key: "isCosplayNameEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "エリア",
      key: "isAreaEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "ホテル",
      key: "isHotelEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "住所",
      key: "isOrderAddressEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "出発時間",
      key: "isOrderDepartureTimeEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "予定IN時間",
      key: "isOrderPlanInTimeEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "実IN時間",
      key: "isOrderActualInTimeEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "実OUT時間",
      key: "isOrderActualEndTimeEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "受注メモ",
      key: "isOrderMemoEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "支払い方法",
      key: "isOrderPaymentTypeEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
    {
      label: "車両情報",
      key: "isCarInfoEnabled",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
  ];

  return (
    <>
      <Box maxWidth={"100vw"}>
        <DriverSettingBookEmailTable
          fullWidth
          title={"ドライバー予約メール"}
          rows={headers}
          data={driverSettingBookEmails}
          formId="driverSettingBookEmailForm"
          forms={forms}
          updateType={UpdateDriverSettingBookEmailReq}
          addFunc={(formData) =>
            updateDriverSettingBookEmail(companyId, formData)
          }
          updateFunc={(formData) =>
            updateDriverSettingBookEmail(companyId, formData)
          }
          deleteFunc={(formData) =>
            updateDriverSettingBookEmail(companyId, formData)
          }
          invisibleDeleteIcon={true}
          values={[
            (s) => (s.isOrderDateEnabled ? "○" : "×"),
            (s) => (s.isOrderCreatedByEnabled ? "○" : "×"),
            (s) => (s.isOrderUpdatedByEnabled ? "○" : "×"),
            (s) => (s.isGuestCategoryEnabled ? "○" : "×"),
            (s) => (s.isGuestNameEnabled ? "○" : "×"),
            (s) => (s.isGuestTelEnabled ? "○" : "×"),
            (s) => (s.isGuestMemoEnabled ? "○" : "×"),
            (s) => (s.isShopNameEnabled ? "○" : "×"),
            (s) => (s.isCastNameEnabled ? "○" : "×"),
            (s) => (s.isCastDesignateCountEnabled ? "○" : "×"),
            (s) => (s.isCastClassNameEnabled ? "○" : "×"),
            (s) => (s.isCastDesignateNameEnabled ? "○" : "×"),
            (s) => (s.isCourseNameEnabled ? "○" : "×"),
            (s) => (s.isAdditionalTimeEnabled ? "○" : "×"),
            (s) => (s.isTotalTimeEnabled ? "○" : "×"),
            (s) => (s.isDiscountNameEnabled ? "○" : "×"),
            (s) => (s.isOptionNameEnabled ? "○" : "×"),
            (s) => (s.isCosplayNameEnabled ? "○" : "×"),
            (s) => (s.isAreaEnabled ? "○" : "×"),
            (s) => (s.isHotelEnabled ? "○" : "×"),
            (s) => (s.isOrderAddressEnabled ? "○" : "×"),
            (s) => (s.isOrderDepartureTimeEnabled ? "○" : "×"),
            (s) => (s.isOrderPlanInTimeEnabled ? "○" : "×"),
            (s) => (s.isOrderActualInTimeEnabled ? "○" : "×"),
            (s) => (s.isOrderActualEndTimeEnabled ? "○" : "×"),
            (s) => (s.isOrderMemoEnabled ? "○" : "×"),
            (s) => (s.isOrderPaymentTypeEnabled ? "○" : "×"),
            (s) => (s.isCarInfoEnabled ? "○" : "×"),
            (s) => s?.shop?.name || "未設定",
          ]}
        />
      </Box>
    </>
  );
};

const DriverSettingBookEmailTable = styled<
  React.FC<
    CommonTableProps<
      DriverSettingBookEmailRes,
      UpdateDriverSettingBookEmailReq,
      UpdateDriverSettingBookEmailReq
    >
  >
>(CommonTable)`
  margin-top: 24px;
`;

export default DriverSettingBookEmail;
