import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import ShopRes from "types/res/shop/ShopRes";
import {
  addShop,
  deleteShop,
  fetchShops,
  updateShop,
} from "redux/actions/shop";
import { FormType } from "components/FormField";
import CreateShopReq from "types/req/shop/CreateShopReq";
import UpdateShopReq from "types/req/shop/UpdateShopReq";
import { fetchNomination } from "redux/actions/nomination";
import StaffRole from "../../types/enum/StaffRole";
import EnumUtils from "../../utils/EnumUtils";
import { BusinessCategoryType } from "types/enum/BusinessCategoryType";
import ShopApi from "api/ShopApi";
import { Box, Typography } from "@material-ui/core";
import EnvironmentUtils from "utils/EnviromentUtils";

const Shop: React.FC = () => {
  const dispatch = useDispatch();
  const shop: ShopRes[] = useSelector((state) => state.shop);
  const companyId = useSelector((state) => state.account.staff.companyId);
  const loginUserRole = useSelector((state) => state.account.staff.role);
  const nominations = useSelector((state) => state.nomination);
  const [useAcdCodeList, setUseAcdCodeList] = useState<string[]>([]);
  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchNomination(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    const apiCall = async () => {
      const result = await ShopApi.acdCodeList(companyId);
      setUseAcdCodeList(result);
    };
    apiCall();
  }, [shop]);
  const headers: { key: keyof ShopRes; label: string }[] = [
    { key: "name", label: "店舗名" },
    { key: "realName", label: "店舗正式名称" },
    { key: "email", label: "メールアドレス" },
    { key: "tel", label: "電話番号" },
    { key: "url", label: "店舗URL" },
    { key: "cardRate", label: "カード手数料（%）" },
    { key: "pointRate", label: "ポイント付与率(%)" },
    { key: "orderAlertEmailTime", label: "接客終了メール送信時間(分)" },
    { key: "openingTime", label: "開店時間" },
    { key: "closingTime", label: "閉店時間" },
    { key: "businessCategoryType", label: "業態" },
    { key: "nomination", label: "本指名フラグ" },
    { key: "shopGuestKey", label: "顧客登録用URL" },
    { key: "isGuestOrder", label: "顧客予約" },
  ];

  const forms: {
    label: string;
    key: string;
    type: number;
    options?: { id: string | number; name: any }[];
    unSelectOptions?: string[];
  }[] = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "店舗正規名称",
      key: "realName",
      type: FormType.Text,
    },
    {
      label: "メールアドレス",
      key: "email",
      type: FormType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
    {
      label: "店舗URL",
      key: "url",
      type: FormType.Text,
    },
    {
      label: "カード手数料（%）",
      key: "cardRate",
      type: FormType.Number,
    },
    {
      label: "ポイント付与率(%)",
      key: "pointRate",
      type: FormType.Number,
    },
    {
      label: "接客終了メール送信時間(分)",
      key: "orderAlertEmailTime",
      type: FormType.Number,
    },
    {
      label: "開店時間",
      key: "openingTime",
      type: FormType.Time,
    },
    {
      label: "閉店時間",
      key: "closingTime",
      type: FormType.Time,
    },
    {
      label: "業態",
      key: "businessCategoryType",
      type: FormType.Select,
      options: Object.entries(BusinessCategoryType).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "本指名フラグ",
      key: "nominationId",
      type: FormType.Select,
      options: nominations.map((nomination) => ({
        id: nomination.nominationId,
        name: nomination.name,
      })),
    },
    {
      label: "顧客予約",
      key: "isGuestOrder",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "不可" },
        { id: "1", name: "可" },
      ],
    },
  ];
  if (
    [StaffRole.notelMaster, StaffRole.notelCallCenterEmployee].includes(
      EnumUtils.mapToEnum(StaffRole, loginUserRole) ||
        StaffRole.clientShopPartTime
    )
  ) {
    const acdCodeList = [...Array(999)].map((_, i) => ({
      id: String(i).padStart(3, "0"),
      name: String(i).padStart(3, "0"),
    }));
    headers.push({ key: "acdCode", label: "acdコード" });
    forms.push({
      label: "acdコード",
      key: "acdCode",
      type: FormType.Select,
      options: acdCodeList,
      unSelectOptions: useAcdCodeList,
    });
  }

  const deleteAlertComponent = (
    <Box display="flex" flexDirection="column" marginY={2}>
      <Typography style={{ color: "red" }}>
        店舗に紐付いた下記のデータが削除されます。
      </Typography>
      <Typography style={{ marginTop: "8px" }}>・源氏名</Typography>
      <Typography>・コース</Typography>
      <Typography>・予約メール管理 ドライバー予約メール管理</Typography>
      <Typography>・着信履歴</Typography>
      <Typography>・受注</Typography>
    </Box>
  );
  return (
    <>
      <ShopTable
        // fullWidth
        title={"店舗"}
        formId="shopForm"
        forms={forms}
        rows={headers}
        data={shop}
        addType={CreateShopReq}
        updateType={UpdateShopReq}
        addFunc={(formData) => addShop(companyId, formData)}
        updateFunc={(formData) => updateShop(companyId, formData)}
        deleteFunc={(item) => deleteShop(companyId, item.shopId)}
        defaultValue={{ businessCategoryType: "other" }}
        values={[
          (s) => s.name,
          (s) => s.realName,
          (s) => s.email,
          (s) => s.tel,
          (s) => s.url,
          (s) => s.cardRate,
          (s) => s.pointRate,
          (s) => s.orderAlertEmailTime,
          (s) => s.openingTime,
          (s) => s.closingTime,
          (s) =>
            EnumUtils.mapToEnum(BusinessCategoryType, s.businessCategoryType) ||
            "未設定",
          (s) => s?.nomination?.name || "未設定",
          (s) =>
            `${EnvironmentUtils.getGuestDomain()}register?shopKey=${
              s.shopGuestKey
            }`,
          (s) => (s.isGuestOrder ? "可" : "不可"),
          (s) =>
            [
              StaffRole.notelMaster,
              StaffRole.notelCallCenterEmployee,
              StaffRole.notelCallCenterPartTime,
            ].includes(
              EnumUtils.mapToEnum(StaffRole, loginUserRole) ||
                StaffRole.clientShopPartTime
            )
              ? s.acdCode || ""
              : "",
        ]}
        deleteModalText={deleteAlertComponent}
      />
    </>
  );
};

const ShopTable = styled<
  React.FC<CommonTableProps<ShopRes, CreateShopReq, UpdateShopReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default Shop;
