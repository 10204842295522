import RequestUtils from "utils/RequestUtils";
import MediumRes from "types/res/medium/MediumRes";
import CreateMediumReq from "types/req/medium/CreateMediumReq";
import UpdateMediumReq from "types/req/medium/UpdateMediumReq";
import DeleteMediumReq from "types/req/medium/DeleteMediumReq";

export default class MediumApi {
  private constructor() {}

  static async findAll(companyId: number): Promise<MediumRes[]> {
    return await RequestUtils.getArray(
      MediumRes,
      `/medium/findAll/${companyId}`
    );
  }

  static async create(companyId: number, data: CreateMediumReq) {
    return await RequestUtils.post(
      MediumRes,
      `/medium/create/${companyId}`,
      data
    );
  }

  static async bulkInsert(companyId: number, data: CreateMediumReq[]) {
    return await RequestUtils.postArray(
      MediumRes,
      `/medium/bulkInsert/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    mediumId: number,
    data: UpdateMediumReq
  ): Promise<MediumRes> {
    return await RequestUtils.post(
      MediumRes,
      `/medium/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteMediumReq) {
    return await RequestUtils.postVoid(`/medium/delete/${companyId}`, data);
  }
}
