import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CompanyRes from "../../types/res/company/CompanyRes";
import { updateCompany } from "../../redux/actions/company";
import EnumUtils from "../../utils/EnumUtils";
import JournalRes from "../../types/res/journal/JournalRes";
import JournalType from "../../types/enum/JournalType";
import Journal from "../../types/enum/JournalType";
import EditJournalRow from "./EditJournalRow";
import CreateJournalReq from "../../types/req/journal/CreateJournalReq";
import { addJournal } from "../../redux/actions/journal";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "../../utils/DateTimeUtils";
import AddJournalRow from "./AddJournalRow";
import ShopRes from "types/res/shop/ShopRes";

type CellProps = {
  title: string;
  value: string;
  isInput?: boolean;
  onChange?: any;
  onBlur?: any;
};
const AccountingCell: React.FC<CellProps> = ({
  title,
  value,
  isInput,
  onChange,
  onBlur,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      border="1px solid #000"
      marginLeft={1}
      width="200px"
    >
      <Box
        borderBottom="1px solid #000"
        paddingY={1}
        display="flex"
        justifyContent="center"
      >
        <Typography>{title}</Typography>
      </Box>
      <Box paddingY={1} display="flex" justifyContent="center">
        {isInput ? (
          <Box display="flex" alignItems="flex-end">
            <TextField
              type="text"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
            <Typography>円</Typography>
          </Box>
        ) : (
          <Typography>{value}</Typography>
        )}
      </Box>
    </Box>
  );
};
type Props = {
  company: CompanyRes;
  journals: JournalRes[];
  shops: ShopRes[];
};

const AccountingTable: React.FC<Props> = ({ company, journals, shops }) => {
  const dispatch = useDispatch();
  const [changeReserve, setChangeReserve] = useState("");
  const accounting = useSelector((state) => state.accounting);
  const [addRevenueJournal, setAddRevenueJournal] = useState(
    {} as CreateJournalReq
  );
  const [addCostJournal, setAddCostJournal] = useState({} as CreateJournalReq);

  useEffect(() => {
    setChangeReserve(String(company.changeReserve || ""));
  }, [company]);

  useEffect(() => {
    if (
      !addRevenueJournal.shopId ||
      !addRevenueJournal.genre ||
      !addRevenueJournal.date ||
      !addRevenueJournal.amount ||
      !addRevenueJournal.itemName
    ) {
      return;
    }
    dispatch(
      addJournal(company.companyId, {
        ...addRevenueJournal,
        type: Journal.revenue,
      })
    );
    setAddRevenueJournal({
      genre: "",
      itemName: "",
      amount: 0,
    } as CreateJournalReq);
  }, [addRevenueJournal, company]);

  useEffect(() => {
    if (
      !addCostJournal.shopId ||
      !addCostJournal.genre ||
      !addCostJournal.date ||
      !addCostJournal.amount ||
      !addCostJournal.itemName
    )
      return;
    dispatch(
      addJournal(company.companyId, {
        ...addCostJournal,
        type: Journal.cost,
      })
    );
    setAddCostJournal({
      genre: "",
      itemName: "",
      amount: 0,
    } as CreateJournalReq);
  }, [addCostJournal, company]);

  const revenueJournals = journals.filter(
    (journal) =>
      EnumUtils.mapToEnum(JournalType, journal.type) === Journal.revenue
  );

  const costJournals = journals.filter(
    (journal) => EnumUtils.mapToEnum(JournalType, journal.type) === Journal.cost
  );

  const bookingRevenue = accounting.bookingTotalFee;
  const bookingDiscount = accounting.bookingDiscountFee;

  const bookingHotelFee = accounting.bookingHotelFee;

  const bookingCastFee = accounting.bookingTotalCastFee;
  const bookingCardRevenue = accounting.bookingCardFee;
  const paidRevenue = accounting.paidTotalFee;
  const paidDiscount = accounting.paidDiscountFee;

  const paidHotelFee = accounting.paidHotelFee;

  const paidCastFee = accounting.paidTotalCastFee;
  const paidPayOffCastFee = accounting.paidPayoffCastFee;
  const paidCardRevenue = accounting.paidCardFee;
  const paidOtherFee = revenueJournals.reduce(
    (sum, journal) => sum + journal.amount,
    0
  );
  const cost = costJournals.reduce((sum, journal) => sum + journal.amount, 0);
  const bookingProfit =
    bookingRevenue -
    bookingHotelFee -
    bookingCastFee -
    bookingCardRevenue +
    paidOtherFee;
  const paidProfit =
    paidRevenue -
    paidHotelFee -
    paidPayOffCastFee -
    paidCardRevenue +
    paidOtherFee -
    cost;
  const cashRegister = paidProfit + company.changeReserve || 0;

  const updateChangeReserve = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    dispatch(
      updateCompany({ ...company, changeReserve: Number(event.target.value) })
    );
  };

  const addRevenueJournalReq =
    (key: string) =>
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.persist();
      setAddRevenueJournal((prev: any) => ({
        ...prev,
        [key]:
          key === "date"
            ? DateTime.fromFormat(
                event.target.value,
                FORMAT_TYPE.YEAR_DAY
              ).toJSDate()
            : key === "amount"
            ? Number(event.target.value)
            : event.target.value,
      }));
    };

  const addCostJournalReq =
    (key: string) =>
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.persist();
      setAddCostJournal((prev: any) => ({
        ...prev,
        [key]:
          key === "date"
            ? DateTime.fromFormat(
                event.target.value,
                FORMAT_TYPE.YEAR_DAY
              ).toJSDate()
            : key === "amount"
            ? Number(event.target.value)
            : event.target.value,
      }));
    };
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end" marginRight={2}>
        <AccountingCell
          title={"準備金"}
          value={changeReserve}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setChangeReserve(event.target.value)
          }
          isInput
          onBlur={updateChangeReserve}
        />
        <AccountingCell title={"現在レジ金"} value={`${cashRegister}円`} />
      </Box>
      <Box display="flex" justifyContent="center" marginTop={2}>
        <Typography variant="h5">見込み売上</Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <AccountingCell title={"売上"} value={`${bookingRevenue}円`} />
        <AccountingCell title={"割引"} value={`${bookingDiscount}円`} />
        <AccountingCell title={"ホテル代"} value={`${bookingHotelFee}円`} />
        <AccountingCell title={"キャスト報酬"} value={`${bookingCastFee}円`} />
        <AccountingCell
          title={"カード売上"}
          value={`${bookingCardRevenue}円`}
        />
        <AccountingCell title={"その他"} value={`${paidOtherFee - cost}円`} />
        <AccountingCell title={"粗利"} value={`${bookingProfit}円`} />
      </Box>
      <Box display="flex" justifyContent="center" marginTop={2}>
        <Typography variant="h5">確定売上</Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <AccountingCell title={"売上"} value={`${paidRevenue}円`} />
        <AccountingCell title={"割引"} value={`${paidDiscount}円`} />
        <AccountingCell title={"ホテル代"} value={`${paidHotelFee}円`} />
        <AccountingCell title={"キャスト報酬"} value={`${paidCastFee}円`} />
        <AccountingCell title={"カード売上"} value={`${paidCardRevenue}円`} />
        <AccountingCell title={"その他"} value={`${paidOtherFee - cost}円`} />
        <AccountingCell title={"粗利"} value={`${paidProfit}円`} />
      </Box>
      <Box display="flex" justifyContent="center" marginTop={2}>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            border="1px solid #000"
            justifyContent="center"
            paddingY={3}
          >
            <Typography>その他売上</Typography>
          </Box>
          <Box display="flex">
            <Box padding={1} border="1px solid #000" width="150px">
              <Typography>日付</Typography>
            </Box>
            <Box padding={1} border="1px solid #000" width="150px">
              <Typography>店舗</Typography>
            </Box>
            <Box padding={1} border="1px solid #000" width="150px">
              <Typography>種類</Typography>
            </Box>
            <Box padding={1} border="1px solid #000" width="150px">
              <Typography>項目</Typography>
            </Box>
            <Box padding={1} border="1px solid #000" width="150px">
              <Typography>金額</Typography>
            </Box>
            <Box padding={1} border="1px solid #000" width="50px" />
          </Box>
          {revenueJournals.map((journal) => (
            <EditJournalRow
              key={journal.journalId}
              journal={journal}
              shops={shops}
            />
          ))}
          <AddJournalRow
            journal={addRevenueJournal}
            onJournalReq={(key: string) => addRevenueJournalReq(key)}
            shops={shops}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            border="1px solid #000"
            justifyContent="center"
            paddingY={3}
          >
            <Typography>その他経費</Typography>
          </Box>
          <Box display="flex">
            <Box padding={1} border="1px solid #000" width="150px">
              <Typography>日付</Typography>
            </Box>
            <Box padding={1} border="1px solid #000" width="150px">
              <Typography>店舗</Typography>
            </Box>
            <Box padding={1} border="1px solid #000" width="150px">
              <Typography>種類</Typography>
            </Box>
            <Box padding={1} border="1px solid #000" width="150px">
              <Typography>項目</Typography>
            </Box>
            <Box padding={1} border="1px solid #000" width="150px">
              <Typography>金額</Typography>
            </Box>
            <Box padding={1} border="1px solid #000" width="50px" />
          </Box>
          {costJournals.map((journal) => (
            <EditJournalRow
              key={journal.journalId}
              journal={journal}
              shops={shops}
            />
          ))}
          <AddJournalRow
            journal={addCostJournal}
            onJournalReq={(key: string) => addCostJournalReq(key)}
            shops={shops}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default AccountingTable;
