import { DataValidationError } from "constants/DataValidationError";
import { IsDate, IsNotEmpty, IsOptional } from "class-validator";
import { Type } from "class-transformer";

export default class BulkInsertCastShiftReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  castId!: number;

  @Type(() => Date)
  @IsDate()
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  planWorkStart!: Date;

  @Type(() => Date)
  @IsDate()
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  planWorkEnd!: Date;

  @IsOptional()
  castShiftId?: number;

  constructor(arg: {
    castId: number;
    planWorkStart: Date;
    planWorkEnd: Date;
    castShiftId?: number;
  }) {
    arg = arg || {};
    this.castId = arg.castId;
    this.planWorkStart = arg.planWorkStart;
    this.planWorkEnd = arg.planWorkEnd;
    this.castShiftId = arg.castShiftId;
  }
}
