import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import CastShiftRes from "types/res/castShift/CastShiftRes";
import CastNameRes from "types/res/castName/CastNameRes";
import { makeStyles } from "@material-ui/core/styles";
import OrderApi from "api/OrderApi";
import FormControlLabel from "@material-ui/core/FormControlLabel";

type Props = {
  formData: any;
  oldOrder: any;
  onChange: (key: string, value: any) => void;
};
const SpOrderDriverAndCast = ({ formData, oldOrder, onChange }: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      <Driver formData={formData} onChange={onChange} />
      <Divider style={{ margin: "10px 0" }} />
      <Cast formData={formData} />
      <Divider style={{ margin: "10px 0" }} />
      <OrderSendMail
        formData={formData}
        oldOrder={oldOrder}
        onChange={onChange}
      />
    </Box>
  );
};

type DriverProps = {
  formData: any;
  onChange: (key: string, value: any) => void;
};
const Driver = ({ formData, onChange }: DriverProps) => {
  const companyId = useSelector((state) => state.account.staff.companyId);
  const driverShifts = useSelector((state) => state.driverShift);
  useEffect(() => {
    onChange(
      "outwardDriver",
      driverShifts.find(
        (driverShift) => driverShift.driverId === formData?.outwardDriverId
      )?.driver || null
    );
  }, [formData?.outwardDriverId]);

  useEffect(() => {
    onChange(
      "returnDriver",
      driverShifts.find(
        (driverShift) => driverShift.driverId === formData?.returnDriverId
      )?.driver || null
    );
  }, [formData?.returnDriverId]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  const onClickReloadDriver = async () => {
    const order = await OrderApi.findOne(companyId, formData?.orderId);
    onChange("outwardDriverId", order?.outwardDriverId || null);
    onChange("returnDriverId", order?.returnDriverId || null);
  };

  return (
    <Box display="flex" flexDirection="column" marginTop={2}>
      <Box display="flex" alignItems="center">
        <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
          配車情報
        </Typography>
        {formData?.orderId && (
          <Button
            variant="contained"
            className={classes.button}
            color="secondary"
            onClick={async (event) => {
              event.persist();
              await onClickReloadDriver();
            }}
          >
            配車情報更新
          </Button>
        )}
      </Box>
      <Box display="flex">
        <Box display="flex" alignItems="center">
          <InputLabel htmlFor={"outwardDriverId"}>送Dr</InputLabel>
          <Select
            inputProps={{
              name: "outwardDriverId",
              id: "outwardDriverId",
            }}
            style={{ width: 150 }}
            value={formData["outwardDriverId"] || ""}
            onChange={(event) =>
              onChange("outwardDriverId", event.target.value)
            }
          >
            <option aria-label="None" value="" />
            {driverShifts?.map((driverShift) => (
              <MenuItem
                value={driverShift?.driverId}
                key={driverShift?.driverId}
              >
                {driverShift?.driver?.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display="flex" marginLeft={"10px"}>
          <InputLabel htmlFor={"returnDriverId"}>迎Dr</InputLabel>
          <Select
            inputProps={{
              name: "returnDriverId",
              id: "returnDriverId",
            }}
            style={{ width: 150 }}
            value={formData["returnDriverId"] || ""}
            onChange={(event) => onChange("returnDriverId", event.target.value)}
          >
            <option aria-label="None" value="" />
            {driverShifts?.map((driverShift) => (
              <MenuItem
                value={driverShift?.driverId}
                key={driverShift?.driverId}
              >
                {driverShift?.driver?.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography>送迎必要有無</Typography>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) =>
                onChange("isUseOutward", event.target.checked)
              }
              checked={Boolean(formData["isUseOutward"])}
            />
          }
          label="送"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) =>
                onChange("isUseReturn", event.target.checked)
              }
              checked={Boolean(formData["isUseReturn"])}
            />
          }
          label="迎"
        />
      </Box>
    </Box>
  );
};
type CastProps = {
  formData: any;
};
const Cast = ({ formData }: CastProps) => {
  const castShift = useSelector((state) => state.castShift);
  const castNames = useSelector((state) => state.castName);
  const [selectCastShift, setSelectCastShift] = useState<CastShiftRes>();
  const [selectCastName, setSelectCastName] = useState<CastNameRes>();
  useEffect(() => {
    setSelectCastShift(
      castShift?.find((shift) =>
        shift?.cast?.castNames
          ?.map((castName) => castName.castNameId)
          .includes(formData.castNameId)
      )
    );
    setSelectCastName(
      castNames?.find(
        (castName) => castName.castNameId === formData?.castNameId
      )
    );
  }, [formData?.castNameId, castShift]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
        キャスト
      </Typography>
      <TextField
        label="キャスト名"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastShift?.cast.displayName || ""}
      />
      <TextField
        label="電話番号"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastShift?.cast.tel || ""}
      />
      <TextField
        label="年齢"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastName?.age || ""}
      />
      <TextField
        label="身長"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastName?.bodyHeight || ""}
      />
      <TextField
        label="カップ"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastName?.cup || ""}
      />
      <TextField
        label="バスト"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastName?.bust || ""}
      />
      <TextField
        label="ウエスト"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastName?.waist || ""}
      />
      <TextField
        label="ヒップ"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastName?.hip || ""}
      />
      <TextField
        label="備考"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastShift?.cast.remarks || ""}
      />
      <TextField
        label="当日シフトキャストメモ"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastShift?.castMemo || ""}
      />
      <TextField
        label="当日シフトスタッフメモ"
        size="small"
        variant="outlined"
        style={{ margin: 5 }}
        disabled
        value={selectCastShift?.staffMemo || ""}
      />
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1" style={{ marginRight: "10px" }}>
          NGエリア
        </Typography>
        <Typography variant="body2">
          {selectCastShift?.cast.ngAreas?.map((area) => area.name).join(",") ||
            "なし"}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" style={{ marginTop: "5px" }}>
        <Typography variant="subtitle1" style={{ marginRight: "10px" }}>
          NG顧客
        </Typography>
        <Typography variant="body2">
          {selectCastShift?.cast.ngGuests
            ?.map((guest) => `${guest.name}(${guest.tel})`)
            .join(",") || "なし"}
        </Typography>
      </Box>
    </Box>
  );
};

type OrderSendMailProps = {
  formData: any;
  oldOrder: any;
  onChange: (key: string, value: any) => any;
};
const OrderSendMail = ({
  formData,
  oldOrder,
  onChange,
}: OrderSendMailProps) => {
  return (
    <Box display="flex" flexDirection="column" marginTop={2}>
      <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
        メール送信対象
      </Typography>
      <FormControlLabel
        style={{ marginTop: "10px" }}
        control={
          <Checkbox
            checked={formData?.sendMailCast}
            onChange={(event) => onChange("sendMailCast", event.target.checked)}
          />
        }
        label={"キャスト"}
      />
      <FormControlLabel
        style={{ marginTop: "5px" }}
        control={
          <Checkbox
            checked={formData?.sendMailOutwardDriver}
            onChange={(event) =>
              onChange("sendMailOutwardDriver", event.target.checked)
            }
          />
        }
        label={"送りドライバー"}
      />
      <FormControlLabel
        style={{ marginTop: "5px" }}
        control={
          <Checkbox
            checked={formData?.sendMailReturnDriver}
            onChange={(event) =>
              onChange("sendMailReturnDriver", event.target.checked)
            }
          />
        }
        label={"迎えドライバー"}
      />
      {oldOrder?.castNameId &&
        formData?.castNameId !== oldOrder?.castNameId && (
          <FormControlLabel
            style={{ marginTop: "10px" }}
            control={
              <Checkbox
                checked={formData?.sendCancelMailOldCast}
                onChange={(event) =>
                  onChange("sendCancelMailOldCast", event.target.checked)
                }
              />
            }
            label={"旧キャストにキャンセルメール"}
          />
        )}
      {oldOrder?.outwardDriverId &&
        formData?.outwardDriverId !== oldOrder?.outwardDriverId && (
          <FormControlLabel
            style={{ marginTop: "5px" }}
            control={
              <Checkbox
                checked={formData?.sendCancelMailOldOutwardDriver}
                onChange={(event) =>
                  onChange(
                    "sendCancelMailOldOutwardDriver",
                    event.target.checked
                  )
                }
              />
            }
            label={"旧送りドライバーにキャンセルメール"}
          />
        )}
      {oldOrder?.returnDriverId &&
        formData?.returnDriverId !== oldOrder?.returnDriverId && (
          <FormControlLabel
            style={{ marginTop: "5px" }}
            control={
              <Checkbox
                checked={formData?.sendCancelMailOldReturnDriver}
                onChange={(event) =>
                  onChange(
                    "sendCancelMailOldReturnDriver",
                    event.target.checked
                  )
                }
              />
            }
            label={"旧迎えドライバーにキャンセルメール"}
          />
        )}
    </Box>
  );
};
export default SpOrderDriverAndCast;
