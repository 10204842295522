import RequestUtils from "utils/RequestUtils";
import HotelRes from "types/res/hotel/HotelRes";
import CreateHotelReq from "types/req/hotel/CreateHotelReq";
import UpdateHotelReq from "types/req/hotel/UpdateHotelReq";
import DeleteHotelReq from "types/req/hotel/DeleteHotelReq";

export default class HotelApi {
  private constructor() {}

  static async findAll(companyId: number): Promise<HotelRes[]> {
    return await RequestUtils.getArray(HotelRes, `/hotel/findAll/${companyId}`);
  }

  static async create(companyId: number, data: CreateHotelReq) {
    return await RequestUtils.post(
      HotelRes,
      `/hotel/create/${companyId}`,
      data
    );
  }

  static async bulkInsert(companyId: number, data: CreateHotelReq[]) {
    return await RequestUtils.postArray(
      HotelRes,
      `/hotel/bulkInsert/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    data: UpdateHotelReq
  ): Promise<HotelRes> {
    return await RequestUtils.post(
      HotelRes,
      `/hotel/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteHotelReq) {
    return await RequestUtils.postVoid(`/hotel/delete/${companyId}`, data);
  }
}
