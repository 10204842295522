import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { fetchDriver } from "redux/actions/driver";
import { DateTime } from "luxon";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import {
  Box,
  Button,
  createStyles,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BulkInsertDriverShiftReq from "types/req/driverShift/BulkInsertDriverShiftReq";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  bulkInsertDriverShift,
  deleteDriverShift,
  fetchDriverShift,
} from "redux/actions/driverShift";
import merge from "ts-deepmerge";
import { Link } from "react-router-dom";
import { fetchShops } from "redux/actions/shop";
import TimeUtils from "utils/TimeUtils";
import { Delete } from "@material-ui/icons";
type ShiftData = {
  [driverId: number]: {
    [date: string]: {
      startDate: {
        date?: string;
        hour?: number;
        minute?: number;
        dateTime?: Date;
      };
      endDate: {
        date?: string;
        hour?: number;
        minute?: number;
        dateTime?: Date;
      };
      driverShiftId?: number;
    };
  };
};
const minutes = ["00", "15", "30", "45"];
const DriverShiftBulkInsert = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const drivers = useSelector((state) => state.driver);
  const driverShifts = useSelector((state) => state.driverShift);
  const shops = useSelector((state) => state.shop);
  const [dateDiff, setDateDiff] = useState<number>(7);
  const [dates, setDates] = useState<string[]>([]);
  const [requestData, setRequestData] = useState<ShiftData>({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .plus({ days: 6 })
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [requestStartDate, setRequestStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [requestEndDate, setRequestEndDate] = useState(
    DateTime.local()
      .plus({ days: 6 })
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [minOpeningTime, setMinOpeningTime] = useState<number>(0);
  const [maxClosingTime, setMaxClosingTime] = useState<number>(0);
  const [hours, setHours] = useState<string[]>([]);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
      cell: {
        position: "sticky",
      },
    })
  );
  const classes = useStyles();

  useEffect(() => {
    if (!shops.length) return;
    setMinOpeningTime(TimeUtils.minOpeningTimeInShop(shops));
    setMaxClosingTime(TimeUtils.maxClosingTimeInShop(shops));
  }, [shops]);

  useEffect(() => {
    setDateDiff(
      DateTime.fromJSDate(requestEndDate).diff(
        DateTime.fromJSDate(requestStartDate),
        "days"
      ).days + 1
    );
  }, [requestStartDate, requestEndDate]);

  useEffect(() => {
    setDates(
      [...Array(dateDiff)].map((_, i) =>
        DateTime.fromJSDate(requestStartDate)
          .plus({ days: i })
          .toFormat(FORMAT_TYPE.YEAR_DAY)
      )
    );
  }, [dateDiff, requestStartDate]);

  useEffect(() => {
    dispatch(fetchDriver(companyId));
    dispatch(fetchShops(companyId));
    dispatch(
      fetchDriverShift(
        companyId,
        DateTimeUtils.toFormatAsLocalTimezone(
          requestStartDate,
          FORMAT_TYPE.YEAR_DAY
        ),
        DateTimeUtils.toFormatAsLocalTimezone(
          DateTime.fromJSDate(requestEndDate).plus({ days: 1 }).toJSDate(),
          FORMAT_TYPE.YEAR_DAY
        )
      )
    );
  }, [companyId, requestStartDate, requestEndDate]);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
  }, [error, requestData]);

  useEffect(() => {
    setRequestData({});
    driverShifts.forEach((driverShift) => {
      const changeStartDate =
        minOpeningTime <= driverShift.planWorkStart.getHours();
      const startDate = changeStartDate
        ? DateTime.fromJSDate(driverShift.planWorkStart).toFormat(
            FORMAT_TYPE.YEAR_DAY
          )
        : DateTime.fromJSDate(driverShift.planWorkStart)
            .minus({ days: 1 })
            .toFormat(FORMAT_TYPE.YEAR_DAY);
      const startHour = changeStartDate
        ? driverShift.planWorkStart.getHours()
        : driverShift.planWorkStart.getHours() + 24;
      const changeEndDate = TimeUtils.isInAllDays(shops)
        ? true
        : changeStartDate &&
          maxClosingTime < driverShift.planWorkEnd.getHours();
      const endDate = changeEndDate
        ? DateTime.fromJSDate(driverShift.planWorkEnd).toFormat(
            FORMAT_TYPE.YEAR_DAY
          )
        : DateTime.fromJSDate(driverShift.planWorkEnd)
            .minus({ days: 1 })
            .toFormat(FORMAT_TYPE.YEAR_DAY);
      const endHour = changeEndDate
        ? driverShift.planWorkEnd.getHours()
        : driverShift.planWorkEnd.getHours() + 24;
      {
        const data = {
          [driverShift.driverId]: {
            [startDate]: {
              startDate: {
                date: startDate,
                hour: startHour,
                minute: String(driverShift.planWorkStart.getMinutes()).padStart(
                  2,
                  "0"
                ),
                dateTime: DateTime.fromFormat(startDate, FORMAT_TYPE.YEAR_DAY)
                  .plus({
                    hours: startHour,
                    minutes: driverShift.planWorkStart.getMinutes(),
                  })
                  .toJSDate(),
              },
              endDate: {
                date: endDate,
                hour: endHour,
                minute: String(driverShift.planWorkEnd.getMinutes()).padStart(
                  2,
                  "0"
                ),
                dateTime: DateTime.fromFormat(endDate, FORMAT_TYPE.YEAR_DAY)
                  .plus({
                    hours: endHour,
                    minutes: driverShift.planWorkEnd.getMinutes(),
                  })
                  .toJSDate(),
              },
              driverShiftId: driverShift.driverShiftId,
            },
          },
        };
        setRequestData((prev: any) => ({ ...merge(prev, data) }));
      }
    });
  }, [driverShifts]);

  useEffect(() => {
    if (!maxClosingTime && !minOpeningTime) return;
    const timeDiff = TimeUtils.isInAllDays(shops)
      ? maxClosingTime - minOpeningTime
      : maxClosingTime - minOpeningTime + 24;

    const arrayHours = [...Array(timeDiff).keys()].map((a) =>
      String(a + minOpeningTime).padStart(2, "0")
    );
    setHours(arrayHours);
  }, [minOpeningTime, maxClosingTime]);

  const onChangeDate = (
    date: string,
    driverId: number,
    key: "startDate" | "endDate",
    hour?: string,
    minute?: string
  ) => {
    const numberHour = Number(
      hour || requestData?.[driverId]?.[date]?.[key]?.hour
    );
    const dateHour =
      numberHour > 23
        ? String(numberHour - 24).padStart(2, "0")
        : String(numberHour).padStart(2, "0");
    let dateString = requestData?.[driverId]?.[date]?.[key]?.date || date;
    if (numberHour > 23) {
      dateString = DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY)
        .plus({
          days: 1,
        })
        .toFormat(FORMAT_TYPE.YEAR_DAY);
    } else {
      dateString = DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY).toFormat(
        FORMAT_TYPE.YEAR_DAY
      );
    }

    const dateTime = DateTime.fromFormat(
      `${dateString} ${
        dateHour || requestData?.[driverId]?.[date]?.[key]?.hour || "00"
      }:${minute || requestData?.[driverId]?.[date]?.[key]?.minute || "00"}`,
      FORMAT_TYPE.YEAR_DATE_TIME
    ).toJSDate();
    const data = {
      [driverId]: {
        [date]: {
          [key]: {
            date: requestData?.[driverId]?.[date]?.[key]?.date || dateString,
            hour:
              hour === undefined
                ? requestData?.[driverId]?.[date]?.[key]?.hour
                : hour,
            minute:
              minute === undefined
                ? requestData?.[driverId]?.[date]?.[key]?.minute
                : minute,
            dateTime,
          },
        },
      },
    };
    setRequestData((prev: any) => ({ ...merge(prev, data) }));
  };

  const createShift = async () => {
    const data = Object.entries(requestData)
      .flatMap(([driverId, dateObject]) => {
        return Object.entries(dateObject)
          .filter(
            ([_, value]) =>
              value?.startDate?.hour &&
              value?.startDate?.minute &&
              value?.endDate?.hour &&
              value?.endDate?.minute
          )
          .flatMap(([dateString, value]) => {
            if (!value?.startDate?.dateTime || !value?.endDate?.dateTime) {
              const driver = drivers.find(
                (driver) => driver.driverId === Number(driverId)
              );
              setError(`${driver?.name}の${dateString}に入力不備があります。`);
            } else {
              return new BulkInsertDriverShiftReq({
                driverId: Number(driverId),
                planWorkStart: value.startDate.dateTime,
                planWorkEnd: value.endDate.dateTime,
                driverShiftId: value.driverShiftId,
              });
            }
          });
      })
      .filter((v): v is BulkInsertDriverShiftReq => typeof v !== "undefined");
    if (!error && data.length) {
      const result = await dispatch(bulkInsertDriverShift(companyId, data));
      if (result instanceof Array) {
        setSuccess(true);
      }
    }
  };
  return (
    <>
      <Box display="flex" marginTop={2}>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          component={Link}
          to="/driverShift"
        >
          一覧画面へ
        </Button>
        <TextField
          type="date"
          label="開始日"
          style={{ margin: "0 10px", width: "200px" }}
          value={DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) => {
            setStartDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .toJSDate()
            );
            setEndDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .plus({ days: 6 })
                .toJSDate()
            );
          }}
          onBlur={(event) => {
            setRequestStartDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .toJSDate()
            );
            setRequestEndDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .plus({ days: 6 })
                .toJSDate()
            );
          }}
        />
        <TextField
          type="date"
          label="終了日"
          style={{ margin: "0 10px", width: "200px" }}
          value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) => {
            setStartDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .minus({ days: 6 })
                .toJSDate()
            );
            setEndDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .toJSDate()
            );
          }}
          onBlur={(event) => {
            setRequestStartDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .minus({ days: 6 })
                .toJSDate()
            );
            setRequestEndDate(
              DateTime.fromISO(event.target.value as string)
                .startOf("days")
                .toJSDate()
            );
          }}
        />
      </Box>
      {error && (
        <Alert severity="error">
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      <div
        style={{
          overflow: "scroll",
          width: `100vw`,
          height: `50vh`,
        }}
      >
        <Table className="sticky_table">
          <TableHead>
            <TableRow>
              <TableCell />
              {dates.map((date) => (
                <TableCell key={date} className="sticky">
                  {date}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {drivers.map((driver) => (
              <TableRow key={driver.driverId}>
                <TableCell>{driver.name}</TableCell>
                {dates.map((date) => (
                  <TableCell key={date}>
                    <Box display="flex">
                      <Box display="flex" alignItems="center">
                        <Select
                          native
                          style={{ minWidth: "50px" }}
                          value={
                            requestData[driver.driverId]?.[date]?.startDate
                              ?.hour
                              ? String(
                                  requestData[driver.driverId]?.[date]
                                    ?.startDate?.hour
                                ).padStart(2, "0")
                              : ""
                          }
                          onChange={(event) =>
                            onChangeDate(
                              date,
                              driver.driverId,
                              "startDate",
                              String(event.target.value)
                            )
                          }
                        >
                          <option value={""} />
                          {hours.map((hour: string) => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Select>
                        :
                        <Select
                          native
                          style={{ minWidth: "50px" }}
                          value={
                            requestData[driver.driverId]?.[date]?.startDate
                              ?.minute
                              ? String(
                                  requestData[driver.driverId]?.[date]
                                    ?.startDate?.minute
                                ).padStart(2, "0")
                              : ""
                          }
                          onChange={(event) => {
                            onChangeDate(
                              date,
                              driver.driverId,
                              "startDate",
                              undefined,
                              String(event.target.value)
                            );
                          }}
                        >
                          <option value="" />
                          {minutes.map((minute) => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </Select>
                      </Box>
                      ~
                      <Box display="flex" alignItems="center">
                        <Select
                          native
                          style={{ minWidth: "50px" }}
                          value={
                            requestData[driver.driverId]?.[date]?.endDate?.hour
                              ? String(
                                  requestData[driver.driverId]?.[date]?.endDate
                                    ?.hour
                                ).padStart(2, "0")
                              : ""
                          }
                          onChange={(event) =>
                            onChangeDate(
                              date,
                              driver.driverId,
                              "endDate",
                              String(event.target.value)
                            )
                          }
                        >
                          <option value="" />
                          {hours.map((hour: string) => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Select>
                        :
                        <Select
                          native
                          style={{ minWidth: "50px" }}
                          value={
                            requestData[driver.driverId]?.[date]?.endDate
                              ?.minute
                              ? String(
                                  requestData[driver.driverId]?.[date]?.endDate
                                    ?.minute
                                ).padStart(2, "0")
                              : ""
                          }
                          onChange={(event) =>
                            onChangeDate(
                              date,
                              driver.driverId,
                              "endDate",
                              undefined,
                              String(event.target.value)
                            )
                          }
                        >
                          <option value="" />
                          {minutes.map((minute) => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </Select>
                      </Box>
                      {requestData[driver.driverId]?.[date]?.driverShiftId && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          onClick={() => {
                            if (window.confirm("本当に削除しますか？")) {
                              dispatch(
                                deleteDriverShift(companyId, {
                                  driverShiftId:
                                    requestData[driver.driverId][date]
                                      .driverShiftId || 0,
                                })
                              );
                            }
                          }}
                        >
                          <Delete />
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {success && (
        <Alert>
          <AlertTitle>保存しました</AlertTitle>
        </Alert>
      )}
      <Box display="flex" justifyContent="center" marginTop={2}>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={createShift}
        >
          一括登録
        </Button>
      </Box>
    </>
  );
};

export default DriverShiftBulkInsert;
