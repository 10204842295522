import { reducerWithInitialState } from "typescript-fsa-reducers";
import payoffActions from "redux/actions/payoff";
import OrderRes from "types/res/order/OrderRes";
export type PayoffState = OrderRes[];

const initialState = [] as PayoffState;

const payoffReducer = reducerWithInitialState(initialState)
  .case(payoffActions.putCollectionAction.done, (state, payload) => {
    return state.map((item) =>
      item.orderId === payload.result.orderId ? payload.result : item
    );
  })
  .case(payoffActions.putPayoffAction.done, (state, payload) => {
    return state.map((item) =>
      item.orderId === payload.result.orderId ? payload.result : item
    );
  })
  .case(payoffActions.fetchPayoffAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(payoffActions.spFetchPayoffAction.done, (state, payload) => {
    return [...payload.result];
  });
export default payoffReducer;
