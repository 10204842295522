import { reducerWithInitialState } from "typescript-fsa-reducers";
import staffActions from "redux/actions/staff";
import StaffRes from "types/res/staff/StaffRes";
export type StaffState = StaffRes[];

const initialState = [] as StaffState;

const staffReducer = reducerWithInitialState(initialState)
  .case(staffActions.addStaffAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(staffActions.updateStaffAction.done, (state, payload) => {
    return state.map((item) =>
      item.staffId === payload.params.staffId ? payload.result : item
    );
  })
  .case(staffActions.deleteStaffAction.done, (state, payload) => {
    return state.filter((item) => item.staffId !== payload.params.staffId);
  })
  .case(staffActions.fetchStaffAction.done, (state, payload) => {
    return [...payload.result];
  });

export default staffReducer;
