import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty } from "class-validator";

export default class DeleteNotelClassReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly notelClassId: number;

  constructor(arg: { notelClassId: number }) {
    arg = arg || {};
    this.notelClassId = arg.notelClassId;
  }
}
