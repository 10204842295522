import RequestUtils from "utils/RequestUtils";
import DriverRes from "types/res/driver/DriverRes";
import CreateDriverReq from "types/req/driver/CreateDriverReq";
import UpdateDriverReq from "types/req/driver/UpdateDriverReq";
import DeleteDriverReq from "types/req/driver/DeleteDriverReq";
import querystring from "querystring";

export default class DriverApi {
  private constructor() {}

  static async findCti(companyId: number, tel: string): Promise<DriverRes> {
    return await RequestUtils.get(
      DriverRes,
      `/driver/findCti/${companyId}/${tel}`
    );
  }

  static async findAll(companyId: number): Promise<DriverRes[]> {
    return await RequestUtils.getArray(
      DriverRes,
      `/driver/findAll/${companyId}`
    );
  }

  static async spFind(
    companyId: number,
    date: string,
    limit: number,
    offset: number
  ): Promise<DriverRes[]> {
    const query = {
      date,
      limit,
      offset,
    };
    return await RequestUtils.getArray(
      DriverRes,
      `/driver/spFind/${companyId}?${querystring.stringify(query)}`
    );
  }

  static async spCount(companyId: number) {
    return await RequestUtils.count(`/driver/spCount/${companyId}`);
  }

  static async create(companyId: number, data: CreateDriverReq) {
    return await RequestUtils.post(
      DriverRes,
      `/driver/create/${companyId}`,
      data
    );
  }

  static async bulkInsert(companyId: number, data: CreateDriverReq[]) {
    return await RequestUtils.postArray(
      DriverRes,
      `/driver/bulkInsert/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    driverId: number,
    data: UpdateDriverReq
  ): Promise<DriverRes> {
    return await RequestUtils.post(
      DriverRes,
      `/driver/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteDriverReq) {
    return await RequestUtils.postVoid(`/driver/delete/${companyId}`, data);
  }
}
