import Timeline, {
  DateHeader,
  TimelineHeaders,
  TodayMarker,
} from "react-calendar-timeline";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "redux/actions/order";
import {
  Box,
  createStyles,
  Icon,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import {
  addDriverShift,
  fetchDriverShift,
  updateDriverShift,
} from "redux/actions/driverShift";
import { fetchDriver } from "redux/actions/driver";
import OrderStatus from "../../types/enum/OrderStatus";
import EnumUtils from "../../utils/EnumUtils";
import { fetchShops } from "redux/actions/shop";
import DriverScheduleOrderItem from "./components/DriverScheduleOrderItem";
import DriverScheduleShiftItem from "./components/DriverScheduleShiftItem";
import OrderRes from "../../types/res/order/OrderRes";
import useModal from "../../hooks/useModal";
import DriverScheduleOnItemMove from "./components/DriverScheduleOnItemMove";
import ShiftStatus from "../../types/enum/ShiftStatus";
import DriverShiftRes from "../../types/res/driverShift/DriverShiftRes";
import DriverScheduleOnShiftMove from "./components/DriverScheduleOnShiftMove";
import FormModal from "../../components/FormModal";
import CreateDriverShiftReq from "../../types/req/driverShift/CreateDriverShiftReq";
import UpdateDriverShiftReq from "../../types/req/driverShift/UpdateDriverShiftReq";
import { FormType } from "components/FormField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TimeUtils from "utils/TimeUtils";
import DriverStatus from "types/enum/DriverStatus";
import { simpleFetchGuestCategory } from "redux/actions/guestCategory";
import { fetchOneCastName } from "redux/actions/castName";
import { fetchAdditionalTime } from "redux/actions/additionalTime";
import { fetchCosplay } from "redux/actions/cosplay";
import { fetchCourse } from "redux/actions/course";
import { fetchNotelClass } from "redux/actions/notelClass";
import { fetchNomination } from "redux/actions/nomination";
import { fetchArea } from "redux/actions/area";
import { fetchHotel } from "redux/actions/hotel";
import DriverScheduleOnItemCreate from "pages/DriverSchedule/components/DriverScheduleOnItemCreate";
import { CastScheduleItemColor } from "types/enum/CastScheduleItemColor";
import CreateCarTransferScheduleReq from "types/req/carTransferSchedule/CreateCarTransferScheduleReq";
import DriverScheduleCarTransferScheduleItem from "pages/DriverSchedule/components/DriverScheduleCarTransferScheduleItem";
import { fetchOption } from "redux/actions/option";
import { fetchDiscount } from "redux/actions/discount";

const DriverSchedule = () => {
  const dispatch = useDispatch();
  const [, setShowMoveForm] = useModal("driverScheduleOnItemMove");
  const [, setShowShiftMoveForm] = useModal("driverScheduleOnShiftMove");
  const [, setDriverForm] = useModal("driverSchedule");
  const [, setShowDriverScheduleOnItemCreate] = useModal(
    "driverScheduleOnItemCreate"
  );

  const companyId = useSelector((state) => state.account.staff.companyId);
  const orders = useSelector((state) => state.order);
  const drivers = useSelector((state) => state.driver);
  const driverShifts = useSelector((state) => state.driverShift);
  const shops = useSelector((state) => state.shop);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [minDate, setMinDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [maxDate, setMaxDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );

  const forms = [
    {
      label: "ドライバー名",
      key: "driverId",
      type: FormType.Select,
      options: drivers?.map((driver) => ({
        id: driver?.driverId,
        name: driver?.name,
      })),
    },
    {
      label: "予定勤務開始",
      key: "planWorkStart",
      type: FormType.IntervalDateTime,
      minuteStep: 15,
    },
    {
      label: "予定勤務終了",
      key: "planWorkEnd",
      type: FormType.IntervalDateTime,
      minuteStep: 15,
    },
    {
      label: "実勤務開始",
      key: "actualWorkStart",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "実勤務終了",
      key: "actualWorkEnd",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "休憩開始",
      key: "workOffStart",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "休憩終了",
      key: "workOffEnd",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "ステータス",
      key: "status",
      type: FormType.Select,
      options: Object.entries(ShiftStatus).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "スケジュールカラー",
      key: "scheduleItemColor",
      type: FormType.Select,
      options: Object.entries(CastScheduleItemColor).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "スタッフメモ",
      key: "staffMemo",
      type: FormType.Text,
    },
    {
      label: "ドライバーメモ",
      key: "driverMemo",
      type: FormType.Text,
    },
  ];

  const [groups, setGroups] = useState<{ id: number; title: string }[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [stateOrders, setStateOrders] = useState<OrderRes[]>([]);
  const [stateShifts, setStateShifts] = useState<DriverShiftRes[]>([]);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  const defaultDuration = (order: OrderRes) => {
    if (order.departureTime && order.planInTime) {
      if (
        DateTime.fromJSDate(order.planInTime).diff(
          DateTime.fromJSDate(order.departureTime),
          "minutes"
        ).minutes > 5
      ) {
        return DateTime.fromJSDate(order.planInTime).diff(
          DateTime.fromJSDate(order.departureTime),
          "minutes"
        ).minutes;
      } else {
        return 5;
      }
    } else {
      return 30;
    }
  };

  useEffect(() => {
    setStateOrders(orders);
  }, [orders]);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchDriver(companyId));
    // 受注メールのために必要
    dispatch(simpleFetchGuestCategory(companyId));
    dispatch(fetchAdditionalTime(companyId));
    dispatch(fetchCosplay(companyId));
    dispatch(fetchCourse(companyId));
    dispatch(fetchNotelClass(companyId));
    dispatch(fetchNomination(companyId));
    dispatch(fetchArea(companyId));
    dispatch(fetchHotel(companyId));
    dispatch(fetchOption(companyId));
    dispatch(fetchDiscount(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (!shops.length) return;
    dispatch(
      fetchDriverShift(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)
      )
    );
    dispatch(
      fetchOrder(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate)
          .plus({ days: TimeUtils.isInAllDays(shops) ? 1 : 0 })
          .toFormat(FORMAT_TYPE.YEAR_DAY)
      )
    );
  }, [startDate, endDate, shops]);

  useEffect(() => {
    setStateShifts(driverShifts);
  }, [driverShifts]);

  useEffect(() => {
    const newGroups = sortFunc(
      stateShifts
        .map((driverShift) => ({
          id: driverShift.driver?.driverId,
          title: `${driverShift.driver?.name}`,
          status: EnumUtils.mapToEnum(DriverStatus, driverShift.driverStatus),
          carInfo: `${driverShift.driver?.carType || ""}${
            driverShift.driver?.carNumber || ""
          }${driverShift.driver?.carColor || ""}`,
          returnTime: driverShift.planReturnTime
            ? DateTime.fromJSDate(driverShift.planReturnTime).toFormat(
                FORMAT_TYPE.TIME
              )
            : "",
          stackItems: true,
          height: 100,
          startTime:
            (driverShift.actualWorkEnd || driverShift.planWorkEnd).getTime() <
            new Date().getTime()
              ? DateTime.fromJSDate(driverShift.planWorkStart)
                  .plus({ days: 2 })
                  .toJSDate()
                  .getTime()
              : (
                  driverShift.actualWorkStart || driverShift.planWorkStart
                ).getTime(),
        }))
        .filter(
          (element, index, self) =>
            self.findIndex((e) => e.title === element.title) === index
        ),
      ["startTime"]
    );
    setGroups([{ id: 0, title: "未アサイン" }].concat(newGroups));
  }, [stateShifts]);

  useEffect(() => {
    if (!shops.length) return;
    setStartTime(
      Number(
        shops.reduce(
          (a, b) =>
            a < Number(b.openingTime.split(":")[0])
              ? a
              : Number(b.openingTime.split(":")[0]),
          24
        )
      )
    );
    setEndTime(TimeUtils.maxClosingTimeInShop(shops));
  }, [shops]);

  useEffect(() => {
    const startAndMinDate = DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .startOf("day")
      .plus({ hours: startTime })
      .toJSDate();
    const endAndMaxDate = DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .startOf("day")
      .plus({ hours: endTime })
      .toJSDate();
    setStartDate(startAndMinDate);
    setEndDate(endAndMaxDate);
  }, [startTime, endTime]);

  useEffect(() => {
    const items = stateOrders
      .filter(
        (order) =>
          ![OrderStatus.cancel, OrderStatus.hold].includes(
            EnumUtils.mapToEnum(OrderStatus, order.status) || OrderStatus.hold
          ) &&
          (order.departureTime || order.planInTime) &&
          order.courseTime
      )
      .flatMap((order) => {
        const o = [];
        if (order.outwardDriverId || order.isUseOutward) {
          o.push({
            id: `outward-${order.orderId}`,
            group: order.outwardDriverId || 0,
            title: order?.castName?.name || "未設定",
            color: "#6d9eeb",
            start_time: Number(
              DateTime.fromJSDate(order.departureTime || new Date()).toFormat(
                "x"
              )
            ),
            end_time: Number(
              order?.outwardDuration
                ? DateTime.fromJSDate(order.departureTime || new Date())
                    .plus({ minutes: order.outwardDuration })
                    .toFormat("x")
                : DateTime.fromJSDate(order.departureTime || new Date())
                    .plus({ minutes: defaultDuration(order) })
                    .toFormat("x")
            ),
            canChangeGroup: true,
            canMove: true,
            canResize: false,
            itemProps: {
              style: {
                color: order?.isSentEmailToOutwardDriver ? "red" : "black",
              },
            },
          });
        }
        if (order.returnDriverId || order.isUseReturn) {
          o.push({
            id: `return-${order.orderId}`,
            group: order.returnDriverId || 0,
            title: order?.castName?.name || "未設定",
            color: "#d5a6bd",
            start_time: Number(
              order?.returnDuration
                ? DateTime.fromJSDate(
                    order.actualEndTime || order.planOutTime || new Date()
                  )
                    .minus({ minutes: order.returnDuration })
                    .toFormat("x")
                : DateTime.fromJSDate(
                    order.actualEndTime || order.planOutTime || new Date()
                  )
                    .minus({ minutes: defaultDuration(order) })
                    .toFormat("x")
            ),
            end_time: Number(
              DateTime.fromJSDate(
                order.actualEndTime || order.planOutTime || new Date()
              ).toFormat("x")
            ),
            canChangeGroup: true,
            canMove: true,
            canResize: false,
            itemProps: {
              style: {
                color: order?.isSentEmailToReturnDriver ? "red" : "black",
              },
            },
          });
        }
        return o;
      });
    const shift = stateShifts.map((shift) => ({
      id: `shift-${shift.driverShiftId}`,
      group: shift?.driverId,
      title: `${
        EnumUtils.mapToEnum(ShiftStatus, shift.status) === ShiftStatus.absence
          ? "当日欠勤"
          : shift.actualWorkStart
          ? "実出勤"
          : "出勤予定"
      }`,
      color: shift?.scheduleItemColor
        ? shift.scheduleItemColor
        : shiftColor(shift.status),
      start_time: Number(
        DateTime.fromJSDate(
          shift?.actualWorkStart
            ? shift.actualWorkStart
            : shift?.planWorkStart || new Date()
        ).toFormat("x")
      ),
      end_time: Number(
        DateTime.fromJSDate(
          shift?.actualWorkEnd
            ? shift?.actualWorkEnd
            : shift?.planWorkEnd || new Date()
        ).toFormat("x")
      ),
      driverShift: shift,
      canChangeGroup: false,
      canMove: true,
      canResize: "both",
    }));
    const rests = stateShifts
      .flatMap((shift) => {
        if (shift.workOffStart && shift.workOffEnd) {
          return {
            id: `rest-${shift.driverShiftId}`,
            group: shift.driverId,
            title: "休憩",
            color: "#949494",
            start_time: Number(
              DateTime.fromJSDate(shift.workOffStart || new Date()).toFormat(
                "x"
              )
            ),
            end_time: Number(
              DateTime.fromJSDate(shift.workOffEnd || new Date()).toFormat("x")
            ),
            driverShift: shift,
            canChangeGroup: false,
            canMove: false,
            canResize: false,
          };
        }
      })
      .filter(Boolean);
    const carTransferSchedules = stateShifts.flatMap((shift) => {
      return shift.driver.carTransferSchedules?.flatMap(
        (carTransferSchedule) => {
          return {
            id: `carTransferSchedule-${carTransferSchedule.carTransferScheduleId}`,
            group: shift.driverId,
            title: carTransferSchedule.title,
            carTransferScheduleId: carTransferSchedule.carTransferScheduleId,
            color: "#7d6464",
            start_time: Number(
              DateTime.fromJSDate(carTransferSchedule.startTime).toFormat("x")
            ),
            end_time: Number(
              DateTime.fromJSDate(carTransferSchedule.endTime).toFormat("x")
            ),
          };
        }
      );
    });
    const allShift = [...shift, ...rests, ...carTransferSchedules];
    setItems([...items, ...allShift]);
  }, [stateShifts, stateOrders]);

  const defaultSortFunc = (
    a: any,
    b: any,
    key: string,
    direction = 1,
    nullsFirst = 1
  ) => {
    if (a[key] == undefined && b[key] == undefined) return 0;
    if (a[key] == undefined) return nullsFirst * 1;
    if (b[key] == undefined) return nullsFirst * -1;
    if (a[key] > b[key]) return direction * 1;
    if (a[key] < b[key]) return direction * -1;
    return 0;
  };
  const sortFunc = (data: any[], keys: string[]) => {
    const _data = data.slice();
    _data.sort((a, b) => {
      let order = 0;
      keys.some((key) => {
        order = defaultSortFunc(a, b, key);
        return !!order;
      });
      return order;
    });
    return _data;
  };

  const shiftColor = (shiftStatus: ShiftStatus) => {
    switch (EnumUtils.mapToEnum(ShiftStatus, shiftStatus)) {
      case ShiftStatus.unconfirmed:
        return "#e8dc45";
      case ShiftStatus.verified:
        return "#30c91c";
      case ShiftStatus.tardy:
        return "#800080";
      case ShiftStatus.absence:
        return "#949494";
    }
  };

  const onTimeChange = (
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: (start: number, end: number) => void
  ) => {
    const minTime = Number(DateTime.fromJSDate(minDate).toFormat("x"));
    const maxTime = Number(DateTime.fromJSDate(maxDate).toFormat("x"));
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime);
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(
        minTime,
        minTime + (visibleTimeEnd - visibleTimeStart)
      );
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(
        maxTime - (visibleTimeEnd - visibleTimeStart),
        maxTime
      );
    } else {
      setStartDate(DateTime.fromMillis(visibleTimeStart).toJSDate());
      setEndDate(DateTime.fromMillis(visibleTimeEnd).toJSDate());
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    }
  };

  const onItemMove = async (
    itemId: string,
    dragTime: number,
    newGroupOrder: number
  ) => {
    if (itemId.split("-")[0] === "carTransferSchedule") {
      if (!groups[newGroupOrder].id) return;
      const carTransferSchedules = driverShifts.flatMap((driverShift) => {
        return driverShift.driver.carTransferSchedules;
      });
      const carTransferSchedule: any = {
        ...carTransferSchedules.find(
          (carTransferSchedule) =>
            carTransferSchedule.carTransferScheduleId ===
            Number(itemId.split("-")[1])
        ),
      };
      if (!carTransferSchedule) return;
      const timeDiff = DateTime.fromJSDate(carTransferSchedule.endTime)
        .diff(DateTime.fromJSDate(carTransferSchedule.startTime))
        .as("milliseconds");
      carTransferSchedule.startTime = DateTime.fromMillis(dragTime).toJSDate();
      carTransferSchedule.endTime = DateTime.fromMillis(
        dragTime + timeDiff
      ).toJSDate();
      carTransferSchedule.driverId = groups[newGroupOrder].id || null;
      setShowDriverScheduleOnItemCreate(true, {
        type: "driverScheduleOnItemCreate",
        carTransferSchedule,
      });
    }
    if (itemId.split("-")[0] !== "shift") {
      const defaultOrders: any[] = orders.map((order) => ({ ...order }));
      const order = defaultOrders.find(
        (order: OrderRes) => order.orderId === Number(itemId.split("-")[1])
      );
      let driver;
      if (!order) return;
      if (order?.castNameId) {
        await dispatch(fetchOneCastName(companyId, order?.castNameId));
      }
      if (itemId.split("-")[0] === "return") {
        order.returnDriverId = groups[newGroupOrder].id || null;
        order.returnDuration = order?.returnDuration || defaultDuration(order);
        driver = driverShifts.find(
          (driverShift) => driverShift.driverId === order.returnDriverId
        )?.driver;
      }
      if (itemId.split("-")[0] === "outward") {
        order.outwardDriverId = groups[newGroupOrder].id || null;
        order.outwardDuration =
          order?.outwardDuration || defaultDuration(order);
        driver = driverShifts.find(
          (driverShift) => driverShift.driverId === order.outwardDriverId
        )?.driver;
      }
      setStateOrders([
        ...defaultOrders.map((item: OrderRes) =>
          item.orderId === order.orderId ? order : item
        ),
      ]);
      setShowMoveForm(true, {
        type: "driverScheduleOnItemMove",
        order,
        driverType: itemId.split("-")[0],
        driver,
      });
    } else {
      const shiftId = Number(itemId.split("-")[1]);
      const defaultShifts = driverShifts.map((shift) => ({ ...shift }));
      const driverShift: any = defaultShifts.find(
        (shift) => shift.driverShiftId === shiftId
      );
      if (!driverShift) return;
      const diff = DateTime.fromJSDate(
        driverShift?.actualWorkEnd
          ? driverShift.actualWorkEnd
          : driverShift.planWorkEnd
      ).diff(
        DateTime.fromJSDate(
          driverShift?.actualWorkStart
            ? driverShift.actualWorkStart
            : driverShift.planWorkStart
        ),
        "minutes"
      ).minutes;
      if (driverShift?.actualWorkEnd) {
        driverShift.actualWorkEnd = DateTime.fromMillis(dragTime)
          .plus({ minutes: diff })
          .toJSDate();
      } else {
        driverShift.planWorkEnd = DateTime.fromMillis(dragTime)
          .plus({ minutes: diff })
          .toJSDate();
      }

      if (driverShift?.actualWorkStart) {
        driverShift.actualWorkStart = DateTime.fromMillis(dragTime).toJSDate();
      } else {
        driverShift.planWorkStart = DateTime.fromMillis(dragTime).toJSDate();
      }

      setStateShifts([
        ...defaultShifts.map((shift) =>
          shift.driverShiftId === driverShift.driverShiftId
            ? driverShift
            : shift
        ),
      ]);
      setShowShiftMoveForm(true, {
        type: "driverScheduleOnShiftMove",
        driverShift,
      });
    }
  };

  const onItemResize = (
    itemId: string,
    time: number,
    edge: "left" | "right"
  ) => {
    const shiftId = Number(itemId.split("-")[1]);
    const defaultShifts = driverShifts.map((shift) => ({ ...shift }));
    const driverShift: any = defaultShifts.find(
      (shift) => shift.driverShiftId === shiftId
    );
    if (!driverShift) return;
    if (driverShift?.actualWorkEnd) {
      if (edge === "right") {
        driverShift.actualWorkEnd = DateTime.fromMillis(time).toJSDate();
      } else {
        driverShift.actualWorkStart = DateTime.fromMillis(time).toJSDate();
      }
    } else {
      if (edge === "right") {
        driverShift.planWorkEnd = DateTime.fromMillis(time).toJSDate();
      } else {
        driverShift.planWorkStart = DateTime.fromMillis(time).toJSDate();
      }
    }
    setStateShifts([
      ...defaultShifts.map((shift) =>
        shift.driverShiftId === driverShift.driverShiftId ? driverShift : shift
      ),
    ]);
    setShowShiftMoveForm(true, {
      type: "driverScheduleOnShiftMove",
      driverShift,
    });
  };

  const itemRenderer = (
    item: any,
    itemContext: any,
    getItemProps: any,
    getResizeProps: any
  ) => {
    if (item.id.split("-")[0] === "carTransferSchedule") {
      return (
        <DriverScheduleCarTransferScheduleItem
          item={item}
          itemContext={itemContext}
          getItemProps={getItemProps}
          onClick={() => onClickEditCarTransferScheduleForm(item)}
          fetchDriverShift={() =>
            dispatch(
              fetchDriverShift(
                companyId,
                DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
                DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)
              )
            )
          }
        />
      );
    }
    if (item.id.split("-")[0] === "shift" || item.id.split("-")[0] === "rest") {
      return (
        <DriverScheduleShiftItem
          item={item}
          itemContext={itemContext}
          getItemProps={getItemProps}
          onClick={() => onClickEditForm(item)}
        />
      );
    }
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const order = orders.find(
      (order) => order.orderId === Number(item.id.split("-")[1])
    );
    if (!order) return <></>;
    return (
      <DriverScheduleOrderItem
        order={order}
        item={item}
        itemContext={itemContext}
        getItemProps={getItemProps}
        rightResizeProps={rightResizeProps}
        leftResizeProps={leftResizeProps}
      />
    );
  };

  const groupRenderer = (group: any) => {
    return (
      <Box display="flex" flexDirection="column">
        <Typography>{group.title}</Typography>
        <Box display="flex" flexDirection="column">
          {group.status && <Typography>({group.status})</Typography>}
          {group.returnTime && <Typography>{group.returnTime}</Typography>}
          {group.carInfo && <Typography>{group.carInfo}</Typography>}
        </Box>
      </Box>
    );
  };

  const onClickCreateForm = () => {
    setDriverForm(true, { type: "addData", item: { status: "unconfirmed" } });
  };

  const onClickEditCarTransferScheduleForm = (item: any) => {
    const carTransferSchedule = { ...item };
    carTransferSchedule.startTime = DateTime.fromMillis(
      item.start_time
    ).toJSDate();
    carTransferSchedule.endTime = DateTime.fromMillis(item.end_time).toJSDate();
    carTransferSchedule.driverId = item.group;
    carTransferSchedule.title = item.title;
    setShowDriverScheduleOnItemCreate(true, { carTransferSchedule });
  };

  const onCanvasClick = (groupId: number, time: number) => {
    if (!groupId) return;
    const carTransferSchedule = {} as CreateCarTransferScheduleReq;
    carTransferSchedule.startTime = DateTime.fromMillis(time).toJSDate();
    carTransferSchedule.endTime = DateTime.fromMillis(time)
      .plus({ minutes: 60 })
      .toJSDate();
    carTransferSchedule.driverId = groupId;
    setShowDriverScheduleOnItemCreate(true, {
      type: "driverScheduleOnItemCreate",
      carTransferSchedule,
    });
  };

  const onClickEditForm = (item: any) => {
    setDriverForm(true, { type: "editData", item: item.driverShift });
  };

  const onClickReload = () => {
    dispatch(
      fetchDriverShift(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)
      )
    );
    dispatch(
      fetchOrder(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate)
          .plus({ days: TimeUtils.isInAllDays(shops) ? 1 : 0 })
          .toFormat(FORMAT_TYPE.YEAR_DAY)
      )
    );
  };
  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box margin={2} display="flex">
          <TextField
            type="date"
            label="開始日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(minDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
            onChange={(event) => {
              const datetime = DateTime.fromISO(event.target.value as string)
                .startOf("day")
                .plus({ hours: startTime })
                .toJSDate();
              const nextDatetime = DateTime.fromJSDate(datetime)
                .startOf("day")
                .plus({ hours: endTime })
                .toJSDate();
              setMinDate(datetime);
              setStartDate(datetime);
              setMaxDate(nextDatetime);
              setEndDate(nextDatetime);
              dispatch(
                fetchOrder(
                  companyId,
                  DateTime.fromJSDate(datetime).toFormat(FORMAT_TYPE.YEAR_DAY),
                  DateTime.fromJSDate(nextDatetime)
                    .plus({ days: TimeUtils.isInAllDays(shops) ? 1 : 0 })
                    .toFormat(FORMAT_TYPE.YEAR_DAY)
                )
              );
              dispatch(
                fetchDriverShift(
                  companyId,
                  DateTime.fromJSDate(datetime).toFormat(FORMAT_TYPE.YEAR_DAY),
                  DateTime.fromJSDate(nextDatetime).toFormat(
                    FORMAT_TYPE.YEAR_DAY
                  )
                )
              );
            }}
          />
          <TextField
            type="date"
            label="終了日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(maxDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
            onChange={(event) => {
              const nextDatetime = DateTime.fromISO(
                event.target.value as string
              )
                .startOf("day")
                .plus({ hours: endTime })
                .toJSDate();
              const datetime = DateTime.fromJSDate(nextDatetime)
                .startOf("day")
                .plus({ hours: startTime })
                .toJSDate();
              setMinDate(datetime);
              setStartDate(datetime);
              setMaxDate(nextDatetime);
              setEndDate(nextDatetime);
              dispatch(
                fetchOrder(
                  companyId,
                  DateTime.fromJSDate(datetime).toFormat(FORMAT_TYPE.YEAR_DAY),
                  DateTime.fromJSDate(nextDatetime)
                    .plus({ days: TimeUtils.isInAllDays(shops) ? 1 : 0 })
                    .toFormat(FORMAT_TYPE.YEAR_DAY)
                )
              );
              dispatch(
                fetchDriverShift(
                  companyId,
                  DateTime.fromJSDate(datetime).toFormat(FORMAT_TYPE.YEAR_DAY),
                  DateTime.fromJSDate(nextDatetime).toFormat(
                    FORMAT_TYPE.YEAR_DAY
                  )
                )
              );
            }}
          />
          <Box p={1}>
            <Button
              variant="contained"
              className={classes.button}
              endIcon={<Icon>add</Icon>}
              color="secondary"
              onClick={() => onClickCreateForm()}
            >
              追加
            </Button>
          </Box>
          <Box p={1}>
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={() => onClickReload()}
            >
              データ更新
            </Button>
          </Box>
          {driverShifts.filter((driverShift) => driverShift.roadConditionMemo)
            .length ? (
            <Box display="flex">
              <Typography variant="subtitle1">道路状況報告</Typography>
              {driverShifts
                .filter((driverShift) => driverShift.roadConditionMemo)
                .map((driverShift) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    key={driverShift.driverShiftId}
                    justifyContent="flex-end"
                    maxWidth="300px"
                  >
                    <Typography>{driverShift.driver.name}さん</Typography>
                    <Typography>{driverShift.roadConditionMemo}</Typography>
                  </Box>
                ))}
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <div style={{ width: "100vw" }}>
        <Timeline
          groups={groups}
          items={items}
          visibleTimeStart={DateTime.fromJSDate(startDate).toMillis()}
          visibleTimeEnd={DateTime.fromJSDate(endDate)
            .plus({ days: 1 })
            .toMillis()}
          stackItems
          canChangeGroup
          onTimeChange={onTimeChange}
          onItemResize={(itemId, time, edge) =>
            onItemResize(itemId as string, time, edge)
          }
          onCanvasDoubleClick={(groupId, time) =>
            onCanvasClick(groupId as number, time)
          }
          onItemMove={(itemId, dragTime, newGroupOrder) =>
            onItemMove(itemId as string, dragTime, newGroupOrder)
          }
          groupRenderer={({ group }) => groupRenderer(group)}
          itemRenderer={({ item, itemContext, getItemProps, getResizeProps }) =>
            itemRenderer(item, itemContext, getItemProps, getResizeProps)
          }
        >
          <TodayMarker date={DateTime.local().toJSDate()}>
            {({ styles }) => (
              <div style={{ ...styles, backgroundColor: "red" }} />
            )}
          </TodayMarker>
          <TimelineHeaders>
            <DateHeader
              unit="day"
              labelFormat="YYYY年MM月DD日"
              style={{ pointerEvents: "none" }}
            />
            <DateHeader
              unit="hour"
              labelFormat="HH:00"
              style={{ pointerEvents: "none" }}
            />
          </TimelineHeaders>
        </Timeline>
        <DriverScheduleOnItemMove
          setStateOrders={setStateOrders}
          orders={orders}
        />
        <DriverScheduleOnShiftMove
          setStateShifts={setStateShifts}
          shifts={driverShifts}
        />
        <DriverScheduleOnItemCreate
          startDate={DateTime.fromJSDate(startDate).toFormat(
            FORMAT_TYPE.YEAR_DAY
          )}
          endDate={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
        />
        <FormModal<CreateDriverShiftReq, UpdateDriverShiftReq>
          formId="driverSchedule"
          title="ドライバーシフト"
          forms={forms}
          addType={CreateDriverShiftReq}
          addFunc={(formData) => addDriverShift(companyId, formData)}
          updateType={UpdateDriverShiftReq}
          updateFunc={(formData) => updateDriverShift(companyId, formData)}
        />
      </div>
    </>
  );
};
export default DriverSchedule;
