import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  InputLabel,
  ListItemText,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import GuestGenreType from "types/enum/GuestGenreType";
import GuestAnalyticsGroupType from "types/enum/GuestAnalyticsGroupType";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { fetchShops } from "redux/actions/shop";
import { fetchArea } from "redux/actions/area";
import { simpleFetchGuestCategory } from "redux/actions/guestCategory";
import { fetchNotelClass } from "redux/actions/notelClass";
import { Bar, BarChart, Brush, Tooltip, XAxis, YAxis } from "recharts";
import Modal from "components/Modal";
import useModal from "hooks/useModal";
import GuestAnalyticsSalesVolumeApi from "api/GuestAnalyticsSalesVolumeApi";
import { fetchCourse } from "redux/actions/course";
import Table from "@material-ui/core/Table";
import MathUtils from "utils/MathUtils";

const GuestAnalyticsSalesVolume = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const shops = useSelector((state) => state.shop);
  const notelClasses = useSelector((state) => state.notelClass);
  const guestCategories = useSelector((state) => state.guestCategory);
  const courses = useSelector((state) => state.course);
  const areas = useSelector((state) => state.area);
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectNotelClasses, setSelectNotelClasses] = useState<string[]>([]);
  const [selectGuestCategories, setSelectGuestCategories] = useState<string[]>(
    []
  );
  const [selectArea, setSelectArea] = useState<string[]>([]);
  const [selectGenre, setSelectGenre] = useState<string[]>([
    ...Object.keys(GuestGenreType),
    "すべて",
  ]);
  const [selectGroup, setSelectGroup] = useState<string[]>([
    ...Object.keys(GuestAnalyticsGroupType),
    "すべて",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [salesData, setSalesData] = useState<{
    [key: string]: { [key: string]: any };
  }>({});
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchArea(companyId));
    dispatch(simpleFetchGuestCategory(companyId));
    dispatch(fetchNotelClass(companyId));
    dispatch(fetchCourse(companyId));
  }, []);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    setSelectNotelClasses([
      ...notelClasses.map((notelClass) => String(notelClass.notelClassId)),
      "すべて",
    ]);
  }, [notelClasses]);

  useEffect(() => {
    setSelectGuestCategories([
      ...guestCategories.map((guestCategory) =>
        String(guestCategory.guestCategoryId)
      ),
      "すべて",
    ]);
  }, [guestCategories]);

  useEffect(() => {
    setSelectArea([...areas.map((area) => String(area.areaId)), "すべて"]);
  }, [areas]);

  const onClickSearch = async () => {
    setIsLoading(true);
    const stringEndDate = DateTime.fromJSDate(endDate).toFormat(
      FORMAT_TYPE.YEAR_DAY
    );
    const result = await GuestAnalyticsSalesVolumeApi.findData(companyId, {
      shopIds: selectShops,
      endDate: stringEndDate,
      guestCategoryIds: selectGuestCategories,
      notelClassIds: selectNotelClasses,
      areaIds: selectArea,
      guestAnalyticsType: selectGroup,
      guestGenreType: selectGenre,
    });
    setSalesData(result);
    setIsLoading(false);
  };

  return (
    <Box display="flex" flexDirection={"column"}>
      <Box margin={2} display="flex" alignItems="center">
        <TextField
          type="date"
          label="終了日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) =>
            setEndDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            )
          }
        />
        <Box>
          <InputLabel id="select-multiple-shop">店舗</InputLabel>
          <Select
            multiple
            value={selectShops}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectShops((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length === shops.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= shops.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-shop" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return shops
                  .filter((shop) =>
                    (selected as string[]).includes(String(shop.shopId))
                  )
                  .map((shop) => shop.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectShops.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {shops.map((shop) => (
              <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                <Checkbox
                  checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                />
                <ListItemText primary={shop.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-notel-class">クラス</InputLabel>
          <Select
            multiple
            value={selectNotelClasses}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectNotelClasses((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...notelClasses.map((notelClass) =>
                      String(notelClass.notelClassId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length ===
                    notelClasses.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...notelClasses.map((notelClass) =>
                      String(notelClass.notelClassId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= notelClasses.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-notelClass" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return notelClasses
                  .filter((notelClass) =>
                    (selected as string[]).includes(
                      String(notelClass.notelClassId)
                    )
                  )
                  .map((notelClass) => notelClass.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectNotelClasses.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {notelClasses.map((notelClass) => (
              <MenuItem
                key={notelClass.notelClassId}
                value={String(notelClass.notelClassId)}
              >
                <Checkbox
                  checked={
                    selectNotelClasses.indexOf(
                      String(notelClass.notelClassId)
                    ) !== -1
                  }
                />
                <ListItemText primary={notelClass.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-shop">顧客カテゴリ</InputLabel>
          <Select
            multiple
            value={selectGuestCategories}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGuestCategories((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...guestCategories.map((guestCategory) =>
                      String(guestCategory.guestCategoryId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length ===
                    guestCategories.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...guestCategories.map((guestCategory) =>
                      String(guestCategory.guestCategoryId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <=
                  guestCategories.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-category" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return guestCategories
                  .filter((guestCategory) =>
                    (selected as string[]).includes(
                      String(guestCategory.guestCategoryId)
                    )
                  )
                  .map((guestCategory) => guestCategory.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGuestCategories.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {guestCategories.map((guestCategory) => (
              <MenuItem
                key={guestCategory.guestCategoryId}
                value={String(guestCategory.guestCategoryId)}
              >
                <Checkbox
                  checked={
                    selectGuestCategories.indexOf(
                      String(guestCategory.guestCategoryId)
                    ) !== -1
                  }
                />
                <ListItemText primary={guestCategory.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-guest-genre">現役・離脱</InputLabel>
          <Select
            multiple
            value={selectGenre}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGenre((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [...Object.keys(GuestGenreType), "すべて"];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(GuestGenreType).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [...Object.keys(GuestGenreType), "すべて"];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(GuestGenreType).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-genre" />}
            style={{ margin: "10px", width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.keys(GuestGenreType)
                  .filter((name) => (selected as string[]).includes(name))
                  .map((key) => (GuestGenreType as any)[key])
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGenre.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(GuestGenreType).map(([key, name]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox checked={selectGenre.indexOf(String(key)) !== -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-guest-genre">顧客グループ</InputLabel>
          <Select
            multiple
            value={selectGroup}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectGroup((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [...Object.keys(GuestAnalyticsGroupType), "すべて"];
                } else if (
                  (event.target.value as string[]).length ===
                    Object.keys(GuestAnalyticsGroupType).length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [...Object.keys(GuestAnalyticsGroupType), "すべて"];
                } else if (
                  (event.target.value as string[]).length <=
                  Object.keys(GuestAnalyticsGroupType).length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-guest-genre" />}
            style={{ width: "200px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return Object.keys(GuestAnalyticsGroupType)
                  .filter((name) => (selected as string[]).includes(name))
                  .map((key) => (GuestAnalyticsGroupType as any)[key])
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectGroup.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {Object.entries(GuestAnalyticsGroupType).map(([key, name]) => (
              <MenuItem key={key} value={String(key)}>
                <Checkbox checked={selectGroup.indexOf(String(key)) !== -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel id="select-multiple-area">エリア</InputLabel>
          <Select
            multiple
            value={selectArea}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectArea((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...areas.map((area) => String(area.areaId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length === areas.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...areas.map((area) => String(area.areaId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= areas.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-area" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return areas
                  .filter((area) =>
                    (selected as string[]).includes(String(area.areaId))
                  )
                  .map((area) => area.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectArea.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {areas.map((area) => (
              <MenuItem key={area.areaId} value={String(area.areaId)}>
                <Checkbox
                  checked={selectArea.indexOf(String(area.areaId)) !== -1}
                />
                <ListItemText primary={area.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={onClickSearch}
        >
          検索
        </Button>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : Object.keys(salesData).length ? (
        <>
          <Box
            paddingY={3}
            display={"flex"}
            justifyContent={"space-evenly"}
            style={{ flexWrap: "wrap" }}
          >
            {Object.entries(salesData).map(([key, data]) => {
              switch (key) {
                case "shop": {
                  return (
                    <Box display={"flex"} flexDirection={"column"}>
                      <Box
                        display={"flex"}
                        justifyContent={"space-evenly"}
                        width={"1500px"}
                      >
                        <ShopYearGraph data={data} />
                        <ShopMonthGraph data={data} />
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"space-evenly"}
                        marginTop={1}
                        width={"1500px"}
                      >
                        <SalesVolumeTable
                          title={"店舗別12ヶ月売上昨対比較"}
                          header={[
                            { name: "店舗名", key: "shopName" },
                            { name: "直近12ヶ月売上", key: "current" },
                            { name: "前12ヶ月売上", key: "last" },
                            { name: "売上前年対比", key: "diff" },
                            { name: "売上対前年伸び率", key: "growth" },
                          ]}
                          data={Object.entries(data).map(([key, d]) => ({
                            name:
                              shops.find((shop) => shop.shopId === Number(key))
                                ?.name || "",
                            current: d.thisYear,
                            last: d.lastYear,
                          }))}
                        />
                        <SalesVolumeTable
                          title={"店舗別月間売上昨対比較"}
                          header={[
                            { name: "店舗名", key: "shopName" },
                            { name: "今月売上", key: "current" },
                            { name: "前月売上", key: "last" },
                            { name: "売上前月対比", key: "diff" },
                            { name: "売上対前月伸び率", key: "growth" },
                          ]}
                          data={Object.entries(data).map(([key, d]) => ({
                            name:
                              shops.find((shop) => shop.shopId === Number(key))
                                ?.name || "",
                            current: d.thisMonth,
                            last: d.lastMonth,
                          }))}
                        />
                      </Box>
                    </Box>
                  );
                }
                case "course": {
                  return (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      marginTop={"100px"}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-evenly"}
                        width={"1500px"}
                      >
                        <CourseYearGraph data={data} />
                        <CourseMonthGraph data={data} />
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"space-evenly"}
                        marginTop={1}
                        width={"1500px"}
                      >
                        <SalesVolumeTable
                          title={"コース別12ヶ月売上昨対比較"}
                          header={[
                            { name: "コース名", key: "courseName" },
                            { name: "直近12ヶ月売上", key: "current" },
                            { name: "前12ヶ月売上", key: "last" },
                            { name: "売上前年対比", key: "diff" },
                            { name: "売上対前年伸び率", key: "growth" },
                          ]}
                          data={Object.entries(data).map(([key, d]) => ({
                            name:
                              courses.find(
                                (course) => course.courseId === Number(key)
                              )?.name || "",
                            current: d.thisYear,
                            last: d.lastYear,
                          }))}
                        />
                        <SalesVolumeTable
                          title={"コース別月間売上昨対比較"}
                          header={[
                            { name: "コース名", key: "courseName" },
                            { name: "今月売上", key: "current" },
                            { name: "前月売上", key: "last" },
                            { name: "売上前月対比", key: "diff" },
                            { name: "売上対前月伸び率", key: "growth" },
                          ]}
                          data={Object.entries(data).map(([key, d]) => ({
                            name:
                              courses.find(
                                (course) => course.courseId === Number(key)
                              )?.name || "",
                            current: d.thisMonth,
                            last: d.lastMonth,
                          }))}
                        />
                      </Box>
                    </Box>
                  );
                }
                case "notelClass": {
                  return (
                    <>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        marginTop={"100px"}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-evenly"}
                          width={"1500px"}
                        >
                          <NotelClassYearGraph data={data} />
                          <NotelClassMonthGraph data={data} />
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"space-evenly"}
                          marginTop={1}
                          width={"1500px"}
                        >
                          <SalesVolumeTable
                            title={"クラス別12ヶ月売上昨対比較"}
                            header={[
                              { name: "クラス名", key: "notelClassName" },
                              { name: "直近12ヶ月売上", key: "current" },
                              { name: "前12ヶ月売上", key: "last" },
                              { name: "売上前年対比", key: "diff" },
                              { name: "売上対前年伸び率", key: "growth" },
                            ]}
                            data={Object.entries(data).map(([key, d]) => ({
                              name:
                                notelClasses.find(
                                  (notelClass) =>
                                    notelClass.notelClassId === Number(key)
                                )?.name || "",
                              current: d.thisYear,
                              last: d.lastYear,
                            }))}
                          />
                          <SalesVolumeTable
                            title={"クラス別月間売上昨対比較"}
                            header={[
                              { name: "クラス名", key: "notelClassName" },
                              { name: "今月売上", key: "current" },
                              { name: "前月売上", key: "last" },
                              { name: "売上前月対比", key: "diff" },
                              { name: "売上対前月伸び率", key: "growth" },
                            ]}
                            data={Object.entries(data).map(([key, d]) => ({
                              name:
                                notelClasses.find(
                                  (notelClass) =>
                                    notelClass.notelClassId === Number(key)
                                )?.name || "",
                              current: d.thisMonth,
                              last: d.lastMonth,
                            }))}
                          />
                        </Box>
                      </Box>
                    </>
                  );
                }
              }
            })}
          </Box>
        </>
      ) : (
        <Box display={"flex"} justifyContent={"center"}>
          <Typography>
            データがありません。条件を変えて検索してください。
          </Typography>
        </Box>
      )}
    </Box>
  );
};

type GraphProps = { data: { [key: string]: any } };
const ShopYearGraph = ({ data }: GraphProps) => {
  const shops = useSelector((state) => state.shop);
  const [sortKey, setSortKey] = useState("thisYear");
  const [, setNonSalesAreaModal] = useModal("nonSalesAreaModal");
  const formatData: any[] = [];
  const noSalesArea: string[] = [];
  Object.entries(data)
    .sort(([, a], [, b]) => {
      if (sortKey === "thisYear") {
        return a.thisYear > b.thisYear ? -1 : 1;
      } else {
        return a.lastYear > b.lastYear ? -1 : 1;
      }
    })
    .forEach(([category, d]) => {
      if (!d.thisYear && !d.lastYear) {
        noSalesArea.push(
          shops.find((shop) => shop.shopId === Number(category))?.name || ""
        );
      } else {
        formatData.push({
          name:
            shops.find((shop) => shop.shopId === Number(category))?.name || "",
          ...d,
        });
      }
    });
  const openModal = () => {
    setNonSalesAreaModal(true, {
      title: `売上0`,
      areaNames: noSalesArea,
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      marginX={2}
      marginY={1}
      width={700}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant={"subtitle1"}>店舗別12ヶ月売上昨対比較</Typography>
        <Typography
          variant={"body1"}
          style={{
            fontSize: "10px",
            cursor: "pointer",
            width: "100px",
            margin: "0 10px",
          }}
          onClick={openModal}
        >
          売上0
        </Typography>
        <TextField
          select
          value={sortKey || ""}
          onChange={(event) => setSortKey(event.target.value as string)}
        >
          <MenuItem value={"thisYear"} key={"thisYear"}>
            今年の売上高い順
          </MenuItem>
          <MenuItem value={"lastYear"} key={"lastYear"}>
            昨年の売上高い順
          </MenuItem>
        </TextField>
      </Box>
      {formatData.length ? (
        <>
          <BarChart width={700} height={500} data={formatData}>
            <YAxis type="number" width={150} />
            <XAxis
              dataKey="name"
              type="category"
              tick={<CustomizeShopTick />}
              minTickGap={1}
              interval={0}
              height={80}
            />
            <Bar
              dataKey="thisYear"
              fill="#8884d8"
              name={"直近12ヶ月"}
              barSize={20}
            />
            <Bar
              dataKey="lastYear"
              fill="#FCD361"
              name={"前年12ヶ月"}
              barSize={20}
            />
            <Tooltip formatter={(value: any) => `¥${value}`} />
            {formatData.length > 10 && (
              <Brush height={30} endIndex={10} dataKey={"name"} />
            )}
          </BarChart>
          <NonSalesAreaModal />
        </>
      ) : (
        <Typography>データがありません</Typography>
      )}
    </Box>
  );
};

const ShopMonthGraph = ({ data }: GraphProps) => {
  const shops = useSelector((state) => state.shop);
  const [sortKey, setSortKey] = useState("thisMonth");
  const [, setNonSalesAreaModal] = useModal("nonSalesAreaModal");
  const formatData: any[] = [];
  const noSalesArea: string[] = [];
  Object.entries(data)
    .sort(([, a], [, b]) => {
      if (sortKey === "thisMonth") {
        return a.thisMonth > b.thisMonth ? -1 : 1;
      } else {
        return a.lastMonth > b.lastMonth ? -1 : 1;
      }
    })
    .forEach(([category, d]) => {
      if (!d.thisMonth && !d.lastMonth) {
        noSalesArea.push(
          shops.find((shop) => shop.shopId === Number(category))?.name || ""
        );
      } else {
        formatData.push({
          name:
            shops.find((shop) => shop.shopId === Number(category))?.name || "",
          ...d,
        });
      }
    });
  const openModal = () => {
    setNonSalesAreaModal(true, {
      title: `売上0`,
      areaNames: noSalesArea,
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      marginX={2}
      marginY={1}
      width={700}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant={"subtitle1"}>店舗別月間売上昨対比較</Typography>
        <Typography
          variant={"body1"}
          style={{
            fontSize: "10px",
            cursor: "pointer",
            width: "100px",
            margin: "0 10px",
          }}
          onClick={openModal}
        >
          売上0
        </Typography>
        <TextField
          select
          value={sortKey || ""}
          onChange={(event) => setSortKey(event.target.value as string)}
        >
          <MenuItem value={"thisMonth"} key={"thisMonth"}>
            今年の売上高い順
          </MenuItem>
          <MenuItem value={"lastMonth"} key={"lastMonth"}>
            昨年の売上高い順
          </MenuItem>
        </TextField>
      </Box>
      {formatData.length ? (
        <>
          <BarChart width={700} height={500} data={formatData}>
            <YAxis type="number" width={150} />
            <XAxis
              dataKey="name"
              type="category"
              tick={<CustomizeShopTick />}
              minTickGap={1}
              interval={0}
              height={80}
            />
            <Bar
              dataKey="thisMonth"
              fill="#8884d8"
              name={"直近12ヶ月"}
              barSize={20}
            />
            <Bar
              dataKey="lastMonth"
              fill="#FCD361"
              name={"前年12ヶ月"}
              barSize={20}
            />
            <Tooltip formatter={(value: any) => `¥${value}`} />
            {formatData.length > 10 && (
              <Brush height={30} endIndex={10} dataKey={"name"} />
            )}
          </BarChart>
          <NonSalesAreaModal />
        </>
      ) : (
        <Typography>データがありません</Typography>
      )}
    </Box>
  );
};

const CourseYearGraph = ({ data }: GraphProps) => {
  const courses = useSelector((state) => state.course);
  const [sortKey, setSortKey] = useState("thisYear");
  const [, setNonSalesAreaModal] = useModal("nonSalesAreaModal");
  const formatData: any[] = [];
  const noSalesArea: string[] = [];
  Object.entries(data)
    .sort(([, a], [, b]) => {
      if (sortKey === "thisYear") {
        return a.thisYear > b.thisYear ? -1 : 1;
      } else {
        return a.lastYear > b.lastYear ? -1 : 1;
      }
    })
    .forEach(([category, d]) => {
      if (!d.thisYear && !d.lastYear) {
        noSalesArea.push(
          courses.find((course) => course.courseId === Number(category))
            ?.name || ""
        );
      } else {
        formatData.push({
          name:
            courses.find((course) => course.courseId === Number(category))
              ?.name || "",
          ...d,
        });
      }
    });
  const openModal = () => {
    setNonSalesAreaModal(true, {
      title: `売上0`,
      areaNames: noSalesArea,
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      marginX={2}
      marginY={1}
      width={700}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant={"subtitle1"}>
          コース別12ヶ月売上昨対比較
        </Typography>
        <Typography
          variant={"body1"}
          style={{
            fontSize: "10px",
            cursor: "pointer",
            width: "100px",
            margin: "0 10px",
          }}
          onClick={openModal}
        >
          売上0
        </Typography>
        <TextField
          select
          value={sortKey || ""}
          onChange={(event) => setSortKey(event.target.value as string)}
        >
          <MenuItem value={"thisYear"} key={"thisYear"}>
            今年の売上高い順
          </MenuItem>
          <MenuItem value={"lastYear"} key={"lastYear"}>
            昨年の売上高い順
          </MenuItem>
        </TextField>
      </Box>
      {formatData.length ? (
        <>
          <BarChart width={700} height={500} data={formatData}>
            <YAxis type="number" width={150} />
            <XAxis
              dataKey="name"
              type="category"
              tick={<CustomizeShopTick />}
              minTickGap={1}
              interval={0}
              height={80}
            />
            <Bar
              dataKey="thisYear"
              fill="#8884d8"
              name={"直近12ヶ月"}
              barSize={20}
            />
            <Bar
              dataKey="lastYear"
              fill="#FCD361"
              name={"前年12ヶ月"}
              barSize={20}
            />
            <Tooltip formatter={(value: any) => `¥${value}`} />
            {formatData.length > 10 && (
              <Brush height={30} endIndex={10} dataKey={"name"} />
            )}
          </BarChart>
          <NonSalesAreaModal />
        </>
      ) : (
        <Typography>データがありません</Typography>
      )}
    </Box>
  );
};

const CourseMonthGraph = ({ data }: GraphProps) => {
  const courses = useSelector((state) => state.course);
  const [sortKey, setSortKey] = useState("thisMonth");
  const [, setNonSalesAreaModal] = useModal("nonSalesAreaModal");
  const formatData: any[] = [];
  const noSalesArea: string[] = [];
  Object.entries(data)
    .sort(([, a], [, b]) => {
      if (sortKey === "thisMonth") {
        return a.thisMonth > b.thisMonth ? -1 : 1;
      } else {
        return a.lastMonth > b.lastMonth ? -1 : 1;
      }
    })
    .forEach(([category, d]) => {
      if (!d.thisMonth && !d.lastMonth) {
        noSalesArea.push(
          courses.find((course) => course.courseId === Number(category))
            ?.name || ""
        );
      } else {
        formatData.push({
          name:
            courses.find((course) => course.courseId === Number(category))
              ?.name || "",
          ...d,
        });
      }
    });
  const openModal = () => {
    setNonSalesAreaModal(true, {
      title: `売上0`,
      areaNames: noSalesArea,
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      marginX={2}
      marginY={1}
      width={700}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant={"subtitle1"}>コース別月間売上昨対比較</Typography>
        <Typography
          variant={"body1"}
          style={{
            fontSize: "10px",
            cursor: "pointer",
            width: "100px",
            margin: "0 10px",
          }}
          onClick={openModal}
        >
          売上0
        </Typography>
        <TextField
          select
          value={sortKey || ""}
          onChange={(event) => setSortKey(event.target.value as string)}
        >
          <MenuItem value={"thisMonth"} key={"thisMonth"}>
            今月の売上高い順
          </MenuItem>
          <MenuItem value={"lastMonth"} key={"lastMonth"}>
            先月の売上高い順
          </MenuItem>
        </TextField>
      </Box>
      {formatData.length ? (
        <>
          <BarChart width={700} height={500} data={formatData}>
            <YAxis type="number" width={150} />
            <XAxis
              dataKey="name"
              type="category"
              tick={<CustomizeShopTick />}
              minTickGap={1}
              interval={0}
              height={80}
            />
            <Bar
              dataKey="thisMonth"
              fill="#8884d8"
              name={"今月"}
              barSize={20}
            />
            <Bar
              dataKey="lastMonth"
              fill="#FCD361"
              name={"先月"}
              barSize={20}
            />
            <Tooltip formatter={(value: any) => `¥${value}`} />
            {formatData.length > 10 && (
              <Brush height={30} endIndex={10} dataKey={"name"} />
            )}
          </BarChart>
          <NonSalesAreaModal />
        </>
      ) : (
        <Typography>データがありません</Typography>
      )}
    </Box>
  );
};

const NotelClassYearGraph = ({ data }: GraphProps) => {
  const notelClasses = useSelector((state) => state.notelClass);
  const [sortKey, setSortKey] = useState("thisYear");
  const [, setNonSalesAreaModal] = useModal("nonSalesAreaModal");
  const formatData: any[] = [];
  const noSalesArea: string[] = [];
  Object.entries(data)
    .sort(([, a], [, b]) => {
      if (sortKey === "thisYear") {
        return a.thisYear > b.thisYear ? -1 : 1;
      } else {
        return a.lastYear > b.lastYear ? -1 : 1;
      }
    })
    .forEach(([category, d]) => {
      if (!d.thisYear && !d.lastYear) {
        noSalesArea.push(
          notelClasses.find(
            (notelClass) => notelClass.notelClassId === Number(category)
          )?.name || ""
        );
      } else {
        formatData.push({
          name:
            notelClasses.find(
              (notelClass) => notelClass.notelClassId === Number(category)
            )?.name || "",
          ...d,
        });
      }
    });
  const openModal = () => {
    setNonSalesAreaModal(true, {
      title: `売上0`,
      areaNames: noSalesArea,
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      marginX={2}
      marginY={1}
      width={700}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant={"subtitle1"}>
          クラス別12ヶ月売上昨対比較
        </Typography>
        <Typography
          variant={"body1"}
          style={{
            fontSize: "10px",
            cursor: "pointer",
            width: "100px",
            margin: "0 10px",
          }}
          onClick={openModal}
        >
          売上0
        </Typography>
        <TextField
          select
          value={sortKey || ""}
          onChange={(event) => setSortKey(event.target.value as string)}
        >
          <MenuItem value={"thisYear"} key={"thisYear"}>
            今年の売上高い順
          </MenuItem>
          <MenuItem value={"lastYear"} key={"lastYear"}>
            昨年の売上高い順
          </MenuItem>
        </TextField>
      </Box>
      {formatData.length ? (
        <>
          <BarChart width={700} height={500} data={formatData}>
            <YAxis type="number" width={150} />
            <XAxis
              dataKey="name"
              type="category"
              tick={<CustomizeShopTick />}
              minTickGap={1}
              interval={0}
              height={80}
            />
            <Bar
              dataKey="thisYear"
              fill="#8884d8"
              name={"直近12ヶ月"}
              barSize={20}
            />
            <Bar
              dataKey="lastYear"
              fill="#FCD361"
              name={"前年12ヶ月"}
              barSize={20}
            />
            <Tooltip formatter={(value: any) => `¥${value}`} />
            {formatData.length > 10 && (
              <Brush height={30} endIndex={10} dataKey={"name"} />
            )}
          </BarChart>
          <NonSalesAreaModal />
        </>
      ) : (
        <Typography>データがありません</Typography>
      )}
    </Box>
  );
};

const NotelClassMonthGraph = ({ data }: GraphProps) => {
  const notelClasses = useSelector((state) => state.notelClass);
  const [sortKey, setSortKey] = useState("thisMonth");
  const [, setNonSalesAreaModal] = useModal("nonSalesAreaModal");
  const formatData: any[] = [];
  const noSalesArea: string[] = [];
  Object.entries(data)
    .sort(([, a], [, b]) => {
      if (sortKey === "thisMonth") {
        return a.thisMonth > b.thisMonth ? -1 : 1;
      } else {
        return a.lastMonth > b.lastMonth ? -1 : 1;
      }
    })
    .forEach(([category, d]) => {
      if (!d.thisMonth && !d.lastMonth) {
        noSalesArea.push(
          notelClasses.find(
            (notelClass) => notelClass.notelClassId === Number(category)
          )?.name || ""
        );
      } else {
        formatData.push({
          name:
            notelClasses.find(
              (notelClass) => notelClass.notelClassId === Number(category)
            )?.name || "",
          ...d,
        });
      }
    });
  const openModal = () => {
    setNonSalesAreaModal(true, {
      title: `売上0`,
      areaNames: noSalesArea,
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      marginX={2}
      marginY={1}
      width={700}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant={"subtitle1"}>クラス別月間売上昨対比較</Typography>
        <Typography
          variant={"body1"}
          style={{
            fontSize: "10px",
            cursor: "pointer",
            width: "100px",
            margin: "0 10px",
          }}
          onClick={openModal}
        >
          売上0
        </Typography>
        <TextField
          select
          value={sortKey || ""}
          onChange={(event) => setSortKey(event.target.value as string)}
        >
          <MenuItem value={"thisMonth"} key={"thisMonth"}>
            今月の売上高い順
          </MenuItem>
          <MenuItem value={"lastMonth"} key={"lastMonth"}>
            先月の売上高い順
          </MenuItem>
        </TextField>
      </Box>
      {formatData.length ? (
        <>
          <BarChart width={700} height={500} data={formatData}>
            <YAxis type="number" width={150} />
            <XAxis
              dataKey="name"
              type="category"
              tick={<CustomizeShopTick />}
              minTickGap={1}
              interval={0}
              height={80}
            />
            <Bar
              dataKey="thisMonth"
              fill="#8884d8"
              name={"今月"}
              barSize={20}
            />
            <Bar
              dataKey="lastMonth"
              fill="#FCD361"
              name={"先月"}
              barSize={20}
            />
            <Tooltip formatter={(value: any) => `¥${value}`} />
            {formatData.length > 10 && (
              <Brush height={30} endIndex={10} dataKey={"name"} />
            )}
          </BarChart>
          <NonSalesAreaModal />
        </>
      ) : (
        <Typography>データがありません</Typography>
      )}
    </Box>
  );
};

type SalesVolumeTableProps = {
  title: string;
  header: { key: string; name: string }[];
  data: { name: string; current: number; last: number }[];
};
const SalesVolumeTable = ({ title, header, data }: SalesVolumeTableProps) => {
  const [, setNonSalesAreaModal] = useModal("nonSalesAreaModal");
  const noSalesArea: string[] = [];
  const filterData: any[] = [];
  data.forEach((d) => {
    if (d.current && d.last) {
      filterData.push(d);
    } else {
      noSalesArea.push(d.name);
    }
  });
  const openModal = () => {
    setNonSalesAreaModal(true, {
      title: `売上0`,
      areaNames: noSalesArea,
    });
  };
  return (
    <Box display={"flex"} flexDirection={"column"} width={"700px"}>
      <Box display={"flex"} justifyContent="center" alignItems="center">
        <Typography variant={"subtitle1"}>{title}</Typography>
        <Typography
          variant={"body1"}
          style={{
            fontSize: "10px",
            cursor: "pointer",
            width: "100px",
            margin: "0 10px",
          }}
          onClick={openModal}
        >
          売上0
        </Typography>
      </Box>
      <TableContainer style={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {header.map((h) => (
                <TableCell key={h.key}>{h.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterData.map((d) => (
              <TableRow key={d.name}>
                <TableCell>{d.name}</TableCell>
                <TableCell>{d.current}</TableCell>
                <TableCell>{d.last}</TableCell>
                <TableCell>{d.current - d.last}</TableCell>
                <TableCell>
                  {d.current && d.last
                    ? MathUtils.roundToTwo((d.current / d.last) * 100)
                    : 0}
                  %
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

function CustomizeShopTick(props: any) {
  const { x, y, payload } = props;
  return (
    <foreignObject x={x - 30} y={y} width="60" height="60">
      <div style={{ fontSize: "10px" }}>{payload.value}</div>
    </foreignObject>
  );
}

const NonSalesAreaModal = () => {
  const [nonSalesAreaModal, setNonSalesAreaModal] =
    useModal("nonSalesAreaModal");

  return (
    <Modal
      show={nonSalesAreaModal.show}
      setShow={setNonSalesAreaModal}
      title={nonSalesAreaModal.meta?.title || ""}
    >
      <Box display={"flex"} flexDirection={"column"}>
        {nonSalesAreaModal.meta?.areaNames?.map((areaName: string) => (
          <Typography key={areaName} style={{ marginTop: "10px" }}>
            {areaName}
          </Typography>
        ))}
      </Box>
    </Modal>
  );
};

export default GuestAnalyticsSalesVolume;
