import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import CastReportApi from "api/CastReportApi";
import CastReportRes from "types/res/castReport/CastReportRes";
import IsReadCastReportReq from "types/req/castReport/IsReadCastReportReq";

const actionCreator = actionCreatorFactory();

const fetchCastReportAction = actionCreator.async<
  {},
  CastReportRes[],
  { code: number; description: string }
>("FETCH_CAST_REPORT");

export const fetchCastReport =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCastReportAction.started([]));

    try {
      const result = await CastReportApi.findAll(companyId);
      dispatch(
        fetchCastReportAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCastReportAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const isReadCastReportAction = actionCreator.async<
  IsReadCastReportReq,
  CastReportRes,
  { code: number; description: string }
>("IS_READ_CAST_REPORT");

export const isReadCastReport =
  (companyId: number, req: IsReadCastReportReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(isReadCastReportAction.started({ ...req }));

    try {
      const result = await CastReportApi.isRead(companyId, req);
      dispatch(
        isReadCastReportAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        isReadCastReportAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const castReportActions = {
  fetchCastReportAction,
  isReadCastReportAction,
};

export default castReportActions;
