import RequestUtils from "utils/RequestUtils";
import CastNameRes from "types/res/castName/CastNameRes";
import CreateCastNameReq from "types/req/castName/CreateCastNameReq";
import UpdateCastNameReq from "types/req/castName/UpdateCastNameReq";
import DeleteCastNameReq from "types/req/castName/DeleteCastNameReq";
import querystring from "querystring";
import FindCastNameReq from "types/req/castName/FindCastNameReq";

export default class CastNameApi {
  private constructor() {}

  static async findOne(
    companyId: number,
    castNameId: number
  ): Promise<CastNameRes> {
    return await RequestUtils.get(
      CastNameRes,
      `/castName/findOne/${companyId}/${castNameId}`
    );
  }

  static async findAll(
    companyId: number,
    shopId?: number
  ): Promise<CastNameRes[]> {
    return await RequestUtils.getArray(
      CastNameRes,
      `/castName/findAll/${companyId}${shopId ? `?shopId=${shopId}` : ""}`
    );
  }

  static async findAllWithPagination(
    companyId: number,
    req: FindCastNameReq
  ): Promise<CastNameRes[]> {
    return await RequestUtils.postArray(
      CastNameRes,
      `/castName/findAllWithPagination/${companyId}`,
      req
    );
  }

  static async count(companyId: number, req: FindCastNameReq) {
    return await RequestUtils.countPost(`/castName/count/${companyId}`, req);
  }

  static async spFind(
    companyId: number,
    shopId: number,
    date: string,
    limit: number,
    offset: number
  ): Promise<CastNameRes[]> {
    const query = {
      date,
      limit,
      offset,
    };
    return await RequestUtils.getArray(
      CastNameRes,
      `/castName/spFind/${companyId}/${shopId}?${querystring.stringify(query)}`
    );
  }

  static async spCount(companyId: number, shopId: number) {
    return await RequestUtils.count(`/castName/spCount/${companyId}/${shopId}`);
  }

  static async create(companyId: number, data: CreateCastNameReq) {
    return await RequestUtils.post(
      CastNameRes,
      `/castName/create/${companyId}`,
      data
    );
  }

  static async bulkInsert(companyId: number, data: CreateCastNameReq[]) {
    return await RequestUtils.postArray(
      CastNameRes,
      `/castName/bulkInsert/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    castNameId: number,
    data: UpdateCastNameReq
  ): Promise<CastNameRes> {
    return await RequestUtils.post(
      CastNameRes,
      `/castName/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteCastNameReq) {
    return await RequestUtils.postVoid(`/castName/delete/${companyId}`, data);
  }
}
