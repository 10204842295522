import { reducerWithInitialState } from "typescript-fsa-reducers";
import modalActions from "redux/actions/modals";

export type ModalState = {
  [key: string]: { show: boolean; meta?: any };
};

const initialState: ModalState = {
  signin: { show: false },
  addData: { show: false, meta: {} },
  editData: { show: false, meta: {} },
  deleteData: { show: false, meta: {} },
  bulkDeleteData: { show: false, meta: {} },
  castScheduleOnItemMove: { show: false, meta: {} },
  castScheduleOnItemResize: { show: false, meta: {} },
  castScheduleOnItemCreate: { show: false, meta: {} },
  castScheduleOnItemMoveChangeClass: { show: false, meta: {} },
  castScheduleShiftAbsence: { show: false, meta: {} },
  castScheduleOnShiftMove: { show: false, meta: {} },
  castScheduleOnItemCreateNoCast: { show: false, meta: {} },
  ctiModal: { show: false, meta: {} },
  driverScheduleOnItemMove: { show: false, meta: {} },
  driverScheduleOnShiftMove: { show: false, meta: {} },
  driverScheduleOnItemCreate: { show: false, meta: {} },
  companyForm: { show: false, meta: {} },
  areaForm: { show: false, meta: {} },
  castForm: { show: false, meta: {} },
  cosplayForm: { show: false, meta: {} },
  discountForm: { show: false, meta: {} },
  driverForm: { show: false, meta: {} },
  shopForm: { show: false, meta: {} },
  optionForm: { show: false, meta: {} },
  staffForm: { show: false, meta: {} },
  addPointForm: { show: false, meta: {} },
  planSelectModal: { show: false, meta: {} },
  cardPaymentModal: { show: false, meta: {} },
  paymentSendMailModal: { show: false, meta: {} },
  selectFreeModal: { show: false, meta: {} },
  nonSalesAreaModal: { show: false, meta: {} },
  spCastShiftModal: { show: false, meta: {} },
  spDriverShiftModal: { show: false, meta: {} },
};

const modalsReducer = reducerWithInitialState(initialState).case(
  modalActions.setModalShow,
  (state, { id, show, meta }) => {
    return { ...state, [id]: { show, meta } };
  }
);

export default modalsReducer;
