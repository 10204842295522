import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import OrderRes from "types/res/order/OrderRes";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";

type Props = {
  orders: OrderRes[];
  message: string;
};

const SpecPdf: React.FC<Props> = ({ orders, message }) => {
  Font.register({
    family: "SourceHanSansJP",
    src: "/fonts/SourceHanSansJP-VF.ttf",
  });
  const BORDER_COLOR = "#bfbfbf";
  const BORDER_STYLE = "solid";
  const COL_TEXT_WIDTH = 10;
  const COLN_WIDTH = (100 - COL_TEXT_WIDTH * 4) / 13;
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      fontFamily: "SourceHanSansJP",
    },
    title: {
      textAlign: "center",
      fontSize: 24,
      fontWeight: "bold",
      marginTop: 10,
    },
    body: {
      padding: 10,
    },
    table: {
      width: "auto",
      marginTop: 20,
      marginLeft: 5,
      marginRight: 5,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableColTextHeader: {
      width: COL_TEXT_WIDTH + "%",
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 1,
      borderTopWidth: 1,
    },
    tableColHeader: {
      width: COLN_WIDTH + "%",
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },
    tableColText: {
      width: COL_TEXT_WIDTH + "%",
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
      borderLeftWidth: 1,
      borderTopWidth: 0,
      flexDirection: "column",
    },
    tableColSumText: {
      width: COL_TEXT_WIDTH * 4 + COLN_WIDTH + "%",
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
      borderLeftWidth: 1,
      borderTopWidth: 0,
      flexDirection: "column",
    },
    tableCol: {
      width: COLN_WIDTH + "%",
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCellHeader: {
      margin: 5,
      fontSize: 12,
      fontWeight: 500,
    },
    tableCell: {
      margin: 5,
      fontSize: 10,
    },
    footer: {
      padding: 10,
      border: "1px solid #000",
      margin: 5,
      fontSize: 12,
      flexDirection: "column",
    },
    footerTitle: {
      fontSize: 16,
      fontWeight: "bold",
      marginLeft: 5,
      marginTop: 5,
    },
    footerCompanyName: {
      fontSize: 16,
      textAlign: "right",
      marginTop: 30,
      marginRight: 5,
    },
  });
  if (!orders.length) return null;
  return (
    <Document language={"ja"}>
      <Page size="B3" orientation="landscape" style={styles.page}>
        <View style={styles.title}>
          <Text>支払通知書</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>日付</Text>
            </View>
            <View style={styles.tableColTextHeader}>
              <Text style={styles.tableCellHeader}>顧客名</Text>
            </View>
            <View style={styles.tableColTextHeader}>
              <Text style={styles.tableCellHeader}>店名</Text>
            </View>
            <View style={styles.tableColTextHeader}>
              <Text style={styles.tableCellHeader}>源氏名</Text>
            </View>
            <View style={styles.tableColTextHeader}>
              <Text style={styles.tableCellHeader}>コース名</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>コース</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>延長</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>指名</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>その他</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>交通費</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>割引・イベント</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>媒体</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>コスプレ</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>オプション</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>厚生費</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>インボイス</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>合計</Text>
            </View>
          </View>
          {orders.map((order) => (
            <View style={styles.tableRow} key={order.orderId}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {DateTimeUtils.toFormatAsLocalTimezone(
                    order.orderDate,
                    FORMAT_TYPE.YEAR_DAY
                  )}
                </Text>
              </View>
              <View style={styles.tableColText}>
                <Text style={styles.tableCell}>{order?.guest?.name}</Text>
              </View>
              <View style={styles.tableColText}>
                <Text style={styles.tableCell}>{order?.shop?.name}</Text>
              </View>
              <View style={styles.tableColText}>
                <Text style={styles.tableCell}>
                  {order?.castName?.name} ({order?.castName?.cast?.name})
                </Text>
              </View>
              <View style={styles.tableColText}>
                <Text style={styles.tableCell}>{order?.course?.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {order?.courseCastFee || 0}円
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {order?.additionalCastFee || 0}円
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {order?.designateCastFee || 0}円
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {order?.otherCastFee || 0}円
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {order?.areaCastFee || 0}円
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {order?.discountCastFee || 0}円
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {order?.mediumCastFee || 0}円
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {order?.cosplayCastFee || 0}円
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {order?.optionCastFee || 0}円
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order?.welfareFee || 0}円</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order?.invoiceFee || 0}円</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {order?.totalCastFee || 0}円
                </Text>
              </View>
            </View>
          ))}
          <View style={styles.tableRow}>
            <View style={styles.tableColSumText}>
              <Text style={styles.tableCell}>合計</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {orders.reduce(
                  (sum, order) => sum + (order.courseCastFee || 0),
                  0
                )}
                円
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {orders.reduce(
                  (sum, order) => sum + (order.additionalCastFee || 0),
                  0
                )}
                円
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {orders.reduce(
                  (sum, order) => sum + (order.designateCastFee || 0),
                  0
                )}
                円
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {orders.reduce(
                  (sum, order) => sum + (order.otherCastFee || 0),
                  0
                )}
                円
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {orders.reduce(
                  (sum, order) => sum + (order.areaCastFee || 0),
                  0
                )}
                円
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {orders.reduce(
                  (sum, order) => sum + (order.discountCastFee || 0),
                  0
                )}
                円
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {orders.reduce(
                  (sum, order) => sum + (order.mediumCastFee || 0),
                  0
                )}
                円
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {orders.reduce(
                  (sum, order) => sum + (order.cosplayCastFee || 0),
                  0
                )}
                円
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {orders.reduce(
                  (sum, order) => sum + (order.optionCastFee || 0),
                  0
                )}
                円
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {orders.reduce(
                  (sum, order) => sum + (order.welfareFee || 0),
                  0
                )}
                円
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {orders.reduce(
                  (sum, order) => sum + (order.invoiceFee || 0),
                  0
                )}
                円
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {orders.reduce(
                  (sum, order) => sum + (order.totalCastFee || 0),
                  0
                )}
                円
              </Text>
            </View>
          </View>
        </View>
        <Text style={styles.footerCompanyName}>
          企業名：{orders?.[0]?.company?.name}
        </Text>
        <Text style={styles.footerTitle}>キャストの方へのメッセージ</Text>
        <View style={styles.footer}>
          <Text>{message}</Text>
        </View>
      </Page>
    </Document>
  );
};
export default SpecPdf;
