import { reducerWithInitialState } from "typescript-fsa-reducers";
import settingBookEmailActions from "redux/actions/settingBookEmail";
import SettingBookEmailRes from "types/res/settingBookEmail/SettingBookEmailRes";
export type SettingBookEmailState = SettingBookEmailRes[];

const initialState = [] as SettingBookEmailState;

const settingBookEmailReducer = reducerWithInitialState(initialState)
  .case(
    settingBookEmailActions.updateSettingBookEmailAction.done,
    (state, payload) => {
      return state.map((item) =>
        item.settingBookEmailId === payload.params.settingBookEmailId
          ? payload.result
          : item
      );
    }
  )
  .case(
    settingBookEmailActions.fetchSettingBookEmailAction.done,
    (state, payload) => {
      return [...payload.result];
    }
  );
export default settingBookEmailReducer;
