import { reducerWithInitialState } from "typescript-fsa-reducers";
import companyGroupActions from "redux/actions/companyGroup";
import CompanyGroupRes from "types/res/companyGroup/CompanyGroupRes";
export type CompanyGroupState = CompanyGroupRes[];

const initialState = [] as CompanyGroupState;

const companyGroupReducer = reducerWithInitialState(initialState)
  .case(companyGroupActions.addCompanyGroupAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(companyGroupActions.updateCompanyGroupAction.done, (state, payload) => {
    return state.map((item) =>
      item.companyGroupId === payload.params.companyGroupId
        ? payload.result
        : item
    );
  })
  .case(companyGroupActions.recessCompanyGroupAction.done, (state, payload) => {
    return state.map((item) =>
      item.companyGroupId === payload.params.companyGroupId
        ? { ...item, recessedAt: item.recessedAt ? null : new Date() }
        : item
    );
  })
  .case(companyGroupActions.deleteCompanyGroupAction.done, (state, payload) => {
    return state.filter(
      (item) => item.companyGroupId !== payload.params.companyGroupId
    );
  })
  .case(companyGroupActions.fetchCompanyGroupsAction.done, (state, payload) => {
    return payload.result;
  });
export default companyGroupReducer;
