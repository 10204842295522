import React, { useEffect } from "react";
import { Box, Button, Table, TextField, Typography } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useDispatch, useSelector } from "react-redux";
import TableBody from "@material-ui/core/TableBody";
import StaffRole from "types/enum/StaffRole";
import EnumUtils from "utils/EnumUtils";
import { fetchCastName } from "redux/actions/castName";
import { fetchGuestNotesByCastNameId } from "redux/actions/guestNote";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import { Autocomplete } from "@material-ui/lab";
import CastNameRes from "types/res/castName/CastNameRes";
import GuestRank from "types/enum/GuestRank";

const GuestNote = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const staffRole = useSelector((state) => state.account.staff.role);
  const castNames = useSelector((state) => state.castName);
  const guestNotes = useSelector((state) => state.guestNote);
  const [selectCastName, setSelectCastName] =
    React.useState<CastNameRes | null>(null);
  const [selectGuestNoteId, setSelectGuestNoteId] = React.useState<number>();
  useEffect(() => {
    dispatch(fetchCastName(companyId));
  }, [companyId]);

  useEffect(() => {
    if (!selectCastName?.castNameId) return;
    dispatch(fetchGuestNotesByCastNameId(companyId, selectCastName.castNameId));
  }, [selectCastName]);
  if (
    ![
      StaffRole.notelMaster,
      StaffRole.notelCallCenterEmployee,
      StaffRole.clientMaster,
    ].includes(
      EnumUtils.mapToEnum(StaffRole, staffRole) || StaffRole.clientShopPartTime
    )
  ) {
    return <Box>管理者権限が必要です。</Box>;
  }

  const guestNoteTable = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>顧客名</TableCell>
            <TableCell>顧客電話番号</TableCell>
            <TableCell>最終利用日</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {guestNotes.map((guestNote) => (
            <TableRow key={guestNote.guestNoteId}>
              <TableCell>{guestNote.guest?.name}</TableCell>
              <TableCell>{guestNote.guest?.tel}</TableCell>
              <TableCell>
                {guestNote?.orders?.[0]?.orderDate
                  ? DateTime.fromJSDate(
                      guestNote?.orders?.[0]?.orderDate
                    ).toFormat(FORMAT_TYPE.YEAR_DAY)
                  : ""}
              </TableCell>
              <TableCell>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setSelectGuestNoteId(guestNote.guestNoteId)}
                  >
                    選択
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const guestNoteOne = () => {
    const guestNote = guestNotes.find(
      (note) => note.guestNoteId === selectGuestNoteId
    );
    return (
      <Box>
        <Box display={"flex"} margin={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSelectGuestNoteId(undefined)}
          >
            戻る
          </Button>
        </Box>
        <Box display={"flex"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            margin={2}
            width={"50%"}
          >
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              基本情報
            </Typography>
            <Typography>顧客名：{guestNote?.guest?.name}</Typography>
            <Typography>顧客名：{guestNote?.guest?.tel}</Typography>
            <Typography>あだ名：{guestNote?.nickName}</Typography>
            <Typography>
              顧客ランク：{EnumUtils.mapToEnum(GuestRank, guestNote?.guestRank)}
            </Typography>
            <Typography>仕事：{guestNote?.work}</Typography>
            <Typography>性格：{guestNote?.personality}</Typography>
            <Typography>体型：{guestNote?.bodyType}</Typography>
            <Typography>ペット：{guestNote?.pet}</Typography>
            <Typography>タバコ：{guestNote?.cigarette}</Typography>
            <Typography>自宅の種類：{guestNote?.home}</Typography>
            <Typography>住所：{guestNote?.address}</Typography>
            <Typography>好きなタイプ：{guestNote?.favoriteType}</Typography>
            <Typography>好きな食べ物：{guestNote?.favoriteFood}</Typography>
            <Typography>好きな色：{guestNote?.favoriteColor}</Typography>
            <Typography>アレルギー：{guestNote?.allergies}</Typography>
            <Typography>利用頻度：{guestNote?.usingFrequency}</Typography>
            <Typography>備考：{guestNote?.remarks}</Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} margin={2}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              接客詳細
            </Typography>
            <Typography>好みのプレイ：{guestNote?.favoritePlay}</Typography>
            <Typography>性器のサイズ：{guestNote?.genitalSize}</Typography>
            <Typography>性器の状態：{guestNote?.genitalState}</Typography>
            <Typography>プレイ回数：{guestNote?.playNum}</Typography>
            <Typography>性感帯：{guestNote?.erogenousZone}</Typography>
            <Typography>本番要求：{guestNote?.request}</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box margin={2}>
      <Box display={"flex"} alignItems={"center"}>
        <Typography>キャスト名</Typography>
        <Autocomplete
          id="search-castName"
          size="small"
          options={castNames}
          getOptionLabel={(option) =>
            option.name ? `${option.name}(${option?.shop?.name})` : ""
          }
          style={{ width: 300, margin: 5 }}
          renderInput={(params) => <TextField {...params} autoFocus={true} />}
          value={selectCastName || null}
          onChange={(event: any, newValue: CastNameRes | null) => {
            setSelectCastName(newValue);
          }}
          renderOption={(option) => (
            <div>
              {option.name ? `${option.name}(${option?.shop?.name})` : ""}
            </div>
          )}
        />
      </Box>
      <Box marginTop={2}>
        {selectCastName?.castNameId ? (
          selectGuestNoteId ? (
            guestNoteOne()
          ) : (
            guestNoteTable()
          )
        ) : (
          <Box display={"flex"} justifyContent={"center"} marginTop={2}>
            キャストを選択してください。
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default GuestNote;
