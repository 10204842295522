import React from "react";
import Box from "@material-ui/core/Box";
import { Divider, MenuItem, TextField, Typography } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { useSelector } from "react-redux";
type Props = {
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  shopId?: number;
  onChangeFormData: (key: string, value: any) => void;
};
const SpOrderHeader = ({
  pageNum,
  setPageNum,
  shopId,
  onChangeFormData,
}: Props) => {
  const shops = useSelector((state) => state.shop);
  const pageList = [
    "顧客情報",
    "受注情報",
    "配車・キャスト情報",
    "料金詳細",
    "内容確認",
  ];
  const handleClick = (index: number) => {
    setPageNum(index);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        position: "sticky",
        top: "56px",
        left: "0",
        backgroundColor: "white",
        zIndex: "1000",
      }}
    >
      <Box
        display={"flex"}
        justifyContent="space-evenly"
        alignContent={"center"}
        marginTop={2}
      >
        {pageList.map((page, index) => (
          <Box display={"flex"} key={page} alignContent={"center"}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"43px"}
              onClick={() => handleClick(index)}
            >
              <Box
                display={"flex"}
                justifyContent="center"
                style={{
                  paddingTop: "8px",
                  height: "43px",
                  backgroundColor: "#358BC8",
                  color: "white",
                  borderRadius: "50%",
                }}
              >
                <Typography>{index + 1}</Typography>
              </Box>
              <Typography
                style={{
                  fontSize: "10px",
                  color: index === pageNum ? "#358BC8" : "gray",
                  paddingTop: "3px",
                }}
              >
                {page}
              </Typography>
            </Box>
            {index !== pageList.length - 1 && (
              <Box marginTop={"6px"} paddingLeft={"4px"}>
                <PlayArrowIcon style={{ fill: "#D9D7D7", stroke: "#D9D7D7" }} />
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <TextField
          select
          variant="outlined"
          value={shopId || ""}
          label={"店舗選択"}
          style={{ width: "70%" }}
          onChange={(event) => {
            event.persist();
            onChangeFormData("shopId", Number(event.target.value));
          }}
        >
          {shops.map((shop) => (
            <MenuItem key={shop.shopId} value={shop.shopId}>
              {shop.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Divider style={{ marginTop: "16px" }} />
    </Box>
  );
};
export default SpOrderHeader;
