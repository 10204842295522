import { IsNotEmpty } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";

export default class DeleteGuestReq {
  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  readonly guestId: number;

  constructor(arg: { guestId: number }) {
    arg = arg || {};
    this.guestId = arg.guestId;
  }
}
