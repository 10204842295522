import { Expose } from "class-transformer";

export default class AccountingRes {
  @Expose() readonly paidTotalFee!: number;
  @Expose() readonly paidDiscountFee!: number;
  @Expose() readonly paidHotelFee!: number;
  @Expose() readonly paidTotalCastFee!: number;
  @Expose() readonly paidPayoffCastFee!: number;
  @Expose() readonly paidCardFee!: number;
  @Expose() readonly bookingTotalFee!: number;
  @Expose() readonly bookingDiscountFee!: number;
  @Expose() readonly bookingHotelFee!: number;
  @Expose() readonly bookingTotalCastFee!: number;
  @Expose() readonly bookingCardFee!: number;
}
