import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchShops } from "redux/actions/shop";
import {
  addGuestCategory,
  deleteGuestCategory,
  simpleFetchGuestCategory,
  updateGuestCategory,
} from "redux/actions/guestCategory";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import GuestCategoryRes from "types/res/guestCategory/GuestCategoryRes";
import { FormType } from "components/FormField";
import CreateGuestCategoryReq from "types/req/guestCategory/CreateGuestCategoryReq";
import UpdateGuestCategoryReq from "types/req/guestCategory/UpdateGuestCategoryReq";

const headers: { key: keyof GuestCategoryRes; label: string }[] = [
  { key: "name", label: "顧客区分名" },
  { key: "shops", label: "適用店舗" },
  { key: "pointGrantFlag", label: "ポイント付与フラグ" },
  { key: "unUseUserFlag", label: "未利用ユーザー自動付与フラグ" },
  { key: "secondUserFlag", label: "2回目ユーザー自動付与フラグ" },
];

const GuestCategory: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const company = useSelector((state) => state.account.staff.company);
  const guestCategory = useSelector((state) => state.guestCategory);
  const shops = useSelector((state) => state.shop);
  const [defaultShops, setDefaultShops] = useState<number[]>([]);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(simpleFetchGuestCategory(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    setDefaultShops(
      company.guestCategoryShopFlag ? [] : shops.map((shop) => shop.shopId)
    );
  }, [shops]);
  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "適用店舗",
      key: "shops",
      type: FormType.MultiOptions,
      options: shops.map((s) => ({ id: Number(s.shopId), name: s.name })),
      value: (val: any) => val.map((s: any) => s.shopId),
      disabled: !company.guestCategoryShopFlag,
    },
    {
      label: "ポイント付与フラグ",
      key: "pointGrantFlag",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "付与しない" },
        { id: "1", name: "付与する" },
      ],
    },
    {
      label: "未利用ユーザー自動付与フラグ",
      key: "unUseUserFlag",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "付与しない" },
        { id: "1", name: "付与する" },
      ],
    },
    {
      label: "2回目ユーザー自動付与フラグ",
      key: "secondUserFlag",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "付与しない" },
        { id: "1", name: "付与する" },
      ],
    },
  ];
  return (
    <>
      <GuestCategoryTable
        title={"顧客区分"}
        formId="guestCategoryForm"
        forms={forms}
        rows={headers}
        data={guestCategory}
        addType={CreateGuestCategoryReq}
        updateType={UpdateGuestCategoryReq}
        addFunc={(formData) => addGuestCategory(companyId, formData)}
        updateFunc={(formData) => updateGuestCategory(companyId, formData)}
        deleteFunc={(item) =>
          deleteGuestCategory(companyId, {
            guestCategoryId: item.guestCategoryId,
          })
        }
        defaultValue={{ shops: defaultShops }}
        values={[
          (s) => s.name,
          (s) =>
            !company.guestCategoryShopFlag
              ? "全店舗"
              : s.shops?.map((shop) => shop.name).join(",") || "未設定",
          (s) => (s.pointGrantFlag ? "付与する" : "付与しない"),
          (s) => (s.unUseUserFlag ? "付与する" : "付与しない"),
          (s) => (s.secondUserFlag ? "付与する" : "付与しない"),
        ]}
      />
    </>
  );
};

const GuestCategoryTable = styled<
  React.FC<
    CommonTableProps<
      GuestCategoryRes,
      CreateGuestCategoryReq,
      UpdateGuestCategoryReq
    >
  >
>(CommonTable)`
  margin-top: 24px;
`;

export default GuestCategory;
