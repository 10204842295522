import RequestUtils from "utils/RequestUtils";
import CastReportRes from "types/res/castReport/CastReportRes";

export default class CastReportApi {
  private constructor() {}

  static async findAll(companyId: number) {
    return await RequestUtils.getArray(
      CastReportRes,
      `/castReport/findAll/${companyId}`
    );
  }

  static async isRead(companyId: number, req: { castReportId: number }) {
    return await RequestUtils.post(
      CastReportRes,
      `/castReport/isRead/${companyId}`,
      req
    );
  }
}
