import CourseBaseReq from "./CourseBaseReq";
import { IsNotEmpty } from "class-validator";
import { DataValidationError } from "../../../constants/DataValidationError";

export default class UpdateCourseReq extends CourseBaseReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  readonly courseId: number;
  constructor(
    arg: ConstructorParameters<typeof CourseBaseReq>[0] & {
      courseId: number;
    }
  ) {
    arg = arg || {};
    super(arg);
    this.courseId = arg.courseId;
  }
}
