import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty, MaxLength } from "class-validator";
import Spec from "constants/Spec";

export default class UpdateMessageBoardReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  messageBoardId!: number;

  @MaxLength(Spec.maxLength.messageBoard.title, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.messageBoard.title,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  title!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  content!: string;

  constructor(arg: { messageBoardId: number; title: string; content: string }) {
    arg = arg || {};
    this.messageBoardId = arg.messageBoardId;
    this.title = arg.title;
    this.content = arg.content;
  }
}
