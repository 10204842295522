import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Cell,
} from "recharts";
import { Box, MenuItem, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import OrderRes from "types/res/order/OrderRes";

type CastRankingGraphProps = {
  displayData: any[];
  calendarDisplayKey: string;
};
const CastRankingGraph = ({
  displayData,
  calendarDisplayKey,
}: CastRankingGraphProps) => {
  const [displayKey, setDisplayKey] = useState("");
  const [selectList, setSelectList] = useState<{ [key: string]: string }>({});
  const [data, setData] = useState<any[]>([]);
  const nominations = useSelector((state) => state.nomination);
  const casts = useSelector((state) => state.cast);
  useEffect(() => {
    switch (calendarDisplayKey) {
      case "attendance": {
        setSelectList({
          commutingTimes: "出勤時間",
          tardyCount: "遅刻",
          absenceCount: "当欠",
        });
        setDisplayKey("commutingTimes");
        break;
      }
      case "orderCount": {
        const nominationMap = new Map(
          nominations.map((nomination) => [
            [`${nomination.nominationId}Count`],
            `${nomination.name}本数`,
          ])
        );
        const nominationList = Object.fromEntries(nominationMap);
        setSelectList({
          orderCount: "総本数",
          ...nominationList,
          averageScore: "平均スコア",
        });
        setDisplayKey("orderCount");
        break;
      }
      case "nominationRate": {
        const nominationMap = new Map(
          nominations.map((nomination) => [
            [`${nomination.nominationId}Rate`],
            `${nomination.name}率`,
          ])
        );
        const nominationList = Object.fromEntries(nominationMap);
        setSelectList({ ...nominationList });
        setDisplayKey(Object.keys(nominationList)[0]);
        break;
      }
      case "revenue": {
        setSelectList({
          totalRevenue: "総売上",
          shopFee: "店売上",
          castFee: "キャスト給",
        });
        setDisplayKey("totalRevenue");
        break;
      }
      case "cast": {
        setSelectList({
          castFee: "キャスト給",
          castFeeByHour: "キャスト時給",
          averageCourseTime: "平均コース時間",
        });
        setDisplayKey("castFee");
        break;
      }
      default: {
        break;
      }
    }
  }, [calendarDisplayKey]);

  useEffect(() => {
    if (!displayData) return;
    switch (displayKey) {
      case "commutingTimes": {
        const d = displayData.map((Dd) => ({
          date: Dd.date,
          cast: Dd.data.cast.commutingTimes,
          shop: Dd.data.shop.commutingTimes / casts.length,
        }));
        setData(d);
        break;
      }
      case "tardyCount": {
        const d = displayData.map((Dd) => ({
          date: Dd.date,
          cast: Dd.data.cast.tardyCount,
          shop: Dd.data.shop.tardyCount / casts.length,
        }));
        setData(d);
        break;
      }
      case "absenceCount": {
        const d = displayData.map((Dd) => ({
          date: Dd.date,
          cast: Dd.data.cast.absenceCount,
          shop: Dd.data.shop.absenceCount / casts.length,
        }));
        setData(d);
        break;
      }
      case "orderCount": {
        const d = Object.entries(displayData).map(([date, Dd]) => ({
          date,
          cast: Dd?.cast?.orderCount,
          shop: Dd?.shop?.orderCount / casts.length,
        }));
        setData(d);
        break;
      }
      case "averageScore": {
        const d = Object.entries(displayData).map(([date, Dd]) => ({
          date,
          cast: Dd?.cast?.averageScore,
          shop: Dd?.shop?.averageScore / casts.length,
        }));
        setData(d);
        break;
      }
      case "totalRevenue": {
        const d = Object.entries(displayData).map(([date, Dd]) => ({
          date,
          cast: Dd?.cast?.reduce(
            (sum: number, c: OrderRes) => sum + (c?.totalFee || 0),
            0
          ),
          shop:
            Dd?.shop?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.totalFee || 0),
              0
            ) / casts.length,
        }));
        setData(d);
        break;
      }
      case "shopFee": {
        const d = Object.entries(displayData).map(([date, Dd]) => ({
          date,
          cast: Dd?.cast?.reduce(
            (sum: number, c: OrderRes) => sum + (c?.totalShopFee || 0),
            0
          ),
          shop:
            Dd?.shop?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.totalShopFee || 0),
              0
            ) / casts.length,
        }));
        setData(d);
        break;
      }
      case "castFee": {
        const d = Object.entries(displayData).map(([date, Dd]) => ({
          date,
          cast: Dd?.cast?.reduce(
            (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
            0
          ),
          shop:
            Dd?.shop?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
              0
            ) / casts.length,
        }));
        setData(d);
        break;
      }
      case "castFeeByHour": {
        const d = Object.entries(displayData).map(([date, Dd]) => ({
          date,
          cast:
            Dd?.cast?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
              0
            ) /
            (Dd?.cast?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.courseTime || 0),
              0
            ) /
              60),
          shop:
            Dd?.shop?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.totalCastFee || 0),
              0
            ) /
            (Dd?.shop?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.courseTime || 0),
              0
            ) /
              60) /
            casts.length,
        }));
        setData(d);
        break;
      }
      case "averageCourseTime": {
        const d = Object.entries(displayData).map(([date, Dd]) => ({
          date,
          cast:
            Dd?.cast?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.courseTime || 0),
              0
            ) / Dd?.cast?.length,
          shop:
            Dd?.shop?.reduce(
              (sum: number, c: OrderRes) => sum + (c?.courseTime || 0),
              0
            ) /
            Dd?.shop?.length /
            casts.length,
        }));
        setData(d);
        break;
      }
    }
    nominations.map((nomination) => {
      switch (displayKey) {
        case `${nomination.nominationId}Count`: {
          const d = Object.entries(displayData).map(([date, Dd]) => ({
            date,
            cast: Dd?.cast?.[`${nomination.nominationId}Count`],
            shop:
              Dd?.shop?.[`${nomination.nominationId}Count`] / casts.length || 0,
          }));
          setData(d);
          break;
        }
        case `${nomination.nominationId}Rate`: {
          const d = Object.entries(displayData).map(([date, Dd]) => {
            return {
              date,
              cast:
                (Dd?.cast?.[`${nomination.nominationId}Count`] /
                  Dd?.cast?.orderCount) *
                  100 || 0,
              shop:
                (Dd?.shop?.[`${nomination.nominationId}Count`] /
                  Dd?.shop?.orderCount /
                  casts.length) *
                  100 || 0,
            };
          });
          setData(d);
          break;
        }
      }
    });
  }, [displayKey, displayData]);
  return (
    <Box display="flex" flexDirection="column">
      <div>
        <TextField
          select
          label={"表示項目"}
          value={displayKey}
          style={{ width: "150px" }}
          onChange={(event) => setDisplayKey(event.target.value)}
        >
          {Object.entries(selectList).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <BarChart
        width={800}
        height={650}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="top" />
        <Bar dataKey={"shop"} name={"店舗"} key={"shop"} fill="#2699F9" />
        <Bar dataKey={"cast"} name={"キャスト"} key={"cast"} fill="#BCE0FD">
          {data.map((d, index) => (
            <Cell
              key={index}
              fill={
                d.cast > d.shop
                  ? "#7DE286"
                  : d.cast === d.shop
                  ? "#BCE0FD"
                  : "#FFE797"
              }
            />
          ))}
        </Bar>
      </BarChart>
    </Box>
  );
};
export default CastRankingGraph;
