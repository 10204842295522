import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import StaffApi from "api/StaffApi";
import StaffRes from "types/res/staff/StaffRes";
import UpdateStaffReq from "types/req/staff/UpdateStaffReq";
import CreateStaffReq from "types/req/staff/CreateStaffReq";
import DeleteStaffReq from "types/req/staff/DeleteStaffReq";
import accountActions from "./account";

const actionCreator = actionCreatorFactory();
const addStaffAction = actionCreator.async<
  CreateStaffReq,
  StaffRes,
  { code: number; description: string }
>("ADD_STAFF");

export const addStaff =
  (companyId: number, req: CreateStaffReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addStaffAction.started({ ...req }));

    try {
      const staff = new CreateStaffReq({ ...req });
      const result = await StaffApi.create(companyId, staff);
      dispatch(
        addStaffAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addStaffAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchStaffAction = actionCreator.async<
  {},
  StaffRes[],
  { code: number; description: string }
>("FETCH_STAFF");

export const fetchStaff =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchStaffAction.started([]));

    try {
      const result = await StaffApi.findAll(companyId);

      dispatch(
        fetchStaffAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchStaffAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateStaffAction = actionCreator.async<
  UpdateStaffReq,
  StaffRes,
  { code: number; description: string }
>("UPDATE_STAFF");

export const updateStaff =
  (companyId: number, req: UpdateStaffReq, accessToken?: string) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateStaffAction.started({ ...req }));

    try {
      const updateStaff = new UpdateStaffReq({ ...req });
      const result = await StaffApi.update(companyId, req.staffId, updateStaff);
      dispatch(
        updateStaffAction.done({
          params: { ...req },
          result,
        })
      );
      if (req.companyId && accessToken) {
        dispatch(
          accountActions.signinWithEmailAction.done({
            params: { email: result.email, password: "" },
            result: { accessToken, staff: result },
          })
        );
      }
      if (req.companyId && req.isForceSite && accessToken) {
        dispatch(
          accountActions.signinWithEmailAction.done({
            params: { email: result.email, password: "" },
            result: { accessToken, staff: result },
          })
        );
      }
      return result;
    } catch (error) {
      dispatch(
        updateStaffAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteStaffAction = actionCreator.async<
  DeleteStaffReq,
  {},
  { code: number; description: string }
>("DELETE_STAFF");

export const deleteStaff =
  (companyId: number, req: DeleteStaffReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteStaffAction.started({ ...req }));

    try {
      const staff = new DeleteStaffReq({ ...req });
      const result = await StaffApi.delete(companyId, staff);
      dispatch(
        deleteStaffAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteStaffAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const staffActions = {
  fetchStaffAction,
  addStaffAction,
  updateStaffAction,
  deleteStaffAction,
};

export default staffActions;
