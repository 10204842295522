import { reducerWithInitialState } from "typescript-fsa-reducers";
import driverActions from "redux/actions/driver";
import DriverRes from "types/res/driver/DriverRes";
export type DriverState = DriverRes[];

const initialState = [] as DriverState;

const driverReducer = reducerWithInitialState(initialState)
  .case(driverActions.addDriverAction.done, (state, payload) => {
    return [...state, payload.result];
  })
  .case(driverActions.updateDriverAction.done, (state, payload) => {
    return state.map((item) =>
      item.driverId === payload.params.driverId ? payload.result : item
    );
  })
  .case(driverActions.deleteDriverAction.done, (state, payload) => {
    return state.filter((item) => item.driverId !== payload.params.driverId);
  })
  .case(driverActions.fetchDriverAction.done, (state, payload) => {
    return [...payload.result];
  })
  .case(driverActions.bulkInsertDriverAction.done, (state, payload) => {
    return [...state, ...payload.result];
  });
export default driverReducer;
