import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import CastCategoryApi from "api/CastCategoryApi";
import CastCategoryRes from "types/res/castCategory/CastCategoryRes";
import UpdateCastCategoryReq from "types/req/castCategory/UpdateCastCategoryReq";
import CreateCastCategoryReq from "types/req/castCategory/CreateCastCategoryReq";
import DeleteCastCategoryReq from "types/req/castCategory/DeleteCastCategoryReq";

const actionCreator = actionCreatorFactory();
const addCastCategoryAction = actionCreator.async<
  CreateCastCategoryReq,
  CastCategoryRes,
  { code: number; description: string }
>("ADD_CAST_CATEGORY");

export const addCastCategory =
  (companyId: number, req: CreateCastCategoryReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addCastCategoryAction.started({ ...req }));

    try {
      const castCategory = new CreateCastCategoryReq({ ...req });
      const result = await CastCategoryApi.create(companyId, castCategory);
      dispatch(
        addCastCategoryAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addCastCategoryAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchCastCategoryAction = actionCreator.async<
  {},
  CastCategoryRes[],
  { code: number; description: string }
>("FETCH_CAST_CATEGORY");

export const fetchCastCategory =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchCastCategoryAction.started([]));

    try {
      const result = await CastCategoryApi.findAll(companyId);
      dispatch(
        fetchCastCategoryAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchCastCategoryAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
    }
  };

const updateCastCategoryAction = actionCreator.async<
  UpdateCastCategoryReq,
  CastCategoryRes,
  { code: number; description: string }
>("UPDATE_CAST_CATEGORY");

export const updateCastCategory =
  (companyId: number, req: UpdateCastCategoryReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateCastCategoryAction.started({ ...req }));

    try {
      const updateCastCategory = new UpdateCastCategoryReq({ ...req });
      const result = await CastCategoryApi.update(
        companyId,
        req.castCategoryId,
        updateCastCategory
      );
      dispatch(
        updateCastCategoryAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateCastCategoryAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteCastCategoryAction = actionCreator.async<
  DeleteCastCategoryReq,
  {},
  { code: number; description: string }
>("DELETE_CAST_CATEGORY");

export const deleteCastCategory =
  (companyId: number, req: DeleteCastCategoryReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteCastCategoryAction.started({ ...req }));

    try {
      const castCategory = new DeleteCastCategoryReq({ ...req });
      const result = await CastCategoryApi.delete(companyId, castCategory);
      dispatch(
        deleteCastCategoryAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteCastCategoryAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const castCategoryActions = {
  fetchCastCategoryAction,
  addCastCategoryAction,
  updateCastCategoryAction,
  deleteCastCategoryAction,
};

export default castCategoryActions;
