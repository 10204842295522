import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import OrderRes from "types/res/order/OrderRes";
import PayoffApi from "../../api/PayoffApi";

const actionCreator = actionCreatorFactory();

const fetchPayoffAction = actionCreator.async<
  {},
  OrderRes[],
  { code: number; description: string }
>("FETCH_PAYOFF");

export const fetchPayoff =
  (
    companyId: number,
    startDate: string,
    endDate: string,
    shopId?: number,
    castNameId?: number
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(fetchPayoffAction.started([]));

    try {
      const result = await PayoffApi.findAll(
        companyId,
        startDate,
        endDate,
        shopId,
        castNameId
      );
      dispatch(
        fetchPayoffAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchPayoffAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const spFetchPayoffAction = actionCreator.async<
  {},
  OrderRes[],
  { code: number; description: string }
>("SP_FETCH_PAYOFF");

export const spFetchPayoff =
  (companyId: number, startDate: string, endDate: string, castIds: number[]) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(spFetchPayoffAction.started([]));

    try {
      const result = await PayoffApi.spFindAllByCast(
        companyId,
        startDate,
        endDate,
        castIds
      );
      dispatch(
        spFetchPayoffAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        spFetchPayoffAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const putPayoffAction = actionCreator.async<
  {},
  OrderRes,
  { code: number; description: string }
>("PUT_PAYOFF");

export const putPayoff =
  (companyId: number, orderId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(putPayoffAction.started({}));

    try {
      const result = await PayoffApi.payoff(companyId, orderId);
      dispatch(
        putPayoffAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        putPayoffAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const putCollectionAction = actionCreator.async<
  {},
  OrderRes,
  { code: number; description: string }
>("PUT_COLLECTION");

export const putCollection =
  (companyId: number, orderId: number) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(putCollectionAction.started({}));

    try {
      const result = await PayoffApi.collection(companyId, orderId);
      dispatch(
        putCollectionAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        putCollectionAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const orderActions = {
  fetchPayoffAction,
  spFetchPayoffAction,
  putPayoffAction,
  putCollectionAction,
};

export default orderActions;
