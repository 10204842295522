import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShopRes from "types/res/shop/ShopRes";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import {
  addOption,
  bulkApproveOption,
  bulkInsertOption,
  deleteOption,
  fetchOption,
  updateOption,
} from "redux/actions/option";
import OptionRes from "types/res/option/OptionRes";
import { fetchShops } from "redux/actions/shop";
import { FormType } from "components/FormField";
import CreateOptionReq from "types/req/option/CreateOptionReq";
import UpdateOptionReq from "types/req/option/UpdateOptionReq";
import {
  Box,
  Button,
  Checkbox,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import CsvImport from "components/CsvImport";
import { CsvFieldType } from "utils/CsvUtils";
import toastActions, { ToastType } from "redux/actions/toast";

const headers: { key: keyof OptionRes; label: string }[] = [
  { key: "name", label: "名前" },
  { key: "totalFee", label: "料金" },
  { key: "shopFee", label: "店舗料金" },
  { key: "castFee", label: "キャスト料金" },
  { key: "sort", label: "並び順" },
  { key: "isGuestShow", label: "顧客モバイル表示" },
  { key: "shops", label: "適用店舗" },
  { key: "optionId", label: "キャストへの" },
];

const Option: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const option = useSelector((state) => state.option);
  const [openCsvImport, setOpenCsvImport] = useState(false);
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [filterOption, setFilterOption] = useState<OptionRes[]>([]);
  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchOption(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    const filter = option.filter((o) => {
      const shopCondition = selectShops.some((shopId) =>
        o.shops.map((shop) => shop.shopId).includes(Number(shopId))
      );
      return shopCondition;
    });
    setFilterOption(filter);
  }, [option, selectShops]);

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "料金",
      key: "totalFee",
      type: FormType.Number,
    },
    {
      label: "店舗料金",
      key: "shopFee",
      type: FormType.Number,
    },
    {
      label: "キャスト料金",
      key: "castFee",
      type: FormType.Number,
    },
    {
      label: "並び順",
      key: "sort",
      type: FormType.Number,
    },
    {
      label: "顧客モバイル表示",
      key: "isGuestShow",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "非表示" },
        { id: "1", name: "表示" },
      ],
    },
    {
      label: "店舗",
      key: "shops",
      type: FormType.MultiOptions,
      options: shops.map((s) => ({ id: Number(s.shopId), name: s.name })),
      value: (val: any) => val.map((s: any) => s.shopId),
    },
  ];

  const csvHeader = [
    {
      label: "名前",
      key: "name",
      type: CsvFieldType.Text,
    },
    {
      label: "料金※数字のみ",
      key: "totalFee",
      type: CsvFieldType.Number,
    },
    {
      label: "店舗料金※数字のみ",
      key: "shopFee",
      type: CsvFieldType.Number,
    },
    {
      label: "キャスト料金※数字のみ",
      key: "castFee",
      type: CsvFieldType.Number,
    },
    {
      label: "並び順",
      key: "sort",
      type: CsvFieldType.Number,
    },
    {
      label: "顧客モバイル表示",
      key: "isGuestShow",
      type: CsvFieldType.Boolean,
    },
    {
      label: "店舗",
      key: "shops",
      type: CsvFieldType.Array,
      relation: shops,
      relationKey: "name",
      relationId: "shopId",
    },
  ];

  const onClickBulkApprove = async (optionId: number) => {
    await dispatch(bulkApproveOption(companyId, { optionId }));
    await dispatch(
      toastActions.showToast({
        text: "付与しました",
        type: ToastType.Success,
      })
    );
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        marginTop={1}
        marginRight={2}
      >
        <Box display="flex" alignItems="center">
          <Box>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={selectShops}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectShops((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length === shops.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= shops.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectShops.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCsvImport((prev) => !prev)}
              style={{ marginLeft: "30px" }}
            >
              csvインポート
            </Button>
          </Box>
        </Box>
      </Box>
      {openCsvImport && (
        <CsvImport
          templatePath={"/csv/option.csv"}
          templateName={"option_template.csv"}
          csvHeader={csvHeader}
          addType={CreateOptionReq}
          addFunc={(formData) => bulkInsertOption(companyId, formData)}
        />
      )}
      <OptionTable
        title={"オプション"}
        formId="optionForm"
        forms={forms}
        rows={headers}
        data={filterOption}
        defaultValue={{ isGuestShow: true }}
        addFunc={(formData) => addOption(companyId, formData)}
        updateFunc={(formData) => updateOption(companyId, formData)}
        deleteFunc={(item) =>
          deleteOption(companyId, {
            optionId: item.optionId,
          })
        }
        addType={CreateOptionReq}
        updateType={UpdateOptionReq}
        values={[
          (s) => s.name,
          (s) => s.totalFee,
          (s) => s.shopFee,
          (s) => s.castFee,
          (s) => s.sort || "未設定",
          (s) => (s.isGuestShow ? "表示" : "非表示"),
          (s) =>
            s.shops
              .map(
                (shopRes: ShopRes) =>
                  shops.find((shop) => shop.shopId === shopRes.shopId)?.name
              )
              .join(",") || "所属なし",
          (s) => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => onClickBulkApprove(s.optionId)}
            >
              一括付与
            </Button>
          ),
        ]}
      />
    </>
  );
};

const OptionTable = styled<
  React.FC<CommonTableProps<OptionRes, CreateOptionReq, UpdateOptionReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default Option;
