import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import CastNameRes from "types/res/castName/CastNameRes";
import {
  addCastName,
  bulkInsertCastName,
  deleteCastName,
  fetchCastNameWithPagination,
  updateCastName,
} from "redux/actions/castName";
import { fetchOption } from "redux/actions/option";
import { FormType } from "components/FormField";
import CreateCastNameReq from "types/req/castName/CreateCastNameReq";
import UpdateCastNameReq from "types/req/castName/UpdateCastNameReq";
import { fetchShops } from "redux/actions/shop";
import { fetchNotelClass } from "redux/actions/notelClass";
import {
  Box,
  Button,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import CsvImport from "components/CsvImport";
import { CsvFieldType } from "utils/CsvUtils";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { fetchCastCategory } from "redux/actions/castCategory";
import { useLocation } from "react-router-dom";
import useModal from "hooks/useModal";
import CastNameApi from "api/CastNameApi";
import CastRes from "types/res/cast/CastRes";
import CastApi from "api/CastApi";
import { fetchCast } from "redux/actions/cast";

const CastName: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const castNames = useSelector((state) => state.castName);
  const castCategories = useSelector((state) => state.castCategory);
  const optionList = useSelector((state) => state.option);
  const shops = useSelector((state) => state.shop);
  const notelClasses = useSelector((state) => state.notelClass);
  const castAll = useSelector((state) => state.cast);
  const [openCsvImport, setOpenCsvImport] = useState(false);
  const [castDisplayName, setCastDisplayName] = useState<string>();
  const [castName, setCastName] = useState<string>();
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectNotelClasses, setSelectNotelClasses] = useState<string[]>([]);
  const [selectCastCategories, setSelectCastCategories] = useState<string[]>(
    []
  );
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [casts, setCasts] = useState<CastRes[]>([]);
  const queryString = new URLSearchParams(useLocation().search);
  const editId = queryString.get("editId");
  const [, setShowForm] = useModal("castNameForm");

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchNotelClass(companyId));
    dispatch(fetchOption(companyId));
    dispatch(fetchCastNameWithPagination(companyId, { limit, offset }));
    dispatch(fetchCast(companyId));
    const apiCall = async () => {
      const count = await CastNameApi.count(companyId, { limit, offset });
      setTotal(count);
      const result = await fetchCastCategory(companyId)(dispatch);
      if (result) {
        const castCategoryId = result.find((r) =>
          r.name.includes("在籍")
        )?.castCategoryId;
        const castRes = await CastApi.findAllWithPagination(companyId, {
          limit: 100,
          offset: 0,
          castCategoryIds: castCategoryId ? [castCategoryId] : undefined,
        });
        setCasts(castRes);
      }
    };
    apiCall();
  }, [companyId, dispatch]);

  useEffect(() => {
    dispatch(
      fetchCastNameWithPagination(companyId, {
        limit,
        offset,
        name: castName,
        castName: castDisplayName,
        notelClassIds:
          selectNotelClasses.includes("すべて") ||
          selectNotelClasses.length === 0
            ? undefined
            : selectNotelClasses.map(Number),
        shopIds:
          selectShops.includes("すべて") || selectShops.length === 0
            ? undefined
            : selectShops.map(Number),
        castCategoryIds:
          selectCastCategories.includes("すべて") ||
          selectCastCategories.length === 0
            ? undefined
            : selectCastCategories.map(Number),
      })
    );
  }, [limit, offset]);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    setSelectNotelClasses([
      ...notelClasses.map((notelClass) => String(notelClass.notelClassId)),
      "すべて",
    ]);
  }, [notelClasses]);

  useEffect(() => {
    setSelectCastCategories([
      ...castCategories.map((castCategory) =>
        String(castCategory.castCategoryId)
      ),
      "すべて",
    ]);
  }, [castCategories]);

  useEffect(() => {
    if (!editId) return;
    const apiCall = async () => {
      const castName = await CastNameApi.findOne(companyId, Number(editId));
      setShowForm(true, { type: "editData", item: castName });
    };
    apiCall();
  }, [editId]);

  useEffect(() => {
    if (!openCsvImport) return;
    const apiCall = async () => {
      const result = await CastApi.findAll(companyId);
      setCasts(result);
    };
    apiCall();
  }, [openCsvImport]);

  const headers: { key: keyof CastNameRes; label: string }[] = [
    { key: "name", label: "キャスト名" },
    { key: "cast", label: "源氏名" },
    { key: "shop", label: "所属店舗" },
    { key: "notelClass", label: "クラス" },
    { key: "age", label: "年齢" },
    { key: "bodyHeight", label: "身長" },
    { key: "cup", label: "カップ" },
    { key: "bust", label: "バスト" },
    { key: "waist", label: "ウエスト" },
    { key: "hip", label: "ヒップ" },
    { key: "sort", label: "並び順" },
    { key: "options", label: "オプション" },
  ];

  const forms = [
    {
      label: "源氏名",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "キャスト名",
      key: "castId",
      type: FormType.AutoCompleteSelect,
      options: casts?.map((c) => ({ id: c.castId, name: c.displayName })) || [],
      loadMoreResults: async (displayName?: string) => {
        const offset = Math.trunc(casts.length / 100);
        const castCategoryId = castCategories.find((castCategory) =>
          castCategory.name.includes("在籍")
        )?.castCategoryId;
        const c = await CastApi.findAllWithPagination(companyId, {
          limit: 100,
          offset: displayName ? 0 : offset,
          displayName,
          castCategoryIds: castCategoryId ? [castCategoryId] : undefined,
        });
        setCasts((prev) =>
          Array.from(
            new Map([...prev, ...c].map((cast) => [cast.castId, cast])).values()
          )
        );
        return c;
      },
    },
    {
      label: "所属店舗",
      key: "shopId",
      type: FormType.Select,
      options: shops?.map((s) => ({ id: s.shopId, name: s.name })) || [],
    },
    {
      label: "クラス",
      key: "notelClassId",
      type: FormType.Select,
      options:
        notelClasses?.map((c) => ({ id: c.notelClassId, name: c.name })) || [],
    },
    {
      label: "年齢",
      key: "age",
      type: FormType.Number,
    },
    {
      label: "身長",
      key: "bodyHeight",
      type: FormType.Number,
    },
    {
      label: "カップ",
      key: "cup",
      type: FormType.Text,
    },
    {
      label: "バスト",
      key: "bust",
      type: FormType.Number,
    },
    {
      label: "ウエスト",
      key: "waist",
      type: FormType.Number,
    },
    {
      label: "ヒップ",
      key: "hip",
      type: FormType.Number,
    },
    {
      label: "並び順",
      key: "sort",
      type: FormType.Number,
    },
    {
      label: "オプション",
      key: "options",
      type: FormType.MultiOptions,
      options:
        optionList?.map((o) => ({
          id: Number(o.optionId),
          name: o.name,
        })) || [],
      value: (val: any) => val.map((o: any) => o.optionId),
    },
  ];

  const csvHeader = [
    {
      label: "源氏名",
      key: "name",
      type: CsvFieldType.Text,
    },
    {
      label: "本名",
      key: "castId",
      type: CsvFieldType.Object,
      relation: castAll,
      relationId: "castId",
      relationKey: "name",
    },
    {
      label: "所属店舗",
      key: "shopId",
      type: CsvFieldType.Object,
      relation: shops,
      relationKey: "name",
      relationId: "shopId",
    },
    {
      label: "クラス",
      key: "notelClassId",
      type: CsvFieldType.Object,
      relation: notelClasses,
      relationId: "notelClassId",
      relationKey: "name",
    },
    {
      label: "年齢",
      key: "age",
      type: CsvFieldType.Number,
    },
    {
      label: "身長",
      key: "bodyHeight",
      type: CsvFieldType.Number,
    },
    {
      label: "カップ",
      key: "cup",
      type: CsvFieldType.Text,
    },
    {
      label: "バスト",
      key: "bust",
      type: CsvFieldType.Number,
    },
    {
      label: "ウエスト",
      key: "waist",
      type: CsvFieldType.Number,
    },
    {
      label: "ヒップ",
      key: "hip",
      type: CsvFieldType.Number,
    },
    {
      label: "並び順",
      key: "sort",
      type: CsvFieldType.Number,
    },
    {
      label: "オプション",
      key: "options",
      type: CsvFieldType.Array,
      relation: optionList,
      relationId: "optionId",
      relationKey: "name",
    },
  ];

  const onClickSearch = async () => {
    const count = await CastNameApi.count(companyId, {
      limit,
      offset,
      name: castName,
      castName: castDisplayName,
      notelClassIds:
        selectNotelClasses.includes("すべて") || selectNotelClasses.length === 0
          ? undefined
          : selectNotelClasses.map(Number),
      shopIds:
        selectShops.includes("すべて") || selectShops.length === 0
          ? undefined
          : selectShops.map(Number),
      castCategoryIds:
        selectCastCategories.includes("すべて") ||
        selectCastCategories.length === 0
          ? undefined
          : selectCastCategories.map(Number),
    });
    setTotal(count);
    dispatch(
      fetchCastNameWithPagination(companyId, {
        limit: 100,
        offset: 0,
        name: castName,
        castName: castDisplayName,
        notelClassIds:
          selectNotelClasses.includes("すべて") ||
          selectNotelClasses.length === 0
            ? undefined
            : selectNotelClasses.map(Number),
        shopIds:
          selectShops.includes("すべて") || selectShops.length === 0
            ? undefined
            : selectShops.map(Number),
        castCategoryIds:
          selectCastCategories.includes("すべて") ||
          selectCastCategories.length === 0
            ? undefined
            : selectCastCategories.map(Number),
      })
    );
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        marginTop={1}
        marginRight={2}
      >
        <Box display="flex" alignItems="center">
          <TextField
            label="キャスト名"
            style={{ margin: "10px", width: "200px" }}
            value={castDisplayName || ""}
            onChange={(event) => setCastDisplayName(event.target.value)}
          />
          <TextField
            label="源氏名"
            style={{ margin: "10px", width: "200px" }}
            value={castName || ""}
            onChange={(event) => setCastName(event.target.value)}
          />
          <Box>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={selectShops}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectShops((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length === shops.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= shops.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectShops.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel id="select-multiple-class">クラス</InputLabel>
            <Select
              multiple
              value={selectNotelClasses}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectNotelClasses((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...notelClasses.map((notelClass) =>
                        String(notelClass.notelClassId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length ===
                      notelClasses.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...notelClasses.map((notelClass) =>
                        String(notelClass.notelClassId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <=
                    notelClasses.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-notelClass" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return notelClasses
                    .filter((notelClass) =>
                      (selected as string[]).includes(
                        String(notelClass.notelClassId)
                      )
                    )
                    .map((notelClass) => notelClass.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectNotelClasses.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {notelClasses.map((notelClass) => (
                <MenuItem
                  key={notelClass.notelClassId}
                  value={String(notelClass.notelClassId)}
                >
                  <Checkbox
                    checked={
                      selectNotelClasses.indexOf(
                        String(notelClass.notelClassId)
                      ) !== -1
                    }
                  />
                  <ListItemText primary={notelClass.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel id="select-multiple-class">キャスト区分</InputLabel>
            <Select
              multiple
              value={selectCastCategories}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectCastCategories((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...castCategories.map((castCategory) =>
                        String(castCategory.castCategoryId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length ===
                      castCategories.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...castCategories.map((castCategory) =>
                        String(castCategory.castCategoryId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <=
                    castCategories.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-castCategory" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return castCategories
                    .filter((castCategory) =>
                      (selected as string[]).includes(
                        String(castCategory.castCategoryId)
                      )
                    )
                    .map((castCategory) => castCategory.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectCastCategories.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {castCategories.map((castCategory) => (
                <MenuItem
                  key={castCategory.castCategoryId}
                  value={String(castCategory.castCategoryId)}
                >
                  <Checkbox
                    checked={
                      selectCastCategories.indexOf(
                        String(castCategory.castCategoryId)
                      ) !== -1
                    }
                  />
                  <ListItemText primary={castCategory.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onClickSearch()}
            style={{ marginLeft: "30px" }}
          >
            検索
          </Button>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCsvImport((prev) => !prev)}
              style={{ marginLeft: "30px" }}
            >
              csvインポート
            </Button>
          </Box>
        </Box>
      </Box>
      {openCsvImport && (
        <CsvImport
          templatePath={"/csv/cast_name.csv"}
          templateName={"cast_name_template.csv"}
          csvHeader={csvHeader}
          addType={CreateCastNameReq}
          addFunc={(formData) => bulkInsertCastName(companyId, formData)}
        />
      )}
      <CastNameTable
        title={"源氏名"}
        formId="castNameForm"
        forms={forms}
        addFunc={(formData) => addCastName(companyId, formData)}
        updateFunc={(formData) => updateCastName(companyId, formData)}
        deleteFunc={(item) =>
          deleteCastName(companyId, {
            castNameId: item?.castNameId,
          })
        }
        updateHook={(formData) => {
          setCasts((prev) =>
            Array.from(
              new Map(
                [...prev, formData.cast].map((cast) => [cast.castId, cast])
              ).values()
            )
          );
        }}
        rows={headers}
        data={castNames}
        addType={CreateCastNameReq}
        updateType={UpdateCastNameReq}
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        totalCount={total}
        values={[
          (s) => s.cast?.displayName || "未設定",
          (s) => s.name,
          (s) => s.shop?.name || "未設定",
          (s) => s.notelClass?.name || "未設定",
          (s) => s.age || "未設定",
          (s) => s.bodyHeight || "未設定",
          (s) => s.cup || "未設定",
          (s) => s.bust || "未設定",
          (s) => s.waist || "未設定",
          (s) => s.hip || "未設定",
          (s) => s.sort || "未設定",
          (s) =>
            s.options
              ?.map(
                (optionRes) =>
                  optionList.find(
                    (option) => option.optionId === optionRes.optionId
                  )?.name
              )
              .join("/") || "オプションなし",
        ]}
      />
    </>
  );
};

const CastNameTable = styled<
  React.FC<CommonTableProps<CastNameRes, CreateCastNameReq, UpdateCastNameReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default CastName;
