import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import NotelClassRes from "types/res/notelClass/NotelClassRes";
import UpdateNotelClassReq from "types/req/notelClass/UpdateNotelClassReq";
import CreateNotelClassReq from "types/req/notelClass/CreateNotelClassReq";
import DeleteNotelClassReq from "types/req/notelClass/DeleteNotelClassReq";
import NotelClassApi from "../../api/NotelClassApi";

const actionCreator = actionCreatorFactory();
const addNotelClassAction = actionCreator.async<
  CreateNotelClassReq,
  NotelClassRes,
  { code: number; description: string }
>("ADD_NOTELCLASS");

export const addNotelClass =
  (companyId: number, req: CreateNotelClassReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(addNotelClassAction.started({ ...req }));

    try {
      const notelClass = new CreateNotelClassReq({ ...req });
      const result = await NotelClassApi.create(companyId, notelClass);
      dispatch(
        addNotelClassAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        addNotelClassAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const fetchNotelClassAction = actionCreator.async<
  {},
  NotelClassRes[],
  { code: number; description: string }
>("FETCH_NOTELCLASS");

export const fetchNotelClass =
  (companyId: number) => async (dispatch: Dispatch<Action>) => {
    dispatch(fetchNotelClassAction.started([]));

    try {
      const result = await NotelClassApi.findAll(companyId);
      dispatch(
        fetchNotelClassAction.done({
          params: {},
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        fetchNotelClassAction.failed({
          params: {},
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const updateNotelClassAction = actionCreator.async<
  UpdateNotelClassReq,
  NotelClassRes,
  { code: number; description: string }
>("UPDATE_NOTELCLASS");

export const updateNotelClass =
  (companyId: number, req: UpdateNotelClassReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateNotelClassAction.started({ ...req }));

    try {
      const updateNotelClass = new UpdateNotelClassReq({ ...req });
      const result = await NotelClassApi.update(companyId, updateNotelClass);
      dispatch(
        updateNotelClassAction.done({
          params: { ...req },
          result,
        })
      );
      return result;
    } catch (error) {
      dispatch(
        updateNotelClassAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const deleteNotelClassAction = actionCreator.async<
  DeleteNotelClassReq,
  {},
  { code: number; description: string }
>("DELETE_NOTELCLASS");

export const deleteNotelClass =
  (companyId: number, req: DeleteNotelClassReq) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(deleteNotelClassAction.started({ ...req }));

    try {
      const notelClass = new DeleteNotelClassReq({ ...req });
      const result = await NotelClassApi.delete(companyId, notelClass);
      dispatch(
        deleteNotelClassAction.done({
          params: { ...req },
          result: {},
        })
      );
      return result;
    } catch (error) {
      dispatch(
        deleteNotelClassAction.failed({
          params: { ...req },
          // @ts-ignore
          error: { code: error.code, description: error.description },
        })
      );
      return error;
    }
  };

const notelClassActions = {
  fetchNotelClassAction,
  addNotelClassAction,
  updateNotelClassAction,
  deleteNotelClassAction,
};

export default notelClassActions;
